import React from "react";
import { pathOr } from "ramda";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Box,
  Chip,
  Tab,
  Tabs as CTabs,
} from "../../../../../components/Atoms";

import { GetEducationalPackageLearner as EducationalPackage } from "../Queries/types/GetEducationalPackageLearner";

export enum TabValue {
  newActivities = "newActivities",
  submittedActivities = "submittedActivities",
}

interface Props {
  onChange: (value: TabValue) => void;
  value: TabValue;
  data?: EducationalPackage;
}

export const Tabs: React.FC<Props> = ({ data, onChange, value }) => {
  const { t } = useTranslation();

  const handleChange = (_event: React.ChangeEvent<{}>, value: TabValue) => {
    onChange(value);
  };

  return (
    <CTabs
      value={value}
      onChange={handleChange as any}
      textColor="primary"
      variant="scrollable"
      scrollButtons="off"
    >
      <Tab
        value={TabValue.newActivities}
        label={
          <Flex>
            {t("containers.learnerEducationPackage.tabs.newActivities")}
            <Box ml={1}>
              <Chip
                color="primary"
                label={pathOr(
                  0,
                  "educationalPackage.data.newActivitiesCount".split("."),
                  data,
                )}
              />
            </Box>
          </Flex>
        }
      />
      <Tab
        value={TabValue.submittedActivities}
        label={
          <Flex>
            {t("containers.learnerEducationPackage.tabs.returnedActivitives")}
            <Box ml={1}>
              <Chip
                color="primary"
                label={pathOr(
                  0,
                  "educationalPackage.data.submittedActivitiesCount".split("."),
                  data,
                )}
              />
            </Box>
          </Flex>
        }
      />
    </CTabs>
  );
};
