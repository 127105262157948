import * as React from "react";

import { storiesOf } from "@storybook/react";
import {
  GoalIcon,
  EducationalPackageIcon,
  AchievementIcon,
  GoogleClassroomIcon,
  FairIcon,
  FairlyGoodIcon,
  GoodIcon,
  VeryGoodIcon,
  ExcellentIcon,
} from ".";
import { Flex } from "../Flex";

storiesOf("Atoms/Icons", module)
  .add(
    "NumericScale",
    () => (
      <Flex pt={3}>
        <FairIcon checked></FairIcon>
        <FairlyGoodIcon checked></FairlyGoodIcon>
        <GoodIcon checked></GoodIcon>
        <VeryGoodIcon checked></VeryGoodIcon>
        <ExcellentIcon checked></ExcellentIcon>
      </Flex>
    ),
    { info: { inline: true } },
  )
  .add(
    "GoalIcon",
    () => (
      <Flex pt={3}>
        <GoalIcon index={1} tooltip="Goal 1"></GoalIcon>
        <GoalIcon index={2} tooltip="Goal 2"></GoalIcon>
        <GoalIcon index={3} tooltip="Goal 3"></GoalIcon>
      </Flex>
    ),
    { info: { inline: true } },
  )
  .add("EducationalPackageIcon", () => <EducationalPackageIcon />, {
    info: { inline: true },
  })
  .add("AchievementIcon", () => <AchievementIcon />, {
    info: { inline: true },
  })
  .add("GoogleClassroomIcon", () => <GoogleClassroomIcon />, {
    info: { inline: true },
  });
