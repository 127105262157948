import React, { useMemo } from "react";

import { Box, Flex, InputLabel, NumericAssessmentInput, Text } from "components/Atoms";

import { Cell, FormValues, StyledInputLabel } from "./index";

import { Hidden } from "@material-ui/core";
import TextToSpeechAudioPlayer from "components/Molecules/TextToSpeechAudioPlayer";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { QuestionType } from "typings/graphql-global-types";
import { GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup2";

interface Props {
  numericQuestions: SurveyQuestion[];
  values: FormValues;
  onChange: (field: string, value: number | null) => void;
  gradingScale: number;
}

interface ListQuestion {
  i: number;
  q: SurveyQuestion;
}

const BoldInputLabel = styled(InputLabel)`
  color: ${props => props.theme.palette.text.primary};
  text-transform: uppercase;
  font-weight: 700;
`;

const GroupColumn = ({ numericQuestions, values, onChange, gradingScale }: Props) => {
  const { t } = useTranslation();
  const goalWidths = ["87%", "80%", "60%"];
  const listenWidths = ["13%", "20%", "10%"];
  const assessmentWidths = ["100%", "100%", "30%"];

  const grouped = useMemo(
    () =>
      numericQuestions.reduce(
        (acc: { [index: string]: ListQuestion[] }, item, i) => {
          const group = item.description;
          if (!acc[group]) acc[group] = [];
          acc[group].push({ i: i, q: item });
          return acc;
        },
        {},
      ),
    [numericQuestions],
  );

  return (
    <>
      {Object.entries(grouped).map((group, g) => {
        const subtitle = group[0];
        const questions = group[1];
        return (
          <Flex
            pl={2}
            pr={1}
            pb={1}
            mt={2}
            flexDirection="column"
            key={`group-${g}`}
          >
            <Flex flex={1} flexWrap="wrap">
              {/* Subtitle */}
              <Cell
                key={`goalname-${subtitle}`}
                width={goalWidths}
                order={1}
                flexGrow={1}
              >
                <Flex alignItems="center" height="100%">
                  <BoldInputLabel>{subtitle}</BoldInputLabel>
                </Flex>
              </Cell>

              {/* Goal */}
              {/* <Cell width={goalWidths} order={1} flexGrow={1}></Cell> */}
              {questions.map(({ i, q: g }) => (
                <Cell
                  key={`goalname-${g.id}`}
                  py={2}
                  divider={i < questions.length - 1}
                  width={goalWidths}
                  order={i + 2}
                  flexGrow={1}
                >
                  <Flex height="100%" alignItems="flex-start" pr={[0, 0, 2]}>
                    <Flex pt={0.5} minHeight="54px" alignItems="center">
                      <Text>{g.name}</Text>
                    </Flex>
                  </Flex>
                </Cell>
              ))}

              {/* Listen */}
              <Cell width={listenWidths} order={1} flexGrow={1}>
                <Hidden mdDown>
                  <Flex alignItems="center" height="100%">
                    <StyledInputLabel>
                      {t("containers.digipolku.surveyForm.listen")}
                    </StyledInputLabel>
                  </Flex>
                </Hidden>
              </Cell>

              {questions.map(({ i, q }) => (
                <Cell
                  key={`listen-${q.id}`}
                  py={2}
                  divider={i < questions.length - 1}
                  width={listenWidths}
                  order={i + 2}
                  flexGrow={1}
                >
                  <Flex
                    height="100%"
                    alignItems="flex-start"
                    justifyContent={["center", "flex-start"]}
                  >
                    <Box>
                      <TextToSpeechAudioPlayer text={q.name} />
                    </Box>
                  </Flex>
                </Cell>
              ))}

              {/* Assessment */}
              <Cell hideMobile width={assessmentWidths} order={1} flexGrow={1}>
                <Hidden mdDown>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                  >
                    <StyledInputLabel htmlFor="surveyQuestionAnswers">
                      {t("containers.digipolku.surveyForm.selfAssessment")}
                    </StyledInputLabel>
                  </Flex>
                </Hidden>
              </Cell>
              {questions.map(({ i, q }) => {
                const answer = values.surveyQuestionAnswers.find(
                  a => a.surveyQuestionId === q.id,
                );

                if (!answer || q.type !== QuestionType.numeric) return null;
                return (
                  <Cell
                    key={`input-${q.id}`}
                    py={2}
                    showBorderBottomOnMobile
                    divider={i < questions.length - 1}
                    width={assessmentWidths}
                    order={i + 2}
                    flexGrow={1}
                  >
                    <Flex
                      pl={["47px", "47px", 0]}
                      pr={[2.5, 2.5, 0]}
                      height="100%"
                      alignItems="center"
                    >
                      <FastField name={`surveyQuestionAnswers.${i}.numeric`}>
                        {() => (
                          <NumericAssessmentInput
                            type={"self"}
                            scale={gradingScale}
                            scaleTexts={null}
                            name={`surveyQuestionAnswers.${i}.numeric`}
                            key={answer.surveyQuestionId}
                            value={answer.numeric ? answer.numeric : null}
                            onChange={value =>
                              onChange(
                                `surveyQuestionAnswers.${i}.numeric`,
                                value,
                              )
                            }
                          />
                        )}
                      </FastField>
                    </Flex>
                  </Cell>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

export default GroupColumn;
