import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import MuiLink from "@material-ui/core/Link";
import { Flex, Box, LogoEmblem, Text } from "components/Atoms";
import { Card, CardContent } from "components/Molecules/Card";
import { useHistory } from "react-router";
import { useAnalytics } from "components/Hooks/firebase-hooks";

import background from "images/error_background.jpg";

const GrowContainer = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Background = styled(Flex)`
  min-height: 100vh;
  background: url(${background}) no-repeat center center fixed;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
`;

const smallIcon = (props: any) => `
  position: absolute;
  top: ${props.theme.spacing(2)}px;
  left: ${props.theme.spacing(2)}px;
  z-index: 1;
  width: 150px;
`;
const ResponsiveLogoWrapper = styled(Box)`
  width: 29rem;
  ${props => props.theme.breakpoints.down("sm")} {
    @media (orientation: landscape) {
      ${props => smallIcon(props)}
    }
  }

  ${props => props.theme.breakpoints.down("xs")} {
    ${props => smallIcon(props)}
  }
`;

export const Terms: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { analytics } = useAnalytics();

  analytics.logEvent("page_view" as string, {
    page_title: "terms",
    page_path: history.location.pathname,
  });

  return (
    <Background flexDirection="column">
      {/* @ts-ignore */}
      <GrowContainer component="main" maxWidth="lg">
        <Flex flex={1} flexDirection="column" mt={[0, 10]}>
          <Flex justifyContent="center">
            <ResponsiveLogoWrapper>
              <LogoEmblem />
            </ResponsiveLogoWrapper>
          </Flex>
          <Box px={{ xs: 1, md: 10 }} pt={{ xs: 2 }} mb={4}>
            <Card>
              <CardContent>
                <Box>
                  <Text variant="h3">{t("containers.terms.title")}</Text>
                  <Box my={2}>
                    <Trans i18nKey="containers.terms.bodyText">
                      <Text variant="body1"></Text>
                      <Text
                        style={{ lineHeight: "2rem" }}
                        variant="body1"
                        fontWeight="600"
                      ></Text>

                      <Text variant="body1" style={{ display: "block" }}></Text>
                      <MuiLink
                        href="https://www.hel.fi/static/liitteet-2019/Kaupunginkanslia/julkaisut/HKI_tietosuojakasikirja_A4.pdf"
                        target="_blank"
                      ></MuiLink>
                    </Trans>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Flex>
      </GrowContainer>
    </Background>
  );
};

export default Terms;
