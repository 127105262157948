import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { pathOr } from "ramda";

import styled from "styled-components";
import { darken } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmailOutlined";

import { Flex, Input, Button, Box, Text } from "../../Atoms";

import { Card, CardContent } from "../../Molecules";

const PositionedAlternateEmailIcon = styled(AlternateEmailIcon)`
  position: absolute;
  margin-left: 8px;
  z-index: 1;
`;

const InputWithAdorment = styled(props => <Input {...props} />)`
  input {
    padding-left: 36px;
    width: 100%;
  }
  width: 100%;
`;

const EmailLinkButton = styled(Button)`
  justify-content: flex-start;
  background-color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonBg".split("."), props)};
  color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonColor".split("."), props)};
  :hover {
    background-color: ${props =>
      darken(
        pathOr("#fff", "theme.custom.emailLinkButtonBg".split("."), props),
        0.15,
      )};
  }
`;

const Wrap = styled(Box)`
  position: relative;
`;

export interface ConfirmEmailLinkFormProps {
  onAccepted: (email: string) => void;
  onBack: () => void;
}

export const ConfirmEmailLinkForm: React.FunctionComponent<
  ConfirmEmailLinkFormProps
> = ({ onAccepted, onBack }) => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const sendSignInLinkToEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    window.localStorage.setItem("emailForSignIn", email);
    onAccepted(email);
  };

  const onEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Box py={2}>
          <form onSubmit={sendSignInLinkToEmail}>
            <Text variant="body1">
              {t("components.organisms.confirmEmailLinkForm.description")}
            </Text>
            <Box my={2}>
              <FormControl fullWidth required>
                <Flex alignItems="center">
                  <PositionedAlternateEmailIcon color="primary" />
                  <InputWithAdorment
                    type="email"
                    placeholder={t(
                      "components.organisms.confirmEmailLinkForm.email",
                    )}
                    onChange={onEmailChanged}
                    id="Email"
                    value={email}
                    data-cy="login-input-email"
                  />
                </Flex>
                <Wrap>
                  <Box mt={3}>
                    <EmailLinkButton
                      size="small"
                      variant="contained"
                      color="secondary"
                      aria-label="EmailLinkButton"
                      data-cy="login-button-emaillink"
                      type="submit"
                      fullWidth
                    >
                      <EmailIcon />

                      <Box flex={1} textAlign="center">
                        {t("components.organisms.confirmEmailLinkForm.accept")}
                      </Box>
                    </EmailLinkButton>
                  </Box>
                </Wrap>
              </FormControl>
            </Box>
            <Box textAlign="right">
              <Button onClick={onBack}>
                {t("components.organisms.confirmEmailLinkForm.goBack")}
              </Button>
            </Box>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};
