import { useContext, useEffect, useState, useCallback } from "react";
import UserContext from "../Contexts/UserContext";
import firebase from "services/firebase";
import { checkLogin } from "services/apollo";
import { useTranslation } from "react-i18next";

const useSession = () => {
  const { user, signOut } = useContext(UserContext);
  return { user, signOut };
};
export interface HookEmailLinkState {
  error?: firebase.auth.Error;
  loading: boolean;
  user?: firebase.User;
  emailConfirmation?: boolean;
}

const useEmailLinkState = (
  auth: firebase.auth.Auth,
  onSuccess: () => void,
  confirmEmail?: boolean,
): HookEmailLinkState => {
  const [state, setState] = useState<HookEmailLinkState>({
    loading: false,
    emailConfirmation: confirmEmail,
  });

  const { i18n } = useTranslation();

  const initSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const login = useCallback(async () => {
    try {
      const email: any = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        setState({ emailConfirmation: true, loading: false });
        return;
      }

      setState({ error: undefined, loading: true });
      const result = await auth.signInWithEmailLink(
        email,
        window.location.href,
      );

      if (result.user) {
        await checkLogin(result.user, i18n);
        const method =
          result && result.credential && result.credential.signInMethod;
        firebase
          .analytics()
          .logEvent(firebase.analytics.EventName.LOGIN as any, { method });
      } else {
        throw new Error("Not authorized!");
      }

      setState({ user: result.user, loading: false });
      window.localStorage.removeItem("emailForSignIn");
      initSuccess();
    } catch (error) {
      console.error("useEmailLinkState error", error);
      setState({ error: error as firebase.auth.Error, loading: false });
    }
  }, [auth, i18n, initSuccess]);

  const isSignInWithEmailLink = auth.isSignInWithEmailLink(
    window.location.href,
  );

  useEffect(() => {
    if (!state.loading && !state.error && isSignInWithEmailLink) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignInWithEmailLink, state.loading, state.error, confirmEmail]);

  return state;
};

const useAuth = (
  auth: firebase.auth.Auth,
) => {
  const [state, setState] = useState<{
    user?: firebase.User | null;
    loading: boolean;
    roles?: string[];
    error?: firebase.auth.Error;
  }>(() => {
    const user = auth.currentUser;
    return { loading: !user, user };
  });

  const onChange = async (user: firebase.User | null) => {
    if (user) {
      const { claims } = await user.getIdTokenResult();
      firebase.analytics().setUserId(user.uid);
      firebase
        .analytics()
        .setUserProperties({ role: claims.roles && claims.roles[0] });

      setState({ loading: false, user, roles: claims.roles, error: undefined });
    } else {
      setState({ loading: false, user, error: undefined });
    }
  };

  function onError(error: firebase.auth.Error) {
    setState({ loading: false, error, user: undefined });
  }

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(onChange, onError);
    return () => unsubscribe();
  }, [auth]);

  const { loading, error, user, roles } = state;
  return { user, loading, roles: roles || [], error };
};

export function useAnalytics(): { analytics: firebase.analytics.Analytics } {
  return {
    analytics: firebase.analytics(),
  };
}

export { useEmailLinkState, useSession, useAuth };
