import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";

interface Props {
  eGoalCount: number;
  onClick: () => void;
}

export const EGoalsLinkedButton = ({ eGoalCount, onClick }: Props) => {
  return eGoalCount > 0 ? (
    <IconButton
      aria-label="egoals button"
      onClick={onClick}
      data-cy="show-egoal-info"
    >
      <Badge overlap="rectangle" badgeContent={eGoalCount} color="primary">
        <LinkIcon color="primary" fontSize="large" />
      </Badge>
    </IconButton>
  ) : (
    <IconButton disabled>
      <LinkOffIcon color="disabled" fontSize="large" />
    </IconButton>
  );
};
