import React from "react";

export const GoodIcon = ({
  checked,
  view,
}: {
  view?: boolean;
  checked?: boolean;
}) => {
  return (
    <svg id="Component_32_1" width="43" height="43" viewBox="0 0 43 43">
      {!view && (
        <g
          id="Ellipse_253"
          data-name="Ellipse 253"
          fill="#fff"
          stroke="#fdb325"
          strokeWidth="2"
        >
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="20.5" fill="none" />
        </g>
      )}
      {(view || checked) && (
        <g
          id="Group_1422"
          data-name="Group 1422"
          transform="translate(-118 -898.533)"
        >
          <path
            id="Path_219"
            data-name="Path 219"
            d="M11.766,0A11.766,11.766,0,1,1,0,11.766,11.766,11.766,0,0,1,11.766,0Z"
            transform="translate(128 908)"
            fill="#fdb325"
          />
          <g id="Group_4" data-name="Group 4" transform="translate(128 908)">
            <line
              id="Line_16"
              data-name="Line 16"
              y1="19.198"
              x2="19.198"
              transform="translate(-3.4 -4.143)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_17"
              data-name="Line 17"
              y1="19.198"
              x2="19.198"
              transform="translate(-0.517 -0.971)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_18"
              data-name="Line 18"
              y1="19.198"
              x2="19.198"
              transform="translate(2.366 2.201)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_19"
              data-name="Line 19"
              y1="19.198"
              x2="19.198"
              transform="translate(5.249 5.373)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_20"
              data-name="Line 20"
              y1="19.198"
              x2="19.198"
              transform="translate(7.616 9.062)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="11.766"
            cy="11.766"
            r="11.766"
            transform="translate(128 908)"
            fill="none"
            stroke="#fdb325"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      )}
    </svg>
  );
};
