import React, { useState } from "react";
import { FormikProps, FieldArray, getIn } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddIcon from "@material-ui/icons/Add";

import {
  Flex,
  Box,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Text,
  Chip,
} from "components/Atoms";

import {
  FairIcon,
  FairlyGoodIcon,
  GoodIcon,
  VeryGoodIcon,
  ExcellentIcon,
} from "components/Atoms/Icons";

import useBeforeUnload from "components/Hooks/use-before-unload";
import { NumericAssessmentSettingInput } from "typings/graphql-global-types";

import { GetNumericAssessmentSettingFavourites_favouriteNumericAssessmentSettings_data as FavouriteNumericAssessmentSetting } from "../Queries/types/GetNumericAssessmentSettingFavourites";

import { RemoveFavouriteAssessmentSettingConfirmationModal } from "components/Organisms/RemoveFavouriteAssessmentSettingConfirmationModal";

const Form = styled.form`
  width: 100%;
`;

const SmallIconWrapper = styled(Flex)`
  svg {
    width: 30px;
  }
`;

const InputLabelWithoutInput = styled(InputLabel)`
  position: initial;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledChip = styled(Chip)`
  background: rgba(0, 0, 0, 0.08);
  color: #8a8a8a;
  text-transform: uppercase;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
`;

const StyledListSubheader = styled(ListSubheader)`
  line-height: 36px;
`;

interface AllFavouriteNumericAssessmentSetting
  extends FavouriteNumericAssessmentSetting {
  type: string;
}

interface VerbalAssessmentSettingFormProps
  extends FormikProps<NumericAssessmentSettingInput> {
  favouriteNumericAssessmentSettings: AllFavouriteNumericAssessmentSetting[];
  isFavouriteManager?: boolean;
  onRemoveFavourite: (id: string) => void;
  onSetDefaultSetting: (id: string, state: boolean) => void;
}

const StyledInputLabel = styled(InputLabel)`
  position: initial;
`;

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
`;

const ScaleIcon = ({
  scale,
  scaleNumber,
}: {
  scale: number;
  scaleNumber: number;
}) => (
  <SmallIconWrapper>
    {scale === 3 && scaleNumber === 0 && <FairIcon checked />}
    {scale === 3 && scaleNumber === 1 && <GoodIcon checked />}
    {scale === 3 && scaleNumber === 2 && <ExcellentIcon checked />}

    {scale === 5 && scaleNumber === 0 && <FairIcon checked />}
    {scale === 5 && scaleNumber === 1 && <FairlyGoodIcon checked />}
    {scale === 5 && scaleNumber === 2 && <GoodIcon checked />}
    {scale === 5 && scaleNumber === 3 && <VeryGoodIcon checked />}
    {scale === 5 && scaleNumber === 4 && <ExcellentIcon checked />}
  </SmallIconWrapper>
);

export const NumericAssesssmentSettingForm: React.FC<VerbalAssessmentSettingFormProps> = ({
  values: {
    id,
    name,
    scale,
    learnerScaleTexts,
    teacherScaleTexts,
    defaultSetting,
  },
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  errors,
  favouriteNumericAssessmentSettings,
  isFavouriteManager,
  onRemoveFavourite,
  onSetDefaultSetting,
}) => {
  const { t } = useTranslation();

  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result && id) {
      onRemoveFavourite(id);
    }
    setShowRemoveConfirmationModal(false);
  };

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);

    if (name === "id") {
      const favouriteNas = favouriteNumericAssessmentSettings.find(
        v => v.id === e.target.value,
      );

      if (favouriteNas) {
        setFieldValue("teacherScaleTexts", favouriteNas.teacherScaleTexts);
        setFieldValue("learnerScaleTexts", favouriteNas.learnerScaleTexts);
        setFieldValue("scale", favouriteNas.scale);
        setFieldValue("name", favouriteNas.name);
        setFieldValue("favourite", favouriteNas.favourite);
        setFieldValue("defaultSetting", favouriteNas.defaultSetting);
      } else {
        setFieldValue(
          `teacherScaleTexts.${0}`,
          t("globals.numericAssessments.teacher.fair"),
        );
        setFieldValue(
          `teacherScaleTexts.${1}`,
          t("globals.numericAssessments.teacher.good"),
        );
        setFieldValue(
          `teacherScaleTexts.${2}`,
          t("globals.numericAssessments.teacher.excellent"),
        );

        setFieldValue(
          `learnerScaleTexts.${0}`,
          t("globals.numericAssessments.self.fair"),
        );
        setFieldValue(
          `learnerScaleTexts.${1}`,
          t("globals.numericAssessments.self.good"),
        );
        setFieldValue(
          `learnerScaleTexts.${2}`,
          t("globals.numericAssessments.self.excellent"),
        );
        setFieldValue("scale", 3);
        setFieldValue("name", "");
      }
    }

    if (name === "scale") {
      const scaleValue = parseInt(e.target.value);
      setFieldValue(name, scaleValue);

      if (scaleValue === 3) {
        setFieldValue(
          `teacherScaleTexts.${0}`,
          t("globals.numericAssessments.teacher.fair"),
        );
        setFieldValue(
          `teacherScaleTexts.${1}`,
          t("globals.numericAssessments.teacher.good"),
        );
        setFieldValue(
          `teacherScaleTexts.${2}`,
          t("globals.numericAssessments.teacher.excellent"),
        );

        setFieldValue(
          `learnerScaleTexts.${0}`,
          t("globals.numericAssessments.self.fair"),
        );
        setFieldValue(
          `learnerScaleTexts.${1}`,
          t("globals.numericAssessments.self.good"),
        );
        setFieldValue(
          `learnerScaleTexts.${2}`,
          t("globals.numericAssessments.self.excellent"),
        );
      } else if (scaleValue === 5) {
        setFieldValue(
          `teacherScaleTexts.${0}`,
          t("globals.numericAssessments.teacher.fair"),
        );
        setFieldValue(
          `teacherScaleTexts.${1}`,
          t("globals.numericAssessments.teacher.fairlyGood"),
        );
        setFieldValue(
          `teacherScaleTexts.${2}`,
          t("globals.numericAssessments.teacher.mediocre"),
        );
        setFieldValue(
          `teacherScaleTexts.${3}`,
          t("globals.numericAssessments.teacher.good"),
        );
        setFieldValue(
          `teacherScaleTexts.${4}`,
          t("globals.numericAssessments.teacher.excellent"),
        );

        setFieldValue(
          `learnerScaleTexts.${0}`,
          t("globals.numericAssessments.self.fair"),
        );
        setFieldValue(
          `learnerScaleTexts.${1}`,
          t("globals.numericAssessments.self.fairlyGood"),
        );
        setFieldValue(
          `learnerScaleTexts.${2}`,
          t("globals.numericAssessments.self.mediocre"),
        );
        setFieldValue(
          `learnerScaleTexts.${3}`,
          t("globals.numericAssessments.self.good"),
        );
        setFieldValue(
          `learnerScaleTexts.${4}`,
          t("globals.numericAssessments.self.excellent"),
        );
      }
    } else if (name === "defaultSetting" && id) {
      onSetDefaultSetting(id, e.target.checked);
    } else {
      setFieldValue(name, e.target.value);
    }
    setFieldTouched(name, true, false);
  };

  const myFavs = favouriteNumericAssessmentSettings.filter(
    a => a.type === "myFavourite",
  );
  const otherFavs = favouriteNumericAssessmentSettings.filter(
    a => a.type === "otherFavourite",
  );

  const scaleInputs = (
    <Flex ml={2}>
      <Box flex={1}>
        <Box pl={7} maxWidth={250}>
          <InputLabelWithoutInput>
            {t(
              "components.organisms.editNumericAssessmentSettingModal.learner",
            )}
          </InputLabelWithoutInput>
        </Box>
        <FieldArray
          name="learnerScaleTexts"
          render={() => (
            <Box>
              {new Array(scale).fill(null).map((a, i) => (
                <Flex m={1} key={`l-scale-item-${i}`}>
                  <Box ml={1.75} mr={2}>
                    <ScaleIcon scale={scale} scaleNumber={i} />
                  </Box>
                  <Input
                    fullWidth
                    disabled={id !== "new"}
                    value={learnerScaleTexts[i]}
                    name={`learnerScaleTexts.${i}`}
                    onChange={change.bind(null, `learnerScaleTexts.${i}`)}
                    helperText={
                      getIn(touched, `learnerScaleTexts.${i}`)
                        ? getIn(errors, `learnerScaleTexts.${i}`)
                        : ""
                    }
                    error={getIn(errors, `learnerScaleTexts.${i}`)}
                  />
                </Flex>
              ))}
            </Box>
          )}
        />
      </Box>
      <Box ml={2} flex={1}>
        <Box maxWidth={200}>
          <InputLabelWithoutInput>
            {t(
              "components.organisms.editNumericAssessmentSettingModal.teacher",
            )}
          </InputLabelWithoutInput>
        </Box>
        <FieldArray
          name="teacherScaleTexts"
          render={() => (
            <Box>
              {new Array(scale).fill(null).map((a, i) => (
                <Box key={`t-scale-item-${i}`} my={1}>
                  <Input
                    fullWidth
                    disabled={id !== "new"}
                    value={teacherScaleTexts[i]}
                    name={`teacherScaleTexts.${i}`}
                    onChange={change.bind(null, `teacherScaleTexts.${i}`)}
                  />
                </Box>
              ))}
            </Box>
          )}
        />
      </Box>
    </Flex>
  );

  if (isFavouriteManager && !myFavs.length) {
    return (
      <Box my={2}>
        <Text>
          {t(
            "components.organisms.editNumericAssessmentSettingModal.noFavourites",
          )}
        </Text>
      </Box>
    );
  }
  return (
    <Form>
      <Flex my={2} flex={"auto"} flexDirection="column">
        <Select
          name="numeric-assessment-setting-select"
          value={id}
          onChange={change.bind(null, "id")}
          input={
            <Input id="assessment-name" name="assessment-name" fullWidth />
          }
        >
          {!isFavouriteManager && (
            <MenuItem value="new" divider>
              <Flex alignItems="center">
                <StyledListItemIcon>
                  <AddIcon fontSize="small" />
                </StyledListItemIcon>
                {t(
                  "components.organisms.editNumericAssessmentSettingModal.createNew",
                )}
              </Flex>
            </MenuItem>
          )}

          {!isFavouriteManager && (
            <MenuItem value="default">
              {t(
                "components.organisms.editNumericAssessmentSettingModal.omaOpsDefault",
              )}
            </MenuItem>
          )}

          <StyledListSubheader>
            {t(
              "components.organisms.editNumericAssessmentSettingModal.myFavourites",
            )}
          </StyledListSubheader>
          {myFavs.map(({ id, name, defaultSetting }, i) => (
            <MenuItem key={id} value={id}>
              <Flex alignItems="center">
                {name}
                {defaultSetting && (
                  <Box ml={2}>
                    <StyledChip
                      label={t(
                        "components.organisms.editNumericAssessmentSettingModal.defaultSettingChip",
                      )}
                    ></StyledChip>
                  </Box>
                )}
              </Flex>
            </MenuItem>
          ))}

          {!isFavouriteManager && otherFavs.length > 0 && (
            <StyledListSubheader>
              {t(
                "components.organisms.editNumericAssessmentSettingModal.otherTeacherFavourites",
              )}
            </StyledListSubheader>
          )}
          {!isFavouriteManager &&
            otherFavs.map(({ id, name }, i) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
        </Select>

        {id === "new" && (
          <FormControl fullWidth>
            <InputLabel htmlFor="name">
              {t(
                "components.organisms.editNumericAssessmentSettingModal.scaleName",
              )}
            </InputLabel>
            <Input
              name="name"
              fullWidth
              value={name}
              onChange={change.bind(null, "name")}
              helperText={touched.name && touched.name ? errors.name : ""}
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
        )}

        {id === "new" && (
          <Box>
            <StyledInputLabel>
              {t(
                "components.organisms.editNumericAssessmentSettingModal.scaleSetting",
              )}
            </StyledInputLabel>
          </Box>
        )}
        {id === "new" ? (
          <StyledRadioGroup
            aria-label="numeric scale"
            name="scale"
            value={scale + ""}
            onChange={change.bind(null, "scale")}
          >
            <Flex flexDirection="column" flex={1}>
              <Box>
                <FormControlLabel
                  value={"3"}
                  disabled={id !== "new"}
                  control={<Radio data-cy="radio-scale-3" color="primary" />}
                  label={
                    <SmallIconWrapper ml={0.5}>
                      <Flex justifyContent="center" mr={1}>
                        <FairIcon checked></FairIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <GoodIcon checked></GoodIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <ExcellentIcon checked></ExcellentIcon>
                      </Flex>
                    </SmallIconWrapper>
                  }
                />
              </Box>
              {scale === 3 && scaleInputs}
              <Box>
                <FormControlLabel
                  value={"5"}
                  disabled={id !== "new"}
                  control={<Radio data-cy="radio-scale-5" color="primary" />}
                  label={
                    <SmallIconWrapper ml={0.5} justifyContent="center">
                      <Flex justifyContent="center" mr={1}>
                        <FairIcon checked></FairIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <FairlyGoodIcon checked></FairlyGoodIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <GoodIcon checked></GoodIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <VeryGoodIcon checked></VeryGoodIcon>
                      </Flex>
                      <Flex justifyContent="center" mr={1}>
                        <ExcellentIcon checked></ExcellentIcon>
                      </Flex>
                    </SmallIconWrapper>
                  }
                />
              </Box>
              {scale === 5 && scaleInputs}
            </Flex>
          </StyledRadioGroup>
        ) : (
          <Flex>
            <Box flex={1}>
              <Box maxWidth={250}>
                <InputLabelWithoutInput>
                  {t(
                    "components.organisms.editNumericAssessmentSettingModal.learner",
                  )}
                </InputLabelWithoutInput>
              </Box>

              <Box>
                {new Array(scale).fill(null).map((a, i) => (
                  <Flex m={1} key={`l-scale-item-${i}`} alignItems="center">
                    <Box mr={2}>
                      <ScaleIcon scale={scale} scaleNumber={i} />
                    </Box>
                    <Text>{learnerScaleTexts[i]}</Text>
                  </Flex>
                ))}
              </Box>
            </Box>
            <Box ml={2} flex={1}>
              <Box maxWidth={200}>
                <InputLabelWithoutInput>
                  {t(
                    "components.organisms.editNumericAssessmentSettingModal.teacher",
                  )}
                </InputLabelWithoutInput>
              </Box>

              <Box>
                {new Array(scale).fill(null).map((a, i) => (
                  <Flex
                    alignItems="center"
                    height="43px"
                    key={`t-scale-item-${i}`}
                    my={1}
                  >
                    <Text>{teacherScaleTexts[i]}</Text>
                  </Flex>
                ))}
              </Box>
            </Box>
          </Flex>
        )}

        {isFavouriteManager && (
          <Box>
            <Checkbox
              name="defaultSetting"
              label={t(
                "components.organisms.editNumericAssessmentSettingModal.defaultSetting",
              )}
              checked={defaultSetting === true ? true : false}
              onChange={change.bind(null, "defaultSetting")}
            />
            <Box mt={1}>
              <StyledButton
                size="small"
                onClick={setShowRemoveConfirmationModal.bind(null, true)}
              >
                {t(
                  "components.organisms.editNumericAssessmentSettingModal.removeFavourite",
                )}
              </StyledButton>
            </Box>
          </Box>
        )}
      </Flex>
      {showRemoveConfirmationModal && (
        <RemoveFavouriteAssessmentSettingConfirmationModal
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </Form>
  );
};
