import data from "./customdata.json";
import { TFunction } from "i18next";

export const getLocaliseData = (t: TFunction): any => {
  let localisedData = data as any;
  Object.keys(data.emojis).forEach(k => {
    localisedData.emojis[k] = {
      ...localisedData.emojis[k],
      a: t(`components.atoms.emojiPicker.emojis.${k}` as const),
    };
  });
  return localisedData;
};
