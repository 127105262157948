import * as React from "react";
import StylesProvider from "@material-ui/styles/StylesProvider";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

interface CustomThemeProviver {
  theme: any;
  children: React.ReactNode;
}

const CustomThemeProvider: React.FunctionComponent<CustomThemeProviver> = ({
  theme,
  children,
}) => (
  <StylesProvider injectFirst>
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>
);

export default CustomThemeProvider;
