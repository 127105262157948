import { createTheme as createMuiTheme, darken, lighten } from "@material-ui/core/styles";
// @ts-ignore
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

export const WebFontConfig = {
  typekit: {
    id: "qbf6nzb",
  },
};

export const educationalPackageThemeColors = [
  "#B0486B",
  "#A15858",
  "#B06B48",
  "#A18858",
  "#B0B048",
  "#88A158",
  "#6BB048",
  "#58A158",
  "#48B06B",
  "#58A188",
  "#48B0B0",
  "#5888A1",
  "#4A69B1",
  "#5858A1",
  "#6B48B1",
  "#8858A1",
  "#B047B0",
  "#A15888",
];

const primaryShades = {
  "50": "#e0eef8",
  "100": "#b3d5ee",
  "200": "#80b9e3",
  "300": "#4d9cd7",
  "400": "#2687cf",
  "500": "#0072c6",
  "600": "#006ac0",
  "700": "#005fb9",
  "800": "#0055b1",
  "900": "#0042a4",
  A100: "#cedeff",
  A200: "#9bbcff",
  A400: "#689aff",
  A700: "#4f89ff",
};

const palette = createPalette({
  background: { default: "#FCFBFC" },
  primary: {
    light: primaryShades[100],
    main: "#0072C6",
    dark: "#174993",
    contrastText: "#fff",
  },
  secondary: {
    light: "#FE7734",
    main: "#FD4F00",
    dark: "#B90A01",
    contrastText: "#fff",
  },
  grey: {
    "50": "#f7f7f7",
    "100": "#ebebec",
    "200": "#dededf",
    "300": "#d0d0d2",
    "400": "#c6c6c9",
    "500": "#bcbcbf",
    "600": "#b6b6b9",
    "700": "#adadb1",
    "800": "#a5a5a9",
    "900": "#97979b",
    A100: "#ffffff",
    A200: "#ffffff",
    A400: "#d5d5ff",
    A700: "#bcbcff",
  },
  text: {
    primary: "#174993",
    secondary: "#6C6C6C",
  },
  error: {
    main: "#D04100",
  },
});

const spacing = 8;

export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
};

const defaultTheme: ThemeOptions = createMuiTheme({
  spacing,
  breakpoints: { values: breakpointValues },
  typography: {
    // tslint:disable-next-line: quotemark
    fontFamily: [
      '"proxima-nova-condensed"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1,
      color: palette.common.black,
      letterSpacing: "0.63px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1,
      color: palette.primary.dark,
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1,
      color: palette.common.black,
    },
    h6: {
      textTransform: "uppercase",
      fontSize: "1.1rem",
      fontWeight: 500,
      letterSpacing: "0.02rem",
      color: palette.text.secondary,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: "1.4",
      color: palette.text.secondary,
    },
    caption: {
      fontWeight: "bold",
      fontSize: "1.35rem",
      lineHeight: 1,
      color: palette.common.black,
    },
  },
  palette,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#FCFBFC",
          [`@media (min-width: ${breakpointValues.xl}px)`]: {
            backgroundColor: "#D9D9D9",
          },
        },
      },
    },
    MuiFab: {
      root: {
        fontSize: "1rem",
      },
      primary: {
        //color: palette.primary.main,
        boxShadow: "none",
        //backgroundColor: primaryShades[50],
        color: "#000",
        backgroundColor: "#ffffff80",
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: lighten("#fff", 0.45) + " !important",
          },
        },
        "&:hover": {
          backgroundColor: lighten("#fff", 0.45),
        },
      },
      secondary: {
        color: palette.primary.main,
        boxShadow: "none",
        backgroundColor: palette.grey[50],
        "@media (hover: none)": {
          backgroundColor: darken(palette.grey[50], 0.15) + " !important",
        },
        "&:hover": {
          backgroundColor: darken(palette.grey[50], 0.15),
        },
      },
    },
    MuiButton: {
      root: {
        padding: `${1.5 * spacing}px ${2 * spacing}px`,
        lineHeight: 1.2,
        fontSize: "1.35rem",
        fontWeight: "bold",
        color: palette.primary.main,
        textTransform: "uppercase",
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
      containedPrimary: {
        boxShadow: "none",
        borderRadius: 3 * spacing,
        color: palette.common.white,
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.main,
        },
      },
      containedSecondary: {
        boxShadow: "none",
        backgroundColor: primaryShades[300],
        color: palette.common.white,
        borderRadius: 3 * spacing,
        "&:hover": {
          backgroundColor: darken(primaryShades[300], 0.15),
        },
        "@media (hover: none), (pointer: coarse)": {
          "&:hover": {
            backgroundColor: darken(primaryShades[300], 0.15) + " !important",
          },
        },
      },
      outlinedPrimary: {
        boxShadow: "none",
        border: `1px solid ${palette.primary.main}`,
        borderRadius: 3 * spacing,
        backgroundColor: palette.common.white,
        color: palette.primary.main,
        "&:hover": {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
        },
      },
      outlinedSecondary: {
        boxShadow: "none",
        border: `1px solid ${palette.grey[500]}`,
        color: palette.primary.main,
        borderRadius: 3 * spacing,
        "&:hover": {
          border: `1px solid ${darken(palette.grey[500], 0.15)}`,
          backgroundColor: darken(palette.grey[500], 0.15),
        },
      },
      sizeSmall: {
        padding: "3px 8px",
        textTransform: "inherit",
        borderRadius: "5px",
        lineHeight: 1.2,
        fontSize: "1.25rem",
        color: palette.primary.main,
      },
      containedSizeSmall: {
        backgroundColor: "#E6E5EA",
        "&:hover": {
          backgroundColor: darken("#E6E5EA", 0.15),
        },
      },
    },
    MuiButtonGroup: {
      root: {
        boxShadow: "none",
        borderRadius: "5px",
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        borderRadius: "5px",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.7rem",
      },
      fontSizeSmall: {
        fontSize: "1.1rem",
      },
      fontSizeLarge: {
        fontSize: "1.875rem",
      },
    },
    MuiTab: {
      root: {
        fontSize: "1.35rem",
        fontWeight: "bold",
        selected: {
          color: palette.primary.main,
        },
        "@media (min-width: 600px)": {
          fontSize: "1.35rem",
        },
      },
      textColorPrimary: {
        color: palette.text.secondary,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: palette.primary.main,
      },
    },

    MuiChip: {
      label: {
        paddingLeft: "6px",
        paddingRight: "6px",
      },
      root: {
        backgroundColor: primaryShades[50],
        fontSize: "1.2rem",
        lineHeight: 1.2,
        height: "34px",
        borderRadius: "32px",
        padding: "0 6px",
        color: "#000",
        "&:focus": {
          backgroundColor: primaryShades[50],
        },
      },
      deletable: {
        "&:focus": {
          backgroundColor: primaryShades[50],
        },
      },
      colorPrimary: {
        backgroundColor: primaryShades[50],
        color: palette.primary.main,
        fontWeight: "bold",
        padding: "0 4px",
        fontSize: "1.2rem",
      },
      icon: {
        marginRight: "0px",
      },
      deleteIcon: {
        color: palette.primary.main,
        fontSize: "1.25rem",
      },
      colorSecondary: {
        backgroundColor: lighten(palette.secondary.light, 0.85),
        color: palette.secondary.light,
        fontWeight: "bold",
        padding: "0 4px",
        fontSize: "1.2rem",
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: "none",
        "&$selected": {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
        },
        "&$selected:hover": {
          backgroundColor: darken(palette.primary.main, 0.15) + " !important",
        },
        padding: "5px 11px",
        lineHeight: 1,
        backgroundColor: palette.grey[200],
        color: palette.primary.dark,
        border: "none",
        height: "24px",
        "&:not(:first-child)": {
          borderLeft: `1px solid ${palette.grey[100]}`,
        },
        "&:first-child": {
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        },
        "&:last-child": {
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        "&$selected": {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
        },
        backgroundColor: palette.grey[50],
        color: palette.primary.dark,
        border: "none",
        height: "24px",
        borderRadius: "5px",
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 4,
        "&:focus": {
          borderRadius: 4,
          backgroundColor: "inherit",
        },
      },
      icon: {
        top: "inherit",
        paddingRight: "2px",
        color: palette.primary.main,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: "16px",
      },
    },
    MuiInputBase: {
      root: {
        "label + &": {
          marginTop: 3 * spacing,
          paddingTop: 2 * spacing,
        },
      },
      input: {
        "&$disabled": {
          opacity: 0.5,
        },
        fontSize: "1.1rem",
        color: palette.common.black,
        borderRadius: 4,
        position: "relative",
        border: `1px solid ${palette.grey[900]}`,
        padding: "10px",
        "&:focus": {
          borderColor: palette.primary.main,
        },
      },
      inputMultiline: {
        padding: "10px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px",
      },
    },
    MuiTextField: {
      root: {
        marginTop: "5px",
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingTop: "8px",
      },
    },
    MuiFormLabel: {
      root: {
        // padding: "-1px",
        letterSpacing: "0.025rem",
        marginTop: 2 * spacing,
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: palette.common.black,
        "&$focused": {
          color: palette.primary.main,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: spacing * 2.5,
        fontSize: "1.25rem",
        fontWeight: 500,
      },
    },
    MuiFormHelperText: {
      root: {
        letterSpacing: "0.025rem",
        fontSize: "1rem",
        fontWeight: "bold",
      },
    },
    MuiCardContent: {
      root: {
        paddingTop: spacing * 2.5,
        paddingBottom: spacing * 2.5,
        paddingLeft: spacing * 2.5,
        paddingRight: spacing * 2.5,
      },
    },
    MuiCard: {
      root: {
        borderRadius: spacing * 2.5,
      },
    },
    MuiCardHeader: {
      root: {
        minHeight: "60px",
        backgroundColor: palette.grey[100],
        color: palette.primary.dark,
        padding: `${0.4 * spacing}px ${2.5 * spacing}px`,
      },
      action: {
        marginTop: "0px",
      },
    },
    MuiCardActions: {
      root: {
        borderTop: `1px solid ${palette.grey[100]}`,
        paddingLeft: spacing * 2.5,
        paddingTop: spacing * 0.5,
        paddingBottom: spacing * 0.5,
        minHeight: "60px",
      },
    },
    MuiDrawer: {
      paper: {
        background: primaryShades[100],
        width: "250px",
        boxShadow: "none",
      },
    },
    MuiLink: {
      root: {
        fontWeight: 500,
        fontSize: "1.2rem",
        lineHeight: "1.2",
        "&:hover": {
          color: lighten(palette.primary.main, 0.2),
        },
      },
      underlineHover: {
        textDecoration: "underline",
      },
    },
    MuiListItemText: {
      primary: {
        color: palette.primary.dark,
      },
    },

    MuiListItemIcon: {
      root: {
        color: primaryShades[300],
      },
    },
    MuiMenu: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        color: palette.common.black,
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.75,
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
        "&:hover": {
          backgroundColor: primaryShades[50],
        },
      },
    },
    MuiCheckbox: {
      root: {
        paddingTop: "6px",
        paddingRight: "7px",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "unset",
        boxShadow: "rgba(0,0,0,0.5) 0px 1px 2px",
        borderRadius: 3 * spacing + "px",
        border: "none",
      },
    },
    MuiTableRow: {
      root: {
        borderCollapse: "unset",
        "&:first-child th:first-child": {
          borderTopLeftRadius: 3 * spacing + "px",
        },
        "&:first-child th:last-child": {
          borderTopRightRadius: 3 * spacing + "px",
        },
        "&:last-child td:first-child": {
          borderBottomLeftRadius: 3 * spacing + "px",
        },
        "&:last-child td:last-child": {
          borderBottomRightRadius: 3 * spacing + "px",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: `${spacing * 1}px ${spacing * 2}px`,
        height: "60px",
      },
      body: {
        color: "#484848",
        fontSize: "1.25rem",
        backgroundColor: palette.common.white,
      },
      head: {
        textTransform: "uppercase",
        fontSize: "1.1rem",
        fontWeight: 500,
        letterSpacing: "0.02rem",
        color: palette.text.secondary,
        padding: spacing * 2,
        backgroundColor: palette.grey[100],
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#696969",
        fontSize: "1.1rem",
        whiteSpace: "pre-wrap",
      },
      arrow: {
        color: "#696969",
      },
    } as any,
  },
  // @ts-ignore
  custom: {
    emailLinkButtonBg: primaryShades[300],
    emailLinkButtonColor: palette.common.white,
    googleButtonBg: "#4285F4",
    googleButtonColor: palette.common.white,
  },
});

export default defaultTheme;
