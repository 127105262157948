import gql from "graphql-tag";

import {
  AddLearningAchivementMaterialsLearner,
  AddLearningAchivementMaterialsLearnerVariables,
} from "./types/AddLearningAchivementMaterialsLearner";

import { ApolloError, useMutation } from "@apollo/client";
import {
  FileInputType,
  LearningAchievementMaterialInputType,
} from "typings/graphql-global-types";

export const CREATE_OR_UPDATE_LEARNING_ACHIEVEMENT_FILES = gql`
  mutation AddLearningAchivementMaterialsLearner(
    $input: LearningAchievementMaterialInputType!
  ) {
    addLearningAchivementMaterials(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  addLearningAchivementMaterials: {
    mutation: (materials: {
      fileUploads?: FileInputType[];
      driveFiles?: FileInputType[];
      links?: string[];
    }) => Promise<void>;
    loading: boolean;
    error?: ApolloError;
  };
}

interface QueriesProps {
  activityId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, activityId }) => {
  const [addLearningAchivementMaterials, { loading, error }] = useMutation<
    AddLearningAchivementMaterialsLearner,
    AddLearningAchivementMaterialsLearnerVariables
  >(CREATE_OR_UPDATE_LEARNING_ACHIEVEMENT_FILES);

  const addMaterialToLearningAchievement = async (materials: {
    fileUploads?: FileInputType[];
    driveFiles?: FileInputType[];
    links?: string[];
  }) => {
    const input: LearningAchievementMaterialInputType = {
      activityId,
    };

    if (materials.fileUploads) {
      input.fileUploads = materials.fileUploads;
    }

    if (materials.driveFiles) {
      input.driveFiles = materials.driveFiles;
    }

    if (materials.links) {
      input.links = materials.links;
    }

    await addLearningAchivementMaterials({
      variables: { input },
    });
  };

  return children({
    addLearningAchivementMaterials: {
      mutation: addMaterialToLearningAchievement,
      loading,
      error,
    },
  });
};
