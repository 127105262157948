import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import { useParams, useHistory } from "react-router";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";
import { useTranslation } from "react-i18next";

import { EDUCATIONALPACKAGE, HOME, getRoute } from "routes";

import { Loader, Flex, Text } from "components/Atoms";
import { Modal } from "components/Molecules";

import { TabValue } from "containers/Learner/EducationalPackage/components";
import { useAnalytics } from "components/Hooks/firebase-hooks";
import {
  GetActivityRedirect,
  GetActivityRedirectVariables,
} from "./types/GetActivityRedirect";

const StyledNotificationImportantOutlined = styled(
  NotificationImportantOutlined,
)`
  color: ${props => props.theme.palette.primary.dark};
`;

const FullscreenLoader = styled(Loader)`
  height: 100vh;
  width: 100vw;
`;

const GET_ACTIVITY = gql`
  query GetActivityRedirect($filters: IdInputType!) {
    myprofile {
      data {
        id
      }
    }
    activity(filters: $filters) {
      data {
        id
        submittedLearningAchievementsCount
        educationalPackage {
          id
        }
      }
    }
  }
`;

export default () => {
  const { activityId }: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const { analytics } = useAnalytics();

  analytics.logEvent("page_view" as string, {
    page_title: "Learner_selfAssessmentFromLink",
    page_path: history.location.pathname,
  });

  const { loading, data } = useQuery<
    GetActivityRedirect,
    GetActivityRedirectVariables
  >(GET_ACTIVITY, { variables: { filters: { id: activityId } } });

  if (loading) return <FullscreenLoader />;

  const educationalPackageId =
    data &&
    data.activity.data.educationalPackage &&
    data.activity.data.educationalPackage.id;

  const activityCompleted =
    data && data.activity.data.submittedLearningAchievementsCount > 0;

  if (activityCompleted) {
    return (
      <>
        <Modal
          primaryAction={{
            text: t("globals.close"),
            onClick: () => history.replace({ pathname: HOME }),
          }}
          open={true}
          headingAction={<StyledNotificationImportantOutlined />}
          title={t("globals.confirmationModal.title")}
          height="auto"
        >
          <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
            <Text>
              {t("containers.selfAssessmentRedirect.activityReturnedModalBody")}
            </Text>
          </Flex>
        </Modal>
      </>
    );
  }
  if (educationalPackageId) {
    return (
      <Redirect
        to={`${getRoute(EDUCATIONALPACKAGE, {
          educationalPackageId,
          tab: TabValue.newActivities,
        })}?open=${activityId}`}
      />
    );
  } else {
    return <Redirect to={HOME} />;
  }
};
