import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";

import { Flex, Box, Text, GoalIcon, Button, Tooltip } from "components/Atoms";
import { EGoalsLinkedButton } from "components/Atoms/EGoalsLinkedButton";
import { EGoalInfoModal } from "components/Organisms/EGoalInfoModal";

import {
  FairIcon,
  FairlyGoodIcon,
  GoodIcon,
  VeryGoodIcon,
  ExcellentIcon,
} from "components/Atoms/Icons";

import { GetActivitiesTeacher_allActivities_data as Activity } from "../ActivityListQueries/types/GetActivitiesTeacher";

const Cell = styled(Box)<any>`
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  border-bottom: ${props =>
    props.divider && `1px solid ${props.theme.palette.grey[100]}`};

  ${props => props.theme.breakpoints.down("sm")} {
    ${props => (props.hideMobile ? "display: none" : "")};
    border-bottom: ${props =>
      props.showBorderBottomOnMobile
        ? `1px solid ${props.theme.palette.grey[100]}`
        : "none"};
  }
`;

const DescriptionText = styled(Text)`
  white-space: pre-wrap;
  overflow: hidden;
`;

const VerbalHelperTextBox = styled(Box)`
  background-color: #f4f9fd;
  border-radius: ${props => props.theme.spacing(1.5)}px;
`;

const VerbalHelperText = styled(Text)`
  white-space: pre-wrap;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 1.3rem;
`;

export interface ActivityCardContentProps {
  activity: Activity;
  onEditGoalsClick?: (() => void) | null;
  onImportFromTemplateClick?: (() => void) | null;
}

export const ActivityCardContent: React.FC<ActivityCardContentProps> = ({
  activity,
  onEditGoalsClick,
  onImportFromTemplateClick,
}) => {
  const description = activity.description;
  const goals = activity.educationalPackageGoals || [];

  const [showEGoalInfoModal, setShowEGoalInfoModal] = useState<boolean>(false);
  const [selectedEGoalIds, setSelectedEGoalIds] = useState<string[]>([]);

  const [showDescription, setShowDescription] = useState(false);

  const onShowMore = () => {
    setShowDescription(!showDescription);
  };

  const { t } = useTranslation();

  const showEGoalInfo = (eGoalIds: string[]) => {
    setSelectedEGoalIds(eGoalIds);
    setShowEGoalInfoModal(true);
  };

  const editGoalsButton = onEditGoalsClick && (
    <Tooltip
      title={t(
        "containers.teacherEducationPackage.activityCardContent.editGoals",
      )}
      arrow
    >
      <IconButton
        data-cy="edit-goals-button"
        aria-label="edit goals button"
        onClick={onEditGoalsClick}
      >
        <StyledEditIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
  return (
    <>
      <Flex pt={[2, 2, 0]} flex={1} flexDirection="column">
        <Flex>
          {goals.length === 0 && (
            <Flex px={[2.5, 2.5, 0]} flexDirection="column">
              <Text variant="h4">
                {t(
                  "containers.teacherEducationPackage.activityCardContent.goals",
                )}
              </Text>

              <Text>{t("globals.empty.noGoals")}</Text>
              {onEditGoalsClick && (
                <Box>
                  <StyledButton
                    data-cy="edit-goals-button"
                    size="small"
                    onClick={onEditGoalsClick}
                  >
                    {t(
                      "containers.teacherEducationPackage.activityCardContent.addGoal",
                    )}
                  </StyledButton>
                </Box>
              )}
              {onImportFromTemplateClick && (
                <Box>
                  <StyledButton
                    data-cy="import-from-template-button"
                    size="small"
                    onClick={onImportFromTemplateClick}
                  >
                    {t(
                      "containers.teacherEducationPackage.activityCardContent.importFromTemplateButton",
                    )}
                  </StyledButton>
                </Box>
              )}
            </Flex>
          )}
          {goals.length > 0 && (
            <Flex flex={1} flexWrap="wrap">
              <Cell
                px={[2.5, 2.5, 0]}
                width={["70%", "50%", "40%"]}
                order={1}
                flexGrow={1}
              >
                <Flex alignItems="center" height="100%">
                  <Text variant="h4">
                    {t(
                      "containers.teacherEducationPackage.activityCardContent.goals",
                    )}
                  </Text>
                  {onEditGoalsClick && <Hidden mdUp>{editGoalsButton}</Hidden>}
                </Flex>
              </Cell>
              {goals.map((g, i) => (
                <Cell
                  key={`goalname-${g.id}`}
                  px={[2.5, 2.5, 0]}
                  py={2}
                  divider={i < goals.length - 1}
                  width={["70%", "50%", "40%"]}
                  order={i + 2}
                  flexGrow={1}
                >
                  <Flex height="100%" alignItems="flex-start" pr={[0, 0, 2]}>
                    <Flex alignItems="center" pt={0.5} mr={2} height={"54px"}>
                      <GoalIcon tooltip={g.name} index={i + 1} />
                    </Flex>
                    <Flex pt={0.5} minHeight="54px" alignItems="center">
                      <Text>{g.name}</Text>
                    </Flex>
                  </Flex>
                </Cell>
              ))}

              <Cell width={["30%", "50%", "20%"]} order={1} flexGrow={1}>
                <Hidden xsDown>
                  <Flex alignItems="center" height="100%">
                    <Text variant="h4">
                      {t(
                        "containers.teacherEducationPackage.activityCardContent.opsLinking",
                      )}
                    </Text>
                  </Flex>
                </Hidden>
              </Cell>
              {goals.map((g, i) => (
                <Cell
                  key={`ops-${g.id}`}
                  py={2}
                  divider={i < goals.length - 1}
                  width={["30%", "50%", "20%"]}
                  order={i + 2}
                  flexGrow={1}
                >
                  <Flex
                    height="100%"
                    alignItems="flex-start"
                    justifyContent={["center", "flex-start"]}
                  >
                    <Tooltip
                      title={
                        g.eGoals.length > 0
                          ? t(
                              "containers.teacherEducationPackage.activityCardContent.linkedTooltip",
                            )
                          : t(
                              "containers.teacherEducationPackage.activityCardContent.notLinkedTooltip",
                            )
                      }
                      arrow
                    >
                      <Box>
                        <EGoalsLinkedButton
                          eGoalCount={g.eGoals.length}
                          onClick={() => showEGoalInfo(g.eGoals.map(e => e.id))}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Cell>
              ))}

              <Cell hideMobile width={["40%"]} order={1} flexGrow={1}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  height="100%"
                >
                  <Text variant="h4">
                    {t(
                      "containers.teacherEducationPackage.activityCardContent.assessmentScale",
                    )}
                  </Text>
                  {editGoalsButton}
                </Flex>
              </Cell>
              {goals.map((g, i) => (
                <Cell
                  key={`scale-${g.id}`}
                  py={2}
                  showBorderBottomOnMobile
                  divider={i < goals.length - 1}
                  width={["100%", "100%", "40%"]}
                  order={i + 2}
                  flexGrow={1}
                >
                  {!g.numericAssessmentSetting ? (
                    <Flex
                      pl={["71px", "71px", 0]}
                      pr={[2.5, 2.5, 0]}
                      height="100%"
                      alignItems="center"
                    >
                      <Tooltip
                        title={t(`globals.numericAssessments.self.fair`)}
                      >
                        <Box p={1}>
                          <FairIcon checked />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={t(`globals.numericAssessments.self.good`)}
                      >
                        <Box p={1}>
                          <GoodIcon checked />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={t(`globals.numericAssessments.self.excellent`)}
                      >
                        <Box p={1}>
                          <ExcellentIcon checked />
                        </Box>
                      </Tooltip>
                    </Flex>
                  ) : (
                    <Flex
                      pl={["71px", "71px", 0]}
                      pr={[2.5, 2.5, 0]}
                      height="100%"
                      alignItems="center"
                    >
                      {g.numericAssessmentSetting.scale === 3 && (
                        <>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[0]
                            }
                          >
                            <Box p={1}>
                              <FairIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[1]
                            }
                          >
                            <Box p={1}>
                              <GoodIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[2]
                            }
                          >
                            <Box p={1}>
                              <ExcellentIcon checked />
                            </Box>
                          </Tooltip>
                        </>
                      )}

                      {g.numericAssessmentSetting.scale === 5 && (
                        <>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[0]
                            }
                          >
                            <Box p={1}>
                              <FairIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[1]
                            }
                          >
                            <Box p={1}>
                              <FairlyGoodIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[2]
                            }
                          >
                            <Box p={1}>
                              <GoodIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[3]
                            }
                          >
                            <Box p={1}>
                              <VeryGoodIcon checked />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              g.numericAssessmentSetting.learnerScaleTexts[4]
                            }
                          >
                            <Box p={1}>
                              <ExcellentIcon checked />
                            </Box>
                          </Tooltip>
                        </>
                      )}
                    </Flex>
                  )}
                </Cell>
              ))}
            </Flex>
          )}
        </Flex>

        {goals.length > 0 && (
          <Flex my={[2, 2, 1]} px={[2.5, 2.5, 0]} flexDirection="column">
            <Text variant="h4">
              {t("globals.verbalAssessment.learnerHelperTextTitle")}
            </Text>
            <VerbalHelperTextBox mt={2} p={3} width={["100%", "100%", "50%"]}>
              <VerbalHelperText>
                {activity.verbalAssessmentSetting
                  ? activity.verbalAssessmentSetting.learnerHelperText
                  : t("globals.verbalAssessment.learnerHelperText")}
              </VerbalHelperText>
            </VerbalHelperTextBox>
          </Flex>
        )}

        {description && description.length > 0 && (
          <Box px={[2.5, 2.5, 0]} my={2}>
            <Flex height="46px" mb={1.25} alignItems="center">
              <Text variant="h4">
                {t(
                  "containers.teacherEducationPackage.activityCardContent.description",
                )}
              </Text>
            </Flex>
            {showDescription && (
              <Box>
                <DescriptionText>{description}</DescriptionText>
              </Box>
            )}

            <StyledButton size="small" onClick={onShowMore}>
              {!showDescription
                ? t(
                    "containers.teacherEducationPackage.activityCardContent.show",
                  )
                : t(
                    "containers.teacherEducationPackage.activityCardContent.hide",
                  )}
            </StyledButton>
          </Box>
        )}
      </Flex>
      {showEGoalInfoModal && selectedEGoalIds && (
        <EGoalInfoModal
          eGoalIds={selectedEGoalIds}
          onModalClosed={() => setShowEGoalInfoModal(false)}
        />
      )}
    </>
  );
};
