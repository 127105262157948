import i18next from "i18next";
import { getVerbalAssessment } from "./components/AssessmentCell";

import { getAssessmentScaleInfoText } from "utils/scaleHelpers";

import {
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities as Activity,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_educationalPackageGoals as Goal,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_learners as Learner,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_learningAchievements as LearningAchievement,
} from "./components/Queries/types/GetEducationalPackageAssessmentsByUser";

import { GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys as Survey } from "./components/Queries/types/GetDigipolkuSurveyGroupAnswers";

import { GetDigipolkuSurveyGroupAnswers2_digipolkuSurveyGroup2_data_surveys_surveyQuestions as SurveyQuestion2 } from "./components/Queries/types/GetDigipolkuSurveyGroupAnswers2";

import { AssessmentType, QuestionType } from "typings/graphql-global-types";

import { format } from "date-fns";

import { HeaderInput, RowInput, exportCSVFile } from "utils/csvUtils";

import { ExtendedAssessmentType } from "./index";

import { getAcademicYear } from "utils/dateHelpers";

const getNumericAssessment = (
  learner: Learner,
  goal: Goal,
  learningAchievements: LearningAchievement[],
  type: AssessmentType,
): string => {
  const learnersAchievement = learningAchievements.find(
    la => la.user && la.user.id === learner.id,
  );

  const assessment =
    learnersAchievement &&
    learnersAchievement.numericAssessments.find(
      a => a.educationalPackageGoalId === goal.id && a.type === type,
    );

  return !assessment ? "-" : assessment.value + "";
};

export const exportAssessmentsToCsv = (
  learners: Learner[],
  activities: Activity[],
  selectedAssessmentType: AssessmentType,
  educationalPackageName: string,
) => {
  const csvRows: RowInput[] = [];

  activities.forEach(activity => {
    const goals = activity.educationalPackageGoals || [];

    if (goals.length === 0) {
      // Empty goals
      const row: RowInput = {
        activityName: activity.name,
        goal: "",
        info: getAssessmentScaleInfoText(goals, "", selectedAssessmentType),
      };

      learners.forEach(({ id }) => {
        row[id] = "-";
      });
      csvRows.push(row);
    } else {
      // NumericAssessments for Goals
      goals.forEach((goal, i) => {
        const row: RowInput = {
          activityName: i === 0 ? activity.name : "",
          goal: goal.name,
          info: getAssessmentScaleInfoText(
            goals,
            goal.id,
            selectedAssessmentType,
          ),
        };

        learners.forEach(learner => {
          const value = getNumericAssessment(
            learner,
            goal,
            activity.learningAchievements,
            selectedAssessmentType,
          );
          row[learner.id] = value;
        });

        csvRows.push(row);
      });

      // NumericAssessments for Goals
      const row: RowInput = {
        activityName: "",
        goal: i18next.t(
          "components.molecules.assessmentReadOnly.verbalAssessment",
        ),
        info: "",
      };

      learners.forEach(learner => {
        const value = getVerbalAssessment(
          learner,
          activity.learningAchievements,
          selectedAssessmentType,
        );

        row[learner.id] = value?.assessmentText || "";
      });

      csvRows.push(row);
    }
  });

  const learnersHeader: HeaderInput = {};
  learners.forEach(({ id, givenName, familyName }) => {
    learnersHeader[id] = `${givenName} ${familyName}`;
  });

  const headers = {
    activityName: i18next.t(
      "containers.teacherEducationPackage.assessmentsTable.activity",
    ),
    goal: i18next.t("containers.teacherEducationPackage.assessmentsTable.goal"),
    ...learnersHeader,
    info: i18next.t("globals.csv.headerInfo"),
  };

  const date = format(new Date(), "dd.MM.yyyy");

  const assessmentTypeText =
    selectedAssessmentType === AssessmentType.self
      ? i18next.t("containers.teacherEducationPackage.selfAssessment")
      : i18next.t("containers.teacherEducationPackage.teacher");

  exportCSVFile(
    headers,
    csvRows,
    `${educationalPackageName}-${assessmentTypeText}-${date}.csv`.toLowerCase(),
  );
};

export const exportSurveyToCsv = (
  learners: Learner[],
  surveys: Survey[],
  assessmentType: ExtendedAssessmentType,
) => {
  const csvRows: RowInput[] = [];

  surveys.forEach(survey => {
    const questions = survey.surveyQuestions;

    let name = survey.name;
    if (name === "selfAssessment") {
      name = (questions as SurveyQuestion2[])[0].description ?? "";
    }
    if (assessmentType === ExtendedAssessmentType.ownGoals) {
      const academicYear = getAcademicYear(new Date(survey.createdAt));
      name = i18next.t("globals.ownGoalsSurveyTitle", {
        startYear: academicYear.startYear,
        endYear: academicYear.endYear,
        name: survey.name && survey.name.length && `: ${survey.name}`,
      });
    }

    if (questions.length === 0) {
      // Empty goals
      const row: RowInput = {
        activityName: name,
        goal: "",
        info: "",
      };

      learners.forEach(({ id }) => {
        row[id] = "-";
      });
      csvRows.push(row);
    } else {
      questions
        .filter(f => f.type === QuestionType.numeric)
        .forEach((question, i) => {
          const row: RowInput = {
            activityName: i === 0 ? name : "",
            goal: question.name,
            info: i18next.t("globals.csv.selfScale3Text"),
          };

          learners.forEach(learner => {
            const answer = question.surveyQuestionAnswers.find(
              sqa => sqa.user.id === learner.id,
            ) || { numeric: "-" };

            const value = answer.numeric ? answer.numeric + "" : "-";
            row[learner.id] = value;
          });

          csvRows.push(row);
        });

      questions
        .filter(f => f.type === QuestionType.verbal)
        .forEach((question, i) => {
          const row: RowInput = {
            activityName: "",
            goal: question.name.length
              ? question.name
              : i18next.t(
                  "components.molecules.assessmentReadOnly.verbalAssessment",
                ),
            info: "",
          };

          learners.forEach(learner => {
            const answer = question.surveyQuestionAnswers.find(
              sqa => sqa.user.id === learner.id,
            ) || { verbalText: "-" };

            row[learner.id] = answer.verbalText || "";
          });

          csvRows.push(row);
        });
    }
  });

  const learnersHeader: HeaderInput = {};
  learners.forEach(({ id, givenName, familyName }) => {
    learnersHeader[id] = `${givenName} ${familyName}`;
  });

  const headers = {
    activityName: i18next.t(
      "containers.teacherEducationPackage.assessmentsTable.activity",
    ),
    goal: i18next.t("containers.teacherEducationPackage.assessmentsTable.goal"),
    ...learnersHeader,
    info: i18next.t("globals.csv.headerInfo"),
  };

  const date = format(new Date(), "dd.MM.yyyy");

  const assessmentTypeText =
    assessmentType === ExtendedAssessmentType.digipolku
      ? i18next.t("globals.digipolku")
      : i18next.t("globals.ownGoals");

  exportCSVFile(
    headers,
    csvRows,
    `${assessmentTypeText}-${date}.csv`.toLowerCase(),
  );
};
