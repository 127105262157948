import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Modal } from "../../Molecules";
import { Box, Text } from "components/Atoms";
import MessageTimeLine, {
  CommentProps,
} from "components/Molecules/MessageTimeline";

import {
  MarkSurveyQuestionAnswerCommentAsRead,
  MarkSurveyQuestionAnswerCommentAsReadVariables,
} from "./types/MarkSurveyQuestionAnswerCommentAsRead";

export interface CommentsModalProps {
  name: string;
  comments: CommentProps[];
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

export const MARKASREAD = gql`
  mutation MarkSurveyQuestionAnswerCommentAsRead(
    $input: SurveyQuestionAnswerCommentMarkAsReadInput!
  ) {
    markSurveyQuestionAnswerCommentAsRead(input: $input) {
      data {
        id
        read
      }
    }
  }
`;

export const CommentsModal: React.FunctionComponent<CommentsModalProps> = ({
  name,
  comments,
  open,
  onModalClosed,
}) => {
  const { t } = useTranslation();

  const [markAsRead] = useMutation<
    MarkSurveyQuestionAnswerCommentAsRead,
    MarkSurveyQuestionAnswerCommentAsReadVariables
  >(MARKASREAD);

  useEffect(() => {
    const send = async () => {
      await Promise.all(
        comments
          .filter(c => !c.read)
          .map(c =>
            markAsRead({
              variables: {
                input: {
                  id: c.id,
                },
              },
            }),
          ),
      );
    };
    setTimeout(send, 2000);
  }, [comments, markAsRead]);

  const onClose = () => {
    onModalClosed(false);
  };

  return (
    <Modal
      height="550px"
      primaryAction={{
        text: t("globals.close"),
        onClick: onClose,
      }}
      title={t("components.organisms.commentsModal.title")}
      open={open}
    >
      <Box>
        <Box marginTop={2}>
          <Text variant="caption">
            {t("components.organisms.commentsModal.name")}
          </Text>
          <Text variant="body1">{name}</Text>
        </Box>

        <Box marginTop={3}>
          <Text variant="caption">
            {t("components.organisms.commentsModal.comments")}
          </Text>
          <MessageTimeLine comments={comments} />
        </Box>
      </Box>
    </Modal>
  );
};
