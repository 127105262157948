import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { GetDigipolkuSurveyGroup } from "./types/GetDigipolkuSurveyGroup";

export const GET_DIGIPOLKU = gql`
  query GetDigipolkuSurveyGroup {
    digipolkuSurveyGroup {
      data {
        id
        name
        surveys {
          id
          name
          surveyQuestions {
            id
            name
            description
            type
            survey {
              id
              name
            }
            surveyQuestionAnswer {
              id
              numeric
              verbalText
              comments {
                id
                text
                read
                user {
                  id
                  givenName
                  familyName
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  digipolku: {
    loading: boolean;
    data: GetDigipolkuSurveyGroup | undefined;
  };
}

interface Props {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<Props> = ({ children }) => {
  const { loading, /*error,*/ data } = useQuery<GetDigipolkuSurveyGroup>(
    GET_DIGIPOLKU,
  );

  return children({
    digipolku: { loading, data },
  });
};
