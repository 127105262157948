import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";
import { Box, Text } from "components/Atoms";
import { AssessmentType } from "typings/graphql-global-types";

const WrappingText = styled(Text)`
  white-space: pre-wrap;
`;

const VerbalHelperTextBox = styled(Box)`
  background-color: #f4f9fd;
  border-radius: ${props => props.theme.spacing(1.5)}px;
`;

export interface QuestionProps {
  name: string;
  answer: string;
  type: AssessmentType;
  assessor?: string;
  modalTitle?: string;
  verbalEvaluationTitle?: string;
  helperText?: string;
  learner?: string;
}
export interface verbalAssessmentModalProps {
  question: QuestionProps;
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

export const VerbalAssessmentModal: React.FunctionComponent<verbalAssessmentModalProps> = ({
  question,
  open,
  onModalClosed,
}) => {
  const { t } = useTranslation();

  const {
    name,
    answer,
    type,
    assessor,
    modalTitle,
    verbalEvaluationTitle,
    learner,
  } = question;
  const onClose = () => {
    onModalClosed(false);
  };

  return (
    <Modal
      height="600px"
      primaryAction={{
        text: t("globals.close"),
        onClick: onClose,
      }}
      title={
        modalTitle
          ? modalTitle
          : type === AssessmentType.self
          ? t("components.organisms.verbalAssessmentModal.titleLearner")
          : t("components.organisms.verbalAssessmentModal.titleTeacher")
      }
      open={open}
      onClose={onClose}
    >
      <Box>
        {learner && (
          <Box marginTop="2em">
            <Text variant="caption">{t("globals.learnerName")}</Text>
            <Text variant="body1">{learner}</Text>
          </Box>
        )}
        <Box marginTop="2em">
          <Text variant="caption">
            {t("components.organisms.verbalAssessmentModal.activityName")}
          </Text>
          <Text variant="body1">
            {name === "selfAssessment"
              ? t("containers.digipolku.surveyForm.selfAssessment")
              : name}
          </Text>
        </Box>
        <Box marginTop="2em">
          <Text variant="caption">
            {verbalEvaluationTitle
              ? verbalEvaluationTitle
              : t(
                  "components.organisms.verbalAssessmentModal.verbalEvaluation",
                )}
          </Text>
          <WrappingText variant="body1">{answer}</WrappingText>
        </Box>
        {assessor && (
          <Box marginTop="2em">
            <Text variant="caption">
              {t("components.organisms.verbalAssessmentModal.assessor")}
            </Text>
            <Text variant="body1">{assessor}</Text>
          </Box>
        )}

        {question.helperText && (
          <Box display="flex" flexDirection="column" mt={2} mb={1}>
            <Text variant="caption">
              {t("globals.verbalAssessment.learnerHelperTextTitle")}
            </Text>

            <VerbalHelperTextBox mt={2} p={3}>
              <WrappingText>{question.helperText}</WrappingText>
            </VerbalHelperTextBox>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
