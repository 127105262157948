export const HOME = "/";
export const REDIRECT = "/login";
export const LOGIN = "/login";
export const NOTFOUND = "/404";
export const TERMS = "/terms";
export const EDUCATIONALPACKAGE =
  "/educationalpackages/:educationalPackageId/:tab?";
export const SETTINGS = "/settings/:tab?";
export const SETTINGS_ROOT = "/settings";
export const DIGIPOLKU = "/digipolku";
export const DIGIPOLKU2 = "/digipolku2";
export const OWNGOALS = "/omat-tavoitteet";

export const SELFASSESSMENT_REDIRECT = "/activities/:activityId";
interface RouteParams {
  [key: string]: string;
}

export const getRoute = (route: string, params: RouteParams) =>
  Object.keys(params)
    .map(k => ({ key: k, value: params[k] }))
    .reduce(
      (route: string, param: { key: string; value: string }) =>
        route.replace(new RegExp(":" + param.key + "[?]?", "g"), param.value),
      route,
    );
