import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { Flex, Box, Text } from "components/Atoms";

const StyledText = styled(Text)`
  color: #fff;
  width: 100%;
  font-weight: 600;
  -webkit-text-stroke: thin black;
`;

const TruncatedStyledText = styled(StyledText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledMapOutlinedIcon = styled(MapOutlinedIcon)`
  margin-top: -3px;
  fill: #fff;
  color: #fff;
  stroke: black;
  stroke-width: 0.5px;
`;

const Background = styled(Flex)<{ src: string }>`
  background: url("/${props => props.src}") no-repeat center center;
  background-size: cover;
  overflow: auto;
`;

interface Props {
  gradeNumber?: number;
}

export const Hero: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const image = React.useMemo(() => {
    switch (props.gradeNumber) {
      case 3:
      case 4:
        return "saaristo1900x297.jpg";
      case 5:
      case 6:
        return "skeittipuisto1900x297.jpg";
      case 7:
      case 8:
      case 9:
        return "kaupunki1900x297.jpg";
      default:
        return "maatila1900x297.jpg";
    }
  }, [props.gradeNumber]);

  return (
    <Background minHeight={["170px", "170px", "200px", "250px"]} src={image}>
      <Flex px={[2, 3, 6]} pl={[3, 3]} py={[2, 6]} flex={1}>
        <Flex alignItems="center">
          <StyledMapOutlinedIcon />
          <Box width={["200px", "400px", "600px"]} ml={1.5}>
            <TruncatedStyledText variant="h3">
              {t("globals.digipolku2")}
            </TruncatedStyledText>
          </Box>
        </Flex>
      </Flex>
    </Background>
  );
};
