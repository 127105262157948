import React from "react";

export const GoogleDriveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
    >
      <path
        fill="#0072C6"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M6.71 1.5L.15 13l3.43 6 6.55-11.5-3.42-6zM8.73 13L5.3 19h13.12l3.43-6H8.73zm12.55-1L14.42 0H7.57l6.86 12h6.85z"
        transform="translate(-477 -925) translate(477 925)"
      ></path>
    </svg>
  );
};
