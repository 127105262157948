import * as React from "react";

import styled from "styled-components";

import {
  default as MuiInputLabel,
  InputLabelProps as MuiInputLabelProps,
} from "@material-ui/core/InputLabel";

import {
  default as MuiInputBase,
  InputBaseProps as MuiInputBaseProps,
} from "@material-ui/core/InputBase";

import {
  default as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";

import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  default as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@material-ui/core/Select";

import FormHelperText from "@material-ui/core/FormHelperText";

// InputLabel

export type InputLabelProps = MuiInputLabelProps;

export const InputLabel: React.ComponentType<InputLabelProps> = styled(
  (props: MuiInputLabelProps) => <MuiInputLabel {...props} />,
)``;

InputLabel.defaultProps = {
  shrink: true,
};
InputLabel.displayName = "InputLabel";

// InputBase

export type InputBaseProps = {
  helperText?: string;
} & MuiInputBaseProps;

export const Input: React.ComponentType<InputBaseProps> = styled(
  ({ helperText, ...rest }: InputBaseProps) => (
    <>
      <MuiInputBase {...rest} data-cy={`${rest.name}-input`} />
      {helperText && (
        <FormHelperText error={rest.error}>{helperText}</FormHelperText>
      )}
    </>
  ),
)``;

Input.displayName = "InputLabel";

// MenuItem

export type MenuItemProps = MuiMenuItemProps;

export const MenuItem: React.ComponentType<MenuItemProps> = styled(
  MuiMenuItem,
)<MenuItemProps>``;

MenuItem.displayName = "MenuItem";

// Select

export type SelectProps = MuiSelectProps;

export const Select: React.ComponentType<SelectProps> = styled(
  (props: SelectProps) => (
    <MuiSelect
      {...props}
      IconComponent={props => (
        <ExpandMore fontSize="large" color="primary" {...props}></ExpandMore>
      )}
    />
  ),
)``;

Select.displayName = "Select";
