import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import { Flex, Box } from "../../Atoms";

const StyledButton = styled(Button)`
  width: 300px;
` as typeof Button;

const StyledAddIcon = styled(AddIcon)`
  && {
    font-size: 1.2rem;
  }
`;

const FileInput = styled.input`
  display: none;
`;
interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileUploadButton: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <FileInput
        accept="*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={onChange}
      />
      <label htmlFor="contained-button-file">
        <StyledButton
          component={"span"}
          variant="contained"
          color="secondary"
          aria-label="Upload file"
        >
          <Flex alignItems="center">
            <Box display="flex" mr={1}>
              <StyledAddIcon />
            </Box>
            <Box> {t("components.molecules.fileUploadButton.label")}</Box>
          </Flex>
        </StyledButton>
      </label>
    </Flex>
  );
};
