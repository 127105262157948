import { ApolloError, useMutation, useQuery, SingleExecutionResult } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  GetActivityEditGoals_activity_data as Activity,
  GetActivityEditGoals_defaultVerbalAssessmentSetting_data as DefaultVerbalAssessmentSetting,
  GetActivityEditGoals,
  GetActivityEditGoalsVariables,
} from "./types/GetActivityEditGoals";

import {
  SetActivityEducationalPackageGoals,
  SetActivityEducationalPackageGoalsVariables,
} from "./types/SetActivityEducationalPackageGoals";

import { EducationalPackageGoalInput } from "typings/graphql-global-types";

export const UPDATE_ACTIVITY_GOALS = gql`
  mutation SetActivityEducationalPackageGoals(
    $input: ActivitySetGoalsInputType!
  ) {
    setActivityEducationalPackageGoals(input: $input) {
      data {
        id
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            name
            scale
            learnerScaleTexts
            teacherScaleTexts
            favourite
          }
        }
        verbalAssessmentSetting {
          id
          name
          learnerHelperText
          favourite
        }
      }
    }
  }
`;

export const GET_ACTIVITY_GOALS = gql`
  query GetActivityEditGoals($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        educationalPackageGoals {
          id
          name
          hasAssessments
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            name
            scale
            learnerScaleTexts
            teacherScaleTexts
            favourite
          }
        }
        verbalAssessmentSetting {
          id
          name
          learnerHelperText
          favourite
        }
      }
    }
    defaultNumericAssessmentSetting {
      data {
        id
      }
    }
    defaultVerbalAssessmentSetting {
      data {
        id
        learnerHelperText
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activity: {
    loading: boolean;
    data: Activity | null;
    defaultNumericAssessmentSettingId: string | undefined | null;
    defaultVerbalAssessmentSetting:
      | DefaultVerbalAssessmentSetting
      | undefined
      | null;
  };
  update: {
    setGoals: (
      goals: EducationalPackageGoalInput[],
      verbalAssessmentSettingId: string | undefined | null,
    ) => Promise<SingleExecutionResult<SetActivityEducationalPackageGoals>>;
    error?: ApolloError;
    loading?: boolean;
  };
}

interface QueriesProps {
  activityId: string;
  educationalPackageId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  activityId,
  educationalPackageId,
}) => {
  const { loading: activityLoading, data: activityData } = useQuery<
    GetActivityEditGoals,
    GetActivityEditGoalsVariables
  >(GET_ACTIVITY_GOALS, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        id: activityId,
      },
    },
  });

  const [
    setActivityGoals,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    SetActivityEducationalPackageGoals,
    SetActivityEducationalPackageGoalsVariables
  >(UPDATE_ACTIVITY_GOALS);

  const setGoals = (
    goals: EducationalPackageGoalInput[],
    verbalAssessmentSettingId: string | undefined | null,
  ) => {
    return setActivityGoals({
      variables: {
        input: {
          id: activityId,
          educationalPackageGoals: goals,
          verbalAssessmentSettingId,
        },
      },
    });
  };

  return children({
    activity: {
      loading: activityLoading,
      data: activityData ? activityData.activity.data : null,
      defaultNumericAssessmentSettingId:
        activityData &&
        activityData.defaultNumericAssessmentSetting &&
        activityData.defaultNumericAssessmentSetting.data &&
        activityData.defaultNumericAssessmentSetting.data.id,
      defaultVerbalAssessmentSetting:
        activityData &&
        activityData.defaultVerbalAssessmentSetting &&
        activityData.defaultVerbalAssessmentSetting.data,
    },
    update: { setGoals, error: updateError, loading: updateLoading },
  });
};
