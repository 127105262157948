import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, InputLabel } from "../../../Atoms";
import { Suggestion } from "../../../Molecules/MultiChipSelect";
import { UserSelect } from "../../../Organisms";
import useBeforeUnload from "components/Hooks/use-before-unload";

import { Role } from "./../../../../typings/graphql-global-types";

const Form = styled.form`
  width: 100%;
`;

interface Props extends FormikProps<any> {}

export const MemberForm: React.FC<Props> = ({
  values: { teachers, learners },
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const teachersChanged = (items: Suggestion[]) => {
    setFieldValue("teachers", items);
    setFieldTouched("teachers", true, false);
  };

  const learnersChanged = (items: Suggestion[]) => {
    setFieldValue("learners", items);
    setFieldTouched("learners", true, false);
  };

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <InputLabel htmlFor="teachers">
            {t("components.organisms.educationPackageMemberForm.teachers")}
          </InputLabel>
          <UserSelect
            id="teachers"
            name="teachers"
            role={Role.teacher}
            selected={teachers}
            onChange={teachersChanged}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="learners">
            {t("components.organisms.educationPackageMemberForm.learners")}
          </InputLabel>
          <UserSelect
            id="learners"
            name="learners"
            role={Role.learner}
            selected={learners}
            onChange={learnersChanged}
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
