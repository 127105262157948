import * as React from "react";

import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { LocationDescriptor } from "history";

import {
  default as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";

export type ButtonProps = MuiButtonProps;

export const Button: React.FC<ButtonProps> = styled(MuiButton)<ButtonProps>``;

Button.displayName = "Button";

export type ButtonLinkProps = {
  to: LocationDescriptor;
} & MuiButtonProps;

const CollisionLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, "innerRef">
>((props, ref) => <Link innerRef={ref} {...props} />);

export const ButtonLink: React.FC<ButtonLinkProps> = styled(
  (props: ButtonLinkProps) => (
    <MuiButton {...props} component={CollisionLink as any}>
      {props.children}
    </MuiButton>
  ),
)``;

ButtonLink.displayName = "ButtonLink";
