import React from "react";

import { Flex, Text } from "components/Atoms";

import { Cell } from "./index";

import { GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup2";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
}

const GoalColumn = ({ numericQuestions, width }: Props) => {
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}></Cell>
      {numericQuestions.map((g, i) => (
        <Cell
          key={`goalname-${g.id}`}
          py={2}
          divider={i < numericQuestions.length - 1}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex height="100%" alignItems="flex-start" pr={[0, 0, 2]}>
            <Flex pt={0.5} minHeight="54px" alignItems="center">
              <Text>{g.name}</Text>
            </Flex>
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default GoalColumn;
