import * as React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { Box, Flex, Tooltip } from "../../Atoms";

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #d1d0d3;
  background-color: #f1f0f4;
  width: 300px;
  font-weight: 400;
`;

export interface DropdownOptionItem {
  id: string | number;
  label: React.ReactNode;
  disabled?: boolean;
  value?: any;
  tooltip?: string;
}
export interface DropdownMenuProps {
  options: DropdownOptionItem[];
  onSelect: (option: DropdownOptionItem) => void;
  component?: React.ReactElement<any> | React.ReactText;
  name?: string;
}

const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
  e.persist();
  e.nativeEvent.stopImmediatePropagation();
  e.stopPropagation();
};

export const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({
  name,
  options,
  onSelect,
  component,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    stopPropagation(e);
    setAnchorEl(e.currentTarget);
  }

  const handleClickAway = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorEl) {
      e.nativeEvent && e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      setAnchorEl(null);
    }
  };

  function handleCloseMenu(e: React.MouseEvent<HTMLElement>) {
    stopPropagation(e);
    setAnchorEl(null);
  }

  function handleClose(
    e: React.MouseEvent<HTMLElement>,
    option: DropdownOptionItem,
  ) {
    stopPropagation(e);
    onSelect(option);
    setAnchorEl(null);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Flex>
        {component ? (
          React.cloneElement(component as React.ReactElement<any>, {
            onClick: handleClick,
          })
        ) : (
          <IconButton
            data-cy="context-menu-button"
            aria-label="More"
            aria-controls="More-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <StyledMenu
          data-cy={`context-menu-${name}`}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={open}
          onClose={handleCloseMenu}
        >
          {options.map((option, i) =>
            option.tooltip ? (
              <Tooltip
                key={option.id}
                title={option.tooltip}
              >
                <Box>
                  <StyledMenuItem
                    data-cy={`context-menu-item-${i}`}
                    key={option.id}
                    disabled={option.disabled}
                    onClick={e => handleClose(e, option)}
                  >
                    {option.label}
                  </StyledMenuItem>
                </Box>
              </Tooltip>
            ) : (
              <StyledMenuItem
                data-cy={`context-menu-item-${i}`}
                key={option.id}
                disabled={option.disabled}
                onClick={e => handleClose(e, option)}
              >
                {option.label}
              </StyledMenuItem>
            ),
          )}
        </StyledMenu>
      </Flex>
    </ClickAwayListener>
  );
};
