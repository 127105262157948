import React, { useState, useRef } from "react";

import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { Formik, FormikProps, FormikHelpers as FormikActions } from "formik";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import {
  Loader,
  GoalIcon,
  Flex,
  Box,
  InputLabel,
  Input,
  NumericAssessmentInput,
  Tooltip,
  Text,
  FloatableButton,
} from "components/Atoms";

import { EGoalsLinkedButton } from "components/Atoms/EGoalsLinkedButton";

import { useSnackbar } from "components/Contexts/SnackbarContext";
import {
  AssessmentValueScale3,
  AssessmentValueScale5,
} from "components/Atoms/NumericAssessmentInput";

import { EGoalInfoModal } from "components/Organisms/EGoalInfoModal";

import { Queries } from "../Queries";
import useBeforeUnload from "components/Hooks/use-before-unload";

import EmojiPicker from "components/Atoms/EmojiPicker";

import {
  AssessmentType,
  NumericAssessmentInput as NumericAssessmentInputProp,
} from "typings/graphql-global-types";

import { GetAchievementAssessment_learningAchievement_data_numericAssessments as NumericAssessment } from "../Queries/types/GetAchievementAssessment";

import { GetAssessmentActivityGoals_activity_data_educationalPackageGoals as Goal } from "../Queries/types/GetAssessmentActivityGoals";

const Cell = styled(Box)<any>`
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  border-bottom: ${props =>
    props.divider && `1px solid ${props.theme.palette.grey[100]}`};

  ${props => props.theme.breakpoints.down("sm")} {
    ${props => (props.hideMobile ? "display: none" : "")};
    border-bottom: ${props =>
      props.showBorderBottomOnMobile
        ? `1px solid ${props.theme.palette.grey[100]}`
        : "none"};
  }
`;

const VerbalHelperText = styled(Text)`
  white-space: pre-wrap;
`;

const FormSavingLoader = styled(Loader)`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 1.3rem;
`;

interface NumericAssessmentProp
  extends Omit<NumericAssessmentInputProp, "value"> {
  value: number | null;
  name: string;
}
export interface FormValues {
  numericAssessments: NumericAssessmentProp[];
  verbalAssessment: string;
}

interface Props {
  type: AssessmentType;
  activityId: string;
  userId: string;
  onEditGoalsClick: () => void;
}

const StyledForm = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexFormControl = styled(FormControl)`
  flex: auto;
`;

const StyledInputLabel = styled(InputLabel)`
  color: ${props => props.theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 400;
`;

const VerbalInputLabel = styled(StyledInputLabel)`
  position: inherit;
  margin-top: 0;
`;

const StyledInput = styled(Input)`
  min-width: 250px;
  width: 100%;
  max-width: 450px;
  textarea {
    background-color: #fff;
    line-height: 1.6rem;
  }
`;

const EmojiButtonBox = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 0px;
`;

const EmojiInputBox = styled(Box)`
  position: relative;
`;

function getAssessmentValue(a?: NumericAssessment | null) {
  if (a) return a.value;
  return null;
}

export function getNumericAssessmentsInGoalOrder(
  numericAssessments: NumericAssessment[],
  goals: Goal[],
) {
  return goals.map(({ id, name }) => ({
    educationalPackageGoalId: id,
    name,
    value: getAssessmentValue(
      numericAssessments.find(ass => ass.educationalPackageGoalId === id),
    ),
  }));
}

export const Form: React.FC<Props> = ({
  activityId,
  userId,
  type,
  onEditGoalsClick,
}) => {
  const { showSnackbar } = useSnackbar();

  const { t } = useTranslation();
  return (
    <Queries activityId={activityId} userId={userId}>
      {({
        achievement: { loading: loadingAchievement, data: achievementData },
        activity: { loading: loadingActivity, data: activityData },
        assessment: { update, error, loading: savingAssessment },
      }) => {
        const verbalAssessment =
          achievementData &&
          achievementData.verbalAssessments.find(a => a.type === type);

        const numericAssessments =
          achievementData &&
          achievementData.numericAssessments.filter(a => a.type === type);

        const initialValues = {
          numericAssessments: getNumericAssessmentsInGoalOrder(
            numericAssessments || [],
            (activityData && activityData.educationalPackageGoals) || [],
          ),
          verbalAssessment:
            verbalAssessment && verbalAssessment.assessmentText
              ? verbalAssessment.assessmentText
              : "",
        };

        if (loadingAchievement || loadingActivity)
          return (
            <Flex my={5} justifyContent="center">
              <Loader />
            </Flex>
          );

        return (
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              verbalAssessment: Yup.string().max(
                400,
                t("globals.validations.assessmentMax"),
              ),
            })}
            onSubmit={async (
              values: FormValues,
              actions: FormikActions<FormValues>,
            ) => {
              actions.setSubmitting(true);

              const numericAssessments = values.numericAssessments.map(a => ({
                educationalPackageGoalId: a.educationalPackageGoalId,
                value: a.value,
              }));

              const result = await update(numericAssessments, {
                assessmentText: values.verbalAssessment,
              });
              actions.setSubmitting(false);
              actions.setTouched({});

              showSnackbar({
                id: result.data!.createOrPatchLearningAchievement.data!.id,
                type: "success",
                message: t("components.organisms.assessmentForm.saveSuccess"),
              });
            }}
            render={(props: FormikProps<FormValues>) => (
              <AssessmentForm
                onEditGoalsClick={onEditGoalsClick}
                verbalAssessmentHelperText={
                  activityData &&
                  activityData.verbalAssessmentSetting &&
                  activityData.verbalAssessmentSetting.learnerHelperText
                }
                goals={
                  (activityData && activityData.educationalPackageGoals) || []
                }
                {...props}
                type={type}
              />
            )}
          />
        );
      }}
    </Queries>
  );
};

interface AssessmentFormProps extends FormikProps<FormValues> {
  type: AssessmentType;
  verbalAssessmentHelperText: string | null;
  goals: Goal[];
  onEditGoalsClick?: () => void;
}

const getAssessmentScale = (
  goals: Goal[] | null,
  goalId: string,
): number | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting) {
    return g.numericAssessmentSetting.scale;
  }
  return null;
};

const getAssessmentText = (
  goals: Goal[] | null,
  goalId: string,
  type: AssessmentType,
): string[] | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting && type === AssessmentType.self) {
    return g.numericAssessmentSetting.learnerScaleTexts;
  }
  if (g && g.numericAssessmentSetting && type === AssessmentType.teacher) {
    return g.numericAssessmentSetting.teacherScaleTexts;
  }
  return null;
};

export const AssessmentForm: React.FC<AssessmentFormProps> = ({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  handleChange,
  setFieldValue,
  isValid,
  isSubmitting,
  type,
  verbalAssessmentHelperText,
  goals,
  onEditGoalsClick,
}) => {
  const { t } = useTranslation();

  const verbalInputRef = useRef();
  const [showEGoalInfoModal, setShowEGoalInfoModal] = useState<boolean>();
  const [selectedEGoalIds, setSelectedEGoalIds] = useState<
    string[] | undefined
  >();

  const verbalAssessmentHelperTooltipText =
    type === AssessmentType.teacher
      ? t("components.organisms.assessmentForm.teacherVerbalAssessmentTooltip")
      : verbalAssessmentHelperText
      ? verbalAssessmentHelperText
      : t("globals.verbalAssessment.learnerHelperText");

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const showEGoalInfo = (eGoalIds: string[]) => {
    setSelectedEGoalIds(eGoalIds);
    setShowEGoalInfoModal(true);
  };

  const onAssessmentChanged = (
    index: number,
    value: AssessmentValueScale3 | AssessmentValueScale5 | null,
  ) => {
    setFieldValue(`numericAssessments.${index}.value`, value);
    setFieldTouched(`numericAssessments`, true, false);
  };

  const editGoalsButton = onEditGoalsClick && (
    <Tooltip title={t("components.organisms.assessmentForm.editGoals")} arrow>
      <IconButton
        data-cy="edit-goals-button"
        aria-label="edit goals button"
        onClick={onEditGoalsClick}
      >
        <StyledEditIcon color="primary" />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <StyledForm>
        {isSubmitting && <FormSavingLoader />}
        <Flex mt={2} flexDirection="column">
          <Flex>
            {goals.length > 0 && (
              <Flex flex={1} flexWrap="wrap">
                <Cell width={["70%", "50%", "40%"]} order={1} flexGrow={1}>
                  <Flex alignItems="center" height="100%">
                    <StyledInputLabel>
                      {t("components.organisms.assessmentForm.goals")}
                    </StyledInputLabel>
                    {onEditGoalsClick && (
                      <Hidden mdUp>{editGoalsButton}</Hidden>
                    )}
                  </Flex>
                </Cell>
                {goals.map((g, i) => (
                  <Cell
                    key={`goalname-${g.id}`}
                    py={2}
                    divider={i < goals.length - 1}
                    width={["70%", "50%", "40%"]}
                    order={i + 2}
                    flexGrow={1}
                  >
                    <Flex height="100%" alignItems="flex-start" pr={[0, 0, 2]}>
                      <Flex alignItems="center" pt={0.5} mr={2} height={"54px"}>
                        <GoalIcon tooltip={g.name} index={i + 1} />
                      </Flex>
                      <Flex pt={0.5} minHeight="54px" alignItems="center">
                        <Text>{g.name}</Text>
                      </Flex>
                    </Flex>
                  </Cell>
                ))}

                <Cell width={["30%", "50%", "20%"]} order={1} flexGrow={1}>
                  <Hidden xsDown>
                    <Flex alignItems="center" height="100%">
                      <StyledInputLabel>
                        {t("components.organisms.assessmentForm.opsLinking")}
                      </StyledInputLabel>
                    </Flex>
                  </Hidden>
                </Cell>

                {goals.map((g, i) => (
                  <Cell
                    key={`ops-${g.id}`}
                    py={2}
                    divider={i < goals.length - 1}
                    width={["30%", "50%", "20%"]}
                    order={i + 2}
                    flexGrow={1}
                  >
                    <Flex
                      height="100%"
                      alignItems="flex-start"
                      justifyContent={["center", "flex-start"]}
                    >
                      <Tooltip
                        title={
                          g.eGoals.length > 0
                            ? t(
                                "components.organisms.assessmentForm.linkedTooltip",
                              )
                            : t(
                                "components.organisms.assessmentForm.notLinkedTooltip",
                              )
                        }
                        arrow
                      >
                        <Box>
                          <EGoalsLinkedButton
                            eGoalCount={g.eGoals.length}
                            onClick={() =>
                              showEGoalInfo(g.eGoals.map(e => e.id))
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Cell>
                ))}

                <Cell hideMobile width={["40%"]} order={1} flexGrow={1}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                  >
                    <StyledInputLabel htmlFor="numericAssessments">
                      {t("components.organisms.assessmentForm.assessmentScale")}
                    </StyledInputLabel>
                    {editGoalsButton}
                  </Flex>
                </Cell>
                {goals.map((g, i) => {
                  const assessment = values.numericAssessments.find(
                    ns => ns.educationalPackageGoalId === g.id,
                  );
                  if (!assessment) return null;
                  return (
                    <Cell
                      key={`input-${g.id}`}
                      py={2}
                      showBorderBottomOnMobile
                      divider={i < goals.length - 1}
                      width={["100%", "100%", "40%"]}
                      order={i + 2}
                      flexGrow={1}
                    >
                      <Flex
                        pl={["47px", "47px", 0]}
                        pr={[2.5, 2.5, 0]}
                        height="100%"
                        alignItems="center"
                      >
                        <NumericAssessmentInput
                          type={type}
                          scale={getAssessmentScale(
                            goals,
                            assessment.educationalPackageGoalId,
                          )}
                          scaleTexts={getAssessmentText(
                            goals,
                            assessment.educationalPackageGoalId,
                            type,
                          )}
                          name={`numericAssessments.${i}.value`}
                          key={assessment.educationalPackageGoalId}
                          value={assessment.value ? assessment.value : null}
                          onChange={value => onAssessmentChanged(i, value)}
                        ></NumericAssessmentInput>
                      </Flex>
                    </Cell>
                  );
                })}
              </Flex>
            )}
          </Flex>

          <Flex mt={[2, 2, 1]} flexDirection="column">
            <FlexFormControl>
              <VerbalInputLabel htmlFor="verbalAssessment">
                {type === AssessmentType.teacher &&
                  t(
                    "components.organisms.assessmentForm.verbalAssessmentTeacher",
                  )}
                {type === AssessmentType.self &&
                  t("components.organisms.assessmentForm.verbalAssessmentSelf")}
              </VerbalInputLabel>
              <Flex flexWrap="wrap">
                <EmojiInputBox
                  flex={1}
                  minWidth="250px"
                  width="100px"
                  maxWidth="450px"
                >
                  <StyledInput
                    inputRef={verbalInputRef}
                    id="verbalAssessment"
                    name="verbalAssessment"
                    placeholder={t(
                      "components.organisms.assessmentForm.verbalAssessmentPlaceholder",
                    )}
                    multiline
                    rows={4}
                    helperText={
                      touched.verbalAssessment
                        ? (errors.verbalAssessment as string)
                        : ""
                    }
                    error={
                      touched.verbalAssessment &&
                      Boolean(errors.verbalAssessment)
                    }
                    value={values.verbalAssessment}
                    onChange={change.bind(null, "verbalAssessment")}
                    fullWidth
                  />
                  <EmojiButtonBox>
                    <EmojiPicker
                      onSelect={e => {
                        const start = (verbalInputRef.current! as HTMLTextAreaElement)
                          .selectionStart;
                        const end = (verbalInputRef.current! as HTMLTextAreaElement)
                          .selectionEnd;

                        let newText =
                          values.verbalAssessment.substring(0, start) +
                          e.native +
                          values.verbalAssessment.substring(end);

                        setFieldValue(`verbalAssessment`, newText);
                      }}
                    />
                  </EmojiButtonBox>
                </EmojiInputBox>
                <Box pt={1} ml={[1, 1, 2]} maxWidth={["80%", "80%", "40%"]}>
                  <VerbalHelperText>
                    {verbalAssessmentHelperTooltipText}
                  </VerbalHelperText>
                </Box>
              </Flex>
            </FlexFormControl>
          </Flex>
        </Flex>
        <Flex mt={[1, 1, 8]} flex={1} justifyContent="flex-end">
          <FloatableButton
            data-cy="assessment-form-submit-button"
            onClick={() => handleSubmit()}
            disabled={!isValid || isSubmitting}
          >
            {t("components.organisms.assessmentForm.saveAssessment")}
          </FloatableButton>
        </Flex>
      </StyledForm>
      {showEGoalInfoModal && selectedEGoalIds && (
        <EGoalInfoModal
          eGoalIds={selectedEGoalIds}
          onModalClosed={() => setShowEGoalInfoModal(false)}
        />
      )}
    </>
  );
};
