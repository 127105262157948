import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import {
  Flex,
  Box,
  Text,
  InputLabel,
  Input,
  Select,
  MenuItem,
  ColorThemeInput,
} from "../../../Atoms";
import { MultiChipSelect } from "../../../Molecules";

import { Suggestion } from "../../../Molecules/MultiChipSelect";
import useBeforeUnload from "components/Hooks/use-before-unload";

import { GetAllGradeLevelsAndSubjects_allGradeLevels_data as GradeLevel } from "../Queries/types/GetAllGradeLevelsAndSubjects";
import { GetClassroomCourses_listClassroomCourses_data as ClassroomCourse } from "../Queries/types/GetClassroomCourses";

import { ImportedEducationalPackageCreateInputType } from "typings/graphql-global-types";

import firstNumber from "utils/firstNumber";

const Form = styled.form`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  position: relative;
  margin-top: 0;
`;

const DarkText = styled(Text)`
  color: #000;
`;

interface ImportedClassroomPackage
  extends ImportedEducationalPackageCreateInputType {
  name?: string;
}

interface Props extends FormikProps<ImportedClassroomPackage> {
  subjects: Suggestion[];
  gradeLevels: GradeLevel[];
  classroomCourses: ClassroomCourse[];
  isEdit: boolean;
}

export const ClassroomImportedForm: React.FC<Props> = ({
  values: { name, gradeLevelId, schoolSubjects, classroomId, colorTheme },
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  gradeLevels,
  subjects,
  classroomCourses,
  isEdit,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
    if (name === "gradeLevelId") {
      const gr = gradeLevels.find(gl => gl.id === e.target.value);
      const isLukio = gr && (gr.name === "Lukio" || gr.name === "Gymnasiet");
      if (isLukio) {
        setFieldValue("schoolSubjects", []);
      }
    }
  };

  const subjectsChanged = (subjects: Suggestion[]) => {
    setFieldValue(
      "schoolSubjects",
      subjects
        .filter((s, i, arr) => arr.map(o => o.id).indexOf(s.id) === i)
        .map(value => ({ id: value.id, name: value.name })),
    );

    setFieldTouched("schoolSubjects", true, false);
  };

  const colorThemeChange = (value: string) => {
    setFieldValue("colorTheme", value === "none" ? null : value);
    setFieldTouched("colorTheme", true, false);
  };

  const gr = gradeLevels.find(gl => gl.id === gradeLevelId);
  const isLukio = gr && (gr.name === "Lukio" || gr.name === "Gymnasiet");

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        {!isEdit ? (
          <FormControl>
            <InputLabel htmlFor="classroomId">
              {t("components.organisms.educationPackageForm.name")}
            </InputLabel>
            <Select
              value={classroomId}
              onChange={change.bind(null, "classroomId")}
              input={<Input name="classroomId" id="classroomId" />}
            >
              {classroomCourses.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl>
            <InputLabel htmlFor="name">
              {t("components.organisms.educationPackageForm.name")}
            </InputLabel>
            <Input
              id="name"
              name="name"
              disabled
              value={name}
              onChange={change.bind(null, "name")}
              fullWidth
            />
          </FormControl>
        )}
        <FormControl>
          <InputLabel htmlFor="gradeLevelId">
            {t("components.organisms.educationPackageForm.gradeLevel")}
          </InputLabel>
          <Select
            value={gradeLevelId}
            onChange={change.bind(null, "gradeLevelId")}
            input={<Input name="gradeLevelId" id="gradeLevelId" />}
          >
            {gradeLevels.length > 0 &&
              [...gradeLevels]
                .sort((a, b) => firstNumber(a.name) - firstNumber(b.name))
                .map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        {isLukio ? (
          <Box py={0.5}>
            <DarkText>
              {t("components.organisms.educationPackageForm.lukioDisabledText")}
            </DarkText>
          </Box>
        ) : (
          <FormControl>
            <InputLabel htmlFor="subjects">
              {t("components.organisms.educationPackageForm.subjects")}
            </InputLabel>

            <MultiChipSelect
              id="subjects"
              name="subjects"
              suggestions={subjects}
              selected={
                schoolSubjects &&
                schoolSubjects.map(s => ({
                  ...s,
                  name: s.name || "",
                }))
              }
              onChange={subjectsChanged}
            />
            <Box py={0.5}>
              <DarkText>
                {t(
                  "components.organisms.educationPackageForm.schoolSubjectsSelected",
                  { count: schoolSubjects ? schoolSubjects.length : 0 },
                )}
              </DarkText>
            </Box>
          </FormControl>
        )}
        <FormControl>
          <Box mt={2}>
            <StyledInputLabel>
              {t("components.organisms.educationPackageForm.themeColor")}
            </StyledInputLabel>
          </Box>
          <ColorThemeInput
            id="colorTheme"
            value={colorTheme}
            onChange={colorThemeChange}
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
