import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "components/Contexts/SnackbarContext";
import { useParams } from "components/Hooks/react-router-hooks";

import { Button, Loader, Flex, AddNewButton } from "components/Atoms";
import { NoNewPlaceHolderText } from "components/Atoms/Text";
import { DropdownMenu, DropdownOptionItem } from "components/Molecules/DropdownMenu";
import { GetActivitiesTeacher_allActivities_data as Activity } from "./components/ActivityListQueries/types/GetActivitiesTeacher";
import { GetActivitiesTeacher_allActivities_data_educationalPackageGoals as EducationalPackageGoal } from "./components/ActivityListQueries/types/GetActivitiesTeacher";

import {
  EditActivityModal,
  EditActivityGoalsModal,
} from "components/Organisms";

import { ActivityContextMenuItems } from "./components/ActivityCard";
import { ActivityListQueries } from "./components/ActivityListQueries";
import { DraggableList } from "./components/DraggableList";

import { DeleteActivityConfirmationModal } from "./components/DeleteActivityConfirmationModal";

import {
  SelfAssessmentActivityConfirmationModal,
  SelfAssesmentConfirmationState,
} from "./components/SelfAssessmentActivityConfirmationModal";

import { SelfAssessmentSchedule } from "typings/graphql-global-types";
import { ActivityTemplatePickerModal } from "components/Organisms/ActivityTemplatePickerModal";

enum ActivityListContextMenuItems {
  createNew,
  importFromTemplate,
}

interface Props {}

export const ActivityList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { educationalPackageId }: any = useParams();

  const [showEditActivityModal, setShowEditActivityModal] = useState(false);
  const [showImportActivityModal, setShowImportActivityModal] = useState(false);
  const [showExportActivityModal, setShowExportActivityModal] = useState(false);

  const [showEditActivityGoalsModal, setShowEditActivityGoalsModal] = useState(
    false,
  );
  const [
    showDeleteActivityConfirmationModal,
    setShowDeleteActivityConfirmationModal,
  ] = useState(false);

  const [
    showSelfAssessmentConfirmationModal,
    setShowSelfAssessmentConfirmationModal,
  ] = useState(false);

  const [nextSelfAssessmentState, setNextSelfAssessmentState] = useState<
    SelfAssesmentConfirmationState | undefined
  >();

  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(
    null,
  );

  const onAddActivity = () => {
    setShowEditActivityModal(true);
  };

  return (
    <ActivityListQueries educationalPackageId={educationalPackageId}>
      {({
        activities: {
          loading,
          data,
          pageInfo,
          educationalPackageData,
          refetch,
          deleteActivity,
          fetchMoreActivities,
          loadingMore,
          importActivity,
          exportActivity,
        },
        updateOrder: { setOrder },
        updateClassroomAssessment: { setAssessment },
      }) => {
        const onActivityModalClosed = (result: boolean) => {
          setSelectedActivityId(null);
          setShowEditActivityModal(false);
          if (result) refetch();
        };

        const onActivityGoalsModalClosed = (result: boolean) => {
          setSelectedActivityId(null);
          setShowEditActivityGoalsModal(false);
        };

        const onDeleteActivityModalClosed = (result: boolean) => {
          if (result && selectedActivityId) deleteActivity(selectedActivityId);
          setSelectedActivityId(null);
          setShowDeleteActivityConfirmationModal(false);
        };

        const onSelfAssessmentConfirmationModalClosed = (
          result: boolean,
          schedule?: SelfAssessmentSchedule,
        ) => {
          if (result && selectedActivityId) {
            setAssessment(
              selectedActivityId,
              nextSelfAssessmentState === "enable",
              schedule,
            );

            showSnackbar({
              id: "classroomSelfAssessment",
              type: "success",
              message:
                nextSelfAssessmentState === "enable"
                  ? t(
                      "containers.teacherEducationPackage.classroomSelfAssessmentLinkedSuccess",
                    )
                  : t(
                      "containers.teacherEducationPackage.classroomSelfAssessmentUnlinkedSuccess",
                    ),
            });
          }
          setNextSelfAssessmentState(undefined);
          setSelectedActivityId(null);
          setShowSelfAssessmentConfirmationModal(false);
        };

        const onSelfAssessmentChanged = (
          activityId: string,
          state: boolean,
        ) => {
          setSelectedActivityId(activityId);
          const hasGoals = data.find(
            a =>
              a.id === activityId &&
              a.educationalPackageGoals &&
              a.educationalPackageGoals.length,
          );
          if (!hasGoals) {
            setNextSelfAssessmentState("nogoals");
          } else if (state) {
            setNextSelfAssessmentState("enable");
          } else {
            setNextSelfAssessmentState("disable");
          }
          setShowSelfAssessmentConfirmationModal(true);
        };

        const onContextMenuChanged = async (
          activityId: string,
          o: DropdownOptionItem,
        ) => {
          if (o.id === ActivityContextMenuItems.editGoals) {
            setSelectedActivityId(activityId);
            setShowEditActivityGoalsModal(true);
          }
          
          if (o.id === ActivityContextMenuItems.edit) {
            setSelectedActivityId(activityId);
            setShowEditActivityModal(true);
          }

          if (o.id === ActivityContextMenuItems.deleteActivity) {
            setSelectedActivityId(activityId);
            setShowDeleteActivityConfirmationModal(true);
          }

          if (o.id === ActivityContextMenuItems.importFromTemplate) {
            setSelectedActivityId(activityId);
            setShowImportActivityModal(true);
          }

          if (o.id === ActivityContextMenuItems.exportToTemplate) {
            setSelectedActivityId(activityId);
            setShowExportActivityModal(true);
          }
        };

        const otherGoals: any = {};
        if (
          data &&
          data.length &&
          showEditActivityGoalsModal &&
          selectedActivityId
        ) {
          data.forEach((a: Activity) => {
            if (a.id !== selectedActivityId) {
              (a.educationalPackageGoals || []).forEach(
                (e: EducationalPackageGoal) => {
                  if (e.eGoals.length > 0) {
                    e.eGoals.forEach(eGoal => {
                      otherGoals[eGoal.id] = (otherGoals[eGoal.id] || 0) + 1;
                    });
                  }
                },
              );
            }
          });
        }

        const onAddMenuChanged = async (o: DropdownOptionItem) => {
          if (o.id === ActivityListContextMenuItems.createNew) {
            onAddActivity();
          } else if (o.id === ActivityListContextMenuItems.importFromTemplate) {
            setShowImportActivityModal(true);
          }
        };
        const options = [
          {
            id: ActivityListContextMenuItems.createNew,
            label: t("containers.teacherEducationPackage.addNewButton"),
          },
          {
            id: ActivityListContextMenuItems.importFromTemplate,
            label: t("containers.teacherEducationPackage.importFromTemplateButton"),
          },
        ];

        const onActivityTemplatePickerModalClosed = async (result: boolean, id?: string) => {
          if (showImportActivityModal) {
            setShowImportActivityModal(false);
            if (result && id) await importActivity(id, selectedActivityId);
          }
          if (showExportActivityModal) {
            setShowExportActivityModal(false);
            if (result && selectedActivityId && id) await exportActivity(selectedActivityId, id);
          }
          setSelectedActivityId(null);
        };

        return (
          <>
            <Flex
              justifyContent={["flex-start", "flex-end"]}
              pt={[4, 0]}
              pb={[2, 4]}
              pl={[2, 0]}
            >
              {!loading &&
                educationalPackageData &&
                // TODO: functionality for classroom educational packages
                // !educationalPackageData.importedPackage &&
                 (
                  <DropdownMenu
                    name="add-educational-package"
                    onSelect={onAddMenuChanged}
                    options={options}
                    component={
                      <AddNewButton
                        name="add-activity-button"
                        label={t("containers.teacherEducationPackage.addNewButton")}
                      />
                    }
                  />
                )}
            </Flex>
            {loading && <Loader />}
            {!loading && data.length === 0 && (
              <NoNewPlaceHolderText>
                {t("globals.empty.noNewActivities")}
              </NoNewPlaceHolderText>
            )}
            {!loading && data.length > 0 && educationalPackageData && (
              <Flex>
                <DraggableList
                  data={data}
                  educationalPackageData={educationalPackageData}
                  onContextMenuChanged={onContextMenuChanged}
                  onSelfAssessmentChanged={onSelfAssessmentChanged}
                  setOrder={setOrder}
                />
              </Flex>
            )}
            {pageInfo && pageInfo.hasNext && loadingMore && <Loader />}
            {pageInfo && pageInfo.hasNext && !loadingMore && (
              <Flex justifyContent="center">
                <Button onClick={() => fetchMoreActivities(data.length + 25)}>
                  {t("globals.loadMore")}
                </Button>
              </Flex>
            )}
            {showEditActivityModal && (
              <EditActivityModal
                educationalPackageId={educationalPackageId}
                open={showEditActivityModal}
                onModalClosed={onActivityModalClosed}
                activityId={selectedActivityId}
                hideLocalInputs={!educationalPackageData?.importedPackage}
              ></EditActivityModal>
            )}
            {(showImportActivityModal || showExportActivityModal) && (
              <ActivityTemplatePickerModal
                open={showImportActivityModal || showExportActivityModal}
                onModalClosed={onActivityTemplatePickerModalClosed}
                showActivityTemplates={showImportActivityModal}
              />
            )}
            {showEditActivityGoalsModal && selectedActivityId && (
              <EditActivityGoalsModal
                educationalPackageId={educationalPackageId}
                showModal={showEditActivityGoalsModal}
                onModalClosed={onActivityGoalsModalClosed}
                activityId={selectedActivityId}
                otherGoals={otherGoals}
              ></EditActivityGoalsModal>
            )}
            {showDeleteActivityConfirmationModal && selectedActivityId && (
              <DeleteActivityConfirmationModal
                onModalClosed={onDeleteActivityModalClosed}
              />
            )}
            {showSelfAssessmentConfirmationModal &&
              selectedActivityId &&
              nextSelfAssessmentState && (
                <SelfAssessmentActivityConfirmationModal
                  activityStatus={
                    data.find(a => a.id === selectedActivityId)!.status
                  }
                  activityScheduledTime={
                    data.find(a => a.id === selectedActivityId)!.scheduledTime
                  }
                  state={nextSelfAssessmentState}
                  onModalClosed={onSelfAssessmentConfirmationModalClosed}
                />
              )}
          </>
        );
      }}
    </ActivityListQueries>
  );
};
