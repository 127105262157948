import React from "react";
import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

import {
  GetActivityLearners,
  GetActivityLearnersVariables,
  GetActivityLearners_activity_data_learners as Learner,
} from "./types/GetActivityLearners";

export const GET_ACTIVITY_LEARNERS = gql`
  query GetActivityLearners($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        learners {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activityLearners: {
    loading: boolean;
    learners: Learner[];
  };
}

interface QueriesProps {
  activityId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, activityId }) => {
  const { loading, data } = useQuery<
    GetActivityLearners,
    GetActivityLearnersVariables
  >(GET_ACTIVITY_LEARNERS, {
    variables: {
      filters: {
        id: activityId,
      },
    },
  });

  return children({
    activityLearners: {
      loading,
      learners: data ? data.activity.data.learners || [] : [],
    },
  });
};
