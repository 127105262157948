import React from "react";
import { pathOr } from "ramda";

import { Hero as HeroComponent } from "components/Organisms";

import { GetEducationalPackageLearner as EducationalPackage } from "../Queries/types/GetEducationalPackageLearner";

interface Props {
  data?: EducationalPackage;
}

export const Hero: React.FC<Props> = ({ data }) => {
  return (
    <HeroComponent
      hideContextMenu
      title={pathOr("", "educationalPackage.data.name".split("."), data)}
      iconType="educationalPackage"
      loading={!data}
      colorTheme={data && data.educationalPackage.data.colorTheme}
      classroomImported={data && data.educationalPackage.data.importedPackage}
    ></HeroComponent>
  );
};
