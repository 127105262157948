import React from "react";

import styled from "styled-components";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { Flex, Button } from "components/Atoms";
import { EditAssessmentGetUserActivity_activity_data_learners as User } from "../Queries/types/EditAssessmentGetUserActivity";

interface Props {
  previousUser: User | null;
  nextUser: User | null;
  onChange: (user: User) => void;
}

const StyledButton = styled(Button)`
  text-transform: initial;
  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 1.875rem;
  }
`;

export const UserNavi: React.FC<Props> = ({
  previousUser,
  nextUser,
  onChange,
}) => {
  return (
    <Flex flex={1} justifyContent="space-between">
      {previousUser && (
        <StyledButton
          onClick={() => onChange(previousUser)}
          aria-label="Previous user"
          startIcon={<ChevronLeft fontSize="large" />}
        >
          {`${previousUser.givenName} ${previousUser.familyName}`}
        </StyledButton>
      )}
      {nextUser && (
        <StyledButton
          onClick={() => onChange(nextUser)}
          aria-label="Next user"
          endIcon={<ChevronRight fontSize="large" />}
        >
          {`${nextUser.givenName} ${nextUser.familyName}`}
        </StyledButton>
      )}
    </Flex>
  );
};
