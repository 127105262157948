import React, { FunctionComponent, useContext } from "react";

import { RouteComponentProps, Route, Switch, Redirect } from "react-router-dom";

import UserContext from "components/Contexts/UserContext";

import { PageTemplate } from "components/Pages";
import {
  HOME,
  EDUCATIONALPACKAGE,
  SELFASSESSMENT_REDIRECT,
  SETTINGS,
  DIGIPOLKU,
  DIGIPOLKU2,
  OWNGOALS,
} from "routes";

import TeacherHome from "containers/Teacher/Home";
import TeacherEducationalPackage from "containers/Teacher/EducationalPackage";

import LearnerHome from "containers/Learner/Home";
import LearnerEducationalPackage from "containers/Learner/EducationalPackage";

import LearnerSelfAssessmentRedirect from "containers/Learner/SelfAssessmentRedirect";
import TeacherSelfAssessmentRedirect from "containers/Teacher/SelfAssessmentRedirect";

import TeacherSettings from "containers/Teacher/Settings";
import LearnerSettings from "containers/Learner/Settings";

import Digipolku from "containers/Digipolku";
import Digipolku2 from "containers/Digipolku2";

import OwnGoals from "containers/OwnGoals";

interface MainRouterProps {
  title: string;
}
interface MainProps extends RouteComponentProps<MainRouterProps> {}

const NotFound = () => <div>Not found</div>;

const Main: FunctionComponent<MainProps> = () => {
  const { user } = useContext(UserContext);

  const role: string =
    user!.roles.find(r => r === "teacher" || r === "learner") || "";

  return (
    <PageTemplate>
      <Switch>
        <Route exact path={DIGIPOLKU} component={Digipolku} />
        <Route exact path={DIGIPOLKU2} component={Digipolku2} />
        <Route exact path={OWNGOALS} component={OwnGoals} />

        <Route
          exact
          path={SETTINGS}
          component={role === "learner" ? LearnerSettings : TeacherSettings}
        />

        <Route
          path={EDUCATIONALPACKAGE}
          component={
            role === "learner"
              ? LearnerEducationalPackage
              : TeacherEducationalPackage
          }
        />

        <Route
          exact
          path={HOME}
          component={role === "learner" ? LearnerHome : TeacherHome}
        />

        <Route
          exact
          path={SELFASSESSMENT_REDIRECT}
          component={
            role === "learner"
              ? LearnerSelfAssessmentRedirect
              : TeacherSelfAssessmentRedirect
          }
        />

        <Route path="/404" component={NotFound} />
        <Redirect from="*" to="/404" />
      </Switch>
    </PageTemplate>
  );
};

export default Main;
