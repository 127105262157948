import * as React from "react";
import styled from "styled-components";
import { Tooltip } from "components/Atoms";

export interface GoalIconProps {
  index: number;
  tooltip: string;
}

const Wrap = styled.div`
  user-select: none;
  background-color: ${props => props.theme.palette.primary.dark};
  height: 26px;
  text-align: center;
  transform: rotate(45deg);
  width: 26px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.07rem;
`;

const Item = styled.div`
  color: #fff;
  display: table-cell;
  height: 26px;
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 26px;
`;

function romanize(num: number) {
  var lookup: any = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    },
    roman = "",
    i;
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export const GoalIcon: React.FC<GoalIconProps> = ({ index, tooltip }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <Wrap>
        <Item>{romanize(index)}</Item>
      </Wrap>
    </Tooltip>
  );
};

GoalIcon.displayName = "GoalIcon";
