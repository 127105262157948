import React from "react";

import { useTranslation } from "react-i18next";

import { Flex, Text } from "components/Atoms";

import { Cell, StyledInputLabel } from "./index";

import { GoalIcon } from "components/Atoms/Icons";

import { GetDigipolkuSurveyGroup_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
}

// ["70%", "50%", "35%"]
const GoalColumn = ({ numericQuestions, width }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}>
        <Flex alignItems="center" height="100%">
          <StyledInputLabel>
            {t("containers.digipolku.surveyForm.goals")}
          </StyledInputLabel>
        </Flex>
      </Cell>
      {numericQuestions.map((g, i) => (
        <Cell
          key={`goalname-${g.id}`}
          py={2}
          divider={i < numericQuestions.length - 1}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex height="100%" alignItems="flex-start" pr={[0, 0, 2]}>
            <Flex alignItems="center" pt={0.5} mr={2} height={"54px"}>
              <GoalIcon tooltip={g.name} index={i + 1} />
            </Flex>
            <Flex pt={0.5} minHeight="54px" alignItems="center">
              <Text>{g.name}</Text>
            </Flex>
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default GoalColumn;
