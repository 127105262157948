import * as React from "react";

import styled from "styled-components";

import { BoxProps, boxStyles } from "../Box";

export type FlexProps = BoxProps;

export const Flex: React.FC<FlexProps> = styled.div`
  ${boxStyles}
  display: flex;
`;

Flex.displayName = "Flex";
