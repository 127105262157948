import * as React from "react";

import styled from "styled-components";

import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTranslation } from "react-i18next";
import { Flex, Box, Tooltip } from "../";

import {
  FairIcon,
  FairlyGoodIcon,
  GoodIcon,
  VeryGoodIcon,
  ExcellentIcon,
  NoneIcon,
} from "../../Atoms";

export type NumericAssessmentInputProps = {
  name?: string;
  value: number | null;
  type: "self" | "teacher";
  scale: number | null | undefined;
  scaleTexts: string[] | null | undefined;
  readonly?: boolean;
  onChange?: (value: number | null) => void;
};

const StyledRadio = styled(Radio)`
  :hover {
    background-color: rgba(130, 130, 130, 0.08);
  }
  ${(props: any) => props.theme.breakpoints.down("xs")} {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export enum AssessmentValueScale3 {
  Fair = 1,
  Good = 2,
  Excellent = 3,
}

export enum AssessmentValueScale5 {
  Fair = 1,
  FairlyGood = 2,
  Good = 3,
  VeryGood = 4,
  Excellent = 5,
}

const StyledFormHelperText = styled(FormHelperText)`
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0;
`;

export const NumericAssessmentInput: React.ComponentType<NumericAssessmentInputProps> = styled(
  ({
    name,
    onChange,
    value,
    readonly,
    type,
    scaleTexts,
    scale,
  }: NumericAssessmentInputProps) => {
    const { t } = useTranslation();
    return scale === 5 ? (
      <Flex data-cy={name} flexDirection="column">
        <Flex>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[0]) ||
              t(`globals.numericAssessments.${type}.fair`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`fair-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale5.Fair}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale5.Fair
                        ? null
                        : AssessmentValueScale5.Fair,
                    )
                  : null
              }
              value={AssessmentValueScale5.Fair}
              checkedIcon={
                <FairIcon
                  checked
                  view={value === AssessmentValueScale5.Fair && readonly}
                ></FairIcon>
              }
              icon={readonly ? <NoneIcon /> : <FairIcon />}
            />
          </Tooltip>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[1]) ||
              t(`globals.numericAssessments.${type}.fairlyGood`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`fairlygood-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale5.FairlyGood}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale5.FairlyGood
                        ? null
                        : AssessmentValueScale5.FairlyGood,
                    )
                  : null
              }
              value={AssessmentValueScale5.FairlyGood}
              checkedIcon={
                <FairlyGoodIcon
                  checked
                  view={value === AssessmentValueScale5.FairlyGood && readonly}
                ></FairlyGoodIcon>
              }
              icon={readonly ? <NoneIcon /> : <FairlyGoodIcon></FairlyGoodIcon>}
            />
          </Tooltip>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[2]) ||
              t(`globals.numericAssessments.${type}.mediocre`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`good-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale5.Good}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale5.Good
                        ? null
                        : AssessmentValueScale5.Good,
                    )
                  : null
              }
              value={AssessmentValueScale5.Good}
              checkedIcon={
                <GoodIcon
                  checked
                  view={value === AssessmentValueScale5.Good && readonly}
                ></GoodIcon>
              }
              icon={readonly ? <NoneIcon /> : <GoodIcon></GoodIcon>}
            />
          </Tooltip>

          <Tooltip
            title={
              (scaleTexts && scaleTexts[3]) ||
              t(`globals.numericAssessments.${type}.good`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`verygood-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale5.VeryGood}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale5.VeryGood
                        ? null
                        : AssessmentValueScale5.VeryGood,
                    )
                  : null
              }
              value={AssessmentValueScale5.VeryGood}
              checkedIcon={
                <VeryGoodIcon
                  checked
                  view={value === AssessmentValueScale5.VeryGood && readonly}
                ></VeryGoodIcon>
              }
              icon={readonly ? <NoneIcon /> : <VeryGoodIcon></VeryGoodIcon>}
            />
          </Tooltip>

          <Tooltip
            title={
              (scaleTexts && scaleTexts[4]) ||
              t(`globals.numericAssessments.${type}.excellent`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`excellent-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale5.Excellent}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale5.Excellent
                        ? null
                        : AssessmentValueScale5.Excellent,
                    )
                  : null
              }
              value={AssessmentValueScale5.Excellent}
              checkedIcon={
                <ExcellentIcon
                  checked
                  view={value === AssessmentValueScale5.Excellent && readonly}
                ></ExcellentIcon>
              }
              icon={readonly ? <NoneIcon /> : <ExcellentIcon></ExcellentIcon>}
            />
          </Tooltip>
        </Flex>
        <Box ml={readonly ? 2 : 1}>
          <StyledFormHelperText>
            {!value &&
              !readonly &&
              t("components.atoms.numericAssessmentInput.choose")}
            {value === AssessmentValueScale5.Fair
              ? scaleTexts
                ? scaleTexts[0]
                : t(`globals.numericAssessments.${type}.fair`)
              : null}
            {value === AssessmentValueScale5.FairlyGood
              ? scaleTexts
                ? scaleTexts[1]
                : t(`globals.numericAssessments.${type}.fairlyGood`)
              : null}
            {value === AssessmentValueScale5.Good
              ? scaleTexts
                ? scaleTexts[2]
                : t(`globals.numericAssessments.${type}.mediocre`)
              : null}
            {value === AssessmentValueScale5.VeryGood
              ? scaleTexts
                ? scaleTexts[3]
                : t(`globals.numericAssessments.${type}.good`)
              : null}
            {value === AssessmentValueScale5.Excellent
              ? scaleTexts
                ? scaleTexts[4]
                : t(`globals.numericAssessments.${type}.excellent`)
              : null}
          </StyledFormHelperText>
        </Box>
      </Flex>
    ) : (
      <Flex data-cy={name} flexDirection="column">
        <Flex>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[0]) ||
              t(`globals.numericAssessments.${type}.fair`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`fair-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale3.Fair}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale3.Fair
                        ? null
                        : AssessmentValueScale3.Fair,
                    )
                  : null
              }
              value={AssessmentValueScale3.Fair}
              checkedIcon={
                <FairIcon
                  checked
                  view={value === AssessmentValueScale3.Fair && readonly}
                ></FairIcon>
              }
              icon={readonly ? <NoneIcon /> : <FairIcon />}
            />
          </Tooltip>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[1]) ||
              t(`globals.numericAssessments.${type}.mediocre`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`good-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale3.Good}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale3.Good
                        ? null
                        : AssessmentValueScale3.Good,
                    )
                  : null
              }
              value={AssessmentValueScale3.Good}
              checkedIcon={
                <GoodIcon
                  checked
                  view={value === AssessmentValueScale3.Good && readonly}
                ></GoodIcon>
              }
              icon={readonly ? <NoneIcon /> : <GoodIcon></GoodIcon>}
            />
          </Tooltip>
          <Tooltip
            title={
              (scaleTexts && scaleTexts[2]) ||
              t(`globals.numericAssessments.${type}.excellent`)
            }
            arrow
            placement="top-start"
          >
            <StyledRadio
              data-cy={`excellent-radio-input`}
              disabled={readonly}
              checked={value === AssessmentValueScale3.Excellent}
              onClick={() =>
                onChange
                  ? onChange(
                      value === AssessmentValueScale3.Excellent
                        ? null
                        : AssessmentValueScale3.Excellent,
                    )
                  : null
              }
              value={AssessmentValueScale3.Excellent}
              checkedIcon={
                <ExcellentIcon
                  checked
                  view={value === AssessmentValueScale3.Excellent && readonly}
                ></ExcellentIcon>
              }
              icon={readonly ? <NoneIcon /> : <ExcellentIcon></ExcellentIcon>}
            />
          </Tooltip>
        </Flex>
        <Box ml={readonly ? 2 : 1}>
          <StyledFormHelperText>
            {!value &&
              !readonly &&
              t("components.atoms.numericAssessmentInput.choose")}
            {value === AssessmentValueScale3.Fair
              ? scaleTexts
                ? scaleTexts[0]
                : t(`globals.numericAssessments.${type}.fair`)
              : null}
            {value === AssessmentValueScale3.Good
              ? scaleTexts
                ? scaleTexts[1]
                : t(`globals.numericAssessments.${type}.mediocre`)
              : null}
            {value === AssessmentValueScale3.Excellent
              ? scaleTexts
                ? scaleTexts[2]
                : t(`globals.numericAssessments.${type}.excellent`)
              : null}
          </StyledFormHelperText>
        </Box>
      </Flex>
    );
  },
)``;

NumericAssessmentInput.displayName = "NumericAssessmentInput";
