import { useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { GET_SCHOOLS } from "../../GoalTemplatePickerModal/Queries";
import { GetSchoolsPickerModal } from "../../GoalTemplatePickerModal/Queries/types/GetSchoolsPickerModal";
import {
  GetActivityTemplatesPickerModal,
  GetActivityTemplatesPickerModalVariables
} from "./types/GetActivityTemplatesPickerModal";

const GET_SCHOOL_TEMPLATES = gql`
  query GetActivityTemplatesPickerModal(
    $filters: ActivityTemplateListFiltersInputType!
  ) {
    allActivityTemplateFoldersBySchool(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        parent {
          id
        }
        activityTemplates {
          id
          name
        }
        childrens {
          id
          name
          user {
            id
          }
          parent {
            id
          }
          activityTemplates {
            id
            name
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  schools: {
    loading: boolean;
    data: GetSchoolsPickerModal | undefined;
  };
  activityTemplates: {
    loading: boolean;
    data: GetActivityTemplatesPickerModal | undefined;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
  schoolId?: string;
}

export const Queries: React.FC<QueriesProps> = ({
  schoolId,
  children,
}) => {
  const { loading, data } = useQuery<GetSchoolsPickerModal>(GET_SCHOOLS);

  const [
    getSchoolTemplates,
    { loading: templatesLoading, data: templatesData },
  ] = useLazyQuery<
    GetActivityTemplatesPickerModal,
    GetActivityTemplatesPickerModalVariables
  >(GET_SCHOOL_TEMPLATES);

  useEffect(() => {
    if (schoolId) {
      getSchoolTemplates({
        variables: {
          filters: {
            schoolId,
          },
        },
      });
    }
  }, [schoolId, getSchoolTemplates]);

  return children({
    schools: {
      loading,
      data,
    },
    activityTemplates: {
      loading: templatesLoading,
      data: templatesData,
    },
  });
};
