import React, { useState } from "react";
import * as firebase from "firebase/app";

import { useTranslation } from "react-i18next";
import { pathOr } from "ramda";

import styled from "styled-components";
import { darken } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmailOutlined";

import { Flex, Input, Button, Box, Text } from "../../Atoms";

import { Card, CardContent } from "../../Molecules";

const PositionedAlternateEmailIcon = styled(AlternateEmailIcon)`
  position: absolute;
  margin-left: 8px;
  z-index: 1;
`;

const InputWithAdorment = styled(props => <Input {...props} />)`
  input {
    padding-left: 36px;
    width: 100%;
  }
  width: 100%;
`;

const EmailLinkButton = styled(Button)`
  justify-content: flex-start;
  background-color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonBg".split("."), props)};
  color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonColor".split("."), props)};
  :hover {
    background-color: ${props =>
      darken(
        pathOr("#fff", "theme.custom.emailLinkButtonBg".split("."), props),
        0.15,
      )};
  }
`;

const Wrap = styled(Box)`
  position: relative;
`;

export interface EmailLinkFormProps {
  redirectRoute: string;
  onBack: () => void;
  onError: (error?: firebase.auth.Error) => void;
}

export const EmailLinkForm: React.FunctionComponent<EmailLinkFormProps> = ({
  onError,
  onBack,
  redirectRoute,
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const { t } = useTranslation();

  const clearLinkSent = () => setLinkSent(false);

  const onEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const sendSignInLinkToEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    const actionCodeSettings = {
      url: `${window.location.origin}${redirectRoute}`, // Here we redirect back to this same page.
      handleCodeInApp: true, // This must be true.
    };
    try {
      setLoading(true);
      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      setLoading(false);
      setLinkSent(true);
    } catch (error) {
      onError(error as firebase.auth.Error);
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box>
          {!linkSent && (
            <form onSubmit={sendSignInLinkToEmail}>
              <Text variant="body1">
                {t(
                  "components.organisms.emailLinkForm.emailLinkFormDescription",
                )}
              </Text>
              <Box my={2}>
                <FormControl fullWidth required>
                  <Flex alignItems="center">
                    <PositionedAlternateEmailIcon color="primary" />
                    <InputWithAdorment
                      type="email"
                      placeholder={t(
                        "components.organisms.emailLinkForm.email",
                      )}
                      onChange={onEmailChanged}
                      id="Email"
                      name="email"
                      value={email}
                      disabled={loading}
                    />
                  </Flex>
                  <Wrap>
                    <Box mt={3}>
                      <EmailLinkButton
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label="EmailLinkButton"
                        data-cy="login-button-emaillink"
                        type="submit"
                        fullWidth
                        disabled={loading}
                      >
                        <EmailIcon />

                        <Box flex={1} textAlign="center">
                          {t(
                            "components.organisms.emailLinkForm.emailLinkButton",
                          )}
                        </Box>
                      </EmailLinkButton>
                    </Box>
                  </Wrap>
                </FormControl>
              </Box>
              <Box textAlign="right">
                <Button onClick={onBack}>
                  {t("components.organisms.emailLinkForm.goBack")}
                </Button>
              </Box>
            </form>
          )}

          {linkSent && (
            <Box>
              <Text variant="h6">
                {t("components.organisms.emailLinkForm.linkSentTitle")}
              </Text>
              <Box my={2}>
                <Text variant="body1">
                  {t("components.organisms.emailLinkForm.linkSentDescription", {
                    email,
                  })}
                </Text>
              </Box>
              <Box textAlign="right">
                <Button onClick={clearLinkSent}>
                  {t("components.organisms.emailLinkForm.goBack")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
