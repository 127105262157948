import * as React from "react";

import styled from "styled-components";

import { default as MuiLink } from "@material-ui/core/Link";
import { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { TextProps } from "../Text";

export type LinkProps = {
  to?: string;
} & MuiLinkProps &
  TextProps;

const StyledLink = styled(props => <MuiLink {...props} />)`
  cursor: pointer;
`;

const Link1 = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />,
);

export const Link: React.FC<LinkProps> = ({ children, ...rest }) => (
  <StyledLink component={Link1} {...rest}>
    {children}
  </StyledLink>
);

Link.displayName = "Link";
