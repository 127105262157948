import { useContext } from "react";
import { __RouterContext as RouterContext, RouteComponentProps } from "react-router";

export function useRouter() {
  return useContext(RouterContext as React.Context<RouteComponentProps>);
}

export function useParams() {
  const { match } = useRouter();
  return match.params;
}

export function useLocation() {
  const { location, history } = useRouter();

  function navigate(to: string, { replace = false } = {}) {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  return {
    location,
    navigate,
  };
}
