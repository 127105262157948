import { ApolloError, useLazyQuery, useMutation, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  CreateGoalTemplate,
  CreateGoalTemplateVariables,
} from "./types/CreateGoalTemplate";
import {
  GetGoalTemplateEdit,
  GetGoalTemplateEditVariables,
} from "./types/GetGoalTemplateEdit";
import {
  UpdateGoalTemplate,
  UpdateGoalTemplateVariables,
} from "./types/UpdateGoalTemplate";

export const GET = gql`
  query GetGoalTemplateEdit($filters: IdInputType!) {
    goalTemplate(filters: $filters) {
      data {
        id
        text
        user {
          id
        }
        eGoals {
          id
        }
      }
    }
  }
`;

export const UPDATE = gql`
  mutation UpdateGoalTemplate($input: GoalTemplateUpdateInputType!) {
    updateGoalTemplate(input: $input) {
      data {
        id
        text
        user {
          id
        }
        eGoals {
          id
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateGoalTemplate($input: GoalTemplateCreateInputType!) {
    createGoalTemplate(input: $input) {
      data {
        id
        text
        user {
          id
        }
        eGoals {
          id
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  goalTemplate: {
    loading: boolean;
    data: GetGoalTemplateEdit | undefined;
  };
  create: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            CreateGoalTemplate,
            CreateGoalTemplateVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<CreateGoalTemplate>>;
    error?: ApolloError;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            UpdateGoalTemplate,
            UpdateGoalTemplateVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateGoalTemplate>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  goalTemplateId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  goalTemplateId,
}) => {
  const [load, { loading, data }] = useLazyQuery<
    GetGoalTemplateEdit,
    GetGoalTemplateEditVariables
  >(GET);

  useEffect(() => {
    const fetchData = async () => {
      if (goalTemplateId) {
        await load({
          variables: {
            filters: {
              id: goalTemplateId,
            },
          },
        });
      }
    };
    fetchData();
  }, [goalTemplateId, load]);

  const [createTemplate, { error: createError }] = useMutation<
    CreateGoalTemplate,
    CreateGoalTemplateVariables
  >(CREATE);

  const [updateTemplate, { error: updateError }] = useMutation<
    UpdateGoalTemplate,
    UpdateGoalTemplateVariables
  >(UPDATE);

  return children({
    goalTemplate: {
      loading,
      data,
    },
    create: { mutation: createTemplate, error: createError },
    update: { mutation: updateTemplate, error: updateError },
  });
};
