import React from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  FairIcon,
  Text,
  GoodIcon,
  ExcellentIcon,
} from "components/Atoms";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  margin-right: 1rem;
`;

const StyledText = styled(Text)`
  margin-top: 9px;
`;

export const NumericAssessmentInstruction: React.FC<{
  type: "self" | "teacher";
}> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <StyledFlex alignItems="flex-start">
        <FairIcon view checked></FairIcon>
        <StyledText>{t(`globals.numericAssessments.${type}.fair`)}</StyledText>
      </StyledFlex>
      <StyledFlex alignItems="flex-start">
        <GoodIcon view checked></GoodIcon>
        <StyledText>{t(`globals.numericAssessments.${type}.good`)}</StyledText>
      </StyledFlex>
      <Flex alignItems="flex-start">
        <ExcellentIcon view checked></ExcellentIcon>
        <StyledText>
          {t(`globals.numericAssessments.${type}.excellent`)}
        </StyledText>
      </Flex>
    </Flex>
  );
};
