import { ApolloError, useLazyQuery, useMutation, useQuery, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  CreateEducationalPackage,
  CreateEducationalPackageVariables,
} from "./types/CreateEducationalPackage";

import {
  UpdateEducationalPackage,
  UpdateEducationalPackageVariables,
} from "./types/UpdateEducationalPackage";

import { GetAllGradeLevelsAndSubjects } from "./types/GetAllGradeLevelsAndSubjects";

import {
  GetEducationalPackageEdit,
  GetEducationalPackageEditVariables,
} from "./types/GetEducationalPackageEdit";

import { GetClassroomCourses } from "./types/GetClassroomCourses";

import {
  CreateImportedEducationalPackage,
  CreateImportedEducationalPackageVariables,
} from "./types/CreateImportedEducationalPackage";

import {
  EducationalPackageCreateInputType,
  ImportedEducationalPackageCreateInputType,
} from "typings/graphql-global-types";

export const GET_ALL_EDUCATIONALPACKAGES = gql`
  query GetAllEducationalPackagesOptimisticQuery(
    $filters: EducationalPackagesListFiltersInputType!
  ) {
    allEducationalPackages(filters: $filters) {
      data {
        id
        name
        active
        activitiesCount
        learnersCount
        fetching
        importedPackage
        classroomCourseLink
      }
    }
  }
`;

export const GET_CLASSROOM_COURSES = gql`
  query GetClassroomCourses {
    listClassroomCourses {
      data {
        id
        name
      }
    }
  }
`;

export const GET_GRADELEVELS_AND_SUBJECTS = gql`
  query GetAllGradeLevelsAndSubjects {
    allGradeLevels {
      data {
        id
        name
      }
    }
    allSchoolSubjects {
      data {
        id
        name
        schoolSubjects {
          id
          name
        }
      }
    }
  }
`;

export const GET_EDUCATIONAL_PACKAGE = gql`
  query GetEducationalPackageEdit($filters: IdInputType!) {
    educationalPackage(filters: $filters) {
      data {
        id
        name
        active
        importedPackage
        colorTheme
        schoolSubjects {
          id
          name
        }
        gradeLevel {
          id
          name
        }
        joinByCode
        visibleToGuardians
      }
    }
  }
`;

export const UPDATE_EDUCATIONAL_PACKAGE = gql`
  mutation UpdateEducationalPackage(
    $input: EducationalPackageUpdateInputType!
  ) {
    updateEducationalPackage(input: $input) {
      data {
        id
        name
        active
        colorTheme
        schoolSubjects {
          id
          name
        }
        gradeLevel {
          id
          name
        }
        joinByCode
        visibleToGuardians
      }
    }
  }
`;

const CREATE_EDUCATIONAL_PACKAGE = gql`
  mutation CreateEducationalPackage(
    $input: EducationalPackageCreateInputType!
  ) {
    createEducationalPackage(input: $input) {
      data {
        id
        name
        active
        colorTheme
        schoolSubjects {
          id
          name
        }
        gradeLevel {
          id
          name
        }
        joinByCode
        visibleToGuardians
      }
    }
  }
`;

const CREATE_IMPORTED_EDUCATIONAL_PACKAGE = gql`
  mutation CreateImportedEducationalPackage(
    $input: ImportedEducationalPackageCreateInputType!
  ) {
    createImportedEducationalPackage(input: $input) {
      data {
        id
        importedPackage
        fetching
        name
        active
        joinByCode
        visibleToGuardians
        activitiesCount
        learnersCount
        colorTheme
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  educationalPackage: {
    loading: boolean;
    data: GetEducationalPackageEdit | undefined;
  };
  classroom: {
    loading: boolean;
    data: GetClassroomCourses | undefined;
  };
  gradeLevelsAndSubjects: {
    loading: boolean;
    data: GetAllGradeLevelsAndSubjects | undefined;
  };
  create: {
    mutation: (
      input: EducationalPackageCreateInputType,
    ) => Promise<SingleExecutionResult<CreateEducationalPackage>>;
    error?: ApolloError;
  };
  createImported: {
    mutation: (
      input: ImportedEducationalPackageCreateInputType,
    ) => Promise<SingleExecutionResult<CreateImportedEducationalPackage>>;
    error?: ApolloError;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            UpdateEducationalPackage,
            UpdateEducationalPackageVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateEducationalPackage>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  educationalPackageId?: string;
  importFromClassroom?: boolean;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  educationalPackageId,
  importFromClassroom,
}) => {
  const { loading, data } = useQuery<GetAllGradeLevelsAndSubjects>(
    GET_GRADELEVELS_AND_SUBJECTS,
  );

  const [
    loadClassroomCourses,
    { loading: loadingClassroomCourses, data: classroomCourseData },
  ] = useLazyQuery<GetClassroomCourses>(GET_CLASSROOM_COURSES, {
    fetchPolicy: "network-only",
  });

  const [
    loadPackage,
    { loading: loadingPackage, data: dataPackage },
  ] = useLazyQuery<
    GetEducationalPackageEdit,
    GetEducationalPackageEditVariables
  >(GET_EDUCATIONAL_PACKAGE);

  useEffect(() => {
    const fetchData = async () => {
      if (educationalPackageId) {
        await loadPackage({
          variables: {
            filters: {
              id: educationalPackageId,
            },
          },
        });
      }
      if (importFromClassroom) {
        await loadClassroomCourses();
      }
    };
    fetchData();
  }, [
    educationalPackageId,
    loadPackage,
    importFromClassroom,
    loadClassroomCourses,
  ]);

  const [createEducationalPackage, { error: createError }] = useMutation<
    CreateEducationalPackage,
    CreateEducationalPackageVariables
  >(CREATE_EDUCATIONAL_PACKAGE);

  const [
    createImportedEducationalPackage,
    { error: createImportedError },
  ] = useMutation<
    CreateImportedEducationalPackage,
    CreateImportedEducationalPackageVariables
  >(CREATE_IMPORTED_EDUCATIONAL_PACKAGE);

  const create = (input: EducationalPackageCreateInputType) => {
    return createEducationalPackage({
      variables: {
        input,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_EDUCATIONALPACKAGES,
          variables: {
            filters: {
              active: true,
            },
          },
        },
      ],
    });
  };

  const createImported = (input: ImportedEducationalPackageCreateInputType) => {
    return createImportedEducationalPackage({
      variables: {
        input,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ALL_EDUCATIONALPACKAGES,
          variables: {
            filters: {
              active: true,
            },
          },
        },
      ],
    });
  };
  const [updateEducationalPackage, { error: updateError }] = useMutation<
    UpdateEducationalPackage,
    UpdateEducationalPackageVariables
  >(UPDATE_EDUCATIONAL_PACKAGE);

  return children({
    educationalPackage: { loading: loadingPackage, data: dataPackage },
    classroom: { loading: loadingClassroomCourses, data: classroomCourseData },
    gradeLevelsAndSubjects: { loading, data },
    create: { mutation: create, error: createError },
    createImported: {
      mutation: createImported,
      error: createImportedError,
    },
    update: { mutation: updateEducationalPackage, error: updateError },
  });
};
