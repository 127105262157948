import React, { useState } from "react";
import {
  FormikProps,
  FieldArray,
  FormikErrors,
  FormikTouched,
  getIn,
} from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format } from "date-fns";
import { fi, sv } from "date-fns/locale";

import FormHelperText from "@material-ui/core/FormHelperText";

import {
  Flex,
  Box,
  InputLabel,
  Input,
  GoalIcon,
  AddNewButton,
  Tooltip,
  Button,
} from "components/Atoms";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PostAddIcon from "@material-ui/icons/PostAdd";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";

import useBeforeUnload from "components/Hooks/use-before-unload";

import { getAcademicYear } from "utils/dateHelpers";

import { GetOwnGoal_survey_data_surveyQuestions as SurveyQuestion } from "../Queries/types/GetOwnGoal";
import {
  QuestionType,
  OwnGoalSurveyQuestionUpdateInput,
  GoalTemplateFolderType,
} from "typings/graphql-global-types";

import { GoalTemplatePickerModal } from "components/Organisms/GoalTemplatePickerModal";

const StyledForm = styled.form`
  width: 100%;
`;

const StyledStartInputAdornment = styled(InputAdornment)`
  position: absolute;
  z-index: 1;
  padding-left: ${props => props.theme.spacing(1)}px;
  p {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const StyledInputWithAdorment = styled(Input)`
  input {
    padding-left: 165px;
  }
`;

export interface OwnGoalsInputType {
  name: string;
  createdAt: Date;
  order: number;
  surveyQuestions: OwnGoalSurveyQuestionUpdateInput[];
}

interface Props extends FormikProps<OwnGoalsInputType> {
  questions: SurveyQuestion[];
}

const InputLabelWithoutInput = styled(InputLabel)`
  position: initial;
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 53px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-left: -53px;
`;

const LinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #00933d;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #00933d;
  }
`;

const NotLinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #d04100;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #d04100;
  }
`;

interface GoalItemProps {
  name: string;
  value: string | null;
  scheduledDate?: Date | null;
  hasAnswers: boolean;
  index: number;
  errors: FormikErrors<OwnGoalsInputType>;
  touched: FormikTouched<OwnGoalsInputType>;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onChangeScheduledDate: (date: Date | null) => void;
  onRemoveClick: (index: number) => void;
  onSelectGoalFromTemplateClick: (index: number) => void;
}

const GoalItem: React.FC<GoalItemProps> = ({
  name,
  index,
  value,
  scheduledDate,
  touched,
  errors,
  onChange,
  onChangeScheduledDate,
  onRemoveClick,
  hasAnswers,
  onSelectGoalFromTemplateClick,
}) => {
  const { t } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const removeButton = () => (
    <IconButton
      data-cy="remove-goal-button"
      disabled={hasAnswers}
      type="button"
      onClick={() => onRemoveClick(index)}
      aria-label="remove goal"
    >
      <CloseIcon color={hasAnswers ? "disabled" : "primary"}></CloseIcon>
    </IconButton>
  );

  return (
    <Flex flexDirection="column">
      <Flex flex={1} alignItems="center">
        <Box pl={[0]} pr={[1, 2]}>
          <GoalIcon index={index + 1} tooltip={value || ""} />
        </Box>

        <StyledInput
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          fullWidth
          endAdornment={
            <StyledInputAdornment position="end">
              <Tooltip
                arrow
                title={
                  scheduledDate
                    ? t("components.organisms.editOwnGoals.scheduledDateSet", {
                        date: format(new Date(scheduledDate), "dd.MM.yyyy"),
                      })
                    : t("components.organisms.editOwnGoals.scheduledDateNotSet")
                }
              >
                {scheduledDate ? (
                  <LinkedButton
                    data-cy="scheduled-button"
                    onClick={() => setIsDatePickerOpen(true)}
                  >
                    <EventAvailableIcon fontSize="large" />
                  </LinkedButton>
                ) : (
                  <NotLinkedButton
                    onClick={() => setIsDatePickerOpen(true)}
                    data-cy="not-scheduled-button"
                  >
                    <EventBusyIcon fontSize="large" />
                  </NotLinkedButton>
                )}
              </Tooltip>
            </StyledInputAdornment>
          }
        />
        <DatePicker
          open={isDatePickerOpen}
          onClose={() => setIsDatePickerOpen(false)}
          value={scheduledDate}
          onChange={onChangeScheduledDate}
          TextFieldComponent={() => null}
          cancelLabel={t("components.organisms.editOwnGoals.datePickerCancel")}
        />

        <Box mx={[0.5, 2]}>
          <Tooltip title={t("globals.pickFromTemplateTooltip")}>
            <Box>
              <IconButton
                type="button"
                onClick={() => onSelectGoalFromTemplateClick(index)}
                aria-label="pick from template"
                data-cy={"pick-goaltemplate-button"}
              >
                <PostAddIcon color="primary" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>

        <Box mx={[0.5, 2]}>
          {hasAnswers && (
            <Tooltip title={t("components.organisms.editOwnGoals.hasAnswers")}>
              <Box>{removeButton()}</Box>
            </Tooltip>
          )}
          {!hasAnswers && (
            <Tooltip
              title={t("components.organisms.editOwnGoals.removeTooltip")}
            >
              <Box>{removeButton()}</Box>
            </Tooltip>
          )}
        </Box>
      </Flex>
      {touch && error && <FormHelperText error={true}>{error}</FormHelperText>}
    </Flex>
  );
};

export const Form: React.FC<Props> = ({
  values: { name, surveyQuestions, createdAt },
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  questions,
}) => {
  const { t, i18n } = useTranslation();

  const [
    showGoalTemplatePickerModal,
    setShowGoalTemplatePickerModal,
  ] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const locale = i18n.language === "sv-FI" ? sv : fi;
  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onChangeScheduledDate = (name: any, date: Date | null) => {
    setFieldValue(name, date);
    setFieldTouched(name, true, false);
  };

  const onGoalTemplatePickerModalClosed = (result: boolean, text?: string) => {
    if (result) {
      setFieldValue(`surveyQuestions.${selectedIndex}.name`, text);
    }
    setShowGoalTemplatePickerModal(false);
  };

  const onSelectGoalFromTemplateClick = (index: number) => {
    setShowGoalTemplatePickerModal(true);
    setSelectedIndex(index);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <StyledForm>
          <FormControl fullWidth>
            <InputLabel htmlFor="name">
              {t("components.organisms.editOwnGoals.name")}
            </InputLabel>
            <StyledInputWithAdorment
              startAdornment={
                <StyledStartInputAdornment position="start">
                  {t("globals.academicYear", getAcademicYear(createdAt))}
                </StyledStartInputAdornment>
              }
              id="name"
              name="name"
              placeholder={t(
                "components.organisms.editOwnGoals.surveyNamePlaceholder",
              )}
              value={name}
              helperText={touched.name && touched.name ? errors.name : ""}
              error={touched.name && Boolean(errors.name)}
              fullWidth
              onChange={change.bind(null, "name")}
            />
          </FormControl>
          <Flex flex={"auto"} flexDirection="column">
            <InputLabelWithoutInput>
              {t("components.organisms.editOwnGoals.addGoals")}
            </InputLabelWithoutInput>
            <FieldArray
              name="surveyQuestions"
              render={({ insert, remove, push }) => (
                <Flex flexDirection="column">
                  {surveyQuestions.length > 0 &&
                    surveyQuestions.map((question, index) =>
                      question.type === QuestionType.numeric ? (
                        <GoalItem
                          errors={errors}
                          touched={touched}
                          key={index}
                          index={index}
                          hasAnswers={
                            questions.find(
                              f =>
                                f.id === question.id &&
                                f.surveyQuestionAnswer &&
                                f.surveyQuestionAnswer.numeric,
                            )
                              ? true
                              : false
                          }
                          scheduledDate={question.scheduledDate}
                          name={`surveyQuestions.${index}.name`}
                          value={question.name ? question.name : ""}
                          onChangeScheduledDate={date =>
                            onChangeScheduledDate(
                              `surveyQuestions.${index}.scheduledDate`,
                              date,
                            )
                          }
                          onChange={change.bind(
                            null,
                            `surveyQuestions.${index}.name`,
                          )}
                          onRemoveClick={() => remove(index)}
                          onSelectGoalFromTemplateClick={
                            onSelectGoalFromTemplateClick
                          }
                        />
                      ) : (
                        <div key={index} />
                      ),
                    )}

                  <Box ml={[5, 8]} my={2}>
                    <AddNewButton
                      name="add-goal-button"
                      label={t(
                        "components.organisms.editOwnGoals.addGoalButton",
                      )}
                      onClick={() =>
                        insert(
                          surveyQuestions
                            .map(q => q.type)
                            .lastIndexOf(QuestionType.numeric) + 1,
                          { name: "", type: QuestionType.numeric },
                        )
                      }
                    />
                  </Box>
                </Flex>
              )}
            />
          </Flex>
        </StyledForm>
      </MuiPickersUtilsProvider>
      {showGoalTemplatePickerModal && (
        <GoalTemplatePickerModal
          open={showGoalTemplatePickerModal}
          onModalClosed={onGoalTemplatePickerModalClosed}
          type={GoalTemplateFolderType.owngoal}
        ></GoalTemplatePickerModal>
      )}
    </>
  );
};
