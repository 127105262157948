import React from "react";

export const NoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      <circle cx="21.5" cy="21.5" r="14" fill="#E2E2E2"></circle>
    </svg>
  );
};
