import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import {
  GetLearningAchievementsForEGoals,
  GetLearningAchievementsForEGoalsVariables,
  GetLearningAchievementsForEGoals_learningAchievements_data as LearningAchievement,
} from "./types/GetLearningAchievementsForEGoals";

export const GET_LEARNINGACHIEVEMENTS_FOR_EGOALS = gql`
  query GetLearningAchievementsForEGoals(
    $filters: LearningAchievemenFilterInput!
  ) {
    learningAchievements(filters: $filters) {
      data {
        id
        user {
          id
        }
        activity {
          id
          name
          verbalAssessmentSetting {
            id
            learnerHelperText
          }
          educationalPackage {
            id
            name
          }
        }
        verbalAssessments {
          id
          type
          assessmentText
          user {
            id
            givenName
            familyName
          }
        }
        numericAssessments {
          id
          value
          educationalPackageGoal {
            id
            eGoals {
              id
            }
            numericAssessmentSetting {
              id
              scale
              learnerScaleTexts
              teacherScaleTexts
            }
          }
          type
          createdAt
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

export interface EGoalLearningAchievementQueriesProps {
  learningAchievement: {
    loading: boolean;
    data: LearningAchievement[];
  };
}

interface Props {
  children: RenderProp<EGoalLearningAchievementQueriesProps>;
  eGoalIds: string[];
  userIds: string[];
}

export const EGoalLearningAchievementQueries: React.FC<Props> = ({
  children,
  eGoalIds,
  userIds,
}) => {
  const [
    loadLearningAchievements,
    { loading: loadingLearningAchievements, data: dataLearningAchievements },
  ] = useLazyQuery<
    GetLearningAchievementsForEGoals,
    GetLearningAchievementsForEGoalsVariables
  >(GET_LEARNINGACHIEVEMENTS_FOR_EGOALS);

  useEffect(() => {
    const fetchData = async () => {
      if (eGoalIds && eGoalIds.length) {
        await loadLearningAchievements({
          variables: {
            filters: {
              userIds,
              eGoalIds,
            },
          },
        });
      }
    };
    fetchData();
  }, [eGoalIds, userIds, loadLearningAchievements]);

  return children({
    learningAchievement: {
      loading: loadingLearningAchievements,
      data: dataLearningAchievements?.learningAchievements.data || [],
    },
  });
};
