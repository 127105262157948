import React from "react";
import styled from "styled-components";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { default as MuiBreadcrumbs } from "@material-ui/core/Breadcrumbs";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import HomeIcon from "@material-ui/icons/Home";

import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import PersonIcon from "@material-ui/icons/PersonOutline";
import {
  AchievementIcon,
  EducationalPackageIcon,
  OwnGoalsIcon,
} from "components/Atoms";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { BreadcrumbItem } from "typings";

const StyledEducationalPackageIcon = styled(EducationalPackageIcon)`
  font-size: 1.5rem;
`;

const StyledAchievementIcon = styled(AchievementIcon)`
  font-size: 1.5rem;
`;

const StyledOwnGoalsIcon = styled(OwnGoalsIcon)`
  font-size: 1.5rem;
`;

const BreadcrumbIcon: React.FunctionComponent<BreadcrumbItem> = ({ icon }) => {
  if (icon === "home") return <HomeIcon />;
  if (icon === "educationalPackage") return <StyledEducationalPackageIcon />;
  if (icon === "activity") return <StarIcon />;
  if (icon === "add") return <AddIcon />;
  if (icon === "achievements") return <StyledAchievementIcon />;
  if (icon === "person") return <PersonIcon fontSize="large" />;
  if (icon === "digipolku") return <MapOutlinedIcon fontSize="large" />;
  if (icon === "ownGoals") return <StyledOwnGoalsIcon />;

  return null;
};

const StyledBreadCrumbs = styled(MuiBreadcrumbs)`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  color: ${props => props.theme.palette.primary.main};
  .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 8px;
  }
  .MuiListItem-root {
    min-width: 40px;
  }

  li:nth-child(2) {
    margin-left: 0;
  }

  .MuiBreadcrumbs-li:first-child .MuiListItemIcon-root {
    justify-content: flex-start;
    margin-left: 3px;
  }

  .MuiListItem-button:hover {
    background-color: inherit;
  }

  .MuiListItemIcon-root {
    justify-content: center;
    color: inherit;
    min-width: inherit;
  }

  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
`;

const StyledListItemText = styled(ListItemText)`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
  .MuiListItemText-primary {
    color: ${props => props.theme.palette.primary.main};
  }
  ${props => props.theme.breakpoints.down("xs")} {
    &:not(:last-child) {
      display: none;
    }
  }
`;

export const Separator: React.FunctionComponent = () => (
  <svg viewBox="0 0 12 72" height="100%" width="15px">
    <path d="M.5 0l11 36-11 36" fill="none" stroke="#bcbcbf" />
  </svg>
);

interface BreadcrumbProps {
  item: BreadcrumbItem;
}

function Breadcrumb(props: BreadcrumbProps) {
  const { item } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<
        HTMLAnchorElement,
        Omit<RouterLinkProps, "innerRef" | "to">
      >((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={item.path} {...itemProps} innerRef={ref} />
      )),
    [item],
  );

  return (
    <ListItem disabled={item.disabled} button component={renderLink}>
      {item.icon && (
        <ListItemIcon>
          <BreadcrumbIcon {...item} />
        </ListItemIcon>
      )}
      {item.label && <StyledListItemText primary={item.label} />}
    </ListItem>
  );
}

const StyledBreadCrumb = styled(Breadcrumb)`
  .MuiListItemIcon-root {
    justify-content: center;
    ${props => !props.item.label && "justify-content: center"};
  }
`;

export interface BreadcrumbsProps {
  items: BreadcrumbItem[] | null;
}

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  items,
}) => {
  return (
    <StyledBreadCrumbs separator={<Separator />} aria-label="breadcrumb">
      {items &&
        items.map(c => (
          <StyledBreadCrumb key={c.path} item={c}></StyledBreadCrumb>
        ))}
    </StyledBreadCrumbs>
  );
};
