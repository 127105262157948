import * as React from "react";

import styled from "styled-components";

import {
  default as MuiChip,
  ChipProps as MuiChipProps,
} from "@material-ui/core/Chip";

import Close from "@material-ui/icons/Close";

export type ChipProps = MuiChipProps;

export const Chip: React.FunctionComponent<ChipProps> = styled(
  (props: ChipProps) => (
    <MuiChip {...props} deleteIcon={<Close color="primary"></Close>} />
  ),
)`
  &.Mui-disabled {
    opacity: 1;
  }
`;

Chip.displayName = "Chip";
