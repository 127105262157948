import { useMutation, useQuery } from "@apollo/client";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FolderIcon from "@material-ui/icons/Folder";
import { Box, Flex, Input, MenuItem, Select, Text } from "components/Atoms";
import { Modal } from "components/Molecules/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackListItemText,
  GET_LASTSELECTEDSCHOOL, GoalIcon, SettingTitle, StyledListItem, UPDATE_MYLASTSELECTEDSCHOOL
} from "../GoalTemplatePickerModal";
import { GetMyLastSelectedSchool } from "../GoalTemplatePickerModal/types/GetMyLastSelectedSchool";
import {
  UpdateMyLastSelectedSchool,
  UpdateMyLastSelectedSchoolVariables
} from "../GoalTemplatePickerModal/types/UpdateMyLastSelectedSchool";
import { Queries } from "./Queries";
import {
  GetActivityTemplatesPickerModal_allActivityTemplateFoldersBySchool_data as Folder,
  GetActivityTemplatesPickerModal_allActivityTemplateFoldersBySchool_data_activityTemplates as ActivityTemplate
} from "./Queries/types/GetActivityTemplatesPickerModal";

export interface Props {
  open: boolean;
  onModalClosed: (result: boolean, id?: string) => void;
  showActivityTemplates?: boolean;
}

type TemplateOrFolder = ActivityTemplate | Folder;

const isFolder = (toBeDetermined: TemplateOrFolder) => {
  if ((toBeDetermined as Folder).__typename === "ActivityTemplateFolder") {
    return true;
  }
  return false;
};

export const ActivityTemplatePickerModal: React.FunctionComponent<Props> = ({
  onModalClosed,
  open,
  showActivityTemplates,
}) => {
  const { t } = useTranslation();

  const { loading: loadingMyLastSelectedSchool, data } = useQuery<
    GetMyLastSelectedSchool
  >(GET_LASTSELECTEDSCHOOL);

  const [updateLastSelectedSchool] = useMutation<
    UpdateMyLastSelectedSchool,
    UpdateMyLastSelectedSchoolVariables
  >(UPDATE_MYLASTSELECTEDSCHOOL);

  const [selectedSchoolId, setSelectedSchoolId] = useState<string>("default");

  useEffect(() => {
    if (data?.myprofile.data?.lastSelectedSchool) {
      setSelectedSchoolId(data?.myprofile.data?.lastSelectedSchool.id);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedSchoolId !== "default" &&
      selectedSchoolId !== data?.myprofile.data?.lastSelectedSchool?.id
    ) {
      updateLastSelectedSchool({
        variables: {
          input: {
            id: selectedSchoolId,
          },
        },
      });
    }
  }, [selectedSchoolId, updateLastSelectedSchool, data]);

  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [selectedActivityTemplate, setSelectedActivityTemplate] = useState<
    ActivityTemplate
  >();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries
      schoolId={selectedSchoolId === "default" ? undefined : selectedSchoolId}
    >
      {({ schools, activityTemplates }) => {
        const loading =
          schools.loading ||
          activityTemplates.loading ||
          loadingMyLastSelectedSchool;

        const selectedSchool = (schools.data?.allSchools.data || []).find(
          s => s.id === selectedSchoolId,
        );

        const folders =
          activityTemplates.data?.allActivityTemplateFoldersBySchool.data || [];
        const noFolders = !folders.length;

        const onPrimaryAction = () => {
          if (selectedActivityTemplate) {
            onModalClosed(true, selectedActivityTemplate.id);
          } else {
            if (selectedFolder) onModalClosed(true, selectedFolder.id);
          }
        };

        const openFolder = (folder: Folder) => {
          setSelectedActivityTemplate(undefined);
          setSelectedFolder(folder);
        };

        const onItemClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          item: TemplateOrFolder,
        ) => {
          if (isFolder(item)) {
            e.preventDefault();
            openFolder(item as Folder);
            return;
          }
          e.preventDefault();
          setSelectedActivityTemplate(item as ActivityTemplate);
        };

        const onBackClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          previousFolder?: Folder | null,
        ) => {
          setSelectedFolder(previousFolder || undefined);
          setSelectedActivityTemplate(undefined);
        };

        return (
          <Modal
            loading={loading}
            primaryAction={{
              text: t("globals.add"),
              onClick: onPrimaryAction,
              disabled:
                !(activityTemplates && folders.length > 0) ||
                (showActivityTemplates && !selectedActivityTemplate) ||
                (!showActivityTemplates && !selectedFolder) ||
                loading,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={showActivityTemplates
              ? t("components.organisms.activityTemplatePickerModal.importTitle")
              : t("components.organisms.activityTemplatePickerModal.exportTitle")
            }
            open={open}
          >
            <Flex flexDirection="column" flex={1}>
              <Flex mt={2} flexDirection="column">
                <SettingTitle variant="h4">{t("globals.school")}</SettingTitle>
                <Box my={1}>
                  <Select
                    fullWidth
                    value={selectedSchoolId}
                    onChange={e => {
                      setSelectedFolder(undefined);
                      setSelectedActivityTemplate(undefined);
                      setSelectedSchoolId(e.target.value as string);
                    }}
                    input={<Input name="school" id="school" />}
                  >
                    <MenuItem value={"default"}>
                      {t("globals.empty.noSchool")}
                    </MenuItem>
                    {(schools.data?.allSchools.data || []).map(s => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Flex>
              {selectedSchool ? (
                noFolders ? (
                  <Box>
                    <Text>
                      {showActivityTemplates
                        ? t("components.organisms.activityTemplatePickerModal.noTemplates")
                        : t("components.organisms.activityTemplatePickerModal.noFolders")
                      }
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <List aria-label="activity templates">
                      {selectedFolder && (
                        <ListItem
                          divider
                          button
                          onClick={event =>
                            onBackClick(
                              event,
                              folders.find(
                                f => f.id === selectedFolder?.parent?.id,
                              ),
                            )
                          }
                        >
                          <ListItemIcon>
                            <ChevronLeftIcon fontSize="large" />
                          </ListItemIcon>
                          <BackListItemText primary={selectedFolder.name} />
                        </ListItem>
                      )}
                      {selectedFolder
                        ? !loading && (
                          <>
                            {(selectedFolder.childrens || []).map(f => (
                              <StyledListItem
                                divider
                                key={f.id}
                                button
                                selected={selectedFolder === f}
                                onClick={event =>
                                  onItemClick(event, f as Folder)
                                }
                              >
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={f.name} />
                              </StyledListItem>
                            ))}
                            {showActivityTemplates && selectedFolder.activityTemplates.map(
                              (activityTemplate, i) => (
                                <StyledListItem
                                  divider
                                  key={activityTemplate.id}
                                  button
                                  selected={
                                    selectedActivityTemplate === activityTemplate
                                  }
                                  onClick={event =>
                                    onItemClick(event, activityTemplate)
                                  }
                                >
                                  <ListItemIcon>
                                    <GoalIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={activityTemplate.name} />
                                </StyledListItem>
                              ),
                            )}
                          </>
                        )
                        : !loading &&
                        folders.map(folder => (
                          <StyledListItem
                            divider
                            key={folder.id}
                            button
                            selected={selectedFolder === folder}
                            onClick={event => onItemClick(event, folder)}
                          >
                            <ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={folder.name} />
                          </StyledListItem>
                        ))}
                    </List>
                  </Box>
                )
              ) : (
                <Text>{t("globals.empty.noSchoolSelected")}</Text>
              )}
            </Flex>
          </Modal>
        );
      }}
    </Queries>
  );
};
