function firstNumber(s?: string): number {
  if (!s) return -3;
  let t: string = "";
  for (let i: number = 0; i < s.length; i++) {
    const c: string = s.charAt(i);
    if (c >= "0" && c <= "9") t = t + c;
    else if (t.length) break;
  }
  if (!t.length) return 100;
  const n: number = parseInt(t, 10);

  if (isNaN(n)) return -1;
  return n;
}

export default firstNumber;
