import React from "react";

import styled from "styled-components";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { EGoalAssessmentCell, getAssessments } from "../EGoalAssessmentCell";

import { Button, Tooltip, Box } from "components/Atoms";

import {
  // GetEducationalPackageEGoalsOpsTable_educationalPackage_data_activities as Activity,
  GetEducationalPackageEGoalsOpsTable_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal,
} from "../Queries/types/GetEducationalPackageEGoalsOpsTable";

import { AssessmentType } from "typings/graphql-global-types";

import cleanText from "utils/cleanText";

import { Learner } from "../../";
interface Props {
  eGoal: EGoal;
  learners: Learner[];
  selectedAssessmentType: AssessmentType;
  onEGoalClick: (eGoal: EGoal) => void;
  onAssessmentClick: (eGoal: EGoal, learner: Learner) => void;
}

const StyledTableCell = styled(TableCell)`
  height: 100%;
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StickyStyledTableCell = styled(StyledTableCell)`
  position: sticky;
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 0px;
  }
  z-index: 1;
`;

export const EGoalTableRow: React.FC<Props> = ({
  eGoal,
  learners,
  selectedAssessmentType,
  onEGoalClick,
  onAssessmentClick,
}) => {
  const goalText = cleanText(eGoal.eGoal.name);
  const shortText = goalText.split(" ")[0];
  return (
    <TableRow key={eGoal.eGoal.id}>
      <StickyStyledTableCell align="center" style={{ width: "150px" }}>
        <Tooltip title={goalText}>
          <Box height="100%">
            <Button
              data-cy="show-egoal-button"
              size="small"
              onClick={() => onEGoalClick(eGoal)}
            >
              {shortText}
            </Button>
          </Box>
        </Tooltip>
      </StickyStyledTableCell>

      {learners.map(learner => (
        <StyledTableCell key={learner.id}>
          <EGoalAssessmentCell
            numericAssessments={getAssessments(
              eGoal,
              selectedAssessmentType,
              learner.learningAchievements,
            )}
            onClick={() => onAssessmentClick(eGoal, learner)}
          />
        </StyledTableCell>
      ))}
    </TableRow>
  );
};
