import React, { useState } from "react";
import { without } from "ramda";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Flex, Box, Checkbox } from "../../Atoms";

const UsersFlex = styled(Flex)`
  background-color: #e8e8e8;
  overflow-x: auto;
`;

const StyledCheckbox = styled(Checkbox)`
  .MuiFormControlLabel-root {
    width: 180px;
  }
  .MuiFormControlLabel-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type User = {
  id: string;
  label: string;
};

export interface UserSelectionCheckboxGroupProps {
  users: User[];
  onSelectionChanged: (selectedUserIds: string[]) => void;
}

export const UserSelectionCheckboxGroup: React.FC<
  UserSelectionCheckboxGroupProps
> = ({ users, onSelectionChanged }) => {
  const { t } = useTranslation();
  const [checkedUserIds, setCheckedUserIds] = useState<string[]>([]);

  const toggleAll = () => {
    let selectedIds: string[] = [];
    if (checkedUserIds.length !== users.length) {
      selectedIds = users.map(l => l.id);
    }
    setCheckedUserIds(selectedIds);
    onSelectionChanged(selectedIds);
  };
  const toggleUser = (userId: string) => {
    const index = checkedUserIds.indexOf(userId);
    let selectedIds: string[] = [];
    if (index > -1) {
      selectedIds = without([userId], checkedUserIds);
    } else {
      selectedIds = [...checkedUserIds, userId];
    }
    setCheckedUserIds(selectedIds);
    onSelectionChanged(selectedIds);
  };
  return (
    <Flex pt={1} flexDirection="column">
      {users.length > 0 && (
        <Box px={3}>
          <Checkbox
            label={t("components.molecules.userSelectionCheckboxGroup.all")}
            checked={checkedUserIds.length === users.length}
            onChange={toggleAll}
          ></Checkbox>
        </Box>
      )}

      <UsersFlex mt={2}>
        <Flex
          pl={3}
          flexWrap="wrap"
          height="166px"
          width="100%"
          overflow="auto"
          flexDirection="column"
        >
          {users.map(u => (
            <Box flex="0 0 auto" key={u.id} width="180px">
              <StyledCheckbox
                label={u.label}
                checked={checkedUserIds.indexOf(u.id) > -1}
                onChange={() => toggleUser(u.id)}
              ></StyledCheckbox>
            </Box>
          ))}
        </Flex>
      </UsersFlex>
    </Flex>
  );
};
