import React from "react";

import { useTranslation } from "react-i18next";
import { FastField } from "formik";

import { Flex, NumericAssessmentInput } from "components/Atoms";

import {
  Cell,
  StyledInputLabel,
  FormValues,
  MobileTimelineCell,
  TimelineItem,
} from "./index";

import { QuestionType } from "typings/graphql-global-types";

interface Props {
  timelineItems: TimelineItem[];
  width: string[];
  values: FormValues;
  onChange: (field: string, value: number | null) => void;
}
//["40%"];
// ["100%", "100%", "40%"]
const AssessmentColumn = ({
  timelineItems,
  width,
  values,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell pl={2} pr={1} hideMobile width={width} order={1} flexGrow={1}>
        <Flex justifyContent="space-between" alignItems="center" height="100%">
          <StyledInputLabel htmlFor="surveyQuestionAnswers">
            {t("containers.ownGoals.surveyForm.selfAssessment")}
          </StyledInputLabel>
        </Flex>
      </Cell>
      {timelineItems.map((q, i) => {
        const answer = values.surveyQuestionAnswers.find(
          a => a.surveyQuestionId === q.id,
        );

        const sqaIndex = values.surveyQuestionAnswers.findIndex(
          a => a.surveyQuestionId === q.id,
        );

        if (!answer || q.type !== QuestionType.numeric)
          return (
            <MobileTimelineCell
              key={`input-${q.id}`}
              py={1}
              pl={2}
              pr={1}
              alternateColor={q.type !== QuestionType.numeric}
              width={width}
              order={i + 2}
              flexGrow={1}
              noTopLine={i === timelineItems.length - 1}
              noBottomLine={i === timelineItems.length - 1}
            />
          );

        return (
          <MobileTimelineCell
            key={`input-${q.id}`}
            py={1}
            pl={2}
            pr={1}
            alternateColor={q.type !== QuestionType.numeric}
            width={width}
            order={i + 2}
            flexGrow={1}
            noTopLine={i === timelineItems.length - 1}
            noBottomLine={i === timelineItems.length - 1}
          >
            <Flex
              pl={["47px", "47px", 0]}
              pr={[2.5, 2.5, 0]}
              alignItems="center"
            >
              <FastField name={`surveyQuestionAnswers.${sqaIndex}.numeric`}>
                {() => (
                  <NumericAssessmentInput
                    type={"self"}
                    scale={3}
                    scaleTexts={null}
                    name={`surveyQuestionAnswers.${sqaIndex}.numeric`}
                    key={answer.surveyQuestionId}
                    value={answer.numeric ? answer.numeric : null}
                    onChange={value =>
                      onChange(
                        `surveyQuestionAnswers.${sqaIndex}.numeric`,
                        value,
                      )
                    }
                  />
                )}
              </FastField>
            </Flex>
          </MobileTimelineCell>
        );
      })}
    </>
  );
};

export default AssessmentColumn;
