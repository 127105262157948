import React, { useState } from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import ListSubheader from "@material-ui/core/ListSubheader";

import {
  Flex,
  Box,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Text,
  Chip,
} from "components/Atoms";

import useBeforeUnload from "components/Hooks/use-before-unload";
import { VerbalAssessmentSettingInput } from "typings/graphql-global-types";

import { GetVerbalAssessmentSettingFavourites_favouriteVerbalAssessmentSettings_data as FavouriteVerbalAssessmentSetting } from "../Queries/types/GetVerbalAssessmentSettingFavourites";

import { RemoveFavouriteAssessmentSettingConfirmationModal } from "components/Organisms/RemoveFavouriteAssessmentSettingConfirmationModal";

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledChip = styled(Chip)`
  background: rgba(0, 0, 0, 0.08);
  color: #8a8a8a;
  text-transform: uppercase;
`;

const Form = styled.form`
  width: 100%;
`;

interface AllFavouriteVerbalAssessmentSetting
  extends FavouriteVerbalAssessmentSetting {
  type: string;
}

interface VerbalAssessmentSettingFormProps
  extends FormikProps<VerbalAssessmentSettingInput> {
  favouriteVerbalAssessmentSettings: AllFavouriteVerbalAssessmentSetting[];
  isFavouriteManager?: boolean;
  onRemoveFavourite: (id: string) => void;
  onSetDefaultSetting: (id: string, state: boolean) => void;
}

export const VerbalAssesssmentSettingForm: React.FC<VerbalAssessmentSettingFormProps> = ({
  values: { id, name, learnerHelperText, defaultSetting },
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  errors,
  favouriteVerbalAssessmentSettings,
  isFavouriteManager,
  onRemoveFavourite,
  onSetDefaultSetting,
}) => {
  const { t } = useTranslation();

  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result && id) {
      onRemoveFavourite(id);
    }
    setShowRemoveConfirmationModal(false);
  };

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);

    if (name === "id") {
      const favouriteVas = favouriteVerbalAssessmentSettings.find(
        v => v.id === e.target.value,
      );

      if (favouriteVas) {
        setFieldValue("learnerHelperText", favouriteVas.learnerHelperText);
        setFieldValue("name", favouriteVas.name);
        setFieldValue("favourite", favouriteVas.favourite);
        setFieldValue("defaultSetting", favouriteVas.defaultSetting);
      } else {
        setFieldValue(
          "learnerHelperText",
          t("globals.verbalAssessment.learnerHelperText"),
        );
        setFieldValue("name", "");
      }
    } else if (name === "defaultSetting" && id) {
      onSetDefaultSetting(id, e.target.checked);
    }

    setFieldValue(name, e.target.value);
    setFieldTouched(name, true, false);
  };

  const myFavs = favouriteVerbalAssessmentSettings.filter(
    a => a.type === "myFavourite",
  );
  const otherFavs = favouriteVerbalAssessmentSettings.filter(
    a => a.type === "otherFavourite",
  );
  const popular = favouriteVerbalAssessmentSettings.filter(
    a => a.type === "popular",
  );

  if (isFavouriteManager && !myFavs.length) {
    return (
      <Box my={2}>
        <Text>
          {t(
            "components.organisms.editNumericAssessmentSettingModal.noFavourites",
          )}
        </Text>
      </Box>
    );
  }

  return (
    <Form>
      <Flex my={2} flex={"auto"} flexDirection="column">
        <Select
          name="verbal-assessment-setting-select"
          value={id}
          onChange={change.bind(null, "id")}
          input={
            <Input id="assessment-name" name="assessment-name" fullWidth />
          }
        >
          {!isFavouriteManager && (
            <MenuItem value="new" divider>
              {t(
                "components.organisms.editVerbalAssessmentSettingModal.createNew",
              )}
            </MenuItem>
          )}

          {!isFavouriteManager && (
            <MenuItem value="default">
              {t(
                "components.organisms.editVerbalAssessmentSettingModal.omaOpsDefault",
              )}
            </MenuItem>
          )}
          <ListSubheader>
            {t(
              "components.organisms.editVerbalAssessmentSettingModal.myFavourites",
            )}
          </ListSubheader>
          {myFavs.map(({ id, name, defaultSetting }, i) => (
            <MenuItem key={id} value={id}>
              <Flex alignItems="center">
                {name}
                {defaultSetting && (
                  <Box ml={2}>
                    <StyledChip
                      label={t(
                        "components.organisms.editVerbalAssessmentSettingModal.defaultSettingChip",
                      )}
                    ></StyledChip>
                  </Box>
                )}
              </Flex>
            </MenuItem>
          ))}

          {!isFavouriteManager && otherFavs.length > 0 && (
            <ListSubheader>
              {t(
                "components.organisms.editVerbalAssessmentSettingModal.otherTeacherFavourites",
              )}
            </ListSubheader>
          )}
          {!isFavouriteManager &&
            otherFavs.map(({ id, name }, i) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}

          {!isFavouriteManager && popular.length > 0 && (
            <ListSubheader>
              {t(
                "components.organisms.editVerbalAssessmentSettingModal.popular",
              )}
            </ListSubheader>
          )}
          {!isFavouriteManager &&
            popular.map(({ id, name }, i) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
        </Select>

        {id === "new" && (
          <FormControl fullWidth>
            <InputLabel htmlFor="name">
              {t("components.organisms.editVerbalAssessmentSettingModal.name")}
            </InputLabel>
            <Input
              name="name"
              fullWidth
              value={name}
              onChange={change.bind(null, "name")}
              helperText={touched.name && touched.name ? errors.name : ""}
              error={touched.name && Boolean(errors.name)}
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <InputLabel htmlFor="learnerHelperText">
            {t(
              "components.organisms.editVerbalAssessmentSettingModal.learnerHelperText",
            )}
          </InputLabel>
          <Input
            disabled={id !== "new"}
            id="learnerHelperText"
            name="learnerHelperText"
            multiline
            rows={3}
            helperText={
              touched.learnerHelperText && touched.learnerHelperText
                ? errors.learnerHelperText
                : ""
            }
            error={
              touched.learnerHelperText && Boolean(errors.learnerHelperText)
            }
            value={learnerHelperText}
            onChange={change.bind(null, "learnerHelperText")}
            fullWidth
          />
        </FormControl>

        {isFavouriteManager && (
          <Box>
            <Checkbox
              name="defaultSetting"
              label={t(
                "components.organisms.editNumericAssessmentSettingModal.defaultSetting",
              )}
              checked={defaultSetting === true ? true : false}
              onChange={change.bind(null, "defaultSetting")}
            />
            <Box mt={1}>
              <StyledButton
                size="small"
                onClick={setShowRemoveConfirmationModal.bind(null, true)}
              >
                {t(
                  "components.organisms.editNumericAssessmentSettingModal.removeFavourite",
                )}
              </StyledButton>
            </Box>
          </Box>
        )}
      </Flex>
      {showRemoveConfirmationModal && (
        <RemoveFavouriteAssessmentSettingConfirmationModal
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </Form>
  );
};
