import React, { useState } from "react";
import * as firebase from "firebase/app";

//import { useTranslation } from "react-i18next";
//import { pathOr } from "ramda";

//import styled from "styled-components";
//import { darken } from "@material-ui/core/styles";

// import EmailIcon from "@material-ui/icons/EmailOutlined";

//import { Flex, Button, Box, Text, Divider } from "../../Atoms";
import { Box } from "../../Atoms";

import { Card, CardContent, GoogleLoginButton } from "../../Molecules";
import { EmailLinkForm } from "../../Organisms";

/*
const DividerWithText = styled(Flex)`
  position: relative;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;

const DividerText = styled(Text)`
  position: absolute;
  background-color: white;
  padding: 0 20px;
`;

const EmailLinkButton = styled(Button)`
  justify-content: flex-start;
  background-color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonBg".split("."), props)};
  color: ${props =>
    pathOr("inherit", "theme.custom.emailLinkButtonColor".split("."), props)};
  :hover {
    background-color: ${props =>
      darken(
        pathOr("#fff", "theme.custom.emailLinkButtonBg".split("."), props),
        0.15,
      )};
  }
`;
*/
export interface LoginFormProps {
  onError: (error?: firebase.auth.Error) => void;
  onSuccess: () => void;
  redirectRoute: string;
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  onError,
  onSuccess,
  redirectRoute,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emaiLinkFormVisibility, setEmaiLinkFormVisibility] = useState<boolean>(
    false,
  );

  const onLoading = (state: boolean) => setLoading(state);

  //const { t } = useTranslation();
  //const showEmailLinkForm = () => setEmaiLinkFormVisibility(true);
  const hideEmailLinkForm = () => setEmaiLinkFormVisibility(false);

  if (emaiLinkFormVisibility) {
    return (
      <EmailLinkForm
        onError={onError}
        onBack={hideEmailLinkForm}
        redirectRoute={redirectRoute}
      />
    );
  }

  return (
    <Card>
      <CardContent>
        <Box py={2}>
          <GoogleLoginButton
            onError={onError}
            onLoading={onLoading}
            onSuccess={onSuccess}
            disabled={loading}
          />

          {/*<Box my={4}>
            <DividerWithText
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <StyledDivider />
              <DividerText>
                {t("components.organisms.loginForm.dividerText")}
              </DividerText>
            </DividerWithText>
          </Box>

          <EmailLinkButton
            size="small"
            variant="contained"
            color="secondary"
            aria-label="EmailLinkButton"
            data-cy="login-button-enableemaillinkform"
            fullWidth
            disabled={loading}
            onClick={showEmailLinkForm}
          >
            <EmailIcon />

            <Box flex={1} textAlign="center">
              {t("components.organisms.loginForm.enableEmailLinkFormButton")}
            </Box>
          </EmailLinkButton>*/}
        </Box>
      </CardContent>
    </Card>
  );
};
