import React from "react";
import { pathOr } from "ramda";
import { useTranslation } from "react-i18next";

import { Flex, Box, Chip, Tab, Tabs as CTabs } from "components/Atoms";

import { GetEducationalPackageTeacher as EducationalPackage } from "../Queries/types/GetEducationalPackageTeacher";

export enum TabValue {
  activities = "activities",
  assessments = "assessments",
  eGoals = "eGoals",
}
interface Props {
  onChange: (value: TabValue) => void;
  value: TabValue;
  data?: EducationalPackage;
}

export const Tabs: React.FC<Props> = ({ data, onChange, value }) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, value: TabValue) => {
    onChange(value);
  };

  return (
    <CTabs
      textColor="primary"
      value={value}
      onChange={handleChange as any}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab
        value={TabValue.activities}
        label={
          <Flex>
            {t("containers.teacherEducationPackage.tabs.activities")}
            <Box ml={1}>
              <Chip
                color="primary"
                label={pathOr(
                  0,
                  "educationalPackage.data.activitiesCount".split("."),
                  data,
                )}
              />
            </Box>
          </Flex>
        }
      />
      <Tab
        value={TabValue.assessments}
        label={t("containers.teacherEducationPackage.tabs.assessments")}
      />
      <Tab
        value={TabValue.eGoals}
        label={t("containers.teacherEducationPackage.tabs.eGoals")}
      />
    </CTabs>
  );
};
