import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "components/Hooks/firebase-hooks";

import { useHistory } from "react-router";
import { NavigationComponent } from "typings";

import { Box, Flex, Loader } from "components/Atoms";

import { CardGroup } from "components/Contexts/CardGroup";

import {
  GET_LAST_SELECTED_GRADE_NUMBER,
  Hero,
  Queries,
  SurveyCardCard,
} from "./components";

import { useQuery } from "@apollo/client";
import { AudioPlayerContext } from "components/Contexts/AudioPlayerContext";
import { GradeNumberSelect } from "./components/GradeNumberSelect";
import {
  GetDigipolkuSurveyGroup2,
  GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys,
  GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys_surveyQuestions,
} from "./components/Queries/types/GetDigipolkuSurveyGroup2";
import { GetLastSelectedGradeNumber } from "./components/Queries/types/GetLastSelectedGradeNumber";
import { useTranslation } from "react-i18next";

interface DigipolkuProps {}

const Digipolku: NavigationComponent<DigipolkuProps> = () => {
  const { analytics } = useAnalytics();
  const history = useHistory();

  const [playingUrl, setPlayingUrl] = useState<string | null | undefined>();
  const [ref, setRef] = useState();
  const [gradeNumber, setGradeNumber] = useState<number | undefined>(1);
  const { t } = useTranslation();

  analytics.logEvent("page_view" as string, {
    page_title: "Oppijan digipolku 2.0",
    page_path: history.location.pathname,
  });

  const {
    loading: loadingLastSelectedGradeNumber,
    data: dataLastSelectedGradeNumber,
  } = useQuery<GetLastSelectedGradeNumber>(GET_LAST_SELECTED_GRADE_NUMBER);

  useEffect(() => {
    if (dataLastSelectedGradeNumber?.myprofile.data?.lastSelectedGradeNumber) {
      setGradeNumber(
        dataLastSelectedGradeNumber?.myprofile.data?.lastSelectedGradeNumber,
      );
    }
  }, [dataLastSelectedGradeNumber]);

  const gradingScale = useMemo(() => ((gradeNumber ?? 0) > 2 ? 5 : 3), [
    gradeNumber,
  ]);

  const mapDigipolkuSurveys = useCallback(
    (data?: GetDigipolkuSurveyGroup2) => {
      if (!data || !data.digipolkuSurveyGroup2) return [];

      const digipolkuSurveys =
        [...data.digipolkuSurveyGroup2.data.surveys] || [];

      if (!digipolkuSurveys || !digipolkuSurveys.length) return [];
      // console.log("changed: surveys", digipolkuSurveys);

      const filteredSurveys: GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys[] = [];
      const allSurveyQuestions: GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys_surveyQuestions[] = [];

      if (digipolkuSurveys && digipolkuSurveys.length > 0) {
        [...digipolkuSurveys].forEach(survey => {
          if (survey.name === "selfAssessment") {
            survey.surveyQuestions.forEach(q => allSurveyQuestions.push(q));
          } else {
            filteredSurveys.push(survey);
          }
        });

        // console.log(allSurveyQuestions);

        if (allSurveyQuestions.length > 0) {
          const fakeSurvey: Partial<GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys> = {
            id: "selfAssessment",
            name: t("containers.digipolku.surveyHeader.selfAssessment"),
            surveyQuestions: allSurveyQuestions,
            __typename: "Survey",
          };
          filteredSurveys.push(
            fakeSurvey as GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys,
          );
        }
      }

      return (
        <CardGroup
          defaultOpen={filteredSurveys.length > 0 ? filteredSurveys[0].id : ""}
        >
          {filteredSurveys.map(survey => (
            <Box key={survey.id} my={2}>
              <SurveyCardCard
                scrollContainer={ref}
                survey={survey}
                gradingScale={gradingScale}
              />
            </Box>
          ))}
        </CardGroup>
      );
    },
    [gradingScale, ref, t],
  );

  return (
    <Flex
      height="85vh"
      overflow="auto"
      flexDirection="column"
      ref={(newRef: any) => setRef(newRef)}
    >
      <Hero gradeNumber={gradeNumber} />

      <Flex py={[4, 6]} flexDirection="column">
        {ref && (
          <Queries>
            {({
              digipolku: { getDigipolkuSurveyGroup, loading, data },
              updateSelectedGradeNumber,
            }) => {
              const updateGradeNumber = (value: number) => {
                setGradeNumber(value);
                updateSelectedGradeNumber(value);
              };

              return (
                <AudioPlayerContext.Provider
                  value={{ playingUrl, setPlayingUrl }}
                >
                  <Box px={[0, 2, 6]}>
                    <GradeNumberSelect
                      gradeNumber={gradeNumber}
                      onChange={value => updateGradeNumber(value)}
                      getDigipolkuSurveyGroup={getDigipolkuSurveyGroup}
                    />
                    {(loading || loadingLastSelectedGradeNumber) && <Loader />}
                    {mapDigipolkuSurveys(data)}
                  </Box>
                </AudioPlayerContext.Provider>
              );
            }}
          </Queries>
        )}
      </Flex>
    </Flex>
  );
};

Digipolku.navigationOptions = async ({ t }) => {
  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "digipolku",
        label: t("globals.digipolku2"),
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default Digipolku;
