import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ButtonBase from "@material-ui/core/ButtonBase";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import { Flex, Text, Box, Chip, Tooltip } from "components/Atoms";
import {
  DropdownMenu,
  DropdownOptionItem,
} from "components/Molecules/DropdownMenu";

import { EditGoalTemplateModal } from "components/Organisms/EditGoalTemplateModal";

import {
  GetSchoolTemplates_allGoalTemplateFoldersBySchool_data as Folder,
  GetSchoolTemplates_allGoalTemplateFoldersBySchool_data_goalTemplates as GoalTemplate,
} from "../Queries/types/GetSchoolTemplates";

import { TemplateItem } from "../TemplateItem";

import UserContext from "components/Contexts/UserContext";

import { RemoveTemplateItemConfirmationModal } from "../RemoveTemplateItemConfirmationModal";
import { GoalTemplateFolderType } from "typings/graphql-global-types";

export const FolderContainer = styled(Flex)<{ parent: boolean }>`
  background-color: ${props => (props.parent ? "#f6fbfd" : "inherit")};
`;

export const ButtonArea = styled(ButtonBase)`
  display: flex;
  height: 50px;
  padding-left: ${props => props.theme.spacing(2)}px;
  flex: 1;
  justify-content: flex-start;
`;

interface Props {
  school: { id: string; name: string };
  folder: Folder;
  onEdit: (folder: Folder) => void;
  onAddFolder: (folder: Folder) => void;
  onRemove: (folder: Folder) => void;
  onRemoveGoalTemplate: (folder: GoalTemplate) => void;
  refetch: any;
  type: GoalTemplateFolderType;
}

export enum FolderContextMenuItems {
  add = "add",
  edit = "edit",
  addFolder = "addFolder",
  remove = "remove",
}

export const FolderItem: React.FC<Props> = ({
  folder,
  school,
  refetch,
  onEdit,
  onAddFolder,
  onRemove,
  onRemoveGoalTemplate,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [showGoalTemplateModal, setShowGoalTemplateModal] = useState(false);
  const [selectedGoalTemplateId, setSelectedGoalTemplateId] = useState<
    string | undefined
  >();
  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const isFolderOwner = user?.uid === folder.user.id;

  const addFolder = folder.parent
    ? []
    : [
        {
          id: FolderContextMenuItems.addFolder,
          label: t("containers.settings.goalTemplates.addFolder"),
          disabled: !isFolderOwner,
          tooltip: !isFolderOwner
            ? t("containers.settings.goalTemplates.addFolderDisabledTooltip")
            : undefined,
        },
      ];

  const options = [
    {
      id: FolderContextMenuItems.add,
      label: t("containers.settings.goalTemplates.addNewGoalTemplate"),
    },
    ...addFolder,
    {
      id: FolderContextMenuItems.edit,
      label: t("containers.settings.goalTemplates.editFolder"),
      disabled: !isFolderOwner,
      tooltip: !isFolderOwner
        ? t("containers.settings.goalTemplates.editFolderDisabledTooltip")
        : undefined,
    },
    {
      id: FolderContextMenuItems.remove,
      label: t("containers.settings.goalTemplates.removeFolder"),
      disabled:
        !isFolderOwner ||
        folder.childrens.length > 0 ||
        folder.goalTemplates.length > 0,
      tooltip:
        !isFolderOwner ||
        folder.childrens.length > 0 ||
        folder.goalTemplates.length > 0
          ? t("containers.settings.goalTemplates.removeFolderDisabledTooltip")
          : undefined,
    },
  ];

  const onSelect = (o: DropdownOptionItem) => {
    if (o.id === FolderContextMenuItems.add) {
      setSelectedGoalTemplateId(undefined);
      setShowGoalTemplateModal(true);
    }
    if (o.id === FolderContextMenuItems.edit) onEdit(folder);
    if (o.id === FolderContextMenuItems.remove)
      setShowRemoveConfirmationModal(true);
    if (o.id === FolderContextMenuItems.addFolder) onAddFolder(folder);
  };

  const onEditGoalTemplate = (template: GoalTemplate) => {
    setSelectedGoalTemplateId(template.id);
    setShowGoalTemplateModal(true);
  };

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result) {
      onRemove(folder);
    }
    setShowRemoveConfirmationModal(false);
  };

  const noGoalTemplates = !folder.goalTemplates.length;

  const onTemplateModalClosed = (result: boolean) => {
    if (result) refetch();
    setShowGoalTemplateModal(false);
    setOpen(true);
  };

  return (
    <>
      <FolderContainer
        justifyContent="space-between"
        alignItems="center"
        parent={!folder.parent}
      >
        <ButtonArea onClick={() => setOpen(!open)}>
          {open ? <FolderOpenIcon /> : <FolderIcon />}
          <Box ml={2}>
            <Text color="textPrimary">{folder.name}</Text>
          </Box>
        </ButtonArea>
        <Flex alignItems="center">
          {!noGoalTemplates && (
            <Tooltip
              title={t(
                folder.goalTemplates.length === 1
                  ? "containers.settings.goalTemplates.templatesCountTooltip"
                  : "containers.settings.goalTemplates.templatesCountPluralTooltip",
                { count: folder.goalTemplates.length },
              )}
            >
              <Box>
                <Chip color="primary" label={folder.goalTemplates.length} />
              </Box>
            </Tooltip>
          )}
          <DropdownMenu onSelect={onSelect} options={options} />
        </Flex>
      </FolderContainer>
      {open && noGoalTemplates && !folder.childrens.length && (
        <Box p={2} pl={7.5}>
          <Text>{t("containers.settings.goalTemplates.noGoalTemplates")}</Text>
        </Box>
      )}

      {open && (
        <Box pl={5}>
          {(folder.childrens || []).map(folder => (
            <FolderItem
              key={folder.id}
              refetch={refetch}
              school={school}
              folder={{ ...folder, childrens: [] }}
              onEdit={onEdit}
              onAddFolder={onAddFolder}
              onRemove={onRemove}
              onRemoveGoalTemplate={onRemoveGoalTemplate}
              type={type}
            />
          ))}
        </Box>
      )}

      {open &&
        !noGoalTemplates &&
        folder.goalTemplates.map(t => (
          <TemplateItem
            key={t.id}
            onEdit={onEditGoalTemplate}
            onRemove={onRemoveGoalTemplate}
            template={t}
          />
        ))}

      {showGoalTemplateModal && (
        <EditGoalTemplateModal
          goalTemplateId={selectedGoalTemplateId}
          open={showGoalTemplateModal}
          onModalClosed={onTemplateModalClosed}
          folder={folder}
          school={school}
          type={type}
        />
      )}
      {showRemoveConfirmationModal && (
        <RemoveTemplateItemConfirmationModal
          text={t("containers.settings.goalTemplates.removeFolderConfirmation")}
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </>
  );
};
