import { useEffect, useState } from "react";
import WebFont from "webfontloader";

let retryCount = 2;
export default (config: WebFont.Config): { loading: boolean } => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = (timeout: number) => {
      WebFont.load({
        ...config,
        timeout,
        active: () => {
          retryCount = 0;
          setLoading(false);
        },
        inactive: () => {
          setLoading(false);
          if (retryCount > 0) {
            retryCount -= 1;
            load(30000);
          }
        },
      });
    };
    load(3000);
  }, [config, setLoading]);

  return {
    loading,
  };
};
