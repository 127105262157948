import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import { Modal } from "../../Molecules";
import { Flex, Box, Text } from "components/Atoms";
import MessageTimeLine from "components/Molecules/MessageTimeline";

import { CommentForm } from "./Form";
import { Queries } from "./Queries";
import { SubmitCommentConfirmationModal } from "./SubmitCommentConfirmationModal";

import { SurveyQuestionAnswerCommentCreateInput } from "typings/graphql-global-types";

export interface CommentsModalProps {
  surveyQuestionAnswerId: string;
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

export const EditCommentsModal: React.FunctionComponent<CommentsModalProps> = ({
  surveyQuestionAnswerId,
  open,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries surveyQuestionAnswerId={surveyQuestionAnswerId}>
      {({ comments: { loading, data }, create }) => {
        return (
          <Formik
            enableReinitialize
            initialValues={{
              surveyQuestionAnswerId,
              text: "",
            }}
            validationSchema={Yup.object().shape({
              text: Yup.string()
                .required(t("globals.validations.commentRequired"))
                .max(400, t("globals.validations.commentMax")),
            })}
            onSubmit={async (
              values: SurveyQuestionAnswerCommentCreateInput,
              { setSubmitting },
            ) => {
              setSubmitting(true);

              await create(values);

              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              const onSubmit = () => {
                handleSubmit();
              };

              const onConfirmModalClosed = (result: boolean) => {
                setConfirm(false);
                if (result) {
                  onSubmit();
                }
              };

              return (
                <>
                  <Modal
                    height="650px"
                    name="edit-comment"
                    loading={isSubmitting || loading}
                    primaryAction={{
                      text: t("globals.save"),
                      onClick: () => setConfirm(true),
                      disabled: !isValid,
                    }}
                    secondaryAction={{
                      text: t("globals.cancel"),
                      onClick: onCancel,
                    }}
                    title={t("components.organisms.editCommentsModal.titleNew")}
                    open={open}
                  >
                    <Box width="100%">
                      <Box marginTop={2}>
                        <Text variant="caption">
                          {t(
                            "components.organisms.editCommentsModal.learnerName",
                          )}
                        </Text>
                        {data && (
                          <Text variant="body1">{`${data.user.givenName} ${data.user.familyName}`}</Text>
                        )}
                      </Box>

                      <Box marginTop={1.5}>
                        <Text variant="caption">
                          {t("components.organisms.editCommentsModal.name")}
                        </Text>
                        {data && (
                          <Text variant="body1">{`${data.surveyQuestion.name}`}</Text>
                        )}
                      </Box>

                      <Flex display="flex" marginTop={1.5}>
                        <CommentForm {...props} />
                      </Flex>

                      <Box marginTop={1.5}>
                        <Text variant="caption">
                          {t("components.organisms.editCommentsModal.comments")}
                        </Text>
                        {data?.comments?.length ? (
                          <MessageTimeLine comments={data?.comments} />
                        ) : (
                          <Text>
                            {t(
                              "components.organisms.editCommentsModal.noComments",
                            )}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Modal>
                  {confirm && data && (
                    <SubmitCommentConfirmationModal
                      learnerName={`${data.user.givenName} ${data.user.familyName}`}
                      onModalClosed={onConfirmModalClosed}
                    />
                  )}
                </>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
