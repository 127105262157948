import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const GoogleClassroomIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon data-cy="classroom-icon" {...props} viewBox="0 0 36.369 30.307">
    <path
      d="M34.853,2H1.515A1.515,1.515,0,0,0,0,3.515V30.792a1.515,1.515,0,0,0,1.515,1.515H34.853a1.515,1.515,0,0,0,1.515-1.515V3.515A1.515,1.515,0,0,0,34.853,2M33.338,29.276H30.307V27.761H22.73v1.515H3.031V5.031H33.338V29.276M15.593,13.683a2.591,2.591,0,1,1,2.591,2.606,2.591,2.591,0,0,1-2.591-2.606m-6.94,2.394a1.955,1.955,0,1,1,1.955,1.94,1.96,1.96,0,0,1-1.955-1.94m15.154,0a1.955,1.955,0,1,1,1.955,1.94,1.955,1.955,0,0,1-1.955-1.94m6.5,5.834v1.3H6.061v-1.3c0-1.424,2.349-2.591,4.546-2.591a6.823,6.823,0,0,1,2.425.455,8.056,8.056,0,0,1,5.152-1.758,8.056,8.056,0,0,1,5.152,1.758,6.823,6.823,0,0,1,2.425-.455C27.958,19.321,30.307,20.487,30.307,21.912Z"
      transform="translate(0 -2)"
    />
  </SvgIcon>
);

GoogleClassroomIcon.displayName = "GoogleClassroomIcon";
