import * as React from "react";

import styled from "styled-components";
import {
  palette,
  PaletteProps,
  spacing,
  SpacingProps,
  typography,
  TypographyProps,
  borders,
  BordersProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  shadows,
  ShadowsProps,
  sizing,
  SizingProps,
} from "@material-ui/system";

export const boxStyles: Array<(...args: any[]) => any> = [
  display,
  spacing,
  sizing,
  flexbox,
  borders,
  shadows,
  palette,
  typography,
];

export type BoxProps = DisplayProps &
  SizingProps &
  SpacingProps &
  FlexboxProps &
  PaletteProps &
  BordersProps &
  ShadowsProps &
  TypographyProps & { ref?: any; id?: string };

export const Box: React.FC<BoxProps> = styled.div`
  ${boxStyles}
`;

Box.displayName = "Box";
