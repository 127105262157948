import React from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery, QueryLazyOptions } from "@apollo/client";

import {
  GetEducationalPackageAssessmentsByUser,
  GetEducationalPackageAssessmentsByUserVariables,
} from "./types/GetEducationalPackageAssessmentsByUser";

import {
  GetDigipolkuSurveyGroupAnswers,
  GetDigipolkuSurveyGroupAnswersVariables,
} from "./types/GetDigipolkuSurveyGroupAnswers";

import {
  GetDigipolkuSurveyGroupAnswers2,
  GetDigipolkuSurveyGroupAnswers2Variables,
} from "./types/GetDigipolkuSurveyGroupAnswers2";

import {
  GetOwnGoalSurveyGroupAnswers,
  GetOwnGoalSurveyGroupAnswersVariables,
} from "./types/GetOwnGoalSurveyGroupAnswers";

export const GET_ASSESSMENTS_TABLE = gql`
  query GetEducationalPackageAssessmentsByUser($filters: IdInputType!) {
    educationalPackageAssessmentOptimized(filters: $filters) {
      data {
        id
        name
        learners {
          id
          givenName
          familyName
          photoUrl
        }
        gradeLevel {
          id
          name
          gradeLevelGroup {
            id
            name
          }
        }
        activities {
          id
          name
          verbalAssessmentSetting {
            id
            learnerHelperText
          }
          educationalPackageGoals {
            id
            name
            eGoals {
              id
            }
            numericAssessmentSetting {
              id
              scale
              learnerScaleTexts
              teacherScaleTexts
            }
          }
          learningAchievements {
            id
            user {
              id
            }
            updatedAt
            verbalAssessments {
              id
              type
              assessmentText
              user {
                id
                givenName
                familyName
              }
            }
            numericAssessments {
              id
              value
              educationalPackageGoalId
              type
            }
          }
        }
      }
    }
    digipolkuSurveyGroup {
      data {
        id
        name
      }
    }
    digipolkuSurveyGroup2 {
      data {
        id
        name
      }
    }
  }
`;

export const GET_DIGIPOLKU = gql`
  query GetDigipolkuSurveyGroupAnswers(
    $filters: SurveyQuestionFilterInputType!
  ) {
    allGradeLevelGroups {
      data {
        id
        name
      }
    }
    digipolkuSurveyGroup {
      data {
        id
        surveys {
          id
          name
          createdAt
          gradeLevelGroup {
            id
          }
          surveyQuestions {
            id
            name
            type
            survey {
              id
              name
            }
            surveyQuestionAnswers(filters: $filters) {
              id
              numeric
              verbalText
              comments {
                id
                text
                createdAt
                user {
                  id
                }
              }
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DIGIPOLKU2 = gql`
  query GetDigipolkuSurveyGroupAnswers2(
    $filters: SurveyQuestionFilterInputType!
  ) {
    digipolkuSurveyGroup2 {
      data {
        id
        surveys(filters: {name: "selfAssessment"}) {
          id
          name
          createdAt
          gradeLevelGroup {
            id
          }
          gradeNumber
          surveyQuestions {
            id
            name
            type
            description
            survey {
              id
              name
            }
            surveyQuestionAnswers(filters: $filters) {
              id
              numeric
              verbalText
              comments {
                id
                text
                createdAt
                user {
                  id
                }
              }
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_OWNGOAL = gql`
  query GetOwnGoalSurveyGroupAnswers(
    $filters: OwnGoalsSurveyGroupFilterInputType!
    $answerFilters: SurveyQuestionFilterInputType!
  ) {
    ownGoalsSurveyGroup(filters: $filters) {
      data {
        id
        surveys {
          id
          name
          createdAt
          gradeLevelGroup {
            id
          }
          surveyQuestions {
            id
            name
            type
            survey {
              id
              name
            }
            surveyQuestionAnswers(filters: $answerFilters) {
              id
              numeric
              verbalText
              comments {
                id
                text
                createdAt
                user {
                  id
                }
              }
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;
interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

export interface AssessmentQueriesProps {
  educationalPackage: {
    loading: boolean;
    data: GetEducationalPackageAssessmentsByUser | undefined;
  };
  digipolku: {
    getDigipolku: (
      options?:
        | QueryLazyOptions<GetDigipolkuSurveyGroupAnswersVariables>
        | undefined,
    ) => void;
    loading: boolean;
    data: GetDigipolkuSurveyGroupAnswers | undefined;
  };
  digipolku2: {
    getDigipolku2: (
      options?:
        | QueryLazyOptions<GetDigipolkuSurveyGroupAnswers2Variables>
        | undefined,
    ) => void;
    loading: boolean;
    data: GetDigipolkuSurveyGroupAnswers2 | undefined;
  };
  ownGoals: {
    getOwnGoals: (
      options?:
        | QueryLazyOptions<GetOwnGoalSurveyGroupAnswersVariables>
        | undefined,
    ) => void;
    loading: boolean;
    data: GetOwnGoalSurveyGroupAnswers | undefined;
  };
}

interface Props {
  children: RenderProp<AssessmentQueriesProps>;
  educationalPackageId: string;
}

export const AssessmentsQueries: React.FC<Props> = ({
  children,
  educationalPackageId,
}) => {
  const { loading, /*error,*/ data } = useQuery<
    GetEducationalPackageAssessmentsByUser,
    GetEducationalPackageAssessmentsByUserVariables
  >(GET_ASSESSMENTS_TABLE, {
    variables: {
      filters: {
        id: educationalPackageId,
      },
    },
  });

  const [
    getDigipolku,
    { loading: loadingDigipolku, data: dataDigipolku },
  ] = useLazyQuery<
    GetDigipolkuSurveyGroupAnswers,
    GetDigipolkuSurveyGroupAnswersVariables
  >(GET_DIGIPOLKU);

  const [
    getDigipolku2,
    { loading: loadingDigipolku2, data: dataDigipolku2 },
  ] = useLazyQuery<
    GetDigipolkuSurveyGroupAnswers2,
    GetDigipolkuSurveyGroupAnswers2Variables
  >(GET_DIGIPOLKU2);

  const [
    getOwnGoals,
    { loading: loadingOwnGoals, data: dataOwnGoals },
  ] = useLazyQuery<
    GetOwnGoalSurveyGroupAnswers,
    GetOwnGoalSurveyGroupAnswersVariables
  >(GET_USER_OWNGOAL);

  return children({
    educationalPackage: { loading, data },
    digipolku: { getDigipolku, loading: loadingDigipolku, data: dataDigipolku },
    digipolku2: { getDigipolku2, loading: loadingDigipolku2, data: dataDigipolku2 },
    ownGoals: { getOwnGoals, loading: loadingOwnGoals, data: dataOwnGoals },
  });
};
