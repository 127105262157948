import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  GetVerbalAssessmentSetting,
  GetVerbalAssessmentSettingVariables,
  GetVerbalAssessmentSetting_verbalAssessmentSetting_data as VerbalAssessmentSetting,
} from "./types/GetVerbalAssessmentSetting";

import {
  GetVerbalAssessmentSettingFavourites,
  GetVerbalAssessmentSettingFavouritesVariables,
  GetVerbalAssessmentSettingFavourites_favouriteVerbalAssessmentSettings_data as FavouriteVerbalAssessmentSettings,
  GetVerbalAssessmentSettingFavourites_popularVerbalAssessmentSettings_data as PopularVerbalAssessmentSettings,
} from "./types/GetVerbalAssessmentSettingFavourites";

import {
  UpsertVerbalAssessmentSetting,
  UpsertVerbalAssessmentSettingVariables,
} from "./types/UpsertVerbalAssessmentSetting";

import { VerbalAssessmentSettingInput } from "typings/graphql-global-types";

export const GET_VERBALASSESSMENTSETTING = gql`
  query GetVerbalAssessmentSetting($filters: IdInputType!) {
    verbalAssessmentSetting(filters: $filters) {
      data {
        id
        name
        learnerHelperText
        favourite
        defaultSetting
      }
    }
  }
`;

export const GET_FAVOURITES = gql`
  query GetVerbalAssessmentSettingFavourites(
    $filters: VerbalAssessmentSettingFilterInputType
  ) {
    favouriteVerbalAssessmentSettings(filters: $filters) {
      data {
        id
        name
        learnerHelperText
        favourite
        defaultSetting
        user {
          id
          emailAddress
        }
      }
    }
    popularVerbalAssessmentSettings {
      data {
        id
        name
        learnerHelperText
        favourite
        user {
          id
          emailAddress
        }
      }
    }
  }
`;

export const UPSERT_VERBAL_ASSESSMENT_SETTING = gql`
  mutation UpsertVerbalAssessmentSetting(
    $input: VerbalAssessmentSettingInput!
  ) {
    upsertVerbalAssessmentSetting(input: $input) {
      data {
        id
        name
        learnerHelperText
        favourite
        defaultSetting
      }
    }
  }
`;

export const UPDATE_FAVOURITES = gql`
  query UpdateVerbalSettingsFavourites(
    $filters: VerbalAssessmentSettingFilterInputType
  ) {
    favouriteVerbalAssessmentSettings(filters: $filters) {
      data {
        id
        name
        learnerHelperText
        favourite
        defaultSetting
        user {
          id
          emailAddress
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  verbalAssessmentSettings: {
    loading: boolean;
    data: VerbalAssessmentSetting | undefined;
    favouriteVerbalAssessmentSettings: FavouriteVerbalAssessmentSettings[];
    popularVerbalAssessmentSettings: PopularVerbalAssessmentSettings[];
  };
  verbalAssessmentSetting: {
    upsert: (
      input: VerbalAssessmentSettingInput,
    ) => Promise<SingleExecutionResult<UpsertVerbalAssessmentSetting>>;
  };
}

interface QueriesProps {
  educationalPackageId?: string | null;
  assessmentSettingId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  assessmentSettingId,
  educationalPackageId,
}) => {
  const { loading: loadinFavs, data: favData } = useQuery<
    GetVerbalAssessmentSettingFavourites,
    GetVerbalAssessmentSettingFavouritesVariables
  >(GET_FAVOURITES, {
    fetchPolicy: "network-only",
    variables: {
      filters: educationalPackageId ? { educationalPackageId } : null,
    },
  });

  const [loadData, { loading, data }] = useLazyQuery<
    GetVerbalAssessmentSetting,
    GetVerbalAssessmentSettingVariables
  >(GET_VERBALASSESSMENTSETTING);

  useEffect(() => {
    const fetchData = async () => {
      if (assessmentSettingId) {
        await loadData({
          variables: {
            filters: {
              id: assessmentSettingId,
            },
          },
        });
      }
    };
    fetchData();
  }, [assessmentSettingId, loadData]);

  const [upsertVerbalAssessmentSetting, ,] = useMutation<
    UpsertVerbalAssessmentSetting,
    UpsertVerbalAssessmentSettingVariables
  >(UPSERT_VERBAL_ASSESSMENT_SETTING);

  const upsert = (input: VerbalAssessmentSettingInput) => {
    return upsertVerbalAssessmentSetting({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: UPDATE_FAVOURITES,
          variables: {
            filters: educationalPackageId ? { educationalPackageId } : null,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return children({
    verbalAssessmentSettings: {
      loading: loading || loadinFavs,
      data: data && data.verbalAssessmentSetting.data,
      favouriteVerbalAssessmentSettings:
        (favData && favData.favouriteVerbalAssessmentSettings.data) || [],
      popularVerbalAssessmentSettings:
        (favData && favData.popularVerbalAssessmentSettings.data) || [],
    },
    verbalAssessmentSetting: {
      upsert,
    },
  });
};
