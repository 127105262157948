import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";

import styled from "styled-components";
import * as firebase from "firebase/app";
import "firebase/auth";

import { Loader } from "components/Atoms";

import { useAuth } from "components/Hooks/firebase-hooks";
import UserContext from "components/Contexts/UserContext";

import Router from "router";

const FullscreenLoader = styled(Loader)`
  height: 100vh;
  width: 100vw;
`;

const App: React.FC = () => {
  const { user, loading, roles, error } = useAuth(firebase.auth());
  const apolloClient = useApolloClient();

  useEffect(() => {
    const clearStore = async () => {
      await apolloClient.clearStore();
    };
    if (!user) clearStore();
  }, [user, apolloClient]);

  if (loading) {
    return <FullscreenLoader />;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <UserContext.Provider
      value={{
        user: user ? { ...user, roles } : null,
        loading,
        signOut: () => firebase.auth().signOut(),
      }}
    >
      <Router />
    </UserContext.Provider>
  );
};

export default App;
