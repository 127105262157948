import React from "react";
import styled from "styled-components";

import AnimateHeight from "react-animate-height";

import { Flex, Box, Button } from "components/Atoms";
import { Card, CardHeader, CardActions } from "components/Molecules/Card";

import { DropdownOptionItem } from "components/Molecules/DropdownMenu";

import { CardItemContext } from "components/Contexts/CardGroup";

import { GetEducationalPackageNewActivities_allActivities_data as Activity } from "../../../NewActivityListQueries/types/GetEducationalPackageNewActivities";

export interface ActivityCardProps {
  activity: Activity;
  classroomImported?: boolean;
  openUrl?: string;
  openLabel?: string;
  onContextMenuChanged?: (o: DropdownOptionItem) => void;
  hideContextMenu?: boolean;
  height?: string;
  primaryAction?: {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  onSelfAssessmentChanged?: (state: boolean) => void;
  isDragging?: boolean;
}

const ResponsiveCard = styled(Card)`
  width: 100%;
  position: relative;
  ${props => props.theme.breakpoints.down("xs")} {
    border-radius: 0;
  }
`;

type ExpandedProp = {
  expanded?: boolean;
  height?: string;
};

const Collapsible = styled.div<ExpandedProp>`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;
`;

export const ActivityCard: React.FunctionComponent<ActivityCardProps> = ({
  activity,
  children,
  primaryAction,
  isDragging,
  ...props
}) => {
  const id = activity.id;
  const title = activity.name;

  return (
    <CardItemContext.Consumer>
      {({ expandedItem, toggleExpanded }) => (
        <ResponsiveCard {...props} data-cy="activity-card">
          <StyledCardHeader
            onClick={e => toggleExpanded(id === expandedItem ? "" : id)}
            title={
              (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <Box>{title}</Box>
                  </Flex>
                </Flex>
              ) as any
            }
            titleTypographyProps={{ variant: "h3" }}
          />
          <Collapsible expanded={id === expandedItem}>
            <AnimateHeight
              duration={!isDragging ? 200 : 0}
              height={id === expandedItem && !isDragging ? "auto" : 0}
            >
              <Box>{children}</Box>
              <CardActions>
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {primaryAction && (
                    <Button
                      data-cy="primary-action-button"
                      disabled={primaryAction.disabled}
                      onClick={primaryAction.onClick}
                    >
                      {primaryAction.text}
                    </Button>
                  )}
                </Flex>
              </CardActions>
            </AnimateHeight>
          </Collapsible>
        </ResponsiveCard>
      )}
    </CardItemContext.Consumer>
  );
};
