import React, { useState } from "react";

import { lighten } from "@material-ui/core/styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

import { Flex, Box } from "../../Atoms";
import { Modal } from "../../Molecules";
import { Queries } from "./Queries";
import { GetDriveFiles_listDriveFiles_data_files as DriveFile } from "./Queries/types/GetDriveFiles";

const StyledListItem = styled(ListItem)`
  color: #000;
  &.Mui-selected {
    background-color: #e0eef8;
    border-left: 6px solid
      ${props => lighten(props.theme.palette.primary.main, 0.5)};
    padding-left: 10px;
  }
  &:hover {
    background-color: ${lighten("#E0EEF8", 0.3)};
  }
`;

const SquareAvatar = styled(Avatar)`
  border-radius: inherit;
`;

export interface GoogleDrivePickerModalProps {
  activityId: string;
  open: boolean;
  title?: string;
  onModalClosed: (result: boolean) => void;
  onAdd: (fileUplods: DriveFile) => void;
  loading: boolean;
}

export const GoogleDrivePickerModal: React.FunctionComponent<GoogleDrivePickerModalProps> = ({
  activityId,
  onModalClosed,
  open,
  onAdd,
  loading: addLoading,
  title,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<
    DriveFile | null | undefined
  >();
  const [itemTouching, setItemTouching] = useState<
    DriveFile | null | undefined
  >();
  const [navStack, setNavStack] = useState<string[]>([]);

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries activityId={activityId}>
      {({ driveFiles: { loading, data, refetch } }) => {
        const onPrimaryAction = () => {
          if (selectedFile) onAdd(selectedFile);
        };

        const openFolder = (file: DriveFile) => {
          setSelectedFile(null);
          setNavStack([...navStack, file.id]);
          refetch({ filters: { folderId: file.id } });
        };
        const onItemDoubleClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          file: DriveFile,
        ) => {
          if (file.mimeType === "application/vnd.google-apps.folder") {
            e.preventDefault();
            openFolder(file);
          }
        };

        const onItemTouchStart = (
          event: React.TouchEvent<HTMLDivElement>,
          file: DriveFile,
        ) => {
          setItemTouching(file);
        };

        const onItemClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          file: DriveFile,
        ) => {
          if (
            file.mimeType === "application/vnd.google-apps.folder" &&
            itemTouching === file
          ) {
            e.preventDefault();
            openFolder(file);
            setItemTouching(null);
            return;
          }
          e.preventDefault();
          setSelectedFile(file);
        };

        const onBackClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ) => {
          const stack = [...navStack];
          stack.splice(-1, 1);
          if (stack.length) {
            refetch({ filters: { folderId: stack[stack.length - 1] } });
          } else {
            refetch({ filters: {} });
          }
          setNavStack(stack);
          setSelectedFile(null);
        };

        return (
          <Modal
            error={undefined}
            loading={loading || addLoading}
            primaryAction={{
              text: t("globals.add"),
              onClick: onPrimaryAction,
              disabled:
                !(data && data.length > 0) ||
                !selectedFile ||
                selectedFile.mimeType ===
                  "application/vnd.google-apps.folder" ||
                loading,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={
              title
                ? title
                : t("components.organisms.googleDrivePickerModal.title")
            }
            open={open}
          >
            <Flex flexDirection="column" flex={1}>
              <Box>
                <List aria-label="drive files">
                  {navStack.length > 0 && (
                    <ListItem
                      divider
                      button
                      onClick={event => onBackClick(event)}
                    >
                      <ListItemAvatar>
                        <Flex alignItems="center">
                          <ChevronLeftIcon fontSize="large" />
                        </Flex>
                      </ListItemAvatar>
                      <ListItemText
                        primary={t(
                          "components.organisms.googleDrivePickerModal.back",
                        )}
                      />
                    </ListItem>
                  )}
                  {!loading &&
                    data.map((f, i) => (
                      <StyledListItem
                        divider
                        key={f.id}
                        button
                        selected={selectedFile === f}
                        onClick={event => onItemClick(event, f)}
                        onDoubleClick={event => onItemDoubleClick(event, f)}
                        onTouchStart={event => onItemTouchStart(event, f)}
                      >
                        <ListItemAvatar>
                          <SquareAvatar
                            alt={f.name}
                            src={`https://drive-thirdparty.googleusercontent.com/32/type/${f.mimeType}`}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={f.name} />
                      </StyledListItem>
                    ))}
                </List>
              </Box>
            </Flex>
          </Modal>
        );
      }}
    </Queries>
  );
};
