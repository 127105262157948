import { SingleExecutionResult, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  UpsertDigipolkuSurveyQuestionAnswer,
  UpsertDigipolkuSurveyQuestionAnswerVariables,
} from "./types/UpsertDigipolkuSurveyQuestionAnswer";

import { SurveyQuestionAnswerInput } from "typings/graphql-global-types";

const UPSERT_SURVEYQUESTIONS_ANSWER = gql`
  mutation UpsertDigipolku2SurveyQuestionAnswer(
    $input: [SurveyQuestionAnswerInput!]!
  ) {
    upsertSurveyQuestionAnswers(input: $input) {
      data {
        id
        numeric
        verbalText
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface SubmitQueries {
  answer: {
    update: (
      input: SurveyQuestionAnswerInput[],
    ) => Promise<SingleExecutionResult<UpsertDigipolkuSurveyQuestionAnswer>>;
    loading?: boolean;
  };
}

interface Props {
  children: RenderProp<SubmitQueries>;
}

export const SubmitQueries: React.FC<Props> = ({ children }) => {
  const [updateAnswer, { loading: updateAnswerLoading }] = useMutation<
    UpsertDigipolkuSurveyQuestionAnswer,
    UpsertDigipolkuSurveyQuestionAnswerVariables
  >(UPSERT_SURVEYQUESTIONS_ANSWER);

  const update = (input: SurveyQuestionAnswerInput[]) => {
    return updateAnswer({
      variables: {
        input,
      },
      // refetchQueries: [
      //   {
      //     query: GET_DIGIPOLKU2,
      //   },
      // ],
      // awaitRefetchQueries: true,
    });
  };

  return children({
    answer: { update, loading: updateAnswerLoading },
  });
};
