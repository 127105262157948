import React from "react";

import { useTranslation } from "react-i18next";
import { FastField } from "formik";
import Hidden from "@material-ui/core/Hidden";

import { Flex, NumericAssessmentInput } from "components/Atoms";

import { Cell, StyledInputLabel, FormValues } from "./index";

import { QuestionType } from "typings/graphql-global-types";

import { GetDigipolkuSurveyGroup_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
  values: FormValues;
  onChange: (field: string, value: number | null) => void;
}
//["40%"];
// ["100%", "100%", "40%"]
const AssessmentColumn = ({
  numericQuestions,
  width,
  values,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell hideMobile width={width} order={1} flexGrow={1}>
        <Hidden mdDown>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <StyledInputLabel htmlFor="surveyQuestionAnswers">
              {t("containers.digipolku.surveyForm.selfAssessment")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>
      {numericQuestions.map((q, i) => {
        const answer = values.surveyQuestionAnswers.find(
          a => a.surveyQuestionId === q.id,
        );

        if (!answer || q.type !== QuestionType.numeric) return null;
        return (
          <Cell
            key={`input-${q.id}`}
            py={2}
            showBorderBottomOnMobile
            divider={i < numericQuestions.length - 1}
            width={width}
            order={i + 2}
            flexGrow={1}
          >
            <Flex
              pl={["47px", "47px", 0]}
              pr={[2.5, 2.5, 0]}
              height="100%"
              alignItems="center"
            >
              <FastField name={`surveyQuestionAnswers.${i}.numeric`}>
                {() => (
                  <NumericAssessmentInput
                    type={"self"}
                    scale={3}
                    scaleTexts={null}
                    name={`surveyQuestionAnswers.${i}.numeric`}
                    key={answer.surveyQuestionId}
                    value={answer.numeric ? answer.numeric : null}
                    onChange={value =>
                      onChange(`surveyQuestionAnswers.${i}.numeric`, value)
                    }
                  />
                )}
              </FastField>
            </Flex>
          </Cell>
        );
      })}
    </>
  );
};

export default AssessmentColumn;
