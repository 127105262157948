import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { Flex, Box, Text } from "components/Atoms";

const DarkText = styled(Text)`
  color: #ececec;
  width: 100%;
  font-weight: 600;
`;

const TruncatedDarkText = styled(DarkText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledMapOutlinedIcon = styled(MapOutlinedIcon)`
  margin-top: -3px;
  fill: #ececec;
  color: #ececec;
`;

const Background = styled(Flex)`
  background-color: #dfeffa;
  background: url("/banner_welho.jpg") no-repeat left center;
  background-size: cover;
  overflow: auto;
`;

export const Hero: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Background minHeight={["170px", "170px", "200px", "250px"]}>
      <Flex px={[2, 3, 6]} pl={[3, 3]} py={[2, 6]} flex={1} bgcolor="#00000096">
        <Flex alignItems="center">
          <StyledMapOutlinedIcon />
          <Box width={["200px", "400px", "600px"]} ml={1.5}>
            <TruncatedDarkText variant="h3">
              {t("globals.digipolku")}
            </TruncatedDarkText>
          </Box>
        </Flex>
      </Flex>
    </Background>
  );
};
