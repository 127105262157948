import { ApolloError, useLazyQuery, useMutation, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  CreateActivity,
  CreateActivityVariables,
} from "./types/CreateActivity";
import {
  GetActivityEdit,
  GetActivityEditVariables,
} from "./types/GetActivityEdit";
import {
  GetEducationalPackageLearnersActivityEdit,
  GetEducationalPackageLearnersActivityEditVariables,
  GetEducationalPackageLearnersActivityEdit_educationalPackage_data_learners as Learner,
} from "./types/GetEducationalPackageLearnersActivityEdit";
import {
  UpdateActivity,
  UpdateActivityVariables,
} from "./types/UpdateActivity";

export const GET_EDUCATIONAL_PACKAGE_MEMBERS = gql`
  query GetEducationalPackageLearnersActivityEdit($filters: IdInputType!) {
    educationalPackage(filters: $filters) {
      data {
        id
        learners {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

export const GET_ACTIVITY = gql`
  query GetActivityEdit($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        name
        description
        status
        selfAssessment
        learners {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($input: ActivityUpdateInputType!) {
    updateActivity(input: $input) {
      data {
        id
        name
        description
        selfAssessment
        learners {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: ActivityCreateInputType!) {
    createActivity(input: $input) {
      data {
        id
        name
        description
        selfAssessment
        learners {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  educationalPackage: {
    loading: boolean;
    learners: Learner[];
  };
  activity: {
    loading: boolean;
    data: GetActivityEdit | undefined;
  };
  create: {
    mutation: (
      options?:
        | MutationFunctionOptions<CreateActivity, CreateActivityVariables>
        | undefined,
    ) => Promise<SingleExecutionResult<CreateActivity>>;
    error?: ApolloError;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<UpdateActivity, UpdateActivityVariables>
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateActivity>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  activityId?: string | null;
  educationalPackageId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  activityId,
  educationalPackageId,
}) => {
  const [loadMembers, { loading: loadingMembers, data: dataMembers }] = useLazyQuery<
    GetEducationalPackageLearnersActivityEdit,
    GetEducationalPackageLearnersActivityEditVariables
  >(GET_EDUCATIONAL_PACKAGE_MEMBERS);

  useEffect(() => {
    const fetchData = async () => {
      if (educationalPackageId) {
        await loadMembers({
          variables: { filters: { id: educationalPackageId } },
        });
      }
    }
    fetchData();
  }, [educationalPackageId, loadMembers]);

  const [
    loadActivity,
    { loading: loadingActivity, data: dataActivity },
  ] = useLazyQuery<GetActivityEdit, GetActivityEditVariables>(GET_ACTIVITY);

  useEffect(() => {
    const fetchData = async () => {
      if (activityId) {
        await loadActivity({
          variables: {
            filters: {
              id: activityId,
            },
          },
        });
      }
    };
    fetchData();
  }, [activityId, loadActivity]);

  const [createActivity, { error: createError }] = useMutation<
    CreateActivity,
    CreateActivityVariables
  >(CREATE_ACTIVITY);

  const [updateActivity, { error: updateError }] = useMutation<
    UpdateActivity,
    UpdateActivityVariables
  >(UPDATE_ACTIVITY);

  return children({
    educationalPackage: {
      loading: loadingMembers,
      learners:
        dataMembers && dataMembers.educationalPackage.data.learners
          ? dataMembers.educationalPackage.data.learners
          : [],
    },
    activity: {
      loading: loadingActivity,
      data: dataActivity,
    },
    create: { mutation: createActivity, error: createError },
    update: { mutation: updateActivity, error: updateError },
  });
};
