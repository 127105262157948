import React from "react";

import { Queries } from "./components/Queries";
import { EGoalInfoModal as Modal } from "./components/EGoalInfoModal";

export interface EGoalInfoModalProps {
  eGoalIds: string[];
  onModalClosed: (result: boolean) => void;
}

export const EGoalInfoModal: React.FunctionComponent<EGoalInfoModalProps> = ({
  onModalClosed,
  eGoalIds,
}) => {
  const onClose = () => {
    onModalClosed(false);
  };

  return (
    <Queries eGoalIds={eGoalIds}>
      {({ eGoal: { data, loading } }) => (
        <Modal
          loading={loading}
          data={data}
          open={true}
          onClose={onClose}
          eGoalIds={eGoalIds}
        />
      )}
    </Queries>
  );
};
