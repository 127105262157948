import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import { Flex, Text, Box, Chip, Tooltip } from "components/Atoms";
import {
  DropdownMenu,
  DropdownOptionItem,
} from "components/Molecules/DropdownMenu";

import {
  GetSchoolActivityTemplates_allActivityTemplateFoldersBySchool_data as Folder,
  GetSchoolActivityTemplates_allActivityTemplateFoldersBySchool_data_activityTemplates as ActivityTemplate,
} from "../Queries/types/GetSchoolActivityTemplates";

import { TemplateItem } from "../TemplateItem";

import UserContext from "components/Contexts/UserContext";

import { RemoveTemplateItemConfirmationModal } from "../../GoalTemplates/RemoveTemplateItemConfirmationModal";
import { EditActivityModal, EditActivityGoalsModal } from "components/Organisms";

import { FolderContainer, ButtonArea, FolderContextMenuItems } from "../../GoalTemplates/FolderItem";

interface Props {
  school: { id: string; name: string };
  folder: Folder;
  onEdit: (folder: Folder) => void;
  onAddFolder: (folder: Folder) => void;
  onRemove: (folder: Folder) => void;
  onRemoveActivityTemplate: (folder: ActivityTemplate) => void;
  refetch: any;
}

export const FolderItem: React.FC<Props> = ({
  folder,
  school,
  refetch,
  onEdit,
  onAddFolder,
  onRemove,
  onRemoveActivityTemplate,
}) => {
  const [open, setOpen] = useState(false);
  const [showActivityEditModal, setShowActivityEditModal] = useState(false);
  const [showActivityTemplateEditModal, setShowActivityTemplateEditModal] = useState(false);
  const [selectedActivityTemplateId, setSelectedActivityTemplateId] = useState<
    string | undefined
  >();
  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const isFolderOwner = user?.uid === folder.user.id;

  const addFolder = folder.parent
    ? []
    : [
        {
          id: FolderContextMenuItems.addFolder,
          label: t("containers.settings.activityTemplates.addFolder"),
          disabled: !isFolderOwner,
          tooltip: !isFolderOwner
            ? t("containers.settings.activityTemplates.addFolderDisabledTooltip")
            : undefined,
        },
      ];

  const options = [
    {
      id: FolderContextMenuItems.add,
      label: t("containers.settings.activityTemplates.addNewActivityTemplate"),
    },
    ...addFolder,
    {
      id: FolderContextMenuItems.edit,
      label: t("containers.settings.activityTemplates.editFolder"),
      disabled: !isFolderOwner,
      tooltip: !isFolderOwner
        ? t("containers.settings.activityTemplates.editFolderDisabledTooltip")
        : undefined,
    },
    {
      id: FolderContextMenuItems.remove,
      label: t("containers.settings.activityTemplates.removeFolder"),
      disabled:
        !isFolderOwner ||
        folder.childrens.length > 0 ||
        folder.activityTemplates.length > 0,
      tooltip:
        !isFolderOwner ||
        folder.childrens.length > 0 ||
        folder.activityTemplates.length > 0
          ? t("containers.settings.activityTemplates.removeFolderDisabledTooltip")
          : undefined,
    },
  ];

  const onSelect = (o: DropdownOptionItem) => {
    if (o.id === FolderContextMenuItems.add) {
      setSelectedActivityTemplateId(undefined);
      setShowActivityEditModal(true);
    }
    if (o.id === FolderContextMenuItems.edit) onEdit(folder);
    if (o.id === FolderContextMenuItems.remove)
      setShowRemoveConfirmationModal(true);
    if (o.id === FolderContextMenuItems.addFolder) onAddFolder(folder);
  };

  const onEditActivity = (template: ActivityTemplate) => {
    setSelectedActivityTemplateId(template.id);
    setShowActivityEditModal(true);
  };

  const onEditActivityTemplate = (template: ActivityTemplate) => {
    setSelectedActivityTemplateId(template.id);
    setShowActivityTemplateEditModal(true);
  };

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result) {
      onRemove(folder);
    }
    setShowRemoveConfirmationModal(false);
  };

  const noActivityTemplates = !folder.activityTemplates.length;

  const onTemplateModalClosed = (result: boolean) => {
    if (result) refetch();
    setShowActivityTemplateEditModal(false);
    setOpen(true);
  };

  const onActivityEditModalClosed = (result: boolean) => {
    if (result) refetch();
    setShowActivityEditModal(false);
    setOpen(true);
  };

  return (
    <>
      <FolderContainer
        justifyContent="space-between"
        alignItems="center"
        parent={!folder.parent}
      >
        <ButtonArea onClick={() => setOpen(!open)}>
          {open ? <FolderOpenIcon /> : <FolderIcon />}
          <Box ml={2}>
            <Text color="textPrimary">{folder.name}</Text>
          </Box>
        </ButtonArea>
        <Flex alignItems="center">
          {!noActivityTemplates && (
            <Tooltip
              title={t(
                folder.activityTemplates.length === 1
                  ? "containers.settings.activityTemplates.templatesCountTooltip"
                  : "containers.settings.activityTemplates.templatesCountPluralTooltip",
                { count: folder.activityTemplates.length },
              )}
            >
              <Box>
                <Chip color="primary" label={folder.activityTemplates.length} />
              </Box>
            </Tooltip>
          )}
          <DropdownMenu onSelect={onSelect} options={options} />
        </Flex>
      </FolderContainer>
      {open && noActivityTemplates && !folder.childrens.length && (
        <Box p={2} pl={7.5}>
          <Text>{t("containers.settings.activityTemplates.noFolders")}</Text>
        </Box>
      )}

      {open && (
        <Box pl={5}>
          {(folder.childrens || []).map(folder => (
            <FolderItem
              key={folder.id}
              refetch={refetch}
              school={school}
              folder={{ ...folder, childrens: [] }}
              onEdit={onEdit}
              onAddFolder={onAddFolder}
              onRemove={onRemove}
              onRemoveActivityTemplate={onRemoveActivityTemplate}
            />
          ))}
        </Box>
      )}

      {open &&
        !noActivityTemplates &&
        folder.activityTemplates.map(t => (
          <TemplateItem
            key={t.id}
            onEdit={onEditActivityTemplate}
            onEditActivity={onEditActivity}
            onRemove={onRemoveActivityTemplate}
            template={t}
          />
        ))}

      {showActivityTemplateEditModal && selectedActivityTemplateId && (
        <EditActivityGoalsModal
          showModal={showActivityTemplateEditModal}
          onModalClosed={onTemplateModalClosed}
          activityId={selectedActivityTemplateId}
          // TODO: otherGoals
          // otherGoals={otherGoals}
        />
      )}
      {showActivityEditModal && (
        <EditActivityModal
          activityTemplateFolderId={folder.id}
          open={showActivityEditModal}
          onModalClosed={onActivityEditModalClosed}
          activityId={selectedActivityTemplateId}
        />
      )}
      {showRemoveConfirmationModal && (
        <RemoveTemplateItemConfirmationModal
          text={t("containers.settings.activityTemplates.removeFolderConfirmation")}
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </>
  );
};
