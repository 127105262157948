import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, Box, InputLabel, Text } from "../../../Atoms";

import { Materials } from "components/Molecules";
import useBeforeUnload from "components/Hooks/use-before-unload";

import { GetLearningAchievementMaterials_learningAchievement_data_materials as Material } from "../Queries/types/GetLearningAchievementMaterials";

import { LearningAchievementMaterialType } from "typings/graphql-global-types";

const Form = styled.form`
  width: 100%;
`;

export interface SetMaterialsVariables {
  materials: Material[];
}

interface Props extends FormikProps<SetMaterialsVariables> {
  readonly?: boolean;
}
const InputLabelWithoutInput = styled(InputLabel)`
  position: initial;
`;

export const MaterialsForm: React.FC<Props> = ({
  values: { materials },
  readonly,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const removeMaterial = (material: { id: string }) => {
    setFieldValue(
      "materials",
      materials.filter(g => g.id !== material.id),
    );
    setFieldTouched("materials", true, false);
  };

  const files = (materials || []).filter(
    m => m.type === LearningAchievementMaterialType.file,
  );

  const links = (materials || []).filter(
    m => m.type === LearningAchievementMaterialType.link,
  );

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <InputLabelWithoutInput>
            {t("components.organisms.learningAchievementMaterialsForm.files")}
          </InputLabelWithoutInput>

          <Box>
            {files.length > 0 ? (
              <Materials
                materials={files}
                onMaterialRemove={readonly ? undefined : removeMaterial}
              />
            ) : (
              <Text>
                {t(
                  "components.organisms.learningAchievementMaterialsForm.noFiles",
                )}
              </Text>
            )}
          </Box>
        </FormControl>
        <FormControl>
          <InputLabelWithoutInput>
            {t("components.organisms.learningAchievementMaterialsForm.links")}
          </InputLabelWithoutInput>
          <Box>
            {links.length > 0 ? (
              <Materials
                materials={links}
                onMaterialRemove={readonly ? undefined : removeMaterial}
              />
            ) : (
              <Text>
                {t(
                  "components.organisms.learningAchievementMaterialsForm.noLinks",
                )}
              </Text>
            )}
          </Box>
        </FormControl>
      </Flex>
    </Form>
  );
};
