import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Flex, Input, Select, MenuItem, InputLabel } from "components/Atoms";

import { GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_learners as Learner } from "../Queries/types/GetEducationalPackageAssessmentsByUser";

interface Props {
  learners: Learner[];
  showOnlyLearners: boolean;
  value: OrderFilterEnum | string;
  onChange: (value: OrderFilterEnum | string) => void;
}

export enum OrderFilterEnum {
  firstNameAsc, //A-Ö
  firstNameDesc, //Ö-A
  lastNameAsc,
  lastNameDesc,
}

const StyledInputLabel = styled(InputLabel)`
  margin-top: 0;
  transform: none;
  font-size: 1.2rem;
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  width: 250px;
  &.MuiInputBase-root {
    margin-top: 0;
    padding-top: 0;
  }
`;

interface User {
  id: string;
  givenName: string | null;
  familyName: string | null;
}

export const sortFunction = (orderFilterValue: OrderFilterEnum) => (
  a: User,
  b: User,
) => {
  let prop = "familyName";
  let order = "asc";
  if (orderFilterValue === OrderFilterEnum.lastNameDesc) {
    prop = "familyName";
    order = "desc";
  } else if (orderFilterValue === OrderFilterEnum.firstNameAsc) {
    prop = "givenName";
    order = "asc";
  } else if (orderFilterValue === OrderFilterEnum.firstNameDesc) {
    prop = "givenName";
    order = "desc";
  }
  const aProp = (a as any)[prop] || "";
  const bProp = (b as any)[prop] || "";
  return aProp > bProp
    ? order === "asc"
      ? 1
      : -1
    : bProp > aProp
    ? order === "asc"
      ? -1
      : 1
    : 0;
};

export const OrderFilter: React.FC<Props> = ({
  value,
  learners,
  onChange,
  showOnlyLearners,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      flex={[1]}
      mt={[2]}
      justifyContent={["space-between", "flex-start", "flex-end"]}
      alignItems={["center"]}
      flexWrap={["wrap", "nowrap"]}
    >
      <StyledInputLabel htmlFor="orderFilterId">
        {t("containers.teacherEducationPackage.assessmentsTable.order")}
      </StyledInputLabel>
      <Select
        value={value}
        onChange={e => onChange(e.target.value as any)}
        input={<StyledInput name="orderFilterId" id="orderFilterId" />}
      >
        {!showOnlyLearners && (
          <MenuItem value={OrderFilterEnum.firstNameAsc}>
            {t(
              "containers.teacherEducationPackage.assessmentsTable.firstNameAsc",
            )}
          </MenuItem>
        )}

        {!showOnlyLearners && (
          <MenuItem value={OrderFilterEnum.firstNameDesc}>
            {t(
              "containers.teacherEducationPackage.assessmentsTable.firstNameDesc",
            )}
          </MenuItem>
        )}

        {!showOnlyLearners && (
          <MenuItem value={OrderFilterEnum.lastNameAsc}>
            {t(
              "containers.teacherEducationPackage.assessmentsTable.lastNameAsc",
            )}
          </MenuItem>
        )}

        {!showOnlyLearners && (
          <MenuItem value={OrderFilterEnum.lastNameDesc} divider>
            {t(
              "containers.teacherEducationPackage.assessmentsTable.lastNameDesc",
            )}
          </MenuItem>
        )}

        {learners
          .sort(sortFunction(OrderFilterEnum.firstNameAsc))
          .map((l, i) => (
            <MenuItem key={l.id} value={l.id}>
              {`${l.givenName} ${l.familyName}`}
            </MenuItem>
          ))}
      </Select>
    </Flex>
  );
};
