import gql from "graphql-tag";
import { useQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  GetEducationalPackageNewActivities,
  GetEducationalPackageNewActivitiesVariables,
  GetEducationalPackageNewActivities_allActivities_data as Activity,
  GetEducationalPackageNewActivities_educationalPackage_data as EducationalPackage,
} from "./types/GetEducationalPackageNewActivities";
import {
  SubmitLearningAchievement,
  SubmitLearningAchievementVariables,
} from "./types/SubmitLearningAchievement";

import {
  UpdateAssessmentFormActivityCard,
  UpdateAssessmentFormActivityCardVariables,
} from "./types/UpdateAssessmentFormActivityCard";

import {
  NumericAssessmentInput,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

export const GET_ACTIVITES = gql`
  query GetEducationalPackageNewActivities(
    $filters: ActivityListFiltersInputType!
    $epFilters: IdInputType!
  ) {
    educationalPackage(filters: $epFilters) {
      data {
        id
        importedPackage
      }
    }
    allActivities(filters: $filters) {
      data {
        id
        name
        selfAssessment
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
            teacherScaleTexts
          }
        }
        learningAchievements {
          id
          user {
            id
          }
          numericAssessments {
            id
            value
            educationalPackageGoalId
            type
          }
          verbalAssessments {
            id
            assessmentText
            type
          }
        }
      }
    }
  }
`;

export const SUBMIT_LEARNING_ACHIEVEMENT = gql`
  mutation SubmitLearningAchievement($input: LearningAchievementInput!) {
    submitLearningAchievement(input: $input) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessmentFormActivityCard(
    $input: PatchLearningAchievementInput!
  ) {
    createOrPatchLearningAchievement(input: $input) {
      data {
        id
        user {
          id
        }
        numericAssessments {
          id
          value
          educationalPackageGoalId
          type
        }
        verbalAssessments {
          id
          assessmentText
          type
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

export interface QueriesProps {
  activities: {
    loading: boolean;
    data: Activity[];
    educationalPackageData: EducationalPackage | undefined;
    refetch: any;
  };
  activity: {
    submit: (
      activityId: string,
    ) => Promise<SingleExecutionResult<SubmitLearningAchievement>>;
    loading?: boolean;
  };
  assessment: {
    update: (
      userId: string,
      activityId: string,
      numericAssessments: NumericAssessmentInput[],
      verbalAssessment: VerbalAssessmentInput,
    ) => Promise<SingleExecutionResult<UpdateAssessmentFormActivityCard>>;
    loading?: boolean;
  };
}

interface NewActivityListQueriesProps {
  children: RenderProp<QueriesProps>;
  educationalPackageId: string;
}

export const NewActivityListQueries: React.FC<NewActivityListQueriesProps> = ({
  children,
  educationalPackageId,
}) => {
  const { loading, data, refetch } = useQuery<
    GetEducationalPackageNewActivities,
    GetEducationalPackageNewActivitiesVariables
  >(GET_ACTIVITES, {
    variables: {
      filters: {
        educationalPackageId,
        submitted: false,
      },
      epFilters: {
        id: educationalPackageId,
      },
    },
  });

  const [submitActivity, { loading: submitLoading }] = useMutation<
    SubmitLearningAchievement,
    SubmitLearningAchievementVariables
  >(SUBMIT_LEARNING_ACHIEVEMENT);

  const [updateAssessment, { loading: updateAssessmentLoading }] = useMutation<
    UpdateAssessmentFormActivityCard,
    UpdateAssessmentFormActivityCardVariables
  >(UPDATE_ASSESSMENT);

  const submit = (activityId: string) => {
    return submitActivity({
      variables: {
        input: {
          activityId,
        },
      },
      optimisticResponse: {
        submitLearningAchievement: {
          __typename: "LearningAchievementDataResponse",
          data: {
            id: "",
            __typename: "LearningAchievement",
          },
        },
      },
      update: store => {
        const data = store.readQuery<
          GetEducationalPackageNewActivities,
          GetEducationalPackageNewActivitiesVariables
        >({
          query: GET_ACTIVITES,
          variables: {
            filters: {
              educationalPackageId,
              submitted: false,
            },
            epFilters: {
              id: educationalPackageId,
            },
          },
        });

        if (data) {
          store.writeQuery({
            query: GET_ACTIVITES,
            data: {
              allActivities: {
                __typename: "ActivityListDataResponse",
                data: data.allActivities.data.filter(a => a.id !== activityId),
              },
              educationalPackage: data.educationalPackage,
            },
            variables: {
              filters: {
                educationalPackageId,
                submitted: false,
              },
              epFilters: {
                id: educationalPackageId,
              },
            },
          });
        }
      },
    });
  };

  const update = (
    userId: string,
    activityId: string,
    numericAssessments: NumericAssessmentInput[],
    verbalAssessment: VerbalAssessmentInput,
  ) => {
    return updateAssessment({
      variables: {
        input: {
          userId,
          activityId,
          learningAchievement: {
            numericAssessments,
            verbalAssessment,
          },
        },
      },
    });
  };

  return children({
    activities: {
      loading,
      refetch,
      data: data ? data.allActivities.data : [],
      educationalPackageData: data && data.educationalPackage.data,
    },
    activity: { submit, loading: submitLoading },
    assessment: {
      update,
      loading: updateAssessmentLoading,
    },
  });
};
