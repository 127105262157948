import React, { useState } from "react";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { useTranslation } from "react-i18next";

import "emoji-mart/css/emoji-mart.css";
import { BaseEmoji } from "emoji-mart";

// import data from "emoji-mart/data/google.json";
// import data from "./customdata.json";
import { getLocaliseData } from "./localiseData";
import NimblePicker from "emoji-mart/dist-es/components/picker/nimble-picker";
import IconButton from "@material-ui/core/IconButton";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

const PickerWrapper = styled.div`
  .emoji-mart-anchor-selected .emoji-mart-anchor-bar {
    background-color: ${props => props.theme.palette.primary.main} !important;
  }
  .emoji-mart-anchor-selected {
    color: ${props => props.theme.palette.primary.main} !important;
  }
`;
interface Props {
  onSelect: (emoji: BaseEmoji) => void;
  zIndex?: number;
}

const EmojiPicker = ({ onSelect, zIndex }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "emoji-popper" : undefined;

  const data = getLocaliseData(t);

  return (
    <>
      <IconButton
        data-cy="show-emojipicker-button"
        aria-label="show emoji picker"
        onClick={handleClick}
      >
        <InsertEmoticonIcon />
      </IconButton>
      <Popper
        style={zIndex ? { zIndex } : undefined}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <PickerWrapper>
            <NimblePicker
              data={data as any}
              i18n={
                {
                  search: t(`components.atoms.emojiPicker.search`),
                  clear: t(`components.atoms.emojiPicker.clear`),
                  notfound: t(`components.atoms.emojiPicker.notfound`),
                  skintext: t(`components.atoms.emojiPicker.skintext`),
                  categories: {
                    search: t(`components.atoms.emojiPicker.categories.search`),
                    recent: t(`components.atoms.emojiPicker.categories.recent`),
                    people: t(`components.atoms.emojiPicker.categories.people`),
                  },
                  categorieslabel: t(
                    `components.atoms.emojiPicker.categorieslabel`,
                  ),
                  skintones: {
                    1: t(`components.atoms.emojiPicker.skintones.1`),
                    2: t(`components.atoms.emojiPicker.skintones.2`),
                    3: t(`components.atoms.emojiPicker.skintones.3`),
                    4: t(`components.atoms.emojiPicker.skintones.4`),
                    5: t(`components.atoms.emojiPicker.skintones.5`),
                    6: t(`components.atoms.emojiPicker.skintones.6`),
                  },
                } as any
              }
              native
              onSelect={e => {
                handleClickAway();
                onSelect(e as BaseEmoji);
              }}
              title=""
              emoji="Grinning Face"
              showPreview={true}
            />
          </PickerWrapper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default EmojiPicker;
