import * as React from "react";
import { useTranslation } from "react-i18next";

import { Modal, AssessmentReadonly, Materials } from "components/Molecules";

import { Flex, Box, Text } from "components/Atoms";
import { AssessmentType } from "typings/graphql-global-types";

import {
  EditAssessmentGetUserActivity_activity_data as Activity,
  EditAssessmentGetUserActivity_activity_data_learners as User,
  EditAssessmentGetUserActivity_activity_data_learningAchievements as LearningAchievement,
} from "../Queries/types/EditAssessmentGetUserActivity";

import { getAssessmentValue } from "../Form";

export interface QuestionProps {
  name: string;
  answer: string;
  type: AssessmentType;
  assessor?: string;
  modalTitle?: string;
  verbalEvaluationTitle?: string;
}
export interface SelfAssessmentModalProps {
  activity: Activity;
  achievement?: LearningAchievement;
  learner: User;
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

export const SelfAssessmentModal: React.FunctionComponent<SelfAssessmentModalProps> = ({
  activity,
  achievement,
  learner,
  open,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  const goals = activity.educationalPackageGoals;

  const materials = achievement ? achievement.materials : [];

  const verbalAssessments = achievement ? achievement.verbalAssessments : [];
  const numericAssessments = achievement ? achievement.numericAssessments : [];

  const selfVerbalAssessment = verbalAssessments.find(
    a => a.type === AssessmentType.self,
  );

  const selfNumericAssessments = (goals || []).map(({ id, name }) => ({
    value: getAssessmentValue(
      numericAssessments.find(
        ass =>
          ass.educationalPackageGoalId === id &&
          ass.type === AssessmentType.self,
      ),
    ),
    name,
    goalId: id,
  }));

  const selfVerbalAssessmentText =
    selfVerbalAssessment && selfVerbalAssessment.assessmentText;

  const selfNumericAssessmentDone = numericAssessments.find(
    ass => ass.type === AssessmentType.self,
  );

  const onClose = () => {
    onModalClosed(false);
  };

  return (
    <Modal
      name="selfassessment"
      height="550px"
      primaryAction={{
        text: t("globals.close"),
        onClick: onClose,
      }}
      title={t("components.organisms.editAssessmentModal.selfAchievements")}
      open={open}
    >
      <Flex mt={2} flexDirection="column">
        <Box>
          <Text variant="caption">
            {t("components.organisms.editAssessmentModal.learnerName")}
          </Text>
          <Text variant="body1">{`${learner.givenName} ${learner.familyName}`}</Text>
        </Box>
        <Box mt={2}>
          <Text variant="caption">
            {t("components.organisms.editAssessmentModal.activityName")}
          </Text>
          <Text variant="body1">{activity.name}</Text>
        </Box>
        {goals && goals.length > 0 && (
          <Box>
            {selfNumericAssessmentDone || selfVerbalAssessmentText ? (
              <AssessmentReadonly
                type={AssessmentType.self}
                goals={goals}
                numericAssessments={selfNumericAssessments}
                verbalAssessment={selfVerbalAssessmentText || ""}
                modal
              />
            ) : (
              <Flex mt={2} flexDirection="column">
                <Text variant="caption">
                  {t(
                    "components.molecules.assessmentReadOnly.numericAssessment",
                  )}
                </Text>
                <Text>
                  {t(
                    "components.organisms.editAssessmentModal.noSelfAssessmentDone",
                  )}
                </Text>
              </Flex>
            )}
          </Box>
        )}

        <Box mt={2}>
          <Text variant="h4">
            {t("components.organisms.editAssessmentModal.classroomMaterial")}
          </Text>
          <Box my={1}>
            {materials.length === 0 ? (
              <Text>
                {t("components.organisms.editAssessmentModal.noAchievements")}
              </Text>
            ) : (
              <Materials materials={materials} />
            )}
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
};
