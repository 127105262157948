import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import { Button, ButtonProps } from "components/Atoms/Button";

interface FloatableButtonProps extends ButtonProps {
  scrollContainer?: HTMLElement | null;
}

const StyledButton = styled(
  React.forwardRef(
    ({ isFloat, ...rest }: any, ref: React.Ref<HTMLButtonElement>) => (
      <Button ref={ref} {...rest} />
    ),
  ),
)`
  ${props => props.isFloat && "transform: scale(0);"};
`;

const FloatButton = styled(
  React.forwardRef(
    ({ isFloat, ...rest }: any, ref: React.Ref<HTMLButtonElement>) => (
      <Button ref={ref} {...rest} />
    ),
  ),
)`
  display: none;
  ${props =>
    props.isFloat && `display: inherit; position: fixed; bottom: 40px;`}
`;

const isInViewport = (element: HTMLElement) => {
  const top = element.getBoundingClientRect().top;
  return top <= window.innerHeight;
};

export const FloatableButton = ({
  scrollContainer,
  ...props
}: FloatableButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [isFloat, setIsFloat] = useState<boolean | undefined>();

  useLayoutEffect(() => {
    let throttleTimeout: number | null = null;

    const onScroll = () => {
      if (!throttleTimeout) {
        throttleTimeout = window.setTimeout(() => {
          ref && ref.current && setIsFloat(!isInViewport(ref.current));
          throttleTimeout = null;
        }, 50);
      }
    };

    (scrollContainer || window).addEventListener("scroll", onScroll);
    (scrollContainer || window).addEventListener("resize", onScroll);

    setTimeout(onScroll, 100);

    return () => {
      (scrollContainer || window).removeEventListener("scroll", onScroll);
      (scrollContainer || window).removeEventListener("resize", onScroll);
    };
  }, [setIsFloat, scrollContainer]);

  return (
    <>
      <FloatButton
        variant="contained"
        color="primary"
        isFloat={isFloat}
        {...props}
        data-cy="floatable-button"
      />
      <StyledButton isFloat={isFloat} ref={ref} {...props} />
    </>
  );
};
