import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { lighten } from "@material-ui/core/styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FolderIcon from "@material-ui/icons/Folder";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Flex, Box, Text, Select, Input, MenuItem } from "components/Atoms";
import { Modal } from "components/Molecules/Modal";
import { Queries } from "./Queries";

import { GoalTemplateFolderType } from "typings/graphql-global-types";

import {
  GetSchoolTemplatesPickerModal_allGoalTemplateFoldersBySchool_data as Folder,
  GetSchoolTemplatesPickerModal_allGoalTemplateFoldersBySchool_data_goalTemplates as GoalTemplate,
} from "./Queries/types/GetSchoolTemplatesPickerModal";

import { GetMyLastSelectedSchool } from "./types/GetMyLastSelectedSchool";
import {
  UpdateMyLastSelectedSchool,
  UpdateMyLastSelectedSchoolVariables,
} from "./types/UpdateMyLastSelectedSchool";

export const StyledListItem = styled(ListItem)`
  color: #000;
  &.Mui-selected {
    background-color: #e0eef8;
    border-left: 6px solid
      ${props => lighten(props.theme.palette.primary.main, 0.5)};
    padding-left: 10px;
  }
  &:hover {
    background-color: ${lighten("#E0EEF8", 0.3)};
  }
`;

export const SettingTitle = styled(Text)`
  color: #000;
`;

export const BackListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-weight: 500;
  }
`;

const GoalIconWrap = styled.div`
  user-select: none;
  background-color: ${props => props.theme.palette.primary.dark};
  height: 18px;
  text-align: center;
  transform: rotate(45deg);
  width: 18px;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.07rem;
  margin-left: 5px;
`;

const GoalIconItem = styled.div`
  display: table-cell;
  height: 18px;
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 18px;
`;

export const GET_LASTSELECTEDSCHOOL = gql`
  query GetMyLastSelectedSchool {
    myprofile {
      data {
        id
        lastSelectedSchool {
          id
        }
      }
    }
  }
`;

export const UPDATE_MYLASTSELECTEDSCHOOL = gql`
  mutation UpdateMyLastSelectedSchool($input: IdInputType!) {
    updateLastSelectedSchool(input: $input) {
      data {
        id
        lastSelectedSchool {
          id
        }
      }
    }
  }
`;

export const GoalIcon = () => {
  return (
    <GoalIconWrap>
      <GoalIconItem />
    </GoalIconWrap>
  );
};

export interface Props {
  open: boolean;
  onModalClosed: (result: boolean, text?: string, eGoalsIds?: string[]) => void;
  type: GoalTemplateFolderType;
}

type TemplateOrFolder = GoalTemplate | Folder;

const isFolder = (toBeDetermined: TemplateOrFolder) => {
  if ((toBeDetermined as Folder).__typename === "GoalTemplateFolder") {
    return true;
  }
  return false;
};

export const GoalTemplatePickerModal: React.FunctionComponent<Props> = ({
  onModalClosed,
  open,
  type,
}) => {
  const { t } = useTranslation();

  const { loading: loadingMyLastSelectedSchool, data } = useQuery<
    GetMyLastSelectedSchool
  >(GET_LASTSELECTEDSCHOOL);

  const [updateLastSelectedSchool] = useMutation<
    UpdateMyLastSelectedSchool,
    UpdateMyLastSelectedSchoolVariables
  >(UPDATE_MYLASTSELECTEDSCHOOL);

  const [selectedSchoolId, setSelectedSchoolId] = useState<string>("default");

  useEffect(() => {
    if (data?.myprofile.data?.lastSelectedSchool) {
      setSelectedSchoolId(data?.myprofile.data?.lastSelectedSchool.id);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedSchoolId !== "default" &&
      selectedSchoolId !== data?.myprofile.data?.lastSelectedSchool?.id
    ) {
      updateLastSelectedSchool({
        variables: {
          input: {
            id: selectedSchoolId,
          },
        },
      });
    }
  }, [selectedSchoolId, updateLastSelectedSchool, data]);

  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [selectedGoalTemplate, setSelectedGoalTemplate] = useState<
    GoalTemplate
  >();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries
      schoolId={selectedSchoolId === "default" ? undefined : selectedSchoolId}
      type={type}
    >
      {({ schools, goalTemplates }) => {
        const loading =
          schools.loading ||
          goalTemplates.loading ||
          loadingMyLastSelectedSchool;

        const selectedSchool = (schools.data?.allSchools.data || []).find(
          s => s.id === selectedSchoolId,
        );

        const folders =
          goalTemplates.data?.allGoalTemplateFoldersBySchool.data || [];
        const noFolders = !folders.length;

        const onPrimaryAction = () => {
          if (selectedGoalTemplate) {
            let eGoalsIds: string[] = [];
            if (
              selectedGoalTemplate.eGoals &&
              selectedGoalTemplate.eGoals.length > 0
            ) {
              eGoalsIds = selectedGoalTemplate.eGoals.map(g => g.id);
            }
            onModalClosed(true, selectedGoalTemplate.text, eGoalsIds);
          }
        };

        const openFolder = (folder: Folder) => {
          setSelectedGoalTemplate(undefined);
          setSelectedFolder(folder);
        };

        const onItemClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          item: TemplateOrFolder,
        ) => {
          if (isFolder(item)) {
            e.preventDefault();
            openFolder(item as Folder);
            return;
          }
          e.preventDefault();
          setSelectedGoalTemplate(item as GoalTemplate);
        };

        const onBackClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          previousFolder?: Folder | null,
        ) => {
          setSelectedFolder(previousFolder || undefined);
          setSelectedGoalTemplate(undefined);
        };

        return (
          <Modal
            loading={loading}
            primaryAction={{
              text: t("globals.add"),
              onClick: onPrimaryAction,
              disabled:
                !(goalTemplates && folders.length > 0) ||
                !selectedGoalTemplate ||
                loading,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={t("components.organisms.goalTemplatePickerModal.title")}
            open={open}
          >
            <Flex flexDirection="column" flex={1}>
              <Flex mt={2} flexDirection="column">
                <SettingTitle variant="h4">{t("globals.school")}</SettingTitle>
                <Box my={1}>
                  <Select
                    fullWidth
                    value={selectedSchoolId}
                    onChange={e => {
                      setSelectedFolder(undefined);
                      setSelectedGoalTemplate(undefined);
                      setSelectedSchoolId(e.target.value as string);
                    }}
                    input={<Input name="school" id="school" />}
                  >
                    <MenuItem value={"default"}>
                      {t("globals.empty.noSchool")}
                    </MenuItem>
                    {(schools.data?.allSchools.data || []).map(s => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Flex>
              {selectedSchool ? (
                noFolders ? (
                  <Box>
                    <Text>
                      {t(
                        "components.organisms.goalTemplatePickerModal.noGoalTemplates",
                      )}
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <List aria-label="goal templates">
                      {selectedFolder && (
                        <ListItem
                          divider
                          button
                          onClick={event =>
                            onBackClick(
                              event,
                              folders.find(
                                f => f.id === selectedFolder?.parent?.id,
                              ),
                            )
                          }
                        >
                          <ListItemIcon>
                            <ChevronLeftIcon fontSize="large" />
                          </ListItemIcon>
                          <BackListItemText primary={selectedFolder.name} />
                        </ListItem>
                      )}
                      {selectedFolder
                        ? !loading && (
                            <>
                              {(selectedFolder.childrens || []).map(f => (
                                <StyledListItem
                                  divider
                                  key={f.id}
                                  button
                                  selected={selectedFolder === f}
                                  onClick={event =>
                                    onItemClick(event, f as Folder)
                                  }
                                >
                                  <ListItemIcon>
                                    <FolderIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={f.name} />
                                </StyledListItem>
                              ))}
                              {selectedFolder.goalTemplates.map(
                                (goalTemplate, i) => (
                                  <StyledListItem
                                    divider
                                    key={goalTemplate.id}
                                    button
                                    selected={
                                      selectedGoalTemplate === goalTemplate
                                    }
                                    onClick={event =>
                                      onItemClick(event, goalTemplate)
                                    }
                                  >
                                    <ListItemIcon>
                                      <GoalIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={goalTemplate.text} />
                                  </StyledListItem>
                                ),
                              )}
                            </>
                          )
                        : !loading &&
                          folders.map(folder => (
                            <StyledListItem
                              divider
                              key={folder.id}
                              button
                              selected={selectedFolder === folder}
                              onClick={event => onItemClick(event, folder)}
                            >
                              <ListItemIcon>
                                <FolderIcon />
                              </ListItemIcon>
                              <ListItemText primary={folder.name} />
                            </StyledListItem>
                          ))}
                    </List>
                  </Box>
                )
              ) : (
                <Text>{t("globals.empty.noSchoolSelected")}</Text>
              )}
            </Flex>
          </Modal>
        );
      }}
    </Queries>
  );
};
