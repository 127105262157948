import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery, useMutation, MutationFunctionOptions } from "@apollo/client";

import { GetSchools } from "../../GoalTemplates/Queries/types/GetSchools";

import { DELETE_ACTIVITY } from "../../../../EducationalPackage/components/ActivityList/components/ActivityListQueries";

import {
  GetSchoolActivityTemplates,
  GetSchoolActivityTemplatesVariables,
} from "./types/GetSchoolActivityTemplates";

import {
  RemoveActivityTemplateFolder,
  RemoveActivityTemplateFolderVariables,
} from "./types/RemoveActivityTemplateFolder";

import {
  DeleteActivity,
  DeleteActivityVariables,
} from "../../../../EducationalPackage/components/ActivityList/components/ActivityListQueries/types/DeleteActivity";

import {
  UpdateTeacherLastSelectedSchool,
  UpdateTeacherLastSelectedSchoolVariables,
} from "../../GoalTemplates/Queries/types/UpdateTeacherLastSelectedSchool";

import {
  GET_SCHOOLS,
  UPDATE_LASTSELECTEDSCHOOL,
} from "../../GoalTemplates/Queries";

const GET_SCHOOL_ACTIVITY_TEMPLATES = gql`
  query GetSchoolActivityTemplates($filters: ActivityTemplateListFiltersInputType!) {
    allActivityTemplateFoldersBySchool(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        parent {
          id
        }
        activityTemplates {
          id
          name
          activityTemplateFolder {
            id
            user {
              id
            }
          }
        }
        childrens {
          id
          parent {
            id
          }
          name
          user {
            id
          }
          activityTemplates {
            id
            name
            activityTemplateFolder {
              id
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const REMOVE_ACTIVITY_TEMPLATE_FOLDER = gql`
  mutation RemoveActivityTemplateFolder($input: IdInputType!) {
    deleteActivityTemplateFolder(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  schools: {
    loading: boolean;
    data: GetSchools | undefined;
  };
  activityTemplates: {
    loading: boolean;
    data: GetSchoolActivityTemplates | undefined;
    refetch: any;
  };
  removeActivityTemplateFolder: {
    mutate: (
      options?:
        | MutationFunctionOptions<
          RemoveActivityTemplateFolder,
          RemoveActivityTemplateFolderVariables
        >
        | undefined,
    ) => Promise<any>;
    loading: boolean;
  };
  removeActivityTemplate: {
    mutate: (
      options?:
        | MutationFunctionOptions<
          DeleteActivity,
          DeleteActivityVariables
        >
        | undefined,
    ) => Promise<any>;
    loading: boolean;
  };
  updateSelectedSchool: (schoolId: string) => Promise<void>;
}

interface QueriesProps {
  children: RenderProp<Queries>;
  schoolId?: string;
}

export const Queries: React.FC<QueriesProps> = ({
  schoolId,
  children,
}) => {
  const { loading, data } = useQuery<GetSchools>(GET_SCHOOLS);

  const [
    getSchoolTemplates,
    { loading: templatesLoading, data: templatesData, refetch },
  ] = useLazyQuery<
    GetSchoolActivityTemplates,
    GetSchoolActivityTemplatesVariables
  >(
    GET_SCHOOL_ACTIVITY_TEMPLATES,
  );

  const [removeFolder, { loading: loadingRemoveFolder }] = useMutation<
    RemoveActivityTemplateFolder,
    RemoveActivityTemplateFolderVariables
  >(REMOVE_ACTIVITY_TEMPLATE_FOLDER);

  const [removeGt, { loading: loadingRemoveGoaltemplate }] = useMutation<
    DeleteActivity,
    DeleteActivityVariables
  >(DELETE_ACTIVITY);

  useEffect(() => {
    if (schoolId) {
      getSchoolTemplates({
        variables: {
          filters: {
            schoolId,
          },
        },
      });
    }
  }, [schoolId, getSchoolTemplates]);

  const [updateLastSelectedSchool] = useMutation<
    UpdateTeacherLastSelectedSchool,
    UpdateTeacherLastSelectedSchoolVariables
  >(UPDATE_LASTSELECTEDSCHOOL);

  const updateSelectedSchool = async (id: string) => {
    await updateLastSelectedSchool({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  return children({
    schools: {
      loading,
      data,
    },
    activityTemplates: {
      loading: templatesLoading,
      data: templatesData,
      refetch,
    },
    removeActivityTemplateFolder: {
      mutate: removeFolder,
      loading: loadingRemoveFolder,
    },
    removeActivityTemplate: {
      mutate: removeGt,
      loading: loadingRemoveGoaltemplate,
    },
    updateSelectedSchool,
  });
};
