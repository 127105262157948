import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Flex } from "components/Atoms";

const Background = styled(Flex)`
  min-height: 100vh;
  height: 100%;
  background-color: ${props => props.theme.palette.primary.main};
`;

export const SelfAssessmentPreview: React.FunctionComponent = () => {
  const { t } = useTranslation();
  document.title = t("containers.selfAssessmentPreview.documentTitle");
  return (
    <Background justifyContent="center" alignItems="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150 150"
        width="50%"
        height="50%"
      >
        <path
          d="M75 0a75 75 0 100 150A75 75 0 0075 0zm26.59 73.612l28.422-29.652a62.829 62.829 0 018.128 29.652zm35.616 12.354a62.693 62.693 0 01-5.066 15.966H74.446l15.3-15.966zM122.76 33.672l-52.526 54.8V64.577l.25.249 41.225-41.224a63.445 63.445 0 0111.051 10.07zM41.924 21.189a62.747 62.747 0 0115.957-7v121.616a62.745 62.745 0 01-15.957-7zm58.67-3.942L70.235 47.606V12.021q2.359-.177 4.764-.179a62.948 62.948 0 0125.595 5.405zM11.841 75.001A62.942 62.942 0 0129.57 31.13v87.743a62.947 62.947 0 01-17.73-43.872zm63.158 63.157q-2.4 0-4.764-.178v-23.694h54.217a63.038 63.038 0 01-49.453 23.869z"
          fill="#fff"
        />
      </svg>
    </Background>
  );
};

export default SelfAssessmentPreview;
