import { ApolloClient, ApolloLink, InMemoryCache, NetworkStatus, Observable, Operation, ApolloProvider as RealApolloProvider, ServerError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import firebase from "firebase/app";
import gql from "graphql-tag";
import i18next from "i18next";
import React from "react";

import { useTranslation } from "react-i18next";

import {
  UpdateMyLanguage,
  UpdateMyLanguageVariables,
} from "./types/UpdateMyLanguage";
import { VerifyUser } from "./types/VerifyUser";

import { useSnackbar } from "components/Contexts/SnackbarContext";
import { history } from "router";
import { NOTFOUND } from "routes";

const UPDATE_LANGUAGE = gql`
  mutation UpdateMyLanguage($input: UserLanguageInputType!) {
    updateMyLanguage(input: $input) {
      data {
        id
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}
let client: ApolloClient<any>;

export const ApolloProvider: React.FC<Props> = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const customRequestHandler = async (operation: Operation) => {
    const user = firebase.auth().currentUser;
    const token = user && (await user.getIdToken(false));

    let context = operation.getContext();

    operation.setContext({
      headers: {
        ...context.headers,
        "accept-language": i18next.language,
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  };

  const customRequestHandlerLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: any;
        Promise.resolve(operation)
          .then(oper => customRequestHandler(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      }),
  );

  const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
    const context = operation.getContext();

    if (networkError && (networkError as ServerError).statusCode === 401) {
      firebase.auth().signOut();
    } else if (networkError) {
      if (context.networkStatus === NetworkStatus.poll) return;

      showSnackbar({
        id: networkError.name,
        type: "error",
        // message: networkError.message,
        message: t("globals.errors.generalServerError"),
      });
    } else if (graphQLErrors) {
      for (let index = 0; index < graphQLErrors.length; index++) {
        const error: any = graphQLErrors[index];
        if (error.code === 404) {
          history.push(NOTFOUND);
          break;
        } else {
          if (context.networkStatus === NetworkStatus.poll) return;

          showSnackbar({
            id: error.code as number,
            type: "error",
            // message: error.message,
            message:
              error.code === 413
                ? t("globals.errors.payloadTooLarge")
                : t("globals.errors.generalServerError"),
          });
        }
      }
    }
  });

  const apolloLink = ApolloLink.from([
    errorLink,
    customRequestHandlerLink,
    createUploadLink({
      uri:
        process.env.REACT_APP_API_URL ||
        `${window.location.protocol}//${window.location.host}/api/graphql`,
    }) as any,
  ]);

  client = new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache(),
  });

  return <RealApolloProvider client={client}>{children}</RealApolloProvider>;
};

let languageChanged = false;
export const setLanguageChanged = () => {
  languageChanged = true;
};

export const checkLogin = async (fbUser: firebase.User, i18n: any) => {
  try {
    const result = await client.query<VerifyUser>({
      query: gql`
        query VerifyUser {
          myprofile {
            data {
              id
              emailAddress
              givenName
              familyName
              language
            }
          }
        }
      `,
    });

    if (result.data && result.data.myprofile && result.data.myprofile.data) {
      const language = result.data.myprofile.data.language;

      if (!language || (languageChanged && language !== i18n.language)) {
        languageChanged = false;
        await client.mutate<UpdateMyLanguage, UpdateMyLanguageVariables>({
          variables: { input: { language: i18n.language } },
          mutation: UPDATE_LANGUAGE,
        });
      } else {
        await i18n.changeLanguage(language);
      }
    }

    let { claims } = await fbUser.getIdTokenResult();
    if (!claims.roles || !claims.roles.length) {
      let { claims } = await fbUser.getIdTokenResult(true);
      if (!claims.roles || !claims.roles.length) {
        throw new Error("User does not have role!");
      }
    }
  } catch (e) {
    console.error(e);
    // user exist in firebase but not in backend, we need to logout and inform user
    const customError = {
      message: `Credentials not found for ${fbUser.email}! Please contact person who gave You access.`,
    };
    await firebase.auth().signOut();
    throw Object.assign(e as firebase.auth.Error, customError);
  }
};
