import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import Link from "@material-ui/core/Link";

import { Flex, Box, Text, Button, Divider } from "components/Atoms";
import { Card, CardHeader, CardContent } from "components/Molecules/Card";
import { Modal } from "components/Molecules";
import { GetEducationalPackageEGoals_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal } from "../Queries/types/GetEducationalPackageEGoals";
import firstNumber from "utils/firstNumber";
import cleanText from "utils/cleanText";

interface Props {
  defaultExpanded: boolean;
  eGoal: EGoal;
  eGoalIds: string[];
  count?: number;
  onSetEGoalIds?: (eGoalId: string[]) => void;
}

const Collapsible = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const DarkText = styled(Text)`
  color: #000;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;
`;

const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;
  .MuiCardHeader-action {
    align-self: center;
  }
`;

const LinkedDivider = styled(Divider)``;

const NotLinkedDivider = styled(Divider)``;

const LinkButtonGroup = styled(Flex)`
  flexdirection: "row";
  height: 35px;
  border-radius: 4px;
  min-width: 115px;
`;

const LinkedButtonGroup = styled(LinkButtonGroup)`
  background: #00933d;
  color: #fff;
`;

const NotLinkedButtonGroup = styled(LinkButtonGroup)`
  background: #dededf;
  color: #174993;
`;

const LinkButton = styled(Button)`
  text-transform: none;
  flex: 1;
  height: 35px;
  font-size: 1.25em;
`;

const LinkedButton = styled(LinkButton)`
  background: #00933d;
  color: #fff;
  :hover {
    background: #00933d;
    color: #fff;
  }
`;

const LinkedButtonSmall = styled(LinkedButton)`
  min-width: 25px;
  width: 25px;
  flex: 0;
`;

const NotLinkedButton = styled(LinkButton)`
  background: #dededf;
  color: #174993;
  :hover {
    background: #dededf;
    color: #174993;
  }
`;

const NotLinkedButtonSmall = styled(NotLinkedButton)`
  min-width: 25px;
  width: 25px;
  flex: 0;
`;

const PreWrapText = styled(Text)`
  white-space: pre-wrap;
`;

export const EGoalCard: React.FC<Props> = ({
  defaultExpanded,
  eGoal,
  count,
  eGoalIds,
  onSetEGoalIds,
}) => {
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useState(defaultExpanded);
  const [showInfoText, setShowInfoText] = useState<{
    name: string;
    description: string;
  } | null>(null);

  const connected =
    eGoalIds.length > 0 &&
    eGoal &&
    eGoal.eGoal &&
    eGoalIds.find(egId => eGoal.eGoal.id === egId) !== undefined;

  const buttonText: string = connected
    ? t("components.organisms.selectOPSEducationalPackageGoalModal.connected")
    : t("components.organisms.selectOPSEducationalPackageGoalModal.connect");
  return (
    <>
      <Card>
        <StyledCardHeader
          onClick={() => toggleExpanded(!expanded)}
          title={
            (
              <Flex
                py={1}
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Flex alignItems="center">
                  <Text color="textPrimary" data-cy="egoal-title">
                    {cleanText(eGoal.eGoal.name)}
                  </Text>
                </Flex>
              </Flex>
            ) as any
          }
          action={
            onSetEGoalIds && (
              <Flex pl={1}>
                {connected ? (
                  <LinkedButtonGroup flexDirection="row">
                    {count ? (
                      <Flex>
                        <LinkedButtonSmall
                          data-cy="small-egoal-linked-button"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            return (
                              onSetEGoalIds &&
                              onSetEGoalIds(
                                eGoalIds.filter(id => id !== eGoal.eGoal.id),
                              )
                            );
                          }}
                        >
                          {`${count}`}
                        </LinkedButtonSmall>
                        <LinkedDivider orientation="vertical" />
                      </Flex>
                    ) : null}
                    <LinkedButton
                      data-cy="egoal-linked-button"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        return (
                          onSetEGoalIds &&
                          onSetEGoalIds(
                            eGoalIds.filter(id => id !== eGoal.eGoal.id),
                          )
                        );
                      }}
                    >
                      {buttonText}
                    </LinkedButton>
                  </LinkedButtonGroup>
                ) : (
                  <NotLinkedButtonGroup flexDirection="row">
                    {count ? (
                      <Flex>
                        <NotLinkedButtonSmall
                          data-cy="small-egoal-not-linked-button"
                          size="small"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            return (
                              onSetEGoalIds &&
                              onSetEGoalIds([...eGoalIds, eGoal.eGoal.id])
                            );
                          }}
                        >
                          {`${count ? count : ""}`}
                        </NotLinkedButtonSmall>
                        <NotLinkedDivider orientation="vertical" />
                      </Flex>
                    ) : null}
                    <NotLinkedButton
                      data-cy="egoal-not-linked-button"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        return (
                          onSetEGoalIds &&
                          onSetEGoalIds([...eGoalIds, eGoal.eGoal.id])
                        );
                      }}
                    >
                      {buttonText}
                    </NotLinkedButton>
                  </NotLinkedButtonGroup>
                )}
              </Flex>
            )
          }
        />
        <Collapsible>
          <AnimateHeight height={expanded ? "auto" : 0}>
            <StyledCardContent>
              <Flex flexDirection="column">
                <DarkText>
                  {t(
                    "components.organisms.selectOPSEducationalPackageGoalModal.targetArea",
                  )}
                </DarkText>
                <Flex flexWrap="wrap">
                  {[...eGoal.targetAreas]
                    .sort((a, b) => firstNumber(a.name) - firstNumber(b.name))
                    .map(targetArea => (
                      <Box key={targetArea.id} mr={1.25} my={0.5}>
                        <Text>{targetArea.name}</Text>
                      </Box>
                    ))}
                </Flex>
              </Flex>

              <Flex mt={2} flexDirection="column">
                <DarkText>
                  {t(
                    "components.organisms.selectOPSEducationalPackageGoalModal.wideGoal",
                  )}
                </DarkText>
                <Flex flexWrap="wrap">
                  {[...eGoal.wideGoals]
                    .sort((a, b) => firstNumber(a.name) - firstNumber(b.name))
                    .map(wideGoal => (
                      <Box key={wideGoal.id} mr={1.25} my={0.5}>
                        <StyledLink onClick={() => setShowInfoText(wideGoal)}>
                          {wideGoal.name}
                        </StyledLink>
                      </Box>
                    ))}
                </Flex>
              </Flex>

              <Flex mt={2} flexDirection="column">
                <DarkText>
                  {t(
                    "components.organisms.selectOPSEducationalPackageGoalModal.contentArea",
                  )}
                </DarkText>
                <Flex flexWrap="wrap">
                  {[...eGoal.contentAreas].map(contentArea => (
                    <Box key={contentArea.id} mr={1.25} my={0.5}>
                      <StyledLink onClick={() => setShowInfoText(contentArea)}>
                        {contentArea.name}
                      </StyledLink>
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </StyledCardContent>
          </AnimateHeight>
        </Collapsible>
      </Card>
      {showInfoText && (
        <Modal
          width="550px"
          height="500px"
          primaryAction={{
            text: t("globals.close"),
            onClick: () => setShowInfoText(null),
          }}
          open={!!showInfoText}
          title={showInfoText.name}
        >
          <Flex mb={2} py={3} flex={"auto"} flexDirection="column">
            <PreWrapText>{cleanText(showInfoText.description)}</PreWrapText>
          </Flex>
        </Modal>
      )}
    </>
  );
};
