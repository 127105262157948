import React from "react";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";

import { Flex, Box, Tooltip } from "components/Atoms";

import { Cell, StyledInputLabel } from "./index";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { GetDigipolkuSurveyGroup_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
  onClick: (question: SurveyQuestion) => void;
}
// ["10%", "20%", "10%"];
const InfoColumn = ({ numericQuestions, width, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}>
        <Hidden mdDown>
          <Flex alignItems="center" height="100%">
            <StyledInputLabel>
              {t("containers.digipolku.surveyForm.info")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>

      {numericQuestions.map((q, i) => (
        <Cell
          key={`info-${q.id}`}
          py={2}
          divider={i < numericQuestions.length - 1}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex
            height="100%"
            alignItems="flex-start"
            justifyContent={["center", "flex-start"]}
          >
            <Tooltip
              title={
                q.description.length > 0
                  ? t("containers.digipolku.surveyForm.openDescription")
                  : t("containers.digipolku.surveyForm.noDescription")
              }
              arrow
            >
              <Box>
                {q.description.length > 0 ? (
                  <IconButton
                    aria-label="survey question info button"
                    onClick={() => onClick(q)}
                    data-cy="show-info"
                  >
                    <InfoOutlinedIcon color="primary" fontSize="large" />
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <InfoOutlinedIcon color="disabled" fontSize="large" />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default InfoColumn;
