import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";

import { Flex, Text, Box, Tooltip } from "components/Atoms";

import { GetSchoolTemplates_allGoalTemplateFoldersBySchool_data_goalTemplates as GoalTemplate } from "../Queries/types/GetSchoolTemplates";

import { RemoveTemplateItemConfirmationModal } from "../RemoveTemplateItemConfirmationModal";

import UserContext from "components/Contexts/UserContext";

interface Props {
  template: GoalTemplate;
  onEdit: (template: GoalTemplate) => void;
  onRemove: (template: GoalTemplate) => void;
}

export const TemplateItem: React.FC<Props> = ({
  template,
  onEdit,
  onRemove,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result) {
      onRemove(template);
    }
    setShowRemoveConfirmationModal(false);
  };

  const isTemplateOwner = user?.uid === template.user.id;
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box pl={7.5}>
        <Text color="textPrimary">{template.text}</Text>
      </Box>
      <Flex alignItems="center" pr={2}>
        <Tooltip
          title={t("containers.settings.goalTemplates.removeTemplateTooltip")}
        >
          <Box>
            <IconButton
              aria-label="remove goal template"
              color="primary"
              disabled={!isTemplateOwner}
              onClick={() => setShowRemoveConfirmationModal(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip
          title={t("containers.settings.goalTemplates.editTemplateTooltip")}
        >
          <Box>
            <IconButton
              aria-label="edit goal template"
              color="primary"
              disabled={!isTemplateOwner}
              onClick={() => onEdit(template)}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Flex>
      {showRemoveConfirmationModal && (
        <RemoveTemplateItemConfirmationModal
          text={t(
            "containers.settings.goalTemplates.removeGoalTemplateConfirmation",
          )}
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </Flex>
  );
};
