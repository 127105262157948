import React from "react";
import AddIcon from "@material-ui/icons/Add";

import { Flex, Box, Button, ButtonLink } from "../";

export interface AddNewButtonProps {
  onClick?: () => void;
  path?: string;
  label: string;
  size?: "small" | "medium";
  name: string;
  disabled?: boolean;
}

export const AddNewButton: React.FC<AddNewButtonProps> = ({
  label,
  onClick,
  path,
  size,
  name,
  disabled,
  ...props
}) => {
  const fontSize = size !== "small" ? "medium" : "inherit";
  const content = (
    <Flex alignItems="center">
      <Box display="flex" ml={-0.5} mr={0.5}>
        <AddIcon fontSize={fontSize} />
      </Box>
      <Box mr={0.5}>{label}</Box>
    </Flex>
  );

  const variant = size !== "small" ? "contained" : "text";
  const color = size !== "small" ? "secondary" : "default";

  return path ? (
    <ButtonLink
      data-cy={name}
      disabled={disabled}
      size={size}
      variant={variant}
      color={color}
      to={path}
    >
      {content}
    </ButtonLink>
  ) : (
    <Button
      data-cy={name}
      disabled={disabled}
      size={size}
      variant={variant}
      color={color}
      onClick={onClick}
      {...props}
    >
      {content}
    </Button>
  );
};
