import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { GetEGoalInfo, GetEGoalInfoVariables } from "./types/GetEGoalInfo";

export const GET_EGOALS = gql`
  query GetEGoalInfo($ids: [String!]!) {
    eGoalsFull(ids: $ids) {
      data {
        eGoal {
          id
          name
        }
        contentAreas {
          id
          name
          description
        }
        wideGoals {
          id
          name
          description
        }
        targetAreas {
          id
          name
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  eGoal: {
    loading: boolean;
    data: GetEGoalInfo | undefined;
    refetch: any;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
  eGoalIds: string[];
}

export const Queries: React.FC<QueriesProps> = ({ children, eGoalIds }) => {
  const { loading, /*error,*/ data, refetch } = useQuery<
    GetEGoalInfo,
    GetEGoalInfoVariables
  >(GET_EGOALS, {
    variables: {
      ids: eGoalIds,
    },
  });

  return children({
    eGoal: { loading, data, refetch },
  });
};
