import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { GetOwnGoalsSurveyGroup } from "./types/GetOwnGoalsSurveyGroup";

export const GET_OWNGOALS = gql`
  query GetOwnGoalsSurveyGroup {
    ownGoalsSurveyGroup {
      data {
        id
        name
        surveys {
          id
          name
          createdAt
          surveyQuestions {
            id
            name
            description
            type
            scheduledDate
            survey {
              id
              name
            }
            surveyQuestionAnswer {
              id
              numeric
              verbalText
              comments {
                id
                text
                read
                user {
                  id
                  givenName
                  familyName
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  ownGoalsSurveyGroup: {
    loading: boolean;
    data: GetOwnGoalsSurveyGroup | undefined;
    refetch: () => void;
  };
}

interface Props {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<Props> = ({ children }) => {
  const { loading, /*error,*/ data, refetch } = useQuery<
    GetOwnGoalsSurveyGroup
  >(GET_OWNGOALS);

  return children({
    ownGoalsSurveyGroup: { loading, data, refetch },
  });
};
