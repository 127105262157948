import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../Atoms";
import { Modal } from "../../Molecules";

export interface JoinCodeModalProps {
  open: boolean;
  onModalClosed: () => void;
  joinCode: string;
}

const DarkText = styled(Text)`
  color: #000;
  width: 100%;
  font-weight: 600;
  text-align: center;
  align-self: center;
`;

export const JoinCodeModal: React.FunctionComponent<JoinCodeModalProps> = ({
  onModalClosed,
  open,
  joinCode,
}) => {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <Modal
      name="join-code"
      height={fullscreen ? '100%' : "280px"}
      width={fullscreen ? '100%' : undefined}
      primaryAction={{
        text: fullscreen
          ? t("components.organisms.joinCodeModal.shrink")
          : t("components.organisms.joinCodeModal.enlarge"),
        onClick: () => setFullscreen(!fullscreen),
      }}
      secondaryAction={{
        text: t("globals.close"),
        onClick: onModalClosed,
      }}
      title={t("containers.teacherEducationPackage.joinCode")}
      open={open}
    >
      <DarkText fontSize={fullscreen ? '20vw' : '5rem'}>{joinCode}</DarkText>
    </Modal>
  );
}
