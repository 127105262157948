import { ApolloError, useMutation, useQuery, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  GetActivityClassroomSettingsEdit,
  GetActivityClassroomSettingsEditVariables,
} from "./types/GetActivityClassroomSettingsEdit";

import {
  UpdateActivityClassroomSettings,
  UpdateActivityClassroomSettingsVariables,
} from "./types/UpdateActivityClassroomSettings";

export const GET_ACTIVITY = gql`
  query GetActivityClassroomSettingsEdit($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        name
        selfAssessment
        educationalPackageGoals {
          id
          eGoals {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivityClassroomSettings(
    $input: ActivitySetAssessmentInputType!
  ) {
    setClassroomAssessment(input: $input) {
      data {
        id
        name
        selfAssessment
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activity: {
    loading: boolean;
    data: GetActivityClassroomSettingsEdit | undefined;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            UpdateActivityClassroomSettings,
            UpdateActivityClassroomSettingsVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateActivityClassroomSettings>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  activityId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, activityId }) => {
  const { loading: loadingActivity, data: dataActivity } = useQuery<
    GetActivityClassroomSettingsEdit,
    GetActivityClassroomSettingsEditVariables
  >(GET_ACTIVITY, {
    variables: { filters: { id: activityId } },
  });

  const [updateActivity, { error: updateError }] = useMutation<
    UpdateActivityClassroomSettings,
    UpdateActivityClassroomSettingsVariables
  >(UPDATE_ACTIVITY);

  return children({
    activity: {
      loading: loadingActivity,
      data: dataActivity,
    },
    update: { mutation: updateActivity, error: updateError },
  });
};
