import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import {
  GetEducationalPackageEGoalsOpsTable,
  GetEducationalPackageEGoalsOpsTableVariables,
} from "./types/GetEducationalPackageEGoalsOpsTable";

export const GET_EGOALS = gql`
  query GetEducationalPackageEGoalsOpsTable($filters: IdInputType!) {
    allGradeLevelGroups {
      data {
        id
        name
      }
    }
    educationalPackage(filters: $filters) {
      data {
        id
        name
        learners {
          id
          givenName
          familyName
          photoUrl
        }
        gradeLevel {
          id
          gradeLevelGroup {
            id
          }
        }
        schoolSubjects {
          id
          name
          schoolSubjectGradeLevelGroups {
            gradeLevelGroup {
              id
              name
            }
            eGoals {
              eGoal {
                id
                name
              }
              contentAreas {
                id
                name
                description
              }
              wideGoals {
                id
                name
                description
              }
              targetAreas {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

export interface TableQueriesProps {
  educationalPackage: {
    loading: boolean;
    data: GetEducationalPackageEGoalsOpsTable | undefined;
  };
}

interface Props {
  children: RenderProp<TableQueriesProps>;
  educationalPackageId: string;
}

export const TableQueries: React.FC<Props> = ({
  children,
  educationalPackageId,
}) => {
  const { loading, /*error,*/ data } = useQuery<
    GetEducationalPackageEGoalsOpsTable,
    GetEducationalPackageEGoalsOpsTableVariables
  >(GET_EGOALS, {
    variables: {
      filters: {
        id: educationalPackageId,
      },
    },
  });

  return children({
    educationalPackage: { loading, data },
  });
};
