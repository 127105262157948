import React, { useState, useRef, useEffect } from "react";
import { FormikProps, Field } from "formik";

import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormControl from "@material-ui/core/FormControl";

import { Loader, Flex, Box, InputLabel, Input, Text } from "components/Atoms";

import EmojiPicker from "components/Atoms/EmojiPicker";

import { Modal } from "components/Molecules";

import useBeforeUnload from "components/Hooks/use-before-unload";

import { CommentsModal } from "components/Organisms/CommentsModal";

import {
  SurveyQuestionAnswerInput,
  QuestionType,
} from "typings/graphql-global-types";
import { GetDigipolkuSurveyGroup_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup";

import GoalColumn from "./GoalColumn";
import ListenColumn from "./ListenColumn";
import InfoColumn from "./InfoColumn";
import AssessmentColumn from "./AssessmentColumn";
import CommentsColumn from "./CommentsColumn";

export interface FormValues {
  surveyQuestionAnswers: SurveyQuestionAnswerInput[];
}

interface Props extends FormikProps<FormValues> {
  questions: SurveyQuestion[];
}

const StyledForm = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexFormControl = styled(FormControl)`
  flex: auto;
`;

export const StyledInputLabel = styled(InputLabel)`
  color: ${props => props.theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 400;
`;

const VerbalInputLabel = styled(StyledInputLabel)`
  position: inherit;
  margin-top: 0;
`;

const StyledInput = styled(Input)`
  min-width: 250px;
  width: 100%;
  max-width: 750px;

  textarea {
    resize: vertical;
    background-color: #fff;
  }
`;

export const Cell = styled(Box)<any>`
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  border-bottom: ${props =>
    props.divider && `1px solid ${props.theme.palette.grey[100]}`};

  ${props => props.theme.breakpoints.down("sm")} {
    ${props => (props.hideMobile ? "display: none" : "")};
    border-bottom: ${props =>
      props.showBorderBottomOnMobile
        ? `1px solid ${props.theme.palette.grey[100]}`
        : "none"};
  }
`;

const WrapText = styled(Text)`
  white-space: pre-wrap;
`;

const FormSavingLoader = styled(Loader)`
  position: absolute;
  background-color: #6c6c6cab;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const EmojiButtonBox = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 0px;
`;

const EmojiInputBox = styled(Box)`
  position: relative;
`;

function validateText(value: string, t: any) {
  let error;
  if (value && value.length > 400) {
    error = t("globals.validations.assessmentMax");
  }
  return error;
}

export const SurveyForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  handleChange,
  setFieldValue,
  isValid,
  isSubmitting,
  questions,
}: Props) => {
  const { t } = useTranslation();
  const verbalInputsRef = useRef<HTMLTextAreaElement[]>([]);
  const [showInfoModal, setShowInfoModal] = useState<SurveyQuestion | null>(
    null,
  );

  const [
    showCommentsModal,
    setShowCommentsModal,
  ] = useState<SurveyQuestion | null>(null);

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const showInfo = (question: SurveyQuestion) => {
    setShowInfoModal(question);
  };

  const onShowComments = (question: SurveyQuestion) => {
    setShowCommentsModal(question);
  };

  const onAnswerChanged = (name: string, value: number | null) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const numericQuestions = questions.filter(
    q => q.type === QuestionType.numeric,
  );

  useEffect(() => {
    verbalInputsRef.current = verbalInputsRef.current.slice(
      0,
      questions.length,
    );
  }, [questions.length]);

  return (
    <>
      <StyledForm>
        {isSubmitting && <FormSavingLoader />}
        <Flex pl={2} pr={1} pb={1} mt={2} flexDirection="column">
          <Flex>
            {numericQuestions.length > 0 && (
              <Flex flex={1} flexWrap="wrap">
                <GoalColumn
                  numericQuestions={numericQuestions}
                  width={["60%", "40%", "30%"]}
                />

                <ListenColumn
                  numericQuestions={numericQuestions}
                  width={["13%", "20%", "10%"]}
                />

                <InfoColumn
                  numericQuestions={numericQuestions}
                  width={["13%", "20%", "10%"]}
                  onClick={showInfo}
                />

                <Hidden mdUp>
                  <CommentsColumn
                    numericQuestions={numericQuestions}
                    width={["13%", "20%"]}
                    onClick={onShowComments}
                  />
                </Hidden>

                <AssessmentColumn
                  values={values}
                  numericQuestions={numericQuestions}
                  width={["100%", "100%", "30%"]}
                  onChange={onAnswerChanged}
                />

                <Hidden smDown>
                  <CommentsColumn
                    numericQuestions={numericQuestions}
                    width={["10%", "17.5%", "20%"]}
                    onClick={onShowComments}
                  />
                </Hidden>
              </Flex>
            )}
          </Flex>

          <Flex mt={[2, 2, 1]} flexDirection="column">
            {questions.map((q, i) => {
              const answer = values.surveyQuestionAnswers.find(
                a => a.surveyQuestionId === q.id,
              );

              if (!answer || q.type !== QuestionType.verbal) return null;
              return (
                <FlexFormControl key={answer.surveyQuestionId}>
                  <VerbalInputLabel htmlFor="verbalAssessment">
                    {q.name.length > 0
                      ? q.name
                      : t(
                          "containers.digipolku.surveyForm.verbalAssessmentSelf",
                        )}
                  </VerbalInputLabel>

                  <Flex flexWrap="wrap">
                    <Flex
                      minWidth="250px"
                      width="100%"
                      maxWidth="750px"
                      flexDirection="column"
                    >
                      <Field
                        name={`surveyQuestionAnswers.${i}.verbalText`}
                        validate={(value: string) => validateText(value, t)}
                      >
                        {({ meta }: { meta: any }) => (
                          <>
                            <EmojiInputBox
                              flex={1}
                              minWidth="250px"
                              width="100%"
                              maxWidth="600px"
                            >
                              <StyledInput
                                id="verbalAnswer"
                                inputRef={el =>
                                  (verbalInputsRef.current[i] = el)
                                }
                                name={`surveyQuestionAnswers.${i}.verbalText`}
                                placeholder={t(
                                  "containers.digipolku.surveyForm.verbalAssessmentPlaceholder",
                                )}
                                multiline
                                rows={4}
                                rowsMax={40}
                                helperText={
                                  meta.touched && meta.error
                                    ? (meta.error as string)
                                    : ""
                                }
                                error={meta.touched && Boolean(meta.error)}
                                value={answer.verbalText}
                                onChange={change.bind(
                                  null,
                                  `surveyQuestionAnswers.${i}.verbalText`,
                                )}
                                fullWidth
                              />
                              <EmojiButtonBox>
                                <EmojiPicker
                                  onSelect={e => {
                                    const start = (verbalInputsRef.current[
                                      i
                                    ]! as HTMLTextAreaElement).selectionStart;
                                    const end = (verbalInputsRef.current[
                                      i
                                    ]! as HTMLTextAreaElement).selectionEnd;

                                    let currentText =
                                      values.surveyQuestionAnswers[i]
                                        .verbalText || "";

                                    let newText =
                                      currentText.substring(0, start) +
                                      e.native +
                                      currentText.substring(end);

                                    setFieldValue(
                                      `surveyQuestionAnswers.${i}.verbalText`,
                                      newText,
                                    );
                                  }}
                                />
                              </EmojiButtonBox>
                            </EmojiInputBox>
                          </>
                        )}
                      </Field>
                    </Flex>
                    <Box pt={1} ml={[1, 1, 2]} maxWidth={["80%", "80%", "40%"]}>
                      <WrapText>
                        {q.description ||
                          t(
                            "containers.digipolku.surveyForm.verbalAssessmentDescription",
                          )}
                      </WrapText>
                    </Box>
                  </Flex>
                </FlexFormControl>
              );
            })}
          </Flex>
        </Flex>
      </StyledForm>
      {showInfoModal && (
        <InfoModal
          question={showInfoModal}
          onModalClosed={() => setShowInfoModal(null)}
        />
      )}
      {showCommentsModal && (
        <CommentsModal
          name={showCommentsModal.name}
          comments={showCommentsModal.surveyQuestionAnswer?.comments || []}
          open={true}
          onModalClosed={() => setShowCommentsModal(null)}
        />
      )}
    </>
  );
};

interface InfoModalProps {
  question: SurveyQuestion;
  onModalClosed: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({ question, onModalClosed }) => {
  const { t } = useTranslation();
  return (
    <Modal
      primaryAction={{ text: t("globals.close"), onClick: onModalClosed }}
      open={true}
      headingAction={<InfoOutlinedIcon />}
      title={question.survey.name}
      height="auto"
    >
      <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
        <Box mb={1}>
          <Text variant="h4">{t("containers.digipolku.surveyForm.goals")}</Text>
        </Box>
        <Text>{question.name}</Text>

        <Box mt={2} mb={1}>
          <Text variant="h4">
            {t("containers.digipolku.surveyForm.description")}
          </Text>
        </Box>
        <WrapText>{question.description}</WrapText>
      </Flex>
    </Modal>
  );
};
