import * as React from "react";

import styled from "styled-components";

import Typography, { TypographyProps } from "@material-ui/core/Typography";

import {
  typography,
  TypographyProps as SystemTypographyProps,
} from "@material-ui/system";

export type TextProps = TypographyProps & SystemTypographyProps;

export const Text: React.FunctionComponent<TextProps> = styled(Typography)`
  ${typography}
`;

Text.displayName = "Text";

export const NoNewPlaceHolderText = styled(Text)`
  color: #000000;
`;
