import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import EditIcon from "@material-ui/icons/Edit";

import { useTranslation } from "react-i18next";

import { Flex, Box, Tooltip } from "components/Atoms";

import {
  GoodIcon,
  VeryGoodIcon,
  FairlyGoodIcon,
  FairIcon,
  ExcellentIcon,
  VerbalAssessmentActiveIcon,
  VerbalAssessmentInactiveIcon,
} from "components/Atoms/Icons";

import { AssessmentType } from "typings/graphql-global-types";
import {
  AssessmentValueScale3,
  AssessmentValueScale5,
} from "components/Atoms/NumericAssessmentInput";

import {
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_learningAchievements_verbalAssessments as VerbalAssessment,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_learners as Learner,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_learningAchievements as LearningAchievement,
} from "../Queries/types/GetEducationalPackageAssessmentsByUser";

import {
  getAssessmentScale,
  getAssessmentText,
  Goal,
} from "utils/scaleHelpers";

const StyledRemoveOutlinedIcon = styled(RemoveOutlinedIcon)`
  margin-top: -4px;
`;

const FillBox = styled(Box)`
  width: 52px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface PartialNumericAssessment {
  value: number | null;
  educationalPackageGoalId: string;
  __typename?: string;
  type?: AssessmentType;
}

interface ComponentProps {
  component?: any;
}

interface AssessmentCellProps {
  numericAssessments: PartialNumericAssessment[];
  verbalAssessment?: VerbalAssessment;
  onClickViewVerbalEvaluation: (verbalAssessment: VerbalAssessment) => void;
  onClickEditAssessment: () => void;
  goals: Goal[];
}

export const getAndOrderAssessments = (
  learner: Learner,
  goals: Goal[],
  learningAchievements: LearningAchievement[],
  type: AssessmentType,
): PartialNumericAssessment[] => {
  const learnersAchievement = learningAchievements
    .filter(la => la.user && la.user.id === learner.id)
    .sort(
      (a, b) =>
        new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
    )[0];

  return goals.map(g => {
    const assessment =
      learnersAchievement &&
      learnersAchievement.numericAssessments.find(
        a => a.educationalPackageGoalId === g.id && a.type === type,
      );
    if (!assessment) {
      return {
        value: null,
        educationalPackageGoalId: g.id,
      };
    }
    return assessment!;
  });
};

export const getVerbalAssessment = (
  learner: Learner,
  learningAchievements: LearningAchievement[],
  type: AssessmentType,
): VerbalAssessment | undefined => {
  const learnersAchievement = learningAchievements.find(
    la => la.user && la.user.id === learner.id,
  );

  const va =
    learnersAchievement &&
    learnersAchievement.verbalAssessments &&
    learnersAchievement.verbalAssessments.find(va => va.type === type);

  return va;
};

export const AssessmentCell: React.FC<AssessmentCellProps> = ({
  numericAssessments,
  verbalAssessment,
  onClickViewVerbalEvaluation,
  onClickEditAssessment,
  goals,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      py={0.5}
      height={"100%"}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      {numericAssessments.map((assessment, i) => {
        let tooltipText = t(`globals.empty.noAssessment`);

        const scale = getAssessmentScale(
          goals,
          assessment.educationalPackageGoalId,
        );

        const scaleTexts = getAssessmentText(
          goals,
          assessment.educationalPackageGoalId,
          assessment.type,
        );

        if (scale === 5 && assessment.value === AssessmentValueScale5.Fair) {
          tooltipText =
            (scaleTexts && scaleTexts[0]) ||
            t(`globals.numericAssessments.${assessment.type}.fair`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.FairlyGood
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[1]) ||
            t(`globals.numericAssessments.${assessment.type}.fairlyGood`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.Good
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[2]) ||
            t(`globals.numericAssessments.${assessment.type}.mediocre`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.VeryGood
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[3]) ||
            t(`globals.numericAssessments.${assessment.type}.Good`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.Excellent
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[4]) ||
            t(`globals.numericAssessments.${assessment.type}.Excellent`);
        } else if (assessment.value === AssessmentValueScale3.Fair) {
          tooltipText = t(`globals.numericAssessments.${assessment.type}.fair`);
        } else if (assessment.value === AssessmentValueScale3.Good) {
          tooltipText = t(`globals.numericAssessments.${assessment.type}.mediocre`);
        } else if (assessment.value === AssessmentValueScale3.Excellent) {
          tooltipText = t(
            `globals.numericAssessments.${assessment.type}.excellent`,
          );
        }
        return (
          <Tooltip
            key={assessment.educationalPackageGoalId}
            arrow
            PopperProps={{ style: { marginTop: "-10px" } }}
            title={tooltipText}
          >
            <Flex
              marginTop={-0.2}
              mb="7px"
              justifyContent="center"
              width={["100%"]}
            >
              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Fair && (
                  <FairIcon view checked></FairIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.FairlyGood && (
                  <FairlyGoodIcon view checked></FairlyGoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Good && (
                  <GoodIcon view checked></GoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.VeryGood && (
                  <VeryGoodIcon view checked></VeryGoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Excellent && (
                  <ExcellentIcon view checked></ExcellentIcon>
                )}

              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Fair && (
                  <FairIcon view checked></FairIcon>
                )}
              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Good && (
                  <GoodIcon view checked></GoodIcon>
                )}
              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Excellent && (
                  <ExcellentIcon view checked></ExcellentIcon>
                )}
              {!assessment.value && (
                <FillBox>
                  <StyledRemoveOutlinedIcon fontSize="small" />
                </FillBox>
              )}
            </Flex>
          </Tooltip>
        );
      })}
      {verbalAssessment && verbalAssessment.assessmentText ? (
        <Tooltip
          arrow
          title={t(
            "containers.teacherEducationPackage.assessmentsTable.showVerbalAssessment",
          )}
        >
          <IconButton
            aria-label="verbal assessment info button"
            onClick={() => onClickViewVerbalEvaluation(verbalAssessment)}
          >
            <Box>
              <VerbalAssessmentActiveIcon color="primary" />
            </Box>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          title={t(
            "containers.teacherEducationPackage.assessmentsTable.noVerbalAssessment",
          )}
        >
          <Box textAlign="center">
            <IconButton aria-label="verbal assessment info button" disabled>
              <Box>
                <VerbalAssessmentInactiveIcon />
              </Box>
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <Box>
        {goals.length ? (
          <Tooltip
            arrow
            title={t(
              "containers.teacherEducationPackage.assessmentsTable.editAssessment",
            )}
          >
            <IconButton
              data-cy="edit-assessment-button"
              aria-label="edit assessment button"
              onClick={onClickEditAssessment}
            >
              <Box>
                <EditIcon color="primary" />
              </Box>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            arrow
            title={t(
              "containers.teacherEducationPackage.assessmentsTable.editAssessmentDisabled",
            )}
          >
            <Box textAlign="center">
              <IconButton disabled aria-label="edit assessment button disabled">
                <Box>
                  <EditIcon />
                </Box>
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Flex>
  );
};
