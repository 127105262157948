import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { pathOr } from "ramda";

import { Flex, Box, Text, Loader } from "components/Atoms";

import { CardContent } from "components/Molecules";
import { DropdownOptionItem } from "components/Molecules/DropdownMenu";

import { EducationPackageCard } from "components/Organisms";
import { EducationPackageCardContextMenuItems } from "components/Organisms/EducationPackageCard";

import { Queries } from "./../Queries";

import {
  AllEducationalPackagesTeacher,
  AllEducationalPackagesTeacher_allEducationalPackages_data as EducationPackage,
} from "../Queries/types/AllEducationalPackagesTeacher";

import { EDUCATIONALPACKAGE, getRoute } from "routes";

interface Props {
  loading: boolean;
  active: boolean;
  onContextMenuChanged: (id: string, o: DropdownOptionItem) => void;
  data?: AllEducationalPackagesTeacher;
}

type SpacingProp = {
  spacing: number;
};

const StyledFlex = styled(Flex)<SpacingProp>`
  margin: ${props => -1 * props.theme.spacing(props.spacing)}px;

  > ${Box as any} {
    padding: ${(props: any) => props.theme.spacing(props.spacing)}px;
  }

  ${(props: any) => props.theme.breakpoints.down("md")} {
    margin: ${props => -1 * props.theme.spacing(1.5)}px;
    > ${Box as any} {
      padding: ${(props: any) => props.theme.spacing(1.5)}px;
    }
  }

  ${(props: any) => props.theme.breakpoints.down("xs")} {
    margin: 0;
    > ${Box as any} {
      width: 100%;
      padding: 0;
      padding-bottom: ${(props: any) => props.theme.spacing(3)}px;
    }
  }
`;

const FullCardLoader = styled(Loader)`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #6c6c6cab;
`;

const FullCardOpacity = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff80;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;
`;

const BigNumber = styled(Text)`
  font-weight: 200;
  font-size: 2.5rem;
`;

export const EducationalPackageList: React.FC<Props> = ({
  loading,
  data,
  active,
  onContextMenuChanged,
}) => {
  const [loadingCardId, setLoadingCardId] = useState<string | undefined>();
  const { t } = useTranslation();

  if (loading) return <Loader />;

  const educationPackages: EducationPackage[] = pathOr(
    [],
    ["allEducationalPackages", "data"],
    data,
  );

  return (
    <Queries active={active}>
      {({
        setActiveEducationalPackage: {
          mutation: setActive,
          loading: setActiveLoading,
          error: activeError,
        },
      }) => {
        const onMenuChanged = async (
          id: string,
          o: DropdownOptionItem,
          educationalPackage: EducationPackage,
        ) => {
          if (
            o.id === EducationPackageCardContextMenuItems.activate ||
            o.id === EducationPackageCardContextMenuItems.archive
          ) {
            setLoadingCardId(id);
            await setActive({
              variables: {
                input: {
                  id,
                  active: o.value,
                },
              },
            });
          } else if (
            o.id === EducationPackageCardContextMenuItems.moveToClassroom &&
            educationalPackage.classroomCourseLink
          ) {
            window.open(educationalPackage.classroomCourseLink, "_blank");
          } else {
            onContextMenuChanged(id, o);
          }
        };

        return (
          <StyledFlex flexWrap="wrap" spacing={2}>
            {educationPackages.map((d, i) => (
              <Box key={`education-package-${i}`}>
                <EducationPackageCard
                  title={d.name}
                  archived={!d.active}
                  colorTheme={d.colorTheme}
                  onContextMenuChanged={o => onMenuChanged(d.id, o, d)}
                  openUrl={getRoute(EDUCATIONALPACKAGE, {
                    educationalPackageId: d.id,
                    tab: "",
                  })}
                  importedFromClassRoom={d.importedPackage}
                >
                  {setActiveLoading && loadingCardId === d.id && (
                    <FullCardLoader />
                  )}
                  {d.fetching && (
                    <FullCardOpacity data-cy="fetching-loading-layer" />
                  )}
                  <StyledCardContent>
                    {d.fetching ? (
                      <Text>
                        {t(
                          "containers.teacherHome.educationPackageCard.importingDetails",
                        )}
                      </Text>
                    ) : (
                      <Flex>
                        <Flex flexDirection="column">
                          <Text color="textPrimary" variant="h4">
                            {t(
                              "containers.teacherHome.educationPackageCard.activities",
                            )}
                          </Text>
                          <BigNumber variant="body1">
                            {d.activitiesCount}
                          </BigNumber>
                        </Flex>
                        <Flex ml={4} flexDirection="column">
                          <Text color="textPrimary" variant="h4">
                            {t(
                              "containers.teacherHome.educationPackageCard.learners",
                            )}
                          </Text>
                          <BigNumber variant="body1">
                            {d.learnersCount}
                          </BigNumber>
                        </Flex>
                      </Flex>
                    )}
                  </StyledCardContent>
                </EducationPackageCard>
              </Box>
            ))}
          </StyledFlex>
        );
      }}
    </Queries>
  );
};
