import React, { useState } from "react";

import { path } from "ramda";
import { useParams } from "components/Hooks/react-router-hooks";
import { useHistory } from "react-router";

import { EDUCATIONALPACKAGE, getRoute } from "routes";
import { Flex, Box, Loader } from "components/Atoms";
import { HeroContextMenuItems } from "components/Organisms/Hero";

import { DropdownOptionItem } from "components/Molecules/DropdownMenu";
import { useAnalytics } from "components/Hooks/firebase-hooks";

import {
  EditEducationPackageModal,
  EditEducationPackageMembersModal,
} from "components/Organisms";

import {
  EducationPackageQueries,
  Hero,
  Tabs,
  ActivityList,
  GET_EDUCATIONALPACKAGE,
  TabValue,
  AssessmentsTable,
} from "./components";

import { EGoalsTable } from "./components/EGoalsTable";
import { NavigationComponent } from "typings";

interface EducationalPackageProps {}

const TeacherEducationalPackage: NavigationComponent<EducationalPackageProps> = () => {
  const history = useHistory();

  const { educationalPackageId, tab }: any = useParams();

  const { analytics } = useAnalytics();

  const activeTab = tab ? tab : TabValue.activities;

  analytics.logEvent("page_view" as string, {
    page_title: "Teacher_" + activeTab,
    page_path: history.location.pathname,
  });

  const [showEditModal, setShowEditModal] = useState(false);

  const [showEditMembersModal, setShowEditMembersModal] = useState(false);

  const handleChange = (tab: TabValue) => {
    history.push(
      `${getRoute(EDUCATIONALPACKAGE, {
        educationalPackageId,
        tab,
      })}`,
    );
  };

  return (
    <EducationPackageQueries educationalPackageId={educationalPackageId}>
      {({ educationalPackage: { loading, data, refetch } }) => {
        const onEditMembersClosed = (result: boolean) => {
          setShowEditMembersModal(false);
          if (result) refetch();
        };

        const onEditModalClosed = (result: boolean) => {
          setShowEditModal(false);
          if (result) refetch();
        };

        const onHeroContextMenuChanged = async (o: DropdownOptionItem) => {
          if (o.id === HeroContextMenuItems.edit) {
            setShowEditModal(true);
          }
          if (o.id === HeroContextMenuItems.editMembers) {
            setShowEditMembersModal(true);
          }
        };

        return (
          <>
            <Flex flexDirection="column">
              <Hero
                onContextMenuChanged={onHeroContextMenuChanged}
                data={data}
              ></Hero>

              <Flex px={[0, 6]} py={[2, 5]} flexDirection="column">
                <Box px={[2.5, 0]}>
                  <Tabs data={data} value={activeTab} onChange={handleChange} />
                </Box>
                {(loading && (
                  <Box py={[0, 4]}>
                    <Loader />
                  </Box>
                )) || (
                  <Flex flexDirection="column" py={1}>
                    {activeTab === TabValue.activities &&
                      ((loading && <Loader />) || <ActivityList />)}
                    {activeTab === TabValue.assessments && (
                      <Box py={[0, 4]}>
                        {(loading && <Loader />) || (
                          <AssessmentsTable
                            educationalPackageId={educationalPackageId}
                          />
                        )}
                      </Box>
                    )}
                    {activeTab === TabValue.eGoals && (
                      <Box py={[0, 4]}>
                        {(loading && <Loader />) || (
                          <EGoalsTable
                            educationalPackageId={educationalPackageId}
                          />
                        )}
                      </Box>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
            {showEditModal && (
              <EditEducationPackageModal
                educationalPackageId={educationalPackageId}
                showModal={showEditModal}
                onModalClosed={onEditModalClosed}
              ></EditEducationPackageModal>
            )}
            {showEditMembersModal && (
              <EditEducationPackageMembersModal
                educationalPackageId={educationalPackageId}
                showModal={showEditMembersModal}
                onModalClosed={onEditMembersClosed}
              ></EditEducationPackageMembersModal>
            )}
          </>
        );
      }}
    </EducationPackageQueries>
  );
};

TeacherEducationalPackage.navigationOptions = async ({
  match,
  apolloClient,
}) => {
  const result = await apolloClient.query({
    variables: { filters: { id: match.params.educationalPackageId } },
    query: GET_EDUCATIONALPACKAGE,
  });

  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "educationalPackage",
        label: path("data.educationalPackage.data.name".split("."), result),
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default TeacherEducationalPackage;
