import { ApolloError, MutationUpdaterFn, NetworkStatus, useMutation, useQuery, SingleExecutionResult } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState } from "react";

import {
  GetActivitiesTeacher_allActivities_data as Activity,
  GetActivitiesTeacher_educationalPackage_data as EducationalPackage,
  GetActivitiesTeacher,
  GetActivitiesTeacherVariables,
  GetActivitiesTeacher_allActivities_pageInfo as PageInfo,
} from "./types/GetActivitiesTeacher";

import {
  SetActivityOrder_setActivityOrder_data as ActivityWithOrder,
  SetActivityOrder,
  SetActivityOrderVariables,
} from "./types/SetActivityOrder";

import {
  DeleteActivity,
  DeleteActivityVariables,
} from "./types/DeleteActivity";

import {
  SetActivityClassroomAssessment,
  SetActivityClassroomAssessmentVariables,
} from "./types/SetActivityClassroomAssessment";

import {
  ImportActivity,
  ImportActivityVariables,
} from "./types/ImportActivity";

import {
  ExportActivity,
  ExportActivityVariables,
} from "./types/ExportActivity";

import { SelfAssessmentSchedule } from "typings/graphql-global-types";

const POLLINTERVAL = 30000;

export const GET_ACTIVITIES = gql`
  query GetActivitiesTeacher(
    $filters: ActivityListFiltersInputType!
    $epFilters: IdInputType!
  ) {
    educationalPackage(filters: $epFilters) {
      data {
        id
        importedPackage
      }
    }
    allActivities(filters: $filters) {
      data {
        id
        name
        description
        status
        order
        submittedLearningAchievementsCount
        selfAssessment
        scheduledTime
        classroomWorkId
        learners {
          id
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
          }
        }
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
      }
      pageInfo {
        start
        end
        hasNext
        totalCount
      }
    }
  }
`;

const SET_ORDER = gql`
  mutation SetActivityOrder($input: ActivitySetOrderInputType!) {
    setActivityOrder(input: $input) {
      data {
        id
        order
      }
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($input: IdInputType!) {
    deleteActivity(input: $input) {
      data {
        id
      }
    }
  }
`;

export const SET_CLASSROOM_ASSESSMENT = gql`
  mutation SetActivityClassroomAssessment(
    $input: ActivitySetAssessmentInputType!
  ) {
    setClassroomAssessment(input: $input) {
      data {
        id
        selfAssessment
      }
    }
  }
`;

const IMPORT_ACTIVITY = gql`
  mutation ImportActivity($input: ActivityImportInputType!) {
    importActivity(input: $input) {
      data {
        id
        name
        description
        status
        order
        submittedLearningAchievementsCount
        selfAssessment
        scheduledTime
        learners {
          id
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
          }
        }
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
      }
    }
  }
`;

const EXPORT_ACTIVITY = gql`
  mutation ExportActivity($input: ActivityExportInputType!) {
    exportActivity(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activities: {
    loading: boolean;
    loadingMore: boolean;
    data: Activity[];
    pageInfo: PageInfo | undefined;
    educationalPackageData: EducationalPackage | undefined;
    refetch: any;
    deleteActivity: (id: string) => Promise<SingleExecutionResult<DeleteActivity>>;
    fetchMoreActivities: (offset: number) => Promise<void>;
    importActivity: (id: string, activityId?: string | null) => Promise<SingleExecutionResult<ImportActivity>>;
    exportActivity: (id: string, activityTemplateFolderId: string) => Promise<SingleExecutionResult<ExportActivity>>;
  };
  updateOrder: {
    setOrder: (activityId: string, to: number) => void;
    error?: ApolloError;
    loading: boolean;
  };
  updateClassroomAssessment: {
    setAssessment: (
      activityId: string,
      selfAssessment: boolean,
      schedule?: SelfAssessmentSchedule,
    ) => Promise<void>;
    loading: boolean;
  };
}

interface ActivityListQueriesProps {
  children: RenderProp<Queries>;
  educationalPackageId: string;
}

const reorderArray = (
  activity: ActivityWithOrder,
  originalArray: Activity[],
): Activity[] => {
  const movedItem = originalArray.find(item => item.id === activity.id);
  const remainingItems = originalArray.filter(item => item.id !== activity.id);

  return [
    ...remainingItems.slice(0, activity.order),
    movedItem,
    ...remainingItems.slice(activity.order),
  ].map((item, index) => ({ ...item, order: index })) as Activity[];
};

let _networkStatus: NetworkStatus;
export const ActivityListQueries: React.FC<ActivityListQueriesProps> = ({
  children,
  educationalPackageId,
}) => {
  const [limit, setLimit] = useState(75);

  const { loading, /*error,*/ data, refetch, networkStatus } = useQuery<
    GetActivitiesTeacher,
    GetActivitiesTeacherVariables
  >(GET_ACTIVITIES, {
    context: { networkStatus: _networkStatus },
    fetchPolicy: "cache-and-network",
    pollInterval: POLLINTERVAL,
    partialRefetch: true,
    returnPartialData: true,
    variables: {
      filters: {
        educationalPackageId,
        offset: 0,
        limit: limit,
      },
      epFilters: {
        id: educationalPackageId,
      },
    },
  });

  _networkStatus = networkStatus;

  const [
    setActivityClassroomAssessment,
    { loading: setAssessmentLoading },
  ] = useMutation<
    SetActivityClassroomAssessment,
    SetActivityClassroomAssessmentVariables
  >(SET_CLASSROOM_ASSESSMENT);

  const [
    setActivityOrder,
    { loading: setActivityOrderLoading, error: setActivityOrderError },
  ] = useMutation<SetActivityOrder, SetActivityOrderVariables>(SET_ORDER);

  const [deleteActivityQuery, { loading: deleteLoading }] = useMutation<
    DeleteActivity,
    DeleteActivityVariables
  >(DELETE_ACTIVITY);

  const [importActivityQuery, { loading: importLoading }] = useMutation<
    ImportActivity,
    ImportActivityVariables
  >(IMPORT_ACTIVITY);

  const [exportActivityQuery, { loading: exportLoading }] = useMutation<
    ExportActivity,
    ExportActivityVariables
  >(EXPORT_ACTIVITY);

  const updateOrderApolloCache = (
    activity: ActivityWithOrder,
  ): MutationUpdaterFn<any> => store => {
    const { allActivities, educationalPackage } = store.readQuery({
      query: GET_ACTIVITIES,
      variables: {
        filters: {
          educationalPackageId,
          offset: 0,
          limit: limit,
        },
        epFilters: {
          id: educationalPackageId,
        },
      },
    }) as GetActivitiesTeacher;

    store.writeQuery({
      query: GET_ACTIVITIES,
      data: {
        allActivities: {
          __typename: "ActivityListDataResponse",
          data: reorderArray(activity, allActivities.data),
        },
        educationalPackage,
      },
      variables: {
        filters: {
          educationalPackageId,
          offset: 0,
          limit: limit,
        },
        epFilters: {
          id: educationalPackageId,
        },
      },
    });
  };

  const setAssessment = async (
    id: string,
    selfAssessment: boolean,
    schedule?: SelfAssessmentSchedule,
  ) => {
    await setActivityClassroomAssessment({
      variables: {
        input: {
          id,
          selfAssessment,
          schedule,
        },
      },
      optimisticResponse: {
        setClassroomAssessment: {
          __typename: "ActivityDataResponse",
          data: {
            id,
            selfAssessment,
            __typename: "Activity",
          },
        },
      },
    });
  };

  const setOrder = (id: string, to: number) => {
    const activity = {
      id,
      order: to,
      __typename: "Activity",
    } as ActivityWithOrder;

    setActivityOrder({
      variables: {
        input: {
          id,
          to,
        },
      },
      optimisticResponse: {
        setActivityOrder: {
          __typename: "ActivityDataResponse",
          data: {
            id,
            order: to,
            __typename: "Activity",
          },
        },
      },
      update: updateOrderApolloCache(activity),
    });
  };

  const deleteActivity = (id: string) => {
    return deleteActivityQuery({
      variables: {
        input: {
          id,
        },
      },
      update: store => {
        const { allActivities, educationalPackage } = store.readQuery({
          query: GET_ACTIVITIES,
          variables: {
            filters: {
              educationalPackageId,
              offset: 0,
              limit: limit,
            },
            epFilters: {
              id: educationalPackageId,
            },
          },
        }) as GetActivitiesTeacher;
        store.writeQuery({
          query: GET_ACTIVITIES,
          data: {
            allActivities: {
              __typename: "ActivityListDataResponse",
              data: allActivities.data.filter(a => a.id !== id),
            },
            educationalPackage,
          },
          variables: {
            filters: {
              educationalPackageId,
              offset: 0,
              limit: limit,
            },
            epFilters: {
              id: educationalPackageId,
            },
          },
        });
      },
      optimisticResponse: {
        deleteActivity: {
          __typename: "ActivityDataResponse",
          data: {
            id,
            __typename: "Activity",
          },
        },
      },
    });
  };

  const fetchMoreActivities = async (limit: number) => {
    setLimit(limit);
  };

  const importActivity = async (id: string, activityId?: string | null) => {
    return importActivityQuery({
      variables: {
        input: {
          id,
          educationalPackageId,
          activityId,
        }
      },
      refetchQueries: [{
        query: GET_ACTIVITIES,
        variables: {
          filters: {
            educationalPackageId,
            offset: 0,
            limit: limit,
          },
          epFilters: {
            id: educationalPackageId,
          },
        },
      }],
      awaitRefetchQueries: true,
    });
  }

  const exportActivity = async (id: string, activityTemplateFolderId: string) => {
    return exportActivityQuery({
      variables: {
        input: {
          id,
          activityTemplateFolderId,
        }
      },
    });
  }

  return children({
    activities: {
      loading:
        (networkStatus !== NetworkStatus.poll &&
          networkStatus !== NetworkStatus.fetchMore &&
          loading &&
          !(
            data &&
            data.allActivities &&
            data.allActivities.data.length > 0
          )) ||
        deleteLoading ||
        importLoading ||
        exportLoading,
      loadingMore:
        networkStatus !== NetworkStatus.poll &&
        networkStatus !== NetworkStatus.fetchMore &&
        loading,
      educationalPackageData:
        data && data.educationalPackage && data.educationalPackage.data,
      data:
        data && data.allActivities && data.allActivities.data
          ? [...data.allActivities.data].sort((a, b) => (a.order > b.order ? 1 : -1))
          : [],
      pageInfo: data && data.allActivities && data.allActivities.pageInfo,
      refetch,
      deleteActivity,
      fetchMoreActivities,
      importActivity,
      exportActivity,
    },
    updateOrder: {
      setOrder,
      loading: setActivityOrderLoading,
      error: setActivityOrderError,
    },
    updateClassroomAssessment: { setAssessment, loading: setAssessmentLoading },
  });
};
