import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import Link from "@material-ui/core/Link";

import {
  Flex,
  Box,
  Text,
  Select,
  Input,
  MenuItem,
  Loader,
  Tooltip,
} from "components/Atoms";
import { Card, CardContent, CardHeader } from "components/Molecules";
import { EditGoalTemplateFolderModal } from "components/Organisms/EditGoalTemplateFolderModal";

import { Filter } from "./Filter";
import { FolderItem } from "./FolderItem";
import { GoalTemplateFolderType } from "typings/graphql-global-types";

import { Queries } from "./Queries";

import {
  GetSchoolTemplates_allGoalTemplateFoldersBySchool_data as Folder,
  GetSchoolTemplates_allGoalTemplateFoldersBySchool_data_goalTemplates as GoalTemplate,
} from "./Queries/types/GetSchoolTemplates";

import { GetTeacherProfile_myprofile_data as MyProfile } from "../Queries/types/GetTeacherProfile";

interface Props {
  myprofile?: MyProfile | null;
}

export const SettingTitle = styled(Text)`
  color: #000;
`;

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)``;

export const GoalTemplates = (props: Props) => {
  const { t } = useTranslation();
  const [selectedSchoolId, setSelectedSchoolId] = useState<string>(
    (props.myprofile && props.myprofile.lastSelectedSchool?.id) || "default",
  );

  useEffect(() => {
    if (props.myprofile?.lastSelectedSchool) {
      setSelectedSchoolId(props.myprofile.lastSelectedSchool.id);
    }
  }, [props.myprofile]);

  const [selectedGoalType, setSelectedGoalType] = useState<
    GoalTemplateFolderType
  >(GoalTemplateFolderType.owngoal);

  const [showFolderModal, setShowFolderModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState<
    string | undefined
  >();

  const [selectedParentFolderId, setSelectedParentFolderId] = useState<
    string | undefined
  >();

  return (
    <Queries
      type={selectedGoalType}
      schoolId={selectedSchoolId === "default" ? undefined : selectedSchoolId}
    >
      {({
        schools,
        goalTemplates,
        removeGoalTemplate,
        removeGoalTemplateFolder,
        updateSelectedSchool,
      }) => {
        const selectedSchool = (schools.data?.allSchools.data || []).find(
          s => s.id === selectedSchoolId,
        );

        const folders =
          goalTemplates.data?.allGoalTemplateFoldersBySchool.data || [];
        const noFolders = !folders.length;

        const onFolderModalClosed = (result: boolean) => {
          if (result) goalTemplates.refetch();
          setShowFolderModal(false);
          setSelectedFolderId(undefined);
          setSelectedParentFolderId(undefined);
        };

        const onEditFolder = (folder: Folder) => {
          setSelectedFolderId(folder.id);
          setShowFolderModal(true);
        };

        const onAddFolder = (folder: Folder) => {
          setSelectedParentFolderId(folder.id);
          setShowFolderModal(true);
        };

        const onRemoveFolder = async (folder: Folder) => {
          await removeGoalTemplateFolder.mutate({
            variables: {
              input: {
                id: folder.id,
              },
            },
          });
          goalTemplates.refetch();
        };

        const onRemoveGoalTemplate = async (template: GoalTemplate) => {
          await removeGoalTemplate.mutate({
            variables: {
              input: { id: template.id },
            },
          });
          goalTemplates.refetch();
        };

        const cardContentLoader =
          goalTemplates.loading ||
          removeGoalTemplateFolder.loading ||
          removeGoalTemplate.loading;

        return schools.loading ? (
          <Flex px={[1, 6]} pt={[2, 5]} flexDirection="column">
            <Loader />
          </Flex>
        ) : (
          <>
            <Flex px={[1, 6]} pt={[2, 5]} flexDirection="column">
              <Flex mb={2} flexDirection={["column", "row"]} flexWrap="wrap">
                <Flex flexDirection="column" mr={[0, 4]}>
                  <SettingTitle variant="h4">
                    {t("containers.settings.goalTemplates.goalTemplateType")}
                  </SettingTitle>
                  <Box my={1} width="200px">
                    <Filter
                      value={selectedGoalType}
                      onChanged={setSelectedGoalType}
                    />
                  </Box>
                </Flex>
                <Flex mt={[2, 0]} flexDirection="column">
                  <SettingTitle variant="h4">
                    {t("globals.school")}
                  </SettingTitle>
                  <Box my={1} width="200px">
                    <Select
                      fullWidth
                      value={selectedSchoolId}
                      onChange={e => {
                        updateSelectedSchool(e.target.value as string);
                        setSelectedSchoolId(e.target.value as string);
                      }}
                      input={<Input name="school" id="school" />}
                    >
                      <MenuItem value={"default"}>
                        {t("globals.empty.noSchool")}
                      </MenuItem>
                      {(schools.data?.allSchools.data || []).map(s => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Flex>
                {/* <Flex
                  minWidth={"300px"}
                  mt={[2, 0]}
                  flex={1}
                  justifyContent={["flex-start", "flex-end"]}
                >
                  <Box>
                    <StyledLink
                      href="https://youtu.be/yM588MkvtUY"
                      target="_blank"
                    >
                      {t(
                        "containers.settings.goalTemplates.howToUseGoalTemplates",
                      )}
                    </StyledLink>
                  </Box>
                </Flex> */}
              </Flex>
              <Box mt={2}>
                {selectedSchool ? (
                  <Card>
                    <CardHeader
                      title={selectedSchool.name}
                      action={
                        <Tooltip
                          title={t(
                            "containers.settings.goalTemplates.addFolder",
                          )}
                        >
                          <Box>
                            <IconButton
                              aria-label="add new folder"
                              color="primary"
                              onClick={() => setShowFolderModal(true)}
                            >
                              <CreateNewFolderOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      }
                    ></CardHeader>
                    <StyledCardContent>
                      <Box minHeight={"150px"}>
                        {cardContentLoader ? (
                          <Box pt={2}>
                            <Loader />
                          </Box>
                        ) : noFolders ? (
                          <Box p={2}>
                            <Text>
                              {t(
                                "containers.settings.goalTemplates.noFolders_" +
                                  selectedGoalType,
                              )}
                            </Text>
                          </Box>
                        ) : (
                          folders.map(folder => (
                            <FolderItem
                              key={folder.id}
                              refetch={goalTemplates.refetch}
                              school={selectedSchool}
                              folder={folder}
                              onEdit={onEditFolder}
                              onAddFolder={onAddFolder}
                              onRemove={onRemoveFolder}
                              onRemoveGoalTemplate={onRemoveGoalTemplate}
                              type={selectedGoalType}
                            />
                          ))
                        )}
                      </Box>
                    </StyledCardContent>
                  </Card>
                ) : (
                  <Box>
                    <Text>{t("globals.empty.noSchoolSelected")}</Text>
                  </Box>
                )}
              </Box>
            </Flex>

            {selectedSchool && showFolderModal && (
              <EditGoalTemplateFolderModal
                open={showFolderModal}
                onModalClosed={onFolderModalClosed}
                folderId={selectedFolderId}
                parentFolderId={selectedParentFolderId}
                type={selectedGoalType}
                school={selectedSchool}
              />
            )}
          </>
        );
      }}
    </Queries>
  );
};
