import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const EducationalPackageIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 25 25">
    <g stroke="currentColor">
      <g>
        <g fill="none" strokeWidth="3">
          <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
          <circle cx="12.5" cy="12.5" r="11" />
        </g>
      </g>
      <g>
        <g transform="translate(7 7)" fill="none" strokeWidth="3">
          <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
          <circle cx="5.5" cy="5.5" r="4" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

EducationalPackageIcon.displayName = "EducationalPackageIcon";
