import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { UploadFile, UploadFileVariables } from "./types/UploadFile";
import { CreateFileUpload } from "./types/CreateFileUpload";
import { FileInputType } from "typings/graphql-global-types";

export const CREATE_UPLOAD = gql`
  mutation CreateFileUpload {
    createFileUpload {
      data {
        id
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      data
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  upload: {
    uploadFile: (file: File) => Promise<void>;
    fileUploads: {
      data: FileInputType[];
      loading: boolean;
    };
  };
}

interface QueriesProps {
  activityId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const [fileUploads, setFileUploadIds] = useState([] as FileInputType[]);
  const [loading, setLoading] = useState(false);

  const [createFileUpload] = useMutation<CreateFileUpload, undefined>(
    CREATE_UPLOAD,
  );
  const [uploadFileApi] = useMutation<UploadFile, UploadFileVariables>(
    UPLOAD_FILE,
  );

  const uploadFile = async (file: File) => {
    setLoading(true);
    const fileUpload = await createFileUpload();
    if (!fileUpload.data) {
      throw new Error("Cannot create file upload");
    }
    setFileUploadIds(
      fileUploads.concat([
        {
          id: fileUpload.data.createFileUpload.data.id,
          name: file.name,
        },
      ]),
    );
    try {
      await uploadFileApi({
        variables: {
          file,
        },
        context: {
          headers: {
            "portfolio-upload-id": fileUpload.data.createFileUpload.data.id,
          },
        },
      });
    } catch (err) {
      const newFileUploads = [...fileUploads];
      newFileUploads.splice(
        newFileUploads.findIndex(
          f => f.id === fileUpload.data!.createFileUpload.data.id,
        ),
        1,
      );
      setFileUploadIds(newFileUploads);
    }
    setLoading(false);
  };

  return children({
    upload: {
      uploadFile,
      fileUploads: {
        data: fileUploads,
        loading,
      },
    },
  });
};
