import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileOutlined";

import { Flex, Box, Text, Chip } from "../../Atoms";
import { Modal, FileUploadButton } from "../../Molecules";
import { Queries } from "./Queries";
import { FileInputType } from "typings/graphql-global-types";

const BlackInsertDriveFile = styled(InsertDriveFile)`
  color: #000;
`;
const StyledChip = styled(Chip)`
  color: #000;
  min-width: 200px;
  justify-content: space-between;

  ${(props: any) => props.theme.breakpoints.down("xs")} {
    width: 100%;
    justify-content: left;
  }
  .MuiChip-label {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
  }
`;

export interface FileUploadModalProps {
  activityId: string;
  open: boolean;
  onModalClosed: (result: boolean) => void;
  onAdd: (fileUplods: FileInputType[]) => void;
  loading: boolean;
  title?: string;
}

export const FileUploadModal: React.FunctionComponent<FileUploadModalProps> = ({
  activityId,
  onModalClosed,
  open,
  onAdd,
  loading: addLoading,
  title,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries activityId={activityId}>
      {({
        upload: {
          uploadFile,
          fileUploads: { loading, data },
        },
      }) => {
        const onPrimaryAction = () => {
          onAdd(data);
        };

        return (
          <Modal
            error={undefined}
            loading={addLoading || loading}
            primaryAction={{
              text: t("globals.add"),
              onClick: onPrimaryAction,
              disabled: !(data && data.length > 0) || loading,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={
              title ? title : t("components.organisms.fileUploadModal.title")
            }
            open={open}
          >
            <Flex flexDirection="column" flex={[1, "none"]}>
              <Box my={4}>
                <FileUploadButton
                  onChange={({ target }) => {
                    if (!target || !target.files || target.files.length === 0) {
                      return;
                    }
                    Array.from(target.files).forEach(file => {
                      uploadFile(file);
                    });
                  }}
                />
              </Box>
              <Box>
                <Text variant="h4">
                  {t("components.organisms.fileUploadModal.addedFiles")}
                </Text>
                <Flex pt={1.5} flexWrap="wrap">
                  {data.map(fileUpload => (
                    <Box
                      flex={[1, "none"]}
                      key={fileUpload.name}
                      mr={[0, 2]}
                      mb={2}
                    >
                      <StyledChip
                        icon={<BlackInsertDriveFile fontSize="small" />}
                        label={fileUpload.name}
                      ></StyledChip>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Flex>
          </Modal>
        );
      }}
    </Queries>
  );
};
