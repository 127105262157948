import i18next from "i18next";
import { getAssessments } from "./components/EGoalAssessmentCell";

import {
  GetEducationalPackageEGoalsOpsTable_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal,
  GetEducationalPackageEGoalsOpsTable_educationalPackage_data_learners as LearnerWithoutLearningAchievements,
} from "./components/Queries/types/GetEducationalPackageEGoalsOpsTable";

import { GetLearningAchievementsForEGoals_learningAchievements_data as LearningAchievement } from "./components/EGoalLearningAchievementQueries/types/GetLearningAchievementsForEGoals";

import { AssessmentType } from "typings/graphql-global-types";

import { format } from "date-fns";

import { exportCSVFile, RowInput, HeaderInput } from "utils/csvUtils";

import cleanText from "utils/cleanText";
import { Learner } from "./index";

export interface LearnerAssessmentsMap {
  [key: string]: string[];
}

export const exportEGoalsToCsv = (
  learners: LearnerWithoutLearningAchievements[],
  eGoals: EGoal[],
  learningAchievements: LearningAchievement[],
  selectedAssessmentType: AssessmentType,
  schoolSubject: string,
) => {
  const learnersHeader: HeaderInput = {};
  learners.forEach(({ id, givenName, familyName }) => {
    learnersHeader[id] = `${givenName} ${familyName}`;
  });

  const headers = {
    name: i18next.t("containers.teacherEducationPackage.eGoalsTable.goal"),
    ...learnersHeader,
  };

  const learnerLearningAchievements: Learner[] = learners.map(l => ({
    id: l.id,
    givenName: l.givenName || "",
    familyName: l.familyName || "",
    learningAchievements: learningAchievements.filter(
      la => la.user?.id === l.id,
    ),
  }));

  const csvRows: RowInput[] = [];

  eGoals.forEach(eGoal => {
    const goalText = cleanText(eGoal.eGoal.name);
    const shortText = goalText.split(" ")[0];

    const learnerAssessments: LearnerAssessmentsMap = {};
    let maxRows = 0;
    learnerLearningAchievements.forEach(learner => {
      const numericAssessments = getAssessments(
        eGoal,
        selectedAssessmentType,
        learner.learningAchievements,
      );

      maxRows = Math.max(maxRows, numericAssessments.length);
      numericAssessments.forEach((na, i) => {
        if (!learnerAssessments[learner.id])
          learnerAssessments[learner.id] = [na.value + ""];
        else learnerAssessments[learner.id].push(na.value + "");
      });
    });

    for (let i = 0; i < maxRows; i++) {
      const row: RowInput = {
        name: i === 0 ? shortText : "",
      };
      Object.keys(learnerAssessments).forEach(learnerId => {
        row[learnerId] = learnerAssessments[learnerId][i] || "";
      });
      csvRows.push(row);
    }
  });

  const date = format(new Date(), "dd.MM.yyyy");

  const eGoalsText = i18next.t(
    "containers.teacherEducationPackage.tabs.eGoals",
  );

  const assessmentTypeText =
    selectedAssessmentType === AssessmentType.self
      ? i18next.t("containers.teacherEducationPackage.selfAssessment")
      : i18next.t("containers.teacherEducationPackage.teacher");

  exportCSVFile(
    headers,
    csvRows,
    `${eGoalsText}-${assessmentTypeText}-${schoolSubject}-${date}.csv`.toLowerCase(),
  );
};
