import {
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import gql from "graphql-tag";

import { GetDigipolkuSurveyGroup2 } from "./types/GetDigipolkuSurveyGroup2";
import {
  UpdateLastSelectedGradeNumber,
  UpdateLastSelectedGradeNumberVariables,
} from "./types/UpdateLastSelectedGradeNumber";

export const GET_DIGIPOLKU2 = gql`
  query GetDigipolkuSurveyGroup2($filters: SurveyFilterInputType!) {
    digipolkuSurveyGroup2 {
      data {
        id
        name
        surveys(filters: $filters) {
          id
          name
          description
          teacherInstructionLink
          learningMaterialLink
          embedLink
          gradeNumber
          surveyQuestions {
            id
            name
            description
            type
            survey {
              id
              name
            }
            surveyQuestionAnswer {
              id
              numeric
              verbalText
              comments {
                id
                text
                read
                user {
                  id
                  givenName
                  familyName
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_LAST_SELECTED_GRADE_NUMBER = gql`
  mutation UpdateLastSelectedGradeNumber($input: GradeNumberInputType!) {
    updateLastSelectedGradeNumber(input: $input) {
      data {
        id
        lastSelectedGradeNumber
      }
    }
  }
`;

export const GET_LAST_SELECTED_GRADE_NUMBER = gql`
  query GetLastSelectedGradeNumber {
    myprofile {
      data {
        id
        lastSelectedGradeNumber
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  digipolku: {
    loading: boolean;
    data: GetDigipolkuSurveyGroup2 | undefined;
    getDigipolkuSurveyGroup: LazyQueryExecFunction<
      GetDigipolkuSurveyGroup2,
      OperationVariables
    >;
  };
  updateSelectedGradeNumber: (gradeNumber: number) => void;
}

interface Props {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<Props> = ({ children }) => {
  const [getDigipolkuSurveyGroup, { loading, /*error,*/ data }] = useLazyQuery<
    GetDigipolkuSurveyGroup2
  >(GET_DIGIPOLKU2);

  const [updateLastSelectedGradeNumber] = useMutation<
    UpdateLastSelectedGradeNumber,
    UpdateLastSelectedGradeNumberVariables
  >(UPDATE_LAST_SELECTED_GRADE_NUMBER);

  const updateSelectedGradeNumber = async (gradeNumber: number) => {
    await updateLastSelectedGradeNumber({
      variables: {
        input: {
          gradeNumber,
        },
      },
    });
  };

  return children({
    digipolku: { getDigipolkuSurveyGroup, loading, data },
    updateSelectedGradeNumber,
  });
};
