import React from "react";

export function ExcellentIcon({
  checked,
  view,
}: {
  view?: boolean;
  checked?: boolean;
}) {
  return (
    <svg
      data-name="Component 33 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      {!view && (
        <g data-name="Ellipse 256" fill="#fff" stroke="#00933d" strokeWidth="2">
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="20.5" fill="none" />
        </g>
      )}
      {checked && (
        <g data-name="Group 1425" transform="translate(6.88 6.28)">
          <circle
            data-name="Ellipse 5"
            cx="11.54"
            cy="11.54"
            r="11.54"
            transform="rotate(-70.54 15.4 10.89)"
            fill="#00933d"
          />
          <g
            data-name="Group 4"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="2"
          >
            <path data-name="Line 21" stroke="#fff" d="M27.54 22.97H.9" />
            <path data-name="Line 22" stroke="#fff" d="M27.74 18.77H1.1" />
            <path data-name="Line 23" stroke="#fff" d="M27.94 14.57H1.3" />
            <path data-name="Line 24" stroke="#fff" d="M28.14 10.37H1.5" />
            <path data-name="Line 25" stroke="#fff" d="M29.05 6.17H2.41" />
          </g>
          <circle
            data-name="Ellipse 7"
            cx="11.54"
            cy="11.54"
            r="11.54"
            transform="rotate(-70.54 15.4 10.89)"
            fill="none"
            stroke="#00933d"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      )}
    </svg>
  );
}
