import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";

import { Flex, Box, Text, Select, Input, MenuItem } from "components/Atoms";
import { Modal } from "components/Molecules";

import { SelfAssessmentSchedule, Status } from "typings/graphql-global-types";

export type SelfAssesmentConfirmationState = "enable" | "disable" | "nogoals";

enum PublishState {
  Now,
  Scheduled,
  Draft,
}
interface Props {
  activityStatus: Status;
  activityScheduledTime?: Date;
  state: SelfAssesmentConfirmationState;
  onModalClosed: (
    result: boolean,
    publishState?: SelfAssessmentSchedule,
  ) => void;
}

const StyledNotificationImportantOutlined = styled(
  NotificationImportantOutlined,
)`
  color: ${props => props.theme.palette.primary.dark};
`;

export const SelfAssessmentActivityConfirmationModal: React.FC<Props> = ({
  activityStatus,
  activityScheduledTime,
  state,
  onModalClosed,
}) => {
  const { t } = useTranslation();

  const [publishState, setPublishState] = useState<PublishState>(
    activityStatus === Status.draft && activityScheduledTime
      ? PublishState.Scheduled
      : activityStatus === Status.draft
      ? PublishState.Draft
      : PublishState.Now,
  );

  return (
    <Modal
      name="selfassessment-confirmation"
      primaryAction={
        state === "nogoals"
          ? {
              text: t("globals.close"),
              onClick: () => onModalClosed(false),
            }
          : {
              text:
                state === "enable"
                  ? t(
                      "containers.teacherEducationPackage.setAssessmentConfirmation.createAction",
                    )
                  : t(
                      "containers.teacherEducationPackage.setAssessmentConfirmation.removeAction",
                    ),
              onClick: () =>
                onModalClosed(
                  true,
                  state === "enable"
                    ? publishState === PublishState.Now ||
                      publishState === PublishState.Scheduled
                      ? SelfAssessmentSchedule.now
                      : SelfAssessmentSchedule.draft
                    : undefined,
                ),
            }
      }
      secondaryAction={
        state === "nogoals"
          ? undefined
          : {
              text: t("globals.cancel"),
              onClick: () => onModalClosed(false),
            }
      }
      open={true}
      headingAction={<StyledNotificationImportantOutlined />}
      title={t("globals.confirmationModal.title")}
      height="auto"
    >
      <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
        <Text>
          {state === "enable" &&
            t(
              "containers.teacherEducationPackage.setAssessmentConfirmation.enableText",
            )}
          {state === "disable" &&
            t(
              "containers.teacherEducationPackage.setAssessmentConfirmation.disableText",
            )}
          {state === "nogoals" &&
            t(
              "containers.teacherEducationPackage.setAssessmentConfirmation.nogoalsText",
            )}
        </Text>
        {state === "enable" && (
          <Box my={2} width={300}>
            <Select
              value={publishState}
              onChange={(e: any) => setPublishState(e.target.value)}
              input={<Input fullWidth name="age" id="age" />}
            >
              {activityStatus === Status.draft && activityScheduledTime && (
                <MenuItem value={PublishState.Scheduled}>
                  {t(
                    "containers.teacherEducationPackage.setAssessmentConfirmation.publishScheduled",
                  )}
                </MenuItem>
              )}

              {activityStatus === Status.active && (
                <MenuItem value={PublishState.Now}>
                  {t(
                    "containers.teacherEducationPackage.setAssessmentConfirmation.publishNow",
                  )}
                </MenuItem>
              )}

              <MenuItem value={PublishState.Draft}>
                {t(
                  "containers.teacherEducationPackage.setAssessmentConfirmation.draft",
                )}
              </MenuItem>
            </Select>
          </Box>
        )}
      </Flex>
    </Modal>
  );
};
