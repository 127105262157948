import * as React from "react";

import styled from "styled-components";

import {
  default as MuiCard,
  CardProps as MuiCardProps,
} from "@material-ui/core/Card";

import {
  default as MuiCardContent,
  CardContentProps as MuiCardContentProps,
} from "@material-ui/core/CardContent";

import {
  default as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
} from "@material-ui/core/CardHeader";

import {
  default as MuiCardActions,
  CardActionsProps as MuiCardActionsProps,
} from "@material-ui/core/CardActions";

// Card

export type CardProps = MuiCardProps;

export const Card: React.ComponentType<CardProps> = styled(
  ({ children, ...props }: CardProps) => (
    <MuiCard {...props}>{children}</MuiCard>
  ),
)``;

Card.displayName = "Card";

// CardHeader

export type CardHeaderProps = MuiCardHeaderProps;

export const CardHeader: React.ComponentType<CardHeaderProps> = styled(
  ({ titleTypographyProps, ...rest }: CardHeaderProps) => (
    <MuiCardHeader
      {...rest}
      titleTypographyProps={titleTypographyProps || { variant: "h3" }}
    ></MuiCardHeader>
  ),
)``;

CardHeader.displayName = "CardHeader";

// CardContent

export type CardContentProps = MuiCardContentProps;

export const CardContent: React.ComponentType<CardContentProps> = styled(
  MuiCardContent,
)``;

CardContent.displayName = "CardContent";

// CardActions

export type CardActionsProps = MuiCardActionsProps;

export const CardActions: React.ComponentType<CardActionsProps> = styled(
  MuiCardActions,
)``;

CardActions.displayName = "CardActions";
