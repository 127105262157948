import React from "react";

import { Queries } from "./components/Queries";
import { EGoalModal } from "./components/EGoalModal";

export interface SelectOPSEducationalPackageGoalModalProps {
  educationalPackageId?: string | null;
  open: boolean;
  onModalClosed: (result: boolean, selfAssessment: boolean) => void;
  eGoalIds: string[];
  onSetEGoalIds?: (eGoalId: string[]) => void;
  eGoals?: {
    hasAssessments: boolean;
    eGoalIds: string[];
  }[];
  otherGoals?: any;
}

export const SelectOPSEducationalPackageGoalModal: React.FunctionComponent<SelectOPSEducationalPackageGoalModalProps> = ({
  onModalClosed,
  open,
  educationalPackageId,
  eGoalIds,
  onSetEGoalIds,
  eGoals,
  otherGoals,
}) => {
  const onClose = () => {
    onModalClosed(false, false);
  };

  return (
    <Queries educationalPackageId={educationalPackageId}>
      {({
        educationalPackage: { data, loading },
        gradeLevelsAndSubjects: { data: dataGrade, loading: loadingGrade },
        subjectGradeLevelGroups: { loading: loadingSubject, data: dataSubject, fetch: fetchSubject },
        subjectsByEGoalIds: { loading: loadingSubjectsByEGoalIds, data: dataSubjectsByEGoalIds, fetch: fetchByEGoalIds },
        initLoading,
      }) => (
        <EGoalModal
          eGoals={eGoals}
          loading={loading || loadingGrade || loadingSubject || loadingSubjectsByEGoalIds || initLoading}
          data={data}
          open={open}
          onClose={onClose}
          eGoalIds={eGoalIds}
          onSetEGoalIds={onSetEGoalIds}
          otherGoals={otherGoals}
          dataGrade={dataGrade}
          dataSubject={dataSubject}
          getSubjectGradeLevels={fetchSubject}
          dataSubjectsByEGoalIds={dataSubjectsByEGoalIds}
          fetchByEGoalIds={fetchByEGoalIds}
        />
      )}
    </Queries>
  );
};
