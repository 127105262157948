import styled from "styled-components";

import { Flex, Box } from "../";

type SpacingProp = {
  spacing: number;
};

export const GridItem = Box;

export const GridContainer = styled(Flex)<SpacingProp>`
  margin: ${props => -1 * props.theme.spacing(props.spacing)}px;

  > ${Box as any} {
    padding: ${(props: any) => props.theme.spacing(props.spacing)}px;
  }

  ${(props: any) => props.theme.breakpoints.down("xs")} {
    margin: 0;
    > ${Box as any} {
      width: 100%;
      padding: 0;
      padding-bottom: ${(props: any) => props.theme.spacing(5)}px;
    }
  }
`;
