import { ApolloError, useMutation, useQuery, SingleExecutionResult } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  GetLearningAchievementMaterials_learningAchievement_data as Achievement,
  GetLearningAchievementMaterials,
  GetLearningAchievementMaterialsVariables,
} from "./types/GetLearningAchievementMaterials";

import {
  DeleteLearningAchievementMaterials,
  DeleteLearningAchievementMaterialsVariables,
  DeleteLearningAchievementMaterials_deleteLearningAchievementMaterials_data as Material,
} from "./types/DeleteLearningAchievementMaterials";

export const DELETE_ACHIEVEMENT_MATERIALS = gql`
  mutation DeleteLearningAchievementMaterials(
    $input: DeleteMaterialInputType!
  ) {
    deleteLearningAchievementMaterials(input: $input) {
      data {
        id
      }
    }
  }
`;

export const GET_ACHIEVEMENT_MATERIALS = gql`
  query GetLearningAchievementMaterials($filters: LearningAchievementInput!) {
    learningAchievement(filters: $filters) {
      data {
        id
        materials {
          id
          name
          link
          status
          type
        }
        activity {
          id
          name
        }
        user {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  achievement: {
    loading: boolean;
    data: Achievement | null;
  };
  mutate: {
    removeMaterials: (
      removeMaterials: Material[],
    ) => Promise<SingleExecutionResult<DeleteLearningAchievementMaterials>>;
    error?: ApolloError;
    loading?: boolean;
  };
}

interface QueriesProps {
  activityId: string;
  userId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  activityId,
  userId,
}) => {
  const { loading: achievementLoading, data: achievementData } = useQuery<
    GetLearningAchievementMaterials,
    GetLearningAchievementMaterialsVariables
  >(GET_ACHIEVEMENT_MATERIALS, {
    variables: { filters: { activityId, userId } },
  });

  const [
    deleteMaterials,
    { loading: deleting, error: deleteError },
  ] = useMutation<
    DeleteLearningAchievementMaterials,
    DeleteLearningAchievementMaterialsVariables
  >(DELETE_ACHIEVEMENT_MATERIALS);

  const removeMaterials = (materialsToBeRemoved: Material[]) => {
    return deleteMaterials({
      variables: {
        input: {
          learningAchievementMaterialIds: materialsToBeRemoved.map(m => m.id),
        },
      },
      update: store => {
        const { learningAchievement } = store.readQuery({
          query: GET_ACHIEVEMENT_MATERIALS,
          variables: { filters: { activityId, userId } },
        }) as GetLearningAchievementMaterials;

        if (learningAchievement && learningAchievement.data) {
          store.writeQuery({
            query: GET_ACHIEVEMENT_MATERIALS,
            data: {
              learningAchievement: {
                ...learningAchievement,
                materials: learningAchievement.data.materials.filter(
                  m => !materialsToBeRemoved.find(rm => rm.id === m.id),
                ),
              },
            },
            variables: { filters: { activityId, userId } },
          });
        }
      },
      optimisticResponse: {
        deleteLearningAchievementMaterials: {
          __typename: "LearningAchievementMaterialListDataResponse",
          data: materialsToBeRemoved.map(m => ({
            id: m.id,
            __typename: "LearningAchievementMaterial",
          })),
        },
      },
    });
  };

  return children({
    achievement: {
      loading: achievementLoading,
      data: achievementData ? achievementData.learningAchievement.data : null,
    },
    mutate: {
      removeMaterials,
      error: deleteError,
      loading: deleting,
    },
  });
};
