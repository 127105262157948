import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Formik } from "formik";

import AnimateHeight from "react-animate-height";

import { Box, Flex, FloatableButton } from "components/Atoms";
import { CardItemContext } from "components/Contexts/CardGroup";
import { useSnackbar } from "components/Contexts/SnackbarContext";
import { Card, CardActions, CardHeader } from "components/Molecules/Card";

import { SurveyForm } from "./components/SurveyForm";

import StarIcon from "@material-ui/icons/Star";
import { GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys as Survey } from "../Queries/types/GetDigipolkuSurveyGroup2";
import { SubmitQueries } from "../SubmitQueries";

export interface SurveyCardProps {
  survey: Survey;
  scrollContainer?: HTMLElement | null;
  gradingScale: number;
}

type ExpandedProp = {
  expanded?: boolean;
  height?: string;
};

const Collapsible = styled.div<ExpandedProp>`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const ResponsiveCard = styled(Card)`
  width: 100%;
  position: relative;
  ${props => props.theme.breakpoints.down("xs")} {
    border-radius: 0;
  }
`;

const CardItem = styled(Box)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;
`;

const StyledStarIcon = styled(StarIcon)`
  margin-bottom: -5px;
`;

export const SurveyCardCard: React.FunctionComponent<SurveyCardProps> = ({
  survey,
  scrollContainer,
  gradingScale,
}) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const id = survey.id;
  const title = survey.name;
  const subtitle = survey.description;
  const showStarIcon = !!survey.learningMaterialLink;

  return (
    <CardItemContext.Consumer>
      {({ expandedItem, toggleExpanded }) => (
        <ResponsiveCard data-cy="survey-card">
          <StyledCardHeader
            onClick={e => toggleExpanded(id === expandedItem ? "" : id)}
            title={
              (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <Box>
                      {showStarIcon && <StyledStarIcon />}
                      {title}
                    </Box>
                  </Flex>
                  {subtitle && (
                    <Flex alignItems="center">
                      <Box>
                        <span
                          style={{
                            color: "darkgray",
                            fontSize: "1rem",
                            fontStyle: "italic",
                          }}
                        >
                          {subtitle}
                        </span>
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ) as any
            }
            titleTypographyProps={{ variant: "h3" }}
          />
          <Collapsible expanded={id === expandedItem}>
            <AnimateHeight height={id === expandedItem ? "auto" : 0}>
              <SubmitQueries>
                {({ answer: { loading, update } }) => (
                  <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={{
                      surveyQuestionAnswers: survey.surveyQuestions.map(q => {
                        if (q.surveyQuestionAnswer) {
                          const { id, ...noId } = q.surveyQuestionAnswer;
                          return {
                            ...noId,
                            surveyQuestionId: q.id,
                          };
                        }
                        return { surveyQuestionId: q.id };
                      }),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);

                      await update(
                        values.surveyQuestionAnswers.map(a => ({
                          ...a,
                          comments: undefined,
                          __typename: undefined,
                        })),
                      );
                      showSnackbar({
                        id: "submitted",
                        type: "success",
                        message: t(
                          "containers.digipolku.surveyCard.submitSuccess",
                        ),
                      });
                      setSubmitting(false);
                    }}
                  >
                    {props => {
                      return (
                        <>
                          <CardItem>
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <SurveyForm
                                questions={survey.surveyQuestions}
                                teacherInstructionLink={
                                  survey.teacherInstructionLink
                                }
                                learningMaterialLink={
                                  survey.learningMaterialLink
                                }
                                embedLink={survey.embedLink}
                                isAssessment={survey.id === "selfAssessment"}
                                gradingScale={gradingScale}
                                {...props}
                              />
                            </Flex>
                          </CardItem>
                          <CardActions>
                            <Flex
                              width="100%"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {survey.id === "selfAssessment" &&
                                id === expandedItem && (
                                  <FloatableButton
                                    scrollContainer={scrollContainer}
                                    data-cy="primary-action-button"
                                    onClick={() => props.handleSubmit()}
                                    disabled={
                                      !props.isValid || props.isSubmitting
                                    }
                                  >
                                    {t("globals.save")}
                                  </FloatableButton>
                                )}
                            </Flex>
                          </CardActions>
                        </>
                      );
                    }}
                  </Formik>
                )}
              </SubmitQueries>
            </AnimateHeight>
          </Collapsible>
        </ResponsiveCard>
      )}
    </CardItemContext.Consumer>
  );
};
