import React, { useState } from "react";

import { useAnalytics } from "components/Hooks/firebase-hooks";

import { NavigationComponent } from "typings";
import { useHistory } from "react-router";

import { Flex, Box, Loader } from "components/Atoms";

import { CardGroup } from "components/Contexts/CardGroup";

import { Queries, Hero, SurveyCardCard } from "./components";

import { AudioPlayerContext } from "components/Contexts/AudioPlayerContext";

interface DigipolkuProps {}

const Digipolku: NavigationComponent<DigipolkuProps> = () => {
  const { analytics } = useAnalytics();
  const history = useHistory();

  const [playingUrl, setPlayingUrl] = useState<string | null | undefined>();

  const [ref, setRef] = useState();

  analytics.logEvent("page_view" as string, {
    page_title: "Digipolku",
    page_path: history.location.pathname,
  });

  return (
    <Flex
      height="85vh"
      overflow="auto"
      flexDirection="column"
      ref={(newRef: any) => setRef(newRef)}
    >
      <Hero />

      <Flex py={[4, 6]} flexDirection="column">
        {ref && (
          <Queries>
            {({ digipolku: { loading, data } }) => {
              const digipolkuSurveys =
                (data &&
                  data.digipolkuSurveyGroup &&
                  data.digipolkuSurveyGroup.data.surveys) ||
                [];
              return (
                <AudioPlayerContext.Provider
                  value={{ playingUrl, setPlayingUrl }}
                >
                  <Box px={[0, 2, 6]}>
                    {loading && <Loader />}
                    {data && (
                      <CardGroup
                        defaultOpen={
                          digipolkuSurveys.length > 0
                            ? digipolkuSurveys[0].id
                            : ""
                        }
                      >
                        {digipolkuSurveys.map(survey => (
                          <Box key={survey.id} my={2}>
                            <SurveyCardCard
                              scrollContainer={ref}
                              survey={survey}
                            />
                          </Box>
                        ))}
                      </CardGroup>
                    )}
                  </Box>
                </AudioPlayerContext.Provider>
              );
            }}
          </Queries>
        )}
      </Flex>
    </Flex>
  );
};

Digipolku.navigationOptions = async ({ t }) => {
  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "digipolku",
        label: t("globals.digipolku"),
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default Digipolku;
