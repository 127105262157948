import React from "react";

export const FairlyGoodIcon = ({
  checked,
  view,
}: {
  view?: boolean;
  checked?: boolean;
}) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43">
      <g fill="none">
        {!view && (
          <g>
            <circle fill="#FFF" cx="21.5" cy="21.5" r="21.5" />
            <circle
              stroke="#FF7600"
              strokeWidth="2"
              cx="21.5"
              cy="21.5"
              r="20.5"
            />
          </g>
        )}
        {(view || checked) && (
          <g transform="rotate(90 16.267 22.233)">
            <path
              d="M15.766 5c6.498 0 11.766 5.268 11.766 11.766s-5.268 11.766-11.766 11.766S4 23.264 4 16.766 9.268 5 15.766 5z"
              fill="#FF7600"
            />
            <g stroke="#FFF" strokeWidth="2">
              <path d="M.6 20.055L19.798.857M3.483 23.227L22.681 4.029M6.366 26.399L25.564 7.201M9.249 29.571l19.198-19.198M11.616 33.26l19.198-19.198" />
            </g>
            <circle
              stroke="#FF7600"
              strokeWidth="2"
              cx="15.766"
              cy="16.766"
              r="11.766"
            />
          </g>
        )}
      </g>
    </svg>
  );
};
