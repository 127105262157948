import React from "react";
import styled from "styled-components";

import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";

import { useTranslation } from "react-i18next";

import { Box, Flex, Tooltip } from "components/Atoms";

import {
  ExcellentIcon,
  FairIcon,
  FairlyGoodIcon,
  GoodIcon,
  VerbalAssessmentActiveIcon,
  VerbalAssessmentInactiveIcon,
  VeryGoodIcon,
} from "components/Atoms/Icons";

import { QuestionType } from "typings/graphql-global-types";

import { AssessmentValueScale3, AssessmentValueScale5 } from "components/Atoms/NumericAssessmentInput";

import {
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion,
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswers as SurveyQuestionAnswer,
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswers_user as User,
} from "../Queries/types/GetDigipolkuSurveyGroupAnswers";

import { ExtendedAssessmentType } from "../../index";

const StyledRemoveOutlinedIcon = styled(RemoveOutlinedIcon)`
  margin-top: -4px;
`;

const FillBox = styled(Box)`
  width: 52px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SurveyAnswerCellProps {
  questions: SurveyQuestion[];
  user: User;
  onClickViewVerbalEvaluation: (
    answer: SurveyQuestionAnswer,
    question: SurveyQuestion,
  ) => void;
  surveyType: ExtendedAssessmentType;
  onClickEditComment: (surveyQuestionAnswerId: string) => void;
  gradingScale: number;
}

const NoVerbalAssessment: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      arrow
      title={t(
        "containers.teacherEducationPackage.assessmentsTable.noVerbalAssessment",
      )}
    >
      <Box textAlign="center">
        <IconButton aria-label="verbal assessment info button" disabled>
          <Box>
            <VerbalAssessmentInactiveIcon />
          </Box>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export const SurveyAnswerCell: React.FC<SurveyAnswerCellProps> = ({
  user,
  questions,
  onClickViewVerbalEvaluation,
  surveyType,
  onClickEditComment,
  gradingScale,
}) => {
  const { t } = useTranslation();

  const verbalQuestions = questions.filter(f => f.type === QuestionType.verbal);

  return (
    <Flex
      py={0.5}
      height={"100%"}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      {questions
        .filter(f => f.type === QuestionType.numeric)
        .map((q, i) => {
          let tooltipText = t(`globals.empty.noAssessment`);

          const answer = q.surveyQuestionAnswers.find(
            sqa => sqa.user.id === user.id,
          ) || { numeric: undefined };

          if (gradingScale === 3) {
            if (answer.numeric === AssessmentValueScale3.Fair) {
              tooltipText = t(`globals.numericAssessments.self.fair`);
            } else if (answer.numeric === AssessmentValueScale3.Good) {
              tooltipText = t(`globals.numericAssessments.self.mediocre`);
            } else if (answer.numeric === AssessmentValueScale3.Excellent) {
              tooltipText = t(`globals.numericAssessments.self.excellent`);
            }
          }

          if (gradingScale === 5) {
            if (answer.numeric === AssessmentValueScale5.Fair) {
              tooltipText = t(`globals.numericAssessments.self.fair`);
            } else if (answer.numeric === AssessmentValueScale5.FairlyGood) {
              tooltipText = t(`globals.numericAssessments.self.fairlyGood`);
            } else if (answer.numeric === AssessmentValueScale5.Good) {
              tooltipText = t(`globals.numericAssessments.self.mediocre`);
            } else if (answer.numeric === AssessmentValueScale5.VeryGood) {
              tooltipText = t(`globals.numericAssessments.self.good`);
            } else if (answer.numeric === AssessmentValueScale5.Excellent) {
              tooltipText = t(`globals.numericAssessments.self.excellent`);
            }
          }

          return (
            <React.Fragment key={q.id}>
              <Tooltip
                arrow
                PopperProps={{ style: { marginTop: "-10px" } }}
                title={tooltipText}
              >
                <Flex
                  marginTop={-0.2}
                  mb="7px"
                  justifyContent="center"
                  width={["100%"]}
                >
                  {gradingScale === 3 && (
                    <>
                      {answer.numeric === AssessmentValueScale3.Fair && (
                        <FairIcon view checked></FairIcon>
                      )}
                      {answer.numeric === AssessmentValueScale3.Good && (
                        <GoodIcon view checked></GoodIcon>
                      )}
                      {answer.numeric === AssessmentValueScale3.Excellent && (
                        <ExcellentIcon view checked></ExcellentIcon>
                      )}
                    </>
                  )}
                  {gradingScale === 5 && (
                    <>
                      {answer.numeric === AssessmentValueScale5.Fair && (
                        <FairIcon view checked></FairIcon>
                      )}
                      {answer.numeric === AssessmentValueScale5.FairlyGood && (
                        <FairlyGoodIcon view checked></FairlyGoodIcon>
                      )}
                      {answer.numeric === AssessmentValueScale5.Good && (
                        <GoodIcon view checked></GoodIcon>
                      )}
                      {answer.numeric === AssessmentValueScale5.VeryGood && (
                        <VeryGoodIcon view checked></VeryGoodIcon>
                      )}
                      {answer.numeric === AssessmentValueScale5.Excellent && (
                        <ExcellentIcon view checked></ExcellentIcon>
                      )}
                    </>
                  )}
                  {!answer.numeric && (
                    <FillBox>
                      <StyledRemoveOutlinedIcon fontSize="small" />
                    </FillBox>
                  )}
                </Flex>
              </Tooltip>

              <Box>
                {answer.numeric ? (
                  <Tooltip
                    arrow
                    title={t(
                      "containers.teacherEducationPackage.assessmentsTable.editComment",
                    )}
                  >
                    <IconButton
                      data-cy="edit-assessment-button"
                      aria-label="edit comment button"
                      onClick={() => onClickEditComment(answer.id)}
                    >
                      <Badge
                        overlap="rectangle"
                        badgeContent={(answer.comments || []).length}
                        color="primary"
                      >
                        <Box pr={(answer.comments || []).length > 0 ? 0.55 : 0}>
                          <EditIcon color="primary" />
                        </Box>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip arrow title={t("globals.empty.noAssessment")}>
                    <Box textAlign="center">
                      <IconButton
                        disabled
                        aria-label="edit comment button disabled"
                      >
                        <Box>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </React.Fragment>
          );
        })}
      {verbalQuestions.map((a, i) => {
        const answer = a.surveyQuestionAnswers.find(
          sqa => sqa.user.id === user.id,
        );
        if (!answer) return <NoVerbalAssessment key={a.id} />;
        return (
          <Tooltip
            key={a.id}
            arrow
            title={
              surveyType === ExtendedAssessmentType.ownGoals
                ? a.name
                : t(
                    "containers.teacherEducationPackage.assessmentsTable.showVerbalAssessment",
                  )
            }
          >
            <IconButton
              aria-label="verbal assessment info button"
              onClick={() => onClickViewVerbalEvaluation(answer, a)}
            >
              <Box>
                <VerbalAssessmentActiveIcon color="primary" />
              </Box>
            </IconButton>
          </Tooltip>
        );
      })}
      {verbalQuestions.length === 0 && <NoVerbalAssessment />}
    </Flex>
  );
};
