import React, { useState, useEffect } from "react";
import { pathOr } from "ramda";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Button, Flex, Box } from "components/Atoms";

import { Hero as HeroComponent, HeroItem, JoinCodeModal } from "components/Organisms";

import { DropdownOptionItem } from "components/Molecules/DropdownMenu";

import {
  GetEducationalPackageTeacher as EducationalPackage,
  GetEducationalPackageTeacher_educationalPackage_data_teachers as Teacher,
  GetEducationalPackageTeacher_educationalPackage_data_schoolSubjects as SchoolSubjects,
} from "../Queries/types/GetEducationalPackageTeacher";

type HiddenProp = {
  isHidden: boolean;
};

const DetailsWrapper = styled(Flex) <HiddenProp>`
  ${props => (props.isHidden ? "display: none" : "")};
`;
interface Props {
  onContextMenuChanged: (o: DropdownOptionItem) => void;
  data?: EducationalPackage;
}

export const Hero: React.FC<Props> = ({ data, onContextMenuChanged }) => {
  const { t } = useTranslation();
  const isMobileLayout = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs"),
  );
  const [hideDetails, setHideDetails] = useState(isMobileLayout);
  const [showJoinCodeModal, setShowJoinCodeModal] = useState(false);

  useEffect(() => {
    setHideDetails(isMobileLayout);
  }, [isMobileLayout, setHideDetails]);

  const heroItems = [
    {
      name: "teachers",
      title: t("containers.teacherEducationPackage.teachers"),
      text: pathOr(
        [] as Teacher[],
        "educationalPackage.data.teachers".split("."),
        data,
      )
        .map(t => `${t.givenName} ${t.familyName}`)
        .join(", "),
    },
    {
      name: "learners",
      title: t("containers.teacherEducationPackage.learners"),
      text: pathOr(0, "educationalPackage.data.learnersCount".split("."), data),
    },
    {
      name: "gradelevel",
      title: t("containers.teacherEducationPackage.gradeLevel"),
      text: pathOr(
        "",
        "educationalPackage.data.gradeLevel.name".split("."),
        data,
      ),
    },
    {
      name: "schoolsubjects",
      title: t("containers.teacherEducationPackage.schoolSubjects"),
      text: pathOr(
        [] as SchoolSubjects[],
        "educationalPackage.data.schoolSubjects".split("."),
        data,
      )
        .map(s => s.name)
        .join(", "),
    },
    ...(!data || (data && data.educationalPackage.data.importedPackage)
      ? []
      : [
        {
          name: "joincode",
          title: t("containers.teacherEducationPackage.joinCode"),
          text: pathOr(
            "",
            "educationalPackage.data.joinCode".split("."),
            data,
          ),
          onIconClick: () => setShowJoinCodeModal(true),
        },
      ]),
  ];

  return (
    <>
      <HeroComponent
        loading={!data}
        onContextMenuChanged={onContextMenuChanged}
        title={pathOr("", "educationalPackage.data.name".split("."), data)}
        iconType="educationalPackage"
        classroomImported={data && data.educationalPackage.data.importedPackage}
        colorTheme={data && data.educationalPackage.data.colorTheme}
      >
        {(isMobileLayout || hideDetails) && (
          <Box pt={1}>
            <Button size="small" onClick={() => setHideDetails(!hideDetails)}>
              {hideDetails
                ? t("containers.teacherEducationPackage.showMore")
                : t("containers.teacherEducationPackage.showLess")}
            </Button>
          </Box>
        )}
        <DetailsWrapper
          isHidden={hideDetails}
          mt={[1, 4]}
          pr={[2, 6]}
          width={["100%", "100%", "100%", "100%", "70%"]}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {heroItems.map((h, i) => (
            <HeroItem key={`hero-item-${i}`} {...h}></HeroItem>
          ))}
        </DetailsWrapper>
      </HeroComponent>
      {showJoinCodeModal && data?.educationalPackage.data.joinCode && (
        <JoinCodeModal
          open={showJoinCodeModal}
          onModalClosed={() => setShowJoinCodeModal(false)}
          joinCode={data.educationalPackage.data.joinCode}
        />
      )}
    </>
  );
};
