import * as React from "react";

import styled from "styled-components";

import { Flex, Box, Text, Button, Loader } from "../../Atoms";
import { ModalBase, ModalBaseProps } from "../../Atoms/ModalBase";

const ModalSizeLoader = styled(Loader)`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #6c6c6cab;
`;

const StyledFlex = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${props => (props.width ? props.width : "600px")};
  max-width: 100%;

  height: ${props => (props.height ? props.height : "545px")};
  max-height: 92%;

  ${props => props.theme.breakpoints.down("xs")} {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 92%;
    border-radius: 0;
  }
  overflow: hidden;
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
  outline: none;
  border-radius: ${props => props.theme.spacing(1.5)}px;
`;

const Heading = styled(Flex)`
  background-color: ${props => props.theme.palette.grey[100]};
`;

const Actions = styled(Flex)`
  border-top: 1px solid #f1f0f4;
`;

export const Content = styled(Flex)`
  overflow: auto;
`;

export type ModalProps = {
  title: string;
  subtitle?: string;
  headingAction?: React.ReactNode;
  loading?: boolean;
  error?: Error;
  children: React.ReactNode;
  primaryAction: {
    text: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick?: () => void;
  };
  secondaryAction?: {
    text: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick: () => void;
  };
  tertiaryAction?: {
    text: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick: () => void;
  };
  width?: string;
  height?: string;
  name?: string;
  fullscreen?: boolean;
} & ModalBaseProps;

export const Modal: React.FunctionComponent<ModalProps> = ({
  title,
  subtitle,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  children,
  loading,
  error,
  width,
  height,
  name,
  headingAction,
  fullscreen,
  ...props
}) => {
  return (
    <ModalBase {...props} data-cy={`${name}-modal`}>
      <StyledFlex width={width} height={height} flexDirection="column">
        {loading && !error && <ModalSizeLoader data-cy={"modal-loader"} />}
        <Heading
          pb={subtitle || headingAction ? 1.5 : 2.5}
          px={[1, 3]}
          pt={subtitle || headingAction ? 1.5 : 2.5}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex flexDirection="column">
            <Text variant="h3">{title}</Text>
            {subtitle && <Text variant="subtitle1">{subtitle}</Text>}
          </Flex>
          {headingAction && headingAction}
        </Heading>
        <Content mb={2} px={[1, 3]} flex={1}>
          {children}
        </Content>
        {error && (
          <Flex>
            <Text color="error">{error.message}</Text>
          </Flex>
        )}
        <Actions flexDirection="column">
          <Flex px={3} py={2.5} justifyContent="flex-end">
            {tertiaryAction && (
              <Box mr={0.5}>
                <Button
                  data-cy="tertiary-action"
                  disabled={tertiaryAction.disabled}
                  type={tertiaryAction.type}
                  onClick={tertiaryAction.onClick}
                >
                  {tertiaryAction.text}
                </Button>
              </Box>
            )}
            {secondaryAction && (
              <Box mr={0.5}>
                <Button
                  disabled={secondaryAction.disabled}
                  type={secondaryAction.type}
                  data-cy="secondary-action"
                  onClick={secondaryAction.onClick}
                >
                  {secondaryAction.text}
                </Button>
              </Box>
            )}
            <Button
              data-cy="primary-action"
              onClick={primaryAction.onClick}
              disabled={primaryAction.disabled}
              type={primaryAction.type}
            >
              {primaryAction.text}
            </Button>
          </Flex>
        </Actions>
      </StyledFlex>
    </ModalBase>
  );
};

Modal.displayName = "Modal";
