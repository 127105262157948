import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  GetEducationalPackageSubmittedActivities,
  GetEducationalPackageSubmittedActivitiesVariables,
  GetEducationalPackageSubmittedActivities_allActivities_data as Activity,
} from "./types/GetEducationalPackageSubmittedActivities";

export const GET_ACTIVITES = gql`
  query GetEducationalPackageSubmittedActivities(
    $filters: ActivityListFiltersInputType!
  ) {
    allActivities(filters: $filters) {
      data {
        id
        name
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
            teacherScaleTexts
          }
        }
        learningAchievements {
          id
          numericAssessments {
            id
            value
            educationalPackageGoalId
            type
          }
          verbalAssessments {
            id
            assessmentText
            type
            user {
              givenName
              familyName
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activities: {
    loading: boolean;
    data: Activity[];
  };
}

interface SubmittedActivityListQueriesProps {
  children: RenderProp<Queries>;
  educationalPackageId: string;
}

export const SubmittedActivityListQueries: React.FC<SubmittedActivityListQueriesProps> = ({
  children,
  educationalPackageId,
}) => {
  const { loading, data } = useQuery<
    GetEducationalPackageSubmittedActivities,
    GetEducationalPackageSubmittedActivitiesVariables
  >(GET_ACTIVITES, {
    variables: {
      filters: {
        educationalPackageId,
      },
    },
  });

  return children({
    activities: { loading, data: data ? data.allActivities.data : [] },
  });
};
