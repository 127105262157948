import React from "react";

interface AudioPlayerContext {
  playingUrl?: string | null;
  setPlayingUrl: (url: string | null | undefined) => void;
}

export const AudioPlayerContext = React.createContext<AudioPlayerContext>({
  playingUrl: null,
  setPlayingUrl: () => {},
});
