import { ApolloError, useLazyQuery, useMutation, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  CreateGoalTemplateFolder,
  CreateGoalTemplateFolderVariables,
} from "./types/CreateGoalTemplateFolder";
import {
  GetGoalTemplateFolderEdit,
  GetGoalTemplateFolderEditVariables,
} from "./types/GetGoalTemplateFolderEdit";
import {
  UpdateGoalTemplateFolder,
  UpdateGoalTemplateFolderVariables,
} from "./types/UpdateGoalTemplateFolder";

export const GET = gql`
  query GetGoalTemplateFolderEdit($filters: IdInputType!) {
    goalTemplateFolder(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        goalTemplates {
          id
        }
      }
    }
  }
`;

export const UPDATE = gql`
  mutation UpdateGoalTemplateFolder(
    $input: GoalTemplateFolderUpdateInputType!
  ) {
    updateGoalTemplateFolder(input: $input) {
      data {
        id
        name
        user {
          id
        }

        goalTemplates {
          id
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateGoalTemplateFolder(
    $input: GoalTemplateFolderCreateInputType!
  ) {
    createGoalTemplateFolder(input: $input) {
      data {
        id
        name
        user {
          id
        }
        goalTemplates {
          id
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  goalTemplateFolder: {
    loading: boolean;
    data: GetGoalTemplateFolderEdit | undefined;
  };
  create: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            CreateGoalTemplateFolder,
            CreateGoalTemplateFolderVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<CreateGoalTemplateFolder>>;
    error?: ApolloError;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            UpdateGoalTemplateFolder,
            UpdateGoalTemplateFolderVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateGoalTemplateFolder>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  folderId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, folderId }) => {
  const [
    loadFolder,
    { loading: loadingFolder, data: dataFolder },
  ] = useLazyQuery<
    GetGoalTemplateFolderEdit,
    GetGoalTemplateFolderEditVariables
  >(GET);

  useEffect(() => {
    const fetchData = async () => {
      if (folderId) {
        await loadFolder({
          variables: {
            filters: {
              id: folderId,
            },
          },
        });
      }
    };
    fetchData();
  }, [folderId, loadFolder]);

  const [createFolder, { error: createError }] = useMutation<
    CreateGoalTemplateFolder,
    CreateGoalTemplateFolderVariables
  >(CREATE);

  const [updateFolder, { error: updateError }] = useMutation<
    UpdateGoalTemplateFolder,
    UpdateGoalTemplateFolderVariables
  >(UPDATE);

  return children({
    goalTemplateFolder: {
      loading: loadingFolder,
      data: dataFolder,
    },
    create: { mutation: createFolder, error: createError },
    update: { mutation: updateFolder, error: updateError },
  });
};
