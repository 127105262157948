import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, Box, Checkbox, InputLabel, Input } from "../../../Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { UserMultiSelect } from "components/Molecules";
import { ActivityUpdateInputType } from "typings/graphql-global-types";
import { GetEducationalPackageLearnersActivityEdit_educationalPackage_data_learners as Learner } from "../Queries/types/GetEducationalPackageLearnersActivityEdit";

const Form = styled.form`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  &.MuiFormControlLabel-root {
    padding-top: 0;
  }
`;

interface ActivityFormProps extends FormikProps<ActivityUpdateInputType> {
  educationalPackageLearners: Learner[];
  activityTemplateFolderId?: string | null;
  hideLocalInputs?: boolean;
}

const StyledInputLabel = styled(InputLabel)`
  position: initial;
`;

export const ActivityForm: React.FC<ActivityFormProps> = ({
  values: { name, description, selfAssessment, learnerIds },
  educationalPackageLearners,
  errors,
  touched,
  handleChange,
  setFieldTouched,
  activityTemplateFolderId,
  hideLocalInputs,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        {!activityTemplateFolderId && hideLocalInputs && (
          <Flex flexDirection={["column", "row"]}>
            <Box flex={4}>
              <FormControl fullWidth>
                <Box>
                  <StyledInputLabel>
                    {t("components.organisms.editActivityForm.learners")}
                  </StyledInputLabel>
                </Box>
                <UserMultiSelect
                  id="learnerIds"
                  name="learnerIds"
                  selected={learnerIds}
                  fullWidth
                  displayEmpty
                  input={<Input value={"Test"} />}
                  users={educationalPackageLearners.map(l => ({
                    id: l.id,
                    displayName: `${l.givenName} ${l.familyName}`,
                  }))}
                  onChange={change.bind(null, "learnerIds")}
                />
              </FormControl>
            </Box>
            <Box flex={5} ml={[0, 3]}>
              <FormControl fullWidth>
                <Box>
                  <StyledInputLabel>
                    {t("components.organisms.editActivityForm.assessments")}
                  </StyledInputLabel>
                </Box>
                <StyledCheckbox
                  id="selfAssessment"
                  label={t(
                    "components.organisms.editActivityForm.selfAssessment",
                  )}
                  checked={!!selfAssessment}
                  onChange={change.bind(null, "selfAssessment")}
                />
              </FormControl>
            </Box>
          </Flex>
        )}
        <FormControl>
          <InputLabel htmlFor="name">
            {t("components.organisms.editActivityForm.name")}
          </InputLabel>
          <Input
            id="name"
            name="name"
            helperText={touched.name ? errors.name : ""}
            error={touched.name && Boolean(errors.name)}
            value={name}
            onChange={change.bind(null, "name")}
            fullWidth
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="name">
            {t("components.organisms.editActivityForm.description")}
          </InputLabel>
          <Input
            id="description"
            name="description"
            multiline
            rows={8}
            helperText={touched.description ? errors.description : ""}
            error={touched.description && Boolean(errors.description)}
            value={description}
            onChange={change.bind(null, "description")}
            fullWidth
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
