import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Modal } from "../../Molecules";
import { Queries } from "./Queries";
import { JoinByCodeForm } from "./Form";

export interface EditActivityModalProps {
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

export const JoinEducationalPackageByCodeModal: React.FunctionComponent<EditActivityModalProps> = ({
  onModalClosed,
  open,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries>
      {({ joinByCode }) => (
        <Formik
          enableReinitialize
          initialValues={{
            joinCode: "",
          }}
          validationSchema={Yup.object().shape({
            joinCode: Yup.string()
              .required(t("globals.validations.codeRequired"))
              .length(6, t("globals.validations.codeMax")),
          })}
          onSubmit={async (values: any, { setSubmitting }) => {
            setSubmitting(true);
            await joinByCode.mutation({
              variables: {
                input: {
                  ...values,
                }
              }
            })
            setSubmitting(false);
            onModalClosed(true);
          }}
        >
          {props => {
            const { isSubmitting, handleSubmit, isValid, touched, values } = props;

            return (
              <Modal
                name="join-by-code"
                height="270px"
                error={joinByCode.error}
                loading={isSubmitting}
                primaryAction={{
                  text: t("globals.add"),
                  onClick: handleSubmit,
                  disabled:
                    !values.joinCode || touched
                      ? false
                      : !isValid,
                }}
                secondaryAction={{
                  text: t("globals.cancel"),
                  onClick: onCancel,
                }}
                title={t("components.organisms.joinEducationalPackageByCodeModal.title")}
                open={open}
              >
                <JoinByCodeForm {...props} />
              </Modal>
            );
          }}
        </Formik>
      )}
    </Queries>
  );
};
