import React, { useState } from "react";
import { SingleExecutionResult } from "@apollo/client";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportantOutlined";
import { FormikProps } from "formik";

import { Text, Flex, Box, Button } from "components/Atoms";

import { AssessmentForm, FormValues } from "../Form";
import { SelfAssessmentModal } from "../SelfAssessmentModal";

import {
  AssessmentType,
  NumericAssessmentInput as NumericAssessmentInputProp,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

import { EditAssessmentUpdateAssessmentForm } from "../Queries/types/EditAssessmentUpdateAssessmentForm";

import {
  EditAssessmentGetUserActivity_activity_data as Activity,
  EditAssessmentGetUserActivity_activity_data_learners as User,
  EditAssessmentGetUserActivity_activity_data_learningAchievements as LearningAchievement,
} from "../Queries/types/EditAssessmentGetUserActivity";

const StyledNotificationImportantIcon = styled(NotificationImportantIcon)`
  color: ${props => props.theme.palette.primary.dark};
`;

const NoGoalsText = styled(Text)`
  max-width: 400px;
`;

const NoGoals = styled(Flex)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

interface Props extends FormikProps<FormValues> {
  activity: Activity;
  learner: User;
  achievement?: LearningAchievement;
  updateAssessment: (
    numericAssessments: NumericAssessmentInputProp[],
    verbalAssessment: VerbalAssessmentInput,
  ) => Promise<SingleExecutionResult<EditAssessmentUpdateAssessmentForm>>;
}

export enum AchievementContextMenuItems {
  restore,
}

export const Assessments: React.FC<Props> = ({
  activity,
  achievement,
  learner,
  updateAssessment,
  ...props
}) => {
  const { t } = useTranslation();

  const [selfAssessmentModalOpen, setSelfAsssessmentModalOpen] = useState(
    false,
  );

  const onCloseSelfAssessmentModal = () => {
    setSelfAsssessmentModalOpen(false);
  };

  const goals = activity.educationalPackageGoals;

  return (
    <>
      <Flex mt={2} flexDirection="column">
        <Box>
          <Text variant="caption">
            {t("components.organisms.editAssessmentModal.learnerName")}
          </Text>
          <Text variant="body1">{`${learner.givenName} ${learner.familyName}`}</Text>
        </Box>
        <Box mt={2}>
          <Text variant="caption">
            {t("components.organisms.editAssessmentModal.activityName")}
          </Text>
          <Text variant="body1">{activity.name}</Text>
        </Box>

        {goals && goals.length === 0 && (
          <NoGoals px={[2, 3]} py={2} pb={6} flexDirection="column">
            <Box mb={3}>
              <Text variant="h4">
                {t(
                  "components.organisms.editAssessmentModal.teacherAssessment",
                )}
              </Text>
            </Box>
            <Flex alignItems="center">
              <Box>
                <StyledNotificationImportantIcon fontSize="large" />
              </Box>
              <Box ml={3}>
                <NoGoalsText>{t("globals.empty.noGoals")}</NoGoalsText>
              </Box>
            </Flex>
          </NoGoals>
        )}
        {goals && goals.length > 0 && (
          <AssessmentForm
            verbalAssessmentHelperText={
              activity.verbalAssessmentSetting &&
              activity.verbalAssessmentSetting.learnerHelperText
            }
            goals={activity.educationalPackageGoals || []}
            {...props}
            type={AssessmentType.teacher}
          />
        )}
        <Box mt={2} pb={2}>
          <Button
            data-cy="open-selfassessment-button"
            size="small"
            variant="contained"
            onClick={() => setSelfAsssessmentModalOpen(true)}
          >
            {t("components.organisms.editAssessmentModal.openSelfAssessment")}
          </Button>
        </Box>
      </Flex>
      {selfAssessmentModalOpen && (
        <SelfAssessmentModal
          open={selfAssessmentModalOpen}
          activity={activity}
          achievement={achievement}
          learner={learner}
          onModalClosed={onCloseSelfAssessmentModal}
        />
      )}
    </>
  );
};
