import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import { ActivityForm } from "./Form";
import { Queries } from "./Queries";

export interface EditActivityClassroomSettingsModalProps {
  open: boolean;
  onModalClosed: (result: boolean, selfAssessment: boolean) => void;
  activityId: string;
}

export const EditActivityClassroomSettingsModal: React.FunctionComponent<
  EditActivityClassroomSettingsModalProps
> = ({ onModalClosed, activityId, open }) => {
  const { t } = useTranslation();
  const onCancel = () => {
    onModalClosed(false, false);
  };

  return (
    <Queries activityId={activityId}>
      {({
        activity: { loading: loadingActivity, data: activityData },
        update,
      }) => {
        return (
          <Formik
            enableReinitialize
            initialValues={{
              selfAssessment:
                activityData &&
                activityData.activity &&
                activityData.activity.data.selfAssessment,
            }}
            onSubmit={async (values: any, { setSubmitting }) => {
              setSubmitting(true);

              await update.mutation({
                variables: {
                  input: {
                    id: activityId,
                    ...values,
                  },
                },
              });
              setSubmitting(false);
              onModalClosed(true, values.selfAssessment);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              const hasGoals =
                activityData &&
                activityData.activity.data.educationalPackageGoals &&
                activityData.activity.data.educationalPackageGoals.length > 0;
              return (
                <Modal
                  height="550px"
                  error={update.error}
                  loading={isSubmitting || loadingActivity}
                  primaryAction={
                    hasGoals
                      ? {
                          text: t("globals.save"),
                          onClick: handleSubmit,
                          disabled: !isValid,
                        }
                      : {
                          text: t("globals.close"),
                          onClick: onCancel,
                        }
                  }
                  secondaryAction={
                    hasGoals
                      ? {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                      : undefined
                  }
                  title={activityData ? activityData.activity.data.name : ""}
                  open={open}
                >
                  <ActivityForm hasGoals={hasGoals} {...props} />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
