import React from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Modal } from "../../Molecules";

import { Form } from "./Form";
import { Queries } from "./Queries";
import { GoalTemplateFolderType } from "typings/graphql-global-types";

export interface EditGoalTemplateModalProps {
  open: boolean;
  onModalClosed: (result: boolean) => void;
  school: { id: string; name: string };
  folder: { id: string; name: string };
  goalTemplateId?: string;
  type: GoalTemplateFolderType;
}

export const EditGoalTemplateModal: React.FunctionComponent<EditGoalTemplateModalProps> = ({
  onModalClosed,
  open,
  school,
  folder,
  goalTemplateId,
  type,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries goalTemplateId={goalTemplateId}>
      {({ goalTemplate: { loading, data }, create, update }) => {
        return (
          <Formik
            enableReinitialize
            initialValues={{
              text: data?.goalTemplate.data.text || "",
              eGoalIds:
                data &&
                data.goalTemplate.data.eGoals &&
                data.goalTemplate.data.eGoals.length
                  ? data.goalTemplate.data.eGoals.map(epg => epg.id)
                  : [],
            }}
            validationSchema={Yup.object().shape({
              text: Yup.string()
                .required(t("globals.validations.nameRequired"))
                .max(140, t("globals.validations.nameMax")),
            })}
            onSubmit={async (values: any, { setSubmitting }) => {
              setSubmitting(true);
              if (goalTemplateId) {
                await update.mutation({
                  variables: {
                    input: {
                      id: goalTemplateId,
                      ...values,
                    },
                  },
                });
              } else {
                await create.mutation({
                  variables: {
                    input: {
                      folderId: folder.id,
                      ...values,
                    },
                  },
                });
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              const onSubmit = () => {
                handleSubmit();
              };

              return (
                <Modal
                  name="edit-goaltemplate"
                  height="550px"
                  loading={isSubmitting || loading}
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: onSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  title={
                    goalTemplateId
                      ? t(
                          "components.organisms.editGoalTemplateModal.titleEdit",
                        )
                      : t("components.organisms.editGoalTemplateModal.titleNew")
                  }
                  open={open}
                >
                  <Form
                    folderName={folder.name}
                    schoolName={school.name}
                    type={type}
                    {...props}
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
