import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Modal } from "../../Molecules";

import {
  ActivityGoalsForm,
  EducationalPackageGoalInputWithHasAssessments,
} from "./Form";
import { Queries } from "./Queries";

export interface EditActivityGoalsModalProps {
  activityId: string;
  educationalPackageId?: string | null;
  showModal: boolean;
  onModalClosed: (result: boolean) => void;
  otherGoals?: any;
}

export const EditActivityGoalsModal: React.FunctionComponent<EditActivityGoalsModalProps> = ({
  activityId,
  educationalPackageId,
  showModal,
  onModalClosed,
  otherGoals,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };
  return (
    <Queries
      activityId={activityId}
      educationalPackageId={educationalPackageId}
    >
      {({
        activity: {
          loading: loadingActivity,
          data: activityData,
          defaultNumericAssessmentSettingId,
          defaultVerbalAssessmentSetting,
        },
        update: { setGoals },
      }) => {
        const defaultVerbalAssessmentSettingId =
          defaultVerbalAssessmentSetting && defaultVerbalAssessmentSetting.id;

        const defaultVerbalAssessmentLearnerHelperText = defaultVerbalAssessmentSetting
          ? defaultVerbalAssessmentSetting.learnerHelperText
          : t("globals.verbalAssessment.learnerHelperText");

        return (
          <Formik
            enableReinitialize
            initialValues={{
              id: activityId,
              educationalPackageGoals:
                activityData &&
                activityData.educationalPackageGoals &&
                activityData.educationalPackageGoals.length
                  ? activityData.educationalPackageGoals.map(epg => ({
                      id: epg.id,
                      name: epg.name,
                      hasAssessments: epg.hasAssessments,
                      eGoalIds: epg.eGoals.map(e => e.id),
                      numericAssessmentSettingId:
                        epg.numericAssessmentSetting &&
                        epg.numericAssessmentSetting.id,
                    }))
                  : ([
                      {
                        name: "",
                        hasAssessments: false,
                        eGoalIds: [],
                        numericAssessmentSettingId: defaultNumericAssessmentSettingId,
                      },
                    ] as EducationalPackageGoalInputWithHasAssessments[]),
              verbalAssessmentSettingId:
                activityData && activityData.verbalAssessmentSetting
                  ? activityData.verbalAssessmentSetting.id
                  : defaultVerbalAssessmentSettingId,
              learnerHelperText:
                activityData &&
                activityData.verbalAssessmentSetting &&
                activityData.verbalAssessmentSetting.learnerHelperText
                  ? activityData.verbalAssessmentSetting.learnerHelperText
                  : defaultVerbalAssessmentLearnerHelperText,
            }}
            validationSchema={Yup.object().shape({
              educationalPackageGoals: Yup.array()
                .of(
                  Yup.object().shape({
                    name: Yup.string()
                      .required(t("globals.validations.nameRequired"))
                      .max(140, t("globals.validations.nameMax")),
                  }),
                )
                .max(15, t("globals.validations.maxNumberOfActivities")),
              verbalAssessmentSettingEnabled: Yup.boolean(),
              learnerHelperText: Yup.string()
                .max(300, t("globals.validations.textMax300"))
                .when("verbalAssessmentSettingEnabled", {
                  is: true,
                  then: Yup.string().required(
                    t("globals.validations.nameRequired"),
                  ),
                }),
              favouriteName: Yup.string()
                .max(40, t("globals.validations.nameMax"))
                .when("verbalAssessmentSettingEnabled", {
                  is: true,
                  then: Yup.string().required(
                    t("globals.validations.nameRequired"),
                  ),
                }),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              if (values && values.educationalPackageGoals) {
                await setGoals(
                  values.educationalPackageGoals.map(epg => ({
                    id: epg.id,
                    name: epg.name,
                    eGoalIds: epg.eGoalIds,
                    numericAssessmentSettingId: epg.numericAssessmentSettingId,
                  })),
                  values.verbalAssessmentSettingId,
                );
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid, values } = props;
              return (
                <Modal
                  name="edit-goals"
                  width="800px"
                  height="570px"
                  loading={isSubmitting || loadingActivity}
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: handleSubmit,
                    disabled: !isValid || !values?.educationalPackageGoals?.length,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  open={showModal}
                  title={
                    educationalPackageId
                      ? activityData &&
                        activityData.educationalPackageGoals &&
                        activityData.educationalPackageGoals.length
                        ? t(
                          "components.organisms.editActivityGoalsModal.editTitle",
                        )
                        : t(
                          "components.organisms.editActivityGoalsModal.addTitle",
                        )
                      : activityData &&
                        activityData.educationalPackageGoals &&
                        activityData.educationalPackageGoals.length
                        ? t(
                          "components.organisms.editActivityGoalsModal.editTemplateTitle",
                        )
                        : t(
                          "components.organisms.editActivityGoalsModal.addTemplateTitle",
                        )
                  }
                >
                  <ActivityGoalsForm
                    {...props}
                    educationalPackageId={educationalPackageId}
                    otherGoals={otherGoals}
                    defaultNumericAssessmentSettingId={
                      defaultNumericAssessmentSettingId
                    }
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
