import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Formik, FormikProps, FormikHelpers as FormikActions } from "formik";
import * as Yup from "yup";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { CustomModal } from "./components/CustomModal";
import { Flex } from "components/Atoms";
import { useSnackbar } from "components/Contexts/SnackbarContext";

import { Assessments } from "./components/Assessments";
import { UserNavi } from "./components/UserNavi";
import { Queries } from "./components/Queries";
import {
  FormValues,
  getNumericAssessmentsInGoalOrder,
} from "./components/Form";

import { EditAssessmentGetUserActivity_activity_data_learners as User } from "./components/Queries/types/EditAssessmentGetUserActivity";

import { AssessmentType } from "typings/graphql-global-types";

export interface EditAssessmentModalProps {
  activityId: string;
  learnerId: string;
  open: boolean;
  sortFunction: (a: User, b: User) => 1 | -1 | 0;
  onModalClosed: (result: boolean) => void;
}

export const EditAssessmentModal: React.FunctionComponent<EditAssessmentModalProps> = ({
  activityId,
  learnerId,
  open,
  sortFunction,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXl = useMediaQuery(theme.breakpoints.up("xl"));
  const [userId, setUserId] = useState(learnerId);
  const { showSnackbar } = useSnackbar();

  const onUserChange = (learner: User) => {
    setUserId(learner.id);
  };

  const onClose = () => {
    onModalClosed(false);
  };

  return (
    <Queries activityId={activityId} userId={userId}>
      {({
        //achievement: { loading: loadingAchievement, data: achievementData },
        activity: { loading: loadingActivity, data: activityData },
        assessment: { update, error, loading: savingAssessment },
      }) => {
        const achievements = activityData
          ? activityData.learningAchievements
          : [];
        const learners = (activityData && activityData.learners
          ? activityData.learners
          : []
        ).sort(sortFunction);

        const currentIndex = learners.findIndex(a => a.id === userId);
        const currentLearner = learners[currentIndex];

        const nextLearner =
          learners[currentIndex + 1 < learners.length ? currentIndex + 1 : 0];

        const previousLearner =
          learners[
            currentIndex - 1 >= 0 ? currentIndex - 1 : learners.length - 1
          ];

        const type = AssessmentType.teacher;
        const achievement = achievements.find(
          a => a.user && a.user.id === currentLearner.id,
        );
        const verbalAssessment =
          achievement &&
          achievement.verbalAssessments.find(a => a.type === type);

        const numericAssessments =
          achievement &&
          achievement.numericAssessments.filter(a => a.type === type);

        const initialValues = {
          numericAssessments: getNumericAssessmentsInGoalOrder(
            numericAssessments || [],
            (activityData && activityData.educationalPackageGoals) || [],
          ),
          verbalAssessment:
            verbalAssessment && verbalAssessment.assessmentText
              ? verbalAssessment.assessmentText
              : "",
        };

        return (
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              verbalAssessment: Yup.string().max(
                400,
                t("globals.validations.assessmentMax"),
              ),
            })}
            onSubmit={async (
              values: FormValues,
              actions: FormikActions<FormValues>,
            ) => {
              actions.setSubmitting(true);

              const numericAssessments = values.numericAssessments.map(a => ({
                educationalPackageGoalId: a.educationalPackageGoalId,
                value: a.value,
              }));

              const result = await update(numericAssessments, {
                assessmentText: values.verbalAssessment,
              });
              actions.setSubmitting(false);
              actions.setTouched({});

              showSnackbar({
                id: result.data!.createOrPatchLearningAchievement.data!.id,
                type: "success",
                message: t(
                  "components.organisms.editAssessmentModal.saveSuccess",
                ),
                autoHideDuration: 3000,
              });
            }}
            render={(props: FormikProps<FormValues>) => (
              <CustomModal
                name="edit-assessment"
                loading={props.isSubmitting || loadingActivity}
                width={matchesXl ? "1024px" : "80%"}
                height="95%"
                secondaryAction={{
                  text: t("globals.save"),
                  onClick: props.handleSubmit,
                  disabled: props.isSubmitting || !props.isValid,
                }}
                primaryAction={{
                  text: t("globals.close"),
                  onClick: onClose,
                }}
                open={open}
                headingAction={
                  <UserNavi
                    nextUser={nextLearner}
                    previousUser={previousLearner}
                    onChange={onUserChange}
                  />
                }
              >
                <Flex>
                  {activityData && currentLearner && (
                    <Assessments
                      activity={activityData}
                      learner={currentLearner}
                      achievement={achievement}
                      updateAssessment={update}
                      {...props}
                    ></Assessments>
                  )}
                </Flex>
              </CustomModal>
            )}
          />
        );
      }}
    </Queries>
  );
};
