import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const VerbalAssessmentActiveIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 26 27">
    <g>
      <path d="M5.235 11.734h10.951v2.058H5.235v-2.058zm0-5.197h15.146v2.058H5.235V6.537z" />
      <path d="M23.277 0H2.339A2.339 2.339 0 00.004 2.338v16.6a2.34 2.34 0 002.335 2.338h5.515v4.205c0 .568.46 1.027 1.028 1.028h.653c.27-.003.528-.11.72-.3l4.9-4.937h8.127a2.34 2.34 0 002.338-2.338v-16.6A2.34 2.34 0 0023.277 0zm.28 19.22l-9.242.02-4.407 4.407V19.22H2.056V2.058h21.5l.001 17.162z" />
    </g>
  </SvgIcon>
);

VerbalAssessmentActiveIcon.displayName = "VerbalAssessmentActiveIcon";
