import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { GetLearnerProfile } from "./types/GetLearnerProfile";
import {
  UpdateLearnerLanguage,
  UpdateLearnerLanguageVariables,
} from "./types/UpdateLearnerLanguage";

export const GET_LEARNERPROFILE = gql`
  query GetLearnerProfile {
    myprofile {
      data {
        id
        givenName
        familyName
      }
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation UpdateLearnerLanguage($input: UserLanguageInputType!) {
    updateMyLanguage(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  myprofile: {
    loading: boolean;
    data: GetLearnerProfile | undefined;
  };
  updateLanguage: (language: "fi-FI" | "sv-FI") => Promise<void>;
}

interface QueriesProps {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const { loading, data } = useQuery<GetLearnerProfile>(GET_LEARNERPROFILE);

  const [updateLng] = useMutation<
    UpdateLearnerLanguage,
    UpdateLearnerLanguageVariables
  >(UPDATE_LANGUAGE);

  const updateLanguage = async (language: "fi-FI" | "sv-FI") => {
    await updateLng({
      variables: {
        input: {
          language,
        },
      },
    });
  };

  return children({
    myprofile: {
      loading,
      data,
    },
    updateLanguage,
  });
};
