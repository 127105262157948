import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import {
  GetEducationalPackageLearner,
  GetEducationalPackageLearnerVariables,
} from "./types/GetEducationalPackageLearner";

export const GET_EDUCATIONALPACKAGE = gql`
  query GetEducationalPackageLearner($filters: IdInputType!) {
    educationalPackage(filters: $filters) {
      data {
        id
        name
        importedPackage
        colorTheme
        newActivitiesCount: activitiesCount(filters: { submitted: false })
        submittedActivitiesCount: activitiesCount(filters: { submitted: true })
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  educationalPackage: {
    loading: boolean;
    data: GetEducationalPackageLearner | undefined;
    refetch: any;
  };
}

interface EducationPackageQueriesProps {
  children: RenderProp<Queries>;
  educationalPackageId: string;
}

export const EducationPackageQueries: React.FC<
  EducationPackageQueriesProps
> = ({ children, educationalPackageId }) => {
  const { loading, /*error,*/ data, refetch } = useQuery<
    GetEducationalPackageLearner,
    GetEducationalPackageLearnerVariables
  >(GET_EDUCATIONALPACKAGE, {
    variables: {
      filters: {
        id: educationalPackageId,
      },
    },
  });

  return children({
    educationalPackage: { loading, data, refetch },
  });
};
