import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  StyledTable,
  StyledTableBody,
  StickyStyledTableHeaderCell,
  StickyStyledTableHeaderLearnerCell,
} from "../LearnerTable";

import { useTranslation } from "react-i18next";

import {
  Flex,
  Loader,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Tooltip,
} from "components/Atoms";

import { LearnerAvatar } from "components/Atoms/LearnerAvatar";

import { EGoalInfoModal } from "components/Organisms/EGoalInfoModal";

import { AssessmentType } from "typings/graphql-global-types";

import { EGoalTableRow } from "./components/EGoalTableRow";

import { TableQueries, TableQueriesProps } from "./components/Queries";

import {
  EGoalLearningAchievementQueries,
  GET_LEARNINGACHIEVEMENTS_FOR_EGOALS,
} from "./components/EGoalLearningAchievementQueries";

import { EGoalLearningAchievementsModal } from "./components/EGoalLearningAchievementsModal";

import firstNumber from "utils/firstNumber";

import {
  sortFunction,
  OrderFilterEnum,
} from "../AssessmentsTable/components/OrderFilter";

import {
  GetEducationalPackageEGoalsOpsTable_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal,
  GetEducationalPackageEGoalsOpsTable_educationalPackage_data_learners as LearnerWithoutLearningAchievements,
} from "./components/Queries/types/GetEducationalPackageEGoalsOpsTable";

import {
  GetLearningAchievementsForEGoals,
  GetLearningAchievementsForEGoalsVariables,
  GetLearningAchievementsForEGoals_learningAchievements_data as LearningAchievement,
} from "./components/EGoalLearningAchievementQueries/types/GetLearningAchievementsForEGoals";

import { exportEGoalsToCsv } from "./export";
export interface Learner {
  id: string;
  givenName: string;
  familyName: string;
  learningAchievements: LearningAchievement[];
}

const Scrollable = styled.div`
  width: 100%;
  overflow: auto;
  height: 85vh;
`;

const StyledInput = styled(Input)`
  width: 200px;
  &.MuiInputBase-root {
    margin-top: 0;
    padding-top: 0;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-top: 0;
  transform: none;
  font-size: 1.2rem;
  margin-right: 16px;
`;

interface Props {
  educationalPackageId: string;
}

export const EGoalsTable: React.FC<Props> = ({ educationalPackageId }) => (
  <TableQueries educationalPackageId={educationalPackageId}>
    {props => <TableImplementation {...props} />}
  </TableQueries>
);

const TableImplementation: React.FC<TableQueriesProps> = ({
  educationalPackage: { loading: loadingEducationalPackage, data },
}) => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [showEGoalInfoModal, setShowEGoalInfoModal] = useState<boolean>(false);
  const [
    showEGoalLearningAchievementsModal,
    setShowEGoalLearningAchievementsModal,
  ] = useState<boolean>(false);

  const [selectedEGoal, setSelectedEGoal] = useState<EGoal | undefined>();
  const [selectedLearnerForModal, setSelectedLearnerForModal] = useState<
    Learner | undefined
  >();

  const [selectedAssessmentType, setSelectedAssessmentType] = useState<
    AssessmentType
  >(AssessmentType.self);

  const [subjectId, setSubjectId] = useState<string | undefined | null>();

  const [gradeLevelGroupId, setGradeLevelGroupId] = useState<
    string | undefined | null
  >();

  useEffect(() => {
    if (!subjectId) {
      setSubjectId(
        data &&
          data.educationalPackage.data.schoolSubjects &&
          data.educationalPackage.data.schoolSubjects[0] &&
          data.educationalPackage.data.schoolSubjects[0].id,
      );
    }
    if (!gradeLevelGroupId) {
      setGradeLevelGroupId(
        data &&
          data.educationalPackage.data.gradeLevel &&
          data.educationalPackage.data.gradeLevel.gradeLevelGroup &&
          data.educationalPackage.data.gradeLevel.gradeLevelGroup.id,
      );
    }
  }, [data, setSubjectId, setGradeLevelGroupId, subjectId, gradeLevelGroupId]);

  const [selectedLearnerId, setSelectedLearnerId] = useState<
    string | undefined
  >("lastValue");

  const loading = loadingEducationalPackage;
  const learners = (data && data.educationalPackage.data.learners) || [];

  let learnersFilteredSorted: LearnerWithoutLearningAchievements[] = [];

  const found = learners.find(l => l.id === selectedLearnerId);

  if (found) {
    learnersFilteredSorted = [found];
  } else {
    learnersFilteredSorted = [
      ...learners.sort(sortFunction(OrderFilterEnum.firstNameAsc)),
    ];
  }
  const onAssessmentTypeChanged = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setSelectedAssessmentType(e.target.value as AssessmentType);
  };

  const schoolSubject = (
    (data && data.educationalPackage.data.schoolSubjects) ||
    []
  ).find(ss => ss.id === subjectId);

  const gradeLevelGroup =
    schoolSubject &&
    schoolSubject.schoolSubjectGradeLevelGroups.find(
      gr => gr.gradeLevelGroup.id === gradeLevelGroupId,
    );

  let eGoals = ((gradeLevelGroup?.eGoals ? [...gradeLevelGroup.eGoals] : []) as EGoal[]).sort(
    (a, b) => firstNumber(a.eGoal.name) - firstNumber(b.eGoal.name),
  );

  const eGoalIds = eGoals.map(e => e.eGoal.id);
  const educationalPackageId = data && data.educationalPackage.data.id;

  const onShowEGoal = (eGoal: EGoal) => {
    setSelectedEGoal(eGoal);
    setShowEGoalInfoModal(true);
  };

  const onAssessmentClick = (eGoal: EGoal, learner: Learner) => {
    setSelectedEGoal(eGoal);
    setSelectedLearnerForModal(learner);
    setShowEGoalLearningAchievementsModal(true);
  };

  const onExport = async () => {
    const { data: learningAchievementsData } = await apolloClient.query<
      GetLearningAchievementsForEGoals,
      GetLearningAchievementsForEGoalsVariables
    >({
      variables: {
        filters: {
          userIds: learnersFilteredSorted.map(l => l.id),
          eGoalIds,
        },
      },
      query: GET_LEARNINGACHIEVEMENTS_FOR_EGOALS,
    });

    exportEGoalsToCsv(
      learnersFilteredSorted,
      eGoals,
      learningAchievementsData.learningAchievements.data,
      selectedAssessmentType as AssessmentType,
      schoolSubject?.name || "",
    );
  };

  return (
    <>
      <Flex pl={[2.5, 0]} flexDirection="column">
        {/* <Flex
          minWidth={"300px"}
          mt={[2, 0]}
          flex={1}
          justifyContent={["flex-start", "flex-end"]}
        >
          <Box>
            <Link href="https://youtu.be/kTgjRskjzWE" target="_blank">
              {t(
                "containers.teacherEducationPackage.eGoalsTable.howToUseEGoalsTable",
              )}
            </Link>
          </Box>
        </Flex> */}
        <Flex px={1} mb={[1]} flexWrap="wrap">
          <Flex
            flex={[1, "none"]}
            mt={[2]}
            mr={[0, 4]}
            justifyContent={["space-between", "flex-start"]}
            alignItems={["center"]}
            flexWrap="wrap"
          >
            <StyledInputLabel htmlFor="assessmentTypeId">
              {t(
                "containers.teacherEducationPackage.assessmentsTable.filterType",
              )}
            </StyledInputLabel>
            <Select
              value={selectedAssessmentType}
              onChange={onAssessmentTypeChanged}
              input={
                <StyledInput name="assessmentTypeId" id="assessmentTypeId" />
              }
            >
              {Object.keys(AssessmentType).map(k => (
                <MenuItem divider={false} key={k} value={k}>
                  {k === AssessmentType.teacher &&
                    t(
                      "containers.teacherEducationPackage.assessmentsTable.typeTeacher",
                    )}
                  {k === AssessmentType.self &&
                    t(
                      "containers.teacherEducationPackage.assessmentsTable.typeSelf",
                    )}
                </MenuItem>
              ))}
            </Select>
          </Flex>

          <Flex
            flex={[1, "none"]}
            mt={[2]}
            mr={[0, 4]}
            justifyContent={["space-between", "flex-start"]}
            alignItems={["center"]}
            flexWrap="wrap"
          >
            <StyledInputLabel htmlFor="learnerId">
              {t(
                "containers.teacherEducationPackage.eGoalsTable.filterLearner",
              )}
            </StyledInputLabel>
            <Select
              value={selectedLearnerId}
              onChange={e => setSelectedLearnerId(e.target.value as string)}
              input={<StyledInput name="learnerId" id="learnerId" />}
            >
              <MenuItem divider={true} value={"lastValue"}>
                {t("containers.teacherEducationPackage.eGoalsTable.showLast")}
              </MenuItem>

              {learners
                .sort(sortFunction(OrderFilterEnum.firstNameAsc))
                .map(learner => (
                  <MenuItem key={learner.id} value={learner.id}>
                    {`${learner.givenName} ${learner.familyName}`}
                  </MenuItem>
                ))}
            </Select>
          </Flex>

          <Flex
            flex={[1, "none"]}
            mt={[2]}
            mr={[0, 4]}
            justifyContent={["space-between", "flex-start"]}
            alignItems={["center"]}
            flexWrap="wrap"
          >
            <StyledInputLabel htmlFor="gradeLevelGroupId">
              {t(
                "containers.teacherEducationPackage.eGoalsTable.filterGradeLevelGroup",
              )}
            </StyledInputLabel>
            {gradeLevelGroupId && (
              <Select
                value={gradeLevelGroupId}
                onChange={e => setGradeLevelGroupId(e.target.value as string)}
                input={
                  <StyledInput
                    name="gradeLevelGroupId"
                    id="gradeLevelGroupId"
                  />
                }
              >
                {data &&
                  [...data.allGradeLevelGroups.data]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
              </Select>
            )}
          </Flex>

          <Flex
            flex={[1, "none"]}
            mt={[2]}
            mr={[0, 4]}
            justifyContent={["space-between", "flex-start"]}
            alignItems={["center"]}
            flexWrap="wrap"
          >
            <StyledInputLabel htmlFor="subjectId">
              {t(
                "containers.teacherEducationPackage.eGoalsTable.filterSubject",
              )}
            </StyledInputLabel>
            {subjectId && (
              <Select
                value={subjectId}
                onChange={e => setSubjectId(e.target.value as string)}
                input={<StyledInput name="subjectId" id="subjectId" />}
              >
                {data &&
                  (data.educationalPackage.data.schoolSubjects || [])
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
              </Select>
            )}
          </Flex>
        </Flex>

        <Flex justifyContent="flex-end" alignItems="center" mb={1}>
          <Tooltip title={t("globals.csv.exportCsv")}>
            <IconButton disabled={loading} onClick={onExport}>
              <GetAppIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Flex>
        <Scrollable>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StickyStyledTableHeaderCell
                  align="center"
                  style={{ width: "150px" }}
                >
                  <Flex justifyContent="center" height={"100%"}>
                    {t("containers.teacherEducationPackage.eGoalsTable.goal")}
                  </Flex>
                </StickyStyledTableHeaderCell>

                {!loading &&
                  learnersFilteredSorted.map(l => (
                    <StickyStyledTableHeaderLearnerCell
                      key={l.id}
                      align="center"
                    >
                      <LearnerAvatar
                        givenName={l.givenName || ""}
                        familyName={l.familyName || ""}
                      />
                    </StickyStyledTableHeaderLearnerCell>
                  ))}
              </TableRow>
            </TableHead>
            <StyledTableBody>
              {!loading &&
                (eGoals.length === 0 ||
                  learnersFilteredSorted.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      {learnersFilteredSorted.length === 0
                        ? t("globals.empty.noAssessments")
                        : t("globals.empty.noGoals")}
                    </TableCell>
                  </TableRow>
                )}
              {!educationalPackageId && (
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    colSpan={7}
                    style={{ height: 120 }}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {educationalPackageId && (
                <EGoalLearningAchievementQueries
                  userIds={learnersFilteredSorted.map(l => l.id)}
                  eGoalIds={eGoalIds}
                >
                  {({ learningAchievement }) => {
                    const mapLearningAchievements: Learner[] = learnersFilteredSorted
                      .map(l => ({
                        id: l.id,
                        givenName: l.givenName || "",
                        familyName: l.familyName || "",
                        learningAchievements: learningAchievement.data.filter(
                          la => la.user?.id === l.id,
                        ),
                      }))
                      .filter(l => l);

                    if (learningAchievement.loading) {
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            rowSpan={2}
                            colSpan={7}
                            style={{ height: 120 }}
                          >
                            <Loader />
                          </TableCell>
                        </TableRow>
                      );
                    }

                    return (
                      <>
                        {eGoals.map((eGoal: EGoal) => (
                          <EGoalTableRow
                            key={eGoal.eGoal.id}
                            eGoal={eGoal}
                            selectedAssessmentType={
                              selectedAssessmentType as AssessmentType
                            }
                            learners={mapLearningAchievements}
                            onEGoalClick={onShowEGoal}
                            onAssessmentClick={onAssessmentClick}
                          />
                        ))}
                      </>
                    );
                  }}
                </EGoalLearningAchievementQueries>
              )}
            </StyledTableBody>
          </StyledTable>
        </Scrollable>
      </Flex>
      {showEGoalInfoModal && selectedEGoal && (
        <EGoalInfoModal
          eGoalIds={[selectedEGoal.eGoal.id]}
          onModalClosed={() => setShowEGoalInfoModal(false)}
        />
      )}
      {showEGoalLearningAchievementsModal &&
        selectedEGoal &&
        selectedLearnerForModal && (
          <EGoalLearningAchievementsModal
            eGoal={selectedEGoal}
            assessmentType={selectedAssessmentType}
            learner={selectedLearnerForModal}
            open={true}
            onModalClosed={() => setShowEGoalLearningAchievementsModal(false)}
          />
        )}
    </>
  );
};
