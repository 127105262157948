import * as React from "react";

import styled from "styled-components";

import {
  default as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@material-ui/core/Switch";

export type SwitchProps = MuiSwitchProps;

export const Switch: React.FC<SwitchProps> = styled((props: SwitchProps) => (
  <MuiSwitch data-cy={props.name ? props.name : "switch-input"} {...props} />
))``;

Switch.displayName = "Switch";
