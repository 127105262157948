import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Loader,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Tooltip,
} from "components/Atoms";

import { LearnerAvatar } from "components/Atoms/LearnerAvatar";

import { VerbalAssessmentModal } from "components/Organisms";
import { QuestionProps } from "components/Organisms/VerbalAssessmentModal";

import { AssessmentType } from "typings/graphql-global-types";

import { ActivityTableRow } from "./components/ActivityTableRow";
import { SurveyTableRow } from "./components/SurveyTableRow";
import { OwnGoalsTableRow } from "./components/OwnGoalsTableRow";

import { EditAssessmentModal } from "components/Organisms/EditAssessmentModal";

import { EditCommentsModal } from "components/Organisms/EditCommentsModal";

import {
  AssessmentsQueries,
  AssessmentQueriesProps,
} from "./components/Queries";

import {
  OrderFilter,
  sortFunction,
  OrderFilterEnum,
} from "./components/OrderFilter";

import {
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities as Activity,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_learners as Learner,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_learningAchievements_verbalAssessments as VerbalAssessment,
} from "./components/Queries/types/GetEducationalPackageAssessmentsByUser";

import {
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys as Survey,
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswers as SurveyQuestionAnswer,
  GetDigipolkuSurveyGroupAnswers_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion,
} from "./components/Queries/types/GetDigipolkuSurveyGroupAnswers";

import { GetDigipolkuSurveyGroupAnswers2_digipolkuSurveyGroup2_data_surveys as Survey2 } from "./components/Queries/types/GetDigipolkuSurveyGroupAnswers2";

import { getAcademicYear, AcademicYear } from "utils/dateHelpers";

import { exportAssessmentsToCsv, exportSurveyToCsv } from "./export";
const Scrollable = styled.div`
  width: 100%;
  overflow: auto;
  height: 85vh;
`;

const StyledInput = styled(Input)`
  width: 200px;
  &.MuiInputBase-root {
    margin-top: 0;
    padding-top: 0;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-top: 0;
  transform: none;
  font-size: 1.2rem;
  margin-right: 16px;
`;

interface AssessmentsTableProps {
  educationalPackageId: string;
}

export enum ExtendedAssessmentType {
  digipolku = "digipolku",
  digipolku2 = "digipolku2",
  ownGoals = "ownGoals",
}

const StyledTable = styled(({ showPagination, ...rest }) => (
  <Table {...rest} />
))<any>`
  box-shadow: none;
  overflow: auto;
  min-height: 10vh;
  max-height: 85vh;

  &:last-child tr:first-child {
    border-bottom-left-radius: ${props => (props.showPagination ? 0 : 24)}px;
  }

  &:last-child tr:last-child {
    border-bottom-right-radius: ${props => (props.showPagination ? 0 : 24)}px;
  }
`;

const StyledTableBody = styled(TableBody)`
  vertical-align: top;
`;

const StyledTableCell = styled(TableCell)`
  height: 100%;
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StickyStyledTableCell = styled(StyledTableCell)`
  position: sticky;
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 0px;
  }
  z-index: 1;
`;

const StickyStyledTableHeaderCell = styled(StickyStyledTableCell)`
  position: sticky;
  top: 0px;
  z-index: 3;
`;

const StickySecondaryTableHeaderCell = styled(StickyStyledTableHeaderCell)`
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 150px;
  }
`;

const StickyStyledTableHeaderLearnerCell = styled(StickyStyledTableHeaderCell)`
  z-index: 2;
`;

const StyledTablePagination = styled(TablePagination)<any>`
  background: #fff;
  border-radius: 0 0 24px 24px;
`;

export const AssessmentsTable: React.FC<AssessmentsTableProps> = ({
  educationalPackageId,
}) => (
  <AssessmentsQueries educationalPackageId={educationalPackageId}>
    {props => <TableImplementation {...props} />}
  </AssessmentsQueries>
);

let previousAssessmentType: AssessmentType | ExtendedAssessmentType;
const TableImplementation: React.FC<AssessmentQueriesProps> = ({
  educationalPackage: { loading: loadingEducationalPackage, data },
  digipolku: { loading: loadingDigipolku, data: digipolkuData, getDigipolku },
  digipolku2: {
    loading: loadingDigipolku2,
    data: digipolkuData2,
    getDigipolku2,
  },
  ownGoals: { loading: loadingOwnGoals, data: ownGoalsData, getOwnGoals },
}) => {
  const { t } = useTranslation();

  let location = useLocation();
  const searchParamsActivityId: string | null = new URLSearchParams(
    location.search,
  ).get("activityId");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const [selectedActivityIdForModal, setSelectedActivityIdForModal] = useState<
    string | undefined
  >();

  const [
    selectedSurveyQuestionsAnswerIdForModal,
    setSelectedSurveyQuestionsAnswerIdForModal,
  ] = useState<string | undefined>();

  const [selectedAssessmentType, setSelectedAssessmentType] = useState<
    AssessmentType | ExtendedAssessmentType
  >(AssessmentType.self);

  const [selectedActivity, setSelectedActivity] = useState<string>(
    searchParamsActivityId ? searchParamsActivityId : "allActivities",
  );

  const [selectedGradeLevelGroupId, setSelectedGradeLevelGroupId] = useState<
    string
  >("all");

  const [selectedAcademicYear, setSelectedAcademicYear] = useState<string>(
    "all",
  );

  const [selectedGradeNumber, setSelectedGradeNumber] = useState<
    number | undefined
  >(1);

  const onAcademicYearChanged = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setSelectedAcademicYear(e.target.value as string);
  };

  const academicYears =
    ownGoalsData && ownGoalsData.ownGoalsSurveyGroup
      ? ownGoalsData.ownGoalsSurveyGroup.data.surveys
          .map(a => getAcademicYear(a.createdAt))
          .reduce((a: AcademicYear[], b: AcademicYear) => {
            if (a.findIndex(ay => ay.startYear === b.startYear) < 0) {
              a.push(b);
            }
            return a;
          }, [])
      : [];

  const academicYear = academicYears.find(
    a => parseInt(selectedAcademicYear) === a.startYear,
  );

  useEffect(() => {
    if (
      data &&
      data.educationalPackageAssessmentOptimized.data.gradeLevel &&
      data.educationalPackageAssessmentOptimized.data.gradeLevel.gradeLevelGroup
    ) {
      setSelectedGradeLevelGroupId(
        data.educationalPackageAssessmentOptimized.data.gradeLevel
          .gradeLevelGroup.id,
      );
    }
  }, [data]);

  const [verbalAssessmentModalOpen, setVerbalAsssessmentModalOpen] = useState(
    false,
  );

  const [editAssessmentModalOpen, setEditAsssessmentModalOpen] = useState(
    false,
  );

  const [editCommentsModalOpen, setEditCommentsModalOpen] = useState(false);

  const [selectedLearnerId, setSelectedLearnerId] = useState<
    string | undefined
  >();

  const [orderFilterValue, setOrderFilterValue] = useState<
    OrderFilterEnum | string
  >(OrderFilterEnum.firstNameAsc);

  const [
    verbalAssessmentModalAssessment,
    setVerbalAssessmentModalAssessment,
  ] = useState<QuestionProps | undefined>();

  const onClickViewVerbalEvaluation = (
    activity: Activity,
    verbalAssessment: VerbalAssessment,
  ): void => {
    setVerbalAsssessmentModalOpen(true);
    setVerbalAssessmentModalAssessment({
      name: activity.name,
      answer: verbalAssessment.assessmentText,
      type: verbalAssessment.type,
      assessor:
        verbalAssessment.type === AssessmentType.teacher
          ? `${verbalAssessment.user.givenName} ${verbalAssessment.user.familyName}`
          : undefined,
      helperText:
        activity.verbalAssessmentSetting?.learnerHelperText ||
        t("globals.verbalAssessment.learnerHelperText"),
      learner:
        verbalAssessment.type === AssessmentType.self && verbalAssessment.user
          ? `${verbalAssessment.user.givenName} ${verbalAssessment.user.familyName}`
          : undefined,
    });
  };

  const onClickViewSurveyVerbalEvaluation = (
    survey: Survey,
    answer: SurveyQuestionAnswer,
    question?: SurveyQuestion,
  ): void => {
    setVerbalAsssessmentModalOpen(true);

    if (selectedAssessmentType === ExtendedAssessmentType.ownGoals) {
      const academicYear = getAcademicYear(survey.createdAt);
      const name = t("globals.ownGoalsSurveyTitle", {
        startYear: academicYear.startYear,
        endYear: academicYear.endYear,
        name: survey.name && survey.name.length && `: ${survey.name}`,
      });

      setVerbalAssessmentModalAssessment({
        name,
        answer: answer.verbalText || "",
        type: AssessmentType.self,
        modalTitle: question && question.name,
        verbalEvaluationTitle: t(
          "containers.teacherEducationPackage.assessmentsTable.learnersVerbalEvaluationTitle",
        ),
      });
    } else {
      setVerbalAssessmentModalAssessment({
        name: survey.name,
        answer: answer.verbalText || "",
        type: AssessmentType.self,
      });
    }
  };

  const onCloseVerbalAssessmentModal = () => {
    setVerbalAsssessmentModalOpen(false);
    setVerbalAssessmentModalAssessment(undefined);
  };

  const onClickEditAssessment = (activity: Activity, learner: Learner) => {
    setEditAsssessmentModalOpen(true);
    setSelectedActivityIdForModal(activity.id);
    setSelectedLearnerId(learner.id);
  };
  const onCloseEditAssessmentModal = () => {
    setEditAsssessmentModalOpen(false);
    setSelectedActivityIdForModal(undefined);
    setSelectedLearnerId(undefined);
  };

  const onClickEditComment = (surveyQuestionAnswerId: string) => {
    setEditCommentsModalOpen(true);
    setSelectedSurveyQuestionsAnswerIdForModal(surveyQuestionAnswerId);
  };
  const onCloseEditCommentModal = () => {
    setEditCommentsModalOpen(false);
    setSelectedSurveyQuestionsAnswerIdForModal(undefined);
  };

  const loading =
    loadingEducationalPackage || loadingDigipolku || loadingDigipolku2;
  const learners =
    (data && data.educationalPackageAssessmentOptimized.data.learners) || [];

  let learnersFilteredSorted: Learner[] = [];

  const found = learners.find(l => l.id === orderFilterValue);

  if (found) {
    learnersFilteredSorted = [found];
  } else {
    learnersFilteredSorted = [
      ...learners.sort(sortFunction(orderFilterValue as OrderFilterEnum)),
    ];
  }

  let activities =
    (data && data.educationalPackageAssessmentOptimized.data.activities) || [];
  if (selectedActivity === "allActivitiesWithGoal") {
    activities = activities.filter(a => a.educationalPackageGoals?.length);
  }

  let digipolkuSurveys: Survey[] = [];
  let digipolku2Surveys: Survey2[] = [];
  let ownGoalsSurveys: Survey[] = [];
  if (selectedAssessmentType === ExtendedAssessmentType.digipolku) {
    digipolkuSurveys =
      (digipolkuData &&
        digipolkuData.digipolkuSurveyGroup &&
        digipolkuData.digipolkuSurveyGroup.data.surveys) ||
      [];
  }
  if (selectedAssessmentType === ExtendedAssessmentType.digipolku2) {
    digipolku2Surveys =
      (digipolkuData2 &&
        digipolkuData2.digipolkuSurveyGroup2 &&
        digipolkuData2.digipolkuSurveyGroup2.data.surveys) ||
      [];
  }
  if (selectedAssessmentType === ExtendedAssessmentType.ownGoals) {
    ownGoalsSurveys =
      (ownGoalsData &&
        ownGoalsData.ownGoalsSurveyGroup &&
        ownGoalsData.ownGoalsSurveyGroup.data.surveys) ||
      [];
  }
  const onOrderFilterChangedChanged = (
    filter: OrderFilterEnum | string,
    loadOwnGoals?: boolean,
  ) => {
    setOrderFilterValue(filter);
    if (
      (selectedAssessmentType === ExtendedAssessmentType.ownGoals ||
        loadOwnGoals) &&
      typeof filter === "string"
    ) {
      getOwnGoals({
        variables: {
          filters: {
            userId: filter,
          },
          answerFilters: {
            userIds: [filter],
          },
        },
      });
    }
  };

  const onAssessmentTypeChanged = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    previousAssessmentType = selectedAssessmentType;

    if (e.target.value === ExtendedAssessmentType.digipolku) {
      setSelectedActivity("allActivities");
      getDigipolku({
        variables: {
          filters: {
            userIds: learners.map(l => l.id),
          },
        },
      });
    } else if (e.target.value === ExtendedAssessmentType.digipolku2) {
      setSelectedActivity("allActivities");
      getDigipolku2({
        variables: {
          filters: {
            userIds: learners.map(l => l.id),
          },
        },
      });
    } else if (e.target.value === ExtendedAssessmentType.ownGoals) {
      setSelectedActivity("allActivities");
      if (learners[0]) onOrderFilterChangedChanged(learners[0].id, true);
    } else {
      setSelectedGradeLevelGroupId("all");
    }

    if (
      previousAssessmentType === ExtendedAssessmentType.ownGoals &&
      e.target.value !== ExtendedAssessmentType.ownGoals
    ) {
      onOrderFilterChangedChanged(OrderFilterEnum.firstNameAsc);
    }

    setSelectedAssessmentType(
      e.target.value as AssessmentType | ExtendedAssessmentType,
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showPagination =
    selectedAssessmentType !== ExtendedAssessmentType.digipolku &&
    selectedAssessmentType !== ExtendedAssessmentType.digipolku2 &&
    selectedAssessmentType !== ExtendedAssessmentType.ownGoals &&
    (selectedActivity === "allActivities" ||
      selectedActivity === "allActivitiesWithGoal") &&
    activities.length > rowsPerPage;

  const selectedActivities =
    selectedAssessmentType !== ExtendedAssessmentType.digipolku &&
    selectedAssessmentType !== ExtendedAssessmentType.digipolku2 &&
    selectedAssessmentType !== ExtendedAssessmentType.ownGoals &&
    (selectedActivity === "allActivities" ||
      selectedActivity === "allActivitiesWithGoal")
      ? activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : activities.filter(fa => fa.id === selectedActivity);

  const onExport = () => {
    if (selectedAssessmentType === ExtendedAssessmentType.ownGoals) {
      exportSurveyToCsv(
        learnersFilteredSorted,
        ownGoalsSurveys.filter(a => {
          if (!academicYear) return true;

          const d = new Date(a.createdAt).valueOf();
          return (
            d < academicYear.endDate.valueOf() &&
            d > academicYear.startDate.valueOf()
          );
        }),
        ExtendedAssessmentType.ownGoals,
      );
    } else if (selectedAssessmentType === ExtendedAssessmentType.digipolku) {
      exportSurveyToCsv(
        learnersFilteredSorted,
        selectedGradeLevelGroupId === "all"
          ? digipolkuSurveys
          : digipolkuSurveys.filter(
              s =>
                s.gradeLevelGroup &&
                s.gradeLevelGroup.id === selectedGradeLevelGroupId,
            ),
        ExtendedAssessmentType.digipolku,
      );
    } else if (selectedAssessmentType === ExtendedAssessmentType.digipolku2) {
      exportSurveyToCsv(
        learnersFilteredSorted,
        digipolku2Surveys.filter(
          s => s.gradeNumber && s.gradeNumber === selectedGradeNumber,
        ),
        ExtendedAssessmentType.digipolku,
      );
    } else {
      exportAssessmentsToCsv(
        learnersFilteredSorted,
        selectedActivities,
        selectedAssessmentType as AssessmentType,
        data?.educationalPackageAssessmentOptimized.data.name || "",
      );
    }
  };

  const gradeNumberSelectOptions = useMemo(() => {
    const menuItems = [];
    for (let i = 1; i < 10; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}. {t("containers.digipolku.gradeNumberSelect.gradeNumber")}
        </MenuItem>,
      );
    }
    return menuItems;
  }, [t]);

  return (
    <>
      <Flex pl={[2.5, 0]} flexDirection="column">
        <Flex px={1} mb={[1]} flexWrap="wrap">
          <Flex
            flex={[1, "none"]}
            mt={[2]}
            mr={[0, 4]}
            justifyContent={["space-between", "flex-start"]}
            alignItems={["center"]}
            flexWrap="wrap"
          >
            <StyledInputLabel htmlFor="assessmentTypeId">
              {t(
                "containers.teacherEducationPackage.assessmentsTable.filterType",
              )}
            </StyledInputLabel>
            <Select
              value={selectedAssessmentType}
              onChange={onAssessmentTypeChanged}
              input={
                <StyledInput name="assessmentTypeId" id="assessmentTypeId" />
              }
            >
              {Object.keys(AssessmentType).map(k => (
                <MenuItem
                  divider={!!(data && data.digipolkuSurveyGroup)}
                  key={k}
                  value={k}
                >
                  {k === AssessmentType.teacher &&
                    t(
                      "containers.teacherEducationPackage.assessmentsTable.typeTeacher",
                    )}
                  {k === AssessmentType.self &&
                    t(
                      "containers.teacherEducationPackage.assessmentsTable.typeSelf",
                    )}
                </MenuItem>
              ))}

              <MenuItem value={ExtendedAssessmentType.ownGoals}>
                {t("globals.ownGoals")}
              </MenuItem>

              {data && data.digipolkuSurveyGroup2 && (
                <MenuItem value={ExtendedAssessmentType.digipolku2}>
                  {t("globals.digipolku2")}
                </MenuItem>
              )}

              {data && data.digipolkuSurveyGroup && (
                <MenuItem value={ExtendedAssessmentType.digipolku}>
                  {t("globals.digipolku")}
                </MenuItem>
              )}
            </Select>
          </Flex>

          {selectedAssessmentType !== ExtendedAssessmentType.ownGoals &&
            selectedAssessmentType !== ExtendedAssessmentType.digipolku2 && (
              <Flex
                flex={[1, "none"]}
                mt={[2]}
                justifyContent={["space-between", "flex-start"]}
                alignItems={["center"]}
                flexWrap="wrap"
              >
                <StyledInputLabel htmlFor="activityId">
                  {t(
                    "containers.teacherEducationPackage.assessmentsTable.filterActivity",
                  )}
                </StyledInputLabel>

                {selectedAssessmentType !==
                  ExtendedAssessmentType.digipolku && (
                  <Select
                    value={selectedActivity}
                    onChange={e =>
                      setSelectedActivity(e.target.value as string)
                    }
                    input={<StyledInput name="activityId" id="activityId" />}
                  >
                    <MenuItem value={"allActivities"}>
                      {t(
                        "containers.teacherEducationPackage.assessmentsTable.allActivities",
                      )}
                    </MenuItem>
                    <MenuItem value={"allActivitiesWithGoal"}>
                      {t(
                        "containers.teacherEducationPackage.assessmentsTable.allActivitiesWithGoal",
                      )}
                    </MenuItem>
                    {activities.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {selectedAssessmentType === "digipolku" && (
                  <Select
                    value={selectedGradeLevelGroupId}
                    onChange={e =>
                      setSelectedGradeLevelGroupId(e.target.value as string)
                    }
                    input={
                      <StyledInput
                        name="gradeLevelGroupId"
                        id="gradeLevelGroupId"
                      />
                    }
                  >
                    <MenuItem value={"all"}>
                      {t(
                        "containers.teacherEducationPackage.assessmentsTable.allDigipolkuSurveys",
                      )}
                    </MenuItem>
                    {digipolkuData &&
                      [...digipolkuData.allGradeLevelGroups.data]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                  </Select>
                )}
              </Flex>
            )}

          {selectedAssessmentType === ExtendedAssessmentType.ownGoals && (
            <Flex
              flex={[1, "none"]}
              mt={[2]}
              justifyContent={["space-between", "flex-start"]}
              alignItems={["center"]}
              flexWrap="wrap"
            >
              <StyledInputLabel htmlFor="selectedAcademicYearId">
                {t(
                  "containers.teacherEducationPackage.assessmentsTable.academicYearFilter",
                )}
              </StyledInputLabel>

              <Select
                value={selectedAcademicYear}
                onChange={onAcademicYearChanged}
                input={
                  <StyledInput
                    name="selectedAcademicYearId"
                    id="selectedAcademicYearId"
                  />
                }
              >
                <MenuItem value={"all"}>
                  {t(
                    "containers.teacherEducationPackage.assessmentsTable.allAcademicYears",
                  )}
                </MenuItem>

                {academicYears.map(ay => (
                  <MenuItem key={ay.startYear} value={ay.startYear}>
                    {`${ay.startYear}-${ay.endYear}`}
                  </MenuItem>
                ))}
              </Select>
            </Flex>
          )}

          {selectedAssessmentType === ExtendedAssessmentType.digipolku2 && (
            <Flex
              flex={[1, "none"]}
              mt={[2]}
              justifyContent={["space-between", "flex-start"]}
              alignItems={["center"]}
              flexWrap="wrap"
            >
              <StyledInputLabel htmlFor="selectedGradeNumber">
                {t("containers.digipolku.gradeNumberSelect.title")}
              </StyledInputLabel>

              <Select
                onChange={e => setSelectedGradeNumber(e.target.value as number)}
                value={selectedGradeNumber}
                input={
                  <StyledInput
                    name="selectedGradeNumber"
                    id="selectedGradeNumber"
                  />
                }
              >
                {/* <MenuItem value={"all"}>
                  {t(
                    "containers.teacherEducationPackage.assessmentsTable.allAcademicYears",
                  )}
                </MenuItem> */}

                {gradeNumberSelectOptions}
              </Select>
            </Flex>
          )}

          <OrderFilter
            showOnlyLearners={
              selectedAssessmentType === ExtendedAssessmentType.ownGoals
            }
            onChange={(value: OrderFilterEnum | string) =>
              onOrderFilterChangedChanged(value)
            }
            learners={learners}
            value={orderFilterValue}
          />
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center" mb={1}>
          <Tooltip title={t("globals.csv.exportCsv")}>
            <IconButton disabled={loading} onClick={onExport}>
              <GetAppIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Flex>
        <Scrollable>
          <StyledTable showPagination={showPagination}>
            <TableHead>
              <TableRow>
                <StickyStyledTableHeaderCell
                  style={{
                    minWidth: "150px",
                    maxWidth: "150px",
                  }}
                >
                  <Flex alignItems="center" height={"100%"}>
                    {t(
                      "containers.teacherEducationPackage.assessmentsTable.activity",
                    )}
                  </Flex>
                </StickyStyledTableHeaderCell>
                <StickySecondaryTableHeaderCell>
                  <Flex alignItems="center" height={"100%"}>
                    {t(
                      "containers.teacherEducationPackage.assessmentsTable.goal",
                    )}
                  </Flex>
                </StickySecondaryTableHeaderCell>
                {!loading &&
                  learnersFilteredSorted.map(l => (
                    <StickyStyledTableHeaderLearnerCell
                      key={l.id}
                      align="center"
                    >
                      <LearnerAvatar
                        givenName={l.givenName || ""}
                        familyName={l.familyName || ""}
                      />
                    </StickyStyledTableHeaderLearnerCell>
                  ))}
              </TableRow>
            </TableHead>
            <StyledTableBody>
              {!loading &&
                activities.length === 0 &&
                learnersFilteredSorted.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      {t("globals.empty.noAssessments")}
                    </TableCell>
                  </TableRow>
                )}
              {loading && (
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    colSpan={7}
                    style={{ height: 120 }}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                selectedActivities.map(a => (
                  <ActivityTableRow
                    key={a.id}
                    activity={a}
                    selectedAssessmentType={
                      selectedAssessmentType as AssessmentType
                    }
                    learners={learnersFilteredSorted}
                    onClickViewVerbalEvaluation={onClickViewVerbalEvaluation}
                    onClickEditAssessment={onClickEditAssessment}
                  />
                ))}

              {!loading &&
                selectedAssessmentType === ExtendedAssessmentType.ownGoals &&
                ownGoalsSurveys
                  .filter(a => {
                    if (!academicYear) return true;

                    const d = new Date(a.createdAt).valueOf();
                    return (
                      d < academicYear.endDate.valueOf() &&
                      d > academicYear.startDate.valueOf()
                    );
                  })
                  .map(s => (
                    <OwnGoalsTableRow
                      key={s.id}
                      survey={s}
                      users={learnersFilteredSorted}
                      onClickViewVerbalEvaluation={
                        onClickViewSurveyVerbalEvaluation
                      }
                      onClickEditComment={onClickEditComment}
                      gradingScale={3}
                    />
                  ))}

              {!loading &&
                (selectedAssessmentType === ExtendedAssessmentType.digipolku &&
                selectedGradeLevelGroupId === "all"
                  ? digipolkuSurveys
                  : digipolkuSurveys.filter(
                      s =>
                        s.gradeLevelGroup &&
                        s.gradeLevelGroup.id === selectedGradeLevelGroupId,
                    )
                ).map(s => (
                  <SurveyTableRow
                    key={s.id}
                    survey={s}
                    users={learnersFilteredSorted}
                    onClickViewVerbalEvaluation={
                      onClickViewSurveyVerbalEvaluation
                    }
                    onClickEditComment={onClickEditComment}
                    gradingScale={3}
                  />
                ))}

              {!loading &&
                selectedAssessmentType === ExtendedAssessmentType.digipolku2 &&
                digipolku2Surveys
                  .filter(
                    s => s.gradeNumber && s.gradeNumber === selectedGradeNumber,
                  )
                  .map(s => {
                    return (
                      <SurveyTableRow
                        key={s.id}
                        survey={s}
                        users={learnersFilteredSorted}
                        onClickViewVerbalEvaluation={
                          onClickViewSurveyVerbalEvaluation
                        }
                        onClickEditComment={onClickEditComment}
                        gradingScale={(selectedGradeNumber ?? 0) > 2 ? 5 : 3}
                      />
                    );
                  })}
            </StyledTableBody>
          </StyledTable>
        </Scrollable>
        {showPagination && (
          <StyledTablePagination
            component="div"
            labelRowsPerPage=""
            labelDisplayedRows={() =>
              t("globals.pageOf", {
                currentPage: page + 1,
                nbrOfPages: Math.ceil(activities.length / rowsPerPage),
              })
            }
            count={activities.length}
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Flex>

      {verbalAssessmentModalOpen && verbalAssessmentModalAssessment && (
        <VerbalAssessmentModal
          open={verbalAssessmentModalOpen}
          question={verbalAssessmentModalAssessment}
          onModalClosed={onCloseVerbalAssessmentModal}
        />
      )}

      {editAssessmentModalOpen &&
        selectedActivityIdForModal &&
        selectedLearnerId && (
          <EditAssessmentModal
            activityId={selectedActivityIdForModal}
            learnerId={selectedLearnerId}
            open={editAssessmentModalOpen}
            sortFunction={sortFunction(orderFilterValue as OrderFilterEnum)}
            onModalClosed={onCloseEditAssessmentModal}
          />
        )}

      {editCommentsModalOpen && selectedSurveyQuestionsAnswerIdForModal && (
        <EditCommentsModal
          surveyQuestionAnswerId={selectedSurveyQuestionsAnswerIdForModal}
          open={editCommentsModalOpen}
          onModalClosed={onCloseEditCommentModal}
        />
      )}
    </>
  );
};
