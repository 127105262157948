import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import {
  Flex,
  Box,
  Text,
  InputLabel,
  Input,
  Select,
  MenuItem,
  ColorThemeInput,
} from "../../../Atoms";
import { MultiChipSelect } from "../../../Molecules";

import { Suggestion } from "../../../Molecules/MultiChipSelect";
import useBeforeUnload from "components/Hooks/use-before-unload";

import { GetAllGradeLevelsAndSubjects_allGradeLevels_data as GradeLevel } from "../Queries/types/GetAllGradeLevelsAndSubjects";
import { EducationalPackageCreateInputType } from "typings/graphql-global-types";

import firstNumber from "utils/firstNumber";

const Form = styled.form`
  width: 100%;
`;

const DarkText = styled(Text)`
  color: #000;
`;

const StyledInputLabel = styled(InputLabel)`
  position: relative;
  margin-top: 0;
`;

interface Props extends FormikProps<EducationalPackageCreateInputType> {
  subjects: Suggestion[];
  gradeLevels: GradeLevel[];
}

export const EducationPackageForm: React.FC<Props> = ({
  values: {
    name,
    visibleToGuardians,
    joinByCode,
    gradeLevelId,
    schoolSubjects,
    colorTheme,
  },
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  gradeLevels,
  subjects,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const subjectsChanged = (subjects: Suggestion[]) => {
    setFieldValue(
      "schoolSubjects",
      subjects
        .filter((s, i, arr) => arr.map(o => o.id).indexOf(s.id) === i)
        .map(value => ({ id: value.id, name: value.name })),
    );

    setFieldTouched("schoolSubjects", true, false);
  };

  const colorThemeChange = (value: string) => {
    setFieldValue("colorTheme", value === "none" ? null : value);
    setFieldTouched("colorTheme", true, false);
  };

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <InputLabel htmlFor="name">
            {t("components.organisms.educationPackageForm.name")}
          </InputLabel>
          <Input
            id="name"
            name="name"
            helperText={touched.name ? errors.name : ""}
            error={touched.name && Boolean(errors.name)}
            value={name}
            onChange={change.bind(null, "name")}
            fullWidth
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="gradeLevelId">
            {t("components.organisms.educationPackageForm.gradeLevel")}
          </InputLabel>
          <Select
            value={gradeLevelId}
            onChange={change.bind(null, "gradeLevelId")}
            input={<Input name="gradeLevelId" id="gradeLevelId" />}
          >
            {[...gradeLevels]
              .sort((a, b) => firstNumber(a.name) - firstNumber(b.name))
              .map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="subjects">
            {t("components.organisms.educationPackageForm.subjects")}
          </InputLabel>
          <MultiChipSelect
            id="subjects"
            name="subjects"
            suggestions={subjects}
            selected={
              schoolSubjects &&
              schoolSubjects.map(s => ({
                ...s,
                name: s.name || "",
              }))
            }
            onChange={subjectsChanged}
          />
          <Box py={0.5}>
            <DarkText>
              {t(
                "components.organisms.educationPackageForm.schoolSubjectsSelected",
                { count: schoolSubjects ? schoolSubjects.length : 0 },
              )}
            </DarkText>
          </Box>
        </FormControl>

        {/* <FormControl>
          <Box mt={3}>
            <InputLabel>
              {t(
                "components.organisms.educationPackageForm.visibleToGuardians",
              )}
            </InputLabel>
          </Box>
          <Checkbox
            disabled
            id="visibletoguardians"
            label={t(
              "components.organisms.educationPackageForm.visibleToGuardiansCheckbox",
            )}
            checked={visibleToGuardians}
          ></Checkbox>
        </FormControl>
        <FormControl>
          <Box mt={3}>
            <InputLabel>
              {t("components.organisms.educationPackageForm.joinByCode")}
            </InputLabel>
          </Box>
          <Checkbox
            disabled
            id="joinbycode"
            label={t(
              "components.organisms.educationPackageForm.joinByCodeCheckbox",
            )}
            checked={joinByCode}
          ></Checkbox>
        </FormControl> */}
        <FormControl>
          <Box mt={2}>
            <StyledInputLabel>
              {t("components.organisms.educationPackageForm.themeColor")}
            </StyledInputLabel>
          </Box>
          <ColorThemeInput
            id="colorTheme"
            value={colorTheme}
            onChange={colorThemeChange}
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
