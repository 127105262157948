import React, { useState } from "react";
import { pathOr } from "ramda";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Chip } from "components/Atoms";
import { Modal } from "../../Molecules";

import { ActivityForm } from "./Form";
import { Queries } from "./Queries";
import { Status } from "typings/graphql-global-types";
export interface EditActivityModalProps {
  educationalPackageId?: string | null;
  open: boolean;
  onModalClosed: (result: boolean) => void;
  activityId?: string | null;
  otherGoals?: any;
  activityTemplateFolderId?: string | null;
  hideLocalInputs?: boolean;
}

const StyledChip = styled(Chip)`
  background: rgba(0, 0, 0, 0.08);
  color: #8a8a8a;
  text-transform: uppercase;
  font-weight: 700;
`;

const StatusChip: React.FunctionComponent<{ status: Status }> = ({
  status,
}) => {
  const { t } = useTranslation();
  if (status === Status.active)
    return (
      <StyledChip label={t("components.organisms.editActivityModal.active")} />
    );

  if (status === Status.completed)
    return (
      <StyledChip
        label={t("components.organisms.editActivityModal.completed")}
      />
    );

  if (status === Status.draft)
    return (
      <StyledChip label={t("components.organisms.editActivityModal.draft")} />
    );

  return null;
};

export const EditActivityModal: React.FunctionComponent<EditActivityModalProps> = ({
  educationalPackageId,
  onModalClosed,
  activityId,
  open,
  activityTemplateFolderId,
  hideLocalInputs,
}) => {
  const { t } = useTranslation();
  const [activityState, setActivityState] = useState<Status | undefined>();
  const onCancel = () => {
    onModalClosed(false);
  };
  return (
    <Queries
      activityId={activityId}
      educationalPackageId={educationalPackageId}
    >
      {({
        activity: { loading: loadingActivity, data: activityData },
        educationalPackage: {
          loading: loadingEducationalPackage,
          learners: educationalPackageLearners,
        },
        create,
        update,
      }) => {
        const origStatus =
          activityData &&
          activityData.activity &&
          activityData.activity.data.status;
        return (
          <Formik
            enableReinitialize
            initialValues={{
              educationalPackageId,
              activityTemplateFolderId,
              learnerIds:
                activityData &&
                activityData.activity &&
                activityData.activity.data.learners
                  ? activityData.activity.data.learners.map(l => l.id)
                  : educationalPackageLearners
                  ? educationalPackageLearners.map(l => l.id)
                  : [],
              selfAssessment: !activityTemplateFolderId && hideLocalInputs && pathOr(
                true,
                "activity.data.selfAssessment".split("."),
                activityData,
              ),
              name: pathOr("", "activity.data.name".split("."), activityData),
              description: pathOr(
                "",
                "activity.data.description".split("."),
                activityData,
              ),
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(t("globals.validations.nameRequired"))
                .max(140, t("globals.validations.nameMax")),
              description: Yup.string().max(
                800,
                t("globals.validations.descriptionMax"),
              ),
            })}
            onSubmit={async (values: any, { setSubmitting }) => {
              setSubmitting(true);
              if (activityId) {
                await update.mutation({
                  variables: {
                    input: {
                      id: activityId,
                      status: activityState
                        ? activityState
                        : activityData!.activity.data.status,
                      ...values,
                      educationalPackageId: undefined,
                      activityTemplateFolderId: undefined,
                    },
                  },
                });
              } else {
                await create.mutation({
                  variables: {
                    input: {
                      ...values,
                      status: activityState,
                    },
                  },
                });
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid, touched, values } = props;

              const onDraftAndSubmit = () => {
                setActivityState(Status.draft);
                handleSubmit();
              };

              const onActiveAndSubmit = () => {
                setActivityState(Status.active);
                handleSubmit();
              };

              return (
                <Modal
                  name="edit-activity"
                  height="550px"
                  error={create.error || update.error}
                  loading={
                    isSubmitting || loadingActivity || loadingEducationalPackage
                  }
                  headingAction={
                    <StatusChip status={origStatus || Status.draft} />
                  }
                  primaryAction={{
                    text:
                      activityTemplateFolderId || (activityId && origStatus !== Status.draft)
                        ? t("globals.save")
                        : t(
                            "components.organisms.editActivityModal.activateAndSubmit",
                          ),
                    onClick: onActiveAndSubmit,
                    disabled:
                      !values.name
                      || (activityId && origStatus === Status.draft && touched
                        ? false
                        : !isValid),
                  }}
                  secondaryAction={
                    activityTemplateFolderId || (activityId && origStatus !== Status.draft)
                      ? {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                      : {
                          text: t(
                            "components.organisms.editActivityModal.draftAndSubmit",
                          ),
                          onClick: onDraftAndSubmit,
                          disabled: !isValid,
                        }
                  }
                  tertiaryAction={
                    activityTemplateFolderId || (activityId && origStatus !== Status.draft)
                      ? undefined
                      : {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                  }
                  title={
                    educationalPackageId
                      ? activityId
                        ? t("components.organisms.editActivityModal.titleEdit")
                        : t("components.organisms.editActivityModal.titleNew")
                      : activityId
                        ? t("components.organisms.editActivityModal.templateTitleEdit")
                        : t("components.organisms.editActivityModal.templateTitleNew")
                  }
                  open={open}
                >
                  <ActivityForm
                    educationalPackageLearners={educationalPackageLearners}
                    activityTemplateFolderId={activityTemplateFolderId}
                    hideLocalInputs={hideLocalInputs}
                    {...props}
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
