import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Flex, Box, Text, OwnGoalsIcon } from "components/Atoms";

const HideMobileFlex = styled(Flex)`
  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const DarkText = styled(Text)`
  //color: #ececec;
  width: 100%;
  font-weight: 600;
`;

const TruncatedDarkText = styled(DarkText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledOwnGoalsIcon = styled(OwnGoalsIcon)`
  margin-top: 3px;
  fill: ${props => props.theme.palette.primary.dark};
  color: ${props => props.theme.palette.primary.dark};
`;

const Background = styled(Flex)`
  background: url("/owngoals_banner.jpg");
  background-repeat: no-repeat;
  background-position: 86% 30%;
  background-size: cover;
  overflow: auto;
`;

export const Hero: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Background
      minHeight={["170px", "170px", "200px", "250px"]}
      justifyContent="center"
    >
      <Flex
        px={[2, 3, 6]}
        pl={[2, 3, 3]}
        py={[2, 6]}
        flex={1} /*bgcolor="#00000096"*/
      >
        <HideMobileFlex
          height={["40px", "50px"]}
          alignItems="center"
          borderRadius={24}
          px={2}
        >
          <Box mr={1}>
            <StyledOwnGoalsIcon />
          </Box>
          <TruncatedDarkText variant="h3" color="textPrimary">
            {t("globals.ownGoals")}
          </TruncatedDarkText>
        </HideMobileFlex>
      </Flex>
    </Background>
  );
};
