import { ApolloQueryResult, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  GetDriveFiles_listDriveFiles_data_files as DriveFile,
  GetDriveFiles,
  GetDriveFilesVariables,
} from "./types/GetDriveFiles";

export const GET_DRIVEFILES = gql`
  query GetDriveFiles($filters: ListDriveFilesFilter) {
    listDriveFiles(filters: $filters) {
      data {
        files {
          id
          name
          mimeType
        }
        nextPageToken
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  driveFiles: {
    loading: boolean;
    data: DriveFile[];
    refetch: (
      variables?: GetDriveFilesVariables | undefined,
    ) => Promise<ApolloQueryResult<GetDriveFiles>>;
  };
}

interface QueriesProps {
  activityId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const { loading, data, refetch } = useQuery<
    GetDriveFiles,
    GetDriveFilesVariables
  >(GET_DRIVEFILES);

  return children({
    driveFiles: {
      refetch,
      loading,
      data: data ? data.listDriveFiles.data.files : [],
    },
  });
};
