import React from "react";

export const FairIcon = ({
  checked,
  view,
}: {
  view?: boolean;
  checked?: boolean;
}) => {
  return (
    <svg
      id="Component_31_1"
      data-name="Component 31 – 1"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      {!view && (
        <g
          id="Ellipse_260"
          data-name="Ellipse 260"
          fill="#fff"
          stroke="#fe2210"
          strokeWidth="2"
        >
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="20.5" fill="none" />
        </g>
      )}
      {(view || checked) && (
        <g
          id="Group_1427"
          data-name="Group 1427"
          transform="translate(-415 -1474)"
          opacity="0.998"
        >
          <circle
            id="Ellipse_100"
            data-name="Ellipse 100"
            cx="11.613"
            cy="11.613"
            r="11.613"
            transform="translate(420.19 1495.339) rotate(-45)"
            fill="#fe2210"
          />
          <g
            id="Group_886"
            data-name="Group 886"
            transform="translate(420.19 1495.339) rotate(-45)"
          >
            <line
              id="Line_112"
              data-name="Line 112"
              y1="18.947"
              x2="18.947"
              transform="translate(-3.356 -4.089)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_113"
              data-name="Line 113"
              y1="18.947"
              x2="18.947"
              transform="translate(-0.51 -0.958)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_114"
              data-name="Line 114"
              y1="18.947"
              x2="18.947"
              transform="translate(2.335 2.173)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_115"
              data-name="Line 115"
              y1="18.947"
              x2="18.947"
              transform="translate(5.181 5.303)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              id="Line_116"
              data-name="Line 116"
              y1="18.947"
              x2="18.947"
              transform="translate(7.517 8.943)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
          <circle
            id="Ellipse_102"
            data-name="Ellipse 102"
            cx="11.613"
            cy="11.613"
            r="11.613"
            transform="translate(420.19 1495.339) rotate(-45)"
            fill="none"
            stroke="#fe2210"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      )}
    </svg>
  );
};
