import React from "react";

import { path } from "ramda";
import { useParams } from "components/Hooks/react-router-hooks";

import { Flex, Box } from "components/Atoms";

import {
  EducationPackageQueries,
  Hero,
  Tabs,
  NewActivityList,
  GET_EDUCATIONALPACKAGE,
  TabValue,
} from "./components";

import { useAnalytics } from "components/Hooks/firebase-hooks";

import { NavigationComponent } from "typings";
import { useHistory } from "react-router";
import { getRoute, EDUCATIONALPACKAGE } from "routes";
import { SubmittedActivityList } from "./components/SubmittedActivityList";

interface EducationalPackageProps {}

const LearnerEducationalPackage: NavigationComponent<EducationalPackageProps> = () => {
  const history = useHistory();
  const { educationalPackageId, tab }: any = useParams();

  const { analytics } = useAnalytics();

  const activeTab = tab ? tab : TabValue.newActivities;

  analytics.logEvent("page_view" as string, {
    page_title: "Learner_" + activeTab,
    page_path: history.location.pathname,
  });

  const handleChange = (tab: TabValue) => {
    history.push(
      `${getRoute(EDUCATIONALPACKAGE, {
        educationalPackageId,
        tab,
      })}`,
    );
  };

  return (
    <EducationPackageQueries educationalPackageId={educationalPackageId}>
      {({ educationalPackage: { /*loading*/ data, refetch } }) => {
        return (
          <Flex flexDirection="column">
            <Hero data={data}></Hero>
            <Flex pl={[2.5, 6]} pr={[0, 6]} py={[2, 6]} flexDirection="column">
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Tabs data={data} value={activeTab} onChange={handleChange} />
              </Flex>

              <Flex pt={6} flexDirection="column">
                {activeTab === TabValue.newActivities && (
                  <Box ml={[-2.5, 0]}>
                    <NewActivityList
                      educationalPackageId={educationalPackageId}
                      updateTabs={refetch}
                    />
                  </Box>
                )}
                {activeTab === TabValue.submittedActivities && (
                  <SubmittedActivityList
                    educationalPackageId={educationalPackageId}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </EducationPackageQueries>
  );
};

LearnerEducationalPackage.navigationOptions = async ({
  match,
  apolloClient,
}) => {
  const result = await apolloClient.query({
    variables: {
      filters: {
        id: match.params.educationalPackageId,
      },
    },
    query: GET_EDUCATIONALPACKAGE,
  });

  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "educationalPackage",
        label: path("data.educationalPackage.data.name".split("."), result),
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default LearnerEducationalPackage;
