import React, { useState } from "react";

import { useAnalytics } from "components/Hooks/firebase-hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { NavigationComponent } from "typings";
import { useHistory } from "react-router";

import {
  Flex,
  Box,
  Text,
  AddNewButton,
  InputLabel,
  Input,
  Loader,
  Select,
  MenuItem,
} from "components/Atoms";

import { CardGroup } from "components/Contexts/CardGroup";

import { Queries, Hero, SurveyCardCard } from "./components";

import { EditOwnGoalsModal } from "components/Organisms/EditOwnGoalsModal";
import { GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys as Survey } from "./components/Queries/types/GetOwnGoalsSurveyGroup";

import { getAcademicYear, AcademicYear } from "utils/dateHelpers";

interface OwnGoalsProps {}

const StyledAddNewButton = styled(AddNewButton)`
  height: 100%;
`;

const StyledInput = styled(Input)`
  width: 200px;
  &.MuiInputBase-root {
    margin-top: 0;
    padding-top: 0;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-top: 0;
  transform: none;
  font-size: 1.2rem;
  margin-right: 16px;
`;

const OwnGoals: NavigationComponent<OwnGoalsProps> = () => {
  const { analytics } = useAnalytics();
  const history = useHistory();
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
  const [selectedAcademicYear, setSelectedAcademicYear] = useState<string>(
    "all",
  );

  const [ref, setRef] = useState();

  analytics.logEvent("page_view" as string, {
    page_title: "OwnGoals",
    page_path: history.location.pathname,
  });

  const onEditSurvey = (survey: Survey) => {
    setSelectedSurveyId(survey.id);
    setShowEditModal(true);
  };

  const onAcademicYearChanged = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setSelectedAcademicYear(e.target.value as string);
  };

  return (
    <Flex
      height="85vh"
      overflow="auto"
      flexDirection="column"
      ref={(newRef: any) => setRef(newRef)}
    >
      <Hero />
      <Flex px={[2, 4, 4, 9]} pt={[2, 4]} justifyContent="flex-end">
        <StyledAddNewButton
          name="add-own-goal"
          label={t("containers.ownGoals.addGoals")}
          onClick={() => setShowEditModal(true)}
        />
      </Flex>
      <Flex py={[4, 6]} flexDirection="column">
        {ref && (
          <Queries>
            {({ ownGoalsSurveyGroup: { loading, data, refetch } }) => {
              const surveys =
                (data &&
                  data.ownGoalsSurveyGroup &&
                  data.ownGoalsSurveyGroup.data.surveys) ||
                [];

              const onEditModalClosed = async (result: boolean) => {
                setShowEditModal(false);
                setSelectedSurveyId(undefined);
              };

              const academicYears = surveys
                .map(a => getAcademicYear(a.createdAt))
                .reduce((a: AcademicYear[], b: AcademicYear) => {
                  if (a.findIndex(ay => ay.startYear === b.startYear) < 0) {
                    a.push(b);
                  }
                  return a;
                }, []);

              const academicYear = academicYears.find(
                a => parseInt(selectedAcademicYear) === a.startYear,
              );
              return (
                <>
                  <Box px={[0, 2, 6]}>
                    {loading && <Loader />}
                    {!loading && surveys.length === 0 && (
                      <Box>
                        <Text>{t("globals.empty.noGoals")}</Text>
                      </Box>
                    )}
                    {!loading && surveys.length > 0 && (
                      <Flex px={1} mb={[4]} flexWrap="wrap">
                        <Flex
                          flex={[1, "none"]}
                          mr={[0, 4]}
                          justifyContent={["space-between", "flex-start"]}
                          alignItems={["center"]}
                          flexWrap="wrap"
                        >
                          <StyledInputLabel htmlFor="academicYearFilterId">
                            {t("containers.ownGoals.filterAcademicYear")}
                          </StyledInputLabel>
                          <Select
                            value={selectedAcademicYear}
                            onChange={onAcademicYearChanged}
                            input={
                              <StyledInput
                                name="academicYearFilterId"
                                id="academicYearFilterId"
                              />
                            }
                          >
                            {surveys && (
                              <MenuItem value={"all"}>
                                {t("containers.ownGoals.allAcademicYears")}
                              </MenuItem>
                            )}
                            {academicYears.map(ay => (
                              <MenuItem key={ay.startYear} value={ay.startYear}>
                                {`${ay.startYear}-${ay.endYear}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </Flex>
                      </Flex>
                    )}

                    {!loading && data && (
                      <CardGroup
                        defaultOpen={surveys.length > 0 ? surveys[0].id : ""}
                      >
                        {surveys
                          .filter(a => {
                            if (!academicYear) return true;

                            const d = new Date(a.createdAt).valueOf();
                            return (
                              d < academicYear.endDate.valueOf() &&
                              d > academicYear.startDate.valueOf()
                            );
                          })
                          .map(survey => (
                            <Box key={survey.id} my={2}>
                              <SurveyCardCard
                                scrollContainer={ref}
                                survey={survey}
                                onEdit={onEditSurvey}
                              />
                            </Box>
                          ))}
                      </CardGroup>
                    )}
                  </Box>
                  {showEditModal && (
                    <EditOwnGoalsModal
                      surveyId={selectedSurveyId}
                      showModal={showEditModal}
                      onModalClosed={onEditModalClosed}
                    ></EditOwnGoalsModal>
                  )}
                </>
              );
            }}
          </Queries>
        )}
      </Flex>
    </Flex>
  );
};

OwnGoals.navigationOptions = async ({ t }) => {
  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "ownGoals",
        label: t("globals.ownGoals"),
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default OwnGoals;
