import React, { useState } from "react";
import {
  FormikProps,
  FieldArray,
  FormikErrors,
  FormikTouched,
  getIn,
} from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Badge from "@material-ui/core/Badge";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import {
  Flex,
  Box,
  InputLabel,
  Input,
  GoalIcon,
  AddNewButton,
  Tooltip,
  Button,
} from "components/Atoms";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import PostAddIcon from "@material-ui/icons/PostAdd";

import useBeforeUnload from "components/Hooks/use-before-unload";

import {
  SelectOPSEducationalPackageGoalModal,
  EditNumericAssessmentSettingModal,
  EditVerbalAssessmentSettingModal,
} from "components/Organisms";

import { GoalTemplatePickerModal } from "components/Organisms/GoalTemplatePickerModal";

import {
  EducationalPackageGoalInput,
  GoalTemplateFolderType,
} from "typings/graphql-global-types";

const Form = styled.form`
  width: 100%;
`;
const StyledButton = styled(Button)`
  padding-left: 0;
`;
export interface EducationalPackageGoalInputWithHasAssessments
  extends EducationalPackageGoalInput {
  hasAssessments: boolean;
  eGoalIds: string[];
}

interface ActivitySetGoalsInputType {
  id: string;
  educationalPackageGoals: EducationalPackageGoalInputWithHasAssessments[];
  verbalAssessmentSettingId: string | null | undefined;
  learnerHelperText: string;
}

interface Props extends FormikProps<ActivitySetGoalsInputType> {
  educationalPackageId?: string | null;
  otherGoals?: any;
  defaultNumericAssessmentSettingId?: string | null;
}

const InputLabelWithoutInput = styled(InputLabel)`
  position: initial;
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 53px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-left: -53px;
`;

const LinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #00933d;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #00933d;
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background: #4d9cd7;
  }
`;

const NotLinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #d04100;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #d04100;
  }
`;

interface GoalItemProps {
  name: string;
  index: number;
  errors: FormikErrors<ActivitySetGoalsInputType>;
  touched: FormikTouched<ActivitySetGoalsInputType>;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  hasAssessments: boolean;
  onRemoveClick: (index: number) => void;
  value: string | null;
  onLinkOPSClick: () => void;
  onEditNumericAssessmentSettingClick: (index: number) => void;
  onSelectGoalFromTemplateClick: (index: number) => void;
  eGoalIds: string[];
}

const GoalItem: React.FC<GoalItemProps> = ({
  name,
  index,
  value,
  touched,
  errors,
  onChange,
  onRemoveClick,
  hasAssessments,
  onLinkOPSClick,
  onEditNumericAssessmentSettingClick,
  onSelectGoalFromTemplateClick,
  eGoalIds,
}) => {
  const { t } = useTranslation();

  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const editAssessmentSettingButton = () => (
    <IconButton
      disabled={hasAssessments}
      type="button"
      onClick={() => onEditNumericAssessmentSettingClick(index)}
      aria-label="edit assessment setting"
      data-cy={"edit-numericassessmentsetting-button"}
    >
      <SettingsIcon
        color={hasAssessments ? "disabled" : "primary"}
      ></SettingsIcon>
    </IconButton>
  );

  const removeButton = () => (
    <IconButton
      disabled={hasAssessments}
      type="button"
      onClick={() => onRemoveClick(index)}
      aria-label="remove goal"
    >
      <CloseIcon color={hasAssessments ? "disabled" : "primary"}></CloseIcon>
    </IconButton>
  );

  const linked = eGoalIds.length > 0;
  return (
    <Flex flexDirection="column">
      <Flex flex={1} alignItems="center">
        <Box pl={[0, 2]} pr={[1, 2]}>
          <GoalIcon index={index + 1} tooltip={value || ""} />
        </Box>

        <StyledInput
          id={name}
          name={name}
          placeholder={t(
            "components.organisms.activityGoalsForm.goalNamePlaceholder",
          )}
          value={value}
          onChange={onChange}
          fullWidth
          endAdornment={
            <StyledInputAdornment position="end">
              <Tooltip
                arrow
                title={
                  linked
                    ? t("components.organisms.activityGoalsForm.linked")
                    : t("components.organisms.activityGoalsForm.notLinked")
                }
              >
                {linked ? (
                  <LinkedButton
                    data-cy="linked-ops-button"
                    onClick={onLinkOPSClick}
                  >
                    <StyledBadge
                      overlap="rectangle"
                      badgeContent={eGoalIds.length}
                    >
                      <LinkIcon fontSize="large" />
                    </StyledBadge>
                  </LinkedButton>
                ) : (
                  <NotLinkedButton
                    onClick={onLinkOPSClick}
                    data-cy="not-linked-ops-button"
                  >
                    <LinkOffIcon fontSize="large" />
                  </NotLinkedButton>
                )}
              </Tooltip>
            </StyledInputAdornment>
          }
        />
        <Box mx={[0.5, 2]}>
          <Tooltip title={t("globals.pickFromTemplateTooltip")}>
            <Box>
              <IconButton
                type="button"
                onClick={() => onSelectGoalFromTemplateClick(index)}
                aria-label="pick from template"
                data-cy={"pick-goaltemplate-button"}
              >
                <PostAddIcon color="primary" />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>

        <Box mx={[0.5, 2]}>
          {hasAssessments && (
            <Tooltip
              title={t(
                "components.organisms.activityGoalsForm.assessmentSettingTooltipDisabled",
              )}
            >
              <Box>{editAssessmentSettingButton()}</Box>
            </Tooltip>
          )}
          {!hasAssessments && (
            <Tooltip
              title={t(
                "components.organisms.activityGoalsForm.editAssessmentSettingTooltip",
              )}
            >
              <Box>{editAssessmentSettingButton()}</Box>
            </Tooltip>
          )}
        </Box>
        <Box mx={[0.5, 2]}>
          {hasAssessments && (
            <Tooltip
              title={t("components.organisms.activityGoalsForm.hasAssessments")}
            >
              <Box>{removeButton()}</Box>
            </Tooltip>
          )}
          {!hasAssessments && (
            <Tooltip
              title={t("components.organisms.activityGoalsForm.removeTooltip")}
            >
              <Box>{removeButton()}</Box>
            </Tooltip>
          )}
        </Box>
      </Flex>
      {touch && error && <FormHelperText error={true}>{error}</FormHelperText>}
    </Flex>
  );
};

export const ActivityGoalsForm: React.FC<Props> = ({
  values: {
    educationalPackageGoals,
    verbalAssessmentSettingId,
    learnerHelperText,
  },
  educationalPackageId,
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  otherGoals,
  defaultNumericAssessmentSettingId,
}) => {
  const { t } = useTranslation();
  const [showSelectOPSGoalModal, setShowSelectOPSGoalModal] = useState(false);
  const [
    showEditNumericAssessmentSetting,
    setShowEditNumericAssessmentSetting,
  ] = useState(false);

  const [
    showEditVerbalAssessmentSetting,
    setShowEditVerbalAssessmentSetting,
  ] = useState(false);

  const [
    showGoalTemplatePickerModal,
    setShowGoalTemplatePickerModal,
  ] = useState(false);

  const [selectedEGoalIds, setSelectedEGoalIds] = useState<string[]>([]);
  const [
    selectedNumericAssessmentSettingId,
    setSelectedNumericAssessmentSettingId,
  ] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const onSelectOPSGoalModalClosed = () => {
    setShowSelectOPSGoalModal(false);
  };

  const onEditNumericAssessmentSettingModalClosed = (
    result: boolean,
    numericAssessmentSettingId: string | null,
  ) => {
    if (result) {
      setFieldValue(
        `educationalPackageGoals.${selectedIndex}.numericAssessmentSettingId`,
        numericAssessmentSettingId,
      );
    }
    setShowEditNumericAssessmentSetting(false);
  };

  const onEditVerbalAssessmentSettingModalClosed = (
    result: boolean,
    verbalAssessmentSettingId: string | undefined | null,
    learnerHelperText: string | undefined | null,
  ) => {
    if (result) {
      setFieldValue(`verbalAssessmentSettingId`, verbalAssessmentSettingId);
      setFieldValue(
        `learnerHelperText`,
        learnerHelperText || t("globals.verbalAssessment.learnerHelperText"),
      );
    }
    setShowEditVerbalAssessmentSetting(false);
  };

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onGoalTemplatePickerModalClosed = (result: boolean, text?: string, eGoalsIds?: string[]) => {
    if (result) {
      setFieldValue(`educationalPackageGoals.${selectedIndex}.name`, text);
      if (eGoalsIds && eGoalsIds.length > 0) {
        setFieldValue(
          `educationalPackageGoals.${selectedIndex}.eGoalIds`,
          eGoalsIds,
        );
        setSelectedEGoalIds(eGoalsIds);
      }
    }
    setShowGoalTemplatePickerModal(false);
  };

  const onSelectGoalFromTemplateClick = (index: number) => {
    setShowGoalTemplatePickerModal(true);
    setSelectedIndex(index);
  };

  return (
    <>
      <Form>
        <Flex flex={"auto"} flexDirection="column">
          <InputLabelWithoutInput>
            {t("components.organisms.activityGoalsForm.addGoals")}
          </InputLabelWithoutInput>
          <FieldArray
            name="educationalPackageGoals"
            render={({ insert, remove, push }) => (
              <Flex flexDirection="column">
                {educationalPackageGoals.length > 0 &&
                  educationalPackageGoals.map((goal, index) => (
                    <GoalItem
                      errors={errors}
                      touched={touched}
                      key={index}
                      index={index}
                      name={`educationalPackageGoals.${index}.name`}
                      value={goal.name ? goal.name : ""}
                      onChange={change.bind(
                        null,
                        `educationalPackageGoals.${index}.name`,
                      )}
                      hasAssessments={goal.hasAssessments}
                      onRemoveClick={() => remove(index)}
                      onLinkOPSClick={() => {
                        setShowSelectOPSGoalModal(true);
                        setSelectedEGoalIds(goal.eGoalIds);
                        setSelectedIndex(index);
                      }}
                      onEditNumericAssessmentSettingClick={() => {
                        setShowEditNumericAssessmentSetting(true);
                        setSelectedNumericAssessmentSettingId(
                          goal.numericAssessmentSettingId
                            ? goal.numericAssessmentSettingId
                            : null,
                        );
                        setSelectedIndex(index);
                      }}
                      onSelectGoalFromTemplateClick={
                        onSelectGoalFromTemplateClick
                      }
                      eGoalIds={goal.eGoalIds}
                    />
                  ))}

                <Box ml={[5, 8]} my={2}>
                  <AddNewButton
                    name="add-goal-button"
                    disabled={educationalPackageGoals.length > 14}
                    label={t(
                      "components.organisms.activityGoalsForm.addGoalButton",
                    )}
                    onClick={() =>
                      push({
                        name: "",
                        numericAssessmentSettingId: defaultNumericAssessmentSettingId,
                        eGoalIds: [],
                      })
                    }
                  />
                </Box>
              </Flex>
            )}
          />

          <Box ml={[2]} my={1}>
            <FormControl fullWidth>
              <InputLabel htmlFor="learnerHelperText">
                {t(
                  "components.organisms.activityGoalsForm.verbalAssessmentSettingLearnerText",
                )}
              </InputLabel>
              <Input
                disabled
                id="learnerHelperText"
                name="learnerHelperText"
                multiline
                rows={3}
                value={learnerHelperText}
                fullWidth
              />
            </FormControl>
            <StyledButton
              data-cy="edit-verbal-assessment-setting"
              size="small"
              onClick={setShowEditVerbalAssessmentSetting.bind(null, true)}
            >
              {t("components.organisms.activityGoalsForm.editVerbalAssessment")}
            </StyledButton>
          </Box>
        </Flex>
      </Form>
      {showSelectOPSGoalModal && (
        <SelectOPSEducationalPackageGoalModal
          educationalPackageId={educationalPackageId}
          open={showSelectOPSGoalModal}
          onModalClosed={onSelectOPSGoalModalClosed}
          eGoalIds={selectedEGoalIds}
          eGoals={educationalPackageGoals}
          otherGoals={otherGoals}
          onSetEGoalIds={eGoalsIds => {
            setFieldValue(
              `educationalPackageGoals.${selectedIndex}.eGoalIds`,
              eGoalsIds,
            );
            setSelectedEGoalIds(eGoalsIds);
          }}
        ></SelectOPSEducationalPackageGoalModal>
      )}

      {showEditNumericAssessmentSetting && (
        <EditNumericAssessmentSettingModal
          open={showEditNumericAssessmentSetting}
          onModalClosed={onEditNumericAssessmentSettingModalClosed}
          assessmentSettingId={selectedNumericAssessmentSettingId}
          educationalPackageId={educationalPackageId}
        ></EditNumericAssessmentSettingModal>
      )}

      {showEditVerbalAssessmentSetting && (
        <EditVerbalAssessmentSettingModal
          open={showEditVerbalAssessmentSetting}
          onModalClosed={onEditVerbalAssessmentSettingModalClosed}
          assessmentSettingId={verbalAssessmentSettingId}
          educationalPackageId={educationalPackageId}
        ></EditVerbalAssessmentSettingModal>
      )}

      {showGoalTemplatePickerModal && (
        <GoalTemplatePickerModal
          open={showGoalTemplatePickerModal}
          onModalClosed={onGoalTemplatePickerModalClosed}
          type={GoalTemplateFolderType.activity}
        ></GoalTemplatePickerModal>
      )}
    </>
  );
};
