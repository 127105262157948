import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Color from "color";

import StarIcon from "@material-ui/icons/Star";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import { AchievementIcon, EducationalPackageIcon } from "components/Atoms";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import ClassroomImage from "images/classroom.png";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import { Flex, Box, Text, Tooltip } from "../../Atoms";
import { DropdownMenu } from "../../Molecules";
import { DropdownOptionItem } from "../../Molecules/DropdownMenu";

export interface HeroProps {
  title: string;
  iconType?:
    | "educationalPackage"
    | "activity"
    | "achievements"
    | "add"
    | "digipolku"
    | null;
  onContextMenuChanged?: (o: DropdownOptionItem) => void;
  children?: React.ReactNode;
  hideContextMenu?: boolean;
  colorTheme?: string | null;
  classroomImported?: boolean;
  loading?: boolean;
  bgColor?: string;
}

type ThemeColorProp = {
  themeColor?: string | null;
  bgColor?: string | null;
};

type FillProp = {
  fill?: string | null;
};

const DarkText = styled(Text)`
  color: #000;
  width: 100%;
  font-weight: 600;
`;

const TruncatedDarkText = styled(DarkText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAddIcon = styled(AddIcon)<FillProp>`
  margin-top: -3px;
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
`;

const StyledEducationalPackageIcon = styled(EducationalPackageIcon)<FillProp>`
  margin-top: -3px;
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
  ${props => `color: ${props.fill || props.theme.palette.primary.main}`};
`;

const StyledStarIcon = styled(StarIcon)<FillProp>`
  margin-top: -3px;
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
  ${props => `color: ${props.fill || props.theme.palette.primary.main}`};
`;

const StyledAchievementIcon = styled(AchievementIcon)<FillProp>`
  margin-top: -3px;
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
  ${props => `color: ${props.fill || props.theme.palette.primary.main}`};
`;

const StyledMapOutlinedIcon = styled(MapOutlinedIcon)<FillProp>`
  margin-top: -3px;
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
  ${props => `color: ${props.fill || props.theme.palette.primary.main}`};
`;

const StyledFullscreenIcon = styled(FullscreenIcon)<FillProp>`
  margin-top: -3px;
  vertical-align: middle;
  ${props => `${props.onClick ? 'cursor: pointer;' : ''}`}
  ${props => `fill: ${props.fill || props.theme.palette.primary.main}`};
  ${props => `color: ${props.fill || props.theme.palette.primary.main}`};
`;

const Background = styled(Flex)<ThemeColorProp>`
  background-color: ${props =>
    props.bgColor
      ? props.bgColor
      : props.themeColor
      ? Color(props.themeColor)
          .lightness(Color(props.themeColor).lightness() + 40)
          .hex()
      : props.theme.palette.grey[100]};
  min-height: 102px;
`;

const Bar = styled(Flex)<ThemeColorProp>`
  background-color: ${props =>
    props.themeColor || props.theme.palette.primary.main};
  width: 15px;
`;

const ResponsiveHeroItem = styled(Flex)`
  flex-direction: column;
  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: row;
    margin-bottom: ${props => props.theme.spacing(1)}px;
    :last-child {
      margin-bottom: 0;
    }
    ${Text as any}:first-child {
      padding-right: ${props => props.theme.spacing(2)}px;
      flex: 1;
    }
    ${Text as any}:last-child {
      text-align: end;
      flex: 2;
    }
  }
`;

const Image = styled.img`
  width: 2em;
  height: 2em;
`;

export interface HeroItemProps {
  name?: string;
  title: string;
  text: string | number;
  onIconClick?: () => void;
}

export enum HeroContextMenuItems {
  edit,
  editMembers,
}

export const HeroItem: React.FunctionComponent<HeroItemProps> = ({
  name,
  title,
  text,
  onIconClick,
}) => (
  <ResponsiveHeroItem width={["100%", "100%", "auto"]} pr={1}>
    <DarkText>{title}</DarkText>
    <Text data-cy={name}>{text}{name === "joincode" && text && <StyledFullscreenIcon onClick={onIconClick} />}</Text>
  </ResponsiveHeroItem>
);

export const Hero: React.FunctionComponent<HeroProps> = ({
  title,
  children,
  hideContextMenu,
  colorTheme,
  onContextMenuChanged,
  iconType,
  classroomImported,
  loading,
  bgColor,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      id: HeroContextMenuItems.edit,
      label: t("components.organisms.hero.editPackage"),
    },
    ...(!classroomImported
      ? [
          {
            id: HeroContextMenuItems.editMembers,
            label: t("components.organisms.hero.editMembers"),
          },
        ]
      : []),
  ];

  let icon;
  if (iconType === "educationalPackage")
    icon = <StyledEducationalPackageIcon fill={colorTheme || undefined} />;
  if (iconType === "activity")
    icon = <StyledStarIcon fill={colorTheme || undefined} />;
  if (iconType === "achievements")
    icon = <StyledAchievementIcon fill={colorTheme || undefined} />;
  if (iconType === "add")
    icon = <StyledAddIcon fill={colorTheme || undefined} />;
  if (iconType === "digipolku")
    icon = <StyledMapOutlinedIcon fill={colorTheme || undefined} />;

  return (
    <Background
      themeColor={loading ? "#8a8a8a" : colorTheme}
      data-cy="hero"
      bgColor={bgColor}
    >
      <Bar themeColor={loading ? "#efefef" : colorTheme}></Bar>

      <Flex px={[2, 4, 6]} pl={[3, 5]} py={[2, 6]} flex={1}>
        <Flex flex={1} alignItems="center">
          <Flex flex={1} flexDirection="column">
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flex={1}>
                {!loading && icon && icon}
                <Flex flex={1} justifyContent="space-between">
                  <Box width={["200px", "400px", "600px"]} ml={icon ? 1.5 : 0}>
                    <TruncatedDarkText variant="h3">{title}</TruncatedDarkText>
                  </Box>
                  {classroomImported && (
                    <Tooltip title={t("globals.classroomConnected")} arrow>
                      <Image src={ClassroomImage} alt="Classroom" />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
              {!hideContextMenu && (
                <Box>
                  <DropdownMenu
                    name="hero"
                    onSelect={onContextMenuChanged || (() => null)}
                    options={options}
                  />
                </Box>
              )}
            </Flex>
            <Box flex={1}>{children}</Box>
          </Flex>
        </Flex>
      </Flex>
    </Background>
  );
};
