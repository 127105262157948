import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { format } from "date-fns";
import AnimateHeight from "react-animate-height";

import FlipCameraAndroid from "@material-ui/icons/FlipCameraAndroid";

import {
  GoButton,
  Flex,
  Box,
  Text,
  Chip,
  Button,
  Switch,
  Tooltip,
} from "components/Atoms";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
} from "components/Molecules/Card";

import { DropdownMenu } from "components/Molecules";
import { DropdownOptionItem } from "components/Molecules/DropdownMenu";

import { CardItemContext } from "components/Contexts/CardGroup";

import { ActivityCardContent } from "../ActivityCardContent";

import { GetActivitiesTeacher_allActivities_data as Activity } from "../ActivityListQueries/types/GetActivitiesTeacher";
import { Status } from "typings/graphql-global-types";
import ClassroomImage from "images/classroom.png";

export interface ActivityCardProps {
  activity: Activity;
  classroomImported?: boolean;
  openUrl?: string;
  onContextMenuChanged?: (o: DropdownOptionItem) => void;
  hideContextMenu?: boolean;
  animateOpening: boolean;
  height?: string;
  primaryAction?: {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  onSelfAssessmentChanged?: (state: boolean) => void;
}

const Image = styled.img`
  width: 1em;
  height: 1em;
`;

const ResponsiveCard = styled(Card)`
  width: 100%;
  position: relative;
  ${props => props.theme.breakpoints.down("xs")} {
    border-radius: 0;
  }
`;

type ExpandedProp = {
  expanded?: boolean;
  height?: string;
};

const Collapsible = styled.div<ExpandedProp>`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;

  ${(props: any) => props.theme.breakpoints.down("sm")} {
    padding: 0;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;
`;

const ChipsWrapper = styled(Flex)`
  ${(props: any) => props.theme.breakpoints.down("sm")} {
    background-color: #f8f7f9;
  }
`;

const StyledChip = styled(Chip)`
  background: rgba(0, 0, 0, 0.08);
  color: #8a8a8a;
  text-transform: uppercase;
`;

const ClassroomSwitch = styled(Switch)`
  &.MuiSwitch-root {
    padding: 9px 11px;
  }
  .MuiSwitch-thumb {
    background-color: #fff;
  }
  .MuiSwitch-track {
    border-radius: 12px;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #6ea854;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #6ea854;
    opacity: 1;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: #6ea85417;
  }
`;

export enum ActivityContextMenuItems {
  edit,
  editGoals,
  moveUp,
  moveDown,
  deleteActivity,
  importFromTemplate,
  exportToTemplate,
}

const Wrapper: React.FunctionComponent<{
  children: any;
  animate: boolean;
  expanded: boolean;
}> = ({ animate, expanded, children }) =>
  animate ? (
    <AnimateHeight height={expanded ? "auto" : 0}>{children}</AnimateHeight>
  ) : expanded ? (
    <>{children}</>
  ) : null;

export const ActivityCard: React.FunctionComponent<ActivityCardProps> = ({
  activity,
  classroomImported,
  children,
  openUrl,
  hideContextMenu,
  onContextMenuChanged,
  primaryAction,
  onSelfAssessmentChanged,
  animateOpening,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobileLayout = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const id = activity.id;
  const title = activity.name;

  const selfAssessment = activity.selfAssessment;
  const status = activity.status;
  const totalLearningAchievements = activity.learners
    ? activity.learners.length
    : 0;
  const returnedLearningAchievements =
    activity.submittedLearningAchievementsCount;

  const assessors = [t("containers.teacherEducationPackage.teacher")];
  if (activity.selfAssessment) {
    assessors.push(t("containers.teacherEducationPackage.selfAssessment"));
  }

  const options = hideContextMenu
    ? [
      {
        id: ActivityContextMenuItems.exportToTemplate,
        label: t("containers.teacherEducationPackage.activityCard.exportToTemplate"),
      },
      {
        id: ActivityContextMenuItems.deleteActivity,
        label: t("containers.teacherEducationPackage.activityCard.delete"),
      },
    ]
    : [
        {
          id: ActivityContextMenuItems.edit,
          label: t("containers.teacherEducationPackage.activityCard.edit"),
        },
        {
          id: ActivityContextMenuItems.exportToTemplate,
          label: t("containers.teacherEducationPackage.activityCard.exportToTemplate"),
        },
        {
          id: ActivityContextMenuItems.deleteActivity,
          label: t("containers.teacherEducationPackage.activityCard.delete"),
        },
      ];

  const showReturnedArea =
    typeof returnedLearningAchievements !== "undefined" &&
    typeof totalLearningAchievements !== "undefined";

  const chips = (
    <ChipsWrapper p={[2.5, 2.5, 0]} flexDirection={["column", "column", "row"]}>
      {showReturnedArea && (
        <Flex>
          <Text variant="caption">
            {t(
              "containers.teacherEducationPackage.activityCard.returned",
            ).toUpperCase()}
            <Tooltip
              title={t(
                "containers.teacherEducationPackage.activityCard.returnedTooltip",
              )}
              arrow
            >
              <Box display="inline" ml={1}>
                <Chip
                  color="secondary"
                  label={`${returnedLearningAchievements}/${totalLearningAchievements}`}
                />
              </Box>
            </Tooltip>
          </Text>
        </Flex>
      )}
      {assessors && assessors.length && (
        <Flex mt={[2.5, 2.5, 0]} ml={[0, 0, showReturnedArea ? 2 : 0]}>
          <Text variant="caption">
            {t(
              "containers.teacherEducationPackage.activityCard.assessors",
            ).toUpperCase()}
            {assessors.map(a => (
              <Tooltip
                key={a}
                title={
                  a === t("containers.teacherEducationPackage.teacher")
                    ? t(
                        "containers.teacherEducationPackage.activityCard.teacherAssessmentTooltip",
                      )
                    : t(
                        "containers.teacherEducationPackage.activityCard.learnerAssessmentTooltip",
                      )
                }
                arrow
              >
                <Box display="inline" ml={1}>
                  <Chip color="secondary" label={a.toUpperCase()} />
                </Box>
              </Tooltip>
            ))}
          </Text>
        </Flex>
      )}
    </ChipsWrapper>
  );

  return (
    <CardItemContext.Consumer>
      {({ expandedItem, toggleExpanded }) => (
        <ResponsiveCard {...props} data-cy="activity-card">
          <StyledCardHeader
            onClick={e => toggleExpanded(id === expandedItem ? "" : id)}
            title={
              (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <Box>{title}</Box>
                  </Flex>
                  <Flex alignItems="center">
                    {status === Status.draft && activity.scheduledTime && (
                      <Tooltip
                        title={t(
                          "containers.teacherEducationPackage.activityCard.scheduledTooltip",
                          {
                            scheduledTime: format(
                              new Date(activity.scheduledTime),
                              "dd.MM.yyyy",
                            ),
                          },
                        )}
                        arrow
                      >
                        <Box>
                          <StyledChip
                            label={t(
                              "containers.teacherEducationPackage.activityCard.scheduled",
                            )}
                          />
                        </Box>
                      </Tooltip>
                    )}
                    {status === Status.draft && !activity.scheduledTime && (
                      <Tooltip
                        title={t(
                          "containers.teacherEducationPackage.activityCard.draftTooltip",
                        )}
                        arrow
                      >
                        <Box>
                          <StyledChip
                            label={t(
                              "containers.teacherEducationPackage.activityCard.draft",
                            )}
                          />
                        </Box>
                      </Tooltip>
                    )}
                    {status === Status.completed && (
                      <Box>
                        <FlipCameraAndroid color="disabled" />
                      </Box>
                    )}
                    {activity.classroomWorkId && (
                      <Flex ml={2}>
                        <Tooltip title={t("globals.classroomConnected")} arrow>
                          <Image src={ClassroomImage} alt="Classroom" />
                        </Tooltip>
                      </Flex>
                    )}
                    {classroomImported && onSelfAssessmentChanged && (
                      <Flex ml={2}>
                        <Tooltip
                          title={
                            selfAssessment
                              ? t(
                                  "containers.teacherEducationPackage.activityCard.selfAssessmentLinkedToClassroom",
                                )
                              : t(
                                  "containers.teacherEducationPackage.activityCard.selfAssessmentNotLinkedToClassroom",
                                )
                          }
                          arrow
                        >
                          <Box>
                            <ClassroomSwitch
                              checked={selfAssessment}
                              onClick={e => e.stopPropagation()}
                              onChange={e =>
                                onSelfAssessmentChanged(e.target.checked)
                              }
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              ) as any
            }
            titleTypographyProps={{ variant: "h3" }}
            action={
              // !hideContextMenu &&
              <Box>
                <DropdownMenu
                  name="activity"
                  onSelect={
                    onContextMenuChanged ? onContextMenuChanged : () => null
                  }
                  options={options}
                />
              </Box>
            }
          />
          <Collapsible expanded={id === expandedItem}>
            <Wrapper animate={animateOpening} expanded={id === expandedItem}>
              <StyledCardContent>
                <Box>
                  <ActivityCardContent
                    activity={activity}
                    onEditGoalsClick={
                      onContextMenuChanged
                        ? () =>
                            onContextMenuChanged({
                              id: ActivityContextMenuItems.editGoals,
                              label: "",
                            })
                        : null
                    }
                    onImportFromTemplateClick={
                      onContextMenuChanged
                        ? () =>
                            onContextMenuChanged({
                              id: ActivityContextMenuItems.importFromTemplate,
                              label: "",
                            })
                        : null
                    }
                  />
                </Box>
                {isMobileLayout &&
                  (showReturnedArea || (assessors && assessors.length)) &&
                  chips}
              </StyledCardContent>

              <Box>{children}</Box>
              <CardActions>
                <Flex
                  width="100%"
                  justifyContent={isMobileLayout ? "flex-end" : "space-between"}
                  alignItems="center"
                >
                  {!isMobileLayout && chips}
                  {openUrl && (
                    <GoButton
                      label={t(
                        "containers.teacherEducationPackage.openActivity",
                      )}
                      path={openUrl}
                      direction="forward"
                    />
                  )}
                  {primaryAction && (
                    <Button
                      data-cy="primary-action-button"
                      disabled={primaryAction.disabled}
                      onClick={primaryAction.onClick}
                    >
                      {primaryAction.text}
                    </Button>
                  )}
                </Flex>
              </CardActions>
            </Wrapper>
          </Collapsible>
        </ResponsiveCard>
      )}
    </CardItemContext.Consumer>
  );
};

interface Props {
  defaultOpen: string;
}
