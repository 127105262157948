import React, { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Flex, Box, Text } from "../../Atoms";

import { UserSelectionCheckboxGroup } from "../../Molecules/UserSelectionCheckboxGroup";

import { Modal, Content } from "../../Molecules/Modal";
import { Material, Materials } from "../../Molecules/Materials";
import { Queries } from "./Queries";

const StyledModal = styled(Modal)`
  ${Content as any} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export interface ShareMaterialModalProps {
  activityId: string;
  open: boolean;
  materials: Material[];
  onModalClosed: (result: boolean) => void;
  onShare: (learnerIds: string[]) => void;
  loading: boolean;
}

export const ShareMaterialModal: React.FunctionComponent<
  ShareMaterialModalProps
> = ({
  activityId,
  onModalClosed,
  open,
  loading: addLoading,
  materials,
  onShare,
}) => {
  const { t } = useTranslation();
  const [checkedUserIds, setCheckedUserIds] = useState<string[]>([]);

  const onCancel = () => {
    onModalClosed(false);
  };

  const onPrimaryAction = () => {
    onShare(checkedUserIds);
  };

  const onLearnerSelectionChanged = (userIds: string[]) => {
    setCheckedUserIds(userIds);
  };

  return (
    <Queries activityId={activityId}>
      {({ activityLearners: { loading, learners } }) => {
        const links = materials.find(m => m.type === "link");
        return (
          <StyledModal
            height="600px"
            error={undefined}
            loading={loading || addLoading}
            primaryAction={{
              text: t("globals.add"),
              onClick: onPrimaryAction,
              disabled: checkedUserIds.length === 0 || loading || addLoading,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={t("components.organisms.shareMaterialModal.title")}
            open={open}
          >
            <Flex flexDirection="column" py={2} flex={1}>
              <Box px={[2, 3]}>
                <Text variant="h4">
                  {links
                    ? t("components.organisms.shareMaterialModal.addedLinks")
                    : t("components.organisms.shareMaterialModal.addedFiles")}
                </Text>
                <Flex pt={1.5} flexWrap="wrap">
                  <Materials disabled={true} materials={materials} />
                </Flex>
              </Box>

              <Box>
                <Box px={[2, 3]}>
                  <Text variant="h4">
                    {t(
                      "components.organisms.shareMaterialModal.shareToLearners",
                    )}
                  </Text>
                </Box>
                {learners.length === 0 ? (
                  <Box px={[2, 3]}>
                    <Text>{t("globals.empty.noLearners")}</Text>
                  </Box>
                ) : (
                  <UserSelectionCheckboxGroup
                    onSelectionChanged={onLearnerSelectionChanged}
                    users={learners.map(l => ({
                      id: l.id,
                      label: `${l.givenName} ${l.familyName}`,
                    }))}
                  />
                )}
              </Box>
            </Flex>
          </StyledModal>
        );
      }}
    </Queries>
  );
};
