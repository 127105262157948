import React from "react";

export const FileUploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      viewBox="0 0 16 20"
    >
      <path
        fill="#0072C6"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M10 0l6 6v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h8zm4 18V7H9V2H2v16h12zm-6-8l4 4H9.5v3h-3v-3H4l4-4z"
        transform="translate(-482 -971) translate(482 971)"
      ></path>
    </svg>
  );
};
