import { useEffect, useState } from "react";
import isIos from "utils/isIos";

const useAudio = () => {
  const [iosInit, setIosInit] = useState(false);
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [playing, setPlaying] = useState(false);

  const setUrl = (url: string) => {
    audio.src = url;
  };
  const toggle = async (url?: string) => {
    if (!iosInit && !playing && url && isIos()) {
      try {
        audio.src = url;
        audio.play();
        audio.pause();
        setIosInit(true);
        return;
      } catch (err) {
        console.log(err);
      }
    }
    setPlaying(playing ? false : true);
  };

  useEffect(() => {
    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [audio, playing]);

  useEffect(() => {
    audio && audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio && audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return { setUrl, playing, toggle };
};

export default useAudio;
