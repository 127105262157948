import React, { useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Text,
  Flex,
  Box,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from "components/Atoms";
import { Modal } from "components/Molecules";
import { EGoalCard } from "../EGoalCard";
import { GetEducationalPackageEGoals } from "../Queries/types/GetEducationalPackageEGoals";
import firstNumber from "utils/firstNumber";
import { GetAllGradeLevelGroupsAndSubjects } from "../Queries/types/GetAllGradeLevelGroupsAndSubjects";
import { GetSubjectGradeLevelGroups } from "../Queries/types/GetSubjectGradeLevelGroups";
import { GetSchoolSubjectsByEGoalIds } from "../Queries/types/GetSchoolSubjectsByEGoalIds";

const StyledInput = styled(Input)`
  &.MuiInputBase-root {
    margin-top: 0;
    padding-top: 0;
  }
  width: 100%;
`;

interface Props {
  onClose: () => void;
  data: GetEducationalPackageEGoals | undefined;
  loading: boolean;
  open: boolean;
  eGoalIds: string[];
  onSetEGoalIds?: (eGoalId: string[]) => void;
  eGoals?: {
    hasAssessments: boolean;
    eGoalIds: string[];
  }[];
  otherGoals?: any;
  dataGrade: GetAllGradeLevelGroupsAndSubjects | undefined;
  dataSubject: GetSubjectGradeLevelGroups | undefined;
  getSubjectGradeLevels: (subjectId: string) => void;
  dataSubjectsByEGoalIds: GetSchoolSubjectsByEGoalIds | undefined;
  fetchByEGoalIds: (eGoalIds: string[]) => void;
}

export const EGoalModal: React.FC<Props> = ({
  onClose,
  data,
  loading,
  open,
  eGoalIds,
  onSetEGoalIds,
  eGoals,
  otherGoals,
  dataGrade,
  dataSubject,
  getSubjectGradeLevels,
  dataSubjectsByEGoalIds,
  fetchByEGoalIds,
}) => {
  const [subjectId, setSubjectId] = useState<string | null | undefined>(undefined);
  const [gradeLevelGroupId, setGradeLevelGroupId] = useState<string | null | undefined>(undefined);
  const [init, setInit] = useState(true);

  /**
   * Set initial data
   * used in:
   * classroom course with selected school subjects
   */
  useEffect(() => {
    if (!loading && (data || dataGrade) && init) {
      let newSubjectId = data
        ? data.educationalPackage.data.schoolSubjects
        && data.educationalPackage.data.schoolSubjects[0]
        && data.educationalPackage.data.schoolSubjects[0].id
        : dataGrade
          ? dataGrade.allSchoolSubjects.data[0].id
          : undefined;
      let newGradeLevelGroupId = data
        ? data.educationalPackage.data.gradeLevel?.gradeLevelGroup?.id
        : dataGrade
          ? dataGrade.allGradeLevelGroups.data[0].id
          : undefined;

      let found = false;
      if (eGoalIds && eGoalIds.length) {
        for (const subject of (
          data && data.educationalPackage.data?.schoolSubjects
            ? data.educationalPackage.data.schoolSubjects
            : dataGrade && dataGrade.allSchoolSubjects.data
              ? dataGrade.allSchoolSubjects.data
              : []
        )) {
          for (const gradeLevel of subject.schoolSubjectGradeLevelGroups) {
            for (const eGoal of gradeLevel.eGoals) {
              if (eGoalIds.find(egId => eGoal.eGoal.id === egId)) {
                // console.log("found", subject.name);
                newSubjectId = subject.id;
                newGradeLevelGroupId = gradeLevel.gradeLevelGroup.id;
                found = true;
                break;
              }
            }
            if (found) break;
          }
          if (found) break;
        }
      }

      // console.log("newSubjectId", newSubjectId)
      setSubjectId(newSubjectId);
      setGradeLevelGroupId(newGradeLevelGroupId);
      setInit(false);
      // console.log("init");
    }
  }, [data, dataGrade, eGoalIds, init, loading]);

  /**
   * Get data for subject id
   * used in:
   * Activity Templates
   */
  useEffect(() => {
    if (subjectId && dataGrade) {
      getSubjectGradeLevels(subjectId);
      // console.log("getSubjectGradeLevels");
    }
  }, [getSubjectGradeLevels, subjectId, dataGrade]);

  /**
   * Get subjects with eGoalIds
   * used in:
   * courses without selected school subjects
   */
  useEffect(() => {
    if (data
      && data.educationalPackage.data.schoolSubjects
      && !data.educationalPackage.data.schoolSubjects.length
      && eGoalIds && eGoalIds.length) {
      fetchByEGoalIds([...eGoalIds]);
      // console.log("fetchByEGoalIds");
    }
  }, [fetchByEGoalIds, data, eGoalIds]);

  /**
   * Set subjectId and gradeLevelGroupId with eGoalIds
   * used in:
   * courses without selected school subjects
   */
  useEffect(() => {
    if (dataSubjectsByEGoalIds?.findSchoolSubjectsByEGoalIds.data[0].id) {
      const subjectId = dataSubjectsByEGoalIds.findSchoolSubjectsByEGoalIds.data[0].id;
      const gradeLevelGroupId = dataSubjectsByEGoalIds
        .findSchoolSubjectsByEGoalIds.data[0]
        .schoolSubjectGradeLevelGroups
        .find(gradeLevel => (gradeLevel.eGoals as any).find((eGoal: any) => eGoal.eGoal.id === eGoalIds[0]))?.gradeLevelGroup.id
      setSubjectId(subjectId);
      setGradeLevelGroupId(gradeLevelGroupId);
      // console.log("dataSubjectsByEGoalIds");
    }
  }, [dataSubjectsByEGoalIds, eGoalIds]);

  const { t } = useTranslation();

  const schoolSubjectData =
    (data &&
      data.educationalPackage.data.schoolSubjects &&
      data.educationalPackage.data.schoolSubjects.length &&
      data.educationalPackage.data.schoolSubjects && [
        ...data.educationalPackage.data.schoolSubjects,
      ]) ||
    (dataSubject && [dataSubject.findBySchoolSubject.data]) ||
    (dataSubjectsByEGoalIds &&
      dataSubjectsByEGoalIds.findSchoolSubjectsByEGoalIds.data && [
        ...dataSubjectsByEGoalIds.findSchoolSubjectsByEGoalIds.data,
      ]) ||
    [];

    // console.log("subjectId", subjectId);
    // console.log("schoolSubjectData", schoolSubjectData);
    // console.log("data", data?.educationalPackage.data.schoolSubjects);
    // console.log("dataSubject", dataSubject?.findBySchoolSubject.data);
    // console.log("dataSubjectsByEGoalIds", dataSubjectsByEGoalIds?.findSchoolSubjectsByEGoalIds.data);
    // console.log("dataGrade", dataGrade?.allSchoolSubjects.data);

  let goalsCount: number = 0;
  let eGoalsContent: ReactNode = (
    <Box mt={1}>
      {schoolSubjectData &&
        schoolSubjectData.length > 0 &&
        schoolSubjectData
          .filter(ss => ss.id === subjectId)
          .map((subject, is) =>
            subject.schoolSubjectGradeLevelGroups
              .filter(gr => gr.gradeLevelGroup.id === gradeLevelGroupId)
              .map(ssGlg =>
                [...ssGlg.eGoals]
                  .sort(
                    (a, b) =>
                      firstNumber(a.eGoal.name) - firstNumber(b.eGoal.name),
                  )
                  .map((eGoal, ieg) => {
                    let count: number = 0;
                    if (eGoal.eGoal && eGoal.eGoal.id) {
                      (eGoals || []).forEach(t => {
                        if (t.eGoalIds.find(id => id === eGoal.eGoal.id)) {
                          count++;
                        }
                      });
                      count += (otherGoals || {})[eGoal.eGoal.id] || 0;
                    }
                    goalsCount++;
                    return (
                      <Box my={1.5} key={`egoal-${is}-${ieg}`}>
                        <EGoalCard
                          defaultExpanded={is === 0 && ieg === 0}
                          eGoal={eGoal}
                          count={count}
                          eGoalIds={eGoalIds}
                          onSetEGoalIds={eGoalIds =>
                            onSetEGoalIds && onSetEGoalIds(eGoalIds)
                          }
                        />
                      </Box>
                    );
                  }),
              ),
          )}
    </Box>
  );
  
  if (!goalsCount || !eGoalsContent) {
    eGoalsContent = (
      <Box
        my={1.5}
        alignItems={"center"}
        width={"100%"}
        key={`egoal-no-goals-1`}
      >
        <Text align={"center"}>
          {t(
            "components.organisms.selectOPSEducationalPackageGoalModal.noGoalsFound",
          )}
        </Text>
      </Box>
    );
  }

  return (
    <Modal
      height="750px"
      width="700px"
      loading={loading}
      primaryAction={{
        text: t("globals.close"),
        onClick: onClose,
      }}
      title={t(
        "components.organisms.selectOPSEducationalPackageGoalModal.title",
      )}
      open={open}
    >
      <Flex flex={1} mt={2} flexDirection="column">
        <Text>
          {t(
            "components.organisms.selectOPSEducationalPackageGoalModal.titleSearch",
          )}
        </Text>
        <Flex>
          <Box flex={1} mr={1}>
            <InputLabel htmlFor="gradeLevelGroupId">
              {t(
                "components.organisms.selectOPSEducationalPackageGoalModal.filterGradeLevelGroup",
              )}
            </InputLabel>
            {gradeLevelGroupId && (
              <Select
                value={gradeLevelGroupId}
                onChange={e => setGradeLevelGroupId(e.target.value as string)}
                input={<StyledInput name="gradeLevelGroupId" id="subjectId" />}
              >
                {(
                  (data &&
                    data.allGradeLevelGroups.data && [
                      ...data.allGradeLevelGroups.data,
                    ]) ||
                  (dataGrade &&
                    dataGrade.allGradeLevelGroups.data && [
                      ...dataGrade.allGradeLevelGroups.data,
                    ]) ||
                  []
                )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ id, name }, i) => (
                    <MenuItem key={id} value={id} data-cy={`s-glg-${i}`}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Box>
          <Box flex={1} ml={1}>
            <InputLabel htmlFor="subjectId">
              {t(
                "components.organisms.selectOPSEducationalPackageGoalModal.filterSubject",
              )}
            </InputLabel>
            {subjectId && (
              <Select
                value={subjectId}
                onChange={e => setSubjectId(e.target.value as string)}
                input={<StyledInput name="subjectId" id="subjectId" />}
              >
                {(
                  (data &&
                    data.educationalPackage.data.schoolSubjects?.length && [
                      ...data.educationalPackage.data.schoolSubjects,
                    ]) ||
                  (dataSubjectsByEGoalIds && [
                    ...dataSubjectsByEGoalIds.findSchoolSubjectsByEGoalIds.data,
                  ]) ||
                  []
                )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                {dataGrade &&
                  ([...dataGrade.allSchoolSubjects.data] || [])
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
              </Select>
            )}
          </Box>
        </Flex>
        <Flex flex={1} mt={2} flexDirection="column">
          {eGoalsContent}
        </Flex>
      </Flex>
    </Modal>
  );
};
