/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Type for assessments
 */
export enum AssessmentType {
  self = "self",
  teacher = "teacher",
}

/**
 * Type for GoalTemplateFolder
 */
export enum GoalTemplateFolderType {
  activity = "activity",
  owngoal = "owngoal",
}

/**
 * Status for learning achievement material
 */
export enum LearningAchievementMaterialStatus {
  completed = "completed",
  created = "created",
  error = "error",
}

/**
 * Type for learning achievement material
 */
export enum LearningAchievementMaterialType {
  file = "file",
  link = "link",
}

export enum QuestionType {
  numeric = "numeric",
  verbal = "verbal",
}

export enum Role {
  learner = "learner",
  organizationAdmin = "organizationAdmin",
  superAdmin = "superAdmin",
  teacher = "teacher",
}

export enum SelfAssessmentSchedule {
  draft = "draft",
  now = "now",
}

/**
 * Status for activity
 */
export enum Status {
  active = "active",
  completed = "completed",
  draft = "draft",
}

export interface ActivityCreateInputType {
  name: string;
  description: string;
  educationalPackageId?: string | null;
  selfAssessment: boolean;
  learnerIds: string[];
  status: Status;
  activityTemplateFolderId?: string | null;
}

export interface ActivityExportInputType {
  id: string;
  activityTemplateFolderId: string;
}

export interface ActivityImportInputType {
  id: string;
  educationalPackageId: string;
  activityId?: string | null;
}

export interface ActivityListFiltersInputType {
  status?: Status | null;
  submitted?: boolean | null;
  educationalPackageId: string;
  learnerId?: string | null;
  offset?: number | null;
  limit?: number | null;
}

export interface ActivitySetAssessmentInputType {
  id: string;
  selfAssessment: boolean;
  schedule?: SelfAssessmentSchedule | null;
}

export interface ActivitySetGoalsInputType {
  id: string;
  educationalPackageGoals: EducationalPackageGoalInput[];
  verbalAssessmentSettingId?: string | null;
}

export interface ActivitySetOrderInputType {
  id: string;
  to: number;
}

export interface ActivityTemplateFolderCreateInputType {
  name: string;
  schoolId: string;
  parentId?: string | null;
}

export interface ActivityTemplateFolderUpdateInputType {
  id: string;
  name: string;
}

export interface ActivityTemplateListFiltersInputType {
  schoolId: string;
}

export interface ActivityUpdateInputType {
  id: string;
  name: string;
  description: string;
  status: Status;
  selfAssessment: boolean;
  learnerIds: string[];
}

export interface DeleteMaterialInputType {
  learningAchievementMaterialIds: string[];
}

export interface EducationalPackageActivitiesListFiltersInputType {
  status?: Status | null;
  submitted?: boolean | null;
}

export interface EducationalPackageCreateInputType {
  name: string;
  active: boolean;
  visibleToGuardians: boolean;
  joinByCode: boolean;
  gradeLevelId: string;
  colorTheme?: string | null;
  schoolSubjects?: SchoolSubjectListInput[] | null;
}

export interface EducationalPackageGoalInput {
  id?: string | null;
  name: string;
  eGoalIds?: string[] | null;
  numericAssessmentSettingId?: string | null;
}

export interface EducationalPackageJoinByCodeInputType {
  joinCode: string;
}

export interface EducationalPackageSetActiveInputType {
  id: string;
  active: boolean;
}

export interface EducationalPackageSetMembersInputType {
  id: string;
  teachers?: UserInput[] | null;
  learners?: UserInput[] | null;
}

export interface EducationalPackageUpdateInputType {
  id: string;
  name: string;
  active: boolean;
  visibleToGuardians: boolean;
  joinByCode: boolean;
  gradeLevelId?: string | null;
  colorTheme?: string | null;
  schoolSubjects?: SchoolSubjectListInput[] | null;
}

export interface EducationalPackagesListFiltersInputType {
  active?: boolean | null;
}

export interface FileInputType {
  id: string;
  name: string;
}

export interface ForceSyncEducationalPackageType {
  educationalPackageId: string;
}

export interface GoalTemplateCreateInputType {
  text: string;
  folderId: string;
  eGoalIds: string[];
}

export interface GoalTemplateFolderCreateInputType {
  name: string;
  type: GoalTemplateFolderType;
  schoolId: string;
  parentId?: string | null;
}

export interface GoalTemplateFolderUpdateInputType {
  id: string;
  name: string;
}

export interface GoalTemplateListFiltersInputType {
  schoolId: string;
  type: GoalTemplateFolderType;
}

export interface GoalTemplateUpdateInputType {
  id: string;
  text: string;
  eGoalIds: string[];
}

export interface GradeNumberInputType {
  gradeNumber: number;
}

export interface IdInputType {
  id: string;
}

export interface IdsInputType {
  ids: string[];
}

export interface ImportedEducationalPackageCreateInputType {
  classroomId: string;
  gradeLevelId?: string | null;
  colorTheme?: string | null;
  schoolSubjects?: SchoolSubjectListInput[] | null;
}

export interface LearningAchievemenFilterInput {
  eGoalIds: string[];
  userIds: string[];
}

export interface LearningAchievementInput {
  activityId: string;
  userId?: string | null;
}

export interface LearningAchievementInputType {
  numericAssessments?: NumericAssessmentInput[] | null;
  verbalAssessment?: VerbalAssessmentInput | null;
}

export interface LearningAchievementMaterialInputType {
  activityId: string;
  learners?: string[] | null;
  links?: string[] | null;
  fileUploads?: FileInputType[] | null;
  driveFiles?: FileInputType[] | null;
}

export interface ListDriveFilesFilter {
  pageToken?: string | null;
  folderId?: string | null;
}

export interface NumericAssessmentInput {
  educationalPackageGoalId: string;
  value?: number | null;
}

export interface NumericAssessmentSettingFilterInputType {
  educationalPackageId: string;
}

export interface NumericAssessmentSettingInput {
  id?: string | null;
  scale: number;
  learnerScaleTexts: string[];
  teacherScaleTexts: string[];
  favourite: boolean;
  name?: string | null;
  defaultSetting?: boolean | null;
}

export interface OwnGoalSurveyCreateInput {
  name?: string | null;
  order: number;
  surveyQuestions: OwnGoalSurveyQuestionCreateInput[];
}

export interface OwnGoalSurveyQuestionCreateInput {
  name: string;
  description?: string | null;
  type: QuestionType;
  scheduledDate?: any | null;
}

export interface OwnGoalSurveyQuestionUpdateInput {
  id?: string | null;
  name: string;
  description?: string | null;
  type: QuestionType;
  scheduledDate?: any | null;
}

export interface OwnGoalSurveyUpdateInput {
  id: string;
  name?: string | null;
  order: number;
  surveyQuestions: OwnGoalSurveyQuestionUpdateInput[];
}

export interface OwnGoalsSurveyGroupFilterInputType {
  userId: string;
}

export interface PatchLearningAchievementInput {
  activityId: string;
  userId?: string | null;
  learningAchievement?: LearningAchievementInputType | null;
}

export interface SchoolSubjectListInput {
  id: string;
  name?: string | null;
}

export interface SurveyFilterInputType {
  gradeNumber?: number | null;
  name?: string | null;
}

export interface SurveyQuestionAnswerCommentCreateInput {
  text: string;
  surveyQuestionAnswerId: string;
}

export interface SurveyQuestionAnswerCommentMarkAsReadInput {
  id: string;
}

export interface SurveyQuestionAnswerInput {
  numeric?: number | null;
  verbalText?: string | null;
  surveyQuestionId: string;
}

export interface SurveyQuestionFilterInputType {
  userIds: string[];
}

export interface TextToSpeechInput {
  text: string;
}

export interface UserInput {
  id: string;
}

export interface UserLanguageInputType {
  language: string;
}

export interface VerbalAssessmentInput {
  assessmentText: string;
}

export interface VerbalAssessmentSettingFilterInputType {
  educationalPackageId: string;
}

export interface VerbalAssessmentSettingInput {
  id?: string | null;
  learnerHelperText: string;
  favourite: boolean;
  name?: string | null;
  defaultSetting?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
