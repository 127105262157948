import React, { useRef } from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Box, InputLabel, Input } from "../../../Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { SurveyQuestionAnswerCommentCreateInput } from "typings/graphql-global-types";
import EmojiPicker from "components/Atoms/EmojiPicker";

const Form = styled.form`
  width: 100%;
`;

const EmojiButtonBox = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 0px;
`;

const EmojiInputBox = styled(Box)`
  position: relative;
`;

const StyledInputLabel = styled(InputLabel)`
  position: inherit;
  margin-top: 0;
  color: #000;
  font-size: 1.25rem;
  font-weight: bold;
  transform: translate(0, 1.5px);
`;

interface CommentFormProps
  extends FormikProps<SurveyQuestionAnswerCommentCreateInput> {}

export const CommentForm: React.FC<CommentFormProps> = ({
  values: { text },
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <Form>
      <FormControl fullWidth>
        <Box>
          <StyledInputLabel htmlFor="name">
            {t("components.organisms.editCommentsModal.comment")}
          </StyledInputLabel>
        </Box>
        <EmojiInputBox flex={1} width="100%">
          <Input
            inputRef={inputRef}
            id="text"
            name="text"
            multiline
            rows={4}
            helperText={touched.text ? errors.text : ""}
            error={touched.text && Boolean(errors.text)}
            value={text}
            onChange={change.bind(null, "text")}
            fullWidth
          />
          <EmojiButtonBox>
            <EmojiPicker
              zIndex={1300}
              onSelect={e => {
                const start = (inputRef.current! as HTMLTextAreaElement)
                  .selectionStart;
                const end = (inputRef.current! as HTMLTextAreaElement)
                  .selectionEnd;

                let newText =
                  text.substring(0, start) + e.native + text.substring(end);

                setFieldValue(`text`, newText);
              }}
            />
          </EmojiButtonBox>
        </EmojiInputBox>
      </FormControl>
    </Form>
  );
};
