import { useEffect } from "react";

const useBeforeUnload = (value: (evt: BeforeUnloadEvent) => any) => {
  useEffect(() => {
    const handleBeforeunload = (evt: BeforeUnloadEvent) => {
      let r = value(evt);
      if (r) {
        evt.preventDefault();
        evt.returnValue = r;
        return true;
      }
    };
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => window.removeEventListener("beforeunload", handleBeforeunload);
  }, [value]);
};

export default useBeforeUnload;
