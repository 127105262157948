import * as React from "react";
import { pathOr } from "ramda";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import { EducationPackageForm } from "./Form";
import { ClassroomImportedForm } from "./ClassroomImportedForm";
import { Queries } from "./Queries";

import {
  GetAllGradeLevelsAndSubjects_allGradeLevels_data as GradeLevel,
  GetAllGradeLevelsAndSubjects_allSchoolSubjects_data as SchoolSubject,
} from "./Queries/types/GetAllGradeLevelsAndSubjects";

import { ImportedEducationalPackageCreateInputType } from "typings/graphql-global-types";

import { Suggestion } from "../../Molecules/MultiChipSelect";

export interface EditEducationPackageModalProps {
  showModal: boolean;
  onModalClosed: (result: boolean) => void;
  educationalPackageId?: string;
  importFromClassroom?: boolean;
}

export const EditEducationPackageModal: React.FunctionComponent<EditEducationPackageModalProps> = ({
  educationalPackageId,
  showModal,
  onModalClosed,
  importFromClassroom,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };
  return (
    <Queries
      educationalPackageId={educationalPackageId}
      importFromClassroom={importFromClassroom}
    >
      {({
        educationalPackage: {
          loading: loadingPack,
          data: educationalPackageData,
        },
        gradeLevelsAndSubjects: {
          loading: loadingGradeLevelsAndSubjects,
          data: gradeLevelsAndSubjectsData,
        },
        classroom: {
          loading: loadingClassroomCourses,
          data: classroomCourseData,
        },
        create,
        createImported,
        update,
      }) => {
        const allSubjects =
          (gradeLevelsAndSubjectsData &&
            gradeLevelsAndSubjectsData.allSchoolSubjects.data) ||
          [];
        const subjects: Suggestion[] = allSubjects
          .map((s: SchoolSubject) => ({
            id: s.id,
            name: s.name,
            submenuItems: s.schoolSubjects,
          }))
          .filter(
            s =>
              !allSubjects.find(as =>
                as.schoolSubjects.find(a => a.id === s.id),
              ),
          );

        const gradeLevels: GradeLevel[] = pathOr(
          [],
          ["allGradeLevels", "data"],
          gradeLevelsAndSubjectsData,
        );

        let validationSchema: any = Yup.object().shape({
          name: Yup.string()
            .required(t("globals.validations.nameRequired"))
            .max(140, t("globals.validations.nameMax")),
          gradeLevelId: Yup.string().required(
            t("globals.validations.gradeLevelRequired"),
          ),
        });

        const isClassroomImport =
          importFromClassroom ||
          (educationalPackageData &&
            educationalPackageData.educationalPackage.data.importedPackage);

        if (importFromClassroom) {
          validationSchema = Yup.object().shape({
            classroomId: Yup.string().required(
              t("globals.validations.classroomCourseRequired"),
            ),
            gradeLevelId: Yup.string().required(
              t("globals.validations.gradeLevelRequired"),
            ),
          });
        } else if (
          educationalPackageData &&
          educationalPackageData.educationalPackage.data.importedPackage
        ) {
          validationSchema = Yup.object().shape({
            gradeLevelId: Yup.string().required(
              t("globals.validations.gradeLevelRequired"),
            ),
          });
        }

        const loading =
          loadingPack ||
          loadingGradeLevelsAndSubjects ||
          loadingClassroomCourses;
        return (
          <Formik
            enableReinitialize
            initialValues={{
              name: pathOr(
                "",
                "educationalPackage.data.name".split("."),
                educationalPackageData,
              ),
              gradeLevelId: pathOr(
                "",
                "educationalPackage.data.gradeLevel.id".split("."),
                educationalPackageData,
              ),
              schoolSubjects:
                educationalPackageData &&
                educationalPackageData.educationalPackage.data.schoolSubjects
                  ? educationalPackageData.educationalPackage.data.schoolSubjects.map(
                      m => ({ id: m.id, name: m.name }),
                    )
                  : [],
              visibleToGuardians: pathOr(
                true,
                "educationalPackage.data.visibleToGuardians".split("."),
                educationalPackageData,
              ),
              joinByCode: pathOr(
                true,
                "educationalPackage.data.visibleToGuardians".split("."),
                educationalPackageData,
              ),
              active: pathOr(
                true,
                "educationalPackage.data.active".split("."),
                educationalPackageData,
              ),
              colorTheme:
                educationalPackageData &&
                educationalPackageData.educationalPackage.data &&
                educationalPackageData.educationalPackage.data.colorTheme,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values: any, { setSubmitting }) => {
              setSubmitting(true);
              if (educationalPackageId) {
                const newValues = values;
                if (!newValues.schoolSubjects) {
                  newValues.schoolSubjects = [];
                }
                await update.mutation({
                  variables: {
                    input: { id: educationalPackageId, ...values },
                  },
                });
              } else if (importFromClassroom) {
                const importValues = values as ImportedEducationalPackageCreateInputType;

                await createImported.mutation({
                  classroomId: importValues.classroomId,
                  gradeLevelId: importValues.gradeLevelId,
                  schoolSubjects: importValues.schoolSubjects,
                  colorTheme: importValues.colorTheme,
                });
              } else {
                await create.mutation({
                  ...values,
                });
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;
              return (
                <Modal
                  name="edit-educationalpackage"
                  error={create.error || update.error}
                  height="695px"
                  loading={
                    isSubmitting ||
                    loadingPack ||
                    loadingGradeLevelsAndSubjects ||
                    loadingClassroomCourses
                  }
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: handleSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  open={showModal}
                  title={
                    educationalPackageId
                      ? t(
                          "components.organisms.editEducationPackageModal.titleEdit",
                        )
                      : importFromClassroom
                      ? t(
                          "components.organisms.editEducationPackageModal.titleImportFromClassroom",
                        )
                      : t(
                          "components.organisms.editEducationPackageModal.titleNew",
                        )
                  }
                >
                  <>
                    {loading && <></>}
                    {isClassroomImport && !loading && (
                      <ClassroomImportedForm
                        isEdit={!!educationalPackageId}
                        classroomCourses={
                          (classroomCourseData &&
                            classroomCourseData.listClassroomCourses.data) ||
                          []
                        }
                        gradeLevels={gradeLevels}
                        subjects={subjects}
                        {...props}
                      />
                    )}
                    {!isClassroomImport && !loading && (
                      <EducationPackageForm
                        gradeLevels={gradeLevels}
                        subjects={subjects}
                        {...props}
                      />
                    )}
                  </>
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
