import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ToggleButtonGroup, ToggleButton } from "components/Atoms";

import { GoalTemplateFolderType } from "typings/graphql-global-types";

interface Props {
  value: GoalTemplateFolderType;
  onChanged: (state: GoalTemplateFolderType) => void;
}

const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    height: 41px;
    padding: 5px 20px;
  }
`;
export const Filter: React.FC<Props> = ({ value, onChanged }) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup value={value}>
      <StyledToggleButton
        key={1}
        value={GoalTemplateFolderType.owngoal}
        onClick={() => onChanged(GoalTemplateFolderType.owngoal)}
      >
        {t("containers.settings.goalTemplates.ownGoalTemplates")}
      </StyledToggleButton>
      <StyledToggleButton
        key={2}
        value={GoalTemplateFolderType.activity}
        onClick={() => onChanged(GoalTemplateFolderType.activity)}
      >
        {t("containers.settings.goalTemplates.activityGoalTemplates")}
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};
