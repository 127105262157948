import React, { useContext } from "react";
import { pathOr } from "ramda";

import { Formik } from "formik";

import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import UserContext from "../../Contexts/UserContext";
import { MemberForm } from "./Form";
import { Queries } from "./Queries";
import { GetEducationalPackageMembers_educationalPackage_data_learners as User } from "./Queries/types/GetEducationalPackageMembers";

export interface EditEducationPackageModalProps {
  showModal: boolean;
  onModalClosed: (result: boolean) => void;
  educationalPackageId: string;
}

export const EditEducationPackageMembersModal: React.FunctionComponent<EditEducationPackageModalProps> = ({
  educationalPackageId,
  showModal,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const onCancel = () => {
    onModalClosed(false);
  };
  return (
    <Queries educationalPackageId={educationalPackageId}>
      {({
        educationalPackageMembers: { loading: loadingMembers, data },
        update,
      }) => {
        return (
          <Formik
            enableReinitialize
            initialValues={{
              teachers: pathOr(
                [],
                "educationalPackage.data.teachers".split("."),
                data,
              ).map((s: User) => ({
                id: s.id,
                disabled: user && s.id === user.uid,
                name: `${s.givenName} ${s.familyName}`,
              })),
              learners: pathOr(
                [],
                "educationalPackage.data.learners".split("."),
                data,
              ).map((s: User) => ({
                id: s.id,
                name: `${s.givenName} ${s.familyName}`,
              })),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await update.mutation({
                variables: {
                  input: {
                    id: educationalPackageId,
                    learners: values.learners.map(l => ({
                      id: l.id,
                    })),
                    teachers: values.teachers.map(l => ({
                      id: l.id,
                    })),
                  },
                },
              });

              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;
              return (
                <Modal
                  name="edit-educationalpackage-members"
                  error={update.error}
                  loading={isSubmitting || loadingMembers}
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: handleSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  open={showModal}
                  title={t(
                    "components.organisms.editEducationPackageMembersModal.title",
                  )}
                >
                  <MemberForm {...props} />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
