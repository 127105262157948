// New TypeScript versions do not include typings https://stackoverflow.com/a/69491367
declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}


export interface HeaderInput {
  [key: string]: string;
}

export interface RowInput {
  [key: string]: string;
}

export const convertToCSV = (headers: HeaderInput, items: RowInput[]) => {
  const replacer = (key: string, value: string) =>
    value === null ? "" : value;
  const h = Object.keys(headers);
  let csv = items.map(row =>
    h.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","),
  );
  csv.unshift(h.map(k => headers[k]).join(","));
  return csv.join("\r\n");
};

export const exportCSVFile = (
  headers: HeaderInput,
  items: RowInput[],
  filename: string,
) => {
  var csv = convertToCSV(headers, items);

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
