import React from "react";
import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";

import { useTranslation } from "react-i18next";

import { Flex, Tooltip } from "components/Atoms";

import {
  GoodIcon,
  VeryGoodIcon,
  FairlyGoodIcon,
  FairIcon,
  ExcellentIcon,
} from "components/Atoms/Icons";

import { AssessmentType } from "typings/graphql-global-types";
import {
  AssessmentValueScale3,
  AssessmentValueScale5,
} from "components/Atoms/NumericAssessmentInput";

import {
  GetLearningAchievementsForEGoals_learningAchievements_data_numericAssessments as NumericAssessment,
  GetLearningAchievementsForEGoals_learningAchievements_data as LearningAchievement,
} from "../EGoalLearningAchievementQueries/types/GetLearningAchievementsForEGoals";

import { GetEducationalPackageEGoalsOpsTable_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal } from "../Queries/types/GetEducationalPackageEGoalsOpsTable";

import { getAssessmentScale, getAssessmentText } from "utils/scaleHelpers";

const StyledRemoveOutlinedIcon = styled(RemoveOutlinedIcon)`
  margin-top: -4px;
`;

export interface PartialNumericAssessment {
  value: number | null;
  eGoalId: string;
  __typename?: string;
  type?: AssessmentType;
}

interface Props {
  numericAssessments: NumericAssessment[];
  onClick: () => void;
}

export const getAssessments = (
  eGoal: EGoal,
  type: AssessmentType,
  learningAchievements: LearningAchievement[] | null,
): NumericAssessment[] => {
  const assessments = [];

  for (const la of learningAchievements || []) {
    for (const na of la.numericAssessments) {
      if (
        na.educationalPackageGoal.eGoals.find(eg => eg.id === eGoal.eGoal.id) &&
        na.type === type
      )
        assessments.push(na);
    }
  }
  return assessments;
};

export const EGoalAssessmentCell: React.FC<Props> = ({
  numericAssessments,
  onClick,
}) => {
  const { t } = useTranslation();

  const lastAssessment = numericAssessments.length && numericAssessments[0];

  let tooltipText = t(`globals.empty.noAssessment`);

  let scale = null;
  let scaleTexts = null;

  const value = lastAssessment && lastAssessment.value;
  if (lastAssessment) {
    scale = getAssessmentScale(
      [lastAssessment.educationalPackageGoal],
      lastAssessment.educationalPackageGoal.id,
    );

    scaleTexts = getAssessmentText(
      [lastAssessment.educationalPackageGoal],
      lastAssessment.educationalPackageGoal.id,
      lastAssessment.type,
    );
  }

  if (
    scale === 5 &&
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale5.Fair
  ) {
    tooltipText =
      (scaleTexts && scaleTexts[0]) ||
      t(`globals.numericAssessments.${lastAssessment.type}.fair`);
  } else if (
    scale === 5 &&
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale5.FairlyGood
  ) {
    tooltipText =
      (scaleTexts && scaleTexts[1]) ||
      t(`globals.numericAssessments.${lastAssessment.type}.fairlyGood`);
  } else if (
    scale === 5 &&
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale5.Good
  ) {
    tooltipText =
      (scaleTexts && scaleTexts[2]) ||
      t(`globals.numericAssessments.${lastAssessment.type}.mediocre`);
  } else if (
    scale === 5 &&
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale5.VeryGood
  ) {
    tooltipText =
      (scaleTexts && scaleTexts[3]) ||
      t(`globals.numericAssessments.${lastAssessment.type}.Good`);
  } else if (
    scale === 5 &&
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale5.Excellent
  ) {
    tooltipText =
      (scaleTexts && scaleTexts[4]) ||
      t(`globals.numericAssessments.${lastAssessment.type}.Excellent`);
  } else if (
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale3.Fair
  ) {
    tooltipText = t(`globals.numericAssessments.${lastAssessment.type}.fair`);
  } else if (
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale3.Good
  ) {
    tooltipText = t(`globals.numericAssessments.${lastAssessment.type}.mediocre`);
  } else if (
    lastAssessment &&
    lastAssessment.value === AssessmentValueScale3.Excellent
  ) {
    tooltipText = t(
      `globals.numericAssessments.${lastAssessment.type}.excellent`,
    );
  }

  tooltipText +=
    "\n" +
    t("containers.teacherEducationPackage.eGoalsTable.showAssessmentsTooltip", {
      count: numericAssessments.length,
    });

  return (
    <Flex
      py={0.5}
      height={"100%"}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Tooltip
        arrow
        PopperProps={{ style: { marginTop: "-10px", textAlign: "center" } }}
        title={tooltipText}
      >
        <Flex
          marginTop={-0.2}
          mb="7px"
          justifyContent="center"
          width={["100%"]}
        >
          <IconButton onClick={onClick}>
            {scale === 5 && value === AssessmentValueScale5.Fair && (
              <FairIcon view checked></FairIcon>
            )}

            {scale === 5 && value === AssessmentValueScale5.FairlyGood && (
              <FairlyGoodIcon view checked></FairlyGoodIcon>
            )}

            {scale === 5 && value === AssessmentValueScale5.Good && (
              <GoodIcon view checked></GoodIcon>
            )}

            {scale === 5 && value === AssessmentValueScale5.VeryGood && (
              <VeryGoodIcon view checked></VeryGoodIcon>
            )}

            {scale === 5 && value === AssessmentValueScale5.Excellent && (
              <ExcellentIcon view checked></ExcellentIcon>
            )}

            {scale !== 5 && value === AssessmentValueScale3.Fair && (
              <FairIcon view checked></FairIcon>
            )}
            {scale !== 5 && value === AssessmentValueScale3.Good && (
              <GoodIcon view checked></GoodIcon>
            )}
            {scale !== 5 && value === AssessmentValueScale3.Excellent && (
              <ExcellentIcon view checked></ExcellentIcon>
            )}

            {!lastAssessment && <StyledRemoveOutlinedIcon fontSize="small" />}
          </IconButton>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
