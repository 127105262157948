import React from "react";
import styled from "styled-components";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

export const StyledTable = styled(({ showPagination, ...rest }) => (
  <Table {...rest} />
))<any>`
  box-shadow: none;
  overflow: auto;
  min-height: 10vh;
  max-height: 85vh;

  &:last-child tr:first-child {
    border-bottom-left-radius: ${props => (props.showPagination ? 0 : 24)}px;
  }

  &:last-child tr:last-child {
    border-bottom-right-radius: ${props => (props.showPagination ? 0 : 24)}px;
  }
`;

export const StyledTableBody = styled(TableBody)`
  vertical-align: top;
`;

export const StyledTableCell = styled(TableCell)`
  height: 100%;
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

export const StickyStyledTableCell = styled(StyledTableCell)`
  position: sticky;
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 0px;
  }
  z-index: 1;
`;

export const StickyStyledTableHeaderCell = styled(StickyStyledTableCell)`
  position: sticky;
  top: 0px;
  z-index: 3;
`;

export const StickySecondaryTableHeaderCell = styled(
  StickyStyledTableHeaderCell,
)`
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 150px;
  }
`;

export const StickyStyledTableHeaderLearnerCell = styled(
  StickyStyledTableHeaderCell,
)`
  z-index: 2;
`;

export const StyledTablePagination = styled(TablePagination)<any>`
  background: #fff;
  border-radius: 0 0 24px 24px;
`;
