import * as React from "react";

import styled from "styled-components";

import { default as MuiSnackbar, SnackbarProps as MuiSnackbarProps } from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import IconButton from "@material-ui/core/IconButton";

import { Flex, Box } from "components/Atoms";

const SnackbarIcon: React.FunctionComponent<TypeProp> = ({ type }) => {
  if (type === "success") return <CheckCircleOutlineOutlinedIcon />;
  if (type === "error") return <NotificationImportantIcon />;
  if (type === "info") return <InfoIcon />;

  return null;
};

type SnackbarType = "error" | "success" | "info";

type TypeProp = {
  type: SnackbarType;
};

const FullWidthSnackbar = styled(MuiSnackbar)<TypeProp>`
  .MuiSnackbarContent-message {
    flex: 1;
    padding: ${props => props.theme.spacing(0.25)}px 0;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .MuiSnackbarContent-root {
    background-color: ${props =>
      props.type === "error"
        ? props.theme.palette.error.main
        : props.type === "info"
        ? "#E2E2E2"
        : "#c3e2d2"};
    color: ${props =>
      props.type === "error"
        ? props.theme.palette.error.contrastText
        : props.type === "info"
        ? props.theme.palette.text.secondary
        : "#00933D"};
  }
  ${props => props.theme.breakpoints.up("sm")} {
    left: 5%;
    right: 5%;
    .MuiSnackbarContent-root {
      flex-grow: 1;
      min-width: auto;
    }
  }
`;

export interface SnackbarProps {
  type: SnackbarType;
  message: React.ReactNode | null;
  onClose?: () => void;
  autoHideDuration?: number;
}

type FullWidthSnackbarProps = MuiSnackbarProps & SnackbarProps & TypeProp;

export const Snackbar: React.FunctionComponent<FullWidthSnackbarProps> = ({
  type,
  message,
  onClose,
  autoHideDuration,
  ...rest
}) => {
  return (
    <FullWidthSnackbar
      data-cy={`${type}-snackbar`}
      {...rest}
      type={type}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={
        autoHideDuration ? autoHideDuration : type === "success" ? 5000 : null
      }
      open={message ? true : false}
      onClose={onClose}
      ContentProps={{
        "aria-describedby": "label-id",
      }}
      ClickAwayListenerProps={
        type === "info" ? { onClickAway: () => null } : undefined
      }
      message={
        <Flex flex="1" id="label-id" alignItems="center">
          <SnackbarIcon type={type} />
          <Box flex="1" pt={0.1} ml={1}>
            {message}
          </Box>
        </Flex>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
