import * as React from "react";

import styled from "styled-components";

import Radio from "@material-ui/core/Radio";
import Color from "color";

import { Flex, Box } from "..";

import { educationalPackageThemeColors } from "components/Themes/Portfolio";

export type ColorThemeInputProps = {
  id?: string;
  value?: typeof educationalPackageThemeColors[number] | "none" | null;
  onChange: (value: string) => void;
};

const StyledRadio = styled(({ themeColor, ...rest }) => <Radio {...rest} />)`
  width: 45px;
  height: 45px;
  color: ${props =>
    props.themeColor
      ? Color(props.themeColor)
          .lightness(Color(props.themeColor).lightness() + 40)
          .hex()
      : "#0072C6"};
  background-color: ${props =>
    props.themeColor
      ? Color(props.themeColor)
          .lightness(Color(props.themeColor).lightness() + 40)
          .hex()
      : "#F1F0F4"};
  &.Mui-checked {
    color: ${props => props.themeColor || "#0072C6"};
    background-color: ${props => props.themeColor || "#F1F0F4"};
  }
  &:hover {
    color: ${props => props.themeColor || "#0072C6"};
    background-color: ${props => props.themeColor || "#F1F0F4"};
  }
  &.Mui-checked:hover {
    background-color: ${props => props.themeColor || "#F1F0F4"};
  }
`;

export const ColorThemeInput: React.ComponentType<
  ColorThemeInputProps
> = styled(({ id, onChange, value }: ColorThemeInputProps) => {
  return (
    <Flex id={id}>
      <Box m={0.5} mr={2.5}>
        <StyledRadio
          data-cy={`none-theme-radio-input`}
          checked={!value || value === "none"}
          onChange={() => onChange("none")}
          value={"none"}
          inputProps={{ "aria-label": `color none` }}
        />
      </Box>
      <Flex flexWrap="wrap">
        {educationalPackageThemeColors.map((color, i) => (
          <Box m={0.5} key={color}>
            <StyledRadio
              data-cy={`${i}-theme-radio-input`}
              checked={value === color}
              onChange={() => onChange(color)}
              value={color}
              themeColor={color}
              icon={<Box />}
              checkedIcon={<Box />}
              inputProps={{ "aria-label": `color ${i}` }}
            />
          </Box>
        ))}
      </Flex>
    </Flex>
  );
})``;

ColorThemeInput.displayName = "ColorThemeInput";
