import React from "react";
import { pathOr } from "ramda";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { EDUCATIONALPACKAGE, getRoute } from "routes";
import { Flex, Box, Text, Loader } from "components/Atoms";
import { EducationPackageCard } from "components/Organisms";
import { CardContent } from "components/Molecules";

import {
  AllEducationalPackagesLearner,
  AllEducationalPackagesLearner_allEducationalPackages_data as EducationPackage,
} from "../Queries/types/AllEducationalPackagesLearner";

interface Props {
  loading: boolean;
  data?: AllEducationalPackagesLearner;
}

type SpacingProp = {
  spacing: number;
};

const StyledFlex = styled(Flex)<SpacingProp>`
  margin: ${props => -1 * props.theme.spacing(props.spacing)}px;

  > ${Box as any} {
    padding: ${(props: any) => props.theme.spacing(props.spacing)}px;
  }

  ${(props: any) => props.theme.breakpoints.down("md")} {
    margin: ${props => -1 * props.theme.spacing(1.5)}px;
    > ${Box as any} {
      padding: ${(props: any) => props.theme.spacing(1.5)}px;
    }
  }

  ${(props: any) => props.theme.breakpoints.down("xs")} {
    margin: 0;
    > ${Box as any} {
      width: 100%;
      padding: 0;
      padding-bottom: ${(props: any) => props.theme.spacing(3)}px;
    }
  }
`;

const BigText = styled(Text)`
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 1;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;
`;

export const EducationalPackageList: React.FC<Props> = ({ loading, data }) => {
  const { t } = useTranslation();
  if (loading) return <Loader />;

  const educationPackages: EducationPackage[] = pathOr(
    [],
    ["allEducationalPackages", "data"],
    data,
  );

  return (
    <StyledFlex flexWrap="wrap" spacing={2}>
      {educationPackages.map((d, i) => (
        <Box key={`education-package-${i}`}>
          <EducationPackageCard
            title={d.name}
            colorTheme={d.colorTheme}
            openUrl={getRoute(EDUCATIONALPACKAGE, {
              educationalPackageId: d.id,
              tab: "newActivities",
            })}
            hideContextMenu
            importedFromClassRoom={d.importedPackage}
          >
            <StyledCardContent>
              <Flex>
                <Flex flexDirection="column">
                  <Text color="textPrimary" variant="h4">
                    {t(
                      "containers.learnerHome.educationPackageCard.returnedActivities",
                    )}
                  </Text>
                  <Box mt={0.5}>
                    <BigText variant="body1">
                      {d.submittedActivitiesCount} / {d.activeActivitiesCount}
                    </BigText>
                  </Box>
                </Flex>
              </Flex>
            </StyledCardContent>
          </EducationPackageCard>
        </Box>
      ))}
    </StyledFlex>
  );
};
