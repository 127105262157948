import * as React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import { Form } from "./Form";
import { Queries } from "./Queries";

import { GoalTemplateFolderType } from "typings/graphql-global-types";

export interface EditGoalTemplateFolderModalProps {
  open: boolean;
  onModalClosed: (result: boolean) => void;
  type: GoalTemplateFolderType;
  school: { id: string; name: string };
  folderId?: string;
  parentFolderId?: string;
}

export const EditGoalTemplateFolderModal: React.FunctionComponent<EditGoalTemplateFolderModalProps> = ({
  onModalClosed,
  open,
  school,
  type,
  folderId,
  parentFolderId,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Queries folderId={folderId}>
      {({ goalTemplateFolder: { loading, data }, create, update }) => {
        return (
          <Formik
            enableReinitialize
            initialValues={{
              name: data?.goalTemplateFolder.data.name || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(t("globals.validations.nameRequired"))
                .max(140, t("globals.validations.nameMax")),
            })}
            onSubmit={async (values: any, { setSubmitting }) => {
              setSubmitting(true);
              if (folderId) {
                await update.mutation({
                  variables: {
                    input: {
                      id: folderId,
                      parentId: parentFolderId,
                      ...values,
                    },
                  },
                });
              } else {
                await create.mutation({
                  variables: {
                    input: {
                      schoolId: school.id,
                      parentId: parentFolderId,
                      type,
                      ...values,
                    },
                  },
                });
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              const onSubmit = () => {
                handleSubmit();
              };

              return (
                <Modal
                  name="edit-goaltemplatefolder"
                  height="550px"
                  loading={isSubmitting || loading}
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: onSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  title={
                    folderId
                      ? t(
                          "components.organisms.editGoalTemplateFolderModal.titleEdit",
                        )
                      : t(
                          "components.organisms.editGoalTemplateFolderModal.titleNew",
                        )
                  }
                  open={open}
                >
                  <Form schoolName={school.name} {...props} />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
