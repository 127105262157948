import React, { FunctionComponent, useState, useEffect } from "react";

import { RouteProps } from "react-router";
import { pathOr, path } from "ramda";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client";
import { useLocation, useHistory, matchPath } from "react-router";
import { useTranslation } from "react-i18next";

import { Flex, Box, GoButton } from "../../Atoms";
import { Breadcrumbs } from "../../Molecules";
import { Header, Menu } from "../../Organisms";

import {
  BreadcrumbItem,
  NavigationOptions,
  NavigationOptionsMethod,
  NaviItem,
} from "../../../typings";
export interface PageTemplateProps {
  children: React.ReactNode;
}

const Navi = styled(Flex)`
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid ${props => props.theme.palette.grey[500]};
`;

const RootContainer = styled(Flex)`
  min-height: 100%;
  background-color: #d9d9d9;
`;

const ResponsiveHeaderBackground = styled(Flex)`
  width: 100%;
  justify-content: center;
  background-color: ${props => props.theme.palette.primary.dark};
`;

const ResponsiveHeader = styled(Box)`
  width: 100%;
  ${props => props.theme.breakpoints.up("xl")} {
    width: ${props => props.theme.breakpoints.values.xl}px;
  }
`;

const ResponsiveContent = styled(Flex)`
  background-color: #fcfbfc;
  width: 100%;
  ${props => props.theme.breakpoints.up("xl")} {
    width: ${props => props.theme.breakpoints.values.xl}px;
    align-items: center;
  }
`;

export const PageTemplate: FunctionComponent<PageTemplateProps> = ({
  children,
}) => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const [menuState, setMenuState] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [back, setBack] = useState<NaviItem | null>(null);
  const location = useLocation();
  const history = useHistory();
  const onMenuOpen = () => {
    setMenuState(true);
  };

  const onMenuClose = () => {
    setMenuState(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const routes: Array<React.Component<RouteProps>> = pathOr(
        [],
        "props.children".split("."),
        children,
      );

      const route = routes.find(
        ({ props }) =>
          props &&
          !!matchPath(location.pathname, {
            path: props.path,
            exact: true,
          }),
      );

      if (
        route &&
        route.props.component &&
        route.props.component.hasOwnProperty("navigationOptions")
      ) {
        const navigationOptions: any = path(
          "component.navigationOptions".split("."),
          route.props,
        );

        const options =
          typeof navigationOptions === "function"
            ? await (navigationOptions as NavigationOptionsMethod)({
                t,
                apolloClient,
                history,
                match: matchPath(location.pathname, {
                  path: route.props.path,
                }),
              })
            : (navigationOptions as NavigationOptions);

        setBreadcrumbs(options.breadcrumbs || []);
        setBack(options.back || null);
      }
    };
    fetch();
  }, [location.pathname, location.search, history, apolloClient, children, t]);

  return (
    <RootContainer alignItems="center" flexDirection="column">
      <ResponsiveHeaderBackground>
        <ResponsiveHeader>
          <Header onMenuOpen={onMenuOpen} />
        </ResponsiveHeader>
      </ResponsiveHeaderBackground>
      <ResponsiveContent flex={1} flexDirection="column">
        <Navi width="100%" px={2} justifyContent="space-between">
          <Breadcrumbs items={breadcrumbs} />
          {back && <GoButton {...back} direction="back"></GoButton>}
        </Navi>
        <Menu open={menuState} onClose={onMenuClose} />
        <Box width="100%">{children}</Box>
      </ResponsiveContent>
    </RootContainer>
  );
};
