import { ApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GET_TEACHERPROFILE, Queries } from "./components";

import { useAnalytics } from "components/Hooks/firebase-hooks";
import { useParams } from "components/Hooks/react-router-hooks";
import { useHistory } from "react-router";
import { getRoute, SETTINGS } from "routes";

import { useSnackbar } from "components/Contexts/SnackbarContext";

import {
  Box,
  Button,
  Flex,
  Input,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Text,
} from "components/Atoms";

import { GetTeacherProfile } from "./components/Queries/types/GetTeacherProfile";

import { NavigationComponent } from "typings";
import { ActivityTemplates } from "./components/ActivityTemplates";
import { GoalTemplates } from "./components/GoalTemplates";

import {
  EditNumericAssessmentSettingModal,
  EditVerbalAssessmentSettingModal,
} from "components/Organisms";

interface TeacherSettingsProps {}

enum TabValue {
  general = "general",
  goaltemplate = "goaltemplate",
  activitytemplate = "activitytemplate",
}

const Hero = styled(Flex)`
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #dcdada 0%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
`;

const SettingTitle = styled(Text)`
  color: #000;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const TeacherSettings: NavigationComponent<TeacherSettingsProps> = () => {
  const { t, i18n } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const history = useHistory();
  const { tab }: any = useParams();

  const { analytics } = useAnalytics();

  const activeTab = tab ? tab : TabValue.general;

  analytics.logEvent("page_view" as string, {
    page_title: "settings_" + activeTab,
    page_path: history.location.pathname,
  });

  const handleChange = (event: React.ChangeEvent<{}>, tab: TabValue) => {
    history.push(
      `${getRoute(SETTINGS, {
        tab,
      })}`,
    );
  };

  const [showVerbalFavouritesModal, setShowVerbalFavouritesModal] = useState(
    false,
  );
  const [showNumericFavouritesModal, setShowNumericFavouritesModal] = useState(
    false,
  );

  return (
    <Queries>
      {({ myprofile: { loading, data }, updateLanguage }) => {
        const onFavouritesModalClosed = (result: boolean) => {
          setShowVerbalFavouritesModal(false);
          setShowNumericFavouritesModal(false);
        };
        const me = data && data.myprofile && data.myprofile.data;

        const onLanguageChanged = async (
          event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>,
        ) => {
          await updateLanguage(event.target.value as "fi-FI" | "sv-FI");
          i18n.changeLanguage(event.target.value as string);
          showSnackbar({
            id: "language-success",
            type: "success",
            message: t("containers.settings.languageChangeSuccess"),
          });
        };

        return (
          <>
            <Flex flexDirection="column">
              <Hero px={[1, 6]} pt={[2, 5]} flexDirection="column">
                <Text variant="h3">{t("globals.settings")}</Text>
                <Text>{me ? `${me.givenName} ${me.familyName}` : ""}</Text>
              </Hero>
              <Flex px={[1, 6]} pt={[2, 5]} flexDirection="column">
                <Tabs
                  textColor="primary"
                  value={activeTab}
                  onChange={handleChange as any}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    value={TabValue.general}
                    label={t("containers.settings.general")}
                  />
                  <Tab
                    value={TabValue.goaltemplate}
                    label={t("containers.settings.goaltemplate")}
                  />
                  <Tab
                    value={TabValue.activitytemplate}
                    label={t("containers.settings.activitytemplate")}
                  />
                </Tabs>
              </Flex>
              {activeTab === TabValue.general ? (
                <>
                  <Flex px={[1, 6]} pt={[2, 5]} flexDirection="column">
                    <SettingTitle variant="h4">
                      {t("containers.settings.language")}
                    </SettingTitle>
                    <Box my={1} width="200px">
                      <Select
                        fullWidth
                        value={i18n.language}
                        onChange={onLanguageChanged}
                        input={<Input name="language" id="language" />}
                      >
                        <MenuItem value={"fi-FI"}>
                          {t("containers.settings.languageFinnish")}
                        </MenuItem>
                        <MenuItem value={"sv-FI"}>
                          {t("containers.settings.languageSwedish")}
                        </MenuItem>
                        <MenuItem value={"en-US"}>
                          {t("containers.settings.languageEnglish")}
                        </MenuItem>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex px={[1, 6]} py={[2]} flexDirection="column">
                    <SettingTitle variant="h4">
                      {t("containers.settings.favourites")}
                    </SettingTitle>
                    <Box my={1}>
                      <StyledButton
                        data-cy="edit-numeric-favourites-button"
                        size="small"
                        onClick={setShowNumericFavouritesModal.bind(null, true)}
                      >
                        {t("containers.settings.editNumericFavourites")}
                      </StyledButton>
                    </Box>
                    <Box>
                      <StyledButton
                        data-cy="edit-verbal-favourites-button"
                        size="small"
                        onClick={setShowVerbalFavouritesModal.bind(null, true)}
                      >
                        {t("containers.settings.editVerbalFavourites")}
                      </StyledButton>
                    </Box>
                  </Flex>
                </>
              ) : null}
              {activeTab === TabValue.goaltemplate ? (
                <GoalTemplates myprofile={data?.myprofile.data} />
              ) : null}
              {activeTab === TabValue.activitytemplate ? (
                <ActivityTemplates myprofile={data?.myprofile.data} />
              ) : null}
            </Flex>

            {showNumericFavouritesModal && (
              <EditNumericAssessmentSettingModal
                open={showNumericFavouritesModal}
                onModalClosed={onFavouritesModalClosed}
                isFavouriteManager={true}
              />
            )}

            {showVerbalFavouritesModal && (
              <EditVerbalAssessmentSettingModal
                open={showVerbalFavouritesModal}
                onModalClosed={onFavouritesModalClosed}
                isFavouriteManager={true}
              />
            )}
          </>
        );
      }}
    </Queries>
  );
};

TeacherSettings.navigationOptions = async ({
  match,
  apolloClient,
  t,
}: {
  match: any;
  apolloClient: ApolloClient<any>;
  t: any;
}) => {
  const { data } = await apolloClient.query<GetTeacherProfile>({
    query: GET_TEACHERPROFILE,
  });

  const me = data && data.myprofile && data.myprofile.data;

  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "person",
        label: me ? `${me.givenName} ${me.familyName}` : "",
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default TeacherSettings;
