import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  CreateOwnGoals,
  CreateOwnGoalsVariables,
} from "./types/CreateOwnGoals";

import {
  UpdateOwnGoals,
  UpdateOwnGoalsVariables,
} from "./types/UpdateOwnGoals";

import {
  GetOwnGoal,
  GetOwnGoal_survey_data as Survey,
  GetOwnGoalVariables,
} from "./types/GetOwnGoal";

import {
  OwnGoalSurveyCreateInput,
  OwnGoalSurveyUpdateInput,
} from "typings/graphql-global-types";

export const GET_OWNGOALS = gql`
  query GetEditOwnGoalsSurveyGroup {
    ownGoalsSurveyGroup {
      data {
        id
        name
        surveys {
          id
          name
          createdAt
          surveyQuestions {
            id
            name
            description
            type
            scheduledDate
            survey {
              id
              name
            }
            surveyQuestionAnswer {
              id
              numeric
              verbalText
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_OWN_GOALS = gql`
  mutation UpdateOwnGoals($input: OwnGoalSurveyUpdateInput!) {
    updateOwnGoalSurvey(input: $input) {
      data {
        id
        name
        order
        createdAt
        surveyQuestions {
          id
          name
          description
          type
          scheduledDate
        }
      }
    }
  }
`;

export const CREATE_OWN_GOALS = gql`
  mutation CreateOwnGoals($input: OwnGoalSurveyCreateInput!) {
    createOwnGoalSurvey(input: $input) {
      data {
        id
        name
        order
        createdAt
        surveyQuestions {
          id
          name
          description
          type
          scheduledDate
        }
      }
    }
  }
`;

export const GET_OWNGOAL = gql`
  query GetOwnGoal($filters: IdInputType!) {
    survey(filters: $filters) {
      data {
        id
        name
        order
        createdAt
        surveyQuestions {
          id
          name
          description
          type
          scheduledDate
          surveyQuestionAnswer {
            id
            numeric
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  ownGoal: {
    loading: boolean;
    data: Survey | null;
  };
  create: (
    input: OwnGoalSurveyCreateInput,
  ) => Promise<SingleExecutionResult<CreateOwnGoals>>;
  update: (
    input: OwnGoalSurveyUpdateInput,
  ) => Promise<SingleExecutionResult<UpdateOwnGoals>>;
}

interface QueriesProps {
  surveyId?: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, surveyId }) => {
  const [
    getOwnGoal,
    { loading: loadingOwnGoal, data: dataOwnGoal },
  ] = useLazyQuery<GetOwnGoal, GetOwnGoalVariables>(GET_OWNGOAL);

  useEffect(() => {
    const fetchData = async () => {
      if (surveyId) {
        await getOwnGoal({
          variables: {
            filters: {
              id: surveyId,
            },
          },
        });
      }
    };
    fetchData();
  }, [surveyId, getOwnGoal]);

  const [createOwnGoal] = useMutation<CreateOwnGoals, CreateOwnGoalsVariables>(
    CREATE_OWN_GOALS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_OWNGOALS,
        },
      ],
    },
  );

  const [updateOwnGoal] = useMutation<UpdateOwnGoals, UpdateOwnGoalsVariables>(
    UPDATE_OWN_GOALS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_OWNGOALS,
        },
      ],
    },
  );

  const create = (input: OwnGoalSurveyCreateInput) =>
    createOwnGoal({ variables: { input } });

  const update = (input: OwnGoalSurveyUpdateInput) =>
    updateOwnGoal({ variables: { input } });

  return children({
    ownGoal: {
      loading: loadingOwnGoal,
      data: dataOwnGoal ? dataOwnGoal.survey.data : null,
    },
    create,
    update,
  });
};
