import React from "react";
import { useTranslation } from "react-i18next";

import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";
import styled from "styled-components";

import { Flex, Text } from "components/Atoms";
import { Modal } from "components/Molecules";

const StyledNotificationImportantOutlined = styled(
  NotificationImportantOutlined,
)`
  color: ${props => props.theme.palette.primary.dark};
`;

interface Props {
  onClose: (result: boolean) => void;
}

export const RemoveFavouriteAssessmentSettingConfirmationModal: React.FC<Props> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      primaryAction={{
        text: t("globals.remove"),
        onClick: () => onClose(true),
      }}
      secondaryAction={{
        text: t("globals.cancel"),
        onClick: () => onClose(false),
      }}
      open={true}
      headingAction={<StyledNotificationImportantOutlined />}
      title={t("globals.confirmationModal.title")}
      height="auto"
    >
      <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
        <Text>
          {t(
            "components.organisms.removeFavouriteAssessmentSettingConfirmationModal.confirmRemoveFavourite",
          )}
        </Text>
      </Flex>
    </Modal>
  );
};
