import * as React from "react";
import styled from "styled-components";
import { darken } from "@material-ui/core/styles";
import { pathOr } from "ramda";
import { useTranslation } from "react-i18next";
import firebase from "services/firebase";
import { checkLogin } from "../../../services/apollo";

import { Button, Box } from "../../Atoms";

import GoogleLogo from "./g-normal.png";

const GoogleButton = styled(Button)`
  justify-content: flex-start;
  padding: 2px;
  border-radius: 2px;
  background-color: ${props =>
    pathOr("inherit", "theme.custom.googleButtonBg".split("."), props)};
  color: ${props =>
    pathOr("inherit", "theme.custom.googleButtonColor".split("."), props)};

  :hover {
    background-color: ${props =>
      darken(
        pathOr("#fff", "theme.custom.googleButtonBg".split("."), props),
        0.15,
      )};
  }
`;

export interface GoogleLoginButtonProps {
  onLoading: (state: boolean) => void;
  onSuccess: () => void;
  onError: (error?: firebase.auth.Error) => void;
  disabled?: boolean;
}

export const GoogleLoginButton: React.FunctionComponent<GoogleLoginButtonProps> = ({
  onLoading,
  onError,
  onSuccess,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  //const { analytics } = useAnalytics();
  const loginWithGoogle = async () => {
    onLoading(true);
    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(googleProvider);
      onLoading(false);
      if (result.user) {
        await checkLogin(result.user, i18n);
        const method =
          result && result.credential && result.credential.signInMethod;
        firebase.analytics().logEvent("login" as any, { method });
        onSuccess();
      }
    } catch (error) {
      onError(error as firebase.auth.Error);
      onLoading(false);
    }
  };

  return (
    <GoogleButton
      size="small"
      variant="contained"
      color="secondary"
      aria-label="Google login"
      data-cy="login-button-google"
      onClick={loginWithGoogle}
      disabled={disabled}
      fullWidth
    >
      <img alt="Google" src={GoogleLogo} />
      <Box flex={1} textAlign="center">
        {t("components.molecules.googleLoginButton.googleLogin")}
      </Box>
    </GoogleButton>
  );
};
