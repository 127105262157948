import React from "react";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import { useTranslation } from "react-i18next";

import { Flex, Box, Tooltip } from "components/Atoms";

import { Cell, StyledInputLabel, TimelineItem } from "./index";

import {
  VerbalAssessmentActiveIcon,
  VerbalAssessmentInactiveIcon,
} from "components/Atoms/Icons";

import { QuestionType } from "typings/graphql-global-types";

interface Props {
  timelineItems: TimelineItem[];
  width: string[];
  onClick: (question: TimelineItem) => void;
}
// ["10%", "15%", "5%"];
const CommentsColumn = ({ timelineItems, width, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}>
        <Hidden xsDown>
          <Flex alignItems="center" height="100%">
            <StyledInputLabel>
              {t("containers.ownGoals.surveyForm.comments")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>

      {timelineItems.map((q, i) => (
        <Cell
          key={`comments-${q.id}`}
          py={1}
          pl={2}
          pr={1}
          alternateColor={q.type !== QuestionType.numeric}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex
            alignItems="flex-start"
            justifyContent={["center", "flex-start"]}
          >
            {q.type === QuestionType.numeric && (
              <Tooltip
                title={
                  (q.surveyQuestionAnswer?.comments || []).length > 0
                    ? t("containers.ownGoals.surveyForm.openComments")
                    : t("containers.ownGoals.surveyForm.noComments")
                }
                arrow
              >
                <Box>
                  {(q.surveyQuestionAnswer?.comments || []).length > 0 ? (
                    <IconButton
                      aria-label="survey comments button"
                      onClick={() => onClick(q)}
                      data-cy="show-comments"
                    >
                      <Badge
                        badgeContent={
                          (q.surveyQuestionAnswer?.comments || []).filter(
                            c => !c.read,
                          ).length
                        }
                        color="primary"
                      >
                        <VerbalAssessmentActiveIcon color="primary" />
                      </Badge>
                    </IconButton>
                  ) : (
                    <IconButton disabled>
                      <VerbalAssessmentInactiveIcon />
                    </IconButton>
                  )}
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default CommentsColumn;
