import React, { useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { FormikProps } from "formik";
import Hidden from "@material-ui/core/Hidden";
import { SingleExecutionResult } from "@apollo/client";

import {
  GoalIcon,
  Flex,
  Box,
  InputLabel,
  Input,
  NumericAssessmentInput,
  Tooltip,
  Text,
} from "components/Atoms";

import { EGoalsLinkedButton } from "components/Atoms/EGoalsLinkedButton";

import {
  AssessmentValueScale3,
  AssessmentValueScale5,
} from "components/Atoms/NumericAssessmentInput";

import { EGoalInfoModal } from "components/Organisms/EGoalInfoModal";

import useBeforeUnload from "components/Hooks/use-before-unload";

import EmojiPicker from "components/Atoms/EmojiPicker";

import {
  AssessmentType,
  NumericAssessmentInput as NumericAssessmentInputProp,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

import { EditAssessmentUpdateAssessmentForm } from "../Queries/types/EditAssessmentUpdateAssessmentForm";

import {
  EditAssessmentGetUserActivity_activity_data as Activity,
  EditAssessmentGetUserActivity_activity_data_educationalPackageGoals as Goal,
  EditAssessmentGetUserActivity_activity_data_learningAchievements as LearningAchievement,
  EditAssessmentGetUserActivity_activity_data_learningAchievements_numericAssessments as NumericAssessment,
} from "../Queries/types/EditAssessmentGetUserActivity";

const Cell = styled(Box)<any>`
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  border-bottom: ${props =>
    props.divider && `1px solid ${props.theme.palette.grey[100]}`};

  ${props => props.theme.breakpoints.down("sm")} {
    ${props => (props.hideMobile ? "display: none" : "")};
    border-bottom: ${props =>
      props.showBorderBottomOnMobile
        ? `1px solid ${props.theme.palette.grey[100]}`
        : "none"};
  }
`;

const VerbalHelperText = styled(Text)`
  white-space: pre-wrap;
`;

interface NumericAssessmentProp
  extends Omit<NumericAssessmentInputProp, "value"> {
  value: number | null;
  name: string;
}
export interface FormValues {
  numericAssessments: NumericAssessmentProp[];
  verbalAssessment: string;
}

interface Props {
  type: AssessmentType;
  achievement?: LearningAchievement;
  activity: Activity;
  updateAssessment: (
    numericAssessments: NumericAssessmentInputProp[],
    verbalAssessment: VerbalAssessmentInput,
  ) => Promise<SingleExecutionResult<EditAssessmentUpdateAssessmentForm>>;
}

const StyledForm = styled.form`
  position: relative;
  //width: 100%;
  //display: flex;
  //flex-direction: column;
`;

const StyledInputLabel = styled(InputLabel)`
  color: #000;
  font-size: 1.25rem;
  font-weight: bold;
  transform: translate(0, 1.5px);
`;

const VerbalInputLabel = styled(StyledInputLabel)`
  position: inherit;
  margin-top: 0;
`;
const StyledInput = styled(Input)`
  min-width: 250px;
  width: 100%;
  max-width: 450px;
  textarea {
    background-color: #fff;
    line-height: 1.6rem;
  }
`;

const EmojiButtonBox = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 0px;
`;

const EmojiInputBox = styled(Box)`
  position: relative;
`;

export function getAssessmentValue(a?: NumericAssessment | null) {
  if (a) return a.value;
  return null;
}

export function getNumericAssessmentsInGoalOrder(
  numericAssessments: NumericAssessment[],
  goals: Goal[],
) {
  return goals.map(({ id, name }) => ({
    educationalPackageGoalId: id,
    name,
    value: getAssessmentValue(
      numericAssessments.find(ass => ass.educationalPackageGoalId === id),
    ),
  }));
}

interface AssessmentFormProps extends FormikProps<FormValues> {
  type: AssessmentType;
  verbalAssessmentHelperText: string | null;
  goals: Goal[];
  dense?: boolean;
  rootContainer?: HTMLElement | null;
  scrollContainer?: HTMLElement | null;
}

const getAssessmentScale = (
  goals: Goal[] | null,
  goalId: string,
): number | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting) {
    return g.numericAssessmentSetting.scale;
  }
  return null;
};

const getAssessmentText = (
  goals: Goal[] | null,
  goalId: string,
  type: AssessmentType,
): string[] | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting && type === AssessmentType.self) {
    return g.numericAssessmentSetting.learnerScaleTexts;
  }
  if (g && g.numericAssessmentSetting && type === AssessmentType.teacher) {
    return g.numericAssessmentSetting.teacherScaleTexts;
  }
  return null;
};

export const AssessmentForm: React.FC<AssessmentFormProps> = ({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  handleChange,
  setFieldValue,
  isValid,
  isSubmitting,
  type,
  verbalAssessmentHelperText,
  goals,
}) => {
  const { t } = useTranslation();

  const verbalInputRef = useRef();
  const [showEGoalInfoModal, setShowEGoalInfoModal] = useState<boolean>();
  const [selectedEGoalIds, setSelectedEGoalIds] = useState<string[]>([]);

  const verbalAssessmentHelperTooltipText =
    type === AssessmentType.teacher
      ? t("components.organisms.assessmentForm.teacherVerbalAssessmentTooltip")
      : verbalAssessmentHelperText
      ? verbalAssessmentHelperText
      : t("globals.verbalAssessment.learnerHelperText");

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const showEGoalInfo = (eGoalIds: string[]) => {
    setSelectedEGoalIds(eGoalIds);
    setShowEGoalInfoModal(true);
  };

  const onAssessmentChanged = (
    index: number,
    value: AssessmentValueScale3 | AssessmentValueScale5 | null,
  ) => {
    setFieldValue(`numericAssessments.${index}.value`, value);
    setFieldTouched(`numericAssessments`, true, false);
  };

  return (
    <>
      <StyledForm>
        {goals.length > 0 && (
          <Flex mt={1} flex={1} flexWrap="wrap">
            <Cell width={["70%", "50%", "40%"]} order={1} flexGrow={1}>
              <Flex alignItems="center">
                <StyledInputLabel>
                  {t("components.organisms.assessmentForm.goals")}
                </StyledInputLabel>
              </Flex>
            </Cell>
            {goals.map((g, i) => (
              <Cell
                key={`goalname-${g.id}`}
                py={2}
                divider={i < goals.length - 1}
                width={["70%", "50%", "40%"]}
                order={i + 2}
                flexGrow={1}
              >
                <Flex alignItems="flex-start" pr={[0, 0, 2]}>
                  <Flex alignItems="center" pt={0.5} mr={2} height={"54px"}>
                    <GoalIcon tooltip={g.name} index={i + 1} />
                  </Flex>
                  <Flex pt={0.5} minHeight="54px" alignItems="center">
                    <Text>{g.name}</Text>
                  </Flex>
                </Flex>
              </Cell>
            ))}

            <Cell width={["30%", "50%", "20%"]} order={1} flexGrow={1}>
              <Hidden xsDown>
                <Flex alignItems="center">
                  <StyledInputLabel>
                    {t("components.organisms.assessmentForm.opsLinking")}
                  </StyledInputLabel>
                </Flex>
              </Hidden>
            </Cell>

            {goals.map((g, i) => (
              <Cell
                key={`ops-${g.id}`}
                py={2}
                divider={i < goals.length - 1}
                width={["30%", "50%", "20%"]}
                order={i + 2}
                flexGrow={1}
              >
                <Flex
                  alignItems="flex-start"
                  justifyContent={["center", "flex-start"]}
                >
                  <Tooltip
                    title={
                      g.eGoals.length > 0
                        ? t("components.organisms.assessmentForm.linkedTooltip")
                        : t(
                            "components.organisms.assessmentForm.notLinkedTooltip",
                          )
                    }
                    arrow
                  >
                    <Box>
                      <EGoalsLinkedButton
                        eGoalCount={g.eGoals.length}
                        onClick={() => showEGoalInfo(g.eGoals.map(e => e.id))}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Cell>
            ))}

            <Cell hideMobile width={["40%"]} order={1} flexGrow={1}>
              <Flex justifyContent="space-between" alignItems="center">
                <StyledInputLabel htmlFor="numericAssessments">
                  {t("components.organisms.assessmentForm.assessmentScale")}
                </StyledInputLabel>
              </Flex>
            </Cell>
            {goals.map((g, i) => {
              const assessment = values.numericAssessments.find(
                ns => ns.educationalPackageGoalId === g.id,
              );
              if (!assessment) return null;
              return (
                <Cell
                  key={`input-${g.id}`}
                  py={2}
                  showBorderBottomOnMobile
                  divider={i < goals.length - 1}
                  width={["100%", "100%", "40%"]}
                  order={i + 2}
                  flexGrow={1}
                >
                  <Flex
                    pl={["47px", "47px", 0]}
                    pr={[2.5, 2.5, 0]}
                    alignItems="center"
                  >
                    <NumericAssessmentInput
                      type={type}
                      scale={getAssessmentScale(
                        goals,
                        assessment.educationalPackageGoalId,
                      )}
                      scaleTexts={getAssessmentText(
                        goals,
                        assessment.educationalPackageGoalId,
                        type,
                      )}
                      name={`numericAssessments.${i}.value`}
                      key={assessment.educationalPackageGoalId}
                      value={assessment.value ? assessment.value : null}
                      onChange={value => onAssessmentChanged(i, value)}
                    ></NumericAssessmentInput>
                  </Flex>
                </Cell>
              );
            })}
          </Flex>
        )}

        <FormControl>
          <Box mt={1}>
            <VerbalInputLabel htmlFor="verbalAssessment">
              {type === AssessmentType.teacher &&
                t(
                  "components.organisms.assessmentForm.verbalAssessmentTeacher",
                )}
              {type === AssessmentType.self &&
                t("components.organisms.assessmentForm.verbalAssessmentSelf")}
            </VerbalInputLabel>
          </Box>
          <Flex flexWrap="wrap">
            <EmojiInputBox
              flex={1}
              minWidth="250px"
              width="100px"
              maxWidth="450px"
            >
              <StyledInput
                inputRef={verbalInputRef}
                id="verbalAssessment"
                name="verbalAssessment"
                placeholder={t(
                  "components.organisms.assessmentForm.verbalAssessmentPlaceholder",
                )}
                multiline
                rows={3}
                helperText={
                  touched.verbalAssessment
                    ? (errors.verbalAssessment as string)
                    : ""
                }
                error={
                  touched.verbalAssessment && Boolean(errors.verbalAssessment)
                }
                value={values.verbalAssessment}
                onChange={change.bind(null, "verbalAssessment")}
                fullWidth
              />
              <EmojiButtonBox>
                <EmojiPicker
                  zIndex={1300}
                  onSelect={e => {
                    const start = (verbalInputRef.current! as HTMLTextAreaElement)
                      .selectionStart;
                    const end = (verbalInputRef.current! as HTMLTextAreaElement)
                      .selectionEnd;

                    let newText =
                      values.verbalAssessment.substring(0, start) +
                      e.native +
                      values.verbalAssessment.substring(end);

                    setFieldValue(`verbalAssessment`, newText);
                  }}
                />
              </EmojiButtonBox>
            </EmojiInputBox>
            <Box pt={1} ml={[1, 1, 2]} maxWidth={["80%", "80%", "40%"]}>
              <VerbalHelperText>
                {verbalAssessmentHelperTooltipText}
              </VerbalHelperText>
            </Box>
          </Flex>
        </FormControl>
      </StyledForm>
      {showEGoalInfoModal && selectedEGoalIds && (
        <EGoalInfoModal
          eGoalIds={selectedEGoalIds}
          onModalClosed={() => setShowEGoalInfoModal(false)}
        />
      )}
    </>
  );
};
