import React, { useCallback } from "react";
import { head, pipe, defaultTo, prop } from "ramda";
import styled from "styled-components";
import { EDUCATIONALPACKAGE, getRoute } from "routes";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { areEqual } from "utils/areEqual";

import { DropdownOptionItem } from "components/Molecules/DropdownMenu";

import { GetActivitiesTeacher_allActivities_data as Activity } from "../ActivityListQueries/types/GetActivitiesTeacher";

import { GetActivitiesTeacher_educationalPackage_data as EducationalPackage } from "../ActivityListQueries/types/GetActivitiesTeacher";

import { CardGroup } from "components/Contexts/CardGroup";

import { ActivityCard } from "../ActivityCard";
import { CardHeader } from "components/Molecules/Card";

import { Box, Flex, Text } from "components/Atoms";

const DraggingBox = styled(Box)<any>``;

const StyledActivityCard = styled(props => <ActivityCard {...props} />)`
  ${CardHeader as any} {
    cursor: grab;
  }
`;

const MemoRow = React.memo(function Row(props: {
  activity: Activity;
  animateOpening: boolean;
  educationalPackage: EducationalPackage;
  onContextMenuChanged: (activityId: string, o: DropdownOptionItem) => void;
  onSelfAssessmentChanged: (activityId: string, value: boolean) => void;
  index: number;
}) {
  const {
    activity,
    educationalPackage,
    onContextMenuChanged,
    onSelfAssessmentChanged,
    index,
    animateOpening,
  } = props;

  const a = activity;
  return (
    <Draggable draggableId={a.id} index={index}>
      {provided => (
        <DraggingBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          py={2}
        >
          <StyledActivityCard
            activity={a}
            animateOpening={animateOpening}
            classroomImported={educationalPackage.importedPackage}
            onSelfAssessmentChanged={(state: boolean) =>
              onSelfAssessmentChanged(a.id, state)
            }
            hideContextMenu={educationalPackage.importedPackage}
            openUrl={`${getRoute(EDUCATIONALPACKAGE, {
              educationalPackageId: educationalPackage.id,
              tab: "assessments",
            })}?activityId=${a.id}`}
            onContextMenuChanged={(o: DropdownOptionItem) =>
              onContextMenuChanged(a.id, o)
            }
          />
        </DraggingBox>
      )}
    </Draggable>
  );
},
areEqual);

interface Props {
  data: Activity[];
  educationalPackageData: EducationalPackage;
  setOrder: (activityId: string, to: number) => void;
  onContextMenuChanged: (activityId: string, o: DropdownOptionItem) => void;
  onSelfAssessmentChanged: (activityId: string, value: boolean) => void;
}

const getListStyle = (isDraggingOver: boolean) => ({
  width: "100%",
});

const HeaderBox = styled(Flex)`
  background-color: #ebebec;
  border-radius: 24px;
  height: 60px;
`;

const DraggingItem: React.FC<{ label: string }> = ({ label }) => (
  <HeaderBox alignItems="center" px={2.5} py={0.5}>
    <Text variant="h3">{label}</Text>
  </HeaderBox>
);

export const DraggableList: React.FC<Props> = ({
  data,
  educationalPackageData,
  setOrder,
  onContextMenuChanged,
  onSelfAssessmentChanged,
}) => {
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        setOrder(result.draggableId, result.destination.index);
      }
    },
    [setOrder],
  );

  return (
    <CardGroup
      defaultOpen={pipe(head, prop<any, any>("id"), defaultTo(""))(data)}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided, snapshot, rubric) => (
            <DraggingBox
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <DraggingItem label={data[rubric.source.index].name} />
            </DraggingBox>
          )}
        >
          {(provided, droppableSnapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
            >
              {data.map((a: Activity, i: number) => (
                <MemoRow
                  key={a.id}
                  activity={a}
                  index={i}
                  animateOpening={data.length < 100}
                  educationalPackage={educationalPackageData}
                  onContextMenuChanged={onContextMenuChanged}
                  onSelfAssessmentChanged={onSelfAssessmentChanged}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </CardGroup>
  );
};
