import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GetSchoolsPickerModal } from "./types/GetSchoolsPickerModal";
import {
  GetSchoolTemplatesPickerModal,
  GetSchoolTemplatesPickerModalVariables,
} from "./types/GetSchoolTemplatesPickerModal";

import { GoalTemplateFolderType } from "typings/graphql-global-types";

export const GET_SCHOOLS = gql`
  query GetSchoolsPickerModal {
    allSchools {
      data {
        id
        name
      }
    }
  }
`;

const GET_SCHOOL_TEMPLATES = gql`
  query GetSchoolTemplatesPickerModal(
    $filters: GoalTemplateListFiltersInputType!
  ) {
    allGoalTemplateFoldersBySchool(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        parent {
          id
        }
        goalTemplates {
          id
          text
          user {
            id
          }
          eGoals {
            id
          }
        }
        childrens {
          id
          name
          user {
            id
          }
          parent {
            id
          }
          goalTemplates {
            id
            text
            user {
              id
            }
            eGoals {
              id
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  schools: {
    loading: boolean;
    data: GetSchoolsPickerModal | undefined;
  };
  goalTemplates: {
    loading: boolean;
    data: GetSchoolTemplatesPickerModal | undefined;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
  schoolId?: string;
  type: GoalTemplateFolderType;
}

export const Queries: React.FC<QueriesProps> = ({
  schoolId,
  type,
  children,
}) => {
  const { loading, data } = useQuery<GetSchoolsPickerModal>(GET_SCHOOLS);

  const [
    getSchoolTemplates,
    { loading: templatesLoading, data: templatesData },
  ] = useLazyQuery<
    GetSchoolTemplatesPickerModal,
    GetSchoolTemplatesPickerModalVariables
  >(GET_SCHOOL_TEMPLATES);

  useEffect(() => {
    if (schoolId) {
      getSchoolTemplates({
        variables: {
          filters: {
            schoolId,
            type,
          },
        },
      });
    }
  }, [schoolId, type, getSchoolTemplates]);

  return children({
    schools: {
      loading,
      data,
    },
    goalTemplates: {
      loading: templatesLoading,
      data: templatesData,
    },
  });
};
