import React from "react";

import { Queries } from "./Queries";
import {
  FileUploadModal,
  GoogleDrivePickerModal,
  AddLinkModal,
} from "components/Organisms";
import { FileInputType } from "typings/graphql-global-types";

export type MaterialModalType = "file" | "drive" | "link";

interface Props {
  activityId: string;
  open: boolean;
  type: MaterialModalType;
  onModalClosed: (result: boolean) => void;
}

export const MaterialUploadModal: React.FC<Props> = ({
  activityId,
  open,
  type,
  onModalClosed,
}) => {
  return (
    <Queries activityId={activityId}>
      {({ addLearningAchivementMaterials: { mutation, loading } }) => {
        const onAdd = async (fileUploads: FileInputType[]) => {
          await mutation({ fileUploads });
          onModalClosed(true);
        };

        const onAddDriveFile = async (driveFile: {
          id: string;
          name: string;
        }) => {
          await mutation({
            driveFiles: [{ id: driveFile.id, name: driveFile.name }],
          });
          onModalClosed(true);
        };

        const onAddLink = async (links: string[]) => {
          await mutation({
            links,
          });
          onModalClosed(true);
        };

        return (
          <>
            {type === "file" && (
              <FileUploadModal
                activityId={activityId}
                open={open}
                onModalClosed={onModalClosed}
                onAdd={onAdd}
                loading={loading}
              ></FileUploadModal>
            )}
            {type === "drive" && (
              <GoogleDrivePickerModal
                activityId={activityId}
                open={open}
                onModalClosed={onModalClosed}
                onAdd={onAddDriveFile}
                loading={loading}
              ></GoogleDrivePickerModal>
            )}
            {type === "link" && (
              <AddLinkModal
                open={open}
                onModalClosed={onModalClosed}
                onAdd={onAddLink}
                loading={loading}
              />
            )}
          </>
        );
      }}
    </Queries>
  );
};
