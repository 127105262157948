import * as React from "react";
import * as firebase from "firebase/app";

interface PortFolioUser extends firebase.User {
  roles: string[];
}

interface UserContext {
  user: PortFolioUser | null;
  signOut: () => Promise<void>;
  loading?: boolean;
}

const userContext = React.createContext<UserContext>({
  user:
    firebase.auth && firebase.auth().currentUser
      ? { roles: [], ...(firebase.auth().currentUser as firebase.User) }
      : null,
  signOut: () => firebase.auth && firebase.auth().signOut(),
});

export default userContext;
