import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { MenuItem, Select, Text } from "components/Atoms";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GetDigipolkuSurveyGroup2 } from "../Queries/types/GetDigipolkuSurveyGroup2";

interface Props {
  gradeNumber?: number;
  onChange: (value: number) => void;
  getDigipolkuSurveyGroup: LazyQueryExecFunction<
    GetDigipolkuSurveyGroup2,
    OperationVariables
  >;
}

export const GradeNumberSelect: React.FC<Props> = ({
  gradeNumber,
  onChange,
  getDigipolkuSurveyGroup,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (gradeNumber) {
      getDigipolkuSurveyGroup({
        variables: { filters: { gradeNumber } },
      });
    }
  }, [gradeNumber, getDigipolkuSurveyGroup]);

  const selectOptions = useMemo(() => {
    const menuItems = [];
    for (let i = 1; i < 10; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}. {t("containers.digipolku.gradeNumberSelect.gradeNumber")}
        </MenuItem>,
      );
    }
    return menuItems;
  }, [t]);

  return (
    <>
      <Text>{t("containers.digipolku.gradeNumberSelect.title")}</Text>
      <Select
        onChange={e => onChange(e.target.value as number)}
        value={gradeNumber}
      >
        {selectOptions}
      </Select>
    </>
  );
};
