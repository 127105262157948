import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Flex, Box, ToWhomIcon, WhatIcon, Text } from "components/Atoms";

const StyledText = styled(Text)`
  color: #5858a1;
`;

const HideXsBox = styled(Box)`
  ${props => props.theme.breakpoints.down("xs")} {
    display: none;
  }
  ${props => props.theme.breakpoints.down("sm")} {
    @media (orientation: landscape) {
      display: none;
    }
  }
`;

const BottomBox = styled(Flex)`
  background: #fff;
  border-top-left-radius: ${props => props.theme.spacing(1.5)}px;
  border-top-right-radius: ${props => props.theme.spacing(1.5)}px;
  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
const StyledLink = styled(Link)``;

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BottomBox maxWidth={"900px"} mx={[0, 10, 20]} p={3}>
      <Flex mr={[0, 0, 2]} flex={1} pb={1}>
        <HideXsBox mr={2}>
          <WhatIcon height="80px" />
        </HideXsBox>
        <Flex flexDirection="column">
          <Box mb={[0.5]}>
            <StyledText variant="h3">
              {t("containers.login.whatTitle")}
            </StyledText>
          </Box>
          <Text>{t("containers.login.whatText")}</Text>
        </Flex>
      </Flex>
      <Flex ml={[0, 0, 2]} mt={[3, 0, 0]} flex={1} pb={1}>
        <HideXsBox mr={2}>
          <ToWhomIcon height="80px" />
        </HideXsBox>
        <Flex flexDirection="column">
          <Box mb={0.5}>
            <StyledText variant="h3">
              {t("containers.login.toWhomTitle")}
            </StyledText>
          </Box>
          <Text>{t("containers.login.toWhomText")}</Text>
          <Box mt={2}>
            <StyledLink to={"/terms"}>
              <Text>{t("containers.login.terms")}</Text>
            </StyledLink>
          </Box>
        </Flex>
      </Flex>
    </BottomBox>
  );
};
