import React from "react";

import { useTranslation } from "react-i18next";
import {
  Flex,
  Box,
  Text,
  GoalIcon,
  NumericAssessmentInput,
} from "components/Atoms";
import styled from "styled-components";

import { AssessmentType } from "typings/graphql-global-types";
import {
  getAssessmentScale,
  getAssessmentText,
  Goal,
} from "utils/scaleHelpers";

const DescriptionText = styled(Text)`
  white-space: pre-wrap;
`;

const StyledLabel = styled(Text)`
  color: ${props => props.theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 400;
`;

export interface AssessmentReadonlyProps {
  numericAssessments: Array<{
    value: number | null;
    name: string;
    goalId: string;
  }>;
  verbalAssessment: string;
  type: AssessmentType;
  goals: Goal[];
  modal?: boolean;
}

export const AssessmentReadonly: React.FC<AssessmentReadonlyProps> = ({
  numericAssessments,
  verbalAssessment,
  type,
  goals,
  modal,
}) => {
  const { t } = useTranslation();
  return (
    <Box py={modal ? 0 : 2}>
      <Flex flexDirection="column" justifyContent="center">
        <Flex flexWrap="wrap" pt={2}>
          <Box minWidth="300px">
            {modal ? (
              <Text variant="caption">
                {t("components.molecules.assessmentReadOnly.numericAssessment")}
              </Text>
            ) : (
              <StyledLabel>
                {t("components.molecules.assessmentReadOnly.numericAssessment")}
              </StyledLabel>
            )}
            <Flex mt={1} flexWrap="wrap">
              {numericAssessments.map((na, index: number) => (
                <Flex
                  key={index}
                  mr={index < numericAssessments.length - 1 ? 5 : 0}
                >
                  <Box pt={1.5} mr={1.5}>
                    <GoalIcon tooltip={na.name} index={index + 1} />
                  </Box>
                  <NumericAssessmentInput
                    type={type}
                    readonly
                    value={na.value}
                    scale={getAssessmentScale(goals, na.goalId)}
                    scaleTexts={getAssessmentText(goals, na.goalId, type)}
                  />
                </Flex>
              ))}
            </Flex>
          </Box>
          <Box
            maxWidth={["100%", "800px"]}
            ml={modal ? 0 : [0, 0, 0, 0, 6]}
            mt={modal ? 1 : [3, 3, 3, 3, 0]}
            flex={"auto"}
          >
            {modal ? (
              <Text variant="caption">
                {t("components.molecules.assessmentReadOnly.verbalAssessment")}
              </Text>
            ) : (
              <StyledLabel>
                {t("components.molecules.assessmentReadOnly.verbalAssessment")}
              </StyledLabel>
            )}
            <Box mt={2}>
              <DescriptionText>{verbalAssessment}</DescriptionText>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
