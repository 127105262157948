import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const VerbalAssessmentInactiveIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 25 26">
    <path
      d="M22.718 0H2.284a2.283 2.283 0 00-2.28 2.282v16.2a2.284 2.284 0 002.28 2.286h5.382v4.1a1 1 0 001 1h.638c.262-.003.513-.107.7-.291l4.78-4.811h7.933a2.284 2.284 0 002.282-2.282v-16.2c0-1.26-1.02-2.282-2.281-2.284z"
      fill="#DEDEDF"
    />
  </SvgIcon>
);

VerbalAssessmentInactiveIcon.displayName = "VerbalAssessmentInactiveIcon";
