import React from "react";

import styled from "styled-components";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";

import { SurveyAnswerCell } from "../SurveyAnswerCell";

import { Flex, Box } from "components/Atoms";

import { GoalIcon } from "components/Atoms/Icons";

import {
  GetOwnGoalSurveyGroupAnswers_ownGoalsSurveyGroup_data_surveys as Survey,
  GetOwnGoalSurveyGroupAnswers_ownGoalsSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswers_user as User,
  GetOwnGoalSurveyGroupAnswers_ownGoalsSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswers as SurveyQuestionAnswer,
  GetOwnGoalSurveyGroupAnswers_ownGoalsSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion,
} from "../Queries/types/GetOwnGoalSurveyGroupAnswers";

import { QuestionType } from "typings/graphql-global-types";

import { getAcademicYear } from "utils/dateHelpers";

import { ExtendedAssessmentType } from "../../index";

interface Props {
  survey: Survey;
  users: User[];
  onClickViewVerbalEvaluation: (
    survey: Survey,
    answer: SurveyQuestionAnswer,
    question: SurveyQuestion,
  ) => void;
  onClickEditComment: (surveyQuestionAnswerId: string) => void;
  gradingScale: number;
}

const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StickyStyledTableCell = styled(StyledTableCell)`
  position: sticky;
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 0px;
  }
  z-index: 1;
`;

const StickySecondaryTableCell = styled(StickyStyledTableCell)`
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 150px;
  }
`;

export const OwnGoalsTableRow: React.FC<Props> = ({
  survey,
  users,
  onClickViewVerbalEvaluation,
  onClickEditComment,
  gradingScale,
}) => {
  const { t } = useTranslation();
  const academicYear = getAcademicYear(new Date(survey.createdAt));
  const title = t("globals.ownGoalsSurveyTitle", {
    startYear: academicYear.startYear,
    endYear: academicYear.endYear,
    name: survey.name && survey.name.length && `: ${survey.name}`,
  });

  return (
    <TableRow key={survey.id}>
      <StickyStyledTableCell
        align="left"
        style={{
          minWidth: "150px",
          maxWidth: "150px",
          verticalAlign: "top",
        }}
      >
        <Box height="100%" pt={1}>
          {title}
        </Box>
      </StickyStyledTableCell>
      <StickySecondaryTableCell>
        <Flex
          py={0.5}
          height={"100%"}
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          {survey.surveyQuestions
            .filter(q => q.type === QuestionType.numeric)
            .map((q, i) => (
              <Box key={q.id} mt={i > 0 ? 1.5 : 0}>
                <GoalIcon tooltip={q.name} index={i + 1} />
                <Box mt={1.5} height="49px" />
              </Box>
            ))}
          {survey.surveyQuestions
            .filter(q => q.type === QuestionType.verbal)
            .map((q, i) => (
              <Box key={q.id} mt={1.5} height="49px" />
            ))}
        </Flex>
      </StickySecondaryTableCell>

      {users.map(user => (
        <StyledTableCell key={user.id}>
          <SurveyAnswerCell
            user={user}
            surveyType={ExtendedAssessmentType.ownGoals}
            questions={survey.surveyQuestions}
            onClickViewVerbalEvaluation={(answer, question) =>
              onClickViewVerbalEvaluation(survey, answer, question)
            }
            onClickEditComment={onClickEditComment}
            gradingScale={gradingScale}
          />
        </StyledTableCell>
      ))}
    </TableRow>
  );
};
