import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers as FormikActions, FormikProps } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import UserContext from "components/Contexts/UserContext";

import { SingleExecutionResult } from "@apollo/client";

import { AssessmentType } from "typings/graphql-global-types";

import { Box, Flex, Text, Loader } from "components/Atoms";
import { ActivityCard } from "../ActivityCard";
import { useSnackbar } from "components/Contexts/SnackbarContext";

import {
  getNumericAssessmentsInGoalOrder,
  FormValues,
  AssessmentForm,
} from "components/Organisms/AssessmentForm/Form";

import {
  GetEducationalPackageNewActivities_allActivities_data as Activity,
  GetEducationalPackageNewActivities_educationalPackage_data as EducationalPackage,
} from "../../../NewActivityListQueries/types/GetEducationalPackageNewActivities";

import { UpdateAssessmentFormActivityCard } from "../../../NewActivityListQueries/types/UpdateAssessmentFormActivityCard";

import {
  NumericAssessmentInput,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

const FullCardLoader = styled(Loader)`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #6c6c6cab;
`;

const CardItem = styled(Box)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

interface Props {
  activity: Activity;
  educationalPackageData: EducationalPackage | undefined;
  loading?: boolean;
  onSubmitActivityClick: (
    activityId: string,
    submitAssessment?: () => Promise<void>,
  ) => void;
  onSaveAssessment: (
    userId: string,
    activityId: string,
    numericAssessments: NumericAssessmentInput[],
    verbalAssessment: VerbalAssessmentInput,
  ) => Promise<SingleExecutionResult<UpdateAssessmentFormActivityCard>>;
}

function getNumericAssessments(values: FormValues): NumericAssessmentInput[] {
  return values.numericAssessments.map(a => ({
    educationalPackageGoalId: a.educationalPackageGoalId,
    value: a.value,
  })) as NumericAssessmentInput[];
}

export const LearnerActivityCard: React.FC<Props> = ({
  activity,
  loading,
  onSubmitActivityClick,
  onSaveAssessment,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const verbalAssessment =
    activity.learningAchievements[0] &&
    activity.learningAchievements[0].verbalAssessments.find(
      a => a.type === AssessmentType.self,
    );

  const numericAssessments =
    activity.learningAchievements[0] &&
    activity.learningAchievements[0].numericAssessments.filter(
      a => a.type === AssessmentType.self,
    );

  const initialValues = {
    numericAssessments: getNumericAssessmentsInGoalOrder(
      numericAssessments || [],
      activity.educationalPackageGoals || [],
    ),
    verbalAssessment:
      verbalAssessment && verbalAssessment.assessmentText
        ? verbalAssessment.assessmentText
        : "",
  };

  const onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>,
    hideSnackbar?: boolean,
  ) => {
    actions.setSubmitting(true);

    const result = await onSaveAssessment(
      user!.uid,
      activity.id,
      getNumericAssessments(values),
      { assessmentText: values.verbalAssessment },
    );

    actions.setSubmitting(false);
    actions.setTouched({});

    if (!hideSnackbar) {
      showSnackbar({
        id: result.data!.createOrPatchLearningAchievement.data!.id,
        type: "success",
        message: t(
          "containers.learnerEducationPackage.assessmentForm.saveSuccess",
        ),
      });
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        verbalAssessment: Yup.string().max(
          400,
          t("globals.validations.assessmentMax"),
        ),
      })}
      onSubmit={onFormSubmit}
      render={(props: FormikProps<FormValues>) => {
        const onCheckAssessmentAndSubmitActivityClick = (
          activityId: string,
        ) => {
          if ((activity.selfAssessment, Object.keys(props.touched).length)) {
            onSubmitActivityClick(
              activityId,
              async () => await onFormSubmit(props.values, { ...props }, true),
            );
          } else {
            onSubmitActivityClick(activityId);
          }
        };
        return (
          <ActivityCard
            activity={activity}
            height={activity.selfAssessment ? "800px" : undefined}
            primaryAction={{
              text: t("containers.learnerEducationPackage.returnActivity"),
              disabled: !(
                (activity.selfAssessment &&
                  activity.educationalPackageGoals &&
                  activity.learningAchievements &&
                  activity.learningAchievements[0] &&
                  activity.learningAchievements[0].numericAssessments &&
                  activity.educationalPackageGoals.length ===
                    activity.learningAchievements[0].numericAssessments
                      .length) ||
                props.isValid
              ),
              onClick: onCheckAssessmentAndSubmitActivityClick.bind(
                null,
                activity.id,
              ),
            }}
          >
            {loading && <FullCardLoader />}

            {activity.selfAssessment &&
              user &&
              activity.educationalPackageGoals &&
              activity.educationalPackageGoals.length > 0 && (
                <CardItem pl={2} pr={1} py={2}>
                  <Flex flexDirection="column" justifyContent="center">
                    <Text variant="h4" color="textPrimary">
                      {t(
                        "containers.learnerEducationPackage.assessmentForm.title",
                      )}
                    </Text>
                    <AssessmentForm
                      goals={activity && activity.educationalPackageGoals}
                      verbalAssessmentHelperText={
                        activity &&
                        activity.verbalAssessmentSetting &&
                        activity.verbalAssessmentSetting.learnerHelperText
                      }
                      {...props}
                      type={AssessmentType.self}
                    />
                  </Flex>
                </CardItem>
              )}
          </ActivityCard>
        );
      }}
    />
  );
};
