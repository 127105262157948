import * as React from "react";

import { default as CircularProgress } from "@material-ui/core/CircularProgress";

import { Flex } from "../Flex";

export type LoaderProps = {
  size?: string;
};

export const Loader: React.FunctionComponent<LoaderProps> = ({
  size,
  ...props
}) => (
  <Flex {...props} justifyContent="center" alignItems="center">
    <CircularProgress size={size} />
  </Flex>
);

Loader.displayName = "Loader";
