import React from "react";

import Hidden from "@material-ui/core/Hidden";

import { useTranslation } from "react-i18next";

import { Flex, Box } from "components/Atoms";

import { Cell, StyledInputLabel } from "./index";

import TextToSpeechAudioPlayer from "components/Molecules/TextToSpeechAudioPlayer";

import { GetDigipolkuSurveyGroup2_digipolkuSurveyGroup2_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup2";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
}

const ListenColumn = ({ numericQuestions, width }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}>
        <Hidden mdDown>
          <Flex alignItems="center" height="100%">
            <StyledInputLabel>
              {t("containers.digipolku.surveyForm.listen")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>

      {numericQuestions.map((q, i) => (
        <Cell
          key={`listen-${q.id}`}
          py={2}
          divider={i < numericQuestions.length - 1}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex
            height="100%"
            alignItems="flex-start"
            justifyContent={["center", "flex-start"]}
          >
            <Box>
              <TextToSpeechAudioPlayer text={q.name} />
            </Box>
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default ListenColumn;
