import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { GetAllGradeLevelGroupsAndSubjects } from "./types/GetAllGradeLevelGroupsAndSubjects";
import {
  GetEducationalPackageEGoals,
  GetEducationalPackageEGoalsVariables,
} from "./types/GetEducationalPackageEGoals";
import {
  GetSubjectGradeLevelGroups,
  GetSubjectGradeLevelGroupsVariables,
} from "./types/GetSubjectGradeLevelGroups";
import {
  GetSchoolSubjectsByEGoalIds,
  GetSchoolSubjectsByEGoalIdsVariables,
} from "./types/GetSchoolSubjectsByEGoalIds";

export const GET_EGOALS_FOR_EDUCATIONALPACKAGE = gql`
  query GetEducationalPackageEGoals($filters: IdInputType!) {
    allGradeLevelGroups {
      data {
        id
        name
      }
    }
    educationalPackage(filters: $filters) {
      data {
        id
        gradeLevel {
          id
          gradeLevelGroup {
            id
          }
        }
        schoolSubjects {
          id
          name
          schoolSubjectGradeLevelGroups {
            gradeLevelGroup {
              id
              name
            }
            eGoals {
              eGoal {
                id
                name
              }
              contentAreas {
                id
                name
                description
              }
              wideGoals {
                id
                name
                description
              }
              targetAreas {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_GRADELEVEL_GROUPS_AND_SUBJECTS = gql`
  query GetAllGradeLevelGroupsAndSubjects {
    allGradeLevelGroups {
      data {
        id
        name
      }
    }
    allSchoolSubjects {
      data {
        id
        name
        schoolSubjectGradeLevelGroups {
          gradeLevelGroup {
            id
          }
          eGoals {
            eGoal {
              id
            }
          }
        }
      }
    }
  }
`;

const GET_SUBJECT_GRADE_LEVEL_GROUPS = gql`
  query GetSubjectGradeLevelGroups($filters: IdInputType!) {
    findBySchoolSubject(filters: $filters) {
      data {
        id
        name
        schoolSubjectGradeLevelGroups {
          gradeLevelGroup {
            id
            gradeLevelGroupId
            name
          }
          eGoals {
            eGoal {
              id
              name
            }
            contentAreas {
              id
              name
              description
            }
            wideGoals {
              id
              name
              description
            }
            targetAreas {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_SCHOOL_SUBJECTS_BY_EGOAL_IDS = gql`
  query GetSchoolSubjectsByEGoalIds($filters: IdsInputType!) {
    findSchoolSubjectsByEGoalIds(filters: $filters) {
      data {
        id
        name
        schoolSubjectGradeLevelGroups {
          gradeLevelGroup {
            id
            gradeLevelGroupId
            name
          }
          eGoals {
            eGoal {
              id
              name
            }
            contentAreas {
              id
              name
              description
            }
            wideGoals {
              id
              name
              description
            }
            targetAreas {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  educationalPackage: {
    loading: boolean;
    data: GetEducationalPackageEGoals | undefined;
    refetch: any;
  };
  gradeLevelsAndSubjects: {
    loading: boolean;
    data: GetAllGradeLevelGroupsAndSubjects | undefined;
  };
  subjectGradeLevelGroups: {
    loading: boolean;
    data: GetSubjectGradeLevelGroups | undefined;
    fetch: (subjectId: string) => void;
  },
  subjectsByEGoalIds: {
    loading: boolean;
    data: GetSchoolSubjectsByEGoalIds | undefined;
    fetch: (eGoalIds: string[]) => void;
  }
  initLoading: boolean;
}

interface QueriesProps {
  children: RenderProp<Queries>;
  educationalPackageId?: string | null;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  educationalPackageId,
}) => {
  const [initLoading, setInitLoading] = useState(true);

  const [loadEGoals, { loading, /*error,*/ data, refetch }] = useLazyQuery<
    GetEducationalPackageEGoals,
    GetEducationalPackageEGoalsVariables
  >(GET_EGOALS_FOR_EDUCATIONALPACKAGE);

  const [
    loadGradeLevelsAndSubjects,
    { loading: loadingGrade, data: dataGrade }
  ] = useLazyQuery<GetAllGradeLevelGroupsAndSubjects
  >(GET_GRADELEVEL_GROUPS_AND_SUBJECTS);

  useEffect(() => {
    const load = async () => {
      if (initLoading) {
        if (educationalPackageId) {
          await loadEGoals({
            variables: {
              filters: {
                id: educationalPackageId,
              },
            },
          });
        } else {
          await loadGradeLevelsAndSubjects();
        }
        setInitLoading(false);
      }
    }
    load();
  }, [educationalPackageId, loadEGoals, loadGradeLevelsAndSubjects, initLoading]);

  const [
    loadSubjectGradeLevels,
    { loading: loadingSubject, data: dataSubject }
  ] = useLazyQuery<
    GetSubjectGradeLevelGroups,
    GetSubjectGradeLevelGroupsVariables
  >(GET_SUBJECT_GRADE_LEVEL_GROUPS);

  const getSubjectGradeLevels = useCallback((subjectId: string) => {
    loadSubjectGradeLevels({
      variables: {
        filters: {
          id: subjectId,
        },
      },
    });
  }, [loadSubjectGradeLevels]);

  const [
    loadSubjectsByEGoalIds,
    { loading: loadingSubjectsByEGoalIds, data: dataSubjectsByEGoalIds }
  ] = useLazyQuery<
    GetSchoolSubjectsByEGoalIds,
    GetSchoolSubjectsByEGoalIdsVariables
  >(GET_SCHOOL_SUBJECTS_BY_EGOAL_IDS);

  const getSubjectsByEGoalIds = useCallback((eGoalIds: string[]) => {
    loadSubjectsByEGoalIds({
      variables: {
        filters: {
          ids: eGoalIds,
        },
      },
    });
  }, [loadSubjectsByEGoalIds]);

  return children({
    educationalPackage: { loading, data, refetch },
    gradeLevelsAndSubjects: {
      loading: loadingGrade,
      data: dataGrade,
    },
    subjectGradeLevelGroups: {
      loading: loadingSubject,
      data: dataSubject,
      fetch: getSubjectGradeLevels,
    },
    subjectsByEGoalIds: {
      loading: loadingSubjectsByEGoalIds,
      data: dataSubjectsByEGoalIds,
      fetch: getSubjectsByEGoalIds,
    },
    initLoading,
  });
};
