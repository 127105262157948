import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Flex, Box, ButtonLink } from "..";

export interface GoButtonProps {
  path: string;
  label: string;
  direction: "forward" | "back";
}

const Label = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GoButton: React.FC<GoButtonProps> = ({
  path,
  label,
  direction,
}) => {
  return (
    <ButtonLink data-cy={`go-button`} to={path} aria-label={direction}>
      <Flex alignItems="center" overflow="hidden">
        {direction === "back" && (
          <Box display="flex" mr={0.5} mt={-0.4}>
            <ChevronLeftIcon fontSize="large" />
          </Box>
        )}
        <Label overflow="hidden">{label}</Label>
        {direction === "forward" && (
          <Box display="flex" ml={0.5} mt={-0.4}>
            <ChevronRightIcon fontSize="large" />
          </Box>
        )}
      </Flex>
    </ButtonLink>
  );
};
