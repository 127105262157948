import * as React from "react";

import styled from "styled-components";

import {
  default as MuiTab,
  TabProps as MuiTabProps,
} from "@material-ui/core/Tab";

import {
  default as MuiTabs,
  TabsProps as MuiTabsProps,
  TabsTypeMap,
} from "@material-ui/core/Tabs";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export type TabProps = MuiTabProps & {
  component?: any;
  to?: any;
};

export const Tab: React.FC<TabProps> = styled((props: TabProps) => (
  <MuiTab {...props}></MuiTab>
))`
  max-width: 300px;
`;

Tab.displayName = "Tab";

export type TabsProps = MuiTabsProps;

export const Tabs: OverridableComponent<TabsTypeMap<TabsProps>> = styled(
  MuiTabs,
)``;
