import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const AchievementIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 26.534 23.181">
    <g fill="none" stroke="currentColor" strokeWidth="3">
      <path d="M7.497 21.681h11.539l5.77-10.09L19.036 1.5H7.497l-5.77 10.091z" />
      <path d="M11.151 15.01h4.019l2.01-3.515-2.01-3.515h-4.02l-2.01 3.515z" />
    </g>
  </SvgIcon>
);

AchievementIcon.displayName = "AchievementIcon";
