import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Modal } from "../../Molecules";

import { Form } from "./Form";
import { Queries } from "./Queries";

import { QuestionType } from "typings/graphql-global-types";

import { OwnGoalSurveyQuestionUpdateInput } from "typings/graphql-global-types";
export interface EditOwnGoalsModalProps {
  surveyId?: string;
  showModal: boolean;
  onModalClosed: (result: boolean) => void;
}

export const EditOwnGoalsModal: React.FunctionComponent<EditOwnGoalsModalProps> = ({
  surveyId,
  showModal,
  onModalClosed,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  const defaultQuestions: OwnGoalSurveyQuestionUpdateInput[] = [
    {
      name: "",
      type: QuestionType.numeric,
    },
    {
      name: t("components.organisms.editOwnGoals.strengths"),
      description: t("components.organisms.editOwnGoals.strengthsDescription"),
      type: QuestionType.verbal,
    },
    {
      name: t("components.organisms.editOwnGoals.howToAchieveGoals"),
      description: t(
        "components.organisms.editOwnGoals.howToAchieveGoalsDescription",
      ),
      type: QuestionType.verbal,
    },
  ];

  return (
    <Queries surveyId={surveyId}>
      {({ ownGoal: { loading, data }, create, update }) => {
        return (
          <Formik
            enableReinitialize={surveyId ? true : false}
            initialValues={{
              name: (data && data.name) || "",
              order: (data && data.order) || 0,
              createdAt: (data && data.createdAt) || new Date(),
              surveyQuestions:
                data && data.surveyQuestions
                  ? data.surveyQuestions.map(sq => ({
                      id: sq.id,
                      name: sq.name,
                      scheduledDate: sq.scheduledDate,
                      description: sq.description,
                      type: sq.type,
                    }))
                  : defaultQuestions,
            }}
            validationSchema={Yup.object().shape({
              surveyQuestions: Yup.array()
                .of(
                  Yup.object().shape({
                    name: Yup.string()
                      .required(t("globals.validations.nameRequired"))
                      .max(140, t("globals.validations.nameMax")),
                  }),
                )
                .min(surveyId ? 0 : 1),
              name: Yup.string().max(140, t("globals.validations.nameMax")),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const sortedSurveyQuestions = values.surveyQuestions.sort(
                (a, b) => {
                  if (b.type === QuestionType.verbal) return 0;
                  if (a.type === QuestionType.verbal) return 0;

                  if (!a.scheduledDate) return -1;
                  if (!b.scheduledDate) return 1;
                  return (
                    new Date(a.scheduledDate).valueOf() -
                    new Date(b.scheduledDate).valueOf()
                  );
                },
              );

              if (surveyId) {
                await update({
                  id: surveyId,
                  name: values.name,
                  order: values.order,
                  surveyQuestions: sortedSurveyQuestions,
                });
              } else {
                await create({
                  name: values.name,
                  order: values.order,
                  surveyQuestions: sortedSurveyQuestions,
                });
              }
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              return (
                <Modal
                  name="edit-owngoals"
                  width="800px"
                  height="570px"
                  loading={isSubmitting || loading}
                  primaryAction={{
                    text: t("globals.save"),
                    onClick: handleSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={{
                    text: t("globals.cancel"),
                    onClick: onCancel,
                  }}
                  open={showModal}
                  title={
                    surveyId
                      ? t("components.organisms.editOwnGoals.editTitle")
                      : t("components.organisms.editOwnGoals.addTitle")
                  }
                >
                  <Form
                    {...props}
                    questions={(data && data.surveyQuestions) || []}
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
