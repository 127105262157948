import { ApolloError, useMutation, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";

import {
  JoinEducationalPackageByCode,
  JoinEducationalPackageByCodeVariables,
} from "./types/JoinEducationalPackageByCode";

const JOIN_EDUCATIONAL_PACKAGE_BY_CODE = gql`
  mutation JoinEducationalPackageByCode(
    $input: EducationalPackageJoinByCodeInputType!
  ) {
    joinEducationalPackageByCode(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  joinByCode: {
    mutation: (
      options?:
        | MutationFunctionOptions<JoinEducationalPackageByCode, JoinEducationalPackageByCodeVariables>
        | undefined,
    ) => Promise<SingleExecutionResult<JoinEducationalPackageByCode>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const [joinByCode, { error: joinByCodeError }] = useMutation<
    JoinEducationalPackageByCode,
    JoinEducationalPackageByCodeVariables
  >(JOIN_EDUCATIONAL_PACKAGE_BY_CODE);

  return children({
    joinByCode: { mutation: joinByCode, error: joinByCodeError },
  });
};
