import { Button, Flex } from "components/Atoms";
import userContext from "components/Contexts/UserContext";
import { StyledLink } from "containers/Teacher/Settings/components/GoalTemplates";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Cell } from ".";

interface Props {
  teacherInstructionLink?: string;
  learningMaterialLink?: string;
  embedLink?: string;
  isAssessment?: boolean;
  toggleGoals: () => void;
}

const SurveyHeader = ({
  teacherInstructionLink,
  learningMaterialLink,
  embedLink,
  isAssessment,
  toggleGoals,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(userContext);
  return (
    <Flex pl={2} pr={1} pb={1} mt={2} flexDirection="column">
      {(user?.roles.includes("teacher") ||
        user?.roles.includes("superadmin")) && (
        <LinkButton
          url={teacherInstructionLink}
          title={t("containers.digipolku.surveyHeader.teacherInstructionLink")}
        />
      )}
      {learningMaterialLink && (
        <LinkButton
          url={learningMaterialLink}
          title={t("containers.digipolku.surveyHeader.learningMaterialLink")}
        />
      )}
      {embedLink && (
        <LinkButton
          url={embedLink}
          title={t("containers.digipolku.surveyHeader.learningMaterialLink")}
        />
      )}
      {!isAssessment && (
        <Flex alignItems="center" height="100%">
          <Cell>
            <Button onClick={toggleGoals}>
              {t("containers.digipolku.surveyHeader.learnerGoals")}
            </Button>
          </Cell>
        </Flex>
      )}
    </Flex>
  );
};

const LinkButton: React.FC<{ url?: string; title?: string }> = ({
  url,
  title,
}) => {
  if (!url) return null;
  return (
    <Flex alignItems="center" height="100%">
      <Cell>
        <StyledLink
          as={Button as any}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title ? title : url}
        </StyledLink>
      </Cell>
    </Flex>
  );
};

export default SurveyHeader;
