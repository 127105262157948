import * as React from "react";

import styled from "styled-components";

import MuiModal, { ModalProps as MuiModalProps } from "@material-ui/core/Modal";

export type ModalBaseProps = MuiModalProps;

export const ModalBase: React.FunctionComponent<ModalBaseProps> = styled(
  MuiModal,
)``;

ModalBase.displayName = "ModalBase";
