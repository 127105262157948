import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const OwnGoalsIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d="M16 .44L31.56 16 16 31.56.44 16 16 .44zM16 4L4 16l12 12 12-12L16 4zm0 7.76L20.24 16 16 20.24 11.76 16 16 11.76z" />
  </SvgIcon>
);

OwnGoalsIcon.displayName = "OwnGoalsIcon";
