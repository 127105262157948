import React, { useContext } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import UserContext from "components/Contexts/UserContext";
import { Modal } from "../../Molecules";

import { VerbalAssesssmentSettingForm } from "./components/Form";

import { Queries } from "./components/Queries";
import { useSnackbar } from "components/Contexts/SnackbarContext";

export interface EditVerbalAssessmentSettingModalProps {
  open: boolean;
  assessmentSettingId?: string | null;
  educationalPackageId?: string | null;
  onModalClosed: (
    result: boolean,
    verbalAssessmentSettingId: string | undefined | null,
    learnerHelperText: string | undefined | null,
  ) => void;
  isFavouriteManager?: boolean;
}

export const EditVerbalAssessmentSettingModal: React.FunctionComponent<EditVerbalAssessmentSettingModalProps> = ({
  onModalClosed,
  assessmentSettingId,
  educationalPackageId,
  open,
  isFavouriteManager,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { showSnackbar } = useSnackbar();
  const myId = user && user.uid;
  const onCancel = () => {
    onModalClosed(false, null, null);
  };

  return (
    <Queries
      assessmentSettingId={assessmentSettingId}
      educationalPackageId={educationalPackageId}
    >
      {({
        verbalAssessmentSettings: {
          loading,
          data,
          favouriteVerbalAssessmentSettings,
          popularVerbalAssessmentSettings,
        },
        verbalAssessmentSetting: { upsert },
      }) => {
        let setting = data && data;

        const favs = favouriteVerbalAssessmentSettings.sort((a, b) =>
          ((a && a.name) || "").localeCompare((b && b.name) || ""),
        );

        if (isFavouriteManager) {
          setting = favs.find(fvas => fvas.defaultSetting === true);
          if (!setting && favs.length) {
            setting = favs[0];
          }
        }

        const combinedFavs = [
          ...favs
            .filter(f => f.user.id === myId)
            .map(a => ({
              ...a,
              type: "myFavourite",
            })),
          ...favs
            .filter(f => f.user.id !== myId)
            .map(a => ({
              ...a,
              defaultSetting: false,
              type: "otherFavourite",
            })),
          ...popularVerbalAssessmentSettings
            .filter(p => !favs.find(f => f.id === p.id))
            .map(a => ({
              ...a,
              defaultSetting: false,
              type: "popular",
            })),
        ];

        const onRemoveFavourite = async (id: string) => {
          const verbal = favouriteVerbalAssessmentSettings.find(
            v => v.id === id,
          );

          if (verbal) {
            await upsert({
              id: verbal.id,
              learnerHelperText: verbal.learnerHelperText,
              name: verbal.name,
              favourite: false,
              defaultSetting: false,
            });
          }
        };

        const onSetDefaultSetting = async (id: string, state: boolean) => {
          const verbal = favouriteVerbalAssessmentSettings.find(
            v => v.id === id,
          );

          if (verbal) {
            await upsert({
              id: verbal.id,
              learnerHelperText: verbal.learnerHelperText,
              name: verbal.name,
              favourite: verbal.favourite,
              defaultSetting: state,
            });

            if (state) {
              showSnackbar({
                id: verbal.id,
                type: "success",
                message: t(
                  "components.organisms.editVerbalAssessmentSettingModal.defaultSettingSuccess",
                ),
              });
            }
          }
        };

        return (
          <Formik
            enableReinitialize
            initialValues={{
              id: (setting && setting.id) || "default",
              name: (setting && setting.name) || "",
              learnerHelperText:
                (setting && setting.learnerHelperText) ||
                t("globals.verbalAssessment.learnerHelperText"),
              defaultSetting: (setting && setting.defaultSetting) || false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(140, t("globals.validations.nameMax"))
                .when("id", {
                  is: "new",
                  then: Yup.string().required(
                    t("globals.validations.nameRequired"),
                  ),
                }),
              learnerHelperText: Yup.string()
                .required(t("globals.validations.nameRequired"))
                .max(300, t("globals.validations.textMax300")),
            })}
            onSubmit={async (values: any, { setSubmitting }) => {
              if (values && values.id === "new") {
                setSubmitting(true);
                const result = await upsert({
                  learnerHelperText: values.learnerHelperText,
                  name: values.name,
                  favourite: true,
                  id: undefined,
                });
                setSubmitting(false);

                const vas =
                  result &&
                  result.data &&
                  result.data.upsertVerbalAssessmentSetting.data;

                onModalClosed(
                  true,
                  vas && vas.id,
                  vas && vas.learnerHelperText,
                );
              } else if (values.id === "default") {
                onModalClosed(true, null, null);
              } else {
                onModalClosed(true, values.id, values.learnerHelperText);
              }
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              return (
                <Modal
                  name="verbal-assessment-setting"
                  height="650px"
                  width="650px"
                  loading={isSubmitting || loading}
                  primaryAction={
                    isFavouriteManager
                      ? {
                          text: t("globals.close"),
                          onClick: onCancel,
                        }
                      : {
                          text: t("globals.save"),
                          onClick: handleSubmit,
                          disabled: !isValid,
                        }
                  }
                  secondaryAction={
                    isFavouriteManager
                      ? undefined
                      : {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                  }
                  title={t(
                    "components.organisms.editVerbalAssessmentSettingModal.title",
                  )}
                  open={open}
                >
                  <VerbalAssesssmentSettingForm
                    isFavouriteManager={isFavouriteManager}
                    favouriteVerbalAssessmentSettings={combinedFavs}
                    onRemoveFavourite={onRemoveFavourite}
                    onSetDefaultSetting={onSetDefaultSetting}
                    {...props}
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
