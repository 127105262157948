import { ApolloError, useLazyQuery, useMutation, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import {
  CreateActivityTemplateFolder,
  CreateActivityTemplateFolderVariables,
} from "./types/CreateActivityTemplateFolder";
import {
  GetActivityTemplateFolderEdit,
  GetActivityTemplateFolderEditVariables,
} from "./types/GetActivityTemplateFolderEdit";
import {
  UpdateActivityTemplateFolder,
  UpdateActivityTemplateFolderVariables,
} from "./types/UpdateActivityTemplateFolder";

export const GET = gql`
  query GetActivityTemplateFolderEdit($filters: IdInputType!) {
    activityTemplateFolder(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        activityTemplates {
          id
        }
      }
    }
  }
`;

export const UPDATE = gql`
  mutation UpdateActivityTemplateFolder(
    $input: ActivityTemplateFolderUpdateInputType!
  ) {
    updateActivityTemplateFolder(input: $input) {
      data {
        id
        name
        user {
          id
        }
        activityTemplates {
          id
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateActivityTemplateFolder(
    $input: ActivityTemplateFolderCreateInputType!
  ) {
    createActivityTemplateFolder(input: $input) {
      data {
        id
        name
        user {
          id
        }
        activityTemplates {
          id
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activityTemplateFolder: {
    loading: boolean;
    data: GetActivityTemplateFolderEdit | undefined;
  };
  create: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            CreateActivityTemplateFolder,
            CreateActivityTemplateFolderVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<CreateActivityTemplateFolder>>;
    error?: ApolloError;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            UpdateActivityTemplateFolder,
            UpdateActivityTemplateFolderVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<UpdateActivityTemplateFolder>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  folderId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children, folderId }) => {
  const [
    loadFolder,
    { loading: loadingFolder, data: dataFolder },
  ] = useLazyQuery<
    GetActivityTemplateFolderEdit,
    GetActivityTemplateFolderEditVariables
  >(GET);

  useEffect(() => {
    const fetchData = async () => {
      if (folderId) {
        await loadFolder({
          variables: {
            filters: {
              id: folderId,
            },
          },
        });
      }
    };
    fetchData();
  }, [folderId, loadFolder]);

  const [createFolder, { error: createError }] = useMutation<
    CreateActivityTemplateFolder,
    CreateActivityTemplateFolderVariables
  >(CREATE);

  const [updateFolder, { error: updateError }] = useMutation<
    UpdateActivityTemplateFolder,
    UpdateActivityTemplateFolderVariables
  >(UPDATE);

  return children({
    activityTemplateFolder: {
      loading: loadingFolder,
      data: dataFolder,
    },
    create: { mutation: createFolder, error: createError },
    update: { mutation: updateFolder, error: updateError },
  });
};
