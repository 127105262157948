import React, { useContext } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import UserContext from "components/Contexts/UserContext";
import { Modal } from "../../Molecules";

import { NumericAssesssmentSettingForm } from "./components/Form";

import { Queries } from "./components/Queries";
import { useSnackbar } from "components/Contexts/SnackbarContext";

export interface EditNumericAssessmentSettingModalProps {
  open: boolean;
  assessmentSettingId?: string | null;
  educationalPackageId?: string | null;
  onModalClosed: (
    result: boolean,
    numericAssessmentSettingId: string | null,
  ) => void;
  isFavouriteManager?: boolean;
}

export const EditNumericAssessmentSettingModal: React.FunctionComponent<EditNumericAssessmentSettingModalProps> = ({
  onModalClosed,
  assessmentSettingId,
  educationalPackageId,
  open,
  isFavouriteManager,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { showSnackbar } = useSnackbar();
  const myId = user && user.uid;
  const onCancel = () => {
    onModalClosed(false, null);
  };

  return (
    <Queries
      assessmentSettingId={assessmentSettingId}
      educationalPackageId={educationalPackageId}
    >
      {({
        numericAssessmentSettings: {
          loading,
          data,
          favouriteNumericAssessmentSettings,
          popularNumericAssessmentSettings,
        },
        numericAssessmentSetting: { upsert },
      }) => {
        let setting = data && data;

        const favs = favouriteNumericAssessmentSettings.sort((a, b) =>
          ((a && a.name) || "").localeCompare((b && b.name) || ""),
        );

        if (isFavouriteManager) {
          setting = favs.find(fnas => fnas.defaultSetting === true);
          if (!setting && favs.length) {
            setting = favs[0];
          }
        }

        const scale = (setting && setting.scale) || 3;

        const combinedFavs = [
          ...favs
            .filter(f => f.user.id === myId)
            .map(a => ({
              ...a,
              type: "myFavourite",
            })),
          ...favs
            .filter(f => f.user.id !== myId)
            .map(a => ({
              ...a,
              defaultSetting: false,
              type: "otherFavourite",
            })),
          ...popularNumericAssessmentSettings
            .filter(p => !favs.find(f => f.id === p.id))
            .map(a => ({
              ...a,
              defaultSetting: false,
              type: "popular",
            })),
        ];

        const learnerScaleTexts = (setting && setting.learnerScaleTexts) || [
          t("globals.numericAssessments.self.fair"),
          t("globals.numericAssessments.self.good"),
          t("globals.numericAssessments.self.excellent"),
        ];

        const teacherScaleTexts = (setting && setting.teacherScaleTexts) || [
          t("globals.numericAssessments.teacher.fair"),
          t("globals.numericAssessments.teacher.good"),
          t("globals.numericAssessments.teacher.excellent"),
        ];

        const onRemoveFavourite = async (id: string) => {
          const numeric = favouriteNumericAssessmentSettings.find(
            v => v.id === id,
          );

          if (numeric) {
            await upsert({
              id: numeric.id,
              scale: numeric.scale,
              learnerScaleTexts: numeric.learnerScaleTexts,
              teacherScaleTexts: numeric.teacherScaleTexts,
              name: numeric.name,
              favourite: false,
              defaultSetting: false,
            });
          }
        };

        const onSetDefaultSetting = async (id: string, state: boolean) => {
          const numeric = favouriteNumericAssessmentSettings.find(
            v => v.id === id,
          );

          if (numeric) {
            await upsert({
              id: numeric.id,
              scale: numeric.scale,
              learnerScaleTexts: numeric.learnerScaleTexts,
              teacherScaleTexts: numeric.teacherScaleTexts,
              name: numeric.name,
              favourite: numeric.favourite,
              defaultSetting: state,
            });

            if (state) {
              showSnackbar({
                id: numeric.id,
                type: "success",
                message: t(
                  "components.organisms.editNumericAssessmentSettingModal.defaultSettingSuccess",
                ),
              });
            }
          }
        };

        return (
          <Formik
            enableReinitialize
            initialValues={{
              id: (setting && setting.id) || "default",
              name: (setting && setting.name) || "",
              defaultSetting: (setting && setting.defaultSetting) || false,
              favourite: (setting && setting.favourite) || false,
              scale,
              learnerScaleTexts,
              teacherScaleTexts,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(140, t("globals.validations.nameMax"))
                .when("id", {
                  is: "new",
                  then: Yup.string().required(
                    t("globals.validations.nameRequired"),
                  ),
                }),
              scale: Yup.number()
                .required()
                .min(3)
                .max(5),
              learnerScaleTexts: Yup.array()
                .of(
                  Yup.string()
                    .required(t("globals.validations.nameRequired"))
                    .max(140, t("globals.validations.nameMax")),
                )
                .min(3)
                .max(5),
              teacherScaleTexts: Yup.array()
                .of(
                  Yup.string()
                    .required(t("globals.validations.nameRequired"))
                    .max(140, t("globals.validations.nameMax")),
                )
                .min(3)
                .max(5),
            })}
            onSubmit={async (values: any, { setSubmitting }) => {
              if (values && values.id === "new") {
                setSubmitting(true);
                const result = await upsert({
                  scale: values.scale,
                  learnerScaleTexts: values.learnerScaleTexts,
                  teacherScaleTexts: values.teacherScaleTexts,
                  name: values.name,
                  favourite: true,
                  id: undefined,
                });

                setSubmitting(false);
                onModalClosed(
                  true,
                  result &&
                    result.data &&
                    result.data.upsertNumericAssessmentSetting.data
                    ? result.data.upsertNumericAssessmentSetting.data.id
                    : null,
                );
              } else if (values.id === "default") {
                onModalClosed(true, null);
              } else {
                onModalClosed(true, values.id);
              }
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;

              return (
                <Modal
                  name="numeric-assessment-setting"
                  height="650px"
                  width="650px"
                  loading={isSubmitting || loading}
                  primaryAction={
                    isFavouriteManager
                      ? {
                          text: t("globals.close"),
                          onClick: onCancel,
                        }
                      : {
                          text: t("globals.save"),
                          onClick: handleSubmit,
                          disabled: !isValid,
                        }
                  }
                  secondaryAction={
                    isFavouriteManager
                      ? undefined
                      : {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                  }
                  title={t(
                    "components.organisms.editNumericAssessmentSettingModal.title",
                  )}
                  open={open}
                >
                  <NumericAssesssmentSettingForm
                    isFavouriteManager={isFavouriteManager}
                    favouriteNumericAssessmentSettings={combinedFavs}
                    onRemoveFavourite={onRemoveFavourite}
                    onSetDefaultSetting={onSetDefaultSetting}
                    {...props}
                  />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
