import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";

import { Flex, Text } from "components/Atoms";
import { Modal } from "components/Molecules";

interface Props {
  learnerName: string;
  onModalClosed: (result: boolean) => void;
}

const StyledNotificationImportantOutlined = styled(
  NotificationImportantOutlined,
)`
  color: ${props => props.theme.palette.primary.dark};
`;

export const SubmitCommentConfirmationModal: React.FC<Props> = ({
  learnerName,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      width={"450px"}
      name="confirmation"
      primaryAction={{
        text: t("components.organisms.editCommentsModal.submit"),
        onClick: () => onModalClosed(true),
      }}
      secondaryAction={{
        text: t("globals.cancel"),
        onClick: () => onModalClosed(false),
      }}
      open={true}
      headingAction={<StyledNotificationImportantOutlined />}
      title={t("components.organisms.editCommentsModal.title")}
      height="auto"
    >
      <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
        <Text>
          <Trans
            i18nKey="components.organisms.editCommentsModal.confirmText"
            values={{ name: learnerName }}
          >
            <strong></strong>
          </Trans>
        </Text>
      </Flex>
    </Modal>
  );
};
