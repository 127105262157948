import React, { useRef } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import {
  MultiChipSelect,
  Suggestion,
  MenuProps,
} from "../../Molecules/MultiChipSelect";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { MenuItem, Flex, Box, Text } from "../../Atoms";

import {
  UserSelectSearch,
  UserSelectSearchVariables,
  UserSelectSearch_allUsers as User,
} from "./types/UserSelectSearch";

import { Role } from "./../../../typings/graphql-global-types";

export interface UserSelectProps {
  id?: string;
  name?: string;
  role?: Role;
  selected?: Suggestion[] | null;
  onChange: (selectedItems: Suggestion[]) => void;
}

export const SEARCH_USERS = gql`
  query UserSelectSearch($search: String, $role: Role) {
    allUsers(filter: { search: $search, role: $role }) {
      id
      givenName
      familyName
      emailAddress
    }
  }
`;

const StyledText = styled(Text)`
  color: ${props => props.theme.palette.grey[900]};
`;

const MenuWrapper = styled(Paper)`
  border-radius: 0 0 ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(1)}px;
  background-color: #f1f0f4;
  position: absolute;
  z-index: 1;
  margin-top: ${props => props.theme.spacing(1)};
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
`;

const ProfileCircle = styled(Flex)`
  font-size: 1.3em;
  width: 40px;
  height: 40px;
  line-height: 1;
  text-align: center;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.primary.light};
  color: ${props => props.theme.palette.primary.dark};
  vertical-align: middle;
  margin-right: ${props => props.theme.spacing(1.5)}px;
  font-weight: 500;
  padding-top: 3px;
`;

const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #d1d0d3;
  padding-left: 10px;
  :last-child {
    border-bottom: none;
  }
`;

export interface UserMenuProps extends MenuProps {
  role?: Role;
}

const Menu: React.FC<UserMenuProps> = ({
  search,
  selected,
  getItemProps,
  role,
  anchorEl,
  getMenuProps,
}) => {
  const { data, error, loading } = useQuery<
    UserSelectSearch,
    UserSelectSearchVariables
  >(SEARCH_USERS, {
    variables: { search, role },
  });
  if (loading) {
    return null;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const usersFiltered = ((data && data.allUsers) || []).filter((obj: User) => {
    return !(selected || []).some(obj2 => {
      return obj.id === obj2.id;
    });
  });

  return (
    <MenuWrapper
      {...getMenuProps()}
      style={{
        width: anchorEl ? anchorEl.clientWidth : undefined,
      }}
    >
      {usersFiltered.map((user: User) => (
        <StyledMenuItem
          {...getItemProps({
            item: { name: `${user.givenName} ${user.familyName}`, id: user.id },
          })}
          key={user.id}
          component="div"
        >
          <Flex pt={0.5} alignItems="center">
            <ProfileCircle justifyContent="center" alignItems="center">
              {user.givenName && user.givenName[0]}
            </ProfileCircle>
            <Flex flexDirection="column">
              <Text variant="body1" fontWeight="600">
                {user.givenName} {user.familyName}
              </Text>
              <StyledText variant="body1" fontWeight="400">
                {user.emailAddress}
              </StyledText>
            </Flex>
          </Flex>
        </StyledMenuItem>
      ))}
    </MenuWrapper>
  );
};

export const UserSelect: React.FC<UserSelectProps> = ({
  id,
  name,
  selected,
  onChange,
  role,
}) => {
  const anchorEl = useRef<React.Ref<HTMLDivElement>>(null);
  return (
    <Box ref={anchorEl}>
      <MultiChipSelect
        id={id}
        name={name}
        selected={selected}
        onChange={onChange}
        menuComponent={props => (
          <Popper
            open={true}
            anchorEl={props.anchorEl}
            style={{ zIndex: 1300 }}
            placement="bottom-start"
          >
            <Menu role={role} {...props}></Menu>
          </Popper>
        )}
      />
    </Box>
  );
};
