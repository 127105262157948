import React, { useState } from "react";

interface Props {
  defaultOpen: string;
}

export const CardGroup: React.FC<Props> = ({ children, defaultOpen }) => {
  const [expandedItem, setToggleExpanded] = useState(defaultOpen);
  const [dragging, setDragging] = useState(false);

  const toggleExpanded = (id: string) => {
    if (!dragging) setToggleExpanded(id);
  };

  return (
    <CardItemContext.Provider
      value={{
        expandedItem,
        toggleExpanded,
        dragging,
        setDragging,
      }}
    >
      {children}
    </CardItemContext.Provider>
  );
};

interface CardItemContextProps {
  expandedItem: string;
  toggleExpanded: (id: string) => void;
  dragging: boolean;
  setDragging: (state: boolean) => void;
}

export const CardItemContext = React.createContext<CardItemContextProps>({
  expandedItem: "",
  toggleExpanded: (id: string) => {},
  dragging: false,
  setDragging: (state: boolean) => {},
});

export const useCardGroupContext = () => React.useContext(CardItemContext);
