import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { lighten } from "@material-ui/core/styles";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Flex, Box, InputLabel, Text } from "components/Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { ActivityUpdateInputType } from "typings/graphql-global-types";

const Form = styled.form`
  width: 100%;
`;

interface ActivityFormProps extends FormikProps<ActivityUpdateInputType> {
  hasGoals?: boolean | null;
}

const StyledInputLabel = styled(InputLabel)`
  position: initial;
`;

const InfoBox = styled(Box)`
  background-color: ${props => lighten(props.theme.palette.primary.main, 0.8)};
  border-radius: ${props => props.theme.spacing(3)}px;
  color: #000;
`;

const RightBorderedBox = styled(Box)`
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const BlackText = styled(Text)`
  color: #000;
`;

const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: row;
`;

export const ActivityForm: React.FC<ActivityFormProps> = ({
  values: { selfAssessment },
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  hasGoals,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldValue(name, e.target.value === "true");
    setFieldTouched(name, true, false);
  };

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl fullWidth>
          <Box>
            <StyledInputLabel>
              {t(
                "components.organisms.editActivityClassroomSettingsForm.assessments",
              )}
            </StyledInputLabel>
          </Box>
          <StyledRadioGroup
            aria-label="selfAssessment"
            name="selfAssessment"
            value={!!selfAssessment}
            onChange={change.bind(null, "selfAssessment")}
          >
            <FormControlLabel
              value={true}
              disabled={!hasGoals}
              control={<Radio data-cy="radio-input-yes" color="primary" />}
              label={t(
                "components.organisms.editActivityClassroomSettingsForm.yes",
              )}
            />
            <FormControlLabel
              value={false}
              disabled={!hasGoals}
              control={<Radio data-cy="radio-input-no" color="primary" />}
              label={t(
                "components.organisms.editActivityClassroomSettingsForm.no",
              )}
            />
          </StyledRadioGroup>
        </FormControl>
        <InfoBox mt={6} pr={2}>
          <Flex alignItems="center">
            <RightBorderedBox p={3} mr={2}>
              <NotificationImportantOutlined color="primary" />
            </RightBorderedBox>
            <BlackText>
              {!hasGoals
                ? t(
                    "components.organisms.editActivityClassroomSettingsForm.noGoalsInfoText",
                  )
                : t(
                    "components.organisms.editActivityClassroomSettingsForm.selfAssessmentInfoText",
                  )}
            </BlackText>
          </Flex>
        </InfoBox>
      </Flex>
    </Form>
  );
};
