import React, { useContext, useState, useEffect } from "react";
import { prop, sortBy } from "ramda";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Transition, animated } from "react-spring";
import UserContext from "components/Contexts/UserContext";

import { Box, Loader, Text } from "components/Atoms";

import useSroll from "components/Hooks/use-scroll";

import { EditLearningAchievementMaterialsModal } from "components/Organisms";

import { CardGroup } from "components/Contexts/CardGroup";
import { useSnackbar } from "components/Contexts/SnackbarContext";

import { MaterialUploadModal, MaterialModalType } from "../MaterialUploadModal";
import {
  NewActivityListQueries,
  QueriesProps,
} from "../NewActivityListQueries";
import { GetEducationalPackageNewActivities_allActivities_data as Activity } from "../NewActivityListQueries/types/GetEducationalPackageNewActivities";

import { SubmitActivityConfirmationModal } from "./SubmitActivityConfirmationModal";

import { LearnerActivityCard } from "./components";

let _submitAssessmentFn: (() => Promise<void>) | undefined;

const sortByOrder = sortBy(prop("order"));
interface Props {
  educationalPackageId: string;
  updateTabs: () => {};
}
export const NewActivityList: React.FC<Props> = ({
  educationalPackageId,
  updateTabs,
}) => {
  return (
    <NewActivityListQueries educationalPackageId={educationalPackageId}>
      {props => <List {...props} updateTabs={updateTabs} />}
    </NewActivityListQueries>
  );
};

interface ListProps extends QueriesProps {
  updateTabs: () => {};
}

const List: React.FC<ListProps> = ({
  activities: { loading, data, refetch, educationalPackageData },
  activity: { submit, loading: submitLoading },
  assessment: { update },
  updateTabs,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  let location = useLocation();
  const { showSnackbar } = useSnackbar();
  const defaultActivityCardOpen: string | null = new URLSearchParams(
    location.search,
  ).get("open");

  const { executeScroll, htmlElRef } = useSroll();

  useEffect(() => {
    if (defaultActivityCardOpen) executeScroll();
  }, [defaultActivityCardOpen, data, executeScroll]);

  const [showAddMaterialsModal, setShowAddMaterialsModal] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState<
    string | undefined | null
  >();
  const [showMaterialsModal, setShowMaterialsModal] = useState(false);
  const [
    showSubmitActivityConfirmationModal,
    setShowSubmitActivityConfirmationModal,
  ] = useState(false);

  const [
    materialType /*, setMaterialType*/,
  ] = useState<MaterialModalType | null>();

  const onSubmitActivityClick = (
    activityId: string,
    submitAssessment?: () => Promise<void>,
  ) => {
    setSelectedActivityId(activityId);
    _submitAssessmentFn = submitAssessment;
    setShowSubmitActivityConfirmationModal(true);
  };

  const onAddMaterialsModalClosed = (result: boolean) => {
    setShowAddMaterialsModal(false);
    setSelectedActivityId(null);
    if (result) {
      refetch();
    }
  };

  const onMaterialModalClosed = (result: boolean) => {
    setShowMaterialsModal(false);
    setSelectedActivityId(null);
    if (result) {
      refetch();
    }
  };

  const onSubmitActivityModalClosed = async (result: boolean) => {
    setShowMaterialsModal(false);
    const activityId = selectedActivityId;
    setSelectedActivityId(null);
    if (result && activityId) {
      _submitAssessmentFn && (await _submitAssessmentFn());
      await submitActivity(activityId);
    }
    _submitAssessmentFn = undefined;
  };

  const submitActivity = async (activityId: string) => {
    await submit(activityId);
    updateTabs && updateTabs();
    await new Promise(resolve => setTimeout(resolve, 250));

    showSnackbar({
      id: "submitted",
      type: "success",
      message: t("containers.learnerEducationPackage.submitSuccess"),
    });
  };

  const items = sortByOrder(data);

  return (
    <>
      {(loading && <Loader />) ||
        (!loading && data.length === 0 && (
          <Box>
            <Text>{t("globals.empty.noActivities")}</Text>
          </Box>
        ))}
      {!loading && (
        <div style={{ overflowX: "hidden", padding: "1px" }}>
          <CardGroup
            defaultOpen={
              defaultActivityCardOpen
                ? defaultActivityCardOpen
                : items[0] && items[0].id
            }
          >
            <Transition
              items={items}
              keys={(item: Activity) => item.id}
              from={{ opacity: 1 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
            >
              {(props, a, _, i) => (
                <animated.div style={props}>
                  <Box pt={i && 3} ref={htmlElRef}>
                    <LearnerActivityCard
                      activity={items.find(act => act.id === a.id) || a}
                      educationalPackageData={educationalPackageData}
                      loading={submitLoading}
                      onSubmitActivityClick={onSubmitActivityClick}
                      onSaveAssessment={update}
                    />
                  </Box>
                </animated.div>
              )}
            </Transition>
          </CardGroup>
        </div>
      )}

      {showAddMaterialsModal && materialType && selectedActivityId && (
        <MaterialUploadModal
          type={materialType}
          open={showAddMaterialsModal}
          onModalClosed={onAddMaterialsModalClosed}
          activityId={selectedActivityId}
        ></MaterialUploadModal>
      )}
      {user && showMaterialsModal && selectedActivityId && (
        <EditLearningAchievementMaterialsModal
          type="learner"
          readonly={
            educationalPackageData && educationalPackageData.importedPackage
          }
          userId={user.uid}
          activityId={selectedActivityId}
          open={showMaterialsModal}
          onModalClosed={onMaterialModalClosed}
        />
      )}
      {showSubmitActivityConfirmationModal && selectedActivityId && (
        <SubmitActivityConfirmationModal
          onModalClosed={onSubmitActivityModalClosed}
        />
      )}
    </>
  );
};
