import React, { useRef } from "react";
import styled from "styled-components";

import { Flex } from "components/Atoms";
import { Snackbar } from "components/Molecules/Snackbar";

const SnackbarWrapper = styled(Flex)`
  position: fixed;
  z-index: 1301;
  left: 5%;
  bottom: 5%;
  right: 5%;
  flex-direction: column;
`;

const StyledSnackbar = styled(Snackbar)`
  position: inherit;
  width: 100%;
  ${props => props.theme.breakpoints.up("xl")} {
    width: ${props => props.theme.breakpoints.values.xl - 50}px;
  }
`;

interface SnacbarContainerState {
  snackbars: SnackbarProps[];
}

class SnackbarContainer extends React.Component<
  { ref: any },
  SnacbarContainerState
> {
  constructor(props: any) {
    super(props);
    this.state = { snackbars: [] };
  }

  showSnackbar = (snackbar: SnackbarProps) => {
    const { snackbars } = this.state;
    if (!snackbars.find(s => s.id === snackbar.id)) {
      this.setState({ snackbars: [...snackbars, snackbar] });
    }
  };

  closeSnackbar = (id: string) => {
    this.onClose(id);
  };

  onClose = (id: number | string) => {
    const { snackbars } = this.state;
    const newSnackbars = snackbars.filter(t => t.id !== id);
    this.setState({ snackbars: newSnackbars });
  };

  public render() {
    return (
      <SnackbarWrapper>
        {this.state.snackbars.map(
          ({ message, id, type, autoHideDuration }, i) => (
            <Flex justifyContent="center" key={id} mt={i ? 2 : 0}>
              <StyledSnackbar
                type={type}
                message={message}
                autoHideDuration={autoHideDuration}
                onClose={() => this.onClose(id)}
              />
            </Flex>
          ),
        )}
      </SnackbarWrapper>
    );
  }
}

interface SnackbarContext {
  showSnackbar: (opts: SnackbarProps) => void;
  closeSnackbar: (id: string) => void;
}

const Ctx = React.createContext<SnackbarContext>({
  showSnackbar: () => {
    throw new Error("showSnackbar() not implemented");
  },
  closeSnackbar: () => {
    throw new Error("closeSnackbar() not implemented");
  },
});

type SnackbarType = "success" | "error" | "info";

export type SnackbarProps = {
  id: number | string;
  type: SnackbarType;
  message: React.ReactNode;
  autoHideDuration?: number;
};

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const snackbarContainer = useRef<{
    showSnackbar: (props: SnackbarProps) => void;
    closeSnackbar: (id: string) => void;
  }>();

  const showSnackbar = (props: SnackbarProps) => {
    snackbarContainer &&
      snackbarContainer.current &&
      snackbarContainer.current.showSnackbar(props);
  };

  const closeSnackbar = (id: string) => {
    snackbarContainer &&
      snackbarContainer.current &&
      snackbarContainer.current.closeSnackbar(id);
  };

  return (
    <Ctx.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <SnackbarContainer ref={snackbarContainer as any} />
    </Ctx.Provider>
  );
}

export const useSnackbar = () => React.useContext(Ctx);
