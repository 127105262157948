import React from "react";

import styled from "styled-components";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import ExitIcon from "@material-ui/icons/ExitToApp";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import HomeIcon from "@material-ui/icons/Home";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { Link as RouterLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { useTranslation } from "react-i18next";
import { useLocation, useHistory, matchPath } from "react-router";

import {
  Flex,
  Box,
  PortfolioLogo,
  HKLogo,
  Text,
  Divider,
  OwnGoalsIcon,
} from "../../Atoms";

import { default as Drawer, DrawerProps } from "@material-ui/core/Drawer";

import { useSession } from "../../Hooks/firebase-hooks";
import packageInfo from "../../../../package.json";

import { SETTINGS_ROOT, HOME, DIGIPOLKU, DIGIPOLKU2, OWNGOALS } from "routes";

import { DigipolkuSurveyGroup } from "./types/DigipolkuSurveyGroup";

export type MenuProps = DrawerProps;

// const TUTORIAL_VIDEO =
//   "https://www.youtube.com/watch?v=5lUPAImM6bA&list=PLIp5lYvqyycKQiVgVwYY1teq7KGWxcfRw";

const FEEDBACK_URL = "https://forms.gle/6EkQ8zuDFxToywk9A";

export const GET_DIGIPOLKU = gql`
  query DigipolkuSurveyGroup {
    digipolkuSurveyGroup {
      data {
        id
        name
      }
    }
  }
`;

const StyledImg = styled.img`
  border-radius: 50%;
  margin: 0px 2px;
  overflow: hidden;
  position: relative;
  height: 3.25em;
  width: 3.25em;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
`;

const DIGIPOLKU2_EXISTS = gql`
  query DigipolkuSurveyGroup2Exists {
    digipolkuSurveyGroup2 {
      data {
        id
        name
      }
    }
  }
`;

export const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
  const { user, signOut } = useSession();

  const { data } = useQuery<DigipolkuSurveyGroup>(GET_DIGIPOLKU);
  const { data: data2 } = useQuery(DIGIPOLKU2_EXISTS);

  const digipolkuData =
    data && data.digipolkuSurveyGroup && data.digipolkuSurveyGroup.data;
  const digipolku2Data =
    data2 && data2.digipolkuSurveyGroup2 && data2.digipolkuSurveyGroup2.data;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const onSignOut = async () => {
    signOut();
    // @ts-ignore
    const state = { ...location.state } as any;
    delete state.from;
    history.replace({ pathname: "/login", state });
  };

  const isSettings = !!matchPath(location.pathname, {
    path: SETTINGS_ROOT,
  });

  const isDigipolku = !!matchPath(location.pathname, {
    path: DIGIPOLKU,
  });

  const isDigipolku2 = !!matchPath(location.pathname, {
    path: DIGIPOLKU2,
  });

  const isOwnGoals = !!matchPath(location.pathname, {
    path: OWNGOALS,
  });

  const isHome = !isSettings && !isDigipolku && !isDigipolku2 && !isOwnGoals;

  return (
    <Drawer {...props}>
      <div onClick={() => props.onClose && props.onClose({}, "backdropClick")}>
        <Flex width="100%" height="100%" flexDirection="column">
          <Box textAlign="center" pt={3}>
            <PortfolioLogo height="32px" color="primary.dark" />
          </Box>

          {user && user.photoURL && user.email && (
            <Flex pt={2} alignItems="center" flexDirection="column">
              <Box>
                <StyledImg src={user.photoURL} alt={user.email} />
              </Box>
              <Box pt={1}>
                <StyledText color="textPrimary">{user.displayName}</StyledText>
              </Box>
            </Flex>
          )}

          <Box pt={2}>
            <Divider />
          </Box>

          <Box>
            <List>
              <ListItem
                selected={isHome}
                component={props => <RouterLink {...props} to={HOME} />}
              >
                <ListItemIcon>
                  <HomeIcon color={isHome ? "primary" : "inherit"} />
                </ListItemIcon>
                <ListItemText primary={t("globals.home")} />
              </ListItem>

              <ListItem
                data-cy="menu-button-settings"
                selected={isSettings}
                component={props => (
                  <RouterLink {...props} to={SETTINGS_ROOT} />
                )}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon
                    color={isSettings ? "primary" : "inherit"}
                  />
                </ListItemIcon>
                <ListItemText primary={t("globals.settings")} />
              </ListItem>

              <ListItem
                data-cy="menu-button-owngoals"
                selected={isOwnGoals}
                component={props => <RouterLink {...props} to={OWNGOALS} />}
              >
                <ListItemIcon>
                  <OwnGoalsIcon color={isOwnGoals ? "primary" : "inherit"} />
                </ListItemIcon>
                <ListItemText primary={t("globals.ownGoals")} />
              </ListItem>
              {digipolku2Data && (
                <ListItem
                  data-cy="menu-button-digipolku"
                  selected={isDigipolku2}
                  component={props => <RouterLink {...props} to={DIGIPOLKU2} />}
                >
                  <ListItemIcon>
                    <MapOutlinedIcon
                      color={isDigipolku2 ? "primary" : "inherit"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("globals.digipolku2")} />
                </ListItem>
              )}
              {digipolkuData && (
                <ListItem
                  data-cy="menu-button-digipolku"
                  selected={isDigipolku}
                  component={props => <RouterLink {...props} to={DIGIPOLKU} />}
                >
                  <ListItemIcon>
                    <MapOutlinedIcon
                      color={isDigipolku ? "primary" : "inherit"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("globals.digipolku")} />
                </ListItem>
              )}
            </List>
          </Box>

          <Box>
            <Divider />
          </Box>

          <Box>
            <List>
              {/* <ListItem
                component="a"
                button
                href={TUTORIAL_VIDEO}
                target="_blank"
              >
                <ListItemIcon>
                  <VideocamOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("globals.tutorialVideo")} />
              </ListItem> */}

              <ListItem
                component="a"
                button
                href={FEEDBACK_URL}
                target="_blank"
              >
                <ListItemIcon>
                  <AnnouncementOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("globals.giveFeedback")} />
              </ListItem>
            </List>
          </Box>

          <Box>
            <Divider />
          </Box>

          <Box>
            <List>
              <ListItem data-cy="menu-button-logout" button onClick={onSignOut}>
                <ListItemIcon>
                  <ExitIcon />
                </ListItemIcon>
                <ListItemText primary={t("components.organisms.menu.logout")} />
              </ListItem>
            </List>
          </Box>

          <Flex flex={1} pt={3} justifyContent="center" alignItems="flex-end">
            <Flex flexDirection="column" pb={3} textAlign="center">
              <HKLogo width="100px" color="primary.dark" />
              <Box mt={1}>
                <Text color="textPrimary">v. {packageInfo.version}</Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {props.children}
      </div>
    </Drawer>
  );
};

Menu.displayName = "Menu";
