import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery, useMutation, MutationFunctionOptions } from "@apollo/client";

import { GetSchools } from "./types/GetSchools";
import {
  GetSchoolTemplates,
  GetSchoolTemplatesVariables,
} from "./types/GetSchoolTemplates";

import { RemoveFolder, RemoveFolderVariables } from "./types/RemoveFolder";

import {
  RemoveGoalTemplate,
  RemoveGoalTemplateVariables,
} from "./types/RemoveGoalTemplate";

import { GoalTemplateFolderType } from "typings/graphql-global-types";

import {
  UpdateTeacherLastSelectedSchool,
  UpdateTeacherLastSelectedSchoolVariables,
} from "./types/UpdateTeacherLastSelectedSchool";

export const GET_SCHOOLS = gql`
  query GetSchools {
    allSchools {
      data {
        id
        name
      }
    }
  }
`;

const GET_SCHOOL_TEMPLATES = gql`
  query GetSchoolTemplates($filters: GoalTemplateListFiltersInputType!) {
    allGoalTemplateFoldersBySchool(filters: $filters) {
      data {
        id
        name
        user {
          id
        }
        parent {
          id
        }
        goalTemplates {
          id
          text
          user {
            id
          }
        }
        childrens {
          id
          parent {
            id
          }
          name
          user {
            id
          }
          goalTemplates {
            id
            text
            user {
              id
            }
          }
        }
      }
    }
  }
`;

const REMOVE_FOLDER = gql`
  mutation RemoveFolder($input: IdInputType!) {
    deleteGoalTemplateFolder(input: $input) {
      data {
        id
      }
    }
  }
`;

const REMOVE_GOALTEMPLATE = gql`
  mutation RemoveGoalTemplate($input: IdInputType!) {
    deleteGoalTemplate(input: $input) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_LASTSELECTEDSCHOOL = gql`
  mutation UpdateTeacherLastSelectedSchool($input: IdInputType!) {
    updateLastSelectedSchool(input: $input) {
      data {
        id
        lastSelectedSchool {
          id
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  schools: {
    loading: boolean;
    data: GetSchools | undefined;
  };
  goalTemplates: {
    loading: boolean;
    data: GetSchoolTemplates | undefined;
    refetch: any;
  };
  removeGoalTemplateFolder: {
    mutate: (
      options?:
        | MutationFunctionOptions<RemoveFolder, RemoveFolderVariables>
        | undefined,
    ) => Promise<any>;
    loading: boolean;
  };
  removeGoalTemplate: {
    mutate: (
      options?:
        | MutationFunctionOptions<
            RemoveGoalTemplate,
            RemoveGoalTemplateVariables
          >
        | undefined,
    ) => Promise<any>;
    loading: boolean;
  };
  updateSelectedSchool: (schoolId: string) => Promise<void>;
}

interface QueriesProps {
  children: RenderProp<Queries>;
  schoolId?: string;
  type: GoalTemplateFolderType;
}

export const Queries: React.FC<QueriesProps> = ({
  schoolId,
  type,
  children,
}) => {
  const { loading, data } = useQuery<GetSchools>(GET_SCHOOLS);

  const [
    getSchoolTemplates,
    { loading: templatesLoading, data: templatesData, refetch },
  ] = useLazyQuery<GetSchoolTemplates, GetSchoolTemplatesVariables>(
    GET_SCHOOL_TEMPLATES,
  );

  const [removeFolder, { loading: loadingRemoveFolder }] = useMutation<
    RemoveFolder,
    RemoveFolderVariables
  >(REMOVE_FOLDER);

  const [removeGt, { loading: loadingRemoveGoaltemplate }] = useMutation<
    RemoveGoalTemplate,
    RemoveGoalTemplateVariables
  >(REMOVE_GOALTEMPLATE);

  useEffect(() => {
    if (schoolId) {
      getSchoolTemplates({
        variables: {
          filters: {
            schoolId,
            type,
          },
        },
      });
    }
  }, [schoolId, type, getSchoolTemplates]);

  const [updateLastSelectedSchool] = useMutation<
    UpdateTeacherLastSelectedSchool,
    UpdateTeacherLastSelectedSchoolVariables
  >(UPDATE_LASTSELECTEDSCHOOL);

  const updateSelectedSchool = async (id: string) => {
    await updateLastSelectedSchool({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  return children({
    schools: {
      loading,
      data,
    },
    goalTemplates: {
      loading: templatesLoading,
      data: templatesData,
      refetch,
    },
    removeGoalTemplateFolder: {
      mutate: removeFolder,
      loading: loadingRemoveFolder,
    },
    removeGoalTemplate: {
      mutate: removeGt,
      loading: loadingRemoveGoaltemplate,
    },
    updateSelectedSchool,
  });
};
