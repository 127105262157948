import { Badge, InputAdornment } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  GoalTemplateFolderType,
  GoalTemplateUpdateInputType,
} from "typings/graphql-global-types";
import { Button, Flex, Input, InputLabel, Text, Tooltip } from "../../../Atoms";
import { SelectOPSEducationalPackageGoalModal } from "../../SelectOPSEducationalPackageGoalModal";

const StyledForm = styled.form`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  position: initial;
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 53px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-left: -53px;
`;

const LinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #00933d;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #00933d;
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background: #4d9cd7;
  }
`;

const NotLinkedButton = styled(Button)`
  height: 43px;
  min-width: 53px;
  background: #d04100;
  color: #fff;
  border-radius: 0 4px 4px 0;
  :hover {
    background: #d04100;
  }
`;

interface FormProps extends FormikProps<GoalTemplateUpdateInputType> {
  folderName: string;
  schoolName: string;
  type: GoalTemplateFolderType;
}

export const Form: React.FC<FormProps> = ({
  values: { text, eGoalIds },
  schoolName,
  folderName,
  errors,
  touched,
  handleChange,
  setFieldTouched,
  setFieldValue,
  type,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const linked = eGoalIds.length > 0;
  const [showSelectOPSGoalModal, setShowSelectOPSGoalModal] = useState(false);

  const onLinkOPSClick = () => {
    setShowSelectOPSGoalModal(true);
  };

  const onSelectOPSGoalModalClosed = () => {
    setShowSelectOPSGoalModal(false);
  };

  return (
    <>
      <StyledForm>
        <Flex flex={"auto"} flexDirection="column">
          <FormControl>
            <StyledInputLabel>{t("globals.school")}</StyledInputLabel>
            <Text>{schoolName}</Text>
          </FormControl>

          <FormControl>
            <StyledInputLabel>
              {t("components.organisms.editGoalTemplateModal.folder")}
            </StyledInputLabel>
            <Text>{folderName}</Text>
          </FormControl>

          <FormControl>
            <InputLabel htmlFor="text">
              {t("components.organisms.editGoalTemplateModal.text")}
            </InputLabel>
            {/* <Input
            id="text"
            name="text"
            helperText={touched.text ? errors.text : ""}
            error={touched.text && Boolean(errors.text)}
            value={text}
            onChange={change.bind(null, "text")}
            fullWidth
          /> */}

            {/* OPPI-939 */}
            <StyledInput
              id={"text"}
              name={"text"}
              helperText={touched.text ? errors.text : ""}
              error={touched.text && Boolean(errors.text)}
              placeholder={
                type === GoalTemplateFolderType.activity
                  ? t(
                      "components.organisms.activityGoalsForm.goalNamePlaceholder",
                    )
                  : t("components.organisms.editGoalTemplateModal.text")
              }
              value={text}
              onChange={change.bind(null, "text")}
              fullWidth
              endAdornment={
                type === GoalTemplateFolderType.activity && (
                  <StyledInputAdornment position="end">
                    <Tooltip
                      arrow
                      title={
                        linked
                          ? t("components.organisms.activityGoalsForm.linked")
                          : t(
                              "components.organisms.activityGoalsForm.notLinked",
                            )
                      }
                    >
                      {linked ? (
                        <LinkedButton
                          data-cy="linked-ops-button"
                          onClick={onLinkOPSClick}
                        >
                          <StyledBadge
                            overlap="rectangle"
                            badgeContent={eGoalIds.length}
                          >
                            <LinkIcon fontSize="large" />
                          </StyledBadge>
                        </LinkedButton>
                      ) : (
                        <NotLinkedButton
                          onClick={onLinkOPSClick}
                          data-cy="not-linked-ops-button"
                        >
                          <LinkOffIcon fontSize="large" />
                        </NotLinkedButton>
                      )}
                    </Tooltip>
                  </StyledInputAdornment>
                )
              }
            />
          </FormControl>
        </Flex>
      </StyledForm>
      {showSelectOPSGoalModal && (
        <SelectOPSEducationalPackageGoalModal
          educationalPackageId={null}
          open={showSelectOPSGoalModal}
          onModalClosed={onSelectOPSGoalModalClosed}
          eGoalIds={eGoalIds}
          eGoals={[{ hasAssessments: false, eGoalIds }]}
          onSetEGoalIds={eGoalsIds => setFieldValue(`eGoalIds`, eGoalsIds)}
        ></SelectOPSEducationalPackageGoalModal>
      )}
    </>
  );
};
