import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useAnalytics } from "components/Hooks/firebase-hooks";

import { Flex, Box, Text, AddNewButton } from "components/Atoms";

import { NavigationComponent } from "typings";
import { Queries, EducationalPackageList } from "./components";
import { JoinEducationalPackageByCodeModal } from "components/Organisms";

interface LearnerHomeProps { }

const LearnerHome: NavigationComponent<LearnerHomeProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { analytics } = useAnalytics();
  const [showJoinByCodeModal, setShowJoinByCodeModal] = useState(false);

  analytics.logEvent("page_view" as string, {
    page_title: "Learner_Home",
    page_path: history.location.pathname,
  });

  return (
    <Queries>
      {({ allEducationalPackages: { error, loading, data, refetch } }) => {
        const onJoinByCodeModalClosed = (result: boolean) => {
          setShowJoinByCodeModal(false);
          if (result) refetch();
        };
        return (
          <>
            <Flex px={[0, 4, 4, 9]} pt={[3, 6]} flexDirection="column">
              <Flex px={[1]} flexDirection="column">
                <Flex justifyContent="space-between" flexWrap="wrap">
                  <Text variant="h3">{t("containers.learnerHome.title")}</Text>
                  <Box mt={[4, 0]}>
                    <AddNewButton
                      name="add-educational-package-button"
                      onClick={() => setShowJoinByCodeModal(true)}
                      label={t("containers.learnerHome.joinByCode")}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Box pt={[4, 6]} pb={8}>
                <EducationalPackageList loading={loading} data={data} />
              </Box>
            </Flex>
            {showJoinByCodeModal && (
              <JoinEducationalPackageByCodeModal
                open={showJoinByCodeModal}
                onModalClosed={onJoinByCodeModalClosed}
              />
            )}
          </>
        );
      }}
    </Queries>
  );
};

LearnerHome.navigationOptions = {
  breadcrumbs: [{ icon: "home", disabled: true, path: "/" }],
};

export default LearnerHome;
