import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  CreateSurveyQuestionAnswerComment,
  CreateSurveyQuestionAnswerCommentVariables,
} from "./types/CreateSurveyQuestionAnswerComment";

import {
  GetSurveyQuestionAnswer,
  GetSurveyQuestionAnswerVariables,
  GetSurveyQuestionAnswer_surveyQuestionAnswer_data as SurveyQuestionAnswer,
} from "./types/GetSurveyQuestionAnswer";

import { SurveyQuestionAnswerCommentCreateInput } from "typings/graphql-global-types";

export const GET_SURVEYQUESTIONANSWER = gql`
  query GetSurveyQuestionAnswer($filters: IdInputType!) {
    surveyQuestionAnswer(filters: $filters) {
      data {
        id
        user {
          id
          givenName
          familyName
        }
        surveyQuestion {
          id
          name
        }
        comments {
          id
          text
          user {
            id
            givenName
            familyName
          }
          createdAt
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateSurveyQuestionAnswerComment(
    $input: SurveyQuestionAnswerCommentCreateInput!
  ) {
    createSurveyQuestionAnswerComment(input: $input) {
      data {
        id
        text
        user {
          id
          givenName
          familyName
        }
        createdAt
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  comments: {
    loading: boolean;
    data: SurveyQuestionAnswer | null;
  };
  create: (
    input: SurveyQuestionAnswerCommentCreateInput,
  ) => Promise<SingleExecutionResult<CreateSurveyQuestionAnswerComment>>;
}

interface QueriesProps {
  surveyQuestionAnswerId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  surveyQuestionAnswerId,
}) => {
  const { loading, data } = useQuery<
    GetSurveyQuestionAnswer,
    GetSurveyQuestionAnswerVariables
  >(GET_SURVEYQUESTIONANSWER, {
    variables: {
      filters: {
        id: surveyQuestionAnswerId,
      },
    },
  });

  const [createComment] = useMutation<
    CreateSurveyQuestionAnswerComment,
    CreateSurveyQuestionAnswerCommentVariables
  >(CREATE_COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SURVEYQUESTIONANSWER,
        variables: {
          filters: {
            id: surveyQuestionAnswerId,
          },
        },
      },
    ],
  });

  const create = (input: SurveyQuestionAnswerCommentCreateInput) =>
    createComment({ variables: { input } });

  return children({
    comments: {
      loading,
      data: data?.surveyQuestionAnswer.data || null,
    },
    create,
  });
};
