import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  GetAssessmentSetting,
  GetAssessmentSettingVariables,
  GetAssessmentSetting_numericAssessmentSetting_data as NumericAssessmentSetting,
} from "./types/GetAssessmentSetting";

import {
  GetNumericAssessmentSettingFavourites,
  GetNumericAssessmentSettingFavouritesVariables,
  GetNumericAssessmentSettingFavourites_favouriteNumericAssessmentSettings_data as FavouriteNumericAssessmentSettings,
  GetNumericAssessmentSettingFavourites_popularNumericAssessmentSettings_data as PopularNumericAssessmentSettings,
} from "./types/GetNumericAssessmentSettingFavourites";

import {
  UpsertNumericAssessmentSetting,
  UpsertNumericAssessmentSettingVariables,
} from "./types/UpsertNumericAssessmentSetting";

import { NumericAssessmentSettingInput } from "typings/graphql-global-types";

export const GET_NUMERICASSESSMENTSETTING = gql`
  query GetAssessmentSetting($filters: IdInputType!) {
    numericAssessmentSetting(filters: $filters) {
      data {
        id
        name
        scale
        learnerScaleTexts
        teacherScaleTexts
        favourite
        defaultSetting
      }
    }
  }
`;

export const GET_FAVOURITES = gql`
  query GetNumericAssessmentSettingFavourites(
    $filters: NumericAssessmentSettingFilterInputType
  ) {
    favouriteNumericAssessmentSettings(filters: $filters) {
      data {
        id
        name
        scale
        learnerScaleTexts
        teacherScaleTexts
        favourite
        defaultSetting
        user {
          id
          emailAddress
        }
      }
    }
    popularNumericAssessmentSettings {
      data {
        id
        name
        scale
        learnerScaleTexts
        teacherScaleTexts
        favourite
        user {
          id
          emailAddress
        }
      }
    }
  }
`;

export const UPSERT_NUMERIC_ASSESSMENT_SETTING = gql`
  mutation UpsertNumericAssessmentSetting(
    $input: NumericAssessmentSettingInput!
  ) {
    upsertNumericAssessmentSetting(input: $input) {
      data {
        id
        name
        scale
        learnerScaleTexts
        teacherScaleTexts
        defaultSetting
        favourite
      }
    }
  }
`;

export const UPDATE_FAVOURITES = gql`
  query UpdateFavourites($filters: NumericAssessmentSettingFilterInputType) {
    favouriteNumericAssessmentSettings(filters: $filters) {
      data {
        id
        name
        scale
        learnerScaleTexts
        teacherScaleTexts
        favourite
        defaultSetting
        user {
          id
          emailAddress
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  numericAssessmentSettings: {
    loading: boolean;
    data: NumericAssessmentSetting | undefined;
    favouriteNumericAssessmentSettings: FavouriteNumericAssessmentSettings[];
    popularNumericAssessmentSettings: PopularNumericAssessmentSettings[];
  };
  numericAssessmentSetting: {
    upsert: (
      input: NumericAssessmentSettingInput,
    ) => Promise<SingleExecutionResult<UpsertNumericAssessmentSetting>>;
  };
}

interface QueriesProps {
  educationalPackageId?: string | null;
  assessmentSettingId?: string | null;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  assessmentSettingId,
  educationalPackageId,
}) => {
  const { loading: loadinFavs, data: favData } = useQuery<
    GetNumericAssessmentSettingFavourites,
    GetNumericAssessmentSettingFavouritesVariables
  >(GET_FAVOURITES, {
    fetchPolicy: "network-only",
    variables: {
      filters: educationalPackageId ? { educationalPackageId } : null,
    },
  });

  const [loadData, { loading, data }] = useLazyQuery<
    GetAssessmentSetting,
    GetAssessmentSettingVariables
  >(GET_NUMERICASSESSMENTSETTING);

  useEffect(() => {
    const fetchData = async () => {
      if (assessmentSettingId) {
        await loadData({
          variables: {
            filters: {
              id: assessmentSettingId,
            },
          },
        });
      }
    };
    fetchData();
  }, [assessmentSettingId, loadData]);

  const [upsertNumericAssessmentSetting, ,] = useMutation<
    UpsertNumericAssessmentSetting,
    UpsertNumericAssessmentSettingVariables
  >(UPSERT_NUMERIC_ASSESSMENT_SETTING);

  const upsert = (input: NumericAssessmentSettingInput) => {
    return upsertNumericAssessmentSetting({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: UPDATE_FAVOURITES,
          variables: {
            filters: educationalPackageId ? { educationalPackageId } : null,
          },
        },
      ],
      update(cache, { data }) {
        const favs = cache.readQuery<
          GetNumericAssessmentSettingFavourites,
          GetNumericAssessmentSettingFavouritesVariables
        >({
          query: UPDATE_FAVOURITES,
          variables: {
            filters: educationalPackageId ? { educationalPackageId } : null,
          },
        });

        if (favs && favs.favouriteNumericAssessmentSettings.data) {
          const updatedFavs = favs.favouriteNumericAssessmentSettings.data.map(
            fav => {
              let defaultSetting: boolean | null = false;
              if (
                data &&
                data.upsertNumericAssessmentSetting.data.id === fav.id
              ) {
                defaultSetting =
                  data.upsertNumericAssessmentSetting.data.defaultSetting;
              }
              return { ...fav, defaultSetting };
            },
          );

          cache.writeQuery({
            query: UPDATE_FAVOURITES,
            data: {
              ...favs,
              favouriteNumericAssessmentSettings: {
                ...favs.favouriteNumericAssessmentSettings,
                data: updatedFavs,
              },
            },
          });
        }
      },
      awaitRefetchQueries: true,
    });
  };

  return children({
    numericAssessmentSettings: {
      loading: loading || loadinFavs,
      data: data && data.numericAssessmentSetting.data,
      favouriteNumericAssessmentSettings:
        (favData && favData.favouriteNumericAssessmentSettings.data) || [],
      popularNumericAssessmentSettings:
        (favData && favData.popularNumericAssessmentSettings.data) || [],
    },
    numericAssessmentSetting: {
      upsert,
    },
  });
};
