import React from "react";
import styled from "styled-components";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";

import TimelineDot from "@material-ui/lab/TimelineDot";

import MessageIcon from "@material-ui/icons/Message";

import { format } from "date-fns";

import { Box, Text } from "components/Atoms";

export interface CommentProps {
  id: string;
  text: string | null;
  user: {
    givenName: string | null;
    familyName: string | null;
  };
  read?: boolean;
  createdAt: Date;
}

interface Props {
  comments: CommentProps[];
}

const StyledTimelineItem = styled(TimelineItem)`
  &.MuiTimelineItem-missingOppositeContent:before {
    flex: none;
    content: none;
  }
`;

const MessageTimeline = ({ comments }: Props) => {
  return (
    <Timeline align="left">
      {comments.map((comment, i) => (
        <StyledTimelineItem key={comment.id}>
          <TimelineSeparator>
            <TimelineDot
              color={
                typeof comment.read === "undefined"
                  ? "grey"
                  : !comment.read
                  ? "primary"
                  : "grey"
              }
            >
              <MessageIcon />
            </TimelineDot>
            {i < comments.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Box>
              <Text variant="h4">{`${comment.user.givenName} ${comment.user.familyName}`}</Text>
              <Box minHeight="60px">
                <Text>{comment.text}</Text>
              </Box>
              <Text variant="h6">
                {format(new Date(comment.createdAt), "dd.MM.yyyy")}
              </Text>
            </Box>
          </TimelineContent>
        </StyledTimelineItem>
      ))}
    </Timeline>
  );
};

export default MessageTimeline;
