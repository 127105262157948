import React from "react";

import styled from "styled-components";

import { Flex, Box, Button, Loader } from "components/Atoms";
import { ModalBase, ModalBaseProps } from "components/Atoms/ModalBase";

const ModalSizeLoader = styled(Loader)`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #6c6c6cab;
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
const StyledFlex = styled(Flex)`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: ${props => (props.width ? props.width : "600px")};
  max-width: 100%;

  height: ${props => (props.height ? props.height : "545px")};

  max-height: 85%;

  ${props => props.theme.breakpoints.down("xs")} {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 92%;
    max-height: 92%;
    border-radius: 0;
  }
  overflow: hidden;
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
  outline: none;
  border-radius: ${props => props.theme.spacing(1.5)}px;
`;

export const Heading = styled(Flex)`
  background-color: ${props => props.theme.palette.grey[100]};
`;

const Actions = styled(Flex)`
  border-top: 1px solid #f1f0f4;
`;

export const Content = styled(Flex)`
  overflow: auto;
`;

export type ModalProps = {
  headingAction?: React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
  primaryAction: {
    text: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick?: () => void;
  };
  secondaryAction: {
    text: string;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    onClick: () => void;
  };
  width?: string;
  height?: string;
  name?: string;
} & ModalBaseProps;

export const CustomModal: React.FunctionComponent<ModalProps> = ({
  title,
  primaryAction,
  secondaryAction,
  children,
  loading,
  width,
  height,
  name,
  headingAction,
  ...props
}) => {
  return (
    <ModalBase {...props} data-cy={`${name}-modal`}>
      <StyledFlex width={width} height={height} flexDirection="column">
        {loading && <ModalSizeLoader data-cy={"modal-loader"} />}
        <Heading p={1} justifyContent="space-between" alignItems="center">
          {headingAction && headingAction}
        </Heading>
        <Content mb={2} px={[1, 3]} flex={1}>
          {children}
        </Content>

        <Flex px={3} py={0.5} justifyContent="flex-end">
          <Box mr={0.5}>
            <StyledButton
              size="small"
              data-cy="secondary-action"
              onClick={secondaryAction.onClick}
              disabled={secondaryAction.disabled}
              type={secondaryAction.type}
            >
              {secondaryAction.text}
            </StyledButton>
          </Box>
        </Flex>
        <Actions flexDirection="column">
          <Flex px={3} py={1} justifyContent="flex-end">
            <Button
              data-cy="primary-action"
              onClick={primaryAction.onClick}
              disabled={primaryAction.disabled}
              type={primaryAction.type}
            >
              {primaryAction.text}
            </Button>
          </Flex>
        </Actions>
      </StyledFlex>
    </ModalBase>
  );
};
