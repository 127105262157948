import React, { useRef, useEffect, useState } from "react";
import { FormikProps, Field } from "formik";

import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";

import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import { RoadIcon } from "components/Atoms/Icons";

import FormControl from "@material-ui/core/FormControl";

import { Loader, Flex, Box, InputLabel, Input } from "components/Atoms";
import EmojiPicker from "components/Atoms/EmojiPicker";

import useBeforeUnload from "components/Hooks/use-before-unload";

import { CommentsModal } from "components/Organisms/CommentsModal";

import {
  SurveyQuestionAnswerInput,
  QuestionType,
} from "typings/graphql-global-types";

import {
  GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys as Survey,
  GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion,
  GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys_surveyQuestions_surveyQuestionAnswer as surveyQuestionAnswer,
} from "../../../Queries/types/GetOwnGoalsSurveyGroup";

import { getAcademicYear } from "utils/dateHelpers";

import CommentsColumn from "./CommentsColumn";
import GoalColumn from "./GoalColumn";
import ScheduledDateColumn from "./ScheduledDateColumn";
import AssessmentColumn from "./AssessmentColumn";

export interface FormValues {
  surveyQuestionAnswers: SurveyQuestionAnswerInput[];
}

export interface TimelineItem {
  id: string;
  name: string;
  description?: string;
  type: QuestionType | "divider";
  scheduledDate: any | null;
  survey?: Survey;
  surveyQuestionAnswer?: surveyQuestionAnswer | null;
  __typename?: string;
}

interface Props extends FormikProps<FormValues> {
  questions: SurveyQuestion[];
  survey: Survey;
}

const StyledForm = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexFormControl = styled(FormControl)`
  flex: auto;
`;

export const StyledInputLabel = styled(InputLabel)`
  color: ${props => props.theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 400;
`;

const VerbalInputLabel = styled(StyledInputLabel)`
  position: inherit;
  margin-top: 0;
`;

const StyledInput = styled(Input)`
  min-width: 250px;
  width: 100%;
  max-width: 750px;

  textarea {
    resize: vertical;
    background-color: #fff;
  }
`;

export const Cell = styled(Box)<any>`
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  background-color: ${props => props.alternateColor && "#fff"};
  ${props => props.theme.breakpoints.down("sm")} {
    ${props => (props.hideMobile ? "display: none" : "")};
  }
`;

export const TimelineCell = styled(Cell)`
  position: relative;
  :after {
    content: "";
    position: absolute;
    width: 4px;
    background-color: ${props => props.theme.palette.grey[300]};
    top: ${props => (props.noTopLine ? 50 : 0)}%;
    bottom: ${props => (props.noBottomLine ? 50 : 0)}%;
    left: 32px;
  }
`;

export const MobileTimelineCell = styled(Cell)`
  position: relative;
  ${props => props.theme.breakpoints.down("sm")} {
    :after {
      content: "";
      position: absolute;
      width: 4px;
      background-color: ${props => props.theme.palette.grey[300]};
      top: ${props => (props.noTopLine ? 50 : 0)}%;
      bottom: ${props => (props.noBottomLine ? 50 : 0)}%;
      left: 32px;
    }
  }
`;

const FormSavingLoader = styled(Loader)`
  position: absolute;
  background-color: #6c6c6cab;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

const EmojiButtonBox = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 0px;
`;

const EmojiInputBox = styled(Box)`
  position: relative;
`;

function validateText(value: string, t: any) {
  let error;
  if (value && value.length > 400) {
    error = t("globals.validations.assessmentMax");
  }
  return error;
}

export const SurveyForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  handleChange,
  setFieldValue,
  isValid,
  isSubmitting,
  questions,
  survey,
}: Props) => {
  const { t } = useTranslation();
  const verbalInputsRef = useRef<HTMLTextAreaElement[]>([]);
  // you can access the elements with itemsRef.current[n]

  const [
    showCommentsModal,
    setShowCommentsModal,
  ] = useState<TimelineItem | null>(null);

  const onShowComments = (question: TimelineItem) => {
    setShowCommentsModal(question);
  };

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onAnswerChanged = (name: string, value: number | null) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const numericQuestions = questions.filter(
    q => q.type === QuestionType.numeric,
  );

  const verbalQuestions = questions.filter(q => q.type === QuestionType.verbal);

  useEffect(() => {
    verbalInputsRef.current = verbalInputsRef.current.slice(
      0,
      verbalQuestions.length,
    );
  }, [verbalQuestions.length]);

  const { startDate, middleDate, endDate } = getAcademicYear(survey.createdAt);

  const timelineItems: TimelineItem[] = [];
  let i = 0;
  let startAdded = false;
  let middleAdded = false;
  let scheduledDate;
  for (const q of numericQuestions) {
    scheduledDate = q.scheduledDate && new Date(q.scheduledDate);

    if (!i && !q.scheduledDate) {
      timelineItems.push({
        id: "start",
        name: t("containers.ownGoals.surveyForm.startOfAcademicYear"),
        scheduledDate: null,
        type: "divider",
      });
      startAdded = true;
    }

    if (
      !startAdded &&
      scheduledDate > startDate &&
      scheduledDate < middleDate
    ) {
      timelineItems.push({
        id: "start",
        name: t("containers.ownGoals.surveyForm.startOfAcademicYear"),
        scheduledDate: startDate,
        type: "divider",
      });
      startAdded = true;
    }

    if (!startAdded && scheduledDate > middleDate) {
      timelineItems.push({
        id: "middle",
        name: t("containers.ownGoals.surveyForm.middleOfAcademicYear"),
        scheduledDate: middleDate,
        type: "divider",
      });
      startAdded = true;
      middleAdded = true;
    }

    if (!middleAdded && scheduledDate > middleDate) {
      timelineItems.push({
        id: "middle",
        name: t("containers.ownGoals.surveyForm.middleOfAcademicYear"),
        scheduledDate: middleDate,
        type: "divider",
      });
      middleAdded = true;
    }

    timelineItems.push(q as TimelineItem);
    i += 1;
  }

  if (scheduledDate && scheduledDate > middleDate && scheduledDate < endDate) {
    timelineItems.push({
      id: "end",
      name: t("containers.ownGoals.surveyForm.endOfAcademicYear"),
      type: "divider",
      scheduledDate: endDate,
    });
  } else if (scheduledDate && scheduledDate < middleDate) {
    timelineItems.push({
      id: "end",
      name: t("containers.ownGoals.surveyForm.middleOfAcademicYear"),
      type: "divider",
      scheduledDate: endDate,
    });
  } else {
    timelineItems.push({
      id: "end",
      name: t("containers.ownGoals.surveyForm.endOfAcademicYear"),
      type: "divider",
      scheduledDate: endDate,
    });
  }

  return (
    <>
      <StyledForm>
        {isSubmitting && <FormSavingLoader />}
        <Flex pb={1} mt={2} flexDirection="column">
          <Flex>
            <Flex flex={1}>
              {numericQuestions.length > 0 && (
                <Flex flex={1} flexWrap="wrap">
                  <GoalColumn
                    width={["60%", "50%", "30%"]}
                    questions={questions}
                    timelineItems={timelineItems}
                  />

                  <ScheduledDateColumn
                    width={["25%", "25%", "20%"]}
                    timelineItems={timelineItems}
                  />

                  <Hidden mdUp>
                    <CommentsColumn
                      timelineItems={timelineItems}
                      width={["15%", "25%"]}
                      onClick={onShowComments}
                    />
                  </Hidden>

                  <AssessmentColumn
                    values={values}
                    timelineItems={timelineItems}
                    width={["100%", "100%", "30%"]}
                    onChange={onAnswerChanged}
                  />

                  <Hidden smDown>
                    <CommentsColumn
                      timelineItems={timelineItems}
                      width={["10%", "17.5%", "20%"]}
                      onClick={onShowComments}
                    />
                  </Hidden>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex pl={2} pr={1} mt={[4]} flexWrap="wrap">
            {verbalQuestions.map((q, index) => {
              const answer = values.surveyQuestionAnswers.find(
                a => a.surveyQuestionId === q.id,
              );

              const i = values.surveyQuestionAnswers.findIndex(
                a => a.surveyQuestionId === q.id,
              );

              let verbalLabel = q.name;
              let verbalDesc = q.description;
              if (index === 0) {
                verbalLabel = t("components.organisms.editOwnGoals.strengths");
                verbalDesc = t(
                  "components.organisms.editOwnGoals.strengthsDescription",
                );
              } else if (index === 1) {
                verbalLabel = t(
                  "components.organisms.editOwnGoals.howToAchieveGoals",
                );
                verbalDesc = t(
                  "components.organisms.editOwnGoals.howToAchieveGoalsDescription",
                );
              }

              return (
                <FlexFormControl key={`verbalquestiom-${i}`}>
                  <Flex alignItems="center">
                    <Box mr={1}>
                      {index === 0 && <FitnessCenterIcon />}
                      {index === 1 && <RoadIcon />}
                    </Box>
                    <VerbalInputLabel htmlFor="verbalAssessment">
                      {verbalLabel}
                    </VerbalInputLabel>
                  </Flex>

                  <Flex
                    minWidth="250px"
                    width="100%"
                    maxWidth="600px"
                    flexDirection="column"
                    pr={4}
                  >
                    <Field
                      name={`surveyQuestionAnswers.${i}.verbalText`}
                      validate={(value: string) => validateText(value, t)}
                    >
                      {({ meta }: { meta: any }) => (
                        <>
                          <EmojiInputBox
                            flex={1}
                            minWidth="250px"
                            width="100%"
                            maxWidth="600px"
                          >
                            <StyledInput
                              inputRef={el => (verbalInputsRef.current[i] = el)}
                              id="verbalAnswer"
                              name={`surveyQuestionAnswers.${i}.verbalText`}
                              placeholder={verbalDesc}
                              multiline
                              rows={4}
                              rowsMax={40}
                              helperText={
                                meta.touched && meta.error
                                  ? (meta.error as string)
                                  : ""
                              }
                              error={meta.touched && Boolean(meta.error)}
                              value={(answer && answer.verbalText) || ""}
                              onChange={change.bind(
                                null,
                                `surveyQuestionAnswers.${i}.verbalText`,
                              )}
                              fullWidth
                            />
                            <EmojiButtonBox>
                              <EmojiPicker
                                onSelect={e => {
                                  const start = (verbalInputsRef.current[
                                    i
                                  ]! as HTMLTextAreaElement).selectionStart;
                                  const end = (verbalInputsRef.current[
                                    i
                                  ]! as HTMLTextAreaElement).selectionEnd;

                                  let currentText =
                                    values.surveyQuestionAnswers[i]
                                      .verbalText || "";

                                  let newText =
                                    currentText.substring(0, start) +
                                    e.native +
                                    currentText.substring(end);

                                  setFieldValue(
                                    `surveyQuestionAnswers.${i}.verbalText`,
                                    newText,
                                  );
                                }}
                              />
                            </EmojiButtonBox>
                          </EmojiInputBox>
                        </>
                      )}
                    </Field>
                  </Flex>
                </FlexFormControl>
              );
            })}
          </Flex>
        </Flex>
      </StyledForm>
      {showCommentsModal && (
        <CommentsModal
          name={showCommentsModal.name}
          comments={showCommentsModal.surveyQuestionAnswer?.comments || []}
          open={true}
          onModalClosed={() => setShowCommentsModal(null)}
        />
      )}
    </>
  );
};
