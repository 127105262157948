import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "components/Atoms";

import { Cell, StyledInputLabel, TimelineItem, TimelineCell } from "./index";

import { GoalIcon } from "components/Atoms/Icons";

import { QuestionType } from "typings/graphql-global-types";
import { GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetOwnGoalsSurveyGroup";

interface Props {
  questions: SurveyQuestion[];
  timelineItems: TimelineItem[];
  width: string[];
}

const ZIndexedFlex = styled(Flex)`
  z-index: 1;
`;

const TimelineCircle = styled.div`
  background-color: #fff;
  border: 2px solid ${props => props.theme.palette.primary.dark};
  height: 26px;
  width: 26px;
  margin: 5px;
  border-radius: 50%;
  z-index: 1;
`;

// ["70%", "50%", "40%"]
const GoalColumn = ({ questions, timelineItems, width }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell pl={2} pr={1} width={width} order={1} flexGrow={1}>
        <Flex alignItems="center" height="100%">
          <StyledInputLabel>
            {t("containers.ownGoals.surveyForm.goals")}
          </StyledInputLabel>
        </Flex>
      </Cell>
      {timelineItems.map((q, i) => (
        <TimelineCell
          key={`goalname-${q.id}`}
          py={1}
          pl={2}
          pr={1}
          alternateColor={q.type !== QuestionType.numeric}
          width={width}
          order={i + 2}
          flexGrow={1}
          noTopLine={i === 0}
          noBottomLine={i === timelineItems.length - 1}
        >
          <Flex alignItems="flex-start" pr={[0, 0, 2]}>
            <ZIndexedFlex alignItems="center" pt={0.5} mr={2} height={"54px"}>
              {q.type === QuestionType.numeric ? (
                <GoalIcon
                  tooltip={q.name}
                  index={questions.indexOf(q as SurveyQuestion) + 1}
                />
              ) : (
                <TimelineCircle />
              )}
            </ZIndexedFlex>
            <Flex pt={0.5} minHeight="54px" alignItems="center">
              <Text>{q.name}</Text>
            </Flex>
          </Flex>
        </TimelineCell>
      ))}
    </>
  );
};

export default GoalColumn;
