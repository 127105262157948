import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ApolloProvider } from "services/apollo";
import "services/firebase";
import "services/i18n";

import App from "containers/App";
import styled from "styled-components";
import useFont from "components/Hooks/use-font";
import portfolioTheme, { WebFontConfig } from "components/Themes/Portfolio";
import ThemeProvider from "components/Themes/ThemeProvider";
import { Loader } from "components/Atoms";
import { SnackbarProvider } from "components/Contexts/SnackbarContext";

import * as serviceWorker from "serviceWorker";

const FullscreenLoader = styled(Loader)`
  height: 100vh;
  width: 100vw;
`;

const FontLoader = () => {
  const { loading } = useFont(WebFontConfig);
  if (!loading) return <App />;
  else return <FullscreenLoader />;
};

const app = (
  <Suspense fallback={<FullscreenLoader />}>
    <ThemeProvider theme={portfolioTheme}>
      <SnackbarProvider>
        <ApolloProvider>
          <>
            <CssBaseline />
            <FontLoader></FontLoader>
          </>
        </ApolloProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
