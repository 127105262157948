import * as React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import { AddLinkForm, FormValues } from "./Form";

export interface AddLinkModalProps {
  open: boolean;
  onModalClosed: (result: boolean) => void;
  onAdd: (link: string[]) => void;
  loading: boolean;
  title?: string;
}

export const AddLinkModal: React.FunctionComponent<AddLinkModalProps> = ({
  onModalClosed,
  open,
  onAdd,
  loading: addLoading,
  title,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        link: "",
      }}
      validationSchema={Yup.object().shape({
        link: Yup.string()
          .required(t("globals.validations.linkRequired"))
          .max(400, t("globals.validations.linkMax"))
          .url(t("globals.validations.linkNotValid")),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        onAdd([values.link]);
      }}
    >
      {props => {
        const { handleSubmit, isValid } = props;

        return (
          <Modal
            height="300px"
            error={undefined}
            loading={addLoading}
            primaryAction={{
              text: t("globals.add"),
              onClick: handleSubmit,
              disabled: !isValid,
            }}
            secondaryAction={{
              text: t("globals.cancel"),
              onClick: onCancel,
            }}
            title={title ? title : t("components.organisms.addLinkModal.title")}
            open={open}
          >
            <AddLinkForm {...props} />
          </Modal>
        );
      }}
    </Formik>
  );
};
