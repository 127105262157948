import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import GroupIcon from "@material-ui/icons/Group";

import { Flex, Select, MenuItem, Checkbox } from "components/Atoms";
import { SelectProps } from "components/Atoms/InputField";

const ProfileCircle = styled(Flex)`
  font-size: 0.825em;
  width: 36px;
  height: 36px;
  line-height: 1;
  text-align: center;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.primary.light};
  color: ${props => props.theme.palette.primary.dark};
  vertical-align: middle;
  margin-right: ${props => props.theme.spacing(1.5)}px;
  font-weight: 500;
  padding-top: 3px;
  justify-content: center;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 5px;
`;

interface UserMenuItemProps {
  checked: boolean;
  label: string;
  icon: "person" | "all";
  value: any;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const UserMenuItem: React.FC<UserMenuItemProps> = ({
  label,
  checked,
  onClick,
  icon,
  value,
}) => {
  return (
    <StyledMenuItem value={value} onClick={onClick} divider>
      <Checkbox checked={checked} />
      <Flex alignItems="center" mt={-0.5}>
        <ProfileCircle>
          {icon === "all" ? <GroupIcon fontSize="small" /> : label[0]}
        </ProfileCircle>
        {label}
      </Flex>
    </StyledMenuItem>
  );
};

export interface UserMultiSelectProps extends SelectProps {
  selected: string[];
  users: { id: string; displayName: string }[];
}

export const UserMultiSelect: React.FC<UserMultiSelectProps> = ({
  users,
  selected,
  ...rest
}) => {
  const { t } = useTranslation();

  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    const val = event.target.value as string[];
    if (val.find(v => v === "all")) {
      if (val.filter(v => v !== "all").length === users.length) {
        event.target.value = [];
      } else {
        event.target.value = users.map(u => u.id);
      }
    }
    if (rest.onChange) {
      rest.onChange(event, child);
    }
  };

  return (
    <Select
      value={selected}
      multiple
      {...rest}
      onChange={onChange}
      renderValue={selected => {
        if ((selected as string[]).length === 0) {
          return t("components.molecules.userMultiSelect.placeholder");
        }

        return t("components.molecules.userMultiSelect.learnersSelected", {
          count: (selected as string[]).length,
        });
      }}
    >
      <MenuItem value="" disabled>
        {t("components.molecules.userMultiSelect.placeholder")}
      </MenuItem>
      <UserMenuItem
        value="all"
        icon="all"
        label={t("components.molecules.userMultiSelect.all")}
        checked={selected.length === users.length}
      />

      {users.map(({ id, displayName }) => (
        <UserMenuItem
          value={id}
          key={id}
          icon="person"
          label={displayName}
          checked={!!selected.find(s => s === id)}
        />
      ))}
    </Select>
  );
};
