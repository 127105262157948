import React from "react";
import { useTranslation } from "react-i18next";
import { Box, ToggleButtonGroup, ToggleButton } from "components/Atoms";

interface Props {
  active: boolean;
  activeCount: number | null;
  archivedCount: number | null;
  onChanged: (state: boolean) => void;
}

export const Filter: React.FC<Props> = ({
  active,
  activeCount,
  archivedCount,
  onChanged,
}) => {
  const { t } = useTranslation();

  const activeCountStr = activeCount ? ` (${activeCount})` : "";
  const archivedCountStr = archivedCount ? ` (${archivedCount})` : "";
  return (
    <Box>
      <ToggleButtonGroup value={active ? "active" : "archived"}>
        <ToggleButton key={1} value={"active"} onClick={() => onChanged(true)}>
          {t("containers.teacherHome.activeFilter", {
            activeCount: activeCountStr,
          })}
        </ToggleButton>
        <ToggleButton
          key={2}
          value={"archived"}
          onClick={() => onChanged(false)}
        >
          {t("containers.teacherHome.archivedFilter", {
            archivedCount: archivedCountStr,
          })}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
