import React from "react";
import styled from "styled-components";
import { Flex, Tooltip } from "components/Atoms";

export interface LearnerAvatarProps {
  photoUrl?: string;
  givenName: string;
  familyName: string;
}

const ProfileCircle = styled(Flex)`
  font-size: 1em;
  width: 2em;
  height: 2em;
  line-height: 1;
  text-align: center;
  border-radius: 50%;
  background-color: #d0d0d2;
  color: ${props => props.theme.palette.primary.dark};
  vertical-align: middle;
  font-weight: 600;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  border-radius: 50%;
  margin: 0px 2px;
  overflow: hidden;
  position: relative;
  height: 2em;
  width: 2em;
`;

export const LearnerAvatar = ({
  photoUrl,
  givenName,
  familyName,
}: LearnerAvatarProps) => {
  return (
    <Flex justifyContent="center">
      <Tooltip
        title={`${givenName ? givenName : ""} ${familyName ? familyName : ""}`}
        arrow
      >
        {photoUrl ? (
          <StyledImg
            src={photoUrl}
            alt={`${givenName ? givenName[0] : ""}${
              familyName ? familyName[0] : ""
            }`}
          />
        ) : (
          <ProfileCircle>
            {`${givenName ? givenName[0] : ""}${
              familyName ? familyName[0] : ""
            }`}
          </ProfileCircle>
        )}
      </Tooltip>
    </Flex>
  );
};
