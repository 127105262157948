import React, { useState } from "react";
import { SubmittedActivityListQueries } from "../SubmittedActivityListQueries";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import {
  Text,
  GoodIcon,
  FairIcon,
  FairlyGoodIcon,
  VeryGoodIcon,
  ExcellentIcon,
  Box,
  Loader,
  Tooltip,
} from "components/Atoms";
import { AssessmentType } from "typings/graphql-global-types";
import {
  AssessmentValueScale3,
  AssessmentValueScale5,
} from "components/Atoms/NumericAssessmentInput";
import styled from "styled-components";
import {
  GetEducationalPackageSubmittedActivities_allActivities_data as Activity,
  GetEducationalPackageSubmittedActivities_allActivities_data_educationalPackageGoals as Goal,
  GetEducationalPackageSubmittedActivities_allActivities_data_learningAchievements_numericAssessments as NumericAssessment,
  GetEducationalPackageSubmittedActivities_allActivities_data_learningAchievements_verbalAssessments as VerbalAssessment,
} from "../SubmittedActivityListQueries/types/GetEducationalPackageSubmittedActivities";
import { VerbalAssessmentModal } from "components/Organisms";
import { QuestionProps } from "components/Organisms/VerbalAssessmentModal";

import { getAssessmentScale, getAssessmentText } from "utils/scaleHelpers";

const Scrollable = styled(Box)`
  width: 100%;
  overflow: auto;
  height: 90vh;
`;

const StyledTable = styled(Table)<any>`
  overflow: auto;
  box-shadow: none;
  min-height: 10vh;
  max-height: 90vh;
`;

const StickyStyledTableHeaderCell = styled(TableCell)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

interface PartialNumericAssessment {
  value: number | null;
  educationalPackageGoalId: string;
  __typename?: string;
  type?: AssessmentType;
}

function getAndOrderAssessments(
  assessments: NumericAssessment[],
  goals: Goal[],
): PartialNumericAssessment[] {
  return goals.map(g => {
    const assessment = assessments.find(
      a => a.educationalPackageGoalId === g.id,
    );
    if (!assessment) {
      return {
        value: null,
        educationalPackageGoalId: g.id,
      };
    }
    return assessment!;
  });
}

interface SubmittedActivityListProps {
  educationalPackageId: string;
}

const StyledTableBody = styled(TableBody)`
  vertical-align: top;
`;

const StyledTableRow = styled(TableRow)`
  vertical-align: inherit;
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StyledAssessmentTableCell = styled(StyledTableCell)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledTextBlock = styled(Text)`
  padding-top: 10px;
  height: 4rem;
  margin-bottom: 1.25rem;
`;

const AssessmentBox = styled(Box)`
  height: 4rem;
  margin-bottom: 1.25rem;
`;

const EmptyAssessmentBox = styled(Box)`
  padding-top: 12px;
`;

interface ComponentProps {
  component?: any;
}

const StyledLink = styled(Link)<ComponentProps>`
  vertical-align: top;
`;
const StyledText = styled(Text)`
  vertical-align: top;
  line-height: 1.2;
`;

interface AssessmentCellProps {
  numericAssessments: PartialNumericAssessment[];
  verbalAssessment?: VerbalAssessment;
  onClickViewVerbalEvaluation: (verbalAssessment: VerbalAssessment) => void;
  goals: Goal[] | null;
}

const AssessmentCell: React.FC<AssessmentCellProps> = ({
  numericAssessments,
  verbalAssessment,
  onClickViewVerbalEvaluation,
  goals,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {numericAssessments.map(assessment => {
        let tooltipText = t(`globals.empty.noAssessment`);

        const scale = getAssessmentScale(
          goals,
          assessment.educationalPackageGoalId,
        );

        const scaleTexts = getAssessmentText(
          goals,
          assessment.educationalPackageGoalId,
          assessment.type,
        );

        if (scale === 5 && assessment.value === AssessmentValueScale5.Fair) {
          tooltipText =
            (scaleTexts && scaleTexts[0]) ||
            t(`globals.numericAssessments.${assessment.type}.fair`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.FairlyGood
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[1]) ||
            t(`globals.numericAssessments.${assessment.type}.fairlyGood`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.Good
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[2]) ||
            t(`globals.numericAssessments.${assessment.type}.mediocre`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.VeryGood
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[3]) ||
            t(`globals.numericAssessments.${assessment.type}.Good`);
        } else if (
          scale === 5 &&
          assessment.value === AssessmentValueScale5.Excellent
        ) {
          tooltipText =
            (scaleTexts && scaleTexts[4]) ||
            t(`globals.numericAssessments.${assessment.type}.Excellent`);
        } else if (assessment.value === AssessmentValueScale3.Fair) {
          tooltipText =
            (scaleTexts && scaleTexts[0]) ||
            t(`globals.numericAssessments.${assessment.type}.fair`);
        } else if (assessment.value === AssessmentValueScale3.Good) {
          tooltipText =
            (scaleTexts && scaleTexts[1]) ||
            t(`globals.numericAssessments.${assessment.type}.mediocre`);
        } else if (assessment.value === AssessmentValueScale3.Excellent) {
          tooltipText =
            (scaleTexts && scaleTexts[2]) ||
            t(`globals.numericAssessments.${assessment.type}.excellent`);
        }

        return (
          <Tooltip
            key={assessment.educationalPackageGoalId}
            arrow
            PopperProps={{ style: { marginTop: "-25px" } }}
            title={tooltipText}
          >
            <AssessmentBox
              width={["100%"]}
              key={assessment.educationalPackageGoalId}
            >
              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Fair && (
                  <FairIcon view checked></FairIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.FairlyGood && (
                  <FairlyGoodIcon view checked></FairlyGoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Good && (
                  <GoodIcon view checked></GoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.VeryGood && (
                  <VeryGoodIcon view checked></VeryGoodIcon>
                )}

              {scale === 5 &&
                assessment.value === AssessmentValueScale5.Excellent && (
                  <ExcellentIcon view checked></ExcellentIcon>
                )}

              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Fair && (
                  <FairIcon view checked></FairIcon>
                )}
              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Good && (
                  <GoodIcon view checked></GoodIcon>
                )}
              {scale !== 5 &&
                assessment.value === AssessmentValueScale3.Excellent && (
                  <ExcellentIcon view checked></ExcellentIcon>
                )}
              <EmptyAssessmentBox>
                {!assessment.value && <RemoveOutlinedIcon fontSize="small" />}
              </EmptyAssessmentBox>
            </AssessmentBox>
          </Tooltip>
        );
      })}
      {!(verbalAssessment && verbalAssessment.assessmentText) && (
        <StyledText variant="body1">
          {t(
            "containers.learnerEducationPackage.submittedActivitiesList.noVerbalEvaluation",
          )}
        </StyledText>
      )}
      {verbalAssessment && verbalAssessment.assessmentText && (
        <StyledLink
          component="button"
          variant="body2"
          onClick={() => {
            onClickViewVerbalEvaluation(verbalAssessment);
          }}
        >
          {t(
            "containers.learnerEducationPackage.submittedActivitiesList.verbalEvaluation",
          )}
        </StyledLink>
      )}
    </>
  );
};

export const SubmittedActivityList: React.FC<SubmittedActivityListProps> = ({
  educationalPackageId,
}) => {
  const { t } = useTranslation();

  const [verbalAssessmentModalOpen, setVerbalAsssessmentModalOpen] = useState(
    false,
  );

  const [
    verbalAssessmentModalAssessment,
    setVerbalAssessmentModalAssessment,
  ] = useState<QuestionProps | undefined>();

  const onClickViewVerbalEvaluation = (
    activity: Activity,
    verbalAssessment: VerbalAssessment,
  ): void => {
    setVerbalAsssessmentModalOpen(true);
    setVerbalAssessmentModalAssessment({
      name: activity.name,
      answer: verbalAssessment.assessmentText,
      type: verbalAssessment.type,
      assessor:
        verbalAssessment.type === AssessmentType.teacher
          ? `${verbalAssessment.user.givenName} ${verbalAssessment.user.familyName}`
          : undefined,
      helperText:
        activity.verbalAssessmentSetting?.learnerHelperText ||
        t("globals.verbalAssessment.learnerHelperText"),
    });
  };

  const onCloseVerbalAssessmentModal = () => {
    setVerbalAsssessmentModalOpen(false);
    setVerbalAssessmentModalAssessment(undefined);
  };

  return (
    <>
      <SubmittedActivityListQueries educationalPackageId={educationalPackageId}>
        {({ activities: { loading, data } }) => {
          return (
            <Scrollable>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StickyStyledTableHeaderCell>
                      {t(
                        "containers.learnerEducationPackage.submittedActivitiesList.activity",
                      )}
                    </StickyStyledTableHeaderCell>
                    <StickyStyledTableHeaderCell>
                      {t(
                        "containers.learnerEducationPackage.submittedActivitiesList.goal",
                      )}
                    </StickyStyledTableHeaderCell>
                    <StickyStyledTableHeaderCell align="center">
                      {t(
                        "containers.learnerEducationPackage.submittedActivitiesList.me",
                      )}
                    </StickyStyledTableHeaderCell>
                    <StickyStyledTableHeaderCell align="center">
                      {t(
                        "containers.learnerEducationPackage.submittedActivitiesList.teacher",
                      )}
                    </StickyStyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <StyledTableBody>
                  {!loading && data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {t("globals.empty.noActivities")}
                      </TableCell>
                    </TableRow>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        colSpan={7}
                        style={{ height: 120 }}
                      >
                        <Loader />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    data.map(activity => {
                      const numericAssessments = activity.learningAchievements
                        .length
                        ? activity.learningAchievements[0].numericAssessments
                        : [];

                      const verbalAssessments = activity.learningAchievements
                        .length
                        ? activity.learningAchievements[0].verbalAssessments
                        : [];

                      const goals = activity.educationalPackageGoals!;

                      const learnerNumericAssessments = getAndOrderAssessments(
                        numericAssessments.filter(
                          a => a.type === AssessmentType.self,
                        ),
                        goals,
                      );

                      const learnerVerbalAssessment = verbalAssessments.find(
                        a => a.type === AssessmentType.self,
                      );

                      const teacherNumericAssessments = getAndOrderAssessments(
                        numericAssessments.filter(
                          a => a.type === AssessmentType.teacher,
                        ),
                        goals,
                      );

                      const teacherVerbalAssessment = verbalAssessments.find(
                        a => a.type === AssessmentType.teacher,
                      );

                      return (
                        <StyledTableRow key={activity.id}>
                          <StyledTableCell style={{ width: "30%" }}>
                            <StyledTextBlock variant="body1">
                              {activity.name}
                            </StyledTextBlock>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "46%" }}>
                            {activity.educationalPackageGoals!.map(goal => {
                              return (
                                <StyledTextBlock variant="body1" key={goal.id}>
                                  {goal.name}
                                </StyledTextBlock>
                              );
                            })}
                          </StyledTableCell>
                          <StyledAssessmentTableCell
                            align="center"
                            style={{ width: "12%" }}
                          >
                            <AssessmentCell
                              goals={activity.educationalPackageGoals}
                              numericAssessments={learnerNumericAssessments}
                              verbalAssessment={learnerVerbalAssessment}
                              onClickViewVerbalEvaluation={onClickViewVerbalEvaluation.bind(
                                null,
                                activity,
                              )}
                            />
                          </StyledAssessmentTableCell>
                          <StyledAssessmentTableCell
                            align="center"
                            style={{ width: "12%" }}
                          >
                            <AssessmentCell
                              goals={activity.educationalPackageGoals}
                              numericAssessments={teacherNumericAssessments}
                              verbalAssessment={teacherVerbalAssessment}
                              onClickViewVerbalEvaluation={onClickViewVerbalEvaluation.bind(
                                null,
                                activity,
                              )}
                            />
                          </StyledAssessmentTableCell>
                        </StyledTableRow>
                      );
                    })}
                </StyledTableBody>
              </StyledTable>
            </Scrollable>
          );
        }}
      </SubmittedActivityListQueries>
      {verbalAssessmentModalOpen && verbalAssessmentModalAssessment && (
        <VerbalAssessmentModal
          open={verbalAssessmentModalOpen}
          question={verbalAssessmentModalAssessment}
          onModalClosed={onCloseVerbalAssessmentModal}
        />
      )}
    </>
  );
};
