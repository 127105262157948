import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as firebase from "firebase/app";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import { pathOr } from "ramda";
import { Flex, Box, LogoEmblem, Loader } from "components/Atoms";

import { useHistory, useLocation } from "react-router";

import { HOME, LOGIN } from "routes";

import { LanguageFabs } from "components/Molecules/LanguageFabs";

import { LoginForm } from "components/Organisms/LoginForm";
import { ConfirmEmailLinkForm } from "components/Organisms/ConfirmEmailLinkForm";
import { useEmailLinkState } from "components/Hooks/firebase-hooks";
import { useAnalytics } from "components/Hooks/firebase-hooks";
import { useSnackbar } from "components/Contexts/SnackbarContext";

import { setLanguageChanged } from "services/apollo";

import { Footer } from "./components";

import background from "images/login_background.jpg";

const FullscreenLoader = styled(Loader)`
  height: 100vh;
  width: 100vw;
`;

const GrowContainer = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.down("sm")} {
    @media (orientation: landscape) {
      margin-top: 50px;
    }
  }
`;

const Background = styled(Flex)`
  min-height: 100vh;
  background: url(${background}) no-repeat center center fixed;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
`;

const smallIcon = (props: any) => `
  position: absolute;
  top: ${props.theme.spacing(2)}px;
  left: ${props.theme.spacing(2)}px;
  z-index: 1;
  width: 150px;
`;
const ResponsiveLogoWrapper = styled(Box)`
  width: 29rem;
  ${props => props.theme.breakpoints.down("sm")} {
    @media (orientation: landscape) {
      ${props => smallIcon(props)}
    }
  }

  ${props => props.theme.breakpoints.down("xs")} {
    ${props => smallIcon(props)}
  }
`;

export const Login: React.FunctionComponent = () => {
  const [confirmEmail, setConfirmEmail] = useState<string | undefined>();
  const location = useLocation();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { analytics } = useAnalytics();

  analytics.logEvent("page_view" as string, {
    page_title: "login",
    page_path: history.location.pathname,
  });

  const onSuccess = () => {
    const to = pathOr(HOME, "state.from.pathname".split("."), location);
    history.replace({ pathname: to });
  };

  const emailLinkResp = useEmailLinkState(
    firebase.auth(),
    onSuccess,
    confirmEmail ? true : false,
  );

  const loading = emailLinkResp.loading;

  const errorMessage = emailLinkResp.error && emailLinkResp.error.message;

  useEffect(() => {
    if (errorMessage) {
      showSnackbar({ id: "login-error", type: "error", message: errorMessage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  if (loading) {
    return <FullscreenLoader />;
  }

  const onError = (err?: firebase.auth.Error) => {
    if (err) {
      showSnackbar({
        id: "login-error",
        type: "error",
        message: t("containers.login.generalLoginError"),
      });
    }
  };

  const onLanguageChanged = () => {
    setLanguageChanged();
  };

  const onEmailLinkAccepted = (email: string) => {
    setConfirmEmail(email);
  };

  const onBack = () => {
    history.replace({ pathname: LOGIN });
    window.location.reload();
  };

  return (
    <Background flexDirection="column">
      <LanguageFabs onChanged={onLanguageChanged} />
      {/* @ts-ignore */}
      <GrowContainer component="main" maxWidth="sm">
        <Flex
          justifyContent="center"
          flex={1}
          flexDirection="column"
          mt={[0, 0, -10]}
        >
          <Flex justifyContent="center">
            <ResponsiveLogoWrapper>
              <LogoEmblem />
            </ResponsiveLogoWrapper>
          </Flex>
          <Box px={{ xs: 1, md: 10 }} pt={{ xs: 2 }}>
            {!emailLinkResp.emailConfirmation && (
              <LoginForm
                onSuccess={onSuccess}
                onError={onError}
                redirectRoute={LOGIN}
              />
            )}
            {emailLinkResp.emailConfirmation && (
              <ConfirmEmailLinkForm
                onBack={onBack}
                onAccepted={onEmailLinkAccepted}
              />
            )}
          </Box>
        </Flex>
      </GrowContainer>
      <Flex mt={2} justifyContent="center">
        <Footer />
      </Flex>
    </Background>
  );
};

export default Login;
