import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "components/Atoms";
import { Modal } from "components/Molecules";
import { EGoalCard } from "components/Organisms/SelectOPSEducationalPackageGoalModal/components/EGoalCard";
import { GetEGoalInfo } from "../Queries/types/GetEGoalInfo";

interface Props {
  onClose: () => void;
  data: GetEGoalInfo | undefined;
  loading: boolean;
  open: boolean;
  eGoalIds: string[];
}

export const EGoalInfoModal: React.FC<Props> = ({
  onClose,
  data,
  loading,
  open,
  eGoalIds,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      height="600px"
      width="650px"
      loading={loading}
      primaryAction={{
        text: t("globals.close"),
        onClick: onClose,
      }}
      title={t("components.organisms.eGoalInfoModal.title")}
      open={open}
    >
      <Flex flex={1} mt={2} flexDirection="column">
        {data &&
          data.eGoalsFull.data.map(eGoal => (
            <Box py={1.5} key={`egoal-${eGoal.eGoal.id}`}>
              <EGoalCard
                defaultExpanded={true}
                eGoal={eGoal}
                eGoalIds={eGoalIds}
              />
            </Box>
          ))}
      </Flex>
    </Modal>
  );
};
