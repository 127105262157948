import React from "react";
import styled from "styled-components";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";

import TimelineDot from "@material-ui/lab/TimelineDot";

import AssessmentIcon from "@material-ui/icons/Assessment";

import { format } from "date-fns";

import { Box, Text } from "components/Atoms";

export interface ItemProps {
  id: string;
  content: React.ReactNode;
  title: React.ReactNode;
  createdAt: Date;
}

interface Props {
  items: ItemProps[];
}

const StyledTimelineItem = styled(TimelineItem)`
  &.MuiTimelineItem-missingOppositeContent:before {
    flex: none;
    content: none;
  }
`;

const AssessmentTimeline = ({ items }: Props) => {
  return (
    <Timeline align="left">
      {items.map((item, i) => (
        <StyledTimelineItem key={item.id}>
          <TimelineSeparator>
            <TimelineDot color={"primary"}>
              <AssessmentIcon />
            </TimelineDot>
            {i < items.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Box>
              {item.title}
              <Box minHeight="60px">{item.content && item.content}</Box>
              <Text variant="h6">
                {format(new Date(item.createdAt), "dd.MM.yyyy")}
              </Text>
            </Box>
          </TimelineContent>
        </StyledTimelineItem>
      ))}
    </Timeline>
  );
};

export default AssessmentTimeline;
