import * as React from "react";
import styled from "styled-components";

import { sizing, SizingProps, PaletteProps } from "@material-ui/system";

type LogoProps = SizingProps & PaletteProps;

const StyledLogo = styled.svg`
  ${sizing}
`;

export const WhatIcon: React.FC<LogoProps> = ({ width, height }) => (
  <StyledLogo width={width} height={height} viewBox="0 0 150 150">
    <defs>
      <linearGradient x1="100%" y1="-7.1%" x2="0%" y2="100%" id="a">
        <stop stopColor="#DADAEB" offset="0%" />
        <stop stopColor="#5857A1" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M.5 75C.5 33.855 33.855.5 75 .5s74.5 33.355 74.5 74.5-33.355 74.5-74.5 74.5C33.874 149.453.547 116.126.5 75zM75 141.811A66.811 66.811 0 1067.771 8.577v132.846c2.401.259 4.814.388 7.229.388zm-13.062-1.281V9.47a66.39 66.39 0 00-22.5 8.988v113.084a66.39 66.39 0 0022.5 8.988zM8.189 75a66.726 66.726 0 0025.42 52.411V22.59A66.726 66.726 0 008.189 75zm82.426 44.085a5.774 5.774 0 015.756-5.624 5.691 5.691 0 110 11.381 5.8 5.8 0 01-5.756-5.757zm-.536-29.855c0-17 20.618-19.279 20.618-35.747 0-7.1-5.222-13.789-14.593-13.789-7.9 0-12.451 3.079-16.6 8.3l-4.285-4.954A26.835 26.835 0 0196.774 33c13.12 0 21.555 8.836 21.555 19.681 0 19.413-21.422 22.626-21.422 36.951a9.672 9.672 0 003.347 7.1l-5.49 3.347a14.833 14.833 0 01-4.685-10.85v.001z"
      stroke="none"
      fill="url(#a)"
      fillRule="nonzero"
      strokeOpacity="0"
    />
  </StyledLogo>
);
