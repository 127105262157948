import { AssessmentType } from "typings/graphql-global-types";
import i18next from "i18next";
interface NumericAssessmentSetting {
  id: string;
  scale: number;
  learnerScaleTexts: string[];
  teacherScaleTexts: string[];
}

export interface Goal {
  id: string;
  numericAssessmentSetting: NumericAssessmentSetting | null;
}

export const getAssessmentScale = (
  goals: Goal[] | null,
  goalId: string,
): number | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting) {
    return g.numericAssessmentSetting.scale;
  }
  return null;
};

export const getAssessmentText = (
  goals: Goal[] | null,
  goalId: string,
  type?: AssessmentType,
): string[] | null => {
  if (!goals) return null;
  const g = goals.find(g => g.id === goalId);
  if (g && g.numericAssessmentSetting && type === AssessmentType.self) {
    return g.numericAssessmentSetting.learnerScaleTexts;
  }
  if (g && g.numericAssessmentSetting && type === AssessmentType.teacher) {
    return g.numericAssessmentSetting.teacherScaleTexts;
  }
  return null;
};

export const getAssessmentScaleInfoText = (
  goals: Goal[] | null,
  goalId: string,
  type: AssessmentType,
): string => {
  let text =
    type === AssessmentType.self
      ? i18next.t("globals.csv.selfScale3Text")
      : i18next.t("globals.csv.teacherScale3Text");
  if (goals) {
    const g = goals.find(g => g.id === goalId);
    if (g && g.numericAssessmentSetting && type === AssessmentType.self) {
      text = g.numericAssessmentSetting.learnerScaleTexts
        .map((txt, i) => `${i + 1}=${txt}`)
        .join(", ");
    }
    if (g && g.numericAssessmentSetting && type === AssessmentType.teacher) {
      text = g.numericAssessmentSetting.teacherScaleTexts
        .map((txt, i) => `${i + 1}=${txt}`)
        .join(", ");
    }
  }
  return text;
};
