import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, InputLabel, Input } from "components/Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";

const Form = styled.form`
  width: 100%;
`;

export interface FormValues {
  link: string;
}

export const AddLinkForm: React.FC<FormikProps<FormValues>> = ({
  values: { link },
  errors,
  touched,
  handleChange,
  setFieldTouched,
}) => {
  const { t } = useTranslation();

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <InputLabel htmlFor="link">
            {t("components.organisms.addLinkModal.link")}
          </InputLabel>
          <Input
            id="link"
            name="link"
            placeholder={t("components.organisms.addLinkModal.linkPlaceholder")}
            helperText={touched.link ? errors.link : ""}
            error={touched.link && Boolean(errors.link)}
            value={link}
            onChange={change.bind(null, "link")}
            fullWidth
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
