import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Queries, GET_LEARNERPROFILE } from "./components";
import { ApolloClient } from "@apollo/client";

import { useSnackbar } from "components/Contexts/SnackbarContext";

import { Flex, Box, Text, Input, Select, MenuItem } from "components/Atoms";

import { GetLearnerProfile } from "./components/Queries/types/GetLearnerProfile";

import { NavigationComponent } from "typings";

interface LearnerSettingsProps {}

const Hero = styled(Flex)`
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #dcdada 0%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
`;

const SettingTitle = styled(Text)`
  color: #000;
`;

const LearnerSettings: NavigationComponent<LearnerSettingsProps> = () => {
  const { t, i18n } = useTranslation();

  const { showSnackbar } = useSnackbar();

  return (
    <Queries>
      {({ myprofile: { loading, data }, updateLanguage }) => {
        const me = data && data.myprofile && data.myprofile.data;

        const onLanguageChanged = async (
          event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>,
        ) => {
          await updateLanguage(event.target.value as "fi-FI" | "sv-FI");
          i18n.changeLanguage(event.target.value as string);
          showSnackbar({
            id: "language-success",
            type: "success",
            message: t("containers.settings.languageChangeSuccess"),
          });
        };

        return (
          <>
            <Flex flexDirection="column">
              <Hero px={[1, 6]} pt={[2, 5]} flexDirection="column">
                <Text variant="h3">{t("globals.settings")}</Text>
                <Text>{me ? `${me.givenName} ${me.familyName}` : ""}</Text>
              </Hero>
              <Flex px={[1, 6]} pt={[2, 5]} flexDirection="column">
                <SettingTitle variant="h4">
                  {t("containers.settings.language")}
                </SettingTitle>
                <Box my={1} width="200px">
                  <Select
                    fullWidth
                    value={i18n.language}
                    onChange={onLanguageChanged}
                    input={<Input name="language" id="language" />}
                  >
                    <MenuItem value={"fi-FI"}>
                      {t("containers.settings.languageFinnish")}
                    </MenuItem>
                    <MenuItem value={"sv-FI"}>
                      {t("containers.settings.languageSwedish")}
                    </MenuItem>
                    <MenuItem value={"en-US"}>
                      {t("containers.settings.languageEnglish")}
                    </MenuItem>
                  </Select>
                </Box>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Queries>
  );
};

LearnerSettings.navigationOptions = async ({
  match,
  apolloClient,
  t,
}: {
  match: any;
  apolloClient: ApolloClient<any>;
  t: any;
}) => {
  const { data } = await apolloClient.query<GetLearnerProfile>({
    query: GET_LEARNERPROFILE,
  });

  const me = data && data.myprofile && data.myprofile.data;

  return {
    breadcrumbs: [
      { icon: "home", label: "", path: "/" },
      {
        icon: "person",
        label: me ? `${me.givenName} ${me.familyName}` : "",
        disabled: true,
        path: "/",
      },
    ],
  };
};

export default LearnerSettings;
