import React from "react";

import styled from "styled-components";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import {
  AssessmentCell,
  getAndOrderAssessments,
  getVerbalAssessment,
} from "../AssessmentCell";

import { Flex, Box } from "components/Atoms";

import { GoalIcon } from "components/Atoms/Icons";

import {
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities as Activity,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_learners as Learner,
  GetEducationalPackageAssessmentsByUser_educationalPackageAssessmentOptimized_data_activities_learningAchievements_verbalAssessments as VerbalAssessment,
} from "../Queries/types/GetEducationalPackageAssessmentsByUser";

import { AssessmentType } from "typings/graphql-global-types";

interface Props {
  activity: Activity;
  learners: Learner[];
  onClickViewVerbalEvaluation: (
    activity: Activity,
    verbalAssessment: VerbalAssessment,
  ) => void;
  onClickEditAssessment: (activity: Activity, learner: Learner) => void;
  selectedAssessmentType: AssessmentType;
}

const StyledTableCell = styled(TableCell)`
  height: 100%;
  border-right: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StickyStyledTableCell = styled(StyledTableCell)`
  position: sticky;
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 0px;
  }
  z-index: 1;
`;

const StickySecondaryTableCell = styled(StickyStyledTableCell)`
  ${(props: any) => props.theme.breakpoints.up("sm")} {
    left: 150px;
  }
`;

export const ActivityTableRow: React.FC<Props> = ({
  activity,
  learners,
  onClickViewVerbalEvaluation,
  onClickEditAssessment,
  selectedAssessmentType,
}) => {
  return (
    <TableRow key={activity.id}>
      <StickyStyledTableCell
        align="left"
        style={{
          minWidth: "150px",
          maxWidth: "150px",
          verticalAlign: "top",
        }}
      >
        <Box height="100%" pt={1}>
          {activity.name}
        </Box>
      </StickyStyledTableCell>
      <StickySecondaryTableCell>
        <Flex
          py={0.5}
          height={"100%"}
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          {activity.educationalPackageGoals &&
            activity.educationalPackageGoals.map((g, i) => (
              <Box key={g.id} mt={i > 0 ? 1.5 : 0}>
                <GoalIcon tooltip={g.name} index={i + 1} />
              </Box>
            ))}
          <Box mt={1.5} height="49px" />
          <Box mt={1.5} height="49px" />
        </Flex>
      </StickySecondaryTableCell>

      {learners.map(learner => (
        <StyledTableCell key={learner.id}>
          <AssessmentCell
            numericAssessments={getAndOrderAssessments(
              learner,
              activity.educationalPackageGoals || [],
              activity.learningAchievements,
              selectedAssessmentType,
            )}
            verbalAssessment={getVerbalAssessment(
              learner,
              activity.learningAchievements,
              selectedAssessmentType,
            )}
            goals={activity.educationalPackageGoals || []}
            onClickViewVerbalEvaluation={v =>
              onClickViewVerbalEvaluation(activity, v)
            }
            onClickEditAssessment={() =>
              onClickEditAssessment(activity, learner)
            }
          />
        </StyledTableCell>
      ))}
    </TableRow>
  );
};
