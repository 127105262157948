import React, { useContext, useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import IconButton from "@material-ui/core/IconButton";
import Play from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import useAudio from "components/Hooks/useAudio";
import { TextToSpeech, TextToSpeechVariables } from "./types/TextToSpeech";
import { AudioPlayerContext } from "components/Contexts/AudioPlayerContext";
import isIos from "utils/isIos";

const GET_TTS_URL = gql`
  query TextToSpeech($input: TextToSpeechInput!) {
    textToSpeech(input: $input) {
      data {
        url
      }
    }
  }
`;

const TextToSpeechAudioPlayer = ({ text }: { text: string }) => {
  const { playingUrl, setPlayingUrl } = useContext(AudioPlayerContext);

  const { setUrl, playing, toggle } = useAudio();

  const [getUrl, { loading, data }] = useLazyQuery<
    TextToSpeech,
    TextToSpeechVariables
  >(GET_TTS_URL, {
    onCompleted: data => {
      setUrl(data.textToSpeech.data.url);
      toggle();
      setPlayingUrl(data.textToSpeech.data.url);
    },
  });

  useEffect(() => {
    if (data && playingUrl !== data.textToSpeech.data.url && playing) {
      toggle();
    }
  }, [playingUrl, playing, setPlayingUrl, toggle, data]);

  const play = () => {
    if (data) {
      setPlayingUrl(playing ? null : data.textToSpeech.data.url);
      toggle();
    } else {
      if (isIos()) {
        toggle("https://storage.googleapis.com/omaops-development/ios.mp3");
      }
      getUrl({
        variables: {
          input: {
            text,
          },
        },
      });
    }
  };

  return (
    <Box>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="50px"
          height="50px"
        >
          <CircularProgress size="24px" />
        </Box>
      )}
      {!loading && (
        <IconButton onClick={play}>
          {playing ? <Pause color="primary" /> : <Play color="primary" />}
        </IconButton>
      )}
    </Box>
  );
};

export default TextToSpeechAudioPlayer;
