import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import NotificationImportantOutlined from "@material-ui/icons/NotificationImportantOutlined";

import { Flex, Text } from "components/Atoms";
import { Modal } from "components/Molecules";

interface Props {
  onModalClosed: (result: boolean) => void;
}

const StyledNotificationImportantOutlined = styled(
  NotificationImportantOutlined,
)`
  color: ${props => props.theme.palette.primary.dark};
`;

export const SubmitActivityConfirmationModal: React.FC<Props> = ({
  onModalClosed,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      name="confirmation"
      primaryAction={{
        text: t(
          "containers.learnerEducationPackage.submitActivityConfirmation.submit",
        ),
        onClick: () => onModalClosed(true),
      }}
      secondaryAction={{
        text: t("globals.cancel"),
        onClick: () => onModalClosed(false),
      }}
      open={true}
      headingAction={<StyledNotificationImportantOutlined />}
      title={t(
        "containers.learnerEducationPackage.submitActivityConfirmation.title",
      )}
      height="auto"
    >
      <Flex mb={2} py={4} flex={"auto"} flexDirection="column">
        <Text>
          <Trans i18nKey="containers.learnerEducationPackage.submitActivityConfirmation.body">
            <strong></strong>
          </Trans>
        </Text>
      </Flex>
    </Modal>
  );
};
