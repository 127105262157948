import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "components/Molecules";
import {
  NumericAssessmentInput,
  Box,
  Text,
  Tooltip,
  Flex,
} from "components/Atoms";
import {
  VerbalAssessmentActiveIcon,
  VerbalAssessmentInactiveIcon,
} from "components/Atoms/Icons";

import {
  VerbalAssessmentModal,
  QuestionProps,
} from "components/Organisms/VerbalAssessmentModal";

import AssessmentTimeline from "./AssessmentTimeline";
import IconButton from "@material-ui/core/IconButton";

import {
  GetLearningAchievementsForEGoals_learningAchievements_data_numericAssessments as NumericAssessment,
  GetLearningAchievementsForEGoals_learningAchievements_data_activity as Activity,
  GetLearningAchievementsForEGoals_learningAchievements_data_verbalAssessments as VerbalAssessment,
  GetLearningAchievementsForEGoals_learningAchievements_data as LearningAchievement,
} from "../EGoalLearningAchievementQueries/types/GetLearningAchievementsForEGoals";

import { GetEducationalPackageEGoalsOpsTable_educationalPackage_data_schoolSubjects_schoolSubjectGradeLevelGroups_eGoals as EGoal } from "../Queries/types/GetEducationalPackageEGoalsOpsTable";

import cleanText from "utils/cleanText";

import { AssessmentType } from "typings/graphql-global-types";

import { Learner } from "../..";
export interface Props {
  learner: Learner;
  eGoal: EGoal;
  assessmentType: AssessmentType;
  open: boolean;
  onModalClosed: (result: boolean) => void;
}

const TruncateText = styled(Text)`
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props: any) => props.theme.breakpoints.down("xs")} {
    max-width: 120px;
  }
`;

export const EGoalLearningAchievementsModal: React.FunctionComponent<Props> = ({
  learner,
  eGoal,
  assessmentType,
  open,
  onModalClosed,
}) => {
  const { t } = useTranslation();
  const [verbalAssessmentModalOpen, setVerbalAsssessmentModalOpen] = useState(
    false,
  );
  const [
    verbalAssessmentModalAssessment,
    setVerbalAssessmentModalAssessment,
  ] = useState<QuestionProps | undefined>();

  const goalText = cleanText(eGoal.eGoal.name);

  const onClose = () => {
    onModalClosed(false);
  };

  const onClickViewVerbalEvaluation = (
    activity: Activity,
    verbalAssessment: VerbalAssessment,
  ) => {
    setVerbalAsssessmentModalOpen(true);
    setVerbalAssessmentModalAssessment({
      name: activity.name,
      answer: verbalAssessment.assessmentText,
      type: verbalAssessment.type,
      assessor:
        verbalAssessment.type === AssessmentType.teacher
          ? `${verbalAssessment.user.givenName} ${verbalAssessment.user.familyName}`
          : undefined,
      helperText:
        activity.verbalAssessmentSetting?.learnerHelperText ||
        t("globals.verbalAssessment.learnerHelperText"),
      learner: verbalAssessment.type === AssessmentType.self && verbalAssessment.user ?
        `${verbalAssessment.user.givenName} ${verbalAssessment.user.familyName}`
        : undefined,
    });
  };

  const onCloseVerbalAssessmentModal = () => {
    setVerbalAsssessmentModalOpen(false);
    setVerbalAssessmentModalAssessment(undefined);
  };

  const assessment: {
    numericAssessment: NumericAssessment;
    learningAchievement: LearningAchievement;
  }[] = [];

  for (const la of learner.learningAchievements || []) {
    for (const na of la.numericAssessments) {
      if (
        na.educationalPackageGoal.eGoals.find(eg => eg.id === eGoal.eGoal.id) &&
        na.type === assessmentType
      ) {
        assessment.push({ numericAssessment: na, learningAchievement: la });
      }
    }
  }

  const timelineItems = assessment
    .map(a => {
      const va = a.learningAchievement.verbalAssessments.find(
        va => va.type === assessmentType && va.assessmentText.length,
      );

      return {
        id: a.numericAssessment.id,
        title: (
          <Flex mt={0.5} flexDirection="column">
            <Flex>
              <Text variant="h4">
                {t(
                  "containers.teacherEducationPackage.eGoalsTable.eGoalLearningAchievementsModal.educationalPackageTitle",
                )}
              </Text>
              <Box ml={1}>
                <TruncateText variant="h4" fontWeight="normal">
                  {a.learningAchievement.activity.educationalPackage?.name ||
                    ""}
                </TruncateText>
              </Box>
            </Flex>
            <Flex mt={1}>
              <Text variant="h4">
                {t(
                  "containers.teacherEducationPackage.eGoalsTable.eGoalLearningAchievementsModal.activityTitle",
                )}
              </Text>
              <Box ml={1}>
                <TruncateText variant="h4" fontWeight="normal">
                  {a.learningAchievement.activity.name}
                </TruncateText>
              </Box>
            </Flex>
          </Flex>
        ),
        createdAt: a.numericAssessment.createdAt,
        content: (
          <Flex ml={-2} pt={1.5} pb={2.5} alignItems="center">
            <Box mr={7}>
              <NumericAssessmentInput
                type={a.numericAssessment.type}
                readonly
                value={a.numericAssessment.value}
                scale={
                  a.numericAssessment.educationalPackageGoal
                    .numericAssessmentSetting?.scale
                }
                scaleTexts={
                  a.numericAssessment.type === AssessmentType.self
                    ? a.numericAssessment.educationalPackageGoal
                        .numericAssessmentSetting?.learnerScaleTexts
                    : a.numericAssessment.educationalPackageGoal
                        .numericAssessmentSetting?.teacherScaleTexts
                }
              />
            </Box>
            {va ? (
              <Box mt={"-10px"}>
                <Tooltip
                  arrow
                  title={t(
                    "containers.teacherEducationPackage.assessmentsTable.showVerbalAssessment",
                  )}
                >
                  <IconButton
                    aria-label="verbal assessment info button"
                    onClick={() =>
                      onClickViewVerbalEvaluation(
                        a.learningAchievement.activity,
                        va,
                      )
                    }
                  >
                    <Box>
                      <VerbalAssessmentActiveIcon color="primary" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Box mt={"-10px"}>
                <Tooltip
                  arrow
                  title={t(
                    "containers.teacherEducationPackage.assessmentsTable.noVerbalAssessment",
                  )}
                >
                  <Box width="70px">
                    <IconButton
                      aria-label="verbal assessment info button"
                      disabled
                    >
                      <Box>
                        <VerbalAssessmentInactiveIcon />
                      </Box>
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            )}
          </Flex>
        ),
      };
    })
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  return (
    <>
      <Modal
        height="600px"
        width="650px"
        primaryAction={{
          text: t("globals.close"),
          onClick: onClose,
        }}
        title={t(
          "containers.teacherEducationPackage.eGoalsTable.eGoalLearningAchievementsModal.title",
        )}
        open={open}
      >
        <Box>
          <Box marginTop={2}>
            <Text variant="caption">
              {t("containers.teacherEducationPackage.eGoalsTable.goal")}
            </Text>
            <Text variant="body1">{goalText}</Text>
          </Box>

          <Box marginTop={2}>
            <Text variant="caption">{t("globals.learnerName")}</Text>
            <Text variant="body1">{`${learner.givenName} ${learner.familyName}`}</Text>
          </Box>

          <Box marginTop={3}>
            <Text variant="caption">
              {t(
                "containers.teacherEducationPackage.eGoalsTable.eGoalLearningAchievementsModal.assessments",
              )}
            </Text>
            <AssessmentTimeline items={timelineItems} />
          </Box>
        </Box>
      </Modal>
      {verbalAssessmentModalOpen && verbalAssessmentModalAssessment && (
        <VerbalAssessmentModal
          open={verbalAssessmentModalOpen}
          question={verbalAssessmentModalAssessment}
          onModalClosed={onCloseVerbalAssessmentModal}
        />
      )}
    </>
  );
};
