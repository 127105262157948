import * as React from "react";

import styled from "styled-components";

import { default as Fab, FabProps } from "@material-ui/core/Fab";

export type FabButtonProps = FabProps;

export const FabButton: React.FC<FabButtonProps> = styled(Fab)<FabButtonProps>``;

FabButton.displayName = "FabButton";
