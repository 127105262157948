import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  appId: process.env.REACT_APP_FIREBASE_appId,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;
