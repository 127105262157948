import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, InputLabel, Input, Text } from "../../../Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { GoalTemplateFolderUpdateInputType } from "typings/graphql-global-types";

const StyledForm = styled.form`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  position: initial;
`;

interface FormProps extends FormikProps<GoalTemplateFolderUpdateInputType> {
  schoolName: string;
}

export const Form: React.FC<FormProps> = ({
  values: { name },
  schoolName,
  errors,
  touched,
  handleChange,
  setFieldTouched,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <StyledForm>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <StyledInputLabel>{t("globals.school")}</StyledInputLabel>
          <Text>{schoolName}</Text>
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="name">
            {t("components.organisms.editGoalTemplateFolderModal.name")}
          </InputLabel>
          <Input
            id="name"
            name="name"
            helperText={touched.name ? errors.name : ""}
            error={touched.name && Boolean(errors.name)}
            value={name}
            onChange={change.bind(null, "name")}
            fullWidth
          />
        </FormControl>
      </Flex>
    </StyledForm>
  );
};
