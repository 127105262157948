import React, { useEffect, useRef, useMemo } from "react";
import Downshift from "downshift";

import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { Input, Chip, Box } from "../../Atoms";
import { InputBaseProps } from "../../Atoms/InputField";

export interface Suggestion {
  name: string;
  id: string;
  disabled?: boolean;
  submenuItems?: Suggestion[];
  parent?: Suggestion;
}

const MenuPaper = styled(Paper)`
  position: absolute;
  z-index: 1;
  margin-top: ${props => props.theme.spacing(1)};
  left: 0;
  right: 0;
`;

const InputWrapper = styled(Box)`
  position: relative;
`;

type DisabledProp = {
  disabled?: boolean;
};

const StyledListSubheader = styled(ListSubheader)`
  background-color: #fff;
`;

const StyledChip = styled(Chip)<DisabledProp>`
  ${props => props.disabled && `color: ${props.theme.palette.grey[200]}`};
  ${props =>
    props.disabled && `background-color: ${props.theme.palette.grey[50]}`};
`;

const StyledInput = styled(Input)`
  border: 1px solid ${props => props.theme.palette.grey[900]};
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  border-radius: 4px;
  margin-top: 40px;

  input {
    border: none;
    width: auto;
    flex-grow: 1;
  }
`;

const StyledMenuItem = styled(({ itemSelected, ...rest }) => (
  <MenuItem {...rest} />
))<any>`
  font-size: 1rem;
  font-weight: 500;

  ${props =>
    props.itemSelected ? "color:" + props.theme.palette.primary.dark : ""};
`;

type RenderInputProps = InputBaseProps & {
  ref?: React.Ref<HTMLDivElement>;
};

function renderInput(inputProps: RenderInputProps) {
  const { ref, ...other } = inputProps;
  return <StyledInput inputRef={ref} {...other} />;
}

interface RenderSuggestionProps {
  highlightedIndex?: number | null;
  subIndex: number;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItems: Suggestion[];
  suggestion: Suggestion;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const {
    index,
    subIndex,
    suggestion,
    itemProps,
    selectedItems,
  } = suggestionProps;

  const isSelected = selectedItems.filter(s => s.id === suggestion.id).length;

  return (
    <Box key={suggestion.id} pl={suggestion.parent ? 1 : 0}>
      <StyledMenuItem
        data-cy={`select-menu-item-${
          suggestion.parent ? subIndex + "-" + index : index
        }`}
        itemSelected={isSelected}
        disabled={suggestion.disabled}
        {...itemProps}
        key={suggestion.name}
        component="div"
      >
        {suggestion.name}
      </StyledMenuItem>
    </Box>
  );
}

function getSuggestions(
  suggestions: Suggestion[],
  value: string,
  { showEmpty = false, showAllInFocus = true } = {},
) {
  const inputValue = value;
  const inputLength = inputValue.length;
  let count = 0;
  if (inputLength === 0 && showAllInFocus) return suggestions;
  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const parentNameMatch =
          suggestion.parent &&
          suggestion.parent.name.slice(0, inputLength).toLowerCase() ===
            inputValue.toLocaleLowerCase();

        const keep =
          count < 25 &&
          (suggestion.name.slice(0, inputLength).toLowerCase() ===
            inputValue.toLocaleLowerCase() ||
            parentNameMatch);

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

export interface MenuProps {
  search: string | null;
  anchorEl: HTMLElement | null;
  name?: string;
  selected?: Suggestion[];
  suggestions?: Suggestion[];
  highlightedIndex?: number | null;
  getItemProps: (obj: any) => any;
  getMenuProps: () => any;
}

export interface MultiChipSelectProps {
  id?: string;
  name?: string;
  suggestions?: Suggestion[];
  selected?: Suggestion[] | null;
  onChange: (selectedItems: Suggestion[]) => void;
  disabled?: boolean;
  menuComponent?: (props: MenuProps) => JSX.Element;
}

export const MultiChipSelect: React.FC<MultiChipSelectProps> = ({
  id,
  name,
  suggestions,
  selected,
  onChange,
  disabled,
  menuComponent,
}) => {
  const inputWrapperRef = useRef(null);
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState<Array<Suggestion>>(
    selected && selected.length
      ? (suggestions || []).filter(
          s => selected.filter(sel => sel.id === s.id).length,
        )
      : [],
  );

  const flattenedSuggestions = useMemo(() => {
    let ret: Suggestion[] = [];
    (suggestions || []).forEach(s => {
      ret.push(s);
      ret = ret.concat(
        (s.submenuItems || []).map(sub => {
          return { ...sub, parent: s };
        }),
      );
    });
    return ret;
  }, [suggestions]);

  useEffect(() => {
    setSelectedItem(
      selected && selected.length
        ? selected.filter(
            s =>
              (flattenedSuggestions || []).filter(sel => sel.id === s.id)
                .length > -1,
          )
        : [],
    );
  }, [selected, flattenedSuggestions]);

  function handleKeyDown(event: React.KeyboardEvent) {
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace" &&
      !selectedItem[selectedItem.length - 1].disabled
    ) {
      const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1);
      setSelectedItem(newSelectedItem);
      onChange(newSelectedItem);
    }
  }

  function handleInputChange(event: React.ChangeEvent<{ value: string }>) {
    setInputValue(event.target.value);
  }

  function handleChange(item: Suggestion) {
    if (!item) return;
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
    onChange(newSelectedItem);
  }

  const handleDelete = (item: Suggestion) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    onChange(newSelectedItem);
  };

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue: search,
        selectedItem: selected,
        highlightedIndex,
        openMenu,
      }) => {
        const {
          onBlur,
          onChange,
          onFocus,
          onClick,
          ...inputProps
        } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: "",
          onClick: openMenu,
        });

        return (
          <InputWrapper
            {...getRootProps({
              refKey: "ref",
            })}
          >
            <div ref={inputWrapperRef}>
              {renderInput({
                disabled,
                id,
                name,
                startAdornment: selectedItem.map(item => (
                  <Box key={item.id} py={0.5} px={0.25}>
                    <StyledChip
                      disabled={item.disabled}
                      tabIndex={-1}
                      label={item.name}
                      onDelete={!item.disabled ? handleDelete(item) : undefined}
                    />
                  </Box>
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event);
                  onChange!(event as React.ChangeEvent<HTMLInputElement>);
                },
                onFocus,
                onClick,
                inputProps,
              })}
              {isOpen && !menuComponent ? (
                <Menu
                  name={name}
                  anchorEl={inputWrapperRef.current}
                  search={search}
                  selected={selected}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  suggestions={flattenedSuggestions}
                  getMenuProps={getMenuProps}
                ></Menu>
              ) : null}

              {isOpen && menuComponent
                ? menuComponent({
                    name,
                    anchorEl: inputWrapperRef.current,
                    search,
                    selected,
                    getItemProps,
                    highlightedIndex,
                    suggestions: flattenedSuggestions,
                    getMenuProps,
                  })
                : null}
            </div>
          </InputWrapper>
        );
      }}
    </Downshift>
  );
};

const Menu: React.FC<MenuProps> = ({
  name,
  search,
  selected,
  suggestions,
  getItemProps,
  highlightedIndex,
  anchorEl,
  getMenuProps,
}) => {
  const items = getSuggestions(suggestions || [], search!).reduce<
    { string: Suggestion[] } | {}
  >((h, { parent, ...rest }) => {
    const parentName = parent ? parent.name : "none";
    return Object.assign(h, {
      [parentName]: ((h as any)[parentName] || []).concat(
        rest.submenuItems && rest.submenuItems.length > 0
          ? []
          : [{ parent, ...rest }],
      ),
    });
  }, {});

  return (
    <Popper
      open={true}
      anchorEl={anchorEl}
      style={{ zIndex: 1300 }}
      placement="bottom-start"
    >
      <MenuPaper
        {...getMenuProps()}
        id={`${name}-menu`}
        square
        style={{
          marginTop: "8px",
          width: anchorEl ? anchorEl.clientWidth : undefined,
          height: "350px",
          overflowY: "auto",
        }}
      >
        {Object.keys(items).map((parentName, subIndex) => (
          <Box key={parentName}>
            {parentName !== "none" && (
              <StyledListSubheader>{parentName}</StyledListSubheader>
            )}
            {(items as any)[parentName].map(
              (suggestion: Suggestion, index: number) =>
                renderSuggestion({
                  suggestion,
                  index,
                  subIndex,
                  itemProps: getItemProps({ item: suggestion }),
                  highlightedIndex,
                  selectedItems: selected || [],
                }),
            )}
          </Box>
        ))}
      </MenuPaper>
    </Popper>
  );
};
