const ACADEMIC_YEAR_END_MONTH = 5; //june
const ACADEMIC_YEAR_END_DATE = 15;

const ACADEMIC_YEAR_START_MONTH = 7; //june
const ACADEMIC_YEAR_START_DATE = 1;

const ACADEMIC_YEAR_MIDDLE_MONTH = 11; //june
const ACADEMIC_YEAR_MIDDLE_DATE = 24;

export interface AcademicYear {
  startYear: number;
  endYear: number;
  middleDate: Date;
  startDate: Date;
  endDate: Date;
}

export const getAcademicYear: (date: Date) => AcademicYear = date => {
  const timestamp = new Date(date);
  const startDate = new Date();
  startDate.setMonth(ACADEMIC_YEAR_START_MONTH);
  startDate.setDate(ACADEMIC_YEAR_START_DATE);

  const endDate = new Date();
  endDate.setMonth(ACADEMIC_YEAR_END_MONTH);
  endDate.setDate(ACADEMIC_YEAR_END_DATE);

  const middleDate = new Date();
  middleDate.setMonth(ACADEMIC_YEAR_MIDDLE_MONTH);
  middleDate.setDate(ACADEMIC_YEAR_MIDDLE_DATE);

  if (timestamp > endDate) {
    return {
      startYear: timestamp.getFullYear(),
      startDate: new Date(startDate.setFullYear(timestamp.getFullYear())),
      middleDate: new Date(middleDate.setFullYear(timestamp.getFullYear())),
      endYear: timestamp.getFullYear() + 1,
      endDate: new Date(endDate.setFullYear(timestamp.getFullYear() + 1)),
    };
  }

  return {
    startYear: timestamp.getFullYear() - 1,
    startDate: new Date(startDate.setFullYear(timestamp.getFullYear() - 1)),
    middleDate: new Date(middleDate.setFullYear(timestamp.getFullYear() - 1)),
    endYear: timestamp.getFullYear(),
    endDate: new Date(endDate.setFullYear(timestamp.getFullYear())),
  };
};
