import React, { useContext } from "react";

import { Router, Route, Switch, Redirect, RouteProps } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "containers/Login";
import Main from "containers/Main";
import NotFound from "containers/NotFound";
import Terms from "containers/Terms";
import UserContext from "components/Contexts/UserContext";
import SelfAssessmentPreview from "containers/SelfAssessmentPreview";

import {
  HOME,
  LOGIN,
  REDIRECT,
  NOTFOUND,
  TERMS,
  SELFASSESSMENT_REDIRECT,
} from "./routes";

export const history = createBrowserHistory();

const PrivateRoute: React.FC<RouteProps> = props => {
  const { user } = useContext(UserContext);

  if (!user) {
    const renderComponent = () => (
      <Redirect
        to={{
          pathname: LOGIN,
          state: { from: props.location },
        }}
      />
    );
    return <Route {...props} component={renderComponent} render={undefined} />;
  }

  return <Route {...props} />;
};

const RouterComponent: React.FunctionComponent = () => {
  const isGoogleWebPreview =
    navigator.userAgent.indexOf("Google Web Preview") > 0;

  return (
    <Router history={history}>
      <Switch>
        {isGoogleWebPreview && (
          <Route
            exact
            path={SELFASSESSMENT_REDIRECT}
            component={SelfAssessmentPreview}
          />
        )}
        <Route exact path={NOTFOUND} component={NotFound} />
        <Route exact path={TERMS} component={Terms} />
        <Route exact path={LOGIN} component={Login} />
        <PrivateRoute path={HOME} component={Main} />
        <Redirect from={REDIRECT} to={HOME} />
      </Switch>
    </Router>
  );
};

export default RouterComponent;
