import React from "react";
import gql from "graphql-tag";
import { ApolloError, useQuery, useMutation, SingleExecutionResult } from "@apollo/client";

import {
  GetAssessmentActivityGoals,
  GetAssessmentActivityGoalsVariables,
  GetAssessmentActivityGoals_activity_data as Activity,
} from "./types/GetAssessmentActivityGoals";

import {
  GetAchievementAssessment,
  GetAchievementAssessmentVariables,
  GetAchievementAssessment_learningAchievement_data as Achievement,
} from "./types/GetAchievementAssessment";

import {
  UpdateAssessmentForm,
  UpdateAssessmentFormVariables,
} from "./types/UpdateAssessmentForm";

import {
  NumericAssessmentInput,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

export const GET_ACHIEVEMENT = gql`
  query GetAchievementAssessment($filters: LearningAchievementInput!) {
    learningAchievement(filters: $filters) {
      data {
        id
        numericAssessments {
          id
          value
          type
          educationalPackageGoalId
        }
        verbalAssessments {
          type
          assessmentText
        }
      }
    }
  }
`;

export const GET_ACTIVITY_GOALS = gql`
  query GetAssessmentActivityGoals($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
            teacherScaleTexts
          }
        }
      }
    }
  }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessmentForm($input: PatchLearningAchievementInput!) {
    createOrPatchLearningAchievement(input: $input) {
      data {
        id
        user {
          id
        }
        numericAssessments {
          id
          value
          type
          educationalPackageGoalId
        }
        verbalAssessments {
          id
          assessmentText
          type
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activity: {
    loading: boolean;
    data: Activity | null;
  };
  achievement: {
    loading: boolean;
    data: Achievement | null;
  };
  assessment: {
    update: (
      numericAssessments: NumericAssessmentInput[],
      verbalAssessment: VerbalAssessmentInput,
    ) => Promise<SingleExecutionResult<UpdateAssessmentForm>>;
    error?: ApolloError;
    loading?: boolean;
  };
}

interface QueriesProps {
  activityId: string;
  userId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  activityId,
  userId,
}) => {
  const { loading: activityLoading, data: activityData } = useQuery<
    GetAssessmentActivityGoals,
    GetAssessmentActivityGoalsVariables
  >(GET_ACTIVITY_GOALS, {
    variables: {
      filters: {
        id: activityId,
      },
    },
  });

  const { loading: achievementLoading, data: achievementData } = useQuery<
    GetAchievementAssessment,
    GetAchievementAssessmentVariables
  >(GET_ACHIEVEMENT, { variables: { filters: { activityId, userId } } });

  const [
    updateAssessment,
    { loading: updateAssessmentLoading, error: updateAssessmentError },
  ] = useMutation<UpdateAssessmentForm, UpdateAssessmentFormVariables>(
    UPDATE_ASSESSMENT,
  );

  const update = (
    numericAssessments: NumericAssessmentInput[],
    verbalAssessment: VerbalAssessmentInput,
  ) => {
    return updateAssessment({
      variables: {
        input: {
          userId,
          activityId,
          learningAchievement: {
            numericAssessments,
            verbalAssessment,
          },
        },
      },
    });
  };

  return children({
    activity: {
      loading: activityLoading,
      data: activityData ? activityData.activity.data : null,
    },
    achievement: {
      loading: achievementLoading,
      data: achievementData ? achievementData.learningAchievement.data : null,
    },
    assessment: {
      update,
      error: updateAssessmentError,
      loading: updateAssessmentLoading,
    },
  });
};
