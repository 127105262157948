import * as React from "react";
import styled from "styled-components";

import { sizing, SizingProps, PaletteProps } from "@material-ui/system";

export type LogoProps = SizingProps & PaletteProps;

const StyledLogo = styled.svg`
  ${sizing}
`;

export const ToWhomIcon: React.FC<LogoProps> = ({ width, height }) => (
  <StyledLogo width={width} height={height} viewBox="0 0 150 150">
    <defs>
      <linearGradient x1="118.3%" y1="-21.7%" x2="15.1%" y2="96%" id="a">
        <stop stopColor="#DADAEB" offset="0%" />
        <stop stopColor="#5857A1" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M.002 75.816c0-30.26 18.228-57.54 46.184-69.12 27.957-11.581 60.136-5.18 81.533 16.217s27.798 53.576 16.218 81.533c-11.58 27.956-38.861 46.184-69.121 46.184C33.517 150.583.049 117.115.002 75.816zm74.814 67.093a67 67 0 0052.178-24.955H22.639a67 67 0 0052.176 24.956l.001-.001zm-56.414-30.807H131.23a66.718 66.718 0 009.358-23H9.043a66.716 66.716 0 009.359 23zm91.63-28.855h31.47a67.1 67.1 0 10-133.369 0h31.4c4.08-11.796 18.657-20.245 35.248-20.245a45.006 45.006 0 0121.969 5.445h-.006c6.592 3.696 11.258 8.907 13.288 14.8zm-65.68 0h60.86c-1.912-4.26-5.613-8.058-10.659-10.89a40.482 40.482 0 00-19.771-4.871c-13.998 0-26.288 6.499-30.431 15.761h.001zm12.911-42.425c0-9.256 7.504-16.758 16.76-16.758 9.255 0 16.758 7.504 16.758 16.76 0 9.255-7.503 16.758-16.759 16.758-9.252-.01-16.75-7.508-16.759-16.76zm4.487 0c0 6.778 5.494 12.272 12.272 12.272 6.778 0 12.272-5.494 12.272-12.272 0-6.778-5.494-12.272-12.272-12.272-6.774.008-12.264 5.498-12.272 12.272z"
      transform="translate(0 -1)"
      fill="url(#a)"
      fillRule="nonzero"
    />
  </StyledLogo>
);
