import * as React from "react";
import { useTranslation } from "react-i18next";

import { Flex, Box, FabButton } from "../../Atoms";

interface Props {
  onChanged: (lng: string) => void;
}

export const LanguageFabs: React.FunctionComponent<Props> = ({ onChanged }) => {
  const { i18n } = useTranslation();

  const changeFinnishLanguage = () => {
    i18n.changeLanguage("fi-FI");
    onChanged("fi-FI");
  };

  const changeSwedishLanguage = () => {
    i18n.changeLanguage("sv-FI");
    onChanged("sv-FI");
  };

  return (
    <Flex pt={1} width="100%" justifyContent="flex-end">
      <Box mr={1}>
        <FabButton
          color="primary"
          size="small"
          aria-label="finnish"
          onClick={changeFinnishLanguage}
        >
          FI
        </FabButton>
      </Box>
      <Box mr={1}>
        <FabButton
          color="primary"
          size="small"
          aria-label="swedish"
          onClick={changeSwedishLanguage}
        >
          SE
        </FabButton>
      </Box>
    </Flex>
  );
};
