import React from "react";

import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import { useTranslation } from "react-i18next";

import { Flex, Box, Tooltip } from "components/Atoms";

import { Cell, StyledInputLabel } from "./index";

import {
  VerbalAssessmentActiveIcon,
  VerbalAssessmentInactiveIcon,
} from "components/Atoms/Icons";

import { GetDigipolkuSurveyGroup_digipolkuSurveyGroup_data_surveys_surveyQuestions as SurveyQuestion } from "../../../Queries/types/GetDigipolkuSurveyGroup";

interface Props {
  numericQuestions: SurveyQuestion[];
  width: string[];
  onClick: (question: SurveyQuestion) => void;
}
// ["10%", "15%", "5%"];
const CommentsColumn = ({ numericQuestions, width, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell width={width} order={1} flexGrow={1}>
        <Hidden mdDown>
          <Flex alignItems="center" height="100%">
            <StyledInputLabel>
              {t("containers.digipolku.surveyForm.comments")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>

      {numericQuestions.map((q, i) => (
        <Cell
          key={`comments-${q.id}`}
          py={2}
          divider={i < numericQuestions.length - 1}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex
            height="100%"
            alignItems="flex-start"
            justifyContent={["center", "flex-start"]}
          >
            <Tooltip
              title={
                (q.surveyQuestionAnswer?.comments || []).length > 0
                  ? t("containers.digipolku.surveyForm.openComments")
                  : t("containers.digipolku.surveyForm.noComments")
              }
              arrow
            >
              <Box>
                {(q.surveyQuestionAnswer?.comments || []).length > 0 ? (
                  <IconButton
                    aria-label="survey comments button"
                    onClick={() => onClick(q)}
                    data-cy="show-comments"
                  >
                    <Badge
                      badgeContent={
                        (q.surveyQuestionAnswer?.comments || []).filter(
                          c => !c.read,
                        ).length
                      }
                      color="primary"
                    >
                      <VerbalAssessmentActiveIcon color="primary" />
                    </Badge>
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <VerbalAssessmentInactiveIcon />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default CommentsColumn;
