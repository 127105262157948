import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Link from "@material-ui/core/Link";

import { Flex, Box, Text } from "components/Atoms";
import { MaterialButtons } from "../MaterialButtons";

const CardItem = styled(Box)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
`;

export interface AchievementProps {
  filesCount: number;
  linksCount: number;
  onOpenFilesClick: () => void;
  onOpenLinksClick: () => void;
  onFileClick: () => void;
  onDriveClick: () => void;
  onLinkClick: () => void;
  hideActions?: boolean;
}

export const Achievement: React.FC<AchievementProps> = ({
  filesCount,
  linksCount,
  onOpenFilesClick,
  onOpenLinksClick,
  onFileClick,
  onDriveClick,
  onLinkClick,
  hideActions,
}) => {
  const { t } = useTranslation();
  return (
    <CardItem px={3} py={2}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Flex flexDirection="column">
          <Text variant="h4" color="textPrimary">
            {t("components.molecules.achievement.title")}
          </Text>
          <Flex mt={1.5}>
            <Box>
              <Link
                variant="body1"
                component="button"
                onClick={onOpenFilesClick}
              >
                {t("components.molecules.achievement.files", {
                  count: filesCount,
                })}
              </Link>
            </Box>
            <Box ml={2}>
              <Link
                variant="body1"
                component="button"
                onClick={onOpenLinksClick}
              >
                {t("components.molecules.achievement.links", {
                  count: linksCount,
                })}
              </Link>
            </Box>
          </Flex>
        </Flex>
        {!hideActions && (
          <Flex
            justifyContent={["space-between", "none"]}
            width={["100%", "auto"]}
            alignItems="center"
            flexWrap="wrap"
            mt={[2, 0]}
          >
            <Box>
              <Text variant="h6">
                {t("components.molecules.achievement.linkMaterial")}
              </Text>
            </Box>
            <MaterialButtons
              onDriveClick={onDriveClick}
              onFileClick={onFileClick}
              onLinkClick={onLinkClick}
            />
          </Flex>
        )}
      </Flex>
    </CardItem>
  );
};
