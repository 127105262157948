import { ApolloError, useMutation, useQuery, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  GetEducationalPackageMembers,
  GetEducationalPackageMembersVariables,
} from "./types/GetEducationalPackageMembers";

import {
  SetEducationalPackageMembers,
  SetEducationalPackageMembersVariables,
} from "./types/SetEducationalPackageMembers";

export const GET_EDUCATIONAL_PACKAGE_MEMBERS = gql`
  query GetEducationalPackageMembers($filters: IdInputType!) {
    educationalPackage(filters: $filters) {
      data {
        id
        teachers {
          id
          givenName
          familyName
          emailAddress
        }
        learners {
          id
          givenName
          familyName
          emailAddress
        }
      }
    }
  }
`;

export const UPDATE_EDUCATIONAL_PACKAGE_MEMBERS = gql`
  mutation SetEducationalPackageMembers(
    $input: EducationalPackageSetMembersInputType!
  ) {
    setEducationalPackageMembers(input: $input) {
      data {
        id
        teachers {
          id
        }
        learners {
          id
        }
        activities {
          id
          learners {
            id
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  educationalPackageMembers: {
    loading: boolean;
    data: GetEducationalPackageMembers | undefined;
  };
  update: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            SetEducationalPackageMembers,
            SetEducationalPackageMembersVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<SetEducationalPackageMembers>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  educationalPackageId: string;
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  educationalPackageId,
}) => {
  const { loading: loadingMembers, data: dataMembers } = useQuery<
    GetEducationalPackageMembers,
    GetEducationalPackageMembersVariables
  >(GET_EDUCATIONAL_PACKAGE_MEMBERS, {
    variables: {
      filters: {
        id: educationalPackageId,
      },
    },
  });

  const [updateEducationalPackageMembers, { error: updateError }] = useMutation<
    SetEducationalPackageMembers,
    SetEducationalPackageMembersVariables
  >(UPDATE_EDUCATIONAL_PACKAGE_MEMBERS);

  return children({
    educationalPackageMembers: { loading: loadingMembers, data: dataMembers },
    update: { mutation: updateEducationalPackageMembers, error: updateError },
  });
};
