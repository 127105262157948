import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import AnimateHeight from "react-animate-height";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { Flex, Box, FloatableButton, Tooltip } from "components/Atoms";
import { Card, CardHeader, CardActions } from "components/Molecules/Card";
import { useSnackbar } from "components/Contexts/SnackbarContext";
import { CardItemContext } from "components/Contexts/CardGroup";

import { SurveyForm } from "./components/SurveyForm";

import { SubmitQueries } from "../SubmitQueries";
import { GetOwnGoalsSurveyGroup_ownGoalsSurveyGroup_data_surveys as Survey } from "../Queries/types/GetOwnGoalsSurveyGroup";
import { getAcademicYear } from "utils/dateHelpers";

export interface SurveyCardProps {
  survey: Survey;
  scrollContainer?: HTMLElement | null;
  onEdit: (survey: Survey) => void;
}

type ExpandedProp = {
  expanded?: boolean;
  height?: string;
};

const Collapsible = styled.div<ExpandedProp>`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const ResponsiveCard = styled(Card)`
  width: 100%;
  position: relative;
  ${props => props.theme.breakpoints.down("xs")} {
    border-radius: 0;
  }
`;

const CardItem = styled(Box)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

const StyledCardHeader = styled(CardHeader)`
  cursor: pointer;
`;

export const SurveyCardCard: React.FunctionComponent<SurveyCardProps> = ({
  survey,
  scrollContainer,
  onEdit,
}) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const id = survey.id;

  const academicYear = getAcademicYear(new Date(survey.createdAt));
  const title = t("globals.ownGoalsSurveyTitle", {
    startYear: academicYear.startYear,
    endYear: academicYear.endYear,
    name: survey.name && survey.name.length && `: ${survey.name}`,
  });

  return (
    <CardItemContext.Consumer>
      {({ expandedItem, toggleExpanded }) => (
        <ResponsiveCard data-cy={`survey-card-${survey.name}`}>
          <StyledCardHeader
            onClick={e => toggleExpanded(id === expandedItem ? "" : id)}
            title={
              (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <Box>{title}</Box>
                  </Flex>

                  <Flex alignItems="center">
                    <Tooltip
                      title={t("containers.ownGoals.surveyCard.editOwnGoals")}
                    >
                      <IconButton
                        type="button"
                        onClick={e => {
                          e.stopPropagation();
                          onEdit(survey);
                        }}
                        aria-label="edit own goal"
                        data-cy={"edit-owngoal-button"}
                      >
                        <EditIcon fontSize="small" color={"primary"}></EditIcon>
                      </IconButton>
                    </Tooltip>
                  </Flex>
                </Flex>
              ) as any
            }
            titleTypographyProps={{ variant: "h3" }}
          />
          <Collapsible expanded={id === expandedItem}>
            <AnimateHeight height={id === expandedItem ? "auto" : 0}>
              <SubmitQueries>
                {({ answer: { loading, update } }) => (
                  <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={{
                      surveyQuestionAnswers: survey.surveyQuestions.map(q => {
                        if (q.surveyQuestionAnswer) {
                          const { id, ...noId } = q.surveyQuestionAnswer;
                          return {
                            ...noId,
                            surveyQuestionId: q.id,
                          };
                        }
                        return { surveyQuestionId: q.id };
                      }),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);

                      await update(
                        values.surveyQuestionAnswers.map(a => ({
                          ...a,
                          comments: undefined,
                          __typename: undefined,
                        })),
                      );
                      showSnackbar({
                        id: "submitted",
                        type: "success",
                        message: t(
                          "containers.ownGoals.surveyCard.submitSuccess",
                        ),
                      });
                      setSubmitting(false);
                    }}
                    render={props => {
                      return (
                        <>
                          <CardItem>
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <SurveyForm
                                survey={survey}
                                questions={survey.surveyQuestions}
                                {...props}
                              />
                            </Flex>
                          </CardItem>
                          <CardActions>
                            <Flex
                              width="100%"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {id === expandedItem && (
                                <FloatableButton
                                  scrollContainer={scrollContainer}
                                  data-cy="primary-action-button"
                                  onClick={() => props.handleSubmit()}
                                  disabled={
                                    !props.isValid || props.isSubmitting
                                  }
                                >
                                  {t("globals.save")}
                                </FloatableButton>
                              )}
                            </Flex>
                          </CardActions>
                        </>
                      );
                    }}
                  />
                )}
              </SubmitQueries>
            </AnimateHeight>
          </Collapsible>
        </ResponsiveCard>
      )}
    </CardItemContext.Consumer>
  );
};
