import React from "react";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";

import { Flex, Text } from "components/Atoms";
import { format } from "date-fns";

import { Cell, StyledInputLabel, TimelineItem } from "./index";

import { QuestionType } from "typings/graphql-global-types";

interface Props {
  timelineItems: TimelineItem[];
  width: string[];
}

// ["30%", "50%", "20%"]
const GoalColumn = ({ timelineItems, width }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Cell pl={2} pr={1} width={width} order={1} flexGrow={1}>
        <Hidden xsDown>
          <Flex alignItems="center" height="100%">
            <StyledInputLabel>
              {t("containers.ownGoals.surveyForm.scheduledDate")}
            </StyledInputLabel>
          </Flex>
        </Hidden>
      </Cell>

      {timelineItems.map((q, i) => (
        <Cell
          key={`info-${q.id}`}
          py={1}
          pl={2}
          pr={1}
          alternateColor={q.type !== QuestionType.numeric}
          width={width}
          order={i + 2}
          flexGrow={1}
        >
          <Flex
            pt={2}
            alignItems="flex-start"
            justifyContent={["center", "flex-start"]}
          >
            {q.type === QuestionType.numeric && (
              <Text>
                {q.scheduledDate
                  ? format(new Date(q.scheduledDate), "dd.MM.yyyy")
                  : "-"}
              </Text>
            )}
          </Flex>
        </Cell>
      ))}
    </>
  );
};

export default GoalColumn;
