import * as React from "react";

import styled from "styled-components";

import {
  default as MuiDivider,
  DividerProps as MuiDividerProps,
} from "@material-ui/core/Divider";

export type DividerProps = MuiDividerProps;

export const Divider: React.FC<DividerProps> = styled(MuiDivider)``;

Divider.displayName = "Divider";
