import * as React from "react";

import styled from "styled-components";

import {
  default as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from "@material-ui/lab/ToggleButtonGroup";

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps;

export const ToggleButtonGroup: React.ComponentType<
  ToggleButtonGroupProps
> = styled((props: MuiToggleButtonGroupProps) => (
  <MuiToggleButtonGroup {...props} />
))``;

ToggleButtonGroup.displayName = "ToggleButtonGroup";
