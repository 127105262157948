import { useRef } from "react";

const useScroll = (): {
  executeScroll: () => void;
  htmlElRef: React.RefObject<HTMLElement>;
} => {
  const htmlElRef = useRef<HTMLElement>(null);
  const executeScroll = () => {
    if (htmlElRef && htmlElRef.current) {
      htmlElRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return { executeScroll, htmlElRef };
};

export default useScroll;
