import * as React from "react";

import styled from "styled-components";

import {
  default as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from "@material-ui/lab/ToggleButton";

export type ToggleButtonProps = MuiToggleButtonProps;

export const ToggleButton: React.FC<ToggleButtonProps> = styled(
  MuiToggleButton,
)<ToggleButtonProps>``;

ToggleButton.displayName = "ToggleButton";
