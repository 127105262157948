import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";
import { Queries, Filter, EducationalPackageList } from "./components";

import {
  DropdownMenu,
  DropdownOptionItem,
} from "components/Molecules/DropdownMenu";
import { EducationPackageCardContextMenuItems } from "components/Organisms/EducationPackageCard";
import { AddNewButton, Flex, Box, Text } from "components/Atoms";
import {
  EditEducationPackageModal,
  EditEducationPackageMembersModal,
} from "components/Organisms";
import { NavigationComponent } from "typings";
import { useAnalytics } from "components/Hooks/firebase-hooks";

interface TeacherHomeProps {}

export enum EducationalPackageContextMenuItems {
  createNew,
  importFromClassroom,
}

const TeacherHome: NavigationComponent<TeacherHomeProps> = () => {
  const [
    showEditEducationPackageModal,
    setShowEditEducationPackageModal,
  ] = useState(false);
  const [importFromClassroom, setImportFromClassroom] = useState(false);
  const [pollEducationalPackages, setPollEducationalPackages] = useState(false);
  const [showEditMembersModal, setShowEditMembersModal] = useState(false);
  const [
    selectedEducationalPackageId,
    setSelectedEducationalPackageId,
  ] = useState<string | undefined | null>();
  const [filterActive, setFilterActive] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const { analytics } = useAnalytics();

  analytics.logEvent("page_view" as string, {
    page_title: "Teacher_home",
    page_path: history.location.pathname,
  });

  const onFilterChanged = (state: boolean) => {
    setFilterActive(state);
  };

  return (
    <Queries active={filterActive}>
      {({
        allEducationalPackages: {
          loading: packagesLoading,
          data,
          refetch,
          stopPolling,
          startPolling,
        },
        syncFromClassroom,
      }) => {
        const educationPackages =
          (data && data.allEducationalPackages.data) || [];

        if (educationPackages.find(ep => ep.fetching)) {
          startPolling(2000);
          setPollEducationalPackages(true);
        } else if (pollEducationalPackages) {
          stopPolling();
          setPollEducationalPackages(false);
        }

        const onAddEducationPackage = () => {
          setSelectedEducationalPackageId(null);
          setShowEditEducationPackageModal(true);
        };

        const onAddEducationPackageFromClassroom = () => {
          setSelectedEducationalPackageId(null);
          setImportFromClassroom(true);
          setShowEditEducationPackageModal(true);
        };

        const onEditMembersClosed = (result: boolean) => {
          setShowEditMembersModal(false);
          setSelectedEducationalPackageId(null);
          if (result) refetch();
        };

        const onCardMenuChanged = async (id: string, o: DropdownOptionItem) => {
          if (o.id === EducationPackageCardContextMenuItems.edit) {
            setSelectedEducationalPackageId(id);
            setShowEditEducationPackageModal(true);
          }
          if (o.id === EducationPackageCardContextMenuItems.editMembers) {
            setSelectedEducationalPackageId(id);
            setShowEditMembersModal(true);
          }
          if (o.id === EducationPackageCardContextMenuItems.syncClassroom) {
            syncFromClassroom(id);
          }
        };

        const onEditEducationalPackageModalClosed = (result: boolean) => {
          setShowEditEducationPackageModal(false);
          setImportFromClassroom(false);
          // if (result) refetch();
        };

        let activeCount: number | null = null;
        let archivedCount: number | null = null;

        if (data && !packagesLoading) {
          activeCount = data.activeCount.data;
          archivedCount = data.archivedCount.data;
        }

        const onAddMenuChanged = async (o: DropdownOptionItem) => {
          if (o.id === EducationalPackageContextMenuItems.createNew) {
            onAddEducationPackage();
          } else if (
            o.id === EducationalPackageContextMenuItems.importFromClassroom
          ) {
            onAddEducationPackageFromClassroom();
          }
        };
        const options = [
          {
            id: EducationalPackageContextMenuItems.createNew,
            label: t("containers.teacherHome.createNew"),
          },
          {
            id: EducationalPackageContextMenuItems.importFromClassroom,
            label: t("containers.teacherHome.importFromClassroom"),
          },
        ];

        return (
          <>
            <Flex px={[0, 4, 4, 9]} pt={[3, 6]} flexDirection="column">
              <Flex px={[1]} flexDirection="column">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  pb={[4]}
                  flexWrap="wrap"
                >
                  <Text variant="h3">{t("containers.teacherHome.title")}</Text>
                  <Box mt={[4, 0]}>
                    <DropdownMenu
                      name="add-educational-package"
                      onSelect={onAddMenuChanged}
                      options={options}
                      component={
                        <AddNewButton
                          name="add-educational-package-button"
                          label={t("containers.teacherHome.addNewButton")}
                        />
                      }
                    ></DropdownMenu>
                  </Box>
                </Flex>
                <Filter
                  activeCount={activeCount}
                  archivedCount={archivedCount}
                  active={filterActive}
                  onChanged={onFilterChanged}
                />
              </Flex>
              <Box pt={4} pb={8}>
                <EducationalPackageList
                  loading={packagesLoading}
                  data={data}
                  active={filterActive}
                  onContextMenuChanged={onCardMenuChanged}
                />
              </Box>
            </Flex>
            {showEditEducationPackageModal && (
              <EditEducationPackageModal
                importFromClassroom={importFromClassroom}
                educationalPackageId={selectedEducationalPackageId!}
                showModal={showEditEducationPackageModal}
                onModalClosed={onEditEducationalPackageModalClosed}
              ></EditEducationPackageModal>
            )}
            {showEditMembersModal && (
              <EditEducationPackageMembersModal
                educationalPackageId={selectedEducationalPackageId!}
                showModal={showEditMembersModal}
                onModalClosed={onEditMembersClosed}
              ></EditEducationPackageMembersModal>
            )}
          </>
        );
      }}
    </Queries>
  );
};

TeacherHome.navigationOptions = {
  breadcrumbs: [{ icon: "home", disabled: true, path: "/" }],
};

export default TeacherHome;
