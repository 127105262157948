import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import TitleIcon from "@material-ui/icons/Title";

import { Flex, Text, Box, Tooltip } from "components/Atoms";

import {
  GetSchoolActivityTemplates_allActivityTemplateFoldersBySchool_data_activityTemplates as ActivityTemplate,
} from "../Queries/types/GetSchoolActivityTemplates";

import { RemoveTemplateItemConfirmationModal } from "../../GoalTemplates/RemoveTemplateItemConfirmationModal";

import UserContext from "components/Contexts/UserContext";

interface Props {
  template: ActivityTemplate;
  onEdit: (template: ActivityTemplate) => void;
  onEditActivity?: (template: ActivityTemplate) => void;
  onRemove: (template: ActivityTemplate) => void;
}

export const TemplateItem: React.FC<Props> = ({
  template,
  onEdit,
  onEditActivity,
  onRemove,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [
    showRemoveConfirmationModal,
    setShowRemoveConfirmationModal,
  ] = useState(false);

  const onRemoveConfirmationModalClosed = (result: boolean) => {
    if (result) {
      onRemove(template);
    }
    setShowRemoveConfirmationModal(false);
  };
  
  const isTemplateOwner = user?.uid === template.activityTemplateFolder?.user.id;
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box pl={7.5}>
        <Text color="textPrimary">{template.name}</Text>
      </Box>
      <Flex alignItems="center" pr={2}>
        <Tooltip
          title={t("containers.settings.activityTemplates.removeTemplateTooltip")}
        >
          <Box>
            <IconButton
              aria-label="remove goal template"
              color="primary"
              disabled={!isTemplateOwner}
              onClick={() => setShowRemoveConfirmationModal(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip
          title={t("containers.settings.activityTemplates.editTemplateActivityTooltip")}
        >
          <Box>
            <IconButton
              aria-label="edit goal template"
              color="primary"
              disabled={!isTemplateOwner}
              onClick={() => onEdit(template)}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Tooltip>
        {onEditActivity && (
          <Tooltip
            title={t("containers.settings.activityTemplates.editTemplateTooltip")}
          >
            <Box>
              <IconButton
                aria-label="edit goal template"
                color="primary"
                disabled={!isTemplateOwner}
                onClick={() => onEditActivity(template)}
              >
                <TitleIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Flex>
      {showRemoveConfirmationModal && (
        <RemoveTemplateItemConfirmationModal
          text={t(
            "containers.settings.activityTemplates.removeTemplateConfirmation",
          )}
          onClose={onRemoveConfirmationModalClosed}
        />
      )}
    </Flex>
  );
};
