import * as React from "react";

import styled from "styled-components";

import {
  default as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@material-ui/core/Checkbox";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export type CheckboxProps = {
  helperText?: string;
  label?: string;
  inputLabel?: string;
} & MuiCheckboxProps;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: -0.5em;
`;

export const Checkbox: React.ComponentType<CheckboxProps> = styled(
  ({ inputLabel, helperText, label, ...rest }: CheckboxProps) => (
    <>
      {label ? (
        <StyledFormControlLabel
          className={rest.className}
          control={<MuiCheckbox data-cy={`${rest.name}-input`} {...rest} />}
          label={label}
        />
      ) : (
        <MuiCheckbox data-cy={`${rest.name}-input`} {...rest} />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  ),
)``;

Checkbox.defaultProps = {
  color: "primary",
};

Checkbox.displayName = "Checkbox";
