import * as React from "react";

import styled from "styled-components";
import { pathOr } from "ramda";
import { PaletteProps } from "@material-ui/system";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import { useTranslation } from "react-i18next";

import { Flex, Box, PortfolioLogo, Tooltip } from "../../Atoms";

export type HeaderProps = PaletteProps & {
  onMenuOpen: () => void;
};

const StyledMenuIcon = styled(MenuIcon)`
  fill: ${props =>
    pathOr("#fff", "theme.palette.common.white".split("."), props)};
`;
const StyledLink = styled(Link)`
  display: flex;
`;
const StyledAnnouncementOutlinedIcon = styled(AnnouncementOutlinedIcon)`
  color: #fff;
`;
export const Header: React.FunctionComponent<HeaderProps> = styled(
  ({ onMenuOpen, ...props }: HeaderProps) => {
    const { t } = useTranslation();
    return (
      <Flex {...props} alignItems="center" justifyContent="flex-start">
        <Box pl={2} mr={0.5}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onMenuOpen}
            edge="start"
            data-cy="header-button-menu"
          >
            <StyledMenuIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box>
          <StyledLink to={"/"}>
            <PortfolioLogo height="34px" color="common.white"></PortfolioLogo>
          </StyledLink>
        </Box>
        <Flex mr={2} flex={1} justifyContent="flex-end">
          <Tooltip arrow title={t("globals.giveFeedback")}>
            <IconButton
              href="https://forms.gle/6EkQ8zuDFxToywk9A"
              target="_blank"
              color="inherit"
            >
              <StyledAnnouncementOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>
    );
  },
)`
  width: 100%;
  height: 64px;
`;

Header.defaultProps = {
  bgcolor: "primary.dark",
};

Header.displayName = "Header";
