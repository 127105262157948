import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { GetTeacherProfile } from "./types/GetTeacherProfile";
import {
  UpdateTeacherLanguage,
  UpdateTeacherLanguageVariables,
} from "./types/UpdateTeacherLanguage";

export const GET_TEACHERPROFILE = gql`
  query GetTeacherProfile {
    myprofile {
      data {
        id
        givenName
        familyName
        lastSelectedSchool {
          id
        }
      }
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation UpdateTeacherLanguage($input: UserLanguageInputType!) {
    updateMyLanguage(input: $input) {
      data {
        id
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  myprofile: {
    loading: boolean;
    data: GetTeacherProfile | undefined;
  };
  updateLanguage: (language: "fi-FI" | "sv-FI") => Promise<void>;
}

interface QueriesProps {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const { loading, data } = useQuery<GetTeacherProfile>(GET_TEACHERPROFILE);

  const [updateLng] = useMutation<
    UpdateTeacherLanguage,
    UpdateTeacherLanguageVariables
  >(UPDATE_LANGUAGE);

  const updateLanguage = async (language: "fi-FI" | "sv-FI") => {
    await updateLng({
      variables: {
        input: {
          language,
        },
      },
    });
  };

  return children({
    myprofile: {
      loading,
      data,
    },
    updateLanguage,
  });
};
