import { ApolloError, useMutation, useQuery, SingleExecutionResult } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";

import {
  EditAssessmentGetUserActivity_activity_data as Activity,
  EditAssessmentGetUserActivity,
  EditAssessmentGetUserActivityVariables,
} from "./types/EditAssessmentGetUserActivity";

import {
  EditAssessmentUpdateAssessmentForm,
  EditAssessmentUpdateAssessmentFormVariables,
} from "./types/EditAssessmentUpdateAssessmentForm";

import {
  NumericAssessmentInput,
  VerbalAssessmentInput,
} from "typings/graphql-global-types";

export const GET_ACTIVITY = gql`
  query EditAssessmentGetUserActivity($filters: IdInputType!) {
    activity(filters: $filters) {
      data {
        id
        name
        learners {
          id
          givenName
          familyName
        }
        educationalPackage {
          id
        }
        verbalAssessmentSetting {
          id
          learnerHelperText
        }
        educationalPackageGoals {
          id
          name
          eGoals {
            id
          }
          numericAssessmentSetting {
            id
            scale
            learnerScaleTexts
            teacherScaleTexts
          }
        }
        learningAchievements {
          id
          materials {
            id
            name
            link
            status
            type
          }
          user {
            id
          }
          numericAssessments {
            id
            value
            type
            educationalPackageGoalId
          }
          verbalAssessments {
            type
            id
            assessmentText
            user {
              id
              givenName
              familyName
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation EditAssessmentUpdateAssessmentForm(
    $input: PatchLearningAchievementInput!
  ) {
    createOrPatchLearningAchievement(input: $input) {
      data {
        id
        user {
          id
        }
        numericAssessments {
          id
          value
          type
          educationalPackageGoalId
        }
        verbalAssessments {
          id
          assessmentText
          type
          user {
            id
            givenName
            familyName
          }
        }
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  activity: {
    loading: boolean;
    data: Activity | null;
  };

  assessment: {
    update: (
      numericAssessments: NumericAssessmentInput[],
      verbalAssessment: VerbalAssessmentInput,
    ) => Promise<SingleExecutionResult<EditAssessmentUpdateAssessmentForm>>;
    error?: ApolloError;
    loading?: boolean;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
  activityId: string;
  userId: string;
}

export const Queries: React.FC<QueriesProps> = ({
  children,
  activityId,
  userId,
}) => {
  const { loading, /*error,*/ data: activityData } = useQuery<
    EditAssessmentGetUserActivity,
    EditAssessmentGetUserActivityVariables
  >(GET_ACTIVITY, {
    variables: {
      filters: {
        id: activityId,
      },
    },
  });

  const [
    updateAssessment,
    { loading: updateAssessmentLoading, error: updateAssessmentError },
  ] = useMutation<
    EditAssessmentUpdateAssessmentForm,
    EditAssessmentUpdateAssessmentFormVariables
  >(UPDATE_ASSESSMENT);

  const update = (
    numericAssessments: NumericAssessmentInput[],
    verbalAssessment: VerbalAssessmentInput,
  ) => {
    return updateAssessment({
      variables: {
        input: {
          userId,
          activityId,
          learningAchievement: {
            numericAssessments,
            verbalAssessment,
          },
        },
      },
      refetchQueries: [
        {
          query: GET_ACTIVITY,
          variables: {
            filters: {
              id: activityId,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return children({
    activity: {
      loading,
      data: activityData ? activityData.activity.data : null,
    },
    assessment: {
      update,
      error: updateAssessmentError,
      loading: updateAssessmentLoading,
    },
  });
};
