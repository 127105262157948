import { ApolloError, ApolloQueryResult, useMutation, useQuery, SingleExecutionResult, MutationFunctionOptions } from "@apollo/client";
import gql from "graphql-tag";

import {
  AllEducationalPackagesTeacher,
  AllEducationalPackagesTeacherVariables,
} from "./types/AllEducationalPackagesTeacher";

import {
  SetActiveEducationalPackage,
  SetActiveEducationalPackageVariables,
} from "./types/SetActiveEducationalPackage";

import { ClassroomSync, ClassroomSyncVariables } from "./types/ClassroomSync";

export const ALL_EDUCATIONALPACKAGES = gql`
  query AllEducationalPackagesTeacher(
    $filters: EducationalPackagesListFiltersInputType!
    $activeCountFilters: EducationalPackagesListFiltersInputType!
    $archivedCountFilters: EducationalPackagesListFiltersInputType!
  ) {
    allEducationalPackages(filters: $filters) {
      data {
        id
        name
        active
        activitiesCount
        learnersCount
        fetching
        importedPackage
        classroomCourseLink
        colorTheme
      }
    }
    activeCount: allEducationalPackagesCount(filters: $activeCountFilters) {
      data
    }
    archivedCount: allEducationalPackagesCount(filters: $archivedCountFilters) {
      data
    }
  }
`;

export const SET_ACTIVE_EDUCATIONAL_PACKAGE = gql`
  mutation SetActiveEducationalPackage(
    $input: EducationalPackageSetActiveInputType!
  ) {
    setActiveEducationalPackage(input: $input) {
      data {
        id
        name
        active
      }
    }
  }
`;

export const SYNC_FROM_CLASSROOM = gql`
  mutation ClassroomSync($input: ForceSyncEducationalPackageType!) {
    forceClassroomSync(input: $input)
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  allEducationalPackages: {
    loading: boolean;
    data: AllEducationalPackagesTeacher | undefined;
    refetch: (
      variables?: AllEducationalPackagesTeacherVariables | undefined,
    ) => Promise<ApolloQueryResult<AllEducationalPackagesTeacher>>;
    error?: ApolloError;
    stopPolling(): void;
    startPolling(pollInterval: number): void;
  };
  setActiveEducationalPackage: {
    mutation: (
      options?:
        | MutationFunctionOptions<
            SetActiveEducationalPackage,
            SetActiveEducationalPackageVariables
          >
        | undefined,
    ) => Promise<SingleExecutionResult<SetActiveEducationalPackage>>;
    error?: ApolloError;
    loading: boolean;
  };
  syncFromClassroom: (educationalPackageId: string) => void;
}

interface QueriesProps {
  children: RenderProp<Queries>;
  active: boolean;
  pollInterval?: number;
}

export const Queries: React.FC<QueriesProps> = ({ children, active }) => {
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery<
    AllEducationalPackagesTeacher,
    AllEducationalPackagesTeacherVariables
  >(ALL_EDUCATIONALPACKAGES, {
    variables: {
      filters: { active },
      activeCountFilters: {
        active: true,
      },
      archivedCountFilters: {
        active: false,
      },
    },
  });

  const refetchQueries = {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ALL_EDUCATIONALPACKAGES,
        variables: {
          filters: {
            active: !active,
          },
          activeCountFilters: {
            active: true,
          },
          archivedCountFilters: {
            active: false,
          },
        },
      },
      {
        query: ALL_EDUCATIONALPACKAGES,
        variables: {
          filters: {
            active: active,
          },
          activeCountFilters: {
            active: true,
          },
          archivedCountFilters: {
            active: false,
          },
        },
      },
    ],
  };

  const [
    setActiveEducationalPackage,
    { loading: setActiveLoading, error: setActiveError },
  ] = useMutation<
    SetActiveEducationalPackage,
    SetActiveEducationalPackageVariables
  >(SET_ACTIVE_EDUCATIONAL_PACKAGE, refetchQueries);

  const [syncClassroom] = useMutation<ClassroomSync, ClassroomSyncVariables>(
    SYNC_FROM_CLASSROOM,
    refetchQueries,
  );

  const syncFromClassroom = (educationalPackageId: string) => {
    syncClassroom({
      variables: {
        input: {
          educationalPackageId,
        },
      },
    });
  };

  return children({
    allEducationalPackages: {
      loading,
      data,
      error,
      refetch,
      startPolling,
      stopPolling,
    },
    setActiveEducationalPackage: {
      mutation: setActiveEducationalPackage,
      loading: setActiveLoading,
      error: setActiveError,
    },
    syncFromClassroom,
  });
};
