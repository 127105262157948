import * as React from "react";

import styled from "styled-components";

import {
  default as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@material-ui/core/Tooltip";

export type TooltipProps = MuiTooltipProps;

export const Tooltip: React.FC<TooltipProps> = styled(
  (props: MuiTooltipProps) => (
    <MuiTooltip arrow enterTouchDelay={10} {...props} />
  ),
)``;

Tooltip.displayName = "Tooltip";
