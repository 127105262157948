import React from "react";
import { FormikProps } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { Flex, InputLabel, Input } from "../../../Atoms";
import useBeforeUnload from "components/Hooks/use-before-unload";
import { EducationalPackageJoinByCodeInputType } from "typings/graphql-global-types";

const Form = styled.form`
  width: 100%;
`;

interface JoinByCodeFormProps extends FormikProps<EducationalPackageJoinByCodeInputType> {}

export const JoinByCodeForm: React.FC<JoinByCodeFormProps> = ({
  values: { joinCode },
  errors,
  touched,
  handleChange,
  setFieldTouched,
}) => {
  const { t } = useTranslation();

  useBeforeUnload(() => {
    return Object.keys(touched).length > 0;
  });

  const change = (name: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const JOIN_CODE_MAX_LENGTH = 8;

  return (
    <Form>
      <Flex flex={"auto"} flexDirection="column">
        <FormControl>
          <InputLabel htmlFor="joinCode">
            {t("components.organisms.joinEducationalPackageByCodeModal.joinCode")}
          </InputLabel>
          <Input
            id="joinCode"
            name="joinCode"
            helperText={touched.joinCode ? errors.joinCode : ""}
            error={touched.joinCode && Boolean(errors.joinCode)}
            value={joinCode}
            onChange={change.bind(null, "joinCode")}
            fullWidth
            inputProps={{ "maxLength": JOIN_CODE_MAX_LENGTH }}
          />
        </FormControl>
      </Flex>
    </Form>
  );
};
