import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client";
import gql from "graphql-tag";

import {
  AllEducationalPackagesLearner,
  AllEducationalPackagesLearnerVariables,
} from "./types/AllEducationalPackagesLearner";

import { Status } from "typings/graphql-global-types";

export const ALL_EDUCATIONALPACKAGES = gql`
  query AllEducationalPackagesLearner(
    $filters: EducationalPackagesListFiltersInputType!
    $activitiesActiveFilter: EducationalPackageActivitiesListFiltersInputType!
    $activitiesSubmittedFilter: EducationalPackageActivitiesListFiltersInputType!
  ) {
    allEducationalPackages(filters: $filters) {
      data {
        id
        name
        activeActivitiesCount: activitiesCount(filters: $activitiesActiveFilter)
        submittedActivitiesCount: activitiesCount(
          filters: $activitiesSubmittedFilter
        )
        schoolSubjects {
          id
          name
        }
        importedPackage
        classroomCourseLink
        colorTheme
      }
    }
  }
`;

interface RenderProp<TChildrenProps, TElement = any> {
  (props: TChildrenProps): React.ReactElement<TElement>;
}

interface Queries {
  allEducationalPackages: {
    loading: boolean;
    data: AllEducationalPackagesLearner | undefined;
    refetch: (
      variables?: AllEducationalPackagesLearnerVariables | undefined,
    ) => Promise<ApolloQueryResult<AllEducationalPackagesLearner>>;
    error?: ApolloError;
  };
}

interface QueriesProps {
  children: RenderProp<Queries>;
}

export const Queries: React.FC<QueriesProps> = ({ children }) => {
  const { loading, error, data, refetch } = useQuery<
    AllEducationalPackagesLearner,
    AllEducationalPackagesLearnerVariables
  >(ALL_EDUCATIONALPACKAGES, {
    variables: {
      filters: {
        active: true,
      },
      activitiesActiveFilter: { status: Status.active },
      activitiesSubmittedFilter: { submitted: true },
    },
  });

  return children({
    allEducationalPackages: { loading, data, error, refetch },
  });
};
