import * as React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import Color from "color";
import LaunchIcon from "@material-ui/icons/Launch";

import { GoButton, Flex, Box, Tooltip } from "../../Atoms";
import { DropdownMenu, DropdownOptionItem } from "../../Molecules/DropdownMenu";
import { Card, CardHeader, CardActions } from "../../Molecules/Card";

import ClassroomImage from "images/classroom.png";

export interface EducationPackageCardProps {
  title: string;
  openUrl: string;
  onContextMenuChanged?: (o: DropdownOptionItem) => void;
  archived?: boolean;
  colorTheme?: string | null;
  hideContextMenu?: boolean;
  importedFromClassRoom?: boolean;
}

function getTextColor(hex: string) {
  //if (Color(hex).isLight()) return "inherit";
  //else
  return "#fff";
}

type ColorProp = {
  color?: string | null;
};

const Image = styled.img`
  width: 2em;
  height: 2em;
`;

const ColoredHeader = styled(CardHeader)<ColorProp>`
  ${props => props.color && `background-color: ${props.color}`};
  ${props => props.color && `color: ${getTextColor(props.color)}`};
  .MuiTypography-h3,
  svg {
    ${props => props.color && `color: ${getTextColor(props.color)}`};
  }
`;

const Truncate = styled(Box)`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props: any) => props.theme.breakpoints.down("lg")} {
    max-width: 222px;
  }
`;

const ResponsiveCard = styled(Card)`
  height: 236px;
  width: 328px;

  ${(props: any) => props.theme.breakpoints.down("md")} {
    width: 300px;
  }

  ${props => props.theme.breakpoints.down("xs")} {
    max-width: 100%;
    width: 100%;
    border-radius: 0;
  }

  position: relative;
  display: flex;
  flex-direction: column;
`;

export enum EducationPackageCardContextMenuItems {
  activate,
  archive,
  edit,
  editMembers,
  moveToClassroom,
  syncClassroom,
}

export const EducationPackageCard: React.FunctionComponent<EducationPackageCardProps> = ({
  title,
  colorTheme,
  openUrl,
  hideContextMenu,
  children,
  archived,
  onContextMenuChanged,
  importedFromClassRoom,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      id: EducationPackageCardContextMenuItems.edit,
      label: t("components.organisms.educationPackageCard.edit"),
    },
    ...(!importedFromClassRoom
      ? [
          {
            id: EducationPackageCardContextMenuItems.editMembers,
            label: t("components.organisms.educationPackageCard.editMembers"),
          },
        ]
      : []),
    archived
      ? {
          id: EducationPackageCardContextMenuItems.activate,
          label: t("components.organisms.educationPackageCard.activate"),
          value: true,
        }
      : {
          id: EducationPackageCardContextMenuItems.archive,
          label: t("components.organisms.educationPackageCard.archive"),
          value: false,
        },
    ...(importedFromClassRoom
      ? [
          {
            id: EducationPackageCardContextMenuItems.moveToClassroom,
            label: (
              <Flex flex="1" justifyContent="space-between" alignItems="center">
                <Box>
                  {t(
                    "components.organisms.educationPackageCard.moveToClassroom",
                  )}
                </Box>
                <Flex>
                  <LaunchIcon color="primary" fontSize="small" />
                </Flex>
              </Flex>
            ),
          },
          {
            id: EducationPackageCardContextMenuItems.syncClassroom,
            label: t(
              "components.organisms.educationPackageCard.syncFromClassroom",
            ),
          },
        ]
      : []),
  ];

  return (
    <ResponsiveCard data-cy={`educationalpackage-card-${title}`}>
      <ColoredHeader
        color={colorTheme ? colorTheme : undefined}
        title={
          (
            <Tooltip title={title} arrow>
              <Truncate>{title}</Truncate>
            </Tooltip>
          ) as any
        }
        titleTypographyProps={{ variant: "h3", noWrap: true }}
        action={
          !hideContextMenu ? (
            <DropdownMenu
              name={title}
              onSelect={onContextMenuChanged || (() => null)}
              options={options}
            />
          ) : null
        }
      />
      {children}
      <CardActions>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Box>
            {importedFromClassRoom && (
              <Tooltip title={t("globals.classroomConnected")} arrow>
                <Image src={ClassroomImage} alt="Classroom" />
              </Tooltip>
            )}
          </Box>
          <Box>
            <GoButton
              label={t("components.organisms.educationPackageCard.open")}
              path={openUrl}
              direction="forward"
            />
          </Box>
        </Flex>
      </CardActions>
    </ResponsiveCard>
  );
};
