import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Box, Text } from "components/Atoms";
import styled from "styled-components";
import { AssessmentType } from "typings/graphql-global-types";
import { Form } from "./Form";

const CardItem = styled(Box)`
  border-left: 5px solid ${props => props.theme.palette.primary.main};
  background-color: #f6f9fb;
  border-top: 1px solid ${props => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
`;

export interface AssessmentFormProps {
  type: AssessmentType;
  activityId: string;
  userId: string;
  onEditGoalsClick: () => void;
}

export const AssessmentForm: React.FC<AssessmentFormProps> = ({
  activityId,
  userId,
  type,
  onEditGoalsClick,
}) => {
  const { t } = useTranslation();
  return (
    <CardItem px={3} py={2}>
      <Flex flexDirection="column" justifyContent="center">
        <Text variant="h4" color="textPrimary">
          {type === AssessmentType.teacher &&
            t("components.organisms.assessmentForm.titleTeacher")}
          {type === AssessmentType.self &&
            t("components.organisms.assessmentForm.titleSelf")}
        </Text>
        <Form
          type={type}
          activityId={activityId}
          userId={userId}
          onEditGoalsClick={onEditGoalsClick}
        />
      </Flex>
    </CardItem>
  );
};
