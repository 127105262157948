import * as React from "react";

import { Formik } from "formik";

import { useTranslation } from "react-i18next";

import { Modal } from "../../Molecules";

import { MaterialsForm } from "./Form";
import { Queries } from "./Queries";

export interface EditLearningAchievementMaterialsModalProps {
  activityId: string;
  userId: string;
  open: boolean;
  type: "teacher" | "learner";
  readonly?: boolean;
  onModalClosed: (result: boolean) => void;
}

export const EditLearningAchievementMaterialsModal: React.FunctionComponent<EditLearningAchievementMaterialsModalProps> = ({
  activityId,
  userId,
  open,
  onModalClosed,
  type,
  readonly,
}) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onModalClosed(false);
  };
  return (
    <Queries activityId={activityId} userId={userId}>
      {({
        achievement: { loading, data: achievementData },
        mutate: { removeMaterials, error },
      }) => {
        let title = "";
        if (achievementData && type === "learner") {
          title = achievementData.activity.name;
        }

        if (achievementData && achievementData.user && type === "teacher") {
          title = `${achievementData!.user.givenName} ${
            achievementData!.user.familyName
          }`;
        }

        const materials = achievementData ? achievementData.materials : [];
        return (
          <Formik
            enableReinitialize
            initialValues={{
              materials,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const materialsModified = values.materials || [];
              await removeMaterials(
                materials.filter(
                  m => !materialsModified.find(rm => m.id === rm.id),
                ),
              );
              setSubmitting(false);
              onModalClosed(true);
            }}
          >
            {props => {
              const { isSubmitting, handleSubmit, isValid } = props;
              return (
                <Modal
                  error={undefined /*error*/}
                  loading={isSubmitting || loading}
                  primaryAction={{
                    text: readonly ? t("globals.close") : t("globals.save"),
                    onClick: readonly ? onCancel : handleSubmit,
                    disabled: !isValid,
                  }}
                  secondaryAction={
                    readonly
                      ? undefined
                      : {
                          text: t("globals.cancel"),
                          onClick: onCancel,
                        }
                  }
                  open={open}
                  title={title}
                  subtitle={t(
                    "components.organisms.editLearningAchievementMaterialsModal.subtitle",
                  )}
                >
                  <MaterialsForm readonly={readonly} {...props} />
                </Modal>
              );
            }}
          </Formik>
        );
      }}
    </Queries>
  );
};
