import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import { useParams, useHistory } from "react-router";
import { useAnalytics } from "components/Hooks/firebase-hooks";

import { EDUCATIONALPACKAGE, HOME, getRoute } from "routes";

import { Loader } from "components/Atoms";

import {
  GetActivityRedirectTeacher,
  GetActivityRedirectTeacherVariables,
} from "./types/GetActivityRedirectTeacher";

const FullscreenLoader = styled(Loader)`
  height: 100vh;
  width: 100vw;
`;

const GET_ACTIVITY = gql`
  query GetActivityRedirectTeacher($filters: IdInputType!) {
    myprofile {
      data {
        id
      }
    }
    activity(filters: $filters) {
      data {
        id
        educationalPackage {
          id
        }
      }
    }
  }
`;

export default () => {
  const { activityId }: any = useParams();
  const history = useHistory();
  const { analytics } = useAnalytics();

  analytics.logEvent("page_view" as string, {
    page_title: "Teacher_selfAssessmentFromLink",
    page_path: history.location.pathname,
  });

  const { loading, data } = useQuery<
    GetActivityRedirectTeacher,
    GetActivityRedirectTeacherVariables
  >(GET_ACTIVITY, { variables: { filters: { id: activityId } } });

  if (loading) return <FullscreenLoader />;

  const educationalPackageId =
    data &&
    data.activity.data.educationalPackage &&
    data.activity.data.educationalPackage.id;

  if (educationalPackageId) {
    return (
      <Redirect
        to={`${getRoute(EDUCATIONALPACKAGE, {
          educationalPackageId,
          tab: "assessments",
        })}?activityId=${activityId}`}
      />
    );
  } else {
    return <Redirect to={HOME} />;
  }
};
