import React from "react";
import styled from "styled-components";
import LaunchIcon from "@material-ui/icons/Launch";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { Flex, Box, Text, Chip } from "components/Atoms";

const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`;

export interface Material {
  id: string;
  name: string;
  link: string | null;
  status: "created" | "completed" | "error";
  type: "file" | "link";
}

export interface MaterialsProps {
  title?: string;
  materials: Material[];
  onMaterialRemove?: (material: Material) => void;
  disabled?: boolean;
}

const BlackLaunchIcon = styled(LaunchIcon)`
  color: #000;
`;

interface ErrorProp {
  error?: number;
}

const StyledChip = styled(Chip)<ErrorProp>`
  min-width: 200px;
  justify-content: space-between;
  &.Mui-disabled {
    opacity: 0.5;
  }
  .MuiChip-label {
    flex: 1;
  }
  ${(props: any) => props.theme.breakpoints.down("xs")} {
    width: 100%;
    justify-content: left;
  }

  ${props =>
    props.error
      ? `color: ${props.theme.palette.error.main}; svg { fill: ${props.theme.palette.error.main}; }`
      : ""}
`;

const TruncateText = styled(Text)<ErrorProp>`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => (props.error ? `color: ${props.theme.palette.error.main};` : "")}
`;

const StyledLink = styled(Link)`
  color: #000;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: unset;
`;

export const Materials: React.FC<MaterialsProps> = ({
  materials,
  title,
  onMaterialRemove,
  disabled,
}) => {
  const { t } = useTranslation();
  const uploadOngoing = materials.find(f => f.status === "created");

  const uploadError = materials.find(f => f.status === "error");

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        {title && <UpperCaseText variant="h6">{title}</UpperCaseText>}
        {uploadOngoing && (
          <Box my={0.5}>
            <Text>{t("components.molecules.materials.uploadOngoing")}</Text>
          </Box>
        )}
        {uploadError && (
          <Box my={0.5}>
            <Text color="error">
              {t("components.molecules.materials.uploadError")}
            </Text>
          </Box>
        )}
        <Flex pt={title ? 1.5 : 0} alignItems="center" flexWrap="wrap">
          {materials.map(material => (
            <Box key={material.id} flex={[1, "none"]} mr={[0, 2]} mb={2}>
              <StyledChip
                disabled={material.status === "created" || disabled}
                error={material.status === "error" ? 1 : undefined}
                icon={<BlackLaunchIcon fontSize="small" />}
                onDelete={
                  onMaterialRemove
                    ? () => onMaterialRemove(material)
                    : undefined
                }
                label={
                  material.link && material.status !== "error" ? (
                    <StyledLink
                      href={
                        material.type === "file"
                          ? `https://drive.google.com/file/d/${material.link}/view`
                          : material.link
                      }
                      target="_blank"
                    >
                      {material.name}
                    </StyledLink>
                  ) : (
                    <TruncateText
                      error={material.status === "error" ? 1 : undefined}
                    >
                      {material.name}
                    </TruncateText>
                  )
                }
              ></StyledChip>
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
